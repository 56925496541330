import { createContext, useState } from "react";
import { NotImplemented } from "../../../utility";

export type DocUploadContextProps = {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    uploading: boolean;
    setUploading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DocUploadContext = createContext<DocUploadContextProps>({
    loading: false,
    setLoading: NotImplemented,
    uploading: false,
    setUploading: NotImplemented
});

export const useDocUploadContextProps = (): DocUploadContextProps => {
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);

    return {
        loading,
        setLoading,
        uploading,
        setUploading
    };
};
