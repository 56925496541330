import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Dollar(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="12"
            height="24"
            viewBox="0 0 12 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.5 3.75H4.8C3.92479 3.75 3.08542 4.09768 2.46655 4.71655C1.84768 5.33542 1.5 6.17479 1.5 7.05C1.5 12 10.5 12 10.5 16.95C10.5 17.8252 10.1523 18.6646 9.53345 19.2835C8.91458 19.9023 8.07521 20.25 7.2 20.25H1.5"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M6 3.75V0.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 20.25V23.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
