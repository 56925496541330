import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function CardOptions(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M46.5 27V4.5C46.5 3.70435 46.1839 2.94129 45.6213 2.37868C45.0587 1.81607 44.2956 1.5 43.5 1.5H10.5C9.70435 1.5 8.94129 1.81607 8.37868 2.37868C7.81607 2.94129 7.5 3.70435 7.5 4.5V12"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M7.5 10.5H46.5" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M37.5 18H4.5C2.84315 18 1.5 19.3431 1.5 21V43.5C1.5 45.1569 2.84315 46.5 4.5 46.5H37.5C39.1569 46.5 40.5 45.1569 40.5 43.5V21C40.5 19.3431 39.1569 18 37.5 18Z"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M1.5 27H40.5" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.5 36H12" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M27.75 34.5C27.2778 33.8704 26.6194 33.4053 25.8681 33.1706C25.1169 32.9359 24.3108 32.9435 23.5641 33.1924C22.8175 33.4413 22.168 33.9188 21.7078 34.5573C21.2476 35.1958 21 35.9629 21 36.75C21 37.5371 21.2476 38.3042 21.7078 38.9427C22.168 39.5812 22.8175 40.0587 23.5641 40.3076C24.3108 40.5565 25.1169 40.5641 25.8681 40.3294C26.6194 40.0948 27.2778 39.6296 27.75 39C28.2222 39.6296 28.8806 40.0948 29.6319 40.3294C30.3831 40.5641 31.1892 40.5565 31.9359 40.3076C32.6825 40.0587 33.332 39.5812 33.7922 38.9427C34.2524 38.3042 34.5 37.5371 34.5 36.75C34.5 35.9629 34.2524 35.1958 33.7922 34.5573C33.332 33.9188 32.6825 33.4413 31.9359 33.1924C31.1892 32.9435 30.3831 32.9359 29.6319 33.1706C28.8806 33.4053 28.2222 33.8704 27.75 34.5V34.5Z"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
