import { ReactElement } from "react";
import { UserAccount } from "../../../../api/backend";
import { DashboardCardHeaderView } from "./dashboardCardHeaderView";
import { useDashboardCardHeaderViewModel } from "./dashboardCardHeaderViewModel";

export type DashboardCardHeaderProps = {
    index: number;
    account: UserAccount;
};

export function DashboardCardHeader(props: DashboardCardHeaderProps): ReactElement {
    const { hooks, state } = useDashboardCardHeaderViewModel();

    function viewAccount() {
        state.setAccountIndex(props.index);
        hooks.navigate(`/account/summary/${props.index}/home`);
    }

    return <DashboardCardHeaderView {...props} {...state} viewAccount={viewAccount} />;
}
