import { ReactElement } from "react";
import { GenericModal, GenericModalProps, ModalProps } from "../../../generic";
import "./addPaymentAccountSuccess.scss";

export type AddPaymentAccountSuccessProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    bankName: string;
    setBankName: React.Dispatch<React.SetStateAction<string>>;
    setRoutingNumber: React.Dispatch<React.SetStateAction<string>>;
    checkingNumber: string;
    setCheckingNumber: React.Dispatch<React.SetStateAction<string>>;
    setConfirmNumber: React.Dispatch<React.SetStateAction<string>>;
    callback?: Function;
};

export function AddPaymentAccountSuccess(props: ModalProps & AddPaymentAccountSuccessProps): ReactElement {
    const state = {
        headerText: "Payment Account Added",
        primaryButton: {
            text: "Close",
            action: () => {
                onClose();
            }
        }
    } as GenericModalProps;

    function onClose() {
        props.setRoutingNumber("");
        props.setCheckingNumber("");
        props.setConfirmNumber("");
        props.setBankName("");
        props.setShow(false);

        if (props.callback !== undefined) {
            props.callback();
        }
    }

    return (
        <GenericModal show={props.show} {...state}>
            <div className="addPaymentAccountSuccess-text">
                {props.checkingNumber && props.checkingNumber.length > 4 && (
                    <>
                        <p className="addPaymentAccountSuccess-bankname">{props.bankName}</p>
                        <h6 className="addPaymentAccountSuccess-accountnumber">
                            {Array(props.checkingNumber?.length - 4)
                                .fill("•")
                                .join("") + props.checkingNumber?.slice(-4)}
                        </h6>
                    </>
                )}
            </div>
        </GenericModal>
    );
}
