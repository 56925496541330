import { ChangeEvent, ReactElement } from "react";
import { Loading } from "../../..";
import { GenericModal, Modal, ModalProps } from "../../../generic";
import { Add, Exit, StatementDoc } from "../../../generic/icons";
import { ButtonPro } from "../../../generic/inputs/buttonPro/buttonPro";
import { LoadingIndicator } from "../../../peripherals/loading/loadingIndicator";
import DocumentUploadUnavailable from "../unavailable/documentUploadUnavailable";
import "./documentUploadForm.scss";
import { DocumentUploadFormModelProps } from "./documentUploadFormModel";
import { DocumentUploadFormState } from "./documentUploadFormViewModel";

type DocumentUploadFormViewProps = DocumentUploadFormModelProps &
    DocumentUploadFormState & {
        onUploadClick: Function;
        onFileChange: Function;
        onSubmit: Function;
        removeFile: Function;
    };

function DocumentUploadFormView(props: DocumentUploadFormViewProps): ReactElement {
    return (
        <>
            {props.loading ? (
                <Loading show={props.loading} />
            ) : (
                <div id="documentUploadForm">
                    {props.rows.length > 0 ? (
                        <>
                            <DocumentUploadFormHeader />
                            <DocumentUploadFormDocumentReasons {...props} />
                            <div className="documentUploadForm-section">
                                <DocumentUploadFormUploadText {...props} />
                                <DocumentUploadFormUploadFiles {...props} />
                                <DocumentUploadFormButtons {...props} />
                            </div>
                            <UploadingModal show={props.uploading} />
                            <GenericModal show={props.showUploadConfirmation} {...props.uploadConfirmation} />
                            <GenericModal show={props.showUploadError} {...props.uploadError} />
                        </>
                    ) : (
                        <>
                            <DocumentUploadUnavailable {...props} />
                        </>
                    )}
                </div>
            )}
        </>
    );
}

function DocumentUploadFormHeader(): ReactElement {
    return (
        <div className="documentUploadForm-header">
            <div className="documentUploadForm-header-text">
                Submit your paperwork electronically with our quick and secure Upload Documents feature.
            </div>
        </div>
    );
}

function DocumentUploadFormDocumentReasons(props: DocumentUploadFormViewProps): ReactElement {
    return (
        <div id="documentUploadForm-section-reasons">
            <div className="documentUploadForm-section-header">
                <div className="documentUploadForm-section-header">Select a Reason</div>
                <div className="documentUploadForm-section-text">What's the reason you're uploading this?</div>
                <div className="documentUploadForm-reasons-list">
                    {props.rows.length > 0 ? (
                        props.rows
                    ) : (
                        <div className="documentUploadForm-reasons-noList">
                            <h5>No document reasons available</h5>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function DocumentUploadFormUploadText(props: DocumentUploadFormViewProps): ReactElement {
    return (
        <div className="documentUploadForm-content">
            <div className="documentUploadForm-header-text">
                Documents are uploaded via a secure process. Files must not be password protected in order for your
                documents to be viewed and processed.
            </div>
            <div className="documentUploadForm-section-uploadfiles">
                <div className="documentUploadForm-section-header">Upload Files</div>
                <div className="documentUploadForm-section-text">Select the files you</div>
                <div className="documentUploadForm-section-text">wish to upload</div>
            </div>
            <div className="documentUploadForm-section-fileformats">Valid file formats:</div>
            <div className="documentUploadForm-section-text-dark">
                <div className="documentUploadForm-section-indent">JPG, JPEG, PDF, PNG, TIFF</div>
                <div className="documentUploadForm-section-indent">{`Max file size: ${ToMB(
                    props?.appSettings?.docUpload?.docUploadMaxFileSize
                )}`}</div>
                <div className="documentUploadForm-section-indent">{`Max files per upload: ${props.maxFiles}`}</div>
            </div>
        </div>
    );
}

function ToMB(bytes: number): string {
    var exp = (Math.log(bytes) / Math.log(1024)) | 0;
    var result = (bytes / Math.pow(1024, exp)).toFixed(2);
    return result + " " + (exp === 0 ? "bytes" : "KMGT"[exp - 1] + "B");
}

function UploadingModal(props: ModalProps): ReactElement {
    return (
        <Modal show={props.show}>
            <div className="upload-modal">
                <div className="upload-modal-container">
                    <div className="upload-modal-animation">
                        <LoadingIndicator />
                    </div>
                    <h2 className="upload-modal-header">Uploading Your Documents...</h2>
                    <p className="upload-modal-body">
                        Please do not close this window or select the back button while files are uploading.
                    </p>
                </div>
            </div>
        </Modal>
    );
}

function DocumentUploadFormUploadFiles(props: DocumentUploadFormViewProps): ReactElement {
    return (
        <div className="documentUploadForm-addfiles-container">
            <div className="documentUploadForm-addfiles-section">
                <div className="documentUploadForm-filelist">
                    {props.files.length > 0 ? (
                        props.files.map((data, index) => (
                            <div key={index} className={`documentUploadForm-file-${index % 2}`}>
                                <StatementDoc className="statement-icon" />
                                {data.name}
                                <Exit className="exit-icon" onClick={() => props.removeFile(index)} />
                            </div>
                        ))
                    ) : (
                        <></>
                    )}
                </div>
                <div
                    className="documentUploadForm-addfiles-header"
                    data-testid="addFilesDiv"
                    onClick={() => props.onUploadClick()}
                >
                    <Add className="plus-sin" />
                    <h5>Add Files</h5>
                </div>
                <input
                    type="file"
                    // multiple
                    ref={props.inputFile}
                    data-testid="AddFilesInput"
                    style={{ display: "none" }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => props.onFileChange(e)}
                    accept="image/jpeg, image/png, image/tiff, .pdf, image/jpg, .jpg"
                    onClick={(e) => {
                        const element = e.target as HTMLInputElement;
                        element.value = "";
                    }}
                />
                <div className="documentUploadForm-addfiles-filelist"></div>
            </div>
            <div className="documentUploadForm-file-counter">
                {props.files.length} of {props.maxFiles} files
            </div>
        </div>
    );
}

function DocumentUploadFormButtons(props: DocumentUploadFormViewProps): ReactElement {
    return (
        <div className={`documentUploadForm-buttons${props.size}`}>
            <ButtonPro
                className={`documentUploadForm-button${props.size}`}
                variant="primary"
                disabled={props.submitDisabled}
                onClick={() => props.onSubmit()}
            >
                Upload
            </ButtonPro>
        </div>
    );
}

export default DocumentUploadFormView;
