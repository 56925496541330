import { FormikHelpers } from "formik";
import { ReactElement, useEffect } from "react";
import { ProfileUpdateAddress, ProfileUpdateEmail, ProfileUpdatePhone, ValidationErrors } from "../../../api/backend";
import { FormProps } from "../../../types/formProps";
import { IsNullOrEmpty } from "../../../utility";
import { EmailRegex } from "../../../utility/regex";
import { CardContainer, UpdateContactInformationView } from "./updateContactInformationView";
import {
    AddressFormFields,
    EmailFormFields,
    PhoneNumberFormFields,
    useUpdateContactInformationViewModel
} from "./updateContactInformationViewModel";

export function UpdateContactInformationModel(): ReactElement {
    const { hooks, api, state } = useUpdateContactInformationViewModel();

    useEffect(() => {
        if (state.loading === false && state.loadingAsync === false) {
            let eles = [] as ReactElement[];
            eles = eles.concat(
                state
                    .accounts!.filter((act) => hooks.actionAllowed("updateProfile", act.accountHandle))
                    .map((a, i) => (
                        <CardContainer
                            {...state}
                            submitAddress={submitAddress}
                            submitEmail={submitEmail}
                            checkAddress={checkAddress}
                            checkPhone={checkPhone}
                            checkEmail={checkEmail}
                            submitPhone={submitPhone}
                            index={i}
                            last4={state.accounts![i]?.maskedAccountNumber?.substring(
                                state.accounts![i]?.maskedAccountNumber?.length - 4
                            )}
                            key={a.accountHandle}
                            profile={state.profiles?.filter((p) => p.accountHandle === a.accountHandle)[0]}
                        />
                    ))
            );
            state.setCards(eles);
        }
    }, [
        state.profiles,
        state.size,
        state.editAddress,
        state.editPhone,
        state.editEmail,
        state.canContinue,
        state.unsavedChanges,
        state.activeIndex,
        state.isStateFC,
        state.addressApiError,
        state.showAddressApiError,
        state.phoneApiError,
        state.showPhoneApiError,
        state.emailApiError,
        state.showEmailApiError,
        state.address1Error,
        state.cityError,
        state.zipCodeError,
        state.cellPhone1Error,
        state.cellPhone2Error,
        state.homePhoneError,
        state.workPhoneError,
        state.emailErrors,
        state.selectedState
    ]);

    useEffect(() => {
        if (!hooks.actionAllowed("updateProfile")) {
            hooks.navigate(`/account/dashboard`, { relative: "path" });
        }
    });

    useEffect(() => {
        state.setIsStateFC(state.selectedState === "FC");
    }, [state.selectedState]);

    function checkAddress(
        newValue: string,
        path: "address1" | "address2" | "city" | "state" | "zipCode",
        bag: FormProps<AddressFormFields>,
        isOnBlur: boolean
    ) {
        let address1 = path === "address1" ? newValue : bag.values.address1;
        let city = path === "city" ? newValue : bag.values.city;
        let zipCode = path === "zipCode" ? newValue : bag.values.zipCode;

        if (newValue !== state.profiles[state.activeIndex]![path]) state.setUnsavedChanges(true);

        if (
            address1.length === 0 ||
            city.length === 0 ||
            zipCode.length === 0 ||
            (zipCode.length !== 5 && zipCode.length !== 9)
        ) {
            if (address1.length === 0) {
                state.setCanContinue(false);
                const error = "Address Line 1 is required";
                if (path === "address1" && isOnBlur) {
                    state.setAddress1Error(error);
                }
            } else {
                state.setAddress1Error("");
            }

            if (city.length === 0) {
                state.setCanContinue(false);
                const error = "City is required";
                if (path === "city" && isOnBlur) {
                    state.setCityError(error);
                }
            } else {
                state.setCityError("");
            }

            if (zipCode.length === 0) {
                state.setCanContinue(false);
                const error = "Zip Code is required";
                if (path === "zipCode" && isOnBlur) {
                    state.setZipCodeError(error);
                }
            } else if (zipCode.length !== 5 && zipCode.length !== 9) {
                state.setCanContinue(false);
                const error = "Zip Code must be either 5 or 9 digits";
                if (path === "zipCode" && isOnBlur) {
                    state.setZipCodeError(error);
                }
            } else {
                state.setZipCodeError("");
            }
        } else if (newValue !== state.profiles[state.activeIndex]![path]) {
            state.setAddress1Error("");
            state.setCityError("");
            state.setZipCodeError("");
            state.setCanContinue(true);
        }
    }

    function checkEmail(newValue: string): void {
        if (newValue !== state.profiles[state.activeIndex]!["emailAddress"]) state.setUnsavedChanges(true);

        if (newValue !== state.profiles[state.activeIndex]!["emailAddress"] && newValue !== "") {
            if (newValue.match(EmailRegex)) {
                state.setCanContinue(true);
                state.setEmailErrors("");
            } else {
                state.setCanContinue(false);
                state.setEmailErrors("Invalid Email");
            }
        }
    }

    function checkPhone(
        newValue: string,
        path: "cellPhone1" | "cellPhone2" | "homePhone" | "workPhone",
        bag: FormProps<PhoneNumberFormFields>,
        isOnBlur: boolean
    ) {
        newValue = newValue.replace(/[^+\d]+/g, "");
        let cellPhone1 = path === "cellPhone1" ? newValue : bag.values.cellPhone1.replace(/[^+\d]+/g, "");
        let cellPhone2 = path === "cellPhone2" ? newValue : bag.values.cellPhone2.replace(/[^+\d]+/g, "");
        let homePhone = path === "homePhone" ? newValue : bag.values.homePhone.replace(/[^+\d]+/g, "");
        let workPhone = path === "workPhone" ? newValue : bag.values.workPhone.replace(/[^+\d]+/g, "");

        if (isOnBlur) {
            state.setCellPhone1Error("");
            state.setCellPhone2Error("");
            state.setHomePhoneError("");
            state.setWorkPhoneError("");
        }

        if (newValue !== state.profiles[state.activeIndex]![path]) state.setUnsavedChanges(true);

        if (phoneIsBlank(cellPhone1) && phoneIsBlank(homePhone)) {
            state.setCanContinue(false);
            const error = "Either Cell Phone 1 or Home Phone is required";
            state.setCellPhone1Error(error);
            state.setHomePhoneError(error);
        } else if (
            (cellPhone1.length !== 10 && cellPhone1.length !== 0) ||
            (cellPhone2.length !== 10 && cellPhone2.length !== 0) ||
            (homePhone.length !== 10 && homePhone.length !== 0) ||
            (workPhone.length !== 10 && workPhone.length !== 0)
        ) {
            const error = "Phone Number must be ten digits";
            state.setCanContinue(false);

            if (cellPhone1.length !== 10 && cellPhone1.length !== 0) {
                if (isOnBlur) {
                    state.setCellPhone1Error(error);
                }
            } else {
                state.setCellPhone1Error("");
            }
            if (cellPhone2.length !== 10 && cellPhone2.length !== 0) {
                if (isOnBlur) {
                    state.setCellPhone2Error(error);
                }
            } else {
                state.setCellPhone2Error("");
            }

            if (homePhone.length !== 10 && homePhone.length !== 0) {
                if (isOnBlur) {
                    state.setHomePhoneError(error);
                }
            } else {
                state.setHomePhoneError("");
            }
            if (workPhone.length !== 10 && workPhone.length !== 0) {
                if (isOnBlur) {
                    state.setWorkPhoneError(error);
                }
            } else {
                state.setWorkPhoneError("");
            }
        } else if (
            cellPhone1 === "0000000001" ||
            cellPhone2 === "0000000001" ||
            homePhone === "0000000001" ||
            workPhone === "0000000001"
        ) {
            const error = "Invalid Phone Number";
            state.setCanContinue(false);

            if (cellPhone1 === "0000000001") {
                if (isOnBlur) state.setCellPhone1Error(error);
            } else {
                state.setCellPhone1Error("");
            }
            if (cellPhone2 === "0000000001") {
                if (isOnBlur) state.setCellPhone2Error(error);
            } else {
                state.setCellPhone2Error("");
            }
            if (homePhone === "0000000001") {
                if (isOnBlur) state.setHomePhoneError(error);
            } else {
                state.setHomePhoneError("");
            }
            if (workPhone === "0000000001") {
                if (isOnBlur) state.setWorkPhoneError(error);
            } else {
                state.setWorkPhoneError("");
            }
        } else {
            let tempCanContinue = true;

            if (state.unsavedChanges) {
                for (let [key, value] of Object.entries(bag.values)) {
                    for (let [key2, value2] of Object.entries(bag.values)) {
                        if (key !== key2 && key !== "multipleCard" && key2 !== "multipleCard") {
                            if (
                                value?.toString().replace(/[^+\d]+/g, "") ===
                                    value2?.toString().replace(/[^+\d]+/g, "") &&
                                value !== "" &&
                                value2 !== ""
                            ) {
                                const error = "Phone numbers cannot match";
                                tempCanContinue = false;

                                if (isOnBlur) {
                                    if (key === "cellPhone1" || key2 === "cellPhone1") {
                                        state.setCellPhone1Error(error);
                                    }

                                    if (key === "cellPhone2" || key2 === "cellPhone2") {
                                        state.setCellPhone2Error(error);
                                    }

                                    if (key === "homePhone" || key2 === "homePhone") {
                                        state.setHomePhoneError(error);
                                    }

                                    if (key === "workPhone" || key2 === "workPhone") {
                                        state.setWorkPhoneError(error);
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                tempCanContinue = false;
            }

            if (tempCanContinue) {
                state.setCellPhone1Error("");
                state.setCellPhone2Error("");
                state.setHomePhoneError("");
                state.setWorkPhoneError("");
            }

            state.setCanContinue(tempCanContinue);
        }
    }

    function phoneIsBlank(phone: string) {
        if (IsNullOrEmpty(phone)) {
            return true;
        }
        if (phone.length === 10 && phone === "0000000000") {
            return true;
        }
    }

    function submitAddress(values: AddressFormFields, actions: FormikHelpers<AddressFormFields>) {
        state.setLoading(true);
        state.setCanContinue(false);

        let updateProfileChanges: ProfileUpdateAddress = {
            ...state.profiles[state.activeIndex]!,
            ApplyToAccountHandlesList: []
        };
        updateProfileChanges.address1 = values.address1.trim();
        updateProfileChanges.address2 = values.address2.trim();
        updateProfileChanges.city = values.city.trim();
        updateProfileChanges.state = values.state;
        updateProfileChanges.zipCode = values.zipCode.trim();

        if (values.multipleCard) {
            state.accounts?.forEach((acc) => {
                updateProfileChanges.ApplyToAccountHandlesList.push(acc.accountHandle);
            });
        } else {
            updateProfileChanges.ApplyToAccountHandlesList.push(state.accounts![state.activeIndex].accountHandle);
        }

        api.updateProfileAddress(updateProfileChanges, state.activeIndex)
            .then(() => {
                profileSuccess();
            })
            .catch((err) => {
                if (err.response?.status === 400) {
                    let errors = err.response?.data as ValidationErrors;
                    let errorField = Object.keys(errors.errors)[0];
                    let errorMessage = errors.errors[errorField][0];

                    state.setShowAddressApiError(true);
                    state.setAddressApiError(errorMessage);
                    state.setCanContinue(false);
                }
            })
            .finally(() => {
                state.setLoading(false);
                state.setCanContinue(false);
                actions.setSubmitting(false);
            });
    }

    function submitPhone(values: PhoneNumberFormFields, actions: FormikHelpers<PhoneNumberFormFields>) {
        state.setLoading(true);
        state.setCanContinue(false);

        let updateProfileChanges: ProfileUpdatePhone = {
            ...state.profiles[state.activeIndex]!,
            ApplyToAccountHandlesList: []
        };
        updateProfileChanges.cellPhone1 = values.cellPhone1.replace(/[^+\d]+/g, "");
        updateProfileChanges.cellPhone2 = values.cellPhone2.replace(/[^+\d]+/g, "");
        updateProfileChanges.homePhone = values.homePhone.replace(/[^+\d]+/g, "");
        updateProfileChanges.workPhone = values.workPhone.replace(/[^+\d]+/g, "");

        if (values.multipleCard) {
            state.accounts?.forEach((acc) => {
                updateProfileChanges.ApplyToAccountHandlesList.push(acc.accountHandle);
            });
        } else {
            updateProfileChanges.ApplyToAccountHandlesList.push(state.accounts![state.activeIndex].accountHandle);
        }

        api.updateProfilePhone(updateProfileChanges, state.activeIndex)
            .then(() => {
                profileSuccess();
            })
            .catch((err) => {
                if (err.response?.status === 400) {
                    let errors = err.response?.data as ValidationErrors;
                    let errorField = Object.keys(errors.errors)[0];
                    let errorMessage = errors.errors[errorField][0];

                    state.setShowPhoneApiError(true);
                    state.setPhoneApiError(errorMessage);
                    state.setCanContinue(false);
                }
            })
            .finally(() => {
                state.setLoading(false);
                state.setCanContinue(false);
                actions.setSubmitting(false);
            });
    }

    function submitEmail(values: EmailFormFields, actions: FormikHelpers<EmailFormFields>) {
        state.setLoading(true);
        state.setCanContinue(false);
        let updateProfileChanges: ProfileUpdateEmail = {
            ...state.profiles[state.activeIndex]!,
            ApplyToAccountHandlesList: []
        };
        updateProfileChanges.emailAddress = values.emailAddress;

        if (values.multipleCard) {
            state.accounts?.forEach((acc) => {
                updateProfileChanges.ApplyToAccountHandlesList.push(acc.accountHandle);
            });
        } else {
            updateProfileChanges.ApplyToAccountHandlesList.push(state.accounts![state.activeIndex].accountHandle);
        }

        api.updateProfileEmail(updateProfileChanges, state.activeIndex)
            .then(() => {
                profileSuccess();
            })
            .catch((err) => {
                if (err.response?.status === 400) {
                    let errors = err.response?.data as ValidationErrors;
                    let errorField = Object.keys(errors.errors)[0];
                    let errorMessage = errors.errors[errorField][0];

                    state.setShowEmailApiError(true);
                    state.setEmailApiError(errorMessage);
                    state.setCanContinue(false);
                }
            })
            .finally(() => {
                state.setLoading(false);
                state.setCanContinue(false);
                actions.setSubmitting(false);
            });
    }

    function profileSuccess() {
        state.setCanContinue(false);
        state.setLoading(false);
        hooks.getProfiles();
        state.setShowSuccessModal(true);
        state.setUnsavedChanges(false);
    }

    return <UpdateContactInformationView {...state} cards={state.cards} />;
}
