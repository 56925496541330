import { NavigateFunction, useNavigate } from "react-router-dom";
import { useAccountContext } from "../../../api/backend";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";

type CardApplicationViewModel = {
    hooks: CardApplicationHooks;
    state: CardApplicationState;
};

type CardApplicationHooks = {
    navigate: NavigateFunction;
};

export type CardApplicationState = {
    size: Size;
    setAccountIndex: React.Dispatch<React.SetStateAction<number>>;
};

export function useCardApplicationViewModel(): CardApplicationViewModel {
    const navigate = useNavigate();
    const { setAccountIndex } = useAccountContext();
    const { size } = useViewport(true);

    return {
        hooks: { navigate },
        state: { size, setAccountIndex }
    };
}
