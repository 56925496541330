import * as Yup from "yup";
import { IsNullOrEmpty } from "../../../../utility";

export const validationSchema = Yup.object().shape({
    routingNumber: Yup.string()
        .min(9, "Routing number must be 9 digits")
        .max(9, "Routing number must be 9 digits")
        .required("Routing number must be filled in"),
    checkingNumber: Yup.string()
        .test("isRouting", "The Routing number and Checking account number cannot be the same", (value, context) => {
            let routingNumber = context.parent.routingNumber;
            if (!IsNullOrEmpty(routingNumber) && routingNumber === value) return false;
            return true;
        })
        .min(5, "Checking account must be all numbers and between 5 and 17 characters")
        .max(17, "Checking account must be all numbers and between 5 and 17 characters")
        .required("The Checking account number must be filled in"),
    confirmNumber: Yup.string().test(
        "isChecking",
        "Checking account number and Verify checking account must match",
        (value, context) => {
            let checkingNumber = context.parent.checkingNumber;
            if (!IsNullOrEmpty(value) && !IsNullOrEmpty(checkingNumber) && checkingNumber !== value) return false;
            return true;
        }
    )
});
