import { ReactElement } from "react";
import "./modal.scss";
import { ModalModelProps } from "./modalModel";
import { ModalState } from "./modalViewModel";

export function ModalView(props: ModalState & ModalModelProps): ReactElement {
    return (
        <div id="modal" className={props.classes} data-testid="modal">
            <div id={`modal-content${props.size}`}>{props.children}</div>
        </div>
    );
}
