import * as crypto from "crypto-js";
import { HashSecret } from "../res/react-env-variables";

export function SaltPassword(password: string): string {
    let hash = crypto.PBKDF2(password, HashSecret);
    return hash.toString();
}

//NOT For Passwords
export function Encrypt(message: string | Object): string {
    let stringMessage: string;

    if (typeof message === "object") {
        stringMessage = JSON.stringify(message);
    } else {
        stringMessage = message;
    }

    let encrypted = crypto.AES.encrypt(stringMessage, HashSecret);

    return encrypted.toString();
}

export function Decrypt<T>(message: string | undefined): T {
    if (message === undefined) return {} as T;

    try {
        var bytes = crypto.AES.decrypt(message, HashSecret);

        var originalText = bytes.toString(crypto.enc.Utf8);

        if (originalText[0] === "{" && originalText[originalText.length - 1] === "}") {
            return JSON.parse(originalText) as T;
        }

        return originalText as T;
    } catch (error) {
        return {} as T;
    }
}
