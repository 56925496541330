import "abortcontroller-polyfill";
import axios, { AxiosError } from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { ViewportProvider } from "./hooks/useViewport";
import reportWebVitals from "./reportWebVitals";
import { Polyfill } from "./res/polyfill";
import { Router } from "./res/router";

const AbortController = window.AbortController;
const controller = new AbortController();
const signal = controller.signal;

Polyfill();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

axios.interceptors.response.use(
    (response: any) => {
        return response;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

root.render(
    <React.StrictMode>
        <ViewportProvider>
            <RouterProvider router={Router()} />
        </ViewportProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
