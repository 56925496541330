import { AxiosResponse } from "axios";
import { useContext } from "react";
import { ArticleContent, Articles } from ".";
import { useApiQueueContext } from "../../../contexts";
import { KnowledgeManagementContext } from "./knowledgeManagementContext";

export const useKnowledgeManagementContext = () => {
    const context = useContext(KnowledgeManagementContext);
    const client = useKnowledgeManagementClient();

    function onSearch() {
        if (context.searchText.trim().length > 0) {
            context.setLoading(true);

            client
                .Search(context.searchText)
                .then((x) => {
                    context.setArticles(x.data);
                    context.setCurArticles(x.data.searchResults.slice(0, 10));
                    context.setCurPage(1);
                    context.setPages(Math.ceil(x.data.searchResults.length / 10));
                })
                .finally(() => {
                    context.setLoading(false);
                    context.setCurSearch(context.searchText);
                });
        }
    }

    const clear = () => {
        context.setArticles(undefined);
        context.setCurArticles(undefined);
        context.setPages(0);
        context.setCurPage(0);
        context.setCurSearch("");
        context.setSearchText("");
    };

    return { ...context, ...client, onSearch, clear };
};

const useKnowledgeManagementClient = () => {
    const apiQueue = useApiQueueContext();

    async function ArticleSummaries(queryParams: string): Promise<AxiosResponse<Articles, any>> {
        return await apiQueue.Get(`api/v1/knowledgemanagement/articlesummaries?queryParameters=${queryParams}`);
    }

    async function Search(queryParams: string): Promise<AxiosResponse<Articles, any>> {
        return await apiQueue.Get(`api/v1/knowledgemanagement/search?queryParameters=${queryParams}`);
    }

    async function Content(queryParams: string): Promise<AxiosResponse<ArticleContent, any>> {
        return await apiQueue.Get(`api/v1/knowledgemanagement/content?queryParameters=${queryParams}`);
    }

    return {
        ArticleSummaries,
        Search,
        Content
    };
};
