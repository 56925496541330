import { Formik, Form } from "formik";
import { ReactElement } from "react";
import { LoginCard } from "../common/loginCardModel";
import { ButtonPro, UsernameInput } from "../../generic";
import { ShowUsernameState } from "./showUsernameViewModel";
import "./showUsername.scss";

type ShowUsernameViewProps = ShowUsernameState & {
    returnToLogin: Function;
    forgotYourPassword: Function;
};

export function ShowUsernameView(props: ShowUsernameViewProps): ReactElement {
    const initialValues = { username: props.username };

    return (
        <LoginCard id="showUsername" size={props.size} header="Your Username is:" subtitle="">
            <Formik
                initialValues={{ initialValues }}
                onSubmit={(actions) => {
                    props.returnToLogin(actions);
                }}
            >
                <Form className={`loginCard-form${props.size}`} autoComplete="off">
                    <UsernameInput label="Username" value={props.username} readOnly />

                    <div className="loginCard-buttons">
                        <ButtonPro
                            id={`showusername-submit${props.size}`}
                            type="submit"
                            className="showusername-buttonpro"
                        >
                            Return to Login
                        </ButtonPro>

                        <ButtonPro
                            id="showusername-forgotpassword"
                            type="button"
                            variant="neutral"
                            onClick={() => props.forgotYourPassword()}
                            className="showusername-buttonpro"
                        >
                            Forgot Your Password?
                        </ButtonPro>
                    </div>
                </Form>
            </Formik>
        </LoginCard>
    );
}
