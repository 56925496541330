import { useContext, useEffect, useState } from "react";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { UserAccount, useAccountContext, useDigitalActionsContext } from "../../../api/backend";
import { CardNavContext } from "../../../contexts";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";

type CardNavViewModel = {
    hooks: CardNavHooks;
    state: CardNavState;
};

type CardNavHooks = {
    navigate: NavigateFunction;
};

export type CardNavState = {
    size: Size;
    showNav: boolean;
    accounts: UserAccount[];
    accountIndex: number;
    setAccountIndex: React.Dispatch<React.SetStateAction<number>>;
    navKey: string;
    setNavKey: React.Dispatch<React.SetStateAction<string>>;
    paymentActions: boolean | undefined;
    location: Location<any>;
};

export function useCardNavViewModel(): CardNavViewModel {
    const location = useLocation();
    const navigate = useNavigate();
    const { size } = useViewport(true, true);
    const { showNav } = useContext(CardNavContext);
    const { accounts, accountIndex, setAccountIndex } = useAccountContext();
    const [navKey, setNavKey] = useState(`#${location.pathname.substring(location.pathname.lastIndexOf("/") + 1)}`);
    const { actionAllowed } = useDigitalActionsContext();
    const [paymentActions, setPaymentActions] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        setNavKey(`#${location.pathname.substring(location.pathname.lastIndexOf("/") + 1)}`);
    }, [location]);

    useEffect(() => {
        if (accounts) setPaymentActions(actionAllowed("payments", accounts[accountIndex]?.accountHandle));
    }, [accounts, accountIndex]);

    return {
        hooks: {
            navigate
        },
        state: {
            size,
            showNav,
            accounts: accounts!,
            accountIndex,
            setAccountIndex,
            navKey,
            setNavKey,
            paymentActions,
            location
        }
    };
}
