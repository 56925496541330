import { AxiosResponse } from "axios";
import { ReactElement, useContext, useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
    ElectronicDeliveryPreferences,
    EnrollRequest,
    UserAccount,
    useAccountContext,
    useDeliveryPreferencesContext,
    useDigitalActionsContext,
    useSettingsContext
} from "../../../api/backend";
import { CardNavContext } from "../../../contexts";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";

type UpdateDeliveryPreferencesViewModel = {
    hooks: UpdateDeliveryPreferencesHooks;
    state: UpdateDeliveryPreferencesState;
    api: DeliveryPreferencesFormApi;
};

type UpdateDeliveryPreferencesHooks = {
    navigate: NavigateFunction;
    preferences: ElectronicDeliveryPreferences[] | undefined;
};

export type UpdateDeliveryPreferencesState = {
    size: Size;
    isEMessengerDown: boolean;
    accounts: UserAccount[] | undefined;
    rows: ReactElement[];
    setRows: React.Dispatch<React.SetStateAction<ReactElement[]>>;
    confirmRows: ReactElement[];
    setConfirmRows: React.Dispatch<React.SetStateAction<ReactElement[]>>;
    canEditPreferences: boolean;
    editPreferences: boolean;
    setEditPreferences: React.Dispatch<React.SetStateAction<boolean>>;
    canSave: boolean;
    setCanSave: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    initialValues: any;
    setInitialValues: React.Dispatch<React.SetStateAction<any>>;
    selectedValues: any;
    setSelectedValues: React.Dispatch<React.SetStateAction<any>>;
    showDelPrefTermsModal: boolean;
    setShowDelPrefTermsModal: React.Dispatch<React.SetStateAction<boolean>>;
    showDelPrefConfirmModal: boolean;
    setShowDelPrefConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
    timeoutId: NodeJS.Timeout | undefined;
    setTimeoutId: React.Dispatch<React.SetStateAction<NodeJS.Timeout | undefined>>;
    tempDeliveryPreferences: ElectronicDeliveryPreferences[] | undefined;
    setTempDeliveryPreferences: React.Dispatch<React.SetStateAction<ElectronicDeliveryPreferences[] | undefined>>;
};

type DeliveryPreferencesFormApi = {
    getPreferences: () => Promise<AxiosResponse<ElectronicDeliveryPreferences[], any>>;
    Enroll: (request: EnrollRequest) => Promise<AxiosResponse<any, any>>;
};

export function useUpdateDeliveryPreferencesViewModel(): UpdateDeliveryPreferencesViewModel {
    const { size } = useViewport(true, true);
    const navigate = useNavigate();
    const { appSettings } = useSettingsContext();
    const { loading: dpLoading, preferences, Get: getPreferences, Enroll } = useDeliveryPreferencesContext();
    const { accounts } = useAccountContext();
    const { setShowNav } = useContext(CardNavContext);
    const isEMessengerDown: boolean = appSettings?.featureFlags?.isEMessengerDown;
    const [rows, setRows] = useState([] as ReactElement[]);
    const [confirmRows, setConfirmRows] = useState([] as ReactElement[]);
    const [canEditPreferences, setCanEditPreferences] = useState(true);
    const [editPreferences, setEditPreferences] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [initialValues, setInitialValues] = useState<any>({});
    const [selectedValues, setSelectedValues] = useState<any>({});
    const [showDelPrefTermsModal, setShowDelPrefTermsModal] = useState(false);
    const [showDelPrefConfirmModal, setShowDelPrefConfirmModal] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    const [tempDeliveryPreferences, setTempDeliveryPreferences] = useState<ElectronicDeliveryPreferences[]>();
    const { actionAllowed } = useDigitalActionsContext();

    useEffect(() => {
        if (!actionAllowed("eLetterEnroll") && !actionAllowed("eStatementEnroll")) {
            navigate(`/account/dashboard`, { relative: "path" });
        }

        if (!isEMessengerDown) {
            getPreferences();
        }

        setShowNav(false);
    }, []);

    useEffect(() => {
        setCanEditPreferences(
            preferences?.length !==
                preferences?.filter((d) => {
                    return (
                        d.eStatementsEnrollment.enrollmentStatus === "Processing" ||
                        d.eLettersEnrollment.enrollmentStatus === "Processing"
                    );
                }).length
        );

        let iv = accounts?.map((a) => {
            let eStatementAllowed = actionAllowed("eStatementEnroll", a.accountHandle);
            let eLetterAllowed = actionAllowed("eLetterEnroll", a.accountHandle);
            let preference = preferences?.filter((p) => p.accountHandle === a.accountHandle)[0];
            return eStatementAllowed
                ? preference?.eStatementsEnrollment.enrollmentStatus
                : eLetterAllowed
                  ? preference?.eLettersEnrollment.enrollmentStatus
                  : "NotEnrolled";
        });

        let ivs = "{";
        for (var i = 0; i < iv!.length; i++) {
            ivs += ` "checkBox-${i}" : ${iv![i] === "Enrolled" ? "true" : "false"}${i < iv!.length - 1 ? "," : ""}`;
        }
        ivs = ivs += "}";

        setInitialValues(JSON.parse(ivs));
    }, [accounts, preferences]);

    return {
        hooks: { navigate, preferences },
        state: {
            size,
            isEMessengerDown,
            accounts,
            rows,
            setRows,
            confirmRows,
            setConfirmRows,
            canEditPreferences,
            editPreferences,
            setEditPreferences,
            canSave,
            setCanSave,
            loading: dpLoading,
            initialValues,
            setInitialValues,
            selectedValues,
            setSelectedValues,
            showDelPrefTermsModal,
            setShowDelPrefTermsModal,
            showDelPrefConfirmModal,
            setShowDelPrefConfirmModal,
            timeoutId,
            setTimeoutId,
            tempDeliveryPreferences,
            setTempDeliveryPreferences
        },
        api: { getPreferences, Enroll }
    };
}
