import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { GenericModal, ModalProps } from "../../../generic";

type InvalidEnrollSession = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export function InvalidEnrollSession(props: ModalProps & InvalidEnrollSession): ReactElement {
    const navigate = useNavigate();

    const modalProps = {
        show: props.show,
        headerText: "Something Went Wrong",
        bodyText: "We were unable to process your request. Please try again.",
        primaryButton: {
            text: "Close",
            action: () => {
                props.setShow(false);
                navigate("/auth/login");
            }
        }
    };

    return <GenericModal {...modalProps} />;
}
