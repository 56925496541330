import { EnrollInPaperlessAlertView } from "./enrollinPaperlessAlertView";
import { useEnrollInPaperlessAlertViewModel } from "./enrollinPaperlessAlertViewModel";

export function EnrollInPaperlessAlert(): React.ReactElement {
    const { state, hooks } = useEnrollInPaperlessAlertViewModel();

    function onMore() {
        hooks.navigate("/profile/delivery-options");
    }

    return <EnrollInPaperlessAlertView {...state} onMore={onMore} />;
}
