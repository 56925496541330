import dayjs, { Dayjs } from "dayjs";
import { ReactElement, useEffect } from "react";
import { useAccountContext } from "../../../../api/backend";
import { GenericModal, Modal, ModalProps } from "../../../generic";
import { AmountSelection } from "./paymentAmountOption/paymentAmountOption";
import SchedulePaymentView from "./schedulePaymentView";
import { useSchedulePaymentViewModel } from "./schedulePaymentViewModel";

export type SchedulePaymentProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowReview: React.Dispatch<React.SetStateAction<boolean>>;
    setShowAddBank: React.Dispatch<React.SetStateAction<boolean>>;
    paymentAccountId: number;
    setPaymentAccountId: React.Dispatch<React.SetStateAction<number>>;
    amountSelection: AmountSelection;
    setAmountSelection: React.Dispatch<React.SetStateAction<AmountSelection>>;
    amount: string;
    setAmount: React.Dispatch<React.SetStateAction<string>>;
    date: Dayjs | undefined;
    setDate: React.Dispatch<React.SetStateAction<Dayjs | undefined>>;
};

export function SchedulePaymentModal(props: ModalProps & SchedulePaymentProps): ReactElement {
    const { accounts, accountIndex } = useAccountContext();
    const account = accounts[accountIndex];
    let showNormalPaymentModal = false;

    if (account) {
        showNormalPaymentModal = account.currentBalance > 0;
    }

    return (
        <>
            {showNormalPaymentModal ? (
                <Modal show={props.show}>
                    <SchedulePaymentModel {...props} />
                </Modal>
            ) : (
                <NoSchedulePaymentModal {...props} />
            )}
        </>
    );
}

export function NoSchedulePaymentModal(props: ModalProps & SchedulePaymentProps): ReactElement {
    const state = {
        headerText: "No Payment Due",
        bodyText: "There is no balance on this account",
        primaryButton: {
            text: "Okay",
            action: () => {
                props.setShow(false);
            }
        }
    };

    return <GenericModal show={props.show} {...state} />;
}

export function SchedulePaymentModel(props: SchedulePaymentProps): ReactElement {
    const { hooks, state, api } = useSchedulePaymentViewModel(props);

    useEffect(() => {
        hooks.insertSwrveEvent("Web_MakePayment_Screen");

        if (state.paymentAccounts.length === 0) {
            api.paymentAccountsGet();
        }
    }, []);

    useEffect(() => {
        if (state.paymentAccounts?.length === 1 && props.paymentAccountId === 0) {
            props.setPaymentAccountId(state.paymentAccounts[0].bankAccountId);
        }

        const now = dayjs.tz();
        if (now.isSame(props.date, "date") && now.hour() >= 17) {
            state.setAfter5("Payments made after 5:00 PM CT must be dated for the following day");
        } else {
            state.setAfter5("");
        }
    }, [props.date, state.paymentAccounts]);

    function onSelectionChanged(id: AmountSelection) {
        state.setPaymentAmountTouched(true);
        props.setAmountSelection(id);
        switch (id) {
            case "min":
                props.setAmount(state.account.minimumAmountDue.toString());
                break;
            case "statement":
                props.setAmount(state.account.lastStatementBalance.toString());
                break;
            case "current":
                props.setAmount(state.account.currentBalance.toString());
                break;
            case "other":
                if (props.amountSelection !== "other") props.setAmount("");
                break;
        }
    }

    function onPaymentAccountClick() {
        state.setPaymentAccountTouched(true);
    }

    function onAddPaymentAccount() {
        props.setShow(false);
        props.setShowAddBank(true);
    }

    function onCancel() {
        props.setPaymentAccountId(0);
        props.setAmountSelection("");
        props.setAmount("");
        props.setDate(undefined);
        props.setShow(false);
    }

    function onSubmit() {
        const now = dayjs.tz();
        if (now.isSame(props.date, "date") && now.hour() >= 17) {
            state.setAfter5("Payments made after 5:00 PM CT must be dated for the following day");
            return;
        }

        props.setShow(false);
        props.setShowReview(true);
    }

    return (
        <SchedulePaymentView
            {...props}
            {...state}
            onSelectionChanged={onSelectionChanged}
            onPaymentAccountClick={onPaymentAccountClick}
            onAddPaymentAccount={onAddPaymentAccount}
            onCancel={onCancel}
            onSubmit={onSubmit}
        />
    );
}
