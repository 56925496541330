import { AxiosResponse } from "axios";
import { ReactElement, useMemo, useState } from "react";
import { BankAccountGetResponse, usePaymentAccountContext } from "../../../api/backend";
import { PaymentAccountsRow } from "./paymentAccountsRow/paymentAccountsRow";

export type PaymentAccountState = {
    loading: boolean;
    viewMore: boolean;
    setViewMore: React.Dispatch<React.SetStateAction<boolean>>;
    paymentAccounts: BankAccountGetResponse[];
    rows: ReactElement[];
    showAdd: boolean;
    setShowAdd: React.Dispatch<React.SetStateAction<boolean>>;
    showDelete: boolean;
    setShowDelete: React.Dispatch<React.SetStateAction<boolean>>;
    deletedAccount: BankAccountGetResponse | undefined;
    setDeletedAccount: React.Dispatch<React.SetStateAction<BankAccountGetResponse | undefined>>;
    showSuccess: boolean;
    setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
};

type PaymentAccountApi = {
    Get: () => Promise<AxiosResponse<BankAccountGetResponse[], any>>;
};

const usePaymentAccountState = (): PaymentAccountState => {
    const { loading, paymentAccounts } = usePaymentAccountContext();
    const [viewMore, setViewMore] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deletedAccount, setDeletedAccount] = useState<BankAccountGetResponse>();
    const [showSuccess, setShowSuccess] = useState(false);

    const displayedAccounts = useMemo(() => {
        return viewMore ? paymentAccounts : paymentAccounts.slice(0, 3);
    }, [paymentAccounts, viewMore]);

    const rows = useMemo(() => {
        return displayedAccounts.map((v, i) => (
            <PaymentAccountsRow
                key={v.bankAccountId.toString()}
                index={i}
                paymentAccount={v}
                onDelete={onDeleteAccount}
            />
        ));
    }, [displayedAccounts]);

    function onDeleteAccount(paymentAccount: BankAccountGetResponse) {
        setDeletedAccount(JSON.parse(JSON.stringify(paymentAccount)));
        setShowDelete(true);
    }

    return {
        loading,
        viewMore,
        setViewMore,
        paymentAccounts,
        rows,
        showAdd,
        setShowAdd,
        showDelete,
        setShowDelete,
        deletedAccount,
        setDeletedAccount,
        showSuccess,
        setShowSuccess
    };
};

const usePaymentAccountApi = (): PaymentAccountApi => {
    const { Get } = usePaymentAccountContext();

    return {
        Get
    };
};

export const usePaymentAccountViewModel = () => {
    const state = usePaymentAccountState();
    const api = usePaymentAccountApi();

    return {
        state,
        api
    };
};
