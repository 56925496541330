export function GetAreaCode(phoneNumber: string | undefined): string {
    if (Number(phoneNumber)) {
        let val = Number(phoneNumber).toString();
        if (val.length > 9) {
            val = val?.substring(val.length - 10, 3);
        } else {
            return "";
        }
        return val;
    }

    return "";
}

export function GetPrefix(phoneNumber: string | undefined): string {
    if (Number(phoneNumber)) {
        let val = Number(phoneNumber).toString();
        if (val.length > 6) {
            val = val?.substring(val.length - 7, val.length - 7 + 3);
        } else {
            return "";
        }
        return val;
    }

    return "";
}

export function GetSuffix(phoneNumber: string | undefined): string {
    if (Number(phoneNumber)) {
        let val = Number(phoneNumber).toString();
        if (val.length > 3) {
            val = val?.substring(val.length - 4);
        } else {
            return "";
        }
        return val;
    }

    return "";
}

export type FormattedPhone = {
    formatted: string;
    areaCode: string;
    prefix: string;
    suffix: string;
};

export function FormatPhone(phoneNumber: string | undefined): FormattedPhone {
    let areaCode = GetAreaCode(phoneNumber);
    let prefix = GetPrefix(phoneNumber);
    let suffix = GetSuffix(phoneNumber);
    let formatted = `(${areaCode}) ${prefix}-${suffix}`;

    return { formatted, areaCode, prefix, suffix };
}
