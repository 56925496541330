import { ReactElement } from "react";
import { PrivateView } from "../..";
import { UnAuthBlocksContext, useUnAuthBlocksProps } from "../../../contexts";
import { PublicView, PublicViewClean } from "./publicView";
import { usePublicViewModel } from "./publicViewModel";

export type PublicViewProps = {
    clean?: boolean;
    children?: ReactElement;
};

export function PrivateViewProvider(props: PublicViewProps): ReactElement {
    if (props.clean) {
        return <PublicViewClean {...props} />;
    } else {
        return <PublicViewContext {...props} />;
    }
}

export function PublicViewContext(props: PublicViewProps): ReactElement {
    const blockProps = useUnAuthBlocksProps();

    return (
        <UnAuthBlocksContext.Provider value={blockProps}>
            <PublicViewModel {...props} />
        </UnAuthBlocksContext.Provider>
    );
}

function PublicViewModel(props: PublicViewProps): ReactElement {
    const { state } = usePublicViewModel();

    return state.loggedIn ? <PrivateView {...props} /> : <PublicView {...props} {...state} />;
}
