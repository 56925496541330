import { useState } from "react";
import { useSessionStorage } from "../../../hooks";

type NeedAssistanceViewModel = {
    state: NeedAssistanceState;
};

export type NeedAssistanceState = {
    showEnter: boolean;
    setShowEnter: React.Dispatch<React.SetStateAction<boolean>>;
    showTrust: boolean;
    setShowTrust: React.Dispatch<React.SetStateAction<boolean>>;
    showError: boolean;
    setShowError: React.Dispatch<React.SetStateAction<boolean>>;
    showPinExpired: boolean;
    setShowPinExpired: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useNeedAssistanceViewModel(): NeedAssistanceViewModel {
    const [storage] = useSessionStorage(["showTrust"]);
    const [showEnter, setShowEnter] = useState(false);
    const [showTrust, setShowTrust] = useState(storage["showTrust"] === "true" ? true : false);
    const [showError, setShowError] = useState(false);
    const [showPinExpired, setShowPinExpired] = useState(false);

    return {
        state: {
            showEnter,
            setShowEnter,
            showTrust,
            setShowTrust,
            showError,
            setShowError,
            showPinExpired,
            setShowPinExpired
        }
    };
}
