import { ReactElement } from "react";
import { NewPasswordSplashState } from "./newPasswordSplashViewModel";
import "./newPasswordSplash.scss";

type NewPasswordSplashViewProps = NewPasswordSplashState;

export function NewPasswordSplashView(props: NewPasswordSplashViewProps): ReactElement {
    return (
        <div id="newPasswordSplash">
            <div id={`newPasswordSplash-page${props.size}`}>
                <div id="newPasswordSplash-content">
                    <div id={`newPasswordSplash-header${props.size}`}>
                        <div id="newPasswordSplash-headerText">New Password Created!</div>
                    </div>
                    <div>
                        <div id="newPasswordSplash-subtitle">We're logging you in...</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
