import { useState } from "react";

type MfaAssistanceEnterCodeViewModel = {
    state: MfaAssistanceEnterCodeState;
};

export type MfaAssistanceEnterCodeState = {
    showCancelModal: boolean;
    setShowCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useMfaAssistanceEnterCodeViewModel(): MfaAssistanceEnterCodeViewModel {
    const [showCancelModal, setShowCancelModal] = useState(false);

    return {
        state: { showCancelModal, setShowCancelModal }
    };
}
