import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { HelpLine } from "../../../../utility";
import { GenericModal, ModalProps } from "../../../generic";

type InvalidMFA3TimesProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    isCorpCard: boolean;
};

export function InvalidMFA3Times(props: ModalProps & InvalidMFA3TimesProps): ReactElement {
    const navigate = useNavigate();

    const modalProps = {
        show: props.show,
        headerText: "Online Account Temporarily Locked",
        bodyText: `Access to PREMIER Online has been restricted. Please contact Customer Service at ${HelpLine(props.isCorpCard)}.`,
        primaryButton: {
            text: "Close",
            action: () => {
                props.setShow(false);
                navigate("/auth/login");
            }
        }
    };

    return <GenericModal {...modalProps} />;
}
