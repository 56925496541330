import { ReactElement, useEffect } from "react";
import PCPEnrollmentStatusView from "./pcpEnrollmentStatusView";
import { EnrollmentStatusArray, usePCPEnrollmentStatusViewModel } from "./pcpEnrollmentStatusViewModel";

export function PCPEnrollmentStatusModel(): ReactElement {
    const { state, api, hooks } = usePCPEnrollmentStatusViewModel();

    useEffect(() => {
        if (state.premierCreditProtectionEnrollment === undefined || !state.showSuccessModal) api.loadPCPStatus();
    }, [state.setShowSuccessModal]);

    useEffect(() => {
        if (!hooks.actionAllowed("premierCreditProtection", state.accounts![state.accountIndex].accountHandle)) {
            hooks.navigate(`/account/dashboard`, { relative: "path" });
        }
    });

    function onSubmit() {
        state.setShowOverSixtyTwoModal(true);
    }

    function check(index: number) {
        if (
            state.enrollMode &&
            !state.premierCreditProtectionEnrollment?.accounts[index].enrolled &&
            state.premierCreditProtectionEnrollment?.accounts[index].eligible
        ) {
            let changed = false;
            let holdArr = [...state.enrollmentArray!];
            holdArr[index].checked = !holdArr[index].checked;
            state.premierCreditProtectionEnrollment?.accounts.forEach((account, accountIndex) => {
                if (holdArr[accountIndex].checked !== account.enrolled) changed = true;
            });
            state.setTouched(changed);
            state.setEnrollmentArray(holdArr);
        }
    }

    function onCancel() {
        state.setTouched(false);
        state.setEnrollMode(false);
        let enrollmnetArray: EnrollmentStatusArray[] = [];
        state.premierCreditProtectionEnrollment?.accounts.forEach((account, index) => {
            enrollmnetArray.push({
                checked: account.enrolled,
                last4: state.accounts![index].maskedAccountNumber.slice(-4),
                accent: state.accounts![index].accent,
                accountHandle: state.accounts![index].accountHandle,
                enrolled: account.enrolled
            });
        });
        state.setEnrollmentArray(enrollmnetArray);
    }

    return <PCPEnrollmentStatusView {...state} {...api} onSubmit={onSubmit} check={check} onCancel={onCancel} />;
}
