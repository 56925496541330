import React, { LegacyRef, ReactElement } from "react";
import { Dropdown } from "react-bootstrap";
import { Loading } from "../../..";
import { GetFormattedDate } from "../../../../utility";
import { Statements } from "../../../generic/icons";
import { StatementsRowProps } from "./statementsRow";
import "./statementsRow.scss";
import { StatementsRowState } from "./statementsRowViewModel";

export type StatementsRowViewProps = StatementsRowProps &
    StatementsRowState & {
        openPDF: Function;
        downloadPDF: Function;
    };

const CustomToggle = React.forwardRef(
    (
        { children, onClick }: { children: ReactElement; onClick: Function },
        ref: LegacyRef<HTMLAnchorElement> | undefined
    ) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            <div className="statementsRow-menu">{children}</div>
        </a>
    )
);

export function StatementsRowView(props: StatementsRowViewProps): ReactElement {
    return (
        <div className="statementsRow">
            <div className="statementsRow-collapsed">
                <div className="statementsRow-left">
                    <Statements className="statement-icon" />

                    <p className="statementsRow-date">
                        {GetFormattedDate(props.statement.statementDate?.toString(), true)}
                    </p>
                </div>
                <div className="statementsRow-right">
                    <Dropdown className="statementsRow-dropdown">
                        <Dropdown.Toggle as={CustomToggle} className="statementsRow-dropdown-toggle">
                            <div className="statementsRow-viewmenu">View</div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="statementsRow-dropdown-menu">
                            <Dropdown.Item
                                className="statementsRow-dropdown-item statementsRow-bottom-border"
                                key={0}
                                eventKey={0}
                                onClick={() => {
                                    props.openPDF();
                                }}
                            >
                                Open as PDF
                            </Dropdown.Item>
                            <Dropdown.Item
                                className="statementsRow-dropdown-item"
                                key={1}
                                eventKey={1}
                                onClick={() => {
                                    props.downloadPDF();
                                }}
                            >
                                Download PDF
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <Loading show={props.loading} />
        </div>
    );
}
