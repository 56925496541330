import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function PaperlessNotEnrolled(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.2502 21.1576C8.25313 21.1576 6.33785 20.3642 4.9257 18.9521C3.51355 17.5399 2.72021 15.6247 2.72021 13.6276C2.72021 2.70757 15.8602 8.31757 22.1902 3.06757C22.2625 3.02338 22.3455 3 22.4302 3C22.5149 3 22.598 3.02338 22.6702 3.06757C22.7478 3.1011 22.8158 3.15337 22.8682 3.2197C22.9205 3.28603 22.9556 3.36434 22.9702 3.44757C24.9002 14.7076 16.5702 21.1576 10.2502 21.1576Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.26 11.6177C10.1393 12.4768 7.21523 13.9338 4.65 15.9077L0.75 19.2377"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
