import dayjs from "dayjs";
import { ReactElement } from "react";
import { TransactionDetails } from "../../../../api/backend";
import { DownloadDetails, OFXExporter } from "../../../../utility/ofxExporter";
import { ActivityDownloadView } from "./activityDownloadView";
import { useActivityDownloadViewModel } from "./activityDownloadViewModel";

export type ActivityDownloadProps = {
    filteredHistory: TransactionDetails[];
};

export function ActivityDownload(props: ActivityDownloadProps): ReactElement {
    const { state } = useActivityDownloadViewModel(props);

    function exportCSV(): Promise<DownloadDetails[]> {
        return Promise.resolve(state.details);
    }

    function exportOFX() {
        let fileContent = OFXExporter(state.details, state.accountId, 0, dayjs());

        const element = document.createElement("a");
        const file = new Blob([fileContent], { type: "text/ofx" });
        element.href = URL.createObjectURL(file);
        element.download = "MyPremierCreditcardDownload.ofx";
        document.body.appendChild(element);
        element.click();
    }

    return <ActivityDownloadView {...props} {...state} exportCSV={exportCSV} exportOFX={exportOFX} />;
}
