import { CmsToolTip, useAccountContext, UserAccount } from "../../../api/backend";
import { useTooltipCms, useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { ShowPastDueAlert } from "../../../utility";

type PaymentHubViewModel = {
    state: PaymentHubState;
};

export type PaymentHubState = {
    size: Size;
    cms: CmsToolTip;
    account: UserAccount | undefined;
    showAlert: boolean;
    showBalance: boolean;
};

const usePaymentHubState = (): PaymentHubState => {
    const { size } = useViewport(true, true);
    const { accounts, accountIndex } = useAccountContext();
    const { getTooltip } = useTooltipCms();
    const cms = getTooltip("totalMinDueToolTip");
    const account = accounts[accountIndex];
    const showAlert = ShowPastDueAlert(account?.paymentPastDue, account?.status, account?.seeStatementMessage);
    const showBalance = account?.status === "R";

    return { size, cms, account, showAlert, showBalance };
};

export function usePaymentHubViewModel(): PaymentHubViewModel {
    const state = usePaymentHubState();

    return {
        state
    };
}
