import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function CalendarDollar(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0.75 3.75H23.25V23.25H0.75V3.75Z" strokeWidth="1.5" strokeLinejoin="round" />
            <path d="M0.75 9.75H23.25" strokeWidth="1.5" strokeLinejoin="round" />
            <path d="M6.75 6V0.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.25 6V0.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M13.5001 13.5H11.4671C11.1551 13.4996 10.8527 13.608 10.6119 13.8065C10.3712 14.0049 10.2071 14.2811 10.1479 14.5874C10.0887 14.8938 10.1381 15.2112 10.2876 15.485C10.4371 15.7589 10.6774 15.9721 10.9671 16.088L13.0311 16.913C13.3201 17.0293 13.5597 17.2426 13.7087 17.5162C13.8577 17.7898 13.9069 18.1068 13.8478 18.4127C13.7886 18.7186 13.6249 18.9944 13.3847 19.1928C13.1444 19.3912 12.8427 19.4998 12.5311 19.5H10.5001"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M12 13.5V12.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 20.25V19.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
