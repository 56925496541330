import { ReactElement, useEffect } from "react";
import { Error500View } from "./error500View";
import { useError500ViewModel } from "./error500ViewModel";

export function Error500Model(): ReactElement {
    const { hooks, state } = useError500ViewModel();

    useEffect(() => {
        if (state.location.pathname.toLowerCase() !== "/error500") {
            hooks.navigate("/Error500");
        }
    }, []);

    function onOk() {
        if (state.loggedIn) {
            hooks.navigate("/account/dashboard");
        } else {
            hooks.navigate("/auth/login");
        }
    }

    return <Error500View {...state} onOk={onOk} />;
}
