import { ReactElement } from "react";
import { Dropdown, DropdownItem } from "react-bootstrap";
import { Add, ArrowDown } from "../../../../generic/icons";
import { SelectPaymentAccountProps } from "./selectPaymentAccount";
import "./selectPaymentAccount.scss";
import { SelectPaymentAccountState } from "./selectPaymentAccountViewModel";

type SelectPaymentAccountViewProps = SelectPaymentAccountProps & SelectPaymentAccountState;

export function SelectPaymentAccountView(props: SelectPaymentAccountViewProps): ReactElement {
    return (
        <Dropdown
            align="end"
            id="selectPaymentAccount-dropdown"
            data-testid={"onPaymentAccountClickButton"}
            onClick={() => props.onPaymentAccountClick()}
        >
            <Dropdown.Toggle
                className={`selectPaymentAccount-toggle${props.paymentAccountId !== 0 ? "Selected" : ""}`}
                name="paymentAccount"
                as={"div"}
            >
                <p>{props.selected}</p>
                <ArrowDown />
            </Dropdown.Toggle>
            <Dropdown.Menu className="selectPaymentAccount-menu">
                {props.rows}
                {props.rows.length > 0 ? <Dropdown.Divider className="selectPaymentAccount-divider" /> : <></>}
                <DropdownItem
                    eventKey={props.rows.length}
                    className="selectPaymentAccount-itemAdd"
                    data-testid={"onAddPaymentAccountButton"}
                    onClick={() => props.onAddPaymentAccount()}
                >
                    <Add />
                    <p>Add a payment account</p>
                </DropdownItem>
            </Dropdown.Menu>
        </Dropdown>
    );
}
