import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";

type EnrollTermsSplashViewModel = {
    state: EnrollTermsSplashState;
};

export type EnrollTermsSplashState = {
    size: Size;
};

export function useEnrollTermsSplashViewModel(): EnrollTermsSplashViewModel {
    const { size } = useViewport(true, true);

    return {
        state: {
            size
        }
    };
}
