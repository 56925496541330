import { ReactElement, useState } from "react";
import { IconTest, PalletTest } from "..";
import { Env } from "../../res/react-env-variables";
import { ButtonPro } from "../generic";
import { AlertTest } from "./alertTest/alertTest";
import { ButtonTest } from "./buttonTest/buttonTest";
import { CalendarTest } from "./calendarTest/calendarTest";
import "./devTools.scss";
import { LoadingTest } from "./loadingTest/loadingTest";
import { TypographyTest } from "./typographyTest/typographyTest";

export function DevTools(): React.ReactElement {
    const [content, setContent] = useState(
        "alerts" as "alerts" | "buttons" | "icons" | "colors" | "typography" | "calendar" | "loading"
    );

    return (
        <>
            {Env === "Development" ? (
                <div id="devToolsMenu">
                    <div id="devToolsMenu-card">
                        <div id="devToolsMenu-header">
                            <ButtonPro variant="neutral" content={"Alerts"} onClick={() => setContent("alerts")} />
                            <ButtonPro variant="neutral" content={"Buttons"} onClick={() => setContent("buttons")} />
                            <ButtonPro variant="neutral" content={"Icons"} onClick={() => setContent("icons")} />
                            <ButtonPro variant="neutral" content={"Colors"} onClick={() => setContent("colors")} />
                            <ButtonPro
                                variant="neutral"
                                content={"Typography"}
                                onClick={() => setContent("typography")}
                            />
                            <ButtonPro variant="neutral" content={"Calendar"} onClick={() => setContent("calendar")} />
                            <ButtonPro variant="neutral" content={"Loading"} onClick={() => setContent("loading")} />
                        </div>
                        <div id="devToolsMenu-content">{getContent(content)}</div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

const getContent = (
    value: "alerts" | "buttons" | "icons" | "colors" | "typography" | "calendar" | "loading"
): ReactElement => {
    switch (value) {
        case "alerts":
            return <AlertTest />;
        case "buttons":
            return <ButtonTest />;
        case "icons":
            return <IconTest />;
        case "colors":
            return <PalletTest />;
        case "typography":
            return <TypographyTest />;
        case "calendar":
            return <CalendarTest />;
        case "loading":
            return <LoadingTest />;
    }

    return <></>;
};
