import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Info(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    let svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                strokeWidth="1.5"
            />
            <path
                d="M14 16H13.4C13.0817 16 12.7765 15.8683 12.5515 15.6339C12.3264 15.3995 12.2 15.0815 12.2 14.75V11.625C12.2 11.4592 12.1368 11.3003 12.0243 11.1831C11.9117 11.0658 11.7591 11 11.6 11H11"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.625 6.75C11.5508 6.75 11.4783 6.77199 11.4167 6.8132C11.355 6.8544 11.3069 6.91297 11.2785 6.98149C11.2502 7.05002 11.2427 7.12542 11.2572 7.19816C11.2717 7.2709 11.3074 7.33772 11.3598 7.39016C11.4123 7.44261 11.4791 7.47833 11.5518 7.49279C11.6246 7.50726 11.7 7.49984 11.7685 7.47145C11.837 7.44307 11.8956 7.39501 11.9368 7.33334C11.978 7.27167 12 7.19917 12 7.125C12 7.02554 11.9605 6.93016 11.8902 6.85984C11.8198 6.78951 11.7245 6.75 11.625 6.75Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
