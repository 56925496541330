import { NavigateFunction, useNavigate } from "react-router-dom";
import { useAccountContext } from "../../../../api/backend";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";

type DashboardCardHeaderViewModel = {
    hooks: DashboardCardHeaderHooks;
    state: DashboardCardHeaderState;
};

type DashboardCardHeaderHooks = {
    navigate: NavigateFunction;
};

export type DashboardCardHeaderState = {
    size: Size;
    setAccountIndex: React.Dispatch<React.SetStateAction<number>>;
};

export function useDashboardCardHeaderViewModel(): DashboardCardHeaderViewModel {
    const { size } = useViewport(false, true);
    const { setAccountIndex } = useAccountContext();
    const navigate = useNavigate();

    return {
        hooks: { navigate },
        state: { size, setAccountIndex }
    };
}
