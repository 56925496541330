import { AxiosResponse } from "axios";
import { ReactElement, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
    ContextualAlertDetails,
    UserAccount,
    useAccountContext,
    useContextualMessagingContext
} from "../../../../api/backend";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { GenericModalProps } from "../../../generic";
import { ManageAlertsTextProps } from "./manageAlertsTextsModel";

type ManageAlertsTextsViewModel = {
    hooks: ManageAlertsTextsHooks;
    state: ManageAlertsTextsState;
    api: ManageAlertsTextsApi;
};

type ManageAlertsTextsHooks = {
    navigate: NavigateFunction;
};

export type ManageAlertsTextsState = {
    modalErrorProps: GenericModalProps;
    showModalError: boolean;
    setShowModalError: React.Dispatch<React.SetStateAction<boolean>>;
    modalUpdateSpecificAlertsSuccessProps: GenericModalProps;
    showModalUpdateSpecificAlertsSuccess: boolean;
    setShowModalUpdateSpecificAlertsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    showModalCancelWithoutSaving: boolean;
    setShowModalCancelWithoutSaving: React.Dispatch<React.SetStateAction<boolean>>;
    modalCancelWithoutSavingProps: GenericModalProps;
    saveSpecificAlertsChanges: boolean;
    setSaveSpecificAlertsChanges: React.Dispatch<React.SetStateAction<boolean>>;
    anyThresholdItemErrors: boolean;
    setAnyThresholdItemErrors: React.Dispatch<React.SetStateAction<boolean>>;
    accounts: UserAccount[] | undefined;
    accountIndex: number;
    size: Size;
    displayedAlerts: ContextualAlertDetails[];
    setDisplayedAlerts: React.Dispatch<React.SetStateAction<ContextualAlertDetails[]>>;
    rowsAlerts: ReactElement[];
    setRowsAlerts: React.Dispatch<React.SetStateAction<ReactElement[]>>;
    rowsAlertsEdit: ReactElement[];
    setRowsAlertsEdit: React.Dispatch<React.SetStateAction<ReactElement[]>>;
};

export type ManageAlertsTextsApi = {
    postManageSpecificAlerts: (alertstoPost: ContextualAlertDetails[]) => Promise<AxiosResponse<any, any>>;
};

export function useManageAlertsTextsViewModel(props: ManageAlertsTextProps): ManageAlertsTextsViewModel {
    const navigate = useNavigate();
    const { size } = useViewport(false, true);
    const [showModalError, setShowModalError] = useState(false);
    const [showModalUpdateSpecificAlertsSuccess, setShowModalUpdateSpecificAlertsSuccess] = useState(false);
    const [showModalCancelWithoutSaving, setShowModalCancelWithoutSaving] = useState(false);
    const { AlertsPost } = useContextualMessagingContext();
    const { accounts, accountIndex } = useAccountContext();
    const [saveSpecificAlertsChanges, setSaveSpecificAlertsChanges] = useState(false);
    const [anyThresholdItemErrors, setAnyThresholdItemErrors] = useState(false);
    const [displayedAlerts, setDisplayedAlerts] = useState([] as ContextualAlertDetails[]);
    const [rowsAlerts, setRowsAlerts] = useState([] as ReactElement[]);
    const [rowsAlertsEdit, setRowsAlertsEdit] = useState([] as ReactElement[]);

    //Updates specific alerts
    async function postManageSpecificAlerts(alertstoPost: ContextualAlertDetails[]): Promise<AxiosResponse<any, any>> {
        return await AlertsPost({
            accountHandle: accounts![accountIndex].accountHandle,
            alerts: alertstoPost
        });
    }

    const modalErrorProps = {
        headerText: "Something Went Wrong",
        bodyText: "We're sorry, something went wrong. Please try again.",
        primaryButton: {
            text: "Close",
            action: () => {
                setShowModalError(false);
                navigate(`/account/summary/${accountIndex}/manage-alerts`);
            }
        }
    } as GenericModalProps;

    const modalCancelWithoutSavingProps = {
        headerText: "Discard Changes?",
        bodyText: "You have unsaved changes.",
        primaryButton: {
            text: "Stay on Page",
            action: () => {
                setShowModalCancelWithoutSaving(false);
            }
        },
        secondaryButton: {
            text: "Discard",
            variant: "neutral",
            action: () => {
                props.setCanDiscardSpecificAlertsChanges(true);
                setShowModalCancelWithoutSaving(false);
                props.setIsEditingSpecificAlertsChanged(false);
                props.setIsEditingSpecificAlerts(false);
                props.onCancelEditTextAlerts();
            }
        }
    } as GenericModalProps;

    const modalUpdateSpecificAlertsSuccessProps = {
        headerText: "Success!",
        bodyText: "Your text message alert preferences have been updated",
        primaryButton: {
            text: "Close",
            action: () => {
                setShowModalUpdateSpecificAlertsSuccess(false);
                props.setCanDiscardSpecificAlertsChanges(true);
                props.setIsEditingSpecificAlertsChanged(false);
                props.setIsEditingSpecificAlerts(false);
                navigate(`/account/summary/${accountIndex}/manage-alerts`);
            }
        }
    } as GenericModalProps;

    return {
        hooks: {
            navigate
        },
        state: {
            accounts,
            accountIndex,
            size,
            modalErrorProps,
            showModalError,
            setShowModalError,
            modalUpdateSpecificAlertsSuccessProps,
            showModalUpdateSpecificAlertsSuccess,
            setShowModalUpdateSpecificAlertsSuccess,
            showModalCancelWithoutSaving,
            setShowModalCancelWithoutSaving,
            modalCancelWithoutSavingProps,
            saveSpecificAlertsChanges,
            setSaveSpecificAlertsChanges,
            anyThresholdItemErrors,
            setAnyThresholdItemErrors,
            displayedAlerts,
            setDisplayedAlerts,
            rowsAlerts,
            setRowsAlerts,
            rowsAlertsEdit,
            setRowsAlertsEdit
        },
        api: {
            postManageSpecificAlerts
        }
    };
}
