import { useAccountContext, UserAccount } from "../../../../api/backend";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";

export type PaymentsSummarySplashState = {
    size: Size;
    account: UserAccount | undefined;
};

const usePaymentsSummarySplashState = (): any => {
    const { size } = useViewport(false, true);
    const { accounts, accountIndex } = useAccountContext();
    const account = accounts[accountIndex];

    return {
        size,
        account
    };
};

export const usePaymentsSummarySplashViewModel = () => {
    const state = usePaymentsSummarySplashState();

    return {
        state
    };
};
