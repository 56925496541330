import { useDeepLinkContext } from "../../contexts";
import { useIsLoggedIn } from "../../hooks";

type AuthenticatedViewModel = {
    state: AuthenticatedState;
};

export type AuthenticatedState = {
    loggedIn: boolean;
    deepLink: string;
    setDeepLink: (value: string) => void;
};

export function useAuthenticatedViewModel(): AuthenticatedViewModel {
    const { deepLink, setDeepLink } = useDeepLinkContext();
    const { loggedIn } = useIsLoggedIn();

    return {
        state: {
            loggedIn,
            deepLink,
            setDeepLink
        }
    };
}
