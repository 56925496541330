import { AxiosResponse } from "axios";
import { useState } from "react";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import { AppSettings, useEnrollmentContext, useSettingsContext } from "../../../api/backend";
import { MFAOptions, useIdentityPremierContext } from "../../../api/identityServer";
import { useMfaContext } from "../../../contexts";
import { SecurityType } from "../../../contexts/mfaContext";
import { useSessionStorage, useViewport } from "../../../hooks";
import { Size } from "../../../types";

type LoginDeliverMfaViewModel = {
    hooks: LoginDeliverMfaHooks;
    state: LoginDeliverMfaState;
    api: LoginDeliverMfaApi;
};

type LoginDeliverMfaHooks = {
    navigate: NavigateFunction;
    storeValue: (name: string, value: any) => void;
};

export type LoginDeliverMfaState = {
    size: Size;
    securityType: SecurityType | undefined;
    searchParams: URLSearchParams;
    mfaOptions: MFAOptions | undefined;
    appSettings: AppSettings;
    showCancel: boolean;
    setShowCancel: React.Dispatch<React.SetStateAction<boolean>>;
    showAssistance: boolean;
    setShowAssistance: React.Dispatch<React.SetStateAction<boolean>>;
    selection: MfaSelection;
    setSelection: React.Dispatch<React.SetStateAction<MfaSelection>>;
    canContinue: boolean;
    setCanContinue: React.Dispatch<React.SetStateAction<boolean>>;
    showError: boolean;
    setShowError: React.Dispatch<React.SetStateAction<boolean>>;
};

type LoginDeliverMfaApi = {
    sendCode: () => Promise<AxiosResponse<any, any>>;
    enrollSendCode: (sessionId: string) => Promise<AxiosResponse<any, any>>;
};

export type MfaSelection = {
    type: string | undefined;
    value: DeliveryMethod | undefined;
};

export type MfaValues = {
    value: string;
    deliveryMethod: DeliveryMethod;
};

export type DeliveryMethod =
    | "CallCellPhone"
    | "CallHomePhone"
    | "CallWorkPhone"
    | "CallOtherPhone"
    | "TextCellPhone"
    | "TextHomePhone"
    | "TextWorkPhone"
    | "TextOtherPhone"
    | "Email"
    | "CustomerService";

const sessionKey = ["mfaCustServReload"];

export function useLoginDeliverMfaViewModel(): LoginDeliverMfaViewModel {
    const { size } = useViewport(false, true);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { mfaOptions, securityType } = useMfaContext();
    const { appSettings } = useSettingsContext();
    const [showCancel, setShowCancel] = useState(false);
    const [storage, storeValue] = useSessionStorage(sessionKey);
    const [showAssistance, setShowAssistance] = useState(
        mfaOptions?.noContactInfo || storage["mfaCustServReload"] || false
    );
    let [selection, setSelection] = useState<MfaSelection>({
        type: undefined,
        value: undefined
    });
    let [canContinue, setCanContinue] = useState<boolean>(false);
    const { SendCode } = useIdentityPremierContext();
    const { MfaRequestCode } = useEnrollmentContext();
    const [showError, setShowError] = useState(false);

    async function sendCode(): Promise<AxiosResponse<any, any>> {
        let MfaOptions = selection.value!.toLowerCase();
        let MFADeliveryAddress = MfaOptions.includes("cell")
            ? mfaOptions?.cellPhoneNumber
            : MfaOptions.includes("other")
              ? mfaOptions?.otherPhoneNumber
              : MfaOptions.includes("home")
                ? mfaOptions?.homePhoneNumber
                : MfaOptions.includes("work")
                  ? mfaOptions?.workPhoneNumber
                  : MfaOptions.includes("email")
                    ? mfaOptions?.emailAddress
                    : "";
        return await SendCode(
            selection.value!,
            mfaOptions?.onlineUserId ?? 0,
            mfaOptions?.accountId ?? 0,
            MFADeliveryAddress ?? ""
        );
    }

    async function enrollSendCode(sessionId: string): Promise<AxiosResponse<any, any>> {
        return await MfaRequestCode({ sessionId: sessionId, deliveryMethod: selection.value! });
    }

    return {
        hooks: { navigate, storeValue },
        state: {
            size,
            securityType,
            searchParams,
            mfaOptions,
            appSettings,
            showCancel,
            setShowCancel,
            showAssistance,
            setShowAssistance,
            selection,
            setSelection,
            canContinue,
            setCanContinue,
            showError,
            setShowError
        },
        api: {
            sendCode,
            enrollSendCode
        }
    };
}
