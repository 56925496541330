import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";

type CardSummaryHeaderViewModel = {
    state: CardSummaryHeaderState;
};

export type CardSummaryHeaderState = {
    size: Size;
};

export function useCardSummaryHeaderViewModel(): CardSummaryHeaderViewModel {
    const { size } = useViewport(false, true);

    return {
        state: { size }
    };
}
