import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";

type CardProgramFeeViewModel = {
    state: CardProgramFeeState;
};

export type CardProgramFeeState = { size: Size };

export function useCardProgramFeeViewModel(): CardProgramFeeViewModel {
    const { size } = useViewport(true);

    return {
        state: { size }
    };
}
