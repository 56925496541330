import { ReactElement } from "react";
import { SplashMessage } from "../splashMessage/splashMessage";
import "./dashboard.scss";
import { DashboardState } from "./dashboardViewModel";

export type DashboardViewProps = DashboardState;

function DashboardView(props: DashboardViewProps): ReactElement {
    return (
        <div id="dashboard">
            <SplashMessage cms={props.appCms} />

            <div id="dashboard-content">{props.rows}</div>
        </div>
    );
}

export default DashboardView;
