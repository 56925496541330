import { Formik, FormikHelpers, FormikProps } from "formik";
import { ReactElement } from "react";
import { BankAccountGetResponse } from "../../../../api/backend";
import { Modal, ModalProps } from "../../../generic";
import { PaymentAccountsDeleteForm } from "./paymentAccountsDeleteForm";
import { usePaymentAccountsDeleteViewModel } from "./paymentAccountsDeleteViewModel";

export type PaymentAccountsDeleteProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    deletedAccount: BankAccountGetResponse | undefined;
    setDeletedAccount: React.Dispatch<React.SetStateAction<BankAccountGetResponse | undefined>>;
};

export function PaymentAccountsDeleteModal(props: ModalProps & PaymentAccountsDeleteProps): ReactElement {
    return (
        <Modal show={props.show}>
            <PaymentAccountDeleteModel {...props} />
        </Modal>
    );
}

export function PaymentAccountDeleteModel(props: PaymentAccountsDeleteProps): ReactElement {
    const vm = usePaymentAccountsDeleteViewModel();

    function onSubmit(actions: FormikHelpers<{}>) {
        if (props.deletedAccount !== undefined) {
            vm.api
                .Delete(props.deletedAccount?.bankAccountId)
                .then((res) => {
                    props.setShow(false);
                    props.setShowSuccess(true);
                })
                .finally(() => {
                    actions.setSubmitting(false);
                });
        } else {
            props.setShow(false);
            actions.setSubmitting(false);
        }
    }

    return (
        <Formik
            initialValues={{}}
            onSubmit={(_, actions) => {
                onSubmit(actions);
            }}
        >
            {(formProps: FormikProps<{}>) => <PaymentAccountsDeleteForm props={props} vm={vm} formProps={formProps} />}
        </Formik>
    );
}
