import { FormikProps } from "formik";
import { ReactElement } from "react";
import { PaymentAccountsDeleteProps } from "./paymentAccountsDeleteModel";
import { PaymentAccountsDeleteView } from "./paymentAccountsDeleteView";
import { PaymentAccountsDeleteViewModel } from "./paymentAccountsDeleteViewModel";

type EnterPaymentAccountFormProps = {
    props: PaymentAccountsDeleteProps;
    vm: PaymentAccountsDeleteViewModel;
    formProps: FormikProps<{}>;
};

export function PaymentAccountsDeleteForm(form: EnterPaymentAccountFormProps): ReactElement {
    const { props, formProps } = { ...form };
    const { state } = { ...form.vm };

    function onClose() {
        props.setShow(false);
        props.setDeletedAccount(undefined);
    }

    return <PaymentAccountsDeleteView {...props} {...state} {...formProps} onClose={onClose} />;
}
