import { Formik } from "formik";
import { ReactElement } from "react";
import { Form } from "react-router-dom";
import { FormProps } from "../../../../../types";
import { BrandedCard, ButtonPro, CheckboxPro } from "../../../../generic";
import { CreditCard } from "../../../../generic/icons";
import { InputPro } from "../../../../generic/inputs/inputPro/inputPro";
import "./pcpEnrollmentModal.scss";
import { PcpEnrollmentModalProps } from "./pcpEnrollmentModalModel";
import { PCPEnrollmentModalState } from "./pcpEnrollmentModalViewModel";

type PCPEnrollmentViewProps = PCPEnrollmentModalState &
    PcpEnrollmentModalProps & {
        onSubmit: Function;
        onCancel: Function;
        onChange: Function;
        onTermsClick: Function;
        onBlur: Function;
    };
function PCPEnrollmentModalView(props: PCPEnrollmentViewProps): ReactElement {
    return (
        <BrandedCard
            id="PremierCreditProtectionEnrollmentModal"
            size=""
            header={
                <div className="pcp-enrollment-header-group">
                    <h3 className="pcp-enrollment-header">Enroll in PREMIER Credit Protection</h3>
                    <h6 className="pcp-enrollment-header-subtext">Confirm enrollment for the card(s) listed below</h6>
                </div>
            }
        >
            <div className="pcp-enrollment-modal">
                <div className="pcp-enrollment-modal-card-list">
                    {props.enrollmentArray.map((data, index) => (
                        <div key={index}>
                            {!data.enrolled && data.checked ? (
                                <div className="pcp-enrollment-card-list-item">
                                    <CreditCard className={`pcp-enrollment-credit-card-${data.accent + 1}`} />
                                    <h4 className={`pcp-enrollment-card-list-item-text-${data.accent + 1}`}>
                                        Card (*{data.last4})
                                    </h4>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    ))}
                </div>
                <Formik enableReinitialize initialValues={{ initials: "" }} onSubmit={(values) => {}}>
                    {(formProps: FormProps<{ initials: string }>) => (
                        <Form>
                            <div
                                className="pcp-enrollment-modal-confirmation"
                                onClick={() => {
                                    props.setConfirmEnrollmentCheck(!props.confirmEnrollmentCheck);
                                }}
                            >
                                <CheckboxPro id="check" checked={props.confirmEnrollmentCheck} />
                                <p className="pcp-enrollment-modal-confirmation-text">
                                    YES, I would like to purchase PREMIER Credit Protection! I acknowledge I have
                                    opened, read and agree with the costs, benefits, limitations and exclusions noted on
                                    the{" "}
                                    <span
                                        className="pcp-enrollment-modal-disclaimer-link"
                                        onClick={() => props.onTermsClick()}
                                    >
                                        PREMIER Credit Protection Disclosure Statement.
                                    </span>
                                </p>
                            </div>
                            <div className="pcp-enrollment-modal-initials">
                                <div className="pcp-enrollment-modal-initials-input">
                                    <InputPro
                                        id="initials"
                                        type="text"
                                        mask={/^[a-z]{0,3}$/im}
                                        label="Enter your initials"
                                        error={props.initialError}
                                        onChange={props.onChange(formProps)}
                                        onBlur={() => props.onBlur(formProps)}
                                    />
                                </div>
                                <h6 className="pcp-enrollment-modal-disclaimer">
                                    Initialing here represents my signature and means that I wish to enroll in PREMIER
                                    Credit Protection. I understand the monthly cost of $0.89 per $100.00 of my
                                    outstanding ending monthly balance will appear on my credit card statement. I
                                    acknowledge that I have read and agree with the costs, benefits, limitations and
                                    exclusions noted on the Disclosure Statement for PREMIER Credit Protection and I am
                                    free to cancel at any time. To be eligible for benefits, a Protected Event must occur 30 days or more after the enrollment date. Other eligibility requirements also apply.
                                </h6>
                            </div>
                            <div className="pcp-enrollment-modal-button-group">
                                <ButtonPro type="button" variant="neutral" onClick={() => props.onCancel()}>
                                    Cancel
                                </ButtonPro>
                                <ButtonPro
                                    disabled={
                                        !props.canContinue || !props.confirmEnrollmentCheck || props.errorCount >= 3
                                    }
                                    onClick={() => props.onSubmit(formProps)}
                                >
                                    Enroll
                                </ButtonPro>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </BrandedCard>
    );
}

export default PCPEnrollmentModalView;
