import { useAccountContext, UserAccount } from "../../../../api/backend";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";

export type ActivitySummarySplashState = {
    size: Size;
    account: UserAccount | undefined;
};

const useActivitySummarySplashState = (): any => {
    const { size } = useViewport(false, true);
    const { accounts, accountIndex } = useAccountContext();
    const account = accounts[accountIndex];

    return {
        size,
        account
    };
};

export const useActivitySummarySplashViewModel = () => {
    const state = useActivitySummarySplashState();

    return {
        state
    };
};
