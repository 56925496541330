import { useAccountContext } from "../../../../api/backend";

export type PaymentSuccessState = {
    last4: string;
};

const usePaymentSuccessState = (): PaymentSuccessState => {
    const { accounts, accountIndex } = useAccountContext();
    const last4 = accounts[accountIndex].maskedAccountNumber.slice(-4);

    return {
        last4
    };
};

export const usePaymentSuccessViewModel = () => {
    const state = usePaymentSuccessState();

    return {
        state
    };
};
