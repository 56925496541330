import { useEffect, useState } from "react";
import { useAccountContext } from "../../../api/backend";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { getAppStatusText } from "../cardTiles/cardAppStatus/cardAppStatusViewModel";
import { SplashMessageProps } from "./splashMessage";

type SplashMessageViewModel = {
    state: SplashMessageState;
};

export type SplashMessageState = {
    size: Size;
    firstName: string;
    message: string;
    header: string;
};

export function useSplashMessageViewModel(props: SplashMessageProps): SplashMessageViewModel {
    const { size } = useViewport(true, true);
    const { accounts } = useAccountContext();
    const currentHours = new Date().getHours();
    const firstName = getFirstName();
    const message = getMessage();
    const [header, setHeader] = useState("");

    useEffect(() => {
        setState();
    }, []);

    useEffect(() => {
        setState();
    }, [props.cms]);

    const setState = () => {
        if (props.cms) {
            let a = accounts?.find((x) => x.isApplicationStatus === true);
            if (a !== undefined) {
                let index = accounts!.indexOf(a);
                setHeader(getAppStatusText({ cms: props.cms, account: a, index: index }).header);
            }
        }
    };

    function getFirstName(): string {
        return accounts![0].firstName.toLowerCase();
    }

    function getMessage(): string {
        if (currentHours < 12) {
            return "Good Morning,";
        } else if (currentHours < 17) {
            return "Good Afternoon,";
        } else {
            return "Good Evening,";
        }
    }

    return {
        state: {
            size,
            firstName,
            message,
            header
        }
    };
}
