import { AxiosResponse } from "axios";
import { ReactElement, useRef, useState } from "react";
import {
    AppSettings,
    DocUploadRequest,
    DocumentReasons,
    useAccountContext,
    useDocUploadContext,
    useSettingsContext
} from "../../../../api/backend";
import { GenericModalProps } from "../../../generic";
import { DocumentUploadFormModelProps } from "./documentUploadFormModel";

type DocumentUploadFormViewModel = {
    state: DocumentUploadFormState;
    api: DocumentUploadFormApi;
};

export type DocumentUploadFormState = {
    appSettings: AppSettings;
    loading: boolean;
    uploading: boolean;
    rows: ReactElement[];
    setRows: React.Dispatch<React.SetStateAction<ReactElement[]>>;
    inputFile: React.MutableRefObject<HTMLInputElement | null>;
    files: File[];
    setFiles: React.Dispatch<React.SetStateAction<File[]>>;
    maxFiles: number;
    setMaxFiles: React.Dispatch<React.SetStateAction<number>>;
    documentReasonId: number | undefined;
    setDocumentReasonId: React.Dispatch<React.SetStateAction<number | undefined>>;
    submitDisabled: boolean;
    setSubmitDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    uploadConfirmation: GenericModalProps;
    showUploadConfirmation: boolean;
    setShowUploadConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
    uploadError: GenericModalProps;
    showUploadError: boolean;
    setShowUploadError: React.Dispatch<React.SetStateAction<boolean>>;
    loadReasons: number;
    setLoadReasons: React.Dispatch<React.SetStateAction<number>>;
    bodyText: string;
    setBodyText: React.Dispatch<React.SetStateAction<string>>;
    accountIndex: number;
};

type DocumentUploadFormApi = {
    GetDocumentReasons: (accountHandle: string) => Promise<AxiosResponse<DocumentReasons[], any>>;
    PostFiles: (request: DocUploadRequest) => Promise<AxiosResponse<any, any>>;
};

export function useDocumentUploadFormViewModel(props: DocumentUploadFormModelProps): DocumentUploadFormViewModel {
    const { appSettings } = useSettingsContext();
    const { GetDocumentReasons, Post: PostFiles, loading, uploading } = useDocUploadContext();
    const [rows, setRows] = useState([] as ReactElement[]);
    const inputFile = useRef<HTMLInputElement | null>(null);
    const [files, setFiles] = useState([] as File[]);
    const [maxFiles, setMaxFiles] = useState(appSettings.docUpload.maxFilesPerUpload | 0);
    const [documentReasonId, setDocumentReasonId] = useState<number>();
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [showUploadConfirmation, setShowUploadConfirmation] = useState(Boolean);
    const [showUploadError, setShowUploadError] = useState(Boolean);
    const [loadReasons, setLoadReasons] = useState(0);
    const [bodyText, setBodyText] = useState("");
    const { accountIndex } = useAccountContext();

    const uploadConfirmation = {
        headerText: "Files Successfully Uploaded!",
        bodyText: "",
        primaryButton: {
            text: "Close",
            action: () => {
                setFiles([]);
                setShowUploadConfirmation(false);
            }
        }
    } as GenericModalProps;

    const uploadError = {
        headerText: "Upload Error",
        bodyText: bodyText,
        primaryButton: {
            text: "Close",
            action: () => setShowUploadError(false)
        }
    } as GenericModalProps;

    return {
        state: {
            appSettings,
            loading,
            uploading,
            rows,
            setRows,
            inputFile,
            files,
            setFiles,
            maxFiles,
            setMaxFiles,
            documentReasonId,
            setDocumentReasonId,
            submitDisabled,
            setSubmitDisabled,
            uploadConfirmation,
            showUploadConfirmation,
            setShowUploadConfirmation,
            uploadError,
            showUploadError,
            setShowUploadError,
            loadReasons,
            setLoadReasons,
            bodyText,
            setBodyText,
            accountIndex
        },
        api: {
            GetDocumentReasons,
            PostFiles
        }
    };
}
