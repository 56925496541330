import { FormikHelpers } from "formik";
import { ReactElement, useEffect, useState } from "react";
import { ManageAlertsTermsFields, ManageAlertsTermsView } from "./manageAlertsTermsView";
import { useManageAlertsTermsViewModel } from "./manageAlertsTermsViewModel";

export type ManageAlertsTermsModelProps = {
    onDeclineTerms: Function;
    onAccept: Function;
    onCloseViewTerms: Function;
    viewOnlyTermsAndConditions: boolean;
};

export function ManageAlertsTermsModel(props: ManageAlertsTermsModelProps): ReactElement {
    const { hooks, state } = useManageAlertsTermsViewModel();
    const [scrolledTerms, setScrolledTerms] = useState(false);

    //const trackScrolling = () => {
    const handleScroll = (e: any) => {
        const parent = document.getElementById("manageAlertsTerms-parent-id");
        const target = document.getElementById("manageAlertsTerms-target-id");

        if ((target?.getBoundingClientRect()?.bottom ?? 0) <= (parent?.clientHeight ?? 0)) {
            setScrolledTerms(true);
        }
    };

    useEffect(() => {
        document.addEventListener("scroll", handleScroll);

        return () => {
            document.removeEventListener("scroll", handleScroll);
        };
    }, []);

    function onSubmit(values: ManageAlertsTermsFields, actions: FormikHelpers<{}>) {}

    return (
        <ManageAlertsTermsView
            {...state}
            onDeclineTerms={props.onDeclineTerms}
            onSubmit={onSubmit}
            scrolledTerms={scrolledTerms}
            setScrolledTerms={setScrolledTerms}
            onAccept={props.onAccept}
            onCloseViewTerms={props.onCloseViewTerms}
            viewOnlyTermsAndConditions={props.viewOnlyTermsAndConditions}
        />
    );
}
