import dayjs from "dayjs";
import { useMemo } from "react";
import { Columns } from "react-csv-downloader/dist/esm/lib/csv";
import { TransactionDetails, useAccountContext } from "../../../../api/backend";
import { DownloadDetails } from "../../../../utility/ofxExporter";
import { ActivityDownloadProps } from "./activityDownload";

export type ActivityDownloadState = {
    accountId: string;
    details: DownloadDetails[];
    columns: Columns;
};

const useActivityDownloadState = (props: ActivityDownloadProps): ActivityDownloadState => {
    const { accounts, accountIndex } = useAccountContext();
    const account = accounts[accountIndex];
    const accountId =
        (account.isApplicationStatus ? account.referenceNumber?.toString() : account.maskedAccountNumber.slice(-4)) ??
        "";

    const details = useMemo(() => {
        const copy = JSON.parse(JSON.stringify(props.filteredHistory)) as TransactionDetails[];
        return copy
            .sort((a, b) => dayjs(a.TransactionDate).diff(dayjs(b.TransactionDate)))
            .map((x) => {
                return {
                    TransactionDate: formatTransactionDate(x.TransactionDate),
                    ReferenceNumber: x.ReferenceNumber,
                    TransactionAmount: x.TransactionAmount,
                    MerchantDescription: x.MerchantDescription
                } as DownloadDetails;
            });
    }, [props.filteredHistory]);

    const keys = Object.keys(details[0]);
    const columns = keys.map((k) => {
        return { id: k, displayName: k };
    });

    return {
        accountId,
        details,
        columns
    };
};

const formatTransactionDate = (date: Date | undefined): string => {
    if (date === undefined) return "";

    return dayjs(date).format("MM/DD/YYYY");
};

export const useActivityDownloadViewModel = (props: ActivityDownloadProps) => {
    const state = useActivityDownloadState(props);

    return {
        state
    };
};
