import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Home(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.5 12.4392L10.935 2.99625C11.0748 2.85732 11.2405 2.74729 11.4228 2.67245C11.6051 2.5976 11.8004 2.55942 11.9974 2.56007C12.1945 2.56072 12.3895 2.60019 12.5713 2.67623C12.7531 2.75228 12.9182 2.8634 13.057 3.00325L22.5 12.4392"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.5 9.44104V21.439H15.042M4.5 9.43604V21.439H8.54199"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 21.4395V16.9395C15 16.1438 14.6839 15.3807 14.1213 14.8181C13.5587 14.2555 12.7956 13.9395 12 13.9395C11.2044 13.9395 10.4413 14.2555 9.87868 14.8181C9.31607 15.3807 9 16.1438 9 16.9395V21.4395"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
