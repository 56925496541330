import { useAccountContext, UserAccount } from "../../../../../api/backend";
import { useViewport } from "../../../../../hooks";
import { Size } from "../../../../../types";
import { FormatMoney } from "../../../../../utility";

export type CurrentBalanceState = {
    size: Size;
    account: UserAccount | undefined;
    dollars: string;
    cents: string;
};

const useCurrentBalanceState = (): CurrentBalanceState => {
    const { size } = useViewport(true, true);
    const { accounts, accountIndex } = useAccountContext();
    const account = accounts[accountIndex];
    const { dollars, cents } = FormatMoney(account?.currentBalance, true);

    return {
        size,
        account,
        dollars,
        cents
    };
};

export const useCurrentBalanceViewModel = () => {
    const state = useCurrentBalanceState();

    return {
        state
    };
};
