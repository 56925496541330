import { ReactElement } from "react";
import { UserAccount } from "../../../../api/backend";
import { CardSummaryHeaderView } from "./cardSummaryHeaderView";
import { useCardSummaryHeaderViewModel } from "./cardSummaryHeaderViewModel";

export type CardSummaryHeaderProps = {
    index: number;
    account: UserAccount;
};

export function CardSummaryHeaderModel(props: CardSummaryHeaderProps): ReactElement {
    const { state } = useCardSummaryHeaderViewModel();

    return <CardSummaryHeaderView {...props} {...state} />;
}
