import { ReactElement } from "react";
import { UserAccount } from "../../../../api/backend";
import { CreditCard } from "../../icons";
import { Notification } from "../../icons/notification";
import "./indexedCreditCard.scss";

export type IndexedCreditCardProps = {
    icon?: boolean;
    account: UserAccount;
    size?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    notification?: boolean;
    notificationNumber?: string;
};

export function IndexedCreditCard({
    account,
    size = "h4",
    icon = true,
    notification = false,
    notificationNumber = ""
}: IndexedCreditCardProps): ReactElement {
    let i = account.accent;
    let is = size === "h6" ? "sm" : "lg";

    return (
        <div className={`indexedCreditCard-${is}`}>
            {icon ? (
                <div className="indexedCreditCard-icon">
                    <CreditCard className={`indexedCreditCard-icon-${is}-${i}`} />
                    {notification ? (
                        <>
                            <Notification
                                className="indexedCreditCard-icon-notification"
                                notificationNumber={notificationNumber}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <></>
            )}

            <GetSize index={i} last4={account.maskedAccountNumber.slice(-4)} size={size} />
        </div>
    );
}

export function GetSize(props: { index: number; last4: string; size: string }): ReactElement {
    const cn = `indexedCreditCard-cardNumber-${props.index}`;
    const content = `Card (*${props.last4})`;

    switch (props.size) {
        case "h1":
            return <h1 className={cn}>{content}</h1>;
        case "h2":
            return <h2 className={cn}>{content}</h2>;
        case "h3":
            return <h3 className={cn}>{content}</h3>;
        case "h4":
            return <h4 className={cn}>{content}</h4>;
        case "h5":
            return <h5 className={cn}>{content}</h5>;
        case "h6":
            return <h6 className={cn}>{content}</h6>;
    }

    return <></>;
}
