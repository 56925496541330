import { ReactElement } from "react";
import { AsyncElement } from "../../generic";
import "./statements.scss";
import { StatementsState } from "./statementsViewModel";

function StatementsView(props: StatementsState): ReactElement {
    return (
        <div id="statements">
            <div className="statements-header">
                <h5>Statements</h5>
            </div>
            {!props.isEMessengerDown ? (
                <AsyncElement loading={props.loading}>
                    <div className="statements-list" data-testid="statements-list">
                        {props.rows.length > 0 ? (
                            props.rows
                        ) : (
                            <div className="statements-noList">
                                <h5>{props.noRowsMessage}</h5>
                            </div>
                        )}
                    </div>
                </AsyncElement>
            ) : (
                <div className="statements-list">
                    <div className="statements-noList" data-testid="statements-noList">
                        <h5>{props.noRowsMessage}</h5>
                    </div>
                </div>
            )}

            <div className="statements-footer">
                <p>
                    {props.rows.length} result{props.rows.length !== 1 ? "s" : ""}
                </p>
            </div>
        </div>
    );
}

export default StatementsView;
