import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function ArrowDown(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.0015 1.22119L9.37835 8.84359C9.32889 8.89313 9.27014 8.93243 9.20547 8.95925C9.1408 8.98606 9.07147 8.99986 9.00147 8.99986C8.93146 8.99986 8.86213 8.98606 8.79746 8.95925C8.73279 8.93243 8.67404 8.89313 8.62458 8.84359L1.00146 1.22119"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
