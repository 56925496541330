import { ReactElement } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { GetCardOptions } from "../cardNav";
import { CardNavState } from "../cardNavViewModel";
import { NavMenu } from "../navMenu/navMenu";
import "./cardNav.scss";

type CardNavViewProps = CardNavState & {
    onNavSelect: Function;
    onDropdownClick: Function;
};

export function CardNavMainView(props: CardNavViewProps): ReactElement {
    const navigate = useNavigate();

    return (
        <div id="cardNav">
            <Navbar className="cardNav-navBar">
                <Nav className="cardNav-nav" activeKey={props.navKey} onSelect={(e) => props.onNavSelect(e)}>
                    <div className="cardNav-links">
                        <Nav.Link
                            as={Link}
                            to={`/account/summary/${props.accountIndex}/home`}
                            href="#home"
                            key="home"
                            className={`cardNav-navLink-${props.accounts[props.accountIndex].accent}`}
                        >
                            Card Home
                        </Nav.Link>
                        {props.paymentActions ? (
                            <Nav.Link
                                as={Link}
                                to={`/account/summary/${props.accountIndex}/payments`}
                                href="#payments"
                                key="payments"
                                className={`cardNav-navLink-${props.accounts[props.accountIndex].accent}`}
                            >
                                Payments
                            </Nav.Link>
                        ) : (
                            <></>
                        )}
                        <Nav.Link
                            as={Link}
                            to={`/account/summary/${props.accountIndex}/activity`}
                            href="#activity"
                            key="activity"
                            className={`cardNav-navLink-${props.accounts[props.accountIndex].accent}`}
                        >
                            Activity & Statements
                        </Nav.Link>
                        <NavMenu />
                    </div>
                    <div className={`cardNav-cards${props.size}`}>
                        {GetCardOptions(props.accounts!, props.accountIndex, props.onDropdownClick, navigate, false)}
                    </div>
                </Nav>
            </Navbar>
        </div>
    );
}
