import { AxiosResponse } from "axios";
import { ReactElement, useState } from "react";
import {
    ElectronicDeliveryPreferences,
    UserAccount,
    useAccountContext,
    useDeliveryPreferencesContext
} from "../../../../api/backend";

type DeliveryPreferencesLandingViewModel = {
    state: DeliveryPreferencesLandingState;
    api: DeliveryPreferencesLandingApi;
};

export type DeliveryPreferencesLandingState = {
    accounts: UserAccount[];
    showTerms: boolean;
    setShowTerms: React.Dispatch<React.SetStateAction<boolean>>;
    showConfirmation: boolean;
    setShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
    ignore: any;
    setIgnore: React.Dispatch<React.SetStateAction<any>>;
    preferences: ElectronicDeliveryPreferences[];
    confirmRows: ReactElement[];
    setConfirmRows: React.Dispatch<React.SetStateAction<ReactElement[]>>;
    initialValues: any;
    selectedValues: any;
};

type DeliveryPreferencesLandingApi = {
    getPreferences: () => Promise<AxiosResponse<ElectronicDeliveryPreferences[], any>>;
};

export function useDeliveryPreferencesLandingViewModel(): DeliveryPreferencesLandingViewModel {
    const [showTerms, setShowTerms] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [ignore, setIgnore] = useState<any>();
    const { accounts } = useAccountContext();
    const { Get: getPreferences, preferences } = useDeliveryPreferencesContext();
    const [confirmRows, setConfirmRows] = useState<ReactElement[]>([]);
    let dpValues = getValues(accounts!);

    return {
        state: {
            accounts: accounts!,
            showTerms,
            setShowTerms,
            showConfirmation,
            setShowConfirmation,
            ignore,
            setIgnore,
            preferences: preferences!,
            confirmRows,
            setConfirmRows,
            ...dpValues
        },
        api: {
            getPreferences
        }
    };
}

type dpValues = {
    initialValues: any;
    selectedValues: any;
};

const getValues = (accounts: UserAccount[]): dpValues => {
    let ivs = "{";
    for (var i = 0; i < accounts.length; i++) {
        ivs += ` "checkBox-${i}" : ${accounts[i].displayEStatementPrompt === true ? "false" : "true"}${i < accounts.length - 1 ? "," : ""}`;
    }
    ivs += "}";

    let svs = "{";
    for (var j = 0; j < accounts.length; j++) {
        svs += ` "checkBox-${j}" : true${j < accounts.length - 1 ? "," : ""}`;
    }
    svs += "}";

    return {
        initialValues: JSON.parse(ivs),
        selectedValues: JSON.parse(svs)
    };
};
