import { ReactElement } from "react";
import { ApplicationStatusCms } from "../../../api/backend";
import { SplashMessageView } from "./splashMessageView";
import { useSplashMessageViewModel } from "./splashMessageViewModel";

export type SplashMessageProps = {
    cms: ApplicationStatusCms | undefined;
};

export function SplashMessage(props: SplashMessageProps): ReactElement {
    const { state } = useSplashMessageViewModel(props);

    return <SplashMessageView {...state} />;
}
