import { useLoaderData } from "react-router";
import { useViewport } from "../../../hooks";
import { DisclosuresData } from "./disclosuresLoader";
import { Size } from "../../../types";

type DisclosuresTabViewModel = {
    state: DisclosuresTabState;
};

export type DisclosuresTabState = {
    size: Size;
    headerText: string;
    bodyText: string;
    isPrint: boolean;
    printView: string;
    isPdf: boolean;
    contentType: string;
    fileName: string;
    pdfData: ArrayBuffer;
};

export function useDisclosuresViewModelTab(): DisclosuresTabViewModel {
    const { size } = useViewport(false, true);
    const data = useLoaderData() as DisclosuresData;
    const contentType = data.cms.PrivacyNotice.ContentType;
    const fileName = data.cms.PrivacyNotice.FileName;
    const pdfData = data.cms.PrivacyNotice.DisclosureData;

    return {
        state: {
            size,
            contentType,
            fileName,
            pdfData,
            ...data
        }
    };
}
