import { ReactElement, useEffect } from "react";
import { Documents } from "../../..";
import { SetTitle } from "../../../../utility";
import { BackArrow } from "../../../generic";
import DocumentsInfo from "./documentsInfo/documentsInfo";
import "./documentsSummary.scss";
import DocumentsSummarySplash from "./documentsSummarySplash/documentsSummarySplash";
import { DocumentsSummaryState } from "./documentsSummaryViewModel";

export function DocumentsSummaryView(props: DocumentsSummaryState): ReactElement {
    useEffect(() => {
        SetTitle("Documents");

        return () => SetTitle("");
    }, []);

    return (
        <>
            <div id={`documentsSummary${props.size}`}>
                <BackArrow available to={`/account/summary/${props.accountIndex}/home`}>
                    <DocumentsSummarySplash {...props} />
                </BackArrow>

                <DocumentsInfo {...props} />
                <Documents />
            </div>
        </>
    );
}
