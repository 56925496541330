import "./cardProgramFee.scss";
import "../../cardSummary/cardSummaryCommon.scss";
import { ReactElement } from "react";
import { CardProgramFeeState } from "./cardProgramFeeViewModel";
import { CardApplicationProps } from "../../cardApplication/cardApplication";
import { FormatMoney } from "../../../../utility";
import { ButtonPro } from "../../../generic";

type CardProgramFeeViewProps = CardApplicationProps &
    CardProgramFeeState & {
        onPay: Function;
    };

export function CardProgramFeeView(props: CardProgramFeeViewProps): ReactElement {
    return (
        <div className={`cardProgramFee${props.size}`}>
            <div className="cardProgramFee-content cardSummaryCommon cardTilePadding">
                <div className="cardProgramFee-amounts">
                    <h5>Program Fee</h5>
                    <div className="cardProgramFee-amount">
                        <p>Program Fee Amount</p>
                        <h5>{FormatMoney(props.account.applicationFee).formatted}</h5>
                    </div>
                    <div className="cardProgramFee-amount">
                        <p>Remaining Amount</p>
                        <h5>{FormatMoney(props.account.applicationBalance).formatted}</h5>
                    </div>
                </div>
                {props.account.applicationBalance > 0 ? (
                    <ButtonPro content={"Pay Program Fee"} onClick={() => props.onPay()} />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}
