import { useContext } from "react";
import { NavigateFunction, useLoaderData, useNavigate } from "react-router-dom";
import { useIdentityConnectContext } from "../../../api/identityServer";
import { BrowserSupportContext } from "../../../contexts/browserSupportContext";
import { useSessionStorage, useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { browserName, browserVersion } from "../../../utility/device";
import { BrowserCompatibilityData } from "./browserCompatibilityLoader";

type BrowserCompatibilityHooks = {
    navigate: NavigateFunction;
    storeValue: (name: string, value: any) => void;
};

export type BrowserCompatibilityState = {
    size: Size;
    access_token: string;
    headerText: string;
    bodyText: string;
    current: boolean;
    supported: boolean;
    acknowledgement: boolean;
    setAcknowledgement: React.Dispatch<React.SetStateAction<boolean>>;
};

const storageKeys = ["browserAcknowledgement"];

const useBrowserCompatibilityState = (): BrowserCompatibilityState => {
    const data = useLoaderData() as BrowserCompatibilityData;
    const { size } = useViewport(false, true);
    const { access_token } = useIdentityConnectContext();
    const context = useContext(BrowserSupportContext);
    const { headerText, bodyText } = getTexts(data);

    return {
        size,
        access_token,
        headerText,
        bodyText,
        ...data,
        ...context
    };
};

const useBrowserCompatibilityHooks = (): BrowserCompatibilityHooks => {
    const navigate = useNavigate();
    const [, storeValue] = useSessionStorage(storageKeys);

    return {
        navigate,
        storeValue
    };
};

export const useBrowserCompatibilityViewModel = () => {
    const state = useBrowserCompatibilityState();
    const hooks = useBrowserCompatibilityHooks();

    return {
        state,
        hooks
    };
};

function getTexts(data: BrowserCompatibilityData): { headerText: string; bodyText: string } {
    const headerText = getHeaderText(data);
    const bodyText = getBodyText(data);

    return { headerText, bodyText };
}

function getHeaderText(data: BrowserCompatibilityData) {
    if (!data.supported) {
        return "Browser Not Supported";
    }
    if (!data.current) {
        return "Browser Out of Date";
    }

    return "Browser Supported";
}

function getBodyText(data: BrowserCompatibilityData) {
    if (!data.supported) {
        return data.cms.FooterText;
    }
    if (!data.current) {
        return data.cms.BodyText;
    }

    return `${browserName()} version ${browserVersion()} is currently supported!\n Please Refresh your Page!`;
}
