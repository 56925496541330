import { ReactElement } from "react";
import "./pdfViewer.scss";
import { PdfViewerModelProps } from "./pdfViewerModel";

export function PdfViewerView(props: PdfViewerModelProps): ReactElement {
    return (
        <div id="pdfViewer" data-testid="pdfViewerId">
            <embed
                className={`pdfViewer-body${props.size}`}
                type={props.contentType}
                src={`data:application/pdf;base64,${props.pdfArrayBuffer}`}
            />
        </div>
    );
}
