import { Field, useFormikContext } from "formik";
import { ReactElement } from "react";
import "./stateSelector.scss";

export const stateOptions = [
    { key: "", value: "" },
    { key: "AL", value: "Alabama" },
    { key: "AK", value: "Alaska" },
    { key: "AS", value: "American Samoa" },
    { key: "AZ", value: "Arizona" },
    { key: "AR", value: "Arkansas" },
    { key: "CA", value: "California" },
    { key: "CO", value: "Colorado" },
    { key: "CT", value: "Connecticut" },
    { key: "DE", value: "Delaware" },
    { key: "DC", value: "District of Columbia" },
    { key: "FM", value: "Federated States of Micronesia" },
    { key: "FL", value: "Florida" },
    { key: "GA", value: "Georgia" },
    { key: "GU", value: "Guam" },
    { key: "HI", value: "Hawaii" },
    { key: "ID", value: "Idaho" },
    { key: "IL", value: "Illinois" },
    { key: "IN", value: "Indiana" },
    { key: "IA", value: "Iowa" },
    { key: "KS", value: "Kansas" },
    { key: "KY", value: "Kentucky" },
    { key: "LA", value: "Louisiana" },
    { key: "ME", value: "Maine" },
    { key: "MH", value: "Marshall Islands" },
    { key: "MD", value: "Maryland" },
    { key: "MA", value: "Massachusetts" },
    { key: "MI", value: "Michigan" },
    { key: "MN", value: "Minnesota" },
    { key: "MS", value: "Mississippi" },
    { key: "MO", value: "Missouri" },
    { key: "MT", value: "Montana" },
    { key: "NE", value: "Nebraska" },
    { key: "NV", value: "Nevada" },
    { key: "NH", value: "New Hampshire" },
    { key: "NJ", value: "New Jersey" },
    { key: "NM", value: "New Mexico" },
    { key: "NY", value: "New York" },
    { key: "NC", value: "North Carolina" },
    { key: "ND", value: "North Dakota" },
    { key: "MP", value: "Northern Mariana Islands" },
    { key: "OH", value: "Ohio" },
    { key: "OK", value: "Oklahoma" },
    { key: "OR", value: "Oregon" },
    { key: "PW", value: "Palau" },
    { key: "PA", value: "Pennsylvania" },
    { key: "PR", value: "Puerto Rico" },
    { key: "RI", value: "Rhode Island" },
    { key: "SC", value: "South Carolina" },
    { key: "SD", value: "South Dakota" },
    { key: "TN", value: "Tennessee" },
    { key: "TX", value: "Texas" },
    { key: "UT", value: "Utah" },
    { key: "VT", value: "Vermont" },
    { key: "VI", value: "Virgin Islands" },
    { key: "VA", value: "Virginia" },
    { key: "WA", value: "Washington" },
    { key: "WV", value: "West Virginia" },
    { key: "WI", value: "Wisconsin" },
    { key: "WY", value: "Wyoming" },
    { key: "AA", value: "Armed Forces America" },
    { key: "AE", value: "Armed Forces Europe" },
    { key: "AP", value: "Armed Forces Pacific" },
    { key: "FC", value: "Foreign Country" }
];

type StateSelectorFieldProps = {
    id: string;
    blankOption: boolean;
    label?: string;
    onClick?: Function;
    onBlur?: Function;
};

export function StateSelectorField(props: StateSelectorFieldProps): ReactElement {
    const formik = useFormikContext<any>();

    let error = formik.errors[props.id]?.toString();
    let touched = formik.touched[props.id] as boolean;

    return (
        <div className="inputField">
            <label htmlFor={props.id} className="state">
                {props.label}
            </label>

            <div className="inputField-row">
                <StateSelector {...props} />
            </div>

            {error && touched && (
                <div className="validation-error text-start">
                    <h6>{error}</h6>
                </div>
            )}
        </div>
    );
}

type StateSelectorProps = {
    id: string;
    blankOption: boolean;
    onClick?: Function;
    onBlur?: Function;
};

export function StateSelector(props: StateSelectorProps): ReactElement {
    const states = props.blankOption ? stateOptions : stateOptions.filter((s) => s.value !== "");

    return (
        <Field
            as="select"
            className="stateSelector"
            id={props.id}
            name={props.id}
            onClick={onClickHook(props.onClick)}
            onBlur={onBlurHook(props.onBlur)}
        >
            {states.map((x) => (
                <option key={x.key} value={x.key}>
                    {x.value}
                </option>
            ))}
        </Field>
    );
}

const onClickHook = (onClick: any | undefined) => {
    return onClick === undefined ? () => {} : onClick;
};

const onBlurHook = (onBlur: any | undefined) => {
    return onBlur === undefined ? () => {} : onBlur;
};
