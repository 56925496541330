import { AxiosResponse } from "axios";
import {
    ContextualAlerts,
    ContextualAlertsDestinationRequest,
    ContextualAlertsPostRequest,
    ContextualControlsLockRequest,
    ContextualControlsStatusResponse,
    ContextualControlsUnlockRequest
} from ".";
import { useApiQueueContext } from "../../../contexts";
import { UsePost } from "../../../hooks";
import { useIdentityConnectContext } from "../../identityServer";

export const useContextualMessagingContext = () => {
    const client = useContextualMessagingClient();

    return { ...client };
};

const useContextualMessagingClient = () => {
    const apiQueue = useApiQueueContext();
    const { access_token } = useIdentityConnectContext();

    async function AlertsDestination(request: ContextualAlertsDestinationRequest): Promise<AxiosResponse<any, any>> {
        return await UsePost(`api/v1/contextualmessaging/alerts/destination`, access_token, request);
    }

    async function AlertsGet(accountHandle: string): Promise<AxiosResponse<ContextualAlerts[], any>> {
        return await apiQueue.Get(`api/v1/contextualmessaging/alerts?accountHandle=${accountHandle}`, access_token);
    }

    async function AlertsPost(request: ContextualAlertsPostRequest): Promise<AxiosResponse<any, any>> {
        return await UsePost(`api/v1/contextualmessaging/alerts`, access_token, request);
    }

    async function ControlsUnlock(request: ContextualControlsUnlockRequest): Promise<AxiosResponse<any, any>> {
        return await UsePost(`api/v1/contextualmessaging/controls/unlock`, access_token, request);
    }

    async function ControlsLock(request: ContextualControlsLockRequest): Promise<AxiosResponse<any, any>> {
        return await UsePost(`api/v1/contextualmessaging/controls/lock`, access_token, request);
    }

    async function ControlsStatus(
        accountHandle: string
    ): Promise<AxiosResponse<ContextualControlsStatusResponse, any>> {
        return await apiQueue.Get(
            `api/v1/contextualmessaging/controls/status?accountHandle=${accountHandle}`,
            access_token
        );
    }

    return {
        AlertsDestination,
        AlertsGet,
        AlertsPost,
        ControlsStatus,
        ControlsLock,
        ControlsUnlock
    };
};
