import { ReactElement } from "react";
import { IndexedApplication } from "../../..";
import { ButtonPro } from "../../../generic";
import { CardApplicationViewProps } from "../cardApplicationView";

export function CardApplicationHeader(props: CardApplicationViewProps): ReactElement {
    return (
        <div className="cardAppHeader">
            <div className="cardAppHeader-content">
                <IndexedApplication icon account={props.account} />

                {!props.isDashboard === true ? (
                    <></>
                ) : (
                    <ButtonPro
                        id="cardAppHeader-actionsButton"
                        variant="secondary"
                        onClick={() => props.viewApplication()}
                    >
                        View Application
                    </ButtonPro>
                )}
            </div>
        </div>
    );
}
