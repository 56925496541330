import { createContext, useState } from "react";
import { useLocalStorage, useSessionStorage } from "../../../hooks";
import { NotImplemented } from "../../../utility";

export type IdentityConnectProps = {
    access_token: string;
    setAccessToken: React.Dispatch<React.SetStateAction<string>>;
    refresh_token: string;
    setRefreshToken: React.Dispatch<React.SetStateAction<string>>;
};

export const IdentityConnectContext = createContext<IdentityConnectProps>({
    access_token: "",
    setAccessToken: NotImplemented,
    refresh_token: "",
    setRefreshToken: NotImplemented
});

export const useIdentityConnectProps = (): IdentityConnectProps => {
    const [session] = useSessionStorage(["access_token"]);
    const [local] = useLocalStorage(["refresh_token"]);
    const [access_token, setAccessToken] = useState(session["access_token"] || "");
    const [refresh_token, setRefreshToken] = useState(local["refresh_token"] || "");

    return { access_token, setAccessToken, refresh_token, setRefreshToken };
};
