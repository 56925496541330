import { ReactElement } from "react";
import { Modal, ModalProps } from "../../../generic";
import { AmountSelection } from "../schedulePayment/paymentAmountOption/paymentAmountOption";
import PaymentSuccessView from "./paymentSuccessView";
import { usePaymentSuccessViewModel } from "./paymentSuccessViewModel";

export type PaymentSuccessProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    successId: number;
    setPaymentAccountId: React.Dispatch<React.SetStateAction<number>>;
    setAmountSelection: React.Dispatch<React.SetStateAction<AmountSelection>>;
};

export function PaymentSuccessModal(props: ModalProps & PaymentSuccessProps): ReactElement {
    return (
        <Modal show={props.show}>
            <PaymentSuccessModel {...props} />
        </Modal>
    );
}

export function PaymentSuccessModel(props: PaymentSuccessProps): ReactElement {
    const { state } = usePaymentSuccessViewModel();

    function onClose() {
        props.setPaymentAccountId(0);
        props.setAmountSelection("");
        props.setShow(false);
    }

    return <PaymentSuccessView {...props} {...state} onClose={onClose} />;
}
