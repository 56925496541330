import { ReactElement } from "react";
import { Modal } from "..";
import { ButtonVariant } from "../inputs/buttonPro/buttonPro";
import { GenericModalView } from "./genericModalView";
import { ModalProps } from "./modalModel";
import { useModalViewModel } from "./modalViewModel";

export type GenericModalProps = {
    isBranded?: boolean;
    headerText: string;
    subtitle?: string | ReactElement;
    bodyText?: string;
    alertText?: string;
    children?: ReactElement;
    primaryButton: ModalButton;
    secondaryButton?: ModalButton;
};

export type ModalButton = {
    text: string;
    variant?: ButtonVariant;
    action: Function | (() => void);
    className?: string;
};

export function GenericModalProvider(props: ModalProps & GenericModalProps): ReactElement {
    return (
        <Modal show={props.show}>
            <GenericModalModel {...props} />
        </Modal>
    );
}

function GenericModalModel(props: GenericModalProps): ReactElement {
    const { state } = useModalViewModel();

    if (props.primaryButton.variant === undefined) props.primaryButton.variant = "primary";

    if (props.secondaryButton !== undefined && props.secondaryButton.variant === undefined)
        props.secondaryButton.variant = "primary";

    return <GenericModalView {...props} {...state} />;
}
