import { ReactElement } from "react";
import { Modal, ModalProps } from "../../../generic";
import { DeliveryPreferencesPromptView } from "./deliveryPreferencesPromptView";
import { useDeliveryPreferencesPromptViewModel } from "./deliveryPreferencesPromptViewModel";

export type DeliveryPreferencesPromptProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowTerms: React.Dispatch<React.SetStateAction<boolean>>;
};

export function DeliveryPreferencesPrompt(props: ModalProps & DeliveryPreferencesPromptProps): ReactElement {
    return (
        <Modal show={props.show}>
            <DeliveryPreferencesPromptModel {...props} />
        </Modal>
    );
}

function DeliveryPreferencesPromptModel(props: DeliveryPreferencesPromptProps): ReactElement {
    const { hooks, state } = useDeliveryPreferencesPromptViewModel();

    function onCancel() {
        hooks.storeValue("deliveryLandingPrompted", true);
        props.setShow(false);
    }

    function onSubmit() {
        hooks.storeValue("deliveryLandingPrompted", true);
        props.setShow(false);
        props.setShowTerms(true);
    }

    return <DeliveryPreferencesPromptView {...props} {...state} onCancel={onCancel} onSubmit={onSubmit} />;
}
