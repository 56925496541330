import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { GenericModal, ModalProps } from "../../../generic";

type PinExpiredProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export function PinExpired(props: ModalProps & PinExpiredProps): ReactElement {
    const navigate = useNavigate();

    const modalProps = {
        show: props.show,
        headerText: "Code Expired",
        bodyText: "Request new code?",
        primaryButton: {
            text: "Close",
            action: () => {
                props.setShow(false);
                navigate("/auth/login");
            }
        },
        secondaryButton: {
            text: "Request New Code",
            action: () => {
                props.setShow(false);
                navigate("/auth/request-code");
            }
        }
    };

    return <GenericModal {...modalProps} />;
}
