import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function AddUser(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.25 17.25C11.25 18.8413 11.8821 20.3674 13.0074 21.4926C14.1326 22.6179 15.6587 23.25 17.25 23.25C18.8413 23.25 20.3674 22.6179 21.4926 21.4926C22.6179 20.3674 23.25 18.8413 23.25 17.25C23.25 15.6587 22.6179 14.1326 21.4926 13.0074C20.3674 11.8821 18.8413 11.25 17.25 11.25C15.6587 11.25 14.1326 11.8821 13.0074 13.0074C11.8821 14.1326 11.25 15.6587 11.25 17.25Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M17.25 14.25V20.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.25 17.25H20.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M0.75 17.2501C0.75076 16.1342 1.02804 15.0359 1.55706 14.0533C2.08607 13.0708 2.85033 12.2347 3.78149 11.6197C4.71265 11.0047 5.7817 10.6301 6.89305 10.5293C8.00439 10.4285 9.12339 10.6047 10.15 11.0421"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.375 4.875C3.375 5.96902 3.8096 7.01823 4.58318 7.79182C5.35677 8.5654 6.40598 9 7.5 9C8.59402 9 9.64323 8.5654 10.4168 7.79182C11.1904 7.01823 11.625 5.96902 11.625 4.875C11.625 3.78098 11.1904 2.73177 10.4168 1.95818C9.64323 1.1846 8.59402 0.75 7.5 0.75C6.40598 0.75 5.35677 1.1846 4.58318 1.95818C3.8096 2.73177 3.375 3.78098 3.375 4.875V4.875Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
