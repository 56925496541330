import { useEffect } from "react";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { useSessionStorage, useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { SetTitle } from "../../../utility";

type Error403ViewModel = {
    hooks: Error403Hooks;
    state: Error403State;
};

type Error403Hooks = {
    navigate: NavigateFunction;
};

export type Error403State = {
    size: Size;
    location: Location;
};

export function useError403ViewModel(): Error403ViewModel {
    const { size } = useViewport(true, true);
    const location = useLocation();
    const navigate = useNavigate();
    const [, , removeLocal] = useSessionStorage(["access_token"]);

    useEffect(() => {
        removeLocal(["access_token"]);

        SetTitle("Forbidden");

        return () => {
            SetTitle("");
        };
    }, []);

    return {
        hooks: { navigate },
        state: {
            size,
            location
        }
    };
}
