import { useAccountContext, UserAccount } from "../../../../../api/backend";
import { useViewport } from "../../../../../hooks";
import { Size } from "../../../../../types";

export type PaymentDueDateState = {
    size: Size;
    account: UserAccount | undefined;
};

const usePaymentDueDateState = () => {
    const { size } = useViewport(true, true);
    const { accounts, accountIndex } = useAccountContext();
    const account = accounts[accountIndex];

    return {
        size,
        account
    };
};

export const usePaymentDueDateViewModel = () => {
    const state = usePaymentDueDateState();

    return {
        state
    };
};
