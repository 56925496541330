import { createContext, useState } from "react";
import { UserAccount } from ".";
import { NotImplemented } from "../../../utility";

export type AccountContextProps = {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    accounts: UserAccount[] | undefined;
    setAccounts: React.Dispatch<React.SetStateAction<UserAccount[] | undefined>>;
    accountIndex: number;
    setAccountIndex: React.Dispatch<React.SetStateAction<number>>;
};

export const AccountContext = createContext<AccountContextProps>({
    loading: false,
    setLoading: NotImplemented,
    accounts: undefined,
    setAccounts: NotImplemented,
    accountIndex: 0,
    setAccountIndex: NotImplemented
});

export const useAccountContextProps = (): AccountContextProps => {
    const [loading, setLoading] = useState(false);
    const [accounts, setAccounts] = useState<UserAccount[] | undefined>(undefined);
    const [accountIndex, setAccountIndex] = useState(0);

    return {
        loading,
        setLoading,
        accounts,
        setAccounts,
        accountIndex,
        setAccountIndex
    };
};
