import { ReactElement } from "react";
import { IsNullOrEmpty } from "../../../utility";
import { ButtonPro } from "../inputs/buttonPro/buttonPro";
import "./icon.scss";

type IIconProps = {
    svg: ReactElement;

    name?: string;
    className?: string;
    caption?: string;
    onClick?: () => any;
    clickRestricted?: boolean;

    title?: string;
};

export function Icon({ props }: { props: IIconProps }): ReactElement {
    const cn = `icon-content${props.className !== undefined ? " " + props.className : ""}`;

    return (
        <>
            {props.onClick === undefined ? (
                <div title={props.title} className={cn}>
                    {props.svg}
                    {!IsNullOrEmpty(props.caption) ? <p>{props.caption}</p> : <></>}
                </div>
            ) : (
                <ButtonPro
                    name={props.name}
                    className={cn}
                    variant="icon"
                    type="button"
                    onClick={props.onClick}
                    unblock
                >
                    <div title={props.title} className={cn}>
                        {props.svg}
                        {!IsNullOrEmpty(props.caption) ? <p className="p-margin">{props.caption}</p> : <></>}
                    </div>
                </ButtonPro>
            )}
        </>
    );
}

export function getColor(type: "color" | "background" | "alt" | "crit", pallet?: string, fallback?: string) {
    var fb =
        fallback !== undefined
            ? fallback
            : type === "color"
              ? "#42967D"
              : type === "background"
                ? "#FFFFFF"
                : type === "alt"
                  ? "#000000"
                  : "#C93A3A";

    return pallet !== undefined ? pallet : fb;
}
