import { ReactElement } from "react";

import { SelectPaymentAccountView } from "./selectPaymentAccountView";
import { useSelectPaymentAccountViewModel } from "./selectPaymentAccountViewModel";

export type SelectPaymentAccountProps = {
    paymentAccountId: number;
    setPaymentAccountId: React.Dispatch<React.SetStateAction<number>>;
    onAddPaymentAccount: Function;
    onPaymentAccountClick: Function;
};

export function SelectPaymentAccount(props: SelectPaymentAccountProps): ReactElement {
    const { state } = useSelectPaymentAccountViewModel(props);

    return <SelectPaymentAccountView {...props} {...state} />;
}
