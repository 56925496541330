import { AxiosResponse } from "axios";
import { CreditLineIncreaseGetResponse } from ".";
import { useApiQueueContext } from "../../../contexts";
import { useIdentityConnectContext } from "../../identityServer";

export const useCreditLineIncreaseContext = () => {
    const client = useCreditLineIncreaseClient();

    return { ...client };
};

const useCreditLineIncreaseClient = () => {
    const apiQueue = useApiQueueContext();
    const { access_token } = useIdentityConnectContext();

    function Get(accountHandle: string): Promise<AxiosResponse<CreditLineIncreaseGetResponse, any>> {
        return apiQueue.Get(`api/v1/creditlineincrease?accountHandle=${accountHandle}`, access_token);
    }

    return { Get };
};
