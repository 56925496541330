import { AxiosResponse } from "axios";
import { useContext } from "react";
import { DocUploadRequest, DocumentReasons } from ".";
import { UseGet, UsePost } from "../../../hooks";
import { useIdentityConnectContext } from "../../identityServer";
import { DocUploadContext } from "./docUploadContext";

export const useDocUploadContext = () => {
    const context = useContext(DocUploadContext);
    const client = useDocUploadClient();

    return { ...context, ...client };
};

const useDocUploadClient = () => {
    const context = useContext(DocUploadContext);
    const { access_token } = useIdentityConnectContext();

    async function GetDocumentReasons(accountHandle: string): Promise<AxiosResponse<DocumentReasons[], any>> {
        context.setLoading(true);
        return await UseGet<DocumentReasons[]>(
            `api/v1/docupload/documentreasons?accountHandle=${accountHandle}`,
            access_token
        )
            .then((x) => {
                return x;
            })
            .finally(() => {
                context.setLoading(false);
            });
    }

    async function Post(request: DocUploadRequest): Promise<AxiosResponse<any, any>> {
        context.setUploading(true);

        const formData = new FormData();
        formData.append("AccountHandle", request.accountHandle);
        formData.append("DocumentReasonId", request.documentReasonId.toString());
        for (const file of request.files) {
            formData.append("Files", file);
        }

        return await UsePost("api/v1/DocUpload", access_token, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then((x) => {
                return x;
            })
            .finally(() => {
                context.setUploading(false);
            });
    }

    return { GetDocumentReasons, Post };
};
