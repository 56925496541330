import { CmsToolTip, useAccountContext, UserAccount } from "../../../../../api/backend";
import { useTooltipCms, useViewport } from "../../../../../hooks";
import { Size } from "../../../../../types";
import { FormatMoney } from "../../../../../utility";

export type MinAmountDueState = {
    size: Size;
    cms: CmsToolTip;
    account: UserAccount | undefined;
    dollars: string;
    cents: string;
};

const useMinAmountDueState = (): MinAmountDueState => {
    const { size } = useViewport(true, true);
    const { getTooltip } = useTooltipCms();
    const cms = getTooltip("totalMinDueToolTip");
    const { accounts, accountIndex } = useAccountContext();
    const account = accounts[accountIndex];
    const { dollars, cents } = FormatMoney(account?.minimumAmountDue, true);

    return {
        size,
        cms,
        account,
        dollars,
        cents
    };
};

export const useMinAmountDueViewModel = () => {
    const state = useMinAmountDueState();

    return {
        state
    };
};
