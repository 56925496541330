import { ReactElement } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownToggle, GenericModal } from "../../../generic";
import { DocUploadPrime } from "../../modals/docUploadPrime";
import { LockCardDisabled } from "../../modals/lockCardDisabled";
import { LockCardDisabledStatus } from "../../modals/lockCardDisabledStatus";
import { ManageAlertsDisabled } from "../../modals/manageAlertsDisabled";
import { ManageAlertsUnavailable } from "../../modals/manageAlertsUnavailable";
import { NavMenuProps } from "./navMenu";
import { NavMenuState } from "./navMenuViewModel";

type NavMenuViewProps = NavMenuProps &
    NavMenuState & {
        GetNavMenuOptions: Function;
    };

export function NavMenuView(props: NavMenuViewProps): ReactElement {
    let selected = props.isResponsive === true || props.services.current.find((x) => x.isSelected) !== undefined;
    let { menu, options } = props.GetNavMenuOptions();

    return (
        <>
            {options > 0 ? (
                <Dropdown className="cardNav-dropdown">
                    <Dropdown.Toggle as={DropdownToggle(props.account.accent, selected)} className="cardNav-toggle">
                        <div className="cardNav-toggleContent">
                            <h6 className={selected ? props.cardNumberCN : "cardNav-cardNumber"}>I Want To...</h6>
                        </div>
                    </Dropdown.Toggle>
                    {menu}
                </Dropdown>
            ) : (
                <></>
            )}

            <DocUploadPrime show={props.showDocUplPrimeModal} setShow={props.setShowDocUplPrimeModal} />
            <ManageAlertsDisabled
                show={props.showManageAlertsDisabledModal}
                setShow={props.setShowManageAlertsDisabledModal}
            />
            <LockCardDisabledStatus
                show={props.showLockCardDisabledFromStatusModal}
                setShow={props.setShowLockCardDisabledFromStatusModal}
            />
            <LockCardDisabled
                show={props.showLockCardDisabledModal}
                setShow={props.setShowLockCardDisabledModal}
                account={props.account}
            />
            <ManageAlertsUnavailable
                show={props.showManageAlertsUnavailableModal}
                setShow={props.setShowManageAlertsUnavailableModal}
            />
            <GenericModal show={props.showPCPUnavailableModal} {...props.PCPUnavailable} />
        </>
    );
}
