import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Lock(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.75 9.75H5.25C4.42157 9.75 3.75 10.4216 3.75 11.25V21.75C3.75 22.5784 4.42157 23.25 5.25 23.25H18.75C19.5784 23.25 20.25 22.5784 20.25 21.75V11.25C20.25 10.4216 19.5784 9.75 18.75 9.75Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.75 9.75V6C6.75 4.60761 7.30312 3.27226 8.28769 2.28769C9.27226 1.30312 10.6076 0.75 12 0.75C13.3924 0.75 14.7277 1.30312 15.7123 2.28769C16.6969 3.27226 17.25 4.60761 17.25 6V9.75"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 15.75C11.9258 15.75 11.8533 15.772 11.7917 15.8132C11.73 15.8544 11.6819 15.913 11.6535 15.9815C11.6252 16.05 11.6177 16.1254 11.6322 16.1982C11.6467 16.2709 11.6824 16.3377 11.7348 16.3902C11.7873 16.4426 11.8541 16.4783 11.9268 16.4928C11.9996 16.5073 12.075 16.4998 12.1435 16.4715C12.212 16.4431 12.2706 16.395 12.3118 16.3333C12.353 16.2717 12.375 16.1992 12.375 16.125C12.3751 16.0757 12.3655 16.0269 12.3467 15.9813C12.3279 15.9358 12.3003 15.8944 12.2655 15.8595C12.2306 15.8247 12.1892 15.7971 12.1437 15.7783C12.0981 15.7595 12.0493 15.7499 12 15.75V15.75Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
