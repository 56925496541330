import { ReactElement } from "react";
import { IndexedCreditCard } from "../../../../generic";
import { ArrowRight } from "../../../../generic/icons";
import { CardBalance } from "../../../cardTiles";
import { DashboardCardViewProps } from "../dashboardCardView";
import "./dashboardCardAlt.scss";

export function DashboardCardAltView(props: DashboardCardViewProps): ReactElement {
    return (
        <>
            <div
                className="dashboardCardAlt"
                data-testid="dashboardCardAlt"
                onClick={() => {
                    props.onCardSelected(props.index);
                }}
            >
                <div className="dashboardCardAlt-header">
                    <IndexedCreditCard
                        icon
                        account={props.account}
                        size="h4"
                        notification={props.notification}
                        notificationNumber={props.alertCount.toString()}
                    />
                    <ArrowRight className={`dashboardCardAlt-icon-${props.account.accent}`} />
                </div>
                <div className="dashboardCardAlt-body">
                    <CardBalance {...props} />
                </div>
            </div>
        </>
    );
}
