import { Form, Formik } from "formik";
import { ReactElement, useEffect } from "react";
import { ElectronicDeliveryPreferences, useDigitalActionsContext, UserAccount } from "../../../api/backend";
import { FormProps } from "../../../types";
import { SetTitle } from "../../../utility";
import { AsyncElement, BackArrow, ButtonPro, CheckboxPro } from "../../generic";
import { Warning } from "../../generic/icons";
import { IndexedCreditCard } from "../../generic/visuals/indexedCreditCard/indexedCreditCard";
import { DeliveryPreferencesConfirmationModel } from "./deliveryPreferencesConfirmation/deliveryPreferencesConfirmationModel";
import { DeliveryPreferencesTermsModel } from "./deliveryPreferencesTerms/deliveryPreferencesTermsModel";
import "./updateDeliveryPreferences.scss";
import { UpdateDeliveryPreferencesState } from "./updateDeliveryPreferencesViewModel";

type updateDeliveryPreferencesViewProps = UpdateDeliveryPreferencesState & {
    rows: ReactElement[];
    goToDashboard: Function;
    onSubmit: Function;
    validate: Function;
    setDelPrefTimer: Function;
};

export function UpdateDeliveryPreferencesView(props: updateDeliveryPreferencesViewProps): ReactElement {
    useEffect(() => {
        SetTitle("Delivery Preferences");
        return () => SetTitle("");
    }, []);

    return (
        <div id="updateDeliveryPreferences">
            <BackArrow available to="/account/dashboard">
                <h2>Delivery Preferences</h2>
            </BackArrow>

            <div id="updateDeliveryPreferences-content">
                {props.isEMessengerDown ? (
                    <EMessengerUnavailable {...props} />
                ) : (
                    <Formik
                        initialValues={props.initialValues}
                        enableReinitialize
                        validate={(values) => props.validate(values)}
                        onSubmit={(values, actions) => {
                            props.onSubmit(values, actions);
                        }}
                    >
                        {(formProps: FormProps<any>) => (
                            <Form className="updateDeliveryPreferences-form">
                                <div id={`updateDeliveryPreferences-header${props.size}`}>
                                    <div id={`updateDeliveryPreferences-subheader`}>
                                        <h4>Electronic Delivery</h4>
                                        <h6>Go paperless to receive your communications electronically.</h6>
                                    </div>
                                    {props.editPreferences ? (
                                        <div className="updateDeliveryPreferences-header--edit-buttons">
                                            <ButtonPro
                                                id="cancel"
                                                type="button"
                                                variant="secondary"
                                                onClick={() => {
                                                    formProps.resetForm(props.initialValues);
                                                    props.setEditPreferences(false);
                                                }}
                                            >
                                                Cancel
                                            </ButtonPro>
                                            <ButtonPro
                                                id="save"
                                                type="submit"
                                                isSubmitting={formProps.isSubmitting}
                                                disabled={!props.canSave}
                                            >
                                                Save Changes
                                            </ButtonPro>
                                        </div>
                                    ) : (
                                        <ButtonPro
                                            variant="primary"
                                            className="updateDeliveryPreferences-header-button"
                                            onClick={() => {
                                                props.setEditPreferences(true);
                                            }}
                                            disabled={!props.canEditPreferences || props.loading}
                                        >
                                            Edit Preferences
                                        </ButtonPro>
                                    )}
                                </div>
                                <AsyncElement loading={props.loading}>
                                    <div className={`updateDeliveryPreferences-accounts${props.size}`}>
                                        {props.rows}
                                    </div>
                                </AsyncElement>
                                <DeliveryPreferencesTermsModel
                                    show={props.showDelPrefTermsModal}
                                    setShow={props.setShowDelPrefTermsModal}
                                    setShowDelPrefConfirmModal={props.setShowDelPrefConfirmModal}
                                    setEditPreferences={props.setEditPreferences}
                                    initialValues={props.initialValues}
                                    selectedValues={props.selectedValues}
                                    setSelectedValues={props.setSelectedValues}
                                    resetForm={() => formProps.resetForm({ values: props.initialValues })}
                                    setDelPrefTimer={() => props.setDelPrefTimer()}
                                    setTempDeliveryPreferences={props.setTempDeliveryPreferences}
                                />
                                <DeliveryPreferencesConfirmationModel
                                    show={props.showDelPrefConfirmModal}
                                    setShow={props.setShowDelPrefConfirmModal}
                                    tempDeliveryPreferences={props.tempDeliveryPreferences}
                                    setTempDeliveryPreferences={props.setTempDeliveryPreferences}
                                    resetForm={() => formProps.resetForm({ values: props.initialValues })}
                                    confirmRows={props.confirmRows}
                                />
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    );
}

type AccountPreferenceRowProps = {
    account: UserAccount;
    index: number;
    preferences: ElectronicDeliveryPreferences[] | undefined;
    editPreferences: boolean;
    confirmedRow: boolean;
};

export function AccountPreferenceConfirmedRow(props: AccountPreferenceRowProps): ReactElement {
    let preference = accountPreference(props.preferences, props.account.accountHandle);
    return (
        <>
            {preference?.changed === true ? (
                <AccountPreferenceRow
                    key={props.account.accountHandle}
                    account={props.account}
                    index={props.index}
                    preferences={props.preferences}
                    editPreferences={props.editPreferences}
                    confirmedRow={props.confirmedRow}
                />
            ) : (
                <></>
            )}
        </>
    );
}

export function AccountPreferenceRow(props: AccountPreferenceRowProps): ReactElement {
    const { actionAllowed } = useDigitalActionsContext();
    let preference = accountPreference(props.preferences, props.account.accountHandle);
    let preferenceLabel =
        preference?.changed === true && !props.confirmedRow
            ? "Processing"
            : accountPreferenceLabel(preference, actionAllowed);
    let className =
        preferenceLabel === "On"
            ? "updateDeliveryPreferences-row-enrolled"
            : preferenceLabel === "Off"
              ? "updateDeliveryPreferences-row-unenrolled"
              : "updateDeliveryPreferences-row-processing";

    return (
        <>
            {preference?.eStatementsEnrollment.changesAllowed ||
            preference?.eLettersEnrollment.changesAllowed ||
            preferenceLabel === "Processing" ? (
                <>
                    <div className="updateDeliveryPreferences-column-1 column-underline">
                        <IndexedCreditCard account={props.account} />
                    </div>
                    <div className="updateDeliveryPreferences-column-2 column-underline">
                        <div className="updateDeliveryPreferences-row">
                            {preferenceLabel !== "Processing" ? "Electronic Delivery: " : ""}
                            <div className={className}>
                                {props.editPreferences &&
                                preferenceLabel !== "Processing" &&
                                preference?.changed === undefined ? (
                                    <CheckboxPro
                                        id={`checkBox-${props.index}`}
                                        name={`checkBox-${props.index}`}
                                        className="updateDeliveryPreferences-row-checkbox"
                                    />
                                ) : (
                                    <>{preferenceLabel}</>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    );
}

export function AccountPreferencesSave({ save }: { save: Function }): ReactElement {
    return (
        <div className="updateDeliveryPreferences-column-2">
            <ButtonPro onClick={save} btnSize="sm" disabled>
                Save
            </ButtonPro>
        </div>
    );
}

export function accountPreference(
    preferences: ElectronicDeliveryPreferences[] | undefined,
    accountHandle: string
): ElectronicDeliveryPreferences | undefined {
    if (preferences) {
        let preference = preferences.findIndex((p) => {
            return p.accountHandle === accountHandle;
        });

        if (preference !== undefined) {
            return preferences[preference];
        }
    }

    return undefined;
}

function accountPreferenceLabel(
    preference: ElectronicDeliveryPreferences | undefined,
    actionAllowed: (option: string, accountHandle?: string) => boolean
): string {
    let eStatementAllowed = actionAllowed("eStatementEnroll", preference?.accountHandle);
    let eLetterAllowed = actionAllowed("eLetterEnroll", preference?.accountHandle);

    if (eStatementAllowed) {
        return preference?.eStatementsEnrollment.enrollmentStatus === "Enrolled"
            ? "On"
            : preference?.eStatementsEnrollment.enrollmentStatus === "Processing"
              ? "Processing"
              : "Off";
    } else if (eLetterAllowed) {
        return preference?.eLettersEnrollment.enrollmentStatus === "Enrolled"
            ? "On"
            : preference?.eLettersEnrollment.enrollmentStatus === "Processing"
              ? "Processing"
              : "Off";
    }

    return "Processing";
}

function EMessengerUnavailable(props: updateDeliveryPreferencesViewProps): ReactElement {
    return (
        <div id="updateDeliveryPreferencesUnavailable">
            <div className="updateDeliveryPreferences-unavailable">
                <div className="updateDeliveryPreferences-unavailable-header">
                    <div className="updateDeliveryPreferences-unavailable-icon">
                        <Warning className="updateDeliveryPreferences-unavailable-warning-icon" />
                    </div>
                    <div className="updateDeliveryPreferences-unavailable-content">
                        <div className="updateDeliveryPreferences-unavailable-header-text">
                            The Delivery Preferences Page is Temporarily Unavailable
                        </div>
                        <div className="updateDeliveryPreferences-unavailable-text">Please check back in 2-3 hours</div>
                    </div>
                </div>
            </div>
            <div className="updateDeliveryPreferences-unavailable-buttons">
                <ButtonPro
                    className={`updateDeliveryPreferences-unavailable-button${props.size}`}
                    variant="primary"
                    onClick={() => props.goToDashboard()}
                >
                    Back to Dashboard
                </ButtonPro>
            </div>
        </div>
    );
}
