import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Copy(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg id={id} className={svgClass} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6667 4.66699H7C6.73478 4.66699 6.48043 4.77235 6.29289 4.95989C6.10536 5.14742 6 5.40178 6 5.66699V21.667C6 21.9322 6.10536 22.1866 6.29289 22.3741C6.48043 22.5616 6.73478 22.667 7 22.667H19.6667C19.9319 22.667 20.1862 22.5616 20.3738 22.3741C20.5613 22.1866 20.6667 21.9322 20.6667 21.667V5.66699C20.6667 5.40178 20.5613 5.14742 20.3738 4.95989C20.1862 4.77235 19.9319 4.66699 19.6667 4.66699ZM19.3333 21.3337H7.33333V6.00033H19.3333V21.3337Z" />
            <path d="M17.3337 2.33301C17.3337 2.06779 17.2283 1.81344 17.0408 1.6259C16.8532 1.43836 16.5989 1.33301 16.3337 1.33301H3.66699C3.40178 1.33301 3.14742 1.43836 2.95989 1.6259C2.77235 1.81344 2.66699 2.06779 2.66699 2.33301V18.333C2.66699 18.5982 2.77235 18.8526 2.95989 19.0401C3.14742 19.2277 3.40178 19.333 3.66699 19.333H4.00033V2.66634H17.3337V2.33301Z" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
