export function waitUntil(isTrue: () => boolean, interval: number = 500, timeout: number = 5000): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const intervalId = setInterval(() => {
            if (isTrue()) {
                clearInterval(intervalId);
                resolve(true);
            }
        }, interval);

        setTimeout(() => {
            clearInterval(intervalId);
            resolve(false);
        }, timeout);
    });
}
