import { AxiosError } from "axios";
import React, { ReactElement, useEffect } from "react";
import { UUID } from "../../../utility";
import { LoginDeliverMfaView } from "./loginDeliverMfaView";
import { useLoginDeliverMfaViewModel } from "./loginDeliveryMfaViewModel";

export function LoginDeliverMfaModel(): ReactElement {
    const { hooks, state, api } = useLoginDeliverMfaViewModel();

    useEffect(() => {
        if (state.selection.type !== undefined) state.setCanContinue(true);

        if (state.mfaOptions === undefined) {
            hooks.navigate("/auth/login");
        }
    }, [state.selection, state.mfaOptions]);

    const cancelModal = React.useCallback(() => {
        state.setShowCancel(true);
    }, [state.setShowCancel]);

    function onShowAssistance() {
        state.setShowAssistance(true);
        hooks.storeValue("mfaCustServReload", true);
    }

    function onSubmit() {
        if (state.securityType !== "Enrollment" && state.securityType !== "OMT") {
            submitLogin();
        } else {
            submitEnroll();
        }
    }

    function submitLogin() {
        if (state.selection.value && state.mfaOptions && state.mfaOptions?.accountId && state.mfaOptions?.onlineUserId)
            api.sendCode().then((res) => {
                hooks.navigate("/auth/enter-code");
            });
    }

    function submitEnroll() {
        let sessionId = state.searchParams.get("id");

        if (sessionId && UUID.Valid(sessionId)) {
            api.enrollSendCode(sessionId)
                .then((res) => {
                    hooks.navigate({
                        pathname: "/auth/enter-code",
                        search: `?id=${sessionId}`
                    });
                })
                .catch((err: AxiosError) => {
                    state.setShowError(true);
                });
        } else {
            hooks.navigate("/auth/login");
        }
    }

    return (
        <LoginDeliverMfaView
            {...state}
            cancelModal={cancelModal}
            onSubmit={onSubmit}
            onShowAssistance={onShowAssistance}
        />
    );
}
