import { ReactElement } from "react";
import { Alert, AsyncElement } from "../../generic";
import { EnrollInPaperlessAlertState } from "./enrollinPaperlessAlertViewModel";

type EnrollInPaperlessAlertViewProps = EnrollInPaperlessAlertState & {
    onMore: Function;
};

export function EnrollInPaperlessAlertView(props: EnrollInPaperlessAlertViewProps): ReactElement {
    return (
        <AsyncElement loading={props.loading}>
            {props.showPaperlessAlert ? (
                <>
                    <Alert
                        level={"warning"}
                        text={"We see you're not enrolled in paperless."}
                        ctaText="Enroll here"
                        more={() => props.onMore()}
                    />
                </>
            ) : (
                <></>
            )}
        </AsyncElement>
    );
}
