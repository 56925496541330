import { AxiosResponse } from "axios";
import { FormikHelpers } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    CCPAPrivateRequest,
    CCPAPublicRequest,
    Profile,
    PublicCCPAPageConfiguration,
    ReCaptchaResponse,
    UserAccount,
    useAccountContext,
    useCcpaContext,
    useProfileContext,
    useReCaptchaContext
} from "../../../../api/backend";
import { useIdentityConnectContext } from "../../../../api/identityServer";
import { Size } from "../../../../types";
import { GenericModalProps } from "../../../generic";
import { CCPAAnonymousFormFields } from "./ccpaAnonymous/ccpaAnonymousView";
import { CCPAAuthenticatedFormFields } from "./ccpaAuthenticated/ccpaAuthenticatedView";

export type CCPAFormModelProps = {
    cms: PublicCCPAPageConfiguration;
    size: Size;
};

type CCPAFormViewModel = {
    state: CCPAFormState;
    api: CCPAFormApi;
};

export type CCPAFormState = {
    showSuccess: boolean;
    setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    successProps: GenericModalProps;
    showCancel: boolean;
    setShowCancel: React.Dispatch<React.SetStateAction<boolean>>;
    cancelProps: GenericModalProps;
    access_token: string;
    accounts: UserAccount[] | undefined;
};

type CCPAFormApi = {
    ccpaPublic(values: CCPAAnonymousFormFields): Promise<AxiosResponse<any, any>>;
    ccpaPrivate(
        values: CCPAAuthenticatedFormFields,
        actions: FormikHelpers<CCPAAuthenticatedFormFields>
    ): Promise<AxiosResponse<any, any>>;
    ccpaReCaptcha(token: string): Promise<AxiosResponse<ReCaptchaResponse, any>>;
};

export function useCCPAFormViewModel(props: CCPAFormModelProps & { isPublic: boolean }): CCPAFormViewModel {
    const navigate = useNavigate();
    const { access_token } = useIdentityConnectContext();
    const { Get } = useProfileContext();
    const { Get: getCaptcha } = useReCaptchaContext();
    const { Public, Private } = useCcpaContext();
    const { accounts, accountIndex } = useAccountContext();
    const [showSuccess, setShowSuccess] = useState(false);
    const [showCancel, setShowCancel] = useState(false);

    const successProps = {
        headerText: props.isPublic ? "Form Submitted" : "Success!",
        bodyText: props.isPublic
            ? props.cms.SuccessMessage
            : "You have successfully opted out of having your information shared with third parties.",
        primaryButton: {
            text: props.isPublic ? "Close" : "Return Home",
            className: "ccpa-mobile-button",
            action: () => {
                setShowSuccess(false);

                if (props.isPublic) {
                    navigate("/auth/login");
                } else {
                    navigate("/account/dashboard");
                }
            }
        }
    } as GenericModalProps;

    const cancelProps = {
        headerText: "Cancel CCPA Request?",
        bodyText: "Are you sure you would like to cancel your CCPA request?",
        primaryButton: {
            text: "Cancel CCPA Request",
            action: () => {
                setShowCancel(false);
                navigate("/home/privacy");
            }
        },
        secondaryButton: {
            text: "Not Now",
            variant: "tertiary",
            action: () => {
                setShowCancel(false);
            }
        }
    } as GenericModalProps;

    async function ccpaPublic(values: CCPAAnonymousFormFields): Promise<AxiosResponse<any, any>> {
        return Public(createPublicRequest(values));
    }

    async function ccpaPrivate(
        values: CCPAAuthenticatedFormFields,
        actions: FormikHelpers<CCPAAuthenticatedFormFields>
    ): Promise<any> {
        return Get(accounts![accountIndex].accountHandle).then((res) => {
            Private(createPrivateRequest(values, accounts![accountIndex], res.data))
                .then(() => {
                    setShowSuccess(true);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    actions.setSubmitting(false);
                });
        });
    }

    async function ccpaReCaptcha(token: string): Promise<AxiosResponse<ReCaptchaResponse, any>> {
        return getCaptcha(token);
    }

    return {
        state: {
            showSuccess,
            setShowSuccess,
            successProps,
            showCancel,
            setShowCancel,
            cancelProps,
            access_token,
            accounts
        },
        api: {
            ccpaPublic,
            ccpaPrivate,
            ccpaReCaptcha
        }
    };
}

const createPublicRequest = (values: CCPAAnonymousFormFields): CCPAPublicRequest => {
    return {
        privacyInfo: {
            categoriesSources: values.categoriesSources,
            specificData: values.specificData,
            requestData: values.requestData,
            deleteData: values.deleteData,
            deleteConfirmation: values.deleteConfirmation
        },
        contactInfo: {
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            address1: values.address1.trim(),
            address2: values.address2?.trim() ?? "",
            city: values.city.trim(),
            state: values.state,
            zip: values.zip.trim(),
            phoneNumber: values.phoneNumber.trim(),
            emailAddress: values.emailAddress.trim()
        },
        includeAgentInfo: values.includeAgentInfo ?? false,
        agentContactInfo: {
            agentFirstName: values.agentFirstName?.trim() ?? "",
            agentLastName: values.agentLastName?.trim() ?? "",
            agentAddress1: values.agentAddress1?.trim() ?? "",
            agentAddress2: values.agentAddress2?.trim() ?? "",
            agentCity: values.agentCity?.trim() ?? "",
            agentState: values.agentState ?? "",
            agentZip: values.agentZip?.trim() ?? "",
            agentPhoneNumber: "",
            agentEmailAddress: ""
        }
    };
};

const createPrivateRequest = (
    values: CCPAAuthenticatedFormFields,
    account: UserAccount,
    profile: Profile
): CCPAPrivateRequest => {
    return {
        privacyInfo: {
            categoriesSources: values.categoriesSources,
            specificData: values.specificData,
            requestData: values.requestData,
            deleteData: values.deleteData,
            deleteConfirmation: values.deleteConfirmation
        },
        contactInfo: {
            firstName: account.firstName,
            lastName: account.lastName,
            address1: profile.address1,
            address2: profile.address2,
            city: profile.city,
            state: profile.state,
            zip: profile.zipCode,
            phoneNumber: getPhone(profile),
            emailAddress: profile.emailAddress
        }
    };
};

const getPhone = (profile: Profile): string => {
    if (!!profile.homePhone && profile.homePhone !== "0000000000") {
        return profile.homePhone;
    }

    if (!!profile.cellPhone1 && profile.cellPhone1 !== "0000000000") {
        return profile.cellPhone1;
    }

    if (!!profile.cellPhone2 && profile.cellPhone2 !== "0000000000") {
        return profile.cellPhone2;
    }

    if (!!profile.workPhone && profile.workPhone !== "0000000000") {
        return profile.workPhone;
    }

    return "";
};
