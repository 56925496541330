import dayjs from "dayjs";
import { ReactElement, useEffect } from "react";
import { ExpandableRowContext } from "../../../contexts";
import ActivityRecentView from "./activityRecentView";
import { useActivityViewModel } from "./activityViewModel";

export function ActivityRecentModel(): ReactElement {
    const { context, hooks, state, api } = useActivityViewModel();

    useEffect(() => {
        state.setLoading(true);
        api.Recent(state.account.accountHandle)
            .then((res) => {
                state.setTransactions(res.data.sort((a, b) => dayjs(b.TransactionDate).diff(a.TransactionDate)));
            })
            .finally(() => {
                state.setLoading(false);
            });
    }, [state.accountIndex]);

    function onViewAll() {
        const url = "/account/summary/" + state.accountIndex + "/activity";
        hooks.navigate(url);
    }

    return (
        <ExpandableRowContext.Provider value={{ ...context }}>
            <ActivityRecentView {...state} onViewAll={onViewAll} />
        </ExpandableRowContext.Provider>
    );
}
