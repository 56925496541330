import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { Blocker, NavigateFunction, useBlocker, useNavigate, useParams } from "react-router-dom";
import {
    ContextualAlertDetails,
    ContextualAlerts,
    UserAccount,
    useAccountContext,
    useContextualMessagingContext,
    useDigitalActionsContext,
    useSettingsContext
} from "../../../api/backend";
import { CardNavContext, useSwrveContext } from "../../../contexts";
import { useActiveElement, useViewport } from "../../../hooks";
import { FormFocus, Size } from "../../../types";
import { GenericModalProps } from "../../generic";

type ManageAlertsViewModel = {
    hooks: ManageAlertsHooks;
    state: ManageAlertsState;
    api: ManageAlertsApi;
};

type ManageAlertsHooks = {
    navigate: NavigateFunction;
    insertSwrveEvent: (event: string) => void;
    actionAllowed: (option: string, accountHandle?: string | undefined) => boolean;
};

export type ManageAlertsState = {
    modalEnrollPhoneSuccessProps: GenericModalProps;
    showModalEnrollPhoneSuccess: boolean;
    setShowModalEnrollPhoneSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    modalErrorProps: GenericModalProps;
    showModalError: boolean;
    setShowModalError: React.Dispatch<React.SetStateAction<boolean>>;
    modalUpdatePhoneSuccessProps: GenericModalProps;
    showModalUpdatePhoneSuccess: boolean;
    setShowModalUpdatePhoneSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    modalTurnOffAlertsQuestionProps: GenericModalProps;
    showModalTurnOffAlertsQuestion: boolean;
    setShowModalTurnOffAlertsQuestion: React.Dispatch<React.SetStateAction<boolean>>;
    modalUpdateSpecificAlertsSuccessProps: GenericModalProps;
    showModalUpdateSpecificAlertsSuccess: boolean;
    setShowModalUpdateSpecificAlertsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    showModalCancelWithoutSaving: boolean;
    setShowModalCancelWithoutSaving: React.Dispatch<React.SetStateAction<boolean>>;
    modalCancelWithoutSavingProps: GenericModalProps;
    showTermsModal: boolean;
    setShowTermsModal: React.Dispatch<React.SetStateAction<boolean>>;
    viewOnlyTermsAndConditions: boolean;
    setViewOnlyTermsAndConditions: React.Dispatch<React.SetStateAction<boolean>>;
    isEnrolled: boolean;
    setIsEnrolled: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isEditing: boolean;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
    isEditingSpecificAlerts: boolean;
    setIsEditingSpecificAlerts: React.Dispatch<React.SetStateAction<boolean>>;
    isEditingSpecificAlertsChanged: boolean;
    setIsEditingSpecificAlertsChanged: React.Dispatch<React.SetStateAction<boolean>>;
    canDiscardSpecificAlertsChanges: boolean;
    setCanDiscardSpecificAlertsChanges: React.Dispatch<React.SetStateAction<boolean>>;
    toggleDisabled: boolean;
    setToggleDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    accounts: UserAccount[] | undefined;
    accountIndex: number;
    setAccountIndex: React.Dispatch<React.SetStateAction<number>>;
    setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
    size: Size;
    optInText: string;
    setOptInText: React.Dispatch<React.SetStateAction<string>>;
    enrolledMobileNumber: string;
    setEnrolledMobileNumber: React.Dispatch<React.SetStateAction<string>>;
    enrolledMobileNumberFormatted: string;
    setEnrolledMobileNumberFormatted: React.Dispatch<React.SetStateAction<string>>;
    acceptedManageAlertsTerms: boolean;
    setAcceptedManageAlertsTerms: React.Dispatch<React.SetStateAction<boolean>>;
    updateManageAlertsPhone: boolean;
    setUpdateManageAlertsPhone: React.Dispatch<React.SetStateAction<boolean>>;
    turnOffManageAlertsPhone: boolean;
    setTurnOffManageAlertsPhone: React.Dispatch<React.SetStateAction<boolean>>;
    initializeForm: boolean;
    setInitializeForm: React.Dispatch<React.SetStateAction<boolean>>;
    activeElement: Element | null;
    canSubmit: boolean;
    setCanSubmit: React.Dispatch<React.SetStateAction<boolean>>;
    focused: FormFocus<ManageAlertsFormFields>;
    setFocused: React.Dispatch<React.SetStateAction<FormFocus<ManageAlertsFormFields>>>;
    blocker: Blocker;
    discardChangesModal: GenericModalProps;
    originalAlerts: ContextualAlertDetails[];
    setOriginalAlerts: React.Dispatch<React.SetStateAction<ContextualAlertDetails[]>>;
};

export type ManageAlertsFormFields = {
    confirmMobilePhoneNumber: string;
    mobilePhoneNumber: string;
};

export type ManageAlertsApi = {
    isEnrolledGet: () => Promise<AxiosResponse<ContextualAlerts[]>>;
    postManageAlertsAcceptedTerms: () => Promise<AxiosResponse<any, any>>;
};

export function useManageAlertsViewModel(): ManageAlertsViewModel {
    const navigate = useNavigate();
    const { insertSwrveEvent } = useSwrveContext();
    const { size } = useViewport(false, true);
    const { setShowNav } = useContext(CardNavContext);
    const { appSettings } = useSettingsContext();
    const [showModalEnrollPhoneSuccess, setShowModalEnrollPhoneSuccess] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [showModalUpdatePhoneSuccess, setShowModalUpdatePhoneSuccess] = useState(false);
    const [showModalTurnOffAlertsQuestion, setShowModalTurnOffAlertsQuestion] = useState(false);
    const [showModalUpdateSpecificAlertsSuccess, setShowModalUpdateSpecificAlertsSuccess] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [viewOnlyTermsAndConditions, setViewOnlyTermsAndConditions] = useState(false);
    const [showModalCancelWithoutSaving, setShowModalCancelWithoutSaving] = useState(false);
    const [isEnrolled, setIsEnrolled] = useState(false);
    const { AlertsDestination, AlertsGet } = useContextualMessagingContext();
    const { accounts, accountIndex, setAccountIndex } = useAccountContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingSpecificAlerts, setIsEditingSpecificAlerts] = useState(false);
    const [isEditingSpecificAlertsChanged, setIsEditingSpecificAlertsChanged] = useState(false);
    const [canDiscardSpecificAlertsChanges, setCanDiscardSpecificAlertsChanges] = useState(false);
    const [toggleDisabled, setToggleDisabled] = useState(false);
    const [enrolledMobileNumber, setEnrolledMobileNumber] = useState("");
    const [enrolledMobileNumberFormatted, setEnrolledMobileNumberFormatted] = useState("");
    const [optInText, setOptInText] = useState("");
    const [acceptedManageAlertsTerms, setAcceptedManageAlertsTerms] = useState(false);
    const [updateManageAlertsPhone, setUpdateManageAlertsPhone] = useState(false);
    const [turnOffManageAlertsPhone, setTurnOffManageAlertsPhone] = useState(false);
    const [initializeForm, setInitializeForm] = useState(false);
    const activeElement = useActiveElement();
    const [canSubmit, setCanSubmit] = useState(false);
    const [focused, setFocused] = useState({} as FormFocus<ManageAlertsFormFields>);
    const [originalAlerts, setOriginalAlerts] = useState([] as ContextualAlertDetails[]);
    const { actionAllowed } = useDigitalActionsContext();
    const { id } = useParams();

    useEffect(() => {
        setAccountIndex(Number(id));
    }, []);

    useEffect(() => {
        if (appSettings?.featureFlags !== undefined) {
            if (!appSettings.featureFlags.contextualManagement) {
                navigate(`/account/dashboard`, { relative: "path" });
            }
        }
    }, [appSettings]);

    //Gets whether account is enrolled in alerts and the phone number if enrolled
    function isEnrolledGet(): Promise<AxiosResponse<ContextualAlerts[]>> {
        return AlertsGet(accounts![accountIndex].accountHandle);
    }

    //Enrolls the account in alerts, updates phone number, unennrolls
    function postManageAlertsAcceptedTerms(): Promise<AxiosResponse<any, any>> {
        return AlertsDestination({
            accountHandle: accounts![accountIndex].accountHandle,
            phoneNumber: enrolledMobileNumber
        });
    }

    let blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            (isEditing || (isEditingSpecificAlerts && isEditingSpecificAlertsChanged)) &&
            currentLocation.pathname !== nextLocation.pathname
    );

    const discardChangesModal: GenericModalProps = {
        headerText: "Discard Changes?",
        bodyText: "You have unsaved changes",
        secondaryButton: {
            text: "Discard",
            variant: "neutral",
            action: () => {
                if (blocker.proceed) {
                    blocker.proceed();
                    blocker.reset();
                }
            }
        },
        primaryButton: {
            text: "Stay on Page",
            action: () => {
                if (blocker.reset) blocker.reset();
            }
        }
    };

    const modalEnrollPhoneSuccessProps = {
        headerText: "Success!",
        bodyText:
            "We've added your number and created your Alerts profile. An Opt-in text is on the way. Be sure to reply with \"Y\" to begin receiving alerts.<br /><br />Now, let's customize your Alerts selections.",
        primaryButton: {
            text: "Choose my Alerts",
            action: () => {
                setShowModalEnrollPhoneSuccess(false);
                setIsEditing(false);
                navigate(`/account/summary/${accountIndex}/manage-alerts`);
            }
        }
    } as GenericModalProps;

    const modalErrorProps = {
        headerText: "Something Went Wrong",
        bodyText: "We're sorry, something went wrong. Please try again.",
        primaryButton: {
            text: "Close",
            action: () => {
                setShowModalError(false);
                navigate(`/account/summary/${accountIndex}/manage-alerts`);
            }
        }
    } as GenericModalProps;

    const modalUpdatePhoneSuccessProps = {
        headerText: "Success!",
        bodyText:
            "The phone number associated with your text message alerts has been updated.<br /><br />Would you also like to update the phone number(s) in your contact information?",
        primaryButton: {
            text: "Update Contact Information",
            action: () => {
                setToggleDisabled(false);
                setShowModalUpdatePhoneSuccess(false);
                setIsEditing(false);
                navigate("/profile/update-profile");
            }
        },
        secondaryButton: {
            text: "No, Thanks",
            variant: "neutral",
            action: () => {
                setToggleDisabled(false);
                setShowModalUpdatePhoneSuccess(false);
                setIsEditing(false);
                navigate(`/account/summary/${accountIndex}/manage-alerts`);
            }
        }
    } as GenericModalProps;

    const modalTurnOffAlertsQuestionProps = {
        headerText: "Turn Off Text Message Alerts",
        bodyText: "Are you sure you would like to turn off text message alerts?",
        primaryButton: {
            text: "Turn Off Alerts",
            action: () => {
                setEnrolledMobileNumber("");
                setEnrolledMobileNumberFormatted("");
                setCanSubmit(false);
                setTurnOffManageAlertsPhone(true);
                setInitializeForm(true);
                setShowModalTurnOffAlertsQuestion(false);
                navigate(`/account/summary/${accountIndex}/manage-alerts`);
            }
        },
        secondaryButton: {
            text: "Keep Alerts",
            variant: "neutral",
            action: () => {
                setShowModalTurnOffAlertsQuestion(false);
                setIsEditing(false);
                navigate(`/account/summary/${accountIndex}/manage-alerts`);
            }
        }
    } as GenericModalProps;

    const modalCancelWithoutSavingProps = {
        headerText: "Discard Changes?",
        bodyText: "You have unsaved changes.",
        primaryButton: {
            text: "Stay on Page",
            action: () => {
                setShowModalCancelWithoutSaving(false);
                navigate(`/account/summary/${accountIndex}/manage-alerts`);
            }
        },
        secondaryButton: {
            text: "Discard",
            variant: "neutral",
            action: () => {
                setCanDiscardSpecificAlertsChanges(true);
                setShowModalCancelWithoutSaving(false);
                setIsEditingSpecificAlertsChanged(false);
                setIsEditingSpecificAlerts(false);
                setToggleDisabled(false);
                navigate(`/account/summary/${accountIndex}/manage-alerts`);
            }
        }
    } as GenericModalProps;

    const modalUpdateSpecificAlertsSuccessProps = {
        headerText: "Success!",
        bodyText: "Your text message alert preferences have been updated",
        primaryButton: {
            text: "Close",
            action: () => {
                setShowModalUpdateSpecificAlertsSuccess(false);
                setCanDiscardSpecificAlertsChanges(true);
                setIsEditingSpecificAlertsChanged(false);
                setIsEditingSpecificAlerts(false);
                setToggleDisabled(false);

                navigate(`/account/summary/${accountIndex}/manage-alerts`);
            }
        }
    } as GenericModalProps;

    return {
        hooks: {
            navigate,
            insertSwrveEvent,
            actionAllowed
        },
        state: {
            accounts,
            accountIndex,
            setAccountIndex,
            setShowNav,
            size,
            modalEnrollPhoneSuccessProps,
            showModalEnrollPhoneSuccess,
            setShowModalEnrollPhoneSuccess,
            modalErrorProps,
            showModalError,
            setShowModalError,
            modalUpdatePhoneSuccessProps,
            showModalUpdatePhoneSuccess,
            setShowModalUpdatePhoneSuccess,
            modalTurnOffAlertsQuestionProps,
            showModalTurnOffAlertsQuestion,
            setShowModalTurnOffAlertsQuestion,
            modalUpdateSpecificAlertsSuccessProps,
            showModalUpdateSpecificAlertsSuccess,
            setShowModalUpdateSpecificAlertsSuccess,
            showModalCancelWithoutSaving,
            setShowModalCancelWithoutSaving,
            modalCancelWithoutSavingProps,
            showTermsModal,
            setShowTermsModal,
            viewOnlyTermsAndConditions,
            setViewOnlyTermsAndConditions,
            isLoading,
            setIsLoading,
            isEnrolled,
            setIsEnrolled,
            isEditing,
            setIsEditing,
            isEditingSpecificAlerts,
            setIsEditingSpecificAlerts,
            isEditingSpecificAlertsChanged,
            setIsEditingSpecificAlertsChanged,
            canDiscardSpecificAlertsChanges,
            setCanDiscardSpecificAlertsChanges,
            toggleDisabled,
            setToggleDisabled,
            enrolledMobileNumber,
            setEnrolledMobileNumber,
            enrolledMobileNumberFormatted,
            setEnrolledMobileNumberFormatted,
            optInText,
            setOptInText,
            acceptedManageAlertsTerms,
            setAcceptedManageAlertsTerms,
            updateManageAlertsPhone,
            setUpdateManageAlertsPhone,
            turnOffManageAlertsPhone,
            setTurnOffManageAlertsPhone,
            initializeForm,
            setInitializeForm,
            activeElement,
            canSubmit,
            setCanSubmit,
            focused,
            setFocused,
            blocker,
            discardChangesModal,
            originalAlerts,
            setOriginalAlerts
        },
        api: {
            isEnrolledGet,
            postManageAlertsAcceptedTerms
        }
    };
}
