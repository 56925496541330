import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { ReactElement, useMemo, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { TransactionDetails, useAccountContext, UserAccount, useTransactionsContext } from "../../../api/backend";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { ActivityRow } from "./activityRow/activityRow";

type ActivityContext = {
    curIndex: number | undefined;
    setCurIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
};

type ActivityHooks = {
    navigate: NavigateFunction;
};

export type ActivityState = {
    size: Size;
    account: UserAccount;
    accountIndex: number;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setTransactions: React.Dispatch<React.SetStateAction<TransactionDetails[]>>;
    seeMore: boolean;
    setSeeMore: React.Dispatch<React.SetStateAction<boolean>>;
    filter: number;
    setFilter: React.Dispatch<React.SetStateAction<number>>;
    years: number[];
    filteredTransactions: TransactionDetails[];
    rows: ReactElement[];
    activityErr: boolean;
    setActivityErr: React.Dispatch<React.SetStateAction<boolean>>;
};

type ActivityApi = {
    Recent: (accountHandle: string) => Promise<AxiosResponse<TransactionDetails[], any>>;
    All: (accountHandle: string) => Promise<AxiosResponse<TransactionDetails[], any>>;
};

const useActivityContext = (): ActivityContext => {
    const [curIndex, setCurIndex] = useState(undefined as undefined | number);

    return {
        curIndex,
        setCurIndex
    };
};

const useActivityState = (): ActivityState => {
    const { size } = useViewport(false, true);
    const [loading, setLoading] = useState(false);
    const [seeMore, setSeeMore] = useState(false);
    const [filter, setFilter] = useState(0 as number);
    const [transactions, setTransactions] = useState([] as TransactionDetails[]);
    const { accounts, accountIndex } = useAccountContext();
    const [activityErr, setActivityErr] = useState(false);
    const account = accounts[accountIndex];

    const years = useMemo(() => {
        return transactions
            .map((p) => dayjs(p.TransactionDate).year())
            .filter((p, i, s) => i === s.indexOf(p))
            .sort((a, b) => a - b);
    }, [transactions]);

    const filteredTransactions = useMemo(() => {
        return transactions
            .filter((p) => {
                if (filter > 0) return dayjs(p.TransactionDate).year() === filter;
                else return dayjs(p.TransactionDate) > dayjs().subtract(6, "month");
            })
            .sort((a, b) => dayjs(b.TransactionDate).diff(a.TransactionDate));
    }, [transactions, filter]);

    const displayedTransactions = useMemo(() => {
        return seeMore ? filteredTransactions : filteredTransactions.slice(0, 3);
    }, [filteredTransactions, seeMore]);

    const rows = useMemo(() => {
        return displayedTransactions.map((v, i) => (
            <ActivityRow key={`displayedHistory[i].ReferenceNumber.toString()${i}`} index={i} activity={v} />
        ));
    }, [displayedTransactions]);

    return {
        size,
        account,
        accountIndex,
        loading,
        setLoading,
        setTransactions,
        seeMore,
        setSeeMore,
        filter,
        setFilter,
        years,
        filteredTransactions,
        rows,
        activityErr,
        setActivityErr
    };
};

const useActivityHooks = (): ActivityHooks => {
    const navigate = useNavigate();

    return {
        navigate
    };
};

const useActivityApi = (): ActivityApi => {
    const { Recent, All } = useTransactionsContext();

    return {
        Recent,
        All
    };
};

export const useActivityViewModel = () => {
    const context = useActivityContext();
    const state = useActivityState();
    const hooks = useActivityHooks();
    const api = useActivityApi();

    return {
        context,
        state,
        hooks,
        api
    };
};
