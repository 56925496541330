import { ReactElement } from "react";
import { CardApplicationProps } from "./cardApplication";
import { CardApplicationState } from "./cardApplicationViewModel";
import { CardApplicationMainView } from "./desktop/cardApplicationMainView";
import { CardApplicationAltView } from "./responsive/cardApplicationAltView";

export type CardApplicationViewProps = CardApplicationProps &
    CardApplicationState & {
        viewApplication: Function;
    };

export function CardApplicationView(props: CardApplicationViewProps): ReactElement {
    return (
        <>
            {props.size === "" || !props.isDashboard === true ? (
                <>
                    <CardApplicationMainView {...props} />
                </>
            ) : (
                <>
                    <CardApplicationAltView {...props} />
                </>
            )}
        </>
    );
}
