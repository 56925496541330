import { AxiosResponse } from "axios";
import { ReactElement, useEffect, useState } from "react";
import { Blocker, NavigateFunction, useBlocker, useNavigate } from "react-router-dom";
import {
    Profile,
    ProfileGet,
    ProfileUpdateAddress,
    ProfileUpdateEmail,
    ProfileUpdatePhone,
    UpdateProfileCms,
    UserAccount,
    useAccountContext,
    useCmsContext,
    useDigitalActionsContext,
    useProfileContext
} from "../../../api/backend";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { getStateList } from "../../../utility";
import { GenericModalProps } from "../../generic";

export type UpdateContactInformationViewModel = {
    hooks: UpdateContactInformationHooks;
    state: UpdateContactInformationState;
    api: UpdateContactInformationAPI;
};

type UpdateContactInformationHooks = {
    getProfiles: () => void;
    navigate: NavigateFunction;
    actionAllowed: (option: string, accountHandle?: string | undefined) => boolean;
};

export type UpdateContactInformationState = {
    editAddress: boolean;
    setEditAddress: React.Dispatch<React.SetStateAction<boolean>>;
    editPhone: boolean;
    setEditPhone: React.Dispatch<React.SetStateAction<boolean>>;
    editEmail: boolean;
    setEditEmail: React.Dispatch<React.SetStateAction<boolean>>;
    profileCMS: UpdateProfileCms | undefined;
    accounts: UserAccount[] | undefined;
    stateList: string[];
    selectedState: string;
    setSelectedState: React.Dispatch<React.SetStateAction<string>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    loadingAsync: boolean;
    setLoadingAsync: React.Dispatch<React.SetStateAction<boolean>>;
    canEditMultiple: boolean;
    canContinue: boolean;
    setCanContinue: React.Dispatch<React.SetStateAction<boolean>>;
    size: Size;
    showDiscardChangesModal: boolean;
    setShowDiscardChangesModal: React.Dispatch<React.SetStateAction<boolean>>;
    discardChangesModal: GenericModalProps;
    successModal: GenericModalProps;
    blocker: Blocker;
    unsavedChanges: boolean;
    setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
    showSuccessModal: boolean;
    setShowSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
    emailErrors: string;
    setEmailErrors: React.Dispatch<React.SetStateAction<string>>;
    isStateFC: boolean;
    setIsStateFC: React.Dispatch<React.SetStateAction<boolean>>;
    cards: ReactElement[];
    setCards: React.Dispatch<React.SetStateAction<ReactElement[]>>;
    profiles: ProfileGet[];
    activeIndex: number;
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
    accountIndex: number;
    addressApiError: string;
    setAddressApiError: React.Dispatch<React.SetStateAction<string>>;
    showAddressApiError: boolean;
    setShowAddressApiError: React.Dispatch<React.SetStateAction<boolean>>;
    phoneApiError: string;
    setPhoneApiError: React.Dispatch<React.SetStateAction<string>>;
    showPhoneApiError: boolean;
    setShowPhoneApiError: React.Dispatch<React.SetStateAction<boolean>>;
    emailApiError: string;
    setEmailApiError: React.Dispatch<React.SetStateAction<string>>;
    showEmailApiError: boolean;
    setShowEmailApiError: React.Dispatch<React.SetStateAction<boolean>>;
    cellPhone1Error: string;
    setCellPhone1Error: React.Dispatch<React.SetStateAction<string>>;
    cellPhone2Error: string;
    setCellPhone2Error: React.Dispatch<React.SetStateAction<string>>;
    homePhoneError: string;
    setHomePhoneError: React.Dispatch<React.SetStateAction<string>>;
    workPhoneError: string;
    setWorkPhoneError: React.Dispatch<React.SetStateAction<string>>;
    address1Error: string;
    setAddress1Error: React.Dispatch<React.SetStateAction<string>>;
    cityError: string;
    setCityError: React.Dispatch<React.SetStateAction<string>>;
    zipCodeError: string;
    setZipCodeError: React.Dispatch<React.SetStateAction<string>>;
};
export type UpdateContactInformationAPI = {
    updateProfileAddress: (profileUpdate: ProfileUpdateAddress, index: number) => Promise<AxiosResponse<Profile, any>>;
    updateProfilePhone: (profileUpdate: ProfileUpdatePhone, index: number) => Promise<AxiosResponse<Profile, any>>;
    updateProfileEmail: (profileUpdate: ProfileUpdateEmail, index: number) => Promise<AxiosResponse<Profile, any>>;
};

export type AddressFormFields = {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
    multipleCard: boolean;
};

export type PhoneNumberFormFields = {
    homePhone: string;
    workPhone: string;
    cellPhone1: string;
    cellPhone2: string;
    multipleCard: boolean;
};

export type EmailFormFields = {
    emailAddress: string;
    multipleCard: boolean;
};

export function useUpdateContactInformationViewModel(): UpdateContactInformationViewModel {
    const [editAddress, setEditAddress] = useState(false);
    const [editPhone, setEditPhone] = useState(false);
    const [editEmail, setEditEmail] = useState(false);
    const { accounts, accountIndex } = useAccountContext();
    const { Get, PutAddress, PutPhone, PutEmail } = useProfileContext();
    const { size } = useViewport(true, true);
    const [profileCMS, setProfileCMS] = useState<UpdateProfileCms>();
    const stateList = getStateList();
    const [selectedState, setSelectedState] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [loadingAsync, setLoadingAsync] = useState(false);
    const [canEditMultiple, setCanEditMultiple] = useState(false);
    const [canContinue, setCanContinue] = useState(false);
    const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [emailErrors, setEmailErrors] = useState("");
    const [isStateFC, setIsStateFC] = useState(false);
    const { UpdateProfile } = useCmsContext();
    const [cards, setCards] = useState([] as ReactElement[]);
    const [profiles, setProfiles] = useState([] as ProfileGet[]);
    const [activeIndex, setActiveIndex] = useState(0);
    const { actionAllowed } = useDigitalActionsContext();
    const navigate = useNavigate();
    const [addressApiError, setAddressApiError] = useState<string>("");
    const [showAddressApiError, setShowAddressApiError] = useState(false);
    const [phoneApiError, setPhoneApiError] = useState<string>("");
    const [showPhoneApiError, setShowPhoneApiError] = useState(false);
    const [emailApiError, setEmailApiError] = useState<string>("");
    const [showEmailApiError, setShowEmailApiError] = useState(false);
    const [cellPhone1Error, setCellPhone1Error] = useState("");
    const [cellPhone2Error, setCellPhone2Error] = useState("");
    const [homePhoneError, setHomePhoneError] = useState("");
    const [workPhoneError, setWorkPhoneError] = useState("");
    const [address1Error, setAddress1Error] = useState("");
    const [cityError, setCityError] = useState("");
    const [zipCodeError, setZipCodeError] = useState("");

    let blocker = useBlocker(
        ({ currentLocation, nextLocation }) => unsavedChanges && currentLocation.pathname !== nextLocation.pathname
    );

    const discardChangesModal: GenericModalProps = {
        headerText: "Discard Changes?",
        bodyText: "You have unsaved changes",
        secondaryButton: {
            text: "Discard",
            variant: "neutral",
            action: () => {
                if (blocker.proceed) {
                    blocker.proceed();
                    blocker.reset();
                }
            }
        },
        primaryButton: {
            text: "Stay on Page",
            action: () => {
                if (blocker.reset) blocker.reset();
            }
        }
    };

    const successModal: GenericModalProps = {
        headerText: "Success!",
        bodyText: `Your ${editAddress ? "address" : editEmail ? "email" : "phone"} has been updated.`,

        primaryButton: {
            text: "Close",
            action: () => {
                setShowSuccessModal(false);
                setUnsavedChanges(false);
                setEditEmail(false);
                setEditAddress(false);
                setEditPhone(false);
            }
        }
    };

    async function getProfileCMS(): Promise<AxiosResponse<UpdateProfileCms, any>> {
        return UpdateProfile();
    }

    async function getProfile(accountHandle: string): Promise<AxiosResponse<Profile, any>> {
        return await Get(accountHandle);
    }

    async function updateProfileAddress(
        profileUpdate: ProfileUpdateAddress,
        index: number
    ): Promise<AxiosResponse<Profile, any>> {
        return PutAddress(accounts![index].accountHandle, profileUpdate);
    }

    async function updateProfilePhone(
        profileUpdate: ProfileUpdatePhone,
        index: number
    ): Promise<AxiosResponse<Profile, any>> {
        return PutPhone(accounts![index].accountHandle, profileUpdate);
    }

    async function updateProfileEmail(
        profileUpdate: ProfileUpdateEmail,
        index: number
    ): Promise<AxiosResponse<Profile, any>> {
        return PutEmail(accounts![index].accountHandle, profileUpdate);
    }

    function getProfiles() {
        setLoadingAsync(true);
        let profiles: ProfileGet[] = [];
        let profilesLoaded = 0;

        accounts?.forEach((acc) => {
            getProfile(acc.accountHandle)
                .then((res) => {
                    profiles.push({
                        ...res.data!,
                        accountHandle: acc.accountHandle
                    });
                })
                .finally(() => {
                    profilesLoaded++;

                    if (profilesLoaded === accounts.length) {
                        setCanEditMultiple(profiles?.filter((p) => p.canEditAddress).length > 1);
                        setProfiles(profiles);
                        setLoadingAsync(false);
                    }
                });
        });
    }

    useEffect(() => {
        getProfiles();

        getProfileCMS().then((res) => {
            setProfileCMS(res.data);
        });
    }, []);

    return {
        hooks: { getProfiles, navigate, actionAllowed },
        state: {
            editAddress,
            setEditAddress,
            editPhone,
            setEditPhone,
            editEmail,
            setEditEmail,
            profileCMS,
            accounts,
            stateList,
            selectedState,
            setSelectedState,
            loading,
            setLoading,
            loadingAsync,
            setLoadingAsync,
            canEditMultiple,
            canContinue,
            setCanContinue,
            size,
            showDiscardChangesModal,
            setShowDiscardChangesModal,
            discardChangesModal,
            unsavedChanges,
            setUnsavedChanges,
            blocker,
            showSuccessModal,
            setShowSuccessModal,
            successModal,
            emailErrors,
            setEmailErrors,
            isStateFC,
            setIsStateFC,
            cards,
            setCards,
            profiles,
            activeIndex,
            setActiveIndex,
            accountIndex,
            addressApiError,
            setAddressApiError,
            showAddressApiError,
            setShowAddressApiError,
            phoneApiError,
            setPhoneApiError,
            showPhoneApiError,
            setShowPhoneApiError,
            emailApiError,
            setEmailApiError,
            showEmailApiError,
            setShowEmailApiError,
            cellPhone1Error,
            setCellPhone1Error,
            cellPhone2Error,
            setCellPhone2Error,
            homePhoneError,
            setHomePhoneError,
            workPhoneError,
            setWorkPhoneError,
            address1Error,
            setAddress1Error,
            cityError,
            setCityError,
            zipCodeError,
            setZipCodeError
        },
        api: { updateProfileAddress, updateProfilePhone, updateProfileEmail }
    };
}
