import { ReactElement } from "react";
import { Outlet } from "react-router-dom";
import "./App.scss";
import { useSettingsContext } from "./api/backend";
import { Loading } from "./components";

function App(props: { loading: boolean }): ReactElement {
    useSettingsContext();

    return (
        <div id="App" className="custom-scrollbar">
            <Outlet />
            <Loading show={props.loading} />
        </div>
    );
}

export default App;
