import { ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigation } from "react-router-dom";
import App from "./App";
import { useHashNavigation, useUnhandledPromise, useViewMobile } from "./hooks";

export function AppController(): ReactElement {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const navigation = useNavigation();
    useUnhandledPromise();
    useHashNavigation();
    useViewMobile();

    useEffect(() => {
        if (navigation.state === "loading" || navigation.state === "submitting") {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [navigation]);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.document.getElementById("publicView-outter")?.scrollTo(0, 0);
        window.document.getElementById("privateView-outter")?.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Prompt:wght@400;500;600;700&display=swap"
                rel="stylesheet"
            />
            <App loading={loading} />
        </>
    );
}
