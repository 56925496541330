import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";

type CardSecurityDepositViewModel = {
    state: CardSecurityDepositState;
};

export type CardSecurityDepositState = { size: Size };

export function useCardSecurityDepositViewModel(): CardSecurityDepositViewModel {
    const { size } = useViewport(true);

    return {
        state: { size }
    };
}
