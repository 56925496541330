import { ReactElement, useEffect } from "react";
import { ApplicationStatusCms, UserAccount, useSettingsContext } from "../../../../api/backend";
import { ShowPastDueAlert } from "../../../../utility/showPastDueAlert";
import { CardSummaryTileView } from "./cardSummaryTileView";
import { useCardSummaryTileViewModel } from "./cardSummaryTileViewModel";

export type CardSummaryTileProps = {
    index: number;
    account: UserAccount;
    showUnLockCardSuccess?: boolean;
    header?: ReactElement;
    appCms?: ApplicationStatusCms;
};

export function CardSummaryTileModel(props: CardSummaryTileProps): ReactElement {
    const { hooks, state, api } = useCardSummaryTileViewModel(props);
    const { appSettings } = useSettingsContext();
    const isLockCardEnabled: boolean = appSettings?.featureFlags?.lockCardEnabled;

    useEffect(() => {
        api.accountSummaryCms();

        if (state.appSettings?.featureFlags?.lockCardEnabled) {
            state.setLockLoading(true);
            api.getLockStatus(props.index)
                .then((res) => {
                    state.setLockStatus(res.data.isLocked);
                })
                .finally(() => {
                    state.setLockLoading(false);
                });
        } else {
            state.setLockStatus(false);
            state.setLockLoading(false);
        }
    }, [props.index]);

    useEffect(() => {
        if (ShowPastDueAlert(props.account.paymentPastDue, props.account.status, props.account.seeStatementMessage)) {
            state.setShowAlert(true);
            state.setAlert("Past Due");
        } else {
            state.setShowAlert(false);
        }
    }, [props.account.paymentPastDue, props.account.status, props.account.seeStatementMessage]);

    const ManageLockCard = () => {
        state.setAccountIndex(props.index);

        isLockCardEnabled
            ? hooks.navigate(`/account/summary/${props.index}/lock-card`, { relative: "path" })
            : state.setShowLockCardDisabledModal(true);
    };

    return <CardSummaryTileView {...props} {...state} {...hooks} manageLockCard={ManageLockCard} />;
}
