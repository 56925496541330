import { ReactElement, useEffect } from "react";
import { PaymentAccountView } from "./paymentAccountView";
import { usePaymentAccountViewModel } from "./paymentAccountViewModel";

export function PaymentAccountModel(): ReactElement {
    const { state, api } = usePaymentAccountViewModel();

    useEffect(() => {
        if (state.paymentAccounts.length === 0) {
            api.Get();
        }
    }, []);

    function onAdd() {
        state.setShowAdd(true);
    }

    return <PaymentAccountView {...state} onAdd={onAdd} />;
}
