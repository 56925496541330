import { ReactElement } from "react";
import { PaymentButtonView } from "./paymentButtonView";
import { usePaymentButtonViewModel } from "./paymentButtonViewModel";

export function PaymentButton(): ReactElement {
    const { state, hooks } = usePaymentButtonViewModel();

    function makeNewPayment() {
        hooks.makeNewPayment(state.accountIndex);
    }

    return <PaymentButtonView {...state} makeNewPayment={makeNewPayment} />;
}
