import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Travel(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.4239 20.5711L21.1079 19.8871C21.2932 19.7018 21.4268 19.4711 21.4952 19.2181C21.5636 18.9651 21.5645 18.6986 21.4979 18.4451L19.0649 9.20012L22.2819 5.98312C22.81 5.47859 23.1437 4.80423 23.2243 4.07831C23.305 3.35239 23.1274 2.62125 22.7229 2.01312C22.4704 1.65675 22.1431 1.35982 21.7639 1.14306C21.3847 0.926308 20.9628 0.794963 20.5276 0.758203C20.0924 0.721444 19.6544 0.780156 19.2442 0.930239C18.8341 1.08032 18.4616 1.31815 18.1529 1.62712L14.8229 4.95812L5.57991 2.52612C5.32628 2.45929 5.05957 2.46014 4.80637 2.52857C4.55317 2.597 4.32234 2.73063 4.13691 2.91612L3.45291 3.60012C3.29171 3.76131 3.16937 3.95713 3.09518 4.17268C3.021 4.38824 2.99692 4.61787 3.02478 4.84413C3.05264 5.07039 3.13171 5.28732 3.25597 5.47844C3.38023 5.66956 3.54642 5.82985 3.74191 5.94712L10.0499 9.73212L5.24991 15.1721L3.74991 15.1941C3.52084 15.1549 3.2858 15.1695 3.06332 15.2366C2.84084 15.3038 2.63701 15.4217 2.46791 15.5811L0.969907 16.9531C0.883163 17.0397 0.818987 17.1462 0.783007 17.2634C0.747027 17.3805 0.740343 17.5047 0.763542 17.6251C0.78674 17.7454 0.839113 17.8582 0.916065 17.9536C0.993017 18.049 1.0922 18.124 1.20491 18.1721L4.09991 19.8051L5.99991 22.8001C6.04835 22.9127 6.12359 23.0117 6.21907 23.0884C6.31455 23.1652 6.42736 23.2175 6.54768 23.2407C6.668 23.2638 6.79216 23.2572 6.90933 23.2214C7.0265 23.1856 7.13313 23.1216 7.21991 23.0351L8.52891 21.7871C8.71085 21.6156 8.84705 21.4014 8.92512 21.1639C9.00319 20.9264 9.02064 20.6731 8.97591 20.4271L8.99991 18.9221L14.2919 13.9741L18.0769 20.2821C18.1942 20.4776 18.3545 20.6438 18.5456 20.7681C18.7367 20.8923 18.9536 20.9714 19.1799 20.9992C19.4062 21.0271 19.6358 21.003 19.8513 20.9288C20.0669 20.8547 20.2627 20.7323 20.4239 20.5711Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
