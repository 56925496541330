import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Warning(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="22"
            height="22"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M32.0029 22.858V36.5723" strokeWidth="4.57143" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M32.0025 52.5726C33.2649 52.5726 34.2882 51.5492 34.2882 50.2868C34.2882 49.0245 33.2649 48.0011 32.0025 48.0011C30.7401 48.0011 29.7168 49.0245 29.7168 50.2868C29.7168 51.5492 30.7401 52.5726 32.0025 52.5726Z"
                strokeWidth="4.57143"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M36.0698 4.80015C35.6862 4.05134 35.1033 3.42293 34.3855 2.98412C33.6676 2.5453 32.8426 2.31311 32.0012 2.31311C31.1599 2.31311 30.3348 2.5453 29.617 2.98412C28.8991 3.42293 28.3163 4.05134 27.9327 4.80015L2.7898 55.0859C2.43913 55.7818 2.27217 56.5558 2.30479 57.3343C2.3374 58.1129 2.56851 58.8703 2.97615 59.5344C3.3838 60.1985 3.95445 60.7474 4.63392 61.129C5.31339 61.5105 6.07911 61.7121 6.85838 61.7144H57.1441C57.9234 61.7121 58.6891 61.5105 59.3686 61.129C60.048 60.7474 60.6187 60.1985 61.0263 59.5344C61.434 58.8703 61.6651 58.1129 61.6977 57.3343C61.7303 56.5558 61.5633 55.7818 61.2127 55.0859L36.0698 4.80015Z"
                strokeWidth="4.57143"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
