import { createContext, useState } from "react";
import { ElectronicDeliveryPreferences } from ".";
import { CommunicationSettingsCmsModel } from "..";
import { NotImplemented } from "../../../utility";

export type UserDeliveryPreferencesContextProps = {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    preferences: ElectronicDeliveryPreferences[] | undefined;
    setPreferences: React.Dispatch<React.SetStateAction<ElectronicDeliveryPreferences[] | undefined>>;
    preferencesCms: CommunicationSettingsCmsModel;
    setPreferencesCms: React.Dispatch<React.SetStateAction<CommunicationSettingsCmsModel>>;
    showPreferencesLanding: boolean;
    setShowPreferencesLanding: React.Dispatch<React.SetStateAction<boolean>>;
    showSplitDeliveryLanding: boolean;
    setShowSplitDeliveryLanding: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DeliveryPreferencesContext = createContext<UserDeliveryPreferencesContextProps>({
    loading: false,
    setLoading: NotImplemented,
    preferences: undefined,
    setPreferences: NotImplemented,
    preferencesCms: {} as CommunicationSettingsCmsModel,
    setPreferencesCms: NotImplemented,
    showPreferencesLanding: false,
    setShowPreferencesLanding: NotImplemented,
    showSplitDeliveryLanding: false,
    setShowSplitDeliveryLanding: NotImplemented
});

export const useDeliveryPreferencesProps = (): UserDeliveryPreferencesContextProps => {
    const [loading, setLoading] = useState(false);
    const [preferences, setPreferences] = useState<ElectronicDeliveryPreferences[] | undefined>(undefined);
    const [preferencesCms, setPreferencesCms] = useState({} as CommunicationSettingsCmsModel);
    const [showPreferencesLanding, setShowPreferencesLanding] = useState(false);
    const [showSplitDeliveryLanding, setShowSplitDeliveryLanding] = useState(false);

    return {
        loading,
        setLoading,
        preferences,
        setPreferences,
        preferencesCms,
        setPreferencesCms,
        showPreferencesLanding,
        setShowPreferencesLanding,
        showSplitDeliveryLanding,
        setShowSplitDeliveryLanding
    };
};
