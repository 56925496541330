import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { GenericModal, GenericModalProps, ModalProps } from "../../../generic";

type CancelEnrollSetupProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export function CancelEnrollSetup(props: ModalProps & CancelEnrollSetupProps): ReactElement {
    const navigate = useNavigate();

    const state = {
        headerText: "Cancel Online Access Setup?",
        bodyText: "Your online account setup has not been completed yet. Are you sure you would like to cancel setup?",
        primaryButton: {
            text: "Cancel Setup",
            variant: "tertiary-neg",
            action: () => {
                props.setShow(false);
                navigate("/auth/login");
            }
        },
        secondaryButton: {
            text: "Continue Setup",
            variant: "neutral",
            action: () => {
                props.setShow(false);
            }
        }
    } as GenericModalProps;

    return <GenericModal show={props.show} {...state} />;
}
