import { Form, Formik } from "formik";
import { ReactElement } from "react";
import { IndexedCreditCard, Loading } from "../..";
import { FormProps } from "../../../types";
import { BackArrow, ButtonPro, GenericModal } from "../../generic";
import "./requestCLI.scss";
import { RequestCLIState } from "./requestCLIViewModel";

type RequestCLIViewProps = RequestCLIState & {
    onSubmit: Function;
};

export function RequestCLIView(props: RequestCLIViewProps): ReactElement {
    return (
        <div id={`requestCLI${props.size}`}>
            <BackArrow available to={`/account/summary/${props.index}/home`}>
                <h2 className="requestCLIHeader">Request a Credit Limit Increase</h2>
            </BackArrow>

            <div id="requestCLIForm">
                <div className="requestCLIForm-header">
                    <IndexedCreditCard account={props.accounts![props.accountIndex]} size={"h4"} />
                    <span className="requestCLIForm-currentText">{props.currentText}</span>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{}}
                    onSubmit={(values, actions) => {
                        props.onSubmit(actions);
                    }}
                >
                    {(formProps: FormProps<{}>) => (
                        <Form className={`requestCLIForm-form${props.size}`} autoComplete="off">
                            <span
                                className="requestCLIForm-bodyText"
                                dangerouslySetInnerHTML={{ __html: props.cms.BodyText }}
                            />

                            {props.cms.FooterText?.length > 0 ? (
                                <span
                                    className="requestCLIForm-footerText"
                                    dangerouslySetInnerHTML={{ __html: props.cms.FooterText }}
                                />
                            ) : (
                                <></>
                            )}

                            <div className={`brandedCard-buttons${props.size}`}>
                                <ButtonPro type="submit">Request Increase</ButtonPro>
                            </div>

                            <Loading show={formProps.isSubmitting} />

                            <GenericModal show={props.showSubmitted} {...props.submittedProps} />

                            <GenericModal show={props.showError} {...props.errorProps} />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
