import { Field, useFormikContext } from "formik";
import { ChangeEvent, ReactElement } from "react";
import { onFieldChange } from "../../../../generic/inputs/inputPro/inputPro";

type ccpaInputProps = {
    id: string;
    label?: string;
    max?: number;
    mask?: RegExp;
};

export function CCPAInput(props: ccpaInputProps): ReactElement {
    const formik = useFormikContext<any>();

    let error = formik.errors[props.id]?.toString();
    let touched = formik.touched[props.id] as boolean;

    return (
        <div className="inputField">
            <label htmlFor={props.id}>{props.label}</label>
            <div className="inputField-row">
                <Field
                    id={props.id}
                    name={props.id}
                    className="inputField-inputValue"
                    maxLength={props.max}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => onFieldChange(e, formik, props.id, props.mask)}
                />
            </div>

            {error && touched && (
                <div className="validation-error text-start">
                    <h6>{error}</h6>
                </div>
            )}
        </div>
    );
}
