import { ReactElement } from "react";
import { BrandedCard, ButtonPro } from "../../generic";
import { ColorBlocks2 } from "../../generic/icons";
import "./error500.scss";
import { Error500State } from "./error500ViewModel";

type Error500ViewProps = Error500State & {
    onOk: Function;
};

export function Error500View(props: Error500ViewProps): ReactElement {
    return (
        <div className="error500-wrapper">
            <div className="content">
                <BrandedCard id={`error500${props.size}`} header="Something Went Wrong" size={props.size}>
                    <div className={`brandedCard-form${props.size}`}>
                        <p>
                            The server encountered an error and cannot complete your request. Please check back later.
                        </p>
                        <ButtonPro onClick={() => props.onOk()}>OK</ButtonPro>
                    </div>
                </BrandedCard>
            </div>
            <div className="background">
                {props.size === "" ? <ColorBlocks2 className="publicView-blocks" /> : <></>}
            </div>
        </div>
    );
}
