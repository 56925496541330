import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useIsLoggedIn, useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { AppNavProps } from "../appNavProps";

type FooterViewModel = {
    hooks: FooterHooks;
    state: FooterState;
};

type FooterHooks = {
    navigate: NavigateFunction;
};

export type FooterState = {
    size: Size;
    acProps: AppNavProps;
    setAcProps: React.Dispatch<React.SetStateAction<AppNavProps>>;
    iKey: string | undefined;
    setIKey: React.Dispatch<React.SetStateAction<string | undefined>>;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    modalDest: string;
    setModalDest: React.Dispatch<React.SetStateAction<string>>;
    loggedIn: boolean;
    openNewWindow: boolean;
    setOpenNewWindow: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useFooterViewModel(): FooterViewModel {
    const navigate = useNavigate();
    const { size } = useViewport(true);
    const [acProps, setAcProps] = useState({
        open: true,
        accord: "footer-",
        keys: ["0", "0", "0"]
    });
    const [iKey, setIKey] = useState("0" as string | undefined);
    const [showModal, setShowModal] = useState(false);
    const [modalDest, setModalDest] = useState("");
    const { loggedIn } = useIsLoggedIn();
    const [openNewWindow, setOpenNewWindow] = useState(false);

    return {
        hooks: { navigate },
        state: {
            size,
            acProps,
            setAcProps,
            iKey,
            setIKey,
            showModal,
            setShowModal,
            modalDest,
            setModalDest,
            loggedIn,
            openNewWindow,
            setOpenNewWindow
        }
    };
}
