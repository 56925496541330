import { ReactElement } from "react";
import { ToggleProProps } from "./togglePro";
import { ToggleProState } from "./toggleProViewModel";

export function ToggleProView(props: ToggleProProps & ToggleProState): ReactElement {
    return (
        <label
            id={props.id}
            className={`togglePro${props.variant === "" || props.variant === undefined ? "" : `-${props.variant}`}${
                props.disabled ? "-disabled" : ""
            }`}
        >
            <input
                id={`${props.id !== undefined ? `${props.id}-input` : ""}`}
                type="checkbox"
                disabled={props.disabled}
                checked={props.isToggled}
                onChange={() => {
                    onToggleHook(props.onToggle);
                    props.setIsToggled((prev: boolean) => !prev);
                }}
            />
            <span id={`${props.id !== undefined ? `${props.id}-switch` : ""}`} className="togglePro-switch">
                {props.labels[0] !== "" ? (
                    <h6 className={`togglePro-content-${props.isToggled ? "on" : "off"}`}>
                        {props.isToggled ? <>{props.labels[0]}</> : <>{props.labels[1]}</>}
                    </h6>
                ) : (
                    <></>
                )}
            </span>
        </label>
    );
}

const onToggleHook = (onToggle?: Function) => {
    return onToggle === undefined ? () => {} : onToggle();
};
