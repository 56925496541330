import { FormikHelpers } from "formik";
import { ReactElement, useEffect } from "react";
import { MfaEnterCodeView, MfaFields } from "./mfaEnterCodeView";
import { useMfaEnterCodeViewModel } from "./mfaEnterCodeViewModel";
import { onChangeFn, onOtpFailFn, onStateChangedFn, submitEnrollFn, submitLoginFn } from "./mfaEnterCodeFn";

export function MfaEnterCodeModel(): ReactElement {
    const { hooks, state, api } = useMfaEnterCodeViewModel();

    useEffect(() => {
        onStateChangedFn(state);
    }, [state.canContinue, state.codeState]);

    function onChange(value: string) {
        onChangeFn(state, value);
    }

    function onOtpFail() {
        onOtpFailFn(state);
    }

    function onRequestNew() {
        if (state.securityType === "Enrollment" || state.securityType === "OMT") {
            let sessionId = state.searchParams.get("id");
            hooks.navigate({
                pathname: "/auth/request-code",
                search: `?id=${sessionId}`
            });
        } else {
            hooks.navigate("/auth/request-code");
        }
    }

    function onCancel() {
        if (state.securityType === "Enrollment" || state.securityType === "OMT") {
            state.setShowCancel(true);
        } else {
            hooks.navigate("/auth/login");
        }
    }

    function onSubmit(values: MfaFields, actions: FormikHelpers<MfaFields>) {
        if (state.securityType !== "Enrollment" && state.securityType !== "OMT") {
            submitLoginFn(hooks, state, api, actions);
        } else {
            submitEnrollFn(hooks, state, api, actions);
        }
    }

    return (
        <MfaEnterCodeView
            {...state}
            onChange={onChange}
            onOtpFail={onOtpFail}
            onRequestNew={onRequestNew}
            onCancel={onCancel}
            onSubmit={onSubmit}
        />
    );
}
