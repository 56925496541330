import { useEffect, useState } from "react";
import { IsNullOrEmpty } from "../utility";

export const useHover = () => {
    const [hoveredOver, setHoveredOver] = useState<Element | null | undefined>(document.activeElement);

    const handleMouseOver = (e: MouseEvent) => {
        setHoveredOver(getFirstParentWithId(document.elementFromPoint(e.x, e.y)));
    };

    useEffect(() => {
        document.addEventListener("mouseover", handleMouseOver);
        return () => {
            document.removeEventListener("mouseover", handleMouseOver);
        };
    }, []);

    function getFirstParentWithId(ele: Element | null | undefined) {
        if (ele !== null && ele !== undefined) {
            if (!IsNullOrEmpty(ele.id)) {
                return ele;
            } else {
                return getFirstParentWithId(ele.parentElement);
            }
        }
        return ele;
    }

    return hoveredOver;
};
