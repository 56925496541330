import { ReactElement } from "react";
import "./error403.scss";
import { Error403State } from "./error403ViewModel";

export function Error403View(props: Error403State): ReactElement {
    const errorMessage = "You do not have permission to access this page.";

    return (
        <div id="error403">
            <div id={`error403-page${props.size}`}>
                <div id="error403-content">
                    <div id={`error403-header${props.size}`}>
                        <div id="error403-headerText">Access Denied</div>
                    </div>
                    <div>
                        <div id="error403-subtitle">{errorMessage}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
