import { ReactElement } from "react";
import { UUID } from "../../../../utility";
import { Modal, ModalProps } from "../../../generic";
import MfaAssistanceView from "./mfaAssistanceView";
import { useMfaAssistanceViewModel } from "./mfaAssistanceViewModel";

type MfaAssistanceProps = {
    setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
    setShowNext?: React.Dispatch<React.SetStateAction<boolean>>;
    isNoPhoneEmail?: boolean;
};

export function MfaAssistanceModal(props: ModalProps & MfaAssistanceProps): ReactElement {
    return (
        <Modal show={props.show}>
            <MfaAssistanceModel {...props} />
        </Modal>
    );
}

export function MfaAssistanceModel(props: MfaAssistanceProps): ReactElement {
    const { state, hooks, api } = useMfaAssistanceViewModel();

    function onCancel() {
        hooks.removeValue(["mfaCustServReload"]);

        if (state.mfaOptions?.noContactInfo && state.securityType !== "Login") {
            state.setShowCancelModal(true);
        } else {
            if (props.setShowModal) {
                props.setShowModal(false);
            } else {
                hooks.navigate("/auth/login");
            }
        }
    }

    function onNext() {
        let options = state.mfaOptions;
        let sessionId = state.searchParams.get("id");
        hooks.removeValue(["mfaCustServReload"]);

        if (
            (state.securityType === "OMT" || state.securityType === "Enrollment") &&
            sessionId &&
            UUID.Valid(sessionId)
        ) {
            api.enrollSendCode(sessionId!)
                .then((res) => {
                    if (props.setShowModal && props.setShowNext) {
                        props.setShowModal(false);
                        props.setShowNext(true);
                    } else {
                        hooks.navigate({
                            pathname: "/auth/enter-code",
                            search: `?id=${sessionId}`
                        });
                    }
                })
                .catch((err) => {
                    //This needs better handling
                    hooks.navigate("/auth/login");
                });
        } else if (options && options.accountId && options.onlineUserId)
            api.SendCode("CustomerService", options.onlineUserId, options.accountId, "")
                .then((res) => {
                    if (props.setShowModal && props.setShowNext) {
                        props.setShowModal(false);
                        props.setShowNext(true);
                    } else {
                        hooks.navigate("/auth/enter-code");
                    }
                })
                .catch((err) => {
                    //This needs better handling
                    hooks.navigate("/auth/login");
                });
        else {
            //This is Probably an Error
            if (props.setShowModal) props.setShowModal(false);
        }
    }

    return (
        <MfaAssistanceView
            {...props}
            {...state}
            noPhoneEmail={props.isNoPhoneEmail ?? false}
            onCancel={onCancel}
            onNext={onNext}
        />
    );
}
