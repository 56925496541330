import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function StatementDoc(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.5 21.75C22.5 22.1478 22.342 22.5294 22.0607 22.8107C21.7794 23.092 21.3978 23.25 21 23.25H3C2.60218 23.25 2.22064 23.092 1.93934 22.8107C1.65804 22.5294 1.5 22.1478 1.5 21.75V2.25C1.5 1.85218 1.65804 1.47064 1.93934 1.18934C2.22064 0.908036 2.60218 0.75 3 0.75H18.045C18.4365 0.749779 18.8127 0.902663 19.093 1.176L22.047 4.059C22.1904 4.19873 22.3043 4.36575 22.3821 4.5502C22.4599 4.73465 22.5 4.93281 22.5 5.133V21.75Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M6.04492 8.25H18.0449" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.04492 12.75H18.0449" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.04492 17.25H12.0449" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
