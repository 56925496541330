import { ReactElement } from "react";
import { RadioPro } from "../../../../generic";
import "./documentReasonOption.scss";

type DocumentReasonOptionProps = {
    id: string;
    header: string;
    selection: string;
    setSelection: Function;
};

export function DocumentReasonOption(props: DocumentReasonOptionProps): ReactElement {
    return (
        <div
            className="documentReasonOption-radio"
            onClick={(e) => {
                onChange(props.id, props.setSelection);
            }}
        >
            <RadioPro
                name="documentReason"
                id={`documentReason-${props.id}`}
                data-testid={`documentReason-${props.id}`}
                className="documentReasonOption-input"
                value={props.id.toString()}
            />
            <div className="documentReasonOption-selector">
                <div className="documentReasonOption-header">
                    <h6>{props.header}</h6>
                </div>
                <p></p>
            </div>
        </div>
    );
}

const onChange = (id: string, setSelection: Function): void => {
    var radio = document.getElementById(`documentReason-${id}`) as HTMLInputElement;
    radio.checked = true;
    setSelection(id);
};
