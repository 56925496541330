import { ReactElement } from "react";
import { GenericModal } from "../../../generic";
import { DeliveryPreferencesTermsModel } from "../deliveryPreferencesTerms/deliveryPreferencesTermsModel";
import { SplitDeliveryPrompt } from "../splitDeliveryPrompt/splitDeliveryPromptModel";
import { SplitDeliveryLandingProps } from "./splitDeliveryLandingModel";
import { SplitDeliveryLandingState } from "./splitDeliveryLandingViewModel";

type SplitDeliveryLandingViewProps = SplitDeliveryLandingProps &
    SplitDeliveryLandingState & {
        unEnrollCallback: Function;
        declineTerms: Function;
        acceptTerms: Function;
    };

export function SplitDeliveryLandingView(props: SplitDeliveryLandingViewProps): ReactElement {
    return (
        <>
            {/* Prompt */}
            <SplitDeliveryPrompt
                show={props.showSplitLanding}
                setShow={props.setShowSplitLanding}
                setGoPaperless={props.setChoice}
                {...props}
            />

            {/* Terms */}
            <DeliveryPreferencesTermsModel
                show={props.showTerms}
                setShow={props.setShowTerms}
                /*IGNORE for Hack*/
                setShowDelPrefConfirmModal={props.setIgnore}
                setEditPreferences={props.setIgnore}
                initialValues={props.initialValues}
                selectedValues={props.selectedValues}
                setSelectedValues={props.setIgnore}
                /*Hacking for Split Decline*/
                resetForm={() => props.declineTerms()}
                /*Hacking for Split Accept*/
                setDelPrefTimer={() => props.acceptTerms()}
                setTempDeliveryPreferences={props.setIgnore}
            />

            {/* Confirmation */}
            <GenericModal show={props.showConfirmation} {...props.confirmationModal} />
        </>
    );
}
