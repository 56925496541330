import { createContext, ReactElement, useContext, useEffect, useState } from "react";
import { MFAOptions } from "../api/identityServer";
import { useSessionStorage } from "../hooks";
import { Children } from "../types";
import { IsNullOrEmpty, NotImplemented } from "../utility";

export type SecurityType = "Login" | "ForgotPassword" | "ForgotUsername" | "TempPassword" | "Enrollment" | "OMT";
export type ClearMfaOptions = "options" | "route" | "all";

export type MfaContextProps = {
    securityType?: SecurityType;
    setSecurityType: (value: SecurityType | undefined) => void;
    mfaOptions: MFAOptions | undefined;
    setMfaOptions: (value: MFAOptions | undefined) => void;
};

export const MFAContext = createContext<MfaContextProps>({
    securityType: undefined,
    setSecurityType: NotImplemented,
    mfaOptions: undefined,
    setMfaOptions: NotImplemented
});

const useMfaContextProps = (): MfaContextProps => {
    const [session] = useSessionStorage(["mfaOptions"]);
    const [securityType, setSecurityType] = useState<SecurityType>();
    const [mfaOptions, setMfaOptions] = useState<MFAOptions | undefined>(getMfaOptions());

    function getMfaOptions() {
        if (!IsNullOrEmpty(session["mfaOptions"])) {
            return JSON.parse(session["mfaOptions"]);
        }

        return undefined;
    }

    return {
        securityType,
        setSecurityType,
        mfaOptions,
        setMfaOptions
    };
};

export function MfaContextProvider(props: Children): ReactElement {
    const contextProps = useMfaContextProps();
    const [storage] = useSessionStorage(["mfaOptions", "mfaRoute"]);

    useEffect(() => {
        if (!IsNullOrEmpty(storage["mfaOptions"])) {
            contextProps.setMfaOptions(JSON.parse(storage.mfaOptions));
        }

        if (!IsNullOrEmpty(storage["mfaRoute"])) {
            contextProps.setSecurityType(storage.mfaRoute);
        }
    }, []);

    return (
        <>
            <MFAContext.Provider value={contextProps}>{props.children}</MFAContext.Provider>
        </>
    );
}

export const useMfaContext = () => {
    const context = useContext(MFAContext);
    const [storage, storeValue, removeValue] = useSessionStorage(["mfaRoute", "mfaOptions"]);

    function setMfaOptions(value: MFAOptions) {
        context.setMfaOptions(value);
        storeValue("mfaOptions", JSON.stringify(value));
    }

    function setSecurityType(value: SecurityType) {
        context.setSecurityType(value);
        storeValue("mfaRoute", value);
    }

    function clear(option: ClearMfaOptions) {
        if (["options", "all"].includes(option)) {
            context.setMfaOptions(undefined);
            removeValue(["mfaOptions"]);
        }

        if (["route", "all"].includes(option)) {
            context.setSecurityType(undefined);
            removeValue(["mfaRoute"]);
        }
    }

    return {
        mfaOptions: context.mfaOptions,
        setMfaOptions,
        securityType: context.securityType ?? storage.mfaRoute,
        setSecurityType,
        clear
    };
};
