import { useContext, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import {
    AppSettings,
    OnlineActions,
    UserAccount,
    useAccountContext,
    useDigitalActionsContext,
    useSettingsContext
} from "../../../api/backend";
import { CardNavContext, useSwrveContext } from "../../../contexts";
import { useViewport } from "../../../hooks";

export type DocumentUploadViewModel = {
    state: DocumentUploadState;
    api: DocumentUploadAPI;
    hooks: DocumentUploadHooks;
};

export type DocumentUploadState = {
    size: "" | "-tablet" | "-mobile";
    id: string | undefined;
    accounts: UserAccount[] | undefined;
    accountIndex: number;
    setAccountIndex: React.Dispatch<React.SetStateAction<number>>;
    appSettings: AppSettings;
    showDocUploadFeature: boolean;
    insertSwrveEvent: (event: string) => void;
    setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
    unavailableMessage: string;
    setUnavailableMessage: React.Dispatch<React.SetStateAction<string>>;
};

export type DocumentUploadAPI = {};

export type DocumentUploadHooks = {
    navigate: NavigateFunction;
    actionAllowed: (option: string, accountHandle?: string | undefined) => boolean;
};

export function useDocumentUploadViewModel(): DocumentUploadViewModel {
    const { size } = useViewport(false, true);
    const { id } = useParams();
    const { accounts, accountIndex, setAccountIndex } = useAccountContext();
    const { appSettings } = useSettingsContext();
    const showDocUploadFeature: boolean = appSettings?.featureFlags?.docUpload;
    const { insertSwrveEvent } = useSwrveContext();
    const { setShowNav } = useContext(CardNavContext);
    const [unavailableMessage, setUnavailableMessage] = useState("");
    const { actionAllowed } = useDigitalActionsContext();
    const navigate = useNavigate();

    return {
        state: {
            accountIndex,
            accounts,
            appSettings,
            id,
            insertSwrveEvent,
            setAccountIndex,
            setShowNav,
            setUnavailableMessage,
            showDocUploadFeature,
            size,
            unavailableMessage
        },
        api: {},
        hooks: { navigate, actionAllowed }
    };
}
