import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { NavigateFunction, useLoaderData, useNavigate, useParams } from "react-router-dom";
import {
    CreditLineIncreaseCms,
    CreditLineIncreaseGetResponse,
    UserAccount,
    useAccountContext,
    useCreditLineIncreaseContext,
    useDigitalActionsContext
} from "../../../api/backend";
import { CardNavContext } from "../../../contexts";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { GenericModalProps } from "../../generic";
import { RequestCLIData } from "./requestCLILoader";

type RequestCLIViewModel = {
    state: RequestCLIState;
    api: RequestCLIApi;
    hooks: RequestCLIHooks;
};

export type RequestCLIState = {
    size: Size;
    index: number;
    messageHeader: string;
    setMessageHeader: React.Dispatch<React.SetStateAction<string>>;
    messageBody: string;
    setMessageBody: React.Dispatch<React.SetStateAction<string>>;
    currentText: string;
    submittedProps: GenericModalProps;
    showSubmitted: boolean;
    setShowSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
    errorProps: GenericModalProps;
    showError: boolean;
    setShowError: React.Dispatch<React.SetStateAction<boolean>>;
    accounts: UserAccount[] | undefined;
    accountIndex: number;
    cms: CreditLineIncreaseCms;
    timeoutId: NodeJS.Timeout | undefined;
    setTimeoutId: React.Dispatch<React.SetStateAction<NodeJS.Timeout | undefined>>;
};

type RequestCLIHooks = {
    navigate: NavigateFunction;
    actionAllowed: (option: string, accountHandle?: string | undefined) => boolean;
};
type RequestCLIApi = {
    cliGet: () => Promise<AxiosResponse<CreditLineIncreaseGetResponse, any>>;
    userAccounts: () => Promise<AxiosResponse<UserAccount[], any>>;
};

export function useRequestCLIViewModel(): RequestCLIViewModel {
    const navigate = useNavigate();
    const { size } = useViewport(false, true);
    const data = useLoaderData() as RequestCLIData;
    const { id } = useParams();
    const { Get } = useCreditLineIncreaseContext();
    const { accounts, accountIndex, setAccountIndex, UserAccounts } = useAccountContext();
    const { setShowNav } = useContext(CardNavContext);
    const [messageHeader, setMessageHeader] = useState("Your Request Has Been Submitted");
    const [messageBody, setMessageBody] = useState(
        "Your request for a credit limit increase has been submitted. You will receive a response by mail within 7-10 days."
    );
    const [showSubmitted, setShowSubmitted] = useState(false);
    const [showError, setShowError] = useState(false);
    const [currentText, setCurrentText] = useState("");
    const { actionAllowed } = useDigitalActionsContext();
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

    useEffect(() => {
        setAccountIndex(Number(id));
        setShowNav(true);

        return () => {
            setShowNav(false);
        };
    }, []);

    useEffect(() => {
        setCurrentText(`Current Credit Limit: $${accounts![accountIndex].creditLimit}`);
    }, [accountIndex]);

    useEffect(() => {
        submittedProps.headerText = messageHeader;
        submittedProps.bodyText = messageBody;
    }, [messageHeader, messageBody]);

    let submittedProps = {
        headerText: messageHeader,
        bodyText: messageBody,
        primaryButton: {
            text: "Back to Home",
            action: () => {
                setShowSubmitted(false);
                navigate(`/account/summary/${id}/home`);
            }
        }
    } as GenericModalProps;

    const errorProps = {
        headerText: "Credit Line Increase Unavailable",
        bodyText: "We're sorry, something went wrong. Please try again.",
        primaryButton: {
            text: "Back to Home",
            action: () => {
                setShowError(false);
                navigate(`/account/summary/${id}/home`);
            }
        }
    } as GenericModalProps;

    function cliGet(): Promise<AxiosResponse<CreditLineIncreaseGetResponse, any>> {
        return Get(accounts[accountIndex].accountHandle);
    }

    async function userAccounts(): Promise<AxiosResponse<UserAccount[], any>> {
        return await UserAccounts();
    }

    return {
        state: {
            size,
            index: accountIndex,
            messageHeader,
            setMessageHeader,
            messageBody,
            setMessageBody,
            currentText,
            submittedProps,
            showSubmitted,
            setShowSubmitted,
            errorProps,
            showError,
            setShowError,
            cms: data.cms,
            accountIndex,
            accounts,
            timeoutId,
            setTimeoutId
        },
        api: {
            cliGet,
            userAccounts
        },
        hooks: {
            navigate,
            actionAllowed
        }
    };
}
