import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { LoginCms } from "../../../../api/backend";
import { GenericModal, GenericModalProps, ModalProps } from "../../../generic";

type LockoutProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowInfo: React.Dispatch<React.SetStateAction<boolean>>;
    cms: LoginCms;
};

export function Lockout(props: ModalProps & LockoutProps): ReactElement {
    const navigate = useNavigate();

    const state = {
        headerText: "Online Account Temporarily Locked",
        bodyText: props.cms?.LoginFailuresYesNoText,
        primaryButton: {
            text: "Reset Password",
            variant: "neutral",
            action: () => {
                props.setShow(false);
                navigate("/auth/forgot-password");
            }
        },
        secondaryButton: {
            text: "No",
            variant: "tertiary-neg",
            action: () => {
                props.setShow(false);
                props.setShowInfo(true);
            }
        }
    } as GenericModalProps;

    return <GenericModal show={props.show} {...state} />;
}
