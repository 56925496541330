/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { LegacyRef, ReactElement } from "react";
import { ArrowDown } from "../../icons";

export const DropdownToggle = (index: number, selected: boolean) =>
    React.forwardRef(
        (
            { children, onClick }: { children: ReactElement; onClick: Function },
            ref: LegacyRef<HTMLAnchorElement> | undefined
        ) => (
            <a
                href=""
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                <div className="cardNav-menu">
                    {children}
                    <ArrowDown className={selected ? `cardNav-icon-${index}` : "cardNav-icon"} />
                </div>
            </a>
        )
    );
