import { ReactElement } from "react";
import { useViewport } from "../../../../hooks";
import { Alert, BrandedCard, ButtonPro, Modal } from "../../../generic";
import "./deliveryPreferencesConfirmation.scss";

type DeliveryPreferencesConfirmationViewProps = {
    show: boolean;
    setShow: Function;
    onClose: Function;
    confirmRows: ReactElement[];
};

function DeliveryPreferencesConfirmationView(props: DeliveryPreferencesConfirmationViewProps): ReactElement {
    const { size } = useViewport(false, false);

    return (
        <Modal show={props.show}>
            <BrandedCard
                id="deliveryPreferencesConfirm"
                header="Your Delivery Preferences Have Been Updated"
                size={size}
                displayArt={false}
            >
                <div className="deliveryPreferencesConfirm-container">
                    <div className="updateDeliveryPreferences-accounts">{props.confirmRows}</div>
                    <div className="alert-info-container">
                        <Alert
                            level={"info"}
                            text={
                                "Note: You may receive communications in your prior preferred method until the new settings take effect."
                            }
                        />
                    </div>
                    <div id="deliveryPreferencesConfirm-buttongroup">
                        <ButtonPro
                            id={`deliveryPreferencesConfirmation-close${size}`}
                            variant="primary"
                            onClick={() => {
                                props.onClose();
                            }}
                        >
                            Close
                        </ButtonPro>
                    </div>
                </div>
            </BrandedCard>
        </Modal>
    );
}

export default DeliveryPreferencesConfirmationView;
