import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { useIdentityConnectContext } from "../../../../api/identityServer";
import { GenericModal, GenericModalProps, ModalProps } from "../../../generic";

type CancelPasswordResetProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export function CancelPasswordReset(props: ModalProps & CancelPasswordResetProps): ReactElement {
    const navigate = useNavigate();
    const { clear } = useIdentityConnectContext();

    const state = {
        headerText: "Cancel Password Reset?",
        bodyText: "Are you sure you would like to cancel resetting your password?",
        primaryButton: {
            text: "Cancel Resetting",
            variant: "tertiary-neg",
            action: () => {
                clear("all");
                navigate("/auth/login");
            }
        },
        secondaryButton: {
            text: "Continue Resetting",
            variant: "neutral",
            action: () => {
                props.setShow(false);
            }
        }
    } as GenericModalProps;

    return <GenericModal show={props.show} {...state} />;
}
