import { AxiosRequestConfig, AxiosResponse } from "axios";
import { createContext, useContext, useRef } from "react";
import { GetInstance } from "../hooks/useAxios";
import { waitUntil } from "../utility";

export type DebounceState<T> = Map<string, { isLoading: boolean; data?: T }>;

export type ApiQueueContextProps = {
    queue: DebounceState<any>;
};

export const ApiQueueContext = createContext<ApiQueueContextProps>({
    queue: new Map()
});

export const useApiQueueContextProps = (): ApiQueueContextProps => {
    const queue = useRef<DebounceState<any>>(new Map<string, { isLoading: boolean; data?: any }>());

    return {
        queue: queue.current
    };
};

export const useApiQueueContext = () => {
    const context = useContext(ApiQueueContext);

    async function Get<T>(
        url: string,
        token?: string,
        config?: AxiosRequestConfig<any>
    ): Promise<AxiosResponse<T, any>> {
        if (!context.queue.has(url)) {
            context.queue.set(url, { isLoading: true });

            //console.log("STATE:", context.queue);

            return await GetInstance(token)
                .get<T>(url, config)
                .then((response: AxiosResponse<T, any>) => {
                    context.queue.set(url, { isLoading: false, data: response.data });
                    return response;
                })
                .finally(() => {
                    setTimeout(() => {
                        //console.log("CLEARED:", url);
                        context.queue.delete(url);
                    }, 1500);
                });
        } else {
            let waited = await waitUntil(() => {
                let value = context.queue.get(url);
                return !value?.isLoading && value?.data !== undefined;
            });

            if (!waited) {
                //console.log("DEDUPE: FAILED");
                return await GetInstance(token).get<T>(url, config);
            } else {
                //console.log("DEDUPE: SUCCESS");
                return Promise.resolve({ data: context.queue.get(url)?.data } as AxiosResponse<T, any>);
            }
        }
    }

    return {
        Get
    };
};
