import { ReactElement } from "react";
import { getColor } from "./icon";
import { ColorBlockProps, getBrandedColorCode } from "./iconProps";

export function ColorBlocks_bl_l(props: ColorBlockProps): ReactElement {
    const lg = getColor("color", getBrandedColorCode(props.colors?.lg), "#8B257C");
    const md = getColor("color", getBrandedColorCode(props.colors?.md), "#60AADB");
    const sm = getColor("color", getBrandedColorCode(props.colors?.sm), "#F37232");

    return (
        <svg
            id={props.id}
            className={props.className}
            width="358"
            height="426"
            viewBox="0 0 358 426"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="bl_l-svg"
        >
            <rect
                x="289.284"
                y="426"
                width="289.284"
                height="289.284"
                transform="rotate(180 289.284 426)"
                fill={lg}
                data-testid="bl_l-lgRec"
            />
            <rect
                x="136.455"
                y="136.715"
                width="136.455"
                height="136.455"
                transform="rotate(180 136.455 136.715)"
                fill={md}
                data-testid="bl_l-mdRec"
            />
            <rect
                x="357.511"
                y="426"
                width="68.2273"
                height="68.2273"
                transform="rotate(180 357.511 426)"
                fill={sm}
                data-testid="bl_l-smRec"
            />
        </svg>
    );
}
