import { ReactElement, useContext } from "react";
import { AppDownloadBannerContext } from "../../../contexts";
import { GenericModal, ModalProps } from "../../generic";
import { ButtonVariant } from "../../generic/inputs/buttonPro/buttonPro";

export type IntermediateWarningProps = {
    dest: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    callback?: Function;
    openNewWindow?: boolean;
    setOpenNewWindow?: React.Dispatch<React.SetStateAction<boolean>>;
};

export function IntermediateWarningModal(props: ModalProps & IntermediateWarningProps): ReactElement {
    const { warning } = useContext(AppDownloadBannerContext);

    const state = {
        show: props.show,
        headerText: warning?.Name ?? "",
        bodyText: warning?.BodyText?.replace("NOTICE:", "").trim(),
        primaryButton: {
            text: "Leave Page",
            action: () => {
                if (props.callback !== undefined) {
                    props.callback();
                }

                props.setShow(false);

                if (props.openNewWindow) {
                    window.open(props.dest, "_blank");
                } else {
                    window.location.href = props.dest;
                }

                if (props.setOpenNewWindow !== undefined) {
                    props.setOpenNewWindow(false);
                }
            }
        },
        secondaryButton: {
            text: "Stay on Page",
            variant: "neutral" as ButtonVariant,
            action: () => {
                props.setShow(false);
            }
        }
    };

    return <GenericModal {...state} />;
}
