import { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { ButtonProProps } from "./buttonPro";
import { ButtonProState } from "./buttonProViewModel";

type ButtonProViewProps = ButtonProProps &
    ButtonProState & {
        mouseUpHook: Function;
    };

export function ButtonProView(props: ButtonProViewProps): ReactElement {
    return (
        <Button
            id={props.id}
            aria-label={props.name}
            data-testid={props.id}
            className={props.className}
            bsPrefix={`btn-pro${props.size}`}
            variant={`${props.variant}${props.clickBlock ? "-active" : ""}`}
            type={props.type}
            disabled={props.disabled}
            onMouseUp={props.mouseUpHook()}
            onClick={onClickHook(props.onClick, props.clickBlock)}
            tabIndex={props.tabIndex}
        >
            {props.children !== undefined ? props.children : props.content}
        </Button>
    );
}

const onClickHook = (onClick: any | undefined, clickBlock: boolean) => {
    return clickBlock ? () => {} : onClick;
};
