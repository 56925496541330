import { ReactElement, useEffect } from "react";
import { ElectronicDeliveryPreferences, useSettingsContext } from "../../../../api/backend";
import { AccountPreferenceConfirmedRow } from "../updateDeliveryPreferencesView";
import { DeliveryPreferencesLandingView } from "./deliveryPreferencesLandingView";
import { useDeliveryPreferencesLandingViewModel } from "./deliveryPreferencesLandingViewModel";

export type DeliveryPreferencesLandingProps = {
    showLanding: boolean;
    setShowLanding: React.Dispatch<React.SetStateAction<boolean>>;
};

export function DeliveryPreferencesLandingModel(props: DeliveryPreferencesLandingProps): ReactElement {
    const { state, api } = useDeliveryPreferencesLandingViewModel();
    const { appSettings } = useSettingsContext();
    const isEMessengerDown: boolean = appSettings?.featureFlags?.isEMessengerDown;

    useEffect(() => {
        let affectedActs = state.accounts.filter((x) => x.displayEStatementPrompt === true);
        let tempDeliveryPreferences = JSON.parse(
            JSON.stringify(state.preferences ?? [])
        ) as ElectronicDeliveryPreferences[];

        tempDeliveryPreferences.forEach((x) => {
            if (affectedActs.find((a) => a.accountHandle === x.accountHandle) !== undefined) {
                x.changed = true;
                x.eStatementsEnrollment.enrollmentStatus = "Enrolled";
            }
        });

        let eles = [] as ReactElement[];
        eles = eles.concat(
            state.accounts!.map((a, i) => (
                <AccountPreferenceConfirmedRow
                    key={a.accountHandle}
                    account={a}
                    index={i}
                    preferences={tempDeliveryPreferences}
                    editPreferences={false}
                    confirmedRow={true}
                />
            ))
        );
        state.setConfirmRows(eles);
    }, []);

    function SetDelPrefTimer() {
        if (!isEMessengerDown) {
            setTimeout(() => {
                api.getPreferences();
            }, 5000);
        }
    }

    return <DeliveryPreferencesLandingView {...props} {...state} SetDelPrefTimer={SetDelPrefTimer} />;
}
