import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function useHashNavigation() {
    const [hashElement, setHashElement] = useState<HTMLElement>();
    let location = useLocation();

    const removeHashCharacter = (str: string) => {
        const result = str.slice(1);
        return result;
    };

    useEffect(() => {
        let hash = location.hash;

        setTimeout(() => {
            if (hash) {
                let element = document.getElementById(removeHashCharacter(hash));
                return setHashElement(element as HTMLElement);
            } else {
                return setHashElement(undefined);
            }
        }, 100);
    }, [location]);

    useEffect(() => {
        if (hashElement) {
            hashElement.scrollIntoView({
                //behavior: "smooth",
                block: "start",
                inline: "nearest"
            });
        }
    }, [hashElement]);
}
