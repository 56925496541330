import { createContext, useState } from "react";
import { Articles, SearchResults } from ".";
import { NotImplemented } from "../../../utility";

export type KnowledgeManagementContextProps = {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    searchText: string;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    curSearch: string;
    setCurSearch: React.Dispatch<React.SetStateAction<string>>;
    articles: Articles | undefined;
    setArticles: React.Dispatch<React.SetStateAction<Articles | undefined>>;
    curArticles: SearchResults[] | undefined;
    setCurArticles: React.Dispatch<React.SetStateAction<SearchResults[] | undefined>>;
    pages: number;
    setPages: React.Dispatch<React.SetStateAction<number>>;
    curPage: number;
    setCurPage: React.Dispatch<React.SetStateAction<number>>;
};

export const KnowledgeManagementContext = createContext<KnowledgeManagementContextProps>({
    loading: false,
    setLoading: NotImplemented,
    searchText: "",
    setSearchText: NotImplemented,
    curSearch: "",
    setCurSearch: NotImplemented,
    articles: undefined,
    setArticles: NotImplemented,
    curArticles: undefined,
    setCurArticles: NotImplemented,
    pages: 0,
    setPages: NotImplemented,
    curPage: 0,
    setCurPage: NotImplemented
});

export const useKnowledgeManagementContextProps = (): KnowledgeManagementContextProps => {
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [curSearch, setCurSearch] = useState("");
    const [articles, setArticles] = useState<Articles>();
    const [curArticles, setCurArticles] = useState<SearchResults[]>();
    const [pages, setPages] = useState(0);
    const [curPage, setCurPage] = useState(0);

    return {
        searchText,
        setSearchText,
        loading,
        setLoading,
        curSearch,
        setCurSearch,
        articles,
        setArticles,
        curArticles,
        setCurArticles,
        pages,
        setPages,
        curPage,
        setCurPage
    };
};
