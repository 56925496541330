import { ReactElement, useEffect } from "react";
import { ContextualAlertDetails } from "../../../../api/backend";
import { IsNullOrEmpty, Regex } from "../../../../utility";
import { Check, Exit } from "../../../generic/icons";
import { ManageAlertsTextsView } from "./manageAlertsTextsView";
import { useManageAlertsTextsViewModel } from "./manageAlertsTextsViewModel";

export type ManageAlertsTextProps = {
    onEditingTextAlerts: Function;
    onSaveTextAlerts: Function;
    onCancelEditTextAlerts: Function;
    isEditingSpecificAlertsChanged: boolean;
    setIsEditingSpecificAlertsChanged: React.Dispatch<React.SetStateAction<boolean>>;
    originalAlerts: ContextualAlertDetails[];
    isEnrolled: boolean;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isEditing: boolean;
    isEditingSpecificAlerts: boolean;
    setIsEditingSpecificAlerts: React.Dispatch<React.SetStateAction<boolean>>;
    canDiscardSpecificAlertsChanges: boolean;
    setCanDiscardSpecificAlertsChanges: React.Dispatch<React.SetStateAction<boolean>>;
    getAlertInfo: Function;
};

export function ManageAlertsTextsModel(props: ManageAlertsTextProps): ReactElement {
    const { api, state } = useManageAlertsTextsViewModel(props);

    //initialize the text alerts whenever the original alerts are changed
    useEffect(() => {
        state.setDisplayedAlerts(props.originalAlerts);
    }, [props.originalAlerts]);

    //Updating specific alerts and thresholds
    useEffect(() => {
        if (state.saveSpecificAlertsChanges) {
            let enabledAlerts = state.displayedAlerts.filter((allAlerts) => {
                return allAlerts.enabled === true;
            });

            api.postManageSpecificAlerts(enabledAlerts)
                .then(async () => {
                    props.setIsEditingSpecificAlerts(false);
                    props.setIsEditingSpecificAlertsChanged(false);
                    props.onSaveTextAlerts();
                    state.setShowModalUpdateSpecificAlertsSuccess(true);
                })
                .catch((err) => {
                    console.log(err);
                    state.setShowModalError(true);
                })
                .finally(() => {
                    state.setSaveSpecificAlertsChanges(false);
                    props.getAlertInfo();
                });

            if (props.canDiscardSpecificAlertsChanges) {
                props.setCanDiscardSpecificAlertsChanges(false);
            }
        }
    }, [state.saveSpecificAlertsChanges]);

    //Build the rows
    useEffect(() => {
        state.setRowsAlerts(createRows(state.displayedAlerts, false));
        state.setRowsAlertsEdit(createRows(state.displayedAlerts, true));
    }, [state.displayedAlerts]);

    function createRows(alertsSpecific: ContextualAlertDetails[], isEditingRows: boolean): ReactElement[] {
        state.setAnyThresholdItemErrors(false);
        let newRows = [] as ReactElement[];
        let currCategory: string = "";
        if (alertsSpecific.length > 0) {
            for (let i = 0; i < alertsSpecific.length; i++) {
                //if the category is not null, blank, or empty - otherwise don't show those items
                if (!IsNullOrEmpty(alertsSpecific[i].alertCategory)) {
                    const alertName: string = alertsSpecific[i].alertName;
                    const alertCode: string = alertsSpecific[i].alertCode;
                    const alertCategory: string = alertsSpecific[i].alertCategory;
                    const isThreshholdItem: boolean = alertsSpecific[i].threshold !== null;

                    if (currCategory !== alertCategory) {
                        currCategory = alertCategory;
                        newRows.push(
                            <div key={`${i} ${alertCategory}`} className="manageAlerts-section-category">
                                <div className="manageAlerts-section-category-text" key={`${i} ${alertCode} 7`}>
                                    {alertCategory}
                                </div>
                                {isEditingRows ? (
                                    <div className="checkboxPro manageAlerts-section-category-checkbox">
                                        <label className="manageAlerts-category-checkbox-label">Select all</label>
                                        <input
                                            type="checkbox"
                                            id={`${alertCategory}~all`}
                                            aria-label="check"
                                            onChange={onCategoryClick}
                                        ></input>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        );
                    }

                    newRows.push(
                        <div key={`${i}`} className="alerts-list-row">
                            <div className="manageAlerts-category-row-text" key={`${i} row-text`}>
                                {alertName}
                            </div>
                            <div className="checkboxPro" key={`${i} checkboxdiv`}>
                                {isEditingRows ? (
                                    <input
                                        id={`${alertCategory}~${alertCode}~checkbox`}
                                        key={`${alertCategory}~${alertCode}~checkbox`}
                                        type="checkbox"
                                        aria-label="check"
                                        checked={alertsSpecific[i].enabled}
                                        disabled={alertsSpecific[i].isMandatory}
                                        onChange={onSpecificAlertCheckChange}
                                    ></input>
                                ) : (
                                    <>
                                        {alertsSpecific[i].enabled || alertsSpecific[i].isMandatory ? (
                                            <Check className="manageAlerts-category-row-icon-check" />
                                        ) : (
                                            <Exit className="manageAlerts-category-row-icon-x" />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    );

                    if (isEditingRows && isThreshholdItem) {
                        const showValueAmountError =
                            ((alertsSpecific[i].thresholdMin > parseInt(alertsSpecific[i].threshold) &&
                                alertsSpecific[i].thresholdMin) ||
                                (parseInt(alertsSpecific[i].threshold) > alertsSpecific[i].thresholdMax &&
                                    alertsSpecific[i].thresholdMax) ||
                                alertsSpecific[i].threshold === "") &&
                            alertsSpecific[i].enabled;

                        if (showValueAmountError) {
                            state.setAnyThresholdItemErrors(true);
                        }

                        newRows.push(
                            <div
                                key={`${i}_input_${alertName}`}
                                className={`alerts-list-threshold-section inputField${alertsSpecific[i].enabled ? " threshold-row-expanded" : " threshold-row-collapsed"}`}
                                hidden={!alertsSpecific[i].enabled}
                            >
                                <div className={`inputField-row${showValueAmountError ? " error-line" : ""}`}>
                                    {alertsSpecific[i].thresholdFormat?.toLocaleLowerCase() === "currency" ? "$" : ""}
                                    <input
                                        id={`${alertCategory}~${alertCode}~input`}
                                        key={`${alertCategory}~${alertCode}~input`}
                                        aria-label="Threshold Amount"
                                        className="inputField-inputValue"
                                        maxLength={6}
                                        value={alertsSpecific[i].threshold}
                                        onChange={onChangeThresholdItem}
                                    />
                                </div>
                                <div className={`alerts-list-threshold${showValueAmountError ? "-error" : ""}`}>
                                    <h6>{alertsSpecific[i].thresholdErrorMsg}</h6>
                                </div>
                            </div>
                        );
                    }
                    newRows.push(<hr key={`hr${i}`} />);
                }
            }
        }

        return newRows;
    }

    const onEditSpecificAlerts = () => {
        props.setIsEditingSpecificAlerts(true);
        props.onEditingTextAlerts();
    };

    const onCancelEditSpecificAlerts = () => {
        if (props.isEditingSpecificAlertsChanged) {
            state.setShowModalCancelWithoutSaving(true);
        } else {
            props.setIsEditingSpecificAlerts(false);
            props.setIsEditingSpecificAlertsChanged(false);
            props.onCancelEditTextAlerts();
        }
    };

    const onCategoryClick = (e: any) => {
        try {
            const isChecked = e.target.checked;
            const keyFromCheckbox = e.target.id;
            const splitAlertCategory = keyFromCheckbox.split("~");
            //0: category, 1: code | all, 2: index | misc
            const alertCategory = splitAlertCategory[0];

            const tempDisplayedAlerts = state.displayedAlerts.map((existingAlert) =>
                existingAlert.alertCategory === alertCategory &&
                ((isChecked && !existingAlert.enabled) || (!isChecked && existingAlert.enabled))
                    ? updateAlert(existingAlert)
                    : existingAlert
            );
            state.setDisplayedAlerts(tempDisplayedAlerts);
        } catch (error) {
            console.log(error);
        }
    };

    const onSpecificAlertCheckChange = (e: any) => {
        try {
            const keyFromCheckbox = e.target.id;
            const splitKeyFromCheckbox = keyFromCheckbox.split("~");
            //0: input, 1: code | all
            const alertCode = splitKeyFromCheckbox[1];

            const tempDisplayedAlerts = state.displayedAlerts.map((existingAlert) =>
                existingAlert.alertCode === alertCode ? updateAlert(existingAlert) : existingAlert
            );
            state.setDisplayedAlerts(tempDisplayedAlerts);
        } catch (error) {
            console.log(error);
        }
    };

    function updateAlert(existingAlert: ContextualAlertDetails) {
        try {
            existingAlert.enabled = !existingAlert.enabled;
            props.setIsEditingSpecificAlertsChanged(true);

            if (!props.isEditingSpecificAlerts) {
                props.setIsEditingSpecificAlerts(true);
            }
        } catch (error) {
            console.log(error);
        }

        return existingAlert;
    }

    function updateThresholdItem(existingAlert: ContextualAlertDetails, inputValue: string) {
        try {
            if (existingAlert.thresholdFormat?.toLocaleLowerCase() === "currency") {
                if (
                    Regex.IsNumeric(inputValue) ||
                    inputValue === "" ||
                    (inputValue.substring(inputValue.length - 1) === "." && (inputValue.match(/\./g) || []).length < 2)
                ) {
                    let decimalPlace = inputValue.indexOf(".");
                    if (decimalPlace === -1 || inputValue.substring(decimalPlace).length <= 3)
                        existingAlert.threshold = inputValue;
                }
            } else {
                if ((Regex.IsNumeric(inputValue) || inputValue === "") && inputValue.indexOf(".") < 0)
                    existingAlert.threshold = inputValue;
            }

            if (!props.isEditingSpecificAlertsChanged) {
                props.setIsEditingSpecificAlertsChanged(true);
                props.setIsEditingSpecificAlerts(true);
            }
        } catch (error) {
            console.log(error);
        }

        return existingAlert;
    }

    const onChangeThresholdItem = (e: any) => {
        try {
            const keyFromCheckbox = e.target.id;
            const splitKeyFromCheckbox = keyFromCheckbox.split("~");
            //0: category, 1: code | all, 2: index | misc
            const alertCode = splitKeyFromCheckbox[1];
            const tempDisplayedAlerts = state.displayedAlerts.map((existingAlert) =>
                existingAlert.alertCode === alertCode
                    ? updateThresholdItem(existingAlert, e.target.value)
                    : existingAlert
            );
            state.setDisplayedAlerts(tempDisplayedAlerts);
        } catch (error) {
            console.log(error);
        }
    };

    const onSaveSpecificAlerts = () => {
        state.setSaveSpecificAlertsChanges(true);
    };

    return (
        <ManageAlertsTextsView
            {...state}
            onEditSpecificAlerts={onEditSpecificAlerts}
            onCancelEditSpecificAlerts={onCancelEditSpecificAlerts}
            onSaveSpecificAlerts={onSaveSpecificAlerts}
            onCategoryClick={onCategoryClick}
            onSpecificAlertCheckChange={onSpecificAlertCheckChange}
            isEnrolled={props.isEnrolled}
            isEditingSpecificAlerts={props.isEditingSpecificAlerts}
            isEditing={props.isEditing}
            isLoading={props.isLoading}
            isEditingSpecificAlertsChanged={props.isEditingSpecificAlertsChanged}
        />
    );
}
