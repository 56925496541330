import { Form, Formik } from "formik";
import { ChangeEvent, ReactElement, useEffect } from "react";
import { Loading, PasswordCriteria, PasswordValidation } from "../..";
import { FormProps } from "../../../types";
import { SetTitle } from "../../../utility";
import { Alert, BackArrow, ButtonPro, PasswordInput } from "../../generic";
import "./changePassword.scss";
import { ChangePasswordState } from "./changePasswordViewModel";

type ChangePasswordViewProps = ChangePasswordState & {
    checkPassword: Function;
    onCancel: Function;
    onSubmit: Function;
};

export type ChangePasswordFields = {
    verifyPassword: string;
    password: string;
    confirmPassword: string;
    passwordCriteria: PasswordCriteria;
};

export type ChangePasswordErrors = {
    verifyPassword?: string;
    password?: string;
    confirmPassword?: string;
};

export function ChangePasswordView(props: ChangePasswordViewProps): ReactElement {
    useEffect(() => {
        SetTitle("Change Password");
        return () => SetTitle("");
    }, []);

    let initialValues = {
        verifyPassword: "",
        password: "",
        confirmPassword: "",
        passwordCriteria: {
            length: undefined,
            uppercase: undefined,
            lowercase: undefined,
            numericSpecial: undefined,
            confirmed: undefined,
            hasError: undefined
        }
    };

    return (
        <div className={`changePassword${props.size} container background-primary-lit`}>
            <div className={`changePassword-page-container${props.size}`}>
                <div className="changePassword-page-subcontainer">
                    <BackArrow available to="/account/dashboard">
                        <h2>Change Password</h2>
                    </BackArrow>
                    <div className={`changePassword-body-container${props.size}`}>
                        <div className="changePassword-body-header-section row d-flex">
                            {props.showAlert ? (
                                <div id="alert">
                                    <Alert level="success" text={props.changePasswordConfirmationText} />
                                </div>
                            ) : (
                                <></>
                            )}

                            <h6>
                                Type your current password and new password in the fields below, then select Change
                                Password.
                            </h6>
                        </div>
                        <div className={`changePassword-body-section${props.size}`}>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={(values, actions) => {
                                    props.onSubmit(values, actions);
                                }}
                            >
                                {(formProps: FormProps<ChangePasswordFields>) => (
                                    <Form className={`changePassword-form${props.size}`} autoComplete="off">
                                        <PasswordInput
                                            id="verifyPassword"
                                            label="Current Password"
                                            placeholder="Enter current password"
                                            className="password"
                                            error={formProps.errors.verifyPassword}
                                            stateValue={{
                                                isPrivate: true,
                                                canPeek: true
                                            }}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                props.checkPassword(
                                                    e.currentTarget.value,
                                                    "verifyPassword",
                                                    formProps.values
                                                );
                                                formProps.handleChange(e);
                                            }}
                                        />
                                        <PasswordInput
                                            id="password"
                                            label="New Password"
                                            placeholder="Enter new password"
                                            className="password"
                                            error={formProps.errors.password}
                                            stateValue={{
                                                isPrivate: true,
                                                canPeek: true
                                            }}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                props.checkPassword(
                                                    e.currentTarget.value,
                                                    "password",
                                                    formProps.values
                                                );
                                                formProps.handleChange(e);
                                            }}
                                        />
                                        <PasswordInput
                                            id="confirmPassword"
                                            label="Confirm New Password"
                                            placeholder="Re-enter new password"
                                            className="password"
                                            error={formProps.errors.confirmPassword}
                                            stateValue={{
                                                isPrivate: true,
                                                canPeek: true
                                            }}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                props.checkPassword(
                                                    e.currentTarget.value,
                                                    "confirmPassword",
                                                    formProps.values
                                                );
                                                formProps.handleChange(e);
                                            }}
                                        />
                                        <div id="changePasswordRequirements">
                                            <PasswordValidation
                                                touched={props.passwordTouched}
                                                confirmTouched={props.confirmTouched}
                                                criteria={formProps.values.passwordCriteria}
                                            />

                                            <h6>All fields are required</h6>
                                        </div>
                                        <div id={`changePassword-Buttons${props.size}`}>
                                            <ButtonPro
                                                className={`changePassword-cancel${props.size}`}
                                                type="button"
                                                variant="secondary"
                                                onClick={() => props.onCancel()}
                                            >
                                                Cancel
                                            </ButtonPro>
                                            <ButtonPro
                                                className={`changePassword-continue${props.size}`}
                                                type="submit"
                                                variant="primary"
                                                disabled={!props.canContinue}
                                                isSubmitting={formProps.isSubmitting}
                                            >
                                                Change Password
                                            </ButtonPro>
                                        </div>
                                        <Loading show={formProps.isSubmitting} />
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
