import { Alert } from "../../generic";
import "./alertTest.scss";

export function AlertTest(): React.ReactElement {
    const header = "Lorem Ipsum";
    const text =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";

    return (
        <div id="alertTest">
            <Alert level="success" header={header} text={text} />
            <Alert level="warning" text={text} />
            <Alert
                level="error"
                header={header}
                text={text}
                more={() => {
                    console.log("Error Clicked");
                }}
            />
            <Alert
                level="info"
                text={text}
                more={() => {
                    console.log("Info Clicked");
                }}
            />
        </div>
    );
}
