export function GetCents(amount: number | string | undefined): string {
    if (Number(amount)) {
        let val = Number(amount).toString();
        let index = val?.indexOf(".");
        if (index > 0) {
            let cents = val?.substring(index, index + 3);
            if (cents.length === 1) return ".00";
            if (cents.length === 2) return `${cents}0`;
            return cents;
        }
    }

    return ".00";
}

export function GetDollars(amount: number | string | undefined): string {
    if (Number(amount)) {
        let val = Number(amount).toString();
        let index = val?.indexOf(".");
        if (index > 0) return val?.substring(0, val.indexOf("."));
        return val;
    }

    return "0";
}

export type FormattedMoney = {
    formatted: string;
    dollars: string;
    cents: string;
};

export function FormatMoney(
    amount: number | string | undefined,
    includeSign: boolean = true,
    includeCents: boolean = true
): FormattedMoney {
    let cents = GetCents(amount);
    let dollars = GetDollars(amount);
    let formatted = `${includeSign ? "$" : ""}${dollars}${includeCents ? cents : ""}`;

    return { formatted, dollars, cents };
}
