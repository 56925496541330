import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Login(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.2855 17.1428V21.4285C16.2855 21.8831 16.1049 22.3192 15.7834 22.6407C15.4619 22.9622 15.0259 23.1428 14.5712 23.1428H2.57122C2.11656 23.1428 1.68053 22.9622 1.35904 22.6407C1.03755 22.3192 0.856934 21.8831 0.856934 21.4285V2.57134C0.856934 2.11668 1.03755 1.68065 1.35904 1.35916C1.68053 1.03767 2.11656 0.857056 2.57122 0.857056H14.5712C15.0259 0.857056 15.4619 1.03767 15.7834 1.35916C16.1049 1.68065 16.2855 2.11668 16.2855 2.57134V6.85706"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M21.4286 12H7.71436" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M11.1429 8.57141L7.71436 12L11.1429 15.4286"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
