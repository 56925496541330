import { useContext, useEffect } from "react";
import { NavigateFunction, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { LettersCms, UserAccount, useAccountContext, useDigitalActionsContext } from "../../../../api/backend";
import { CardNavContext } from "../../../../contexts";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { DocumentsCMSData } from "./documentsCMSLoader";

type DocumentsSummaryViewModel = {
    hooks: DocumentsSummaryHooks;
    state: DocumentsSummaryState;
};

type DocumentsSummaryHooks = {
    navigate: NavigateFunction;
    actionAllowed: (option: string, accountHandle?: string | undefined) => boolean;
};

export type DocumentsSummaryState = {
    cms: LettersCms;
    size: Size;
    urlId: number;
    accounts: UserAccount[];
    accountIndex: number;
    setAccountIndex: React.Dispatch<React.SetStateAction<number>>;
    setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useDocumentsSummaryViewModel(): DocumentsSummaryViewModel {
    const navigate = useNavigate();
    const { size } = useViewport(false, true);
    const { id } = useParams();
    const { accounts, accountIndex, setAccountIndex } = useAccountContext();
    const { setShowNav } = useContext(CardNavContext);
    const data = useLoaderData() as DocumentsCMSData;
    const { actionAllowed } = useDigitalActionsContext();

    useEffect(() => {
        setAccountIndex(Number(id));
    }, []);

    return {
        hooks: {
            navigate,
            actionAllowed
        },
        state: {
            ...data,
            size,
            urlId: Number(id),
            accounts: accounts!,
            accountIndex,
            setAccountIndex,
            setShowNav
        }
    };
}
