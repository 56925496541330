import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function HomeFill(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg id={id} className={svgClass} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.95459 8.92147L0.969445 11.9091C0.676676 12.2022 0.676877 12.677 0.969895 12.9698C1.26291 13.2626 1.73779 13.2624 2.03055 12.9694L3.75 11.2485V21.4393C3.75 21.8535 4.08579 22.1893 4.5 22.1893L9 22.1892C9.41421 22.1892 9.75 21.8534 9.75 21.4392V16.9392C9.75 16.3425 9.98705 15.7702 10.409 15.3482C10.831 14.9263 11.4033 14.6892 12 14.6892C12.5967 14.6892 13.169 14.9263 13.591 15.3482C14.0129 15.7702 14.25 16.3425 14.25 16.9392V21.4392C14.25 21.8534 14.5858 22.1892 15 22.1892C16.4979 22.1698 18.0014 22.1893 19.5 22.1893C19.9142 22.1893 20.25 21.8535 20.25 21.4393V11.2512L21.9699 12.9698C22.2629 13.2626 22.7377 13.2624 23.0305 12.9694C23.3233 12.6764 23.3231 12.2015 23.0301 11.9087L20.0403 8.92106C20.0336 8.91412 20.0268 8.90731 20.0198 8.90064L13.5892 2.47485L13.5881 2.47364C13.38 2.26443 13.1329 2.09817 12.8607 1.98432C12.588 1.87026 12.2955 1.81105 11.9999 1.81007C11.7043 1.8091 11.4114 1.86638 11.138 1.97864C10.8645 2.0909 10.6159 2.25595 10.4062 2.46435L10.4044 2.46614L3.98472 8.89131C3.9744 8.90108 3.96435 8.91113 3.95459 8.92147Z" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
