import { ReactElement } from "react";
import { GenericModal, ModalProps } from "../../generic";

type ManageAlertsUnavailableProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ManageAlertsUnavailable(props: ModalProps & ManageAlertsUnavailableProps): ReactElement {
    const state = {
        headerText: "Manage Alerts is Temporarily Unavailable",
        bodyText: "We're sorry. Alerts is currently unavailable. We apologize for any inconvenience.",
        primaryButton: {
            text: "Close",
            action: () => {
                props.setShow(false);
            }
        }
    };

    return (
        <>
            <GenericModal show={props.show} {...state} />
        </>
    );
}
