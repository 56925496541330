import { ReactElement } from "react";
import { UserAccount } from "../../../../api/backend";
import { StatementDoc } from "../../icons";
import { Notification } from "../../icons/notification";
import "./indexedApplication.scss";

export type IndexedApplicationProps = {
    icon?: boolean;
    account: UserAccount;
    size?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    notification?: boolean;
    notificationNumber?: string;
};

export function IndexedApplication({
    account,
    size = "h4",
    icon = true,
    notification = false,
    notificationNumber = ""
}: IndexedApplicationProps): ReactElement {
    let i = account.accent;
    let is = size === "h6" ? "sm" : "lg";

    return (
        <div className={`indexedApplication-${is}`}>
            {icon ? (
                <div className="indexedApplication-icon">
                    <StatementDoc className={`indexedApplication-icon-${is}`} />
                    {notification ? (
                        <>
                            <Notification
                                className="indexedApplication-icon-notification"
                                notificationNumber={notificationNumber}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <></>
            )}

            <GetSize index={i} refNumber={account.referenceNumber ?? 0} size={size} />
        </div>
    );
}

export function GetSize(props: { index: number; refNumber: number; size: string }): ReactElement {
    const cn = `indexedApplication-cardNumber`;
    const content = `Reference ${props.refNumber}`;

    switch (props.size) {
        case "h1":
            return <h1 className={cn}>{content}</h1>;
        case "h2":
            return <h2 className={cn}>{content}</h2>;
        case "h3":
            return <h3 className={cn}>{content}</h3>;
        case "h4":
            return <h4 className={cn}>{content}</h4>;
        case "h5":
            return <h5 className={cn}>{content}</h5>;
        case "h6":
            return <h6 className={cn}>{content}</h6>;
    }

    return <></>;
}
