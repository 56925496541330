import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { Size } from "../../../../types";
import { Warning } from "../../../generic/icons";
import { ButtonPro } from "../../../generic/inputs/buttonPro/buttonPro";
import "./documentUploadUnavailable.scss";

type DocumentUploadUnavailableProps = {
    unavailableMessage: string;
    size: Size;
};

function DocumentUploadUnavailable(props: DocumentUploadUnavailableProps): ReactElement {
    return (
        <>
            <div id="documentUploadForm">
                <DocumentUploadUnavailableMessage {...props} />
                <DocumentUploadUnavailableButtons {...props} />
            </div>
        </>
    );
}

function DocumentUploadUnavailableMessage(props: DocumentUploadUnavailableProps): ReactElement {
    return (
        <>
            <div className="documentUploadForm-unavailable">
                <div className="documentUploadForm-unavailable-header">
                    <div className="documentUploadForm-unavailable-icon">
                        <Warning className="documentUploadForm-unavailable-warning-icon" />
                    </div>
                    <div className="documentUploadForm-unavailable-content">
                        <span
                            className="documentUploadForm-unavailable-text"
                            dangerouslySetInnerHTML={{
                                __html: props.unavailableMessage
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

function DocumentUploadUnavailableButtons(props: DocumentUploadUnavailableProps): ReactElement {
    const navigate = useNavigate();

    function goToDashboard() {
        navigate("/account/dashboard");
    }

    return (
        <div className="documentUploadForm-unavailable-buttons">
            <ButtonPro
                className={`documentUploadForm-unavailable-button${props.size}`}
                variant="primary"
                onClick={goToDashboard}
            >
                Back to Home
            </ButtonPro>
        </div>
    );
}

export default DocumentUploadUnavailable;
