import { AxiosResponse } from "axios";
import {
    AcceptTermsRequest,
    AcceptTermsResponse,
    MfaCodeRequest,
    MfaOptionsRequest,
    MfaOptionsResponse,
    MfaProcessCodeRequest,
    ValidateIdentityByIdRequest,
    ValidateIdentityRequest,
    ValidateUsernameRequest
} from ".";
import { UsePost } from "../../../hooks";

export const useEnrollmentContext = () => {
    const client = useEnrollmentClient();

    return { ...client };
};

const useEnrollmentClient = () => {
    function ValidateIdentity(request: ValidateIdentityRequest): Promise<AxiosResponse<any, any>> {
        return UsePost("api/v1/enrollment/validateidentity", undefined, request);
    }

    function ValidateIdentityById(request: ValidateIdentityByIdRequest): Promise<AxiosResponse<any, any>> {
        return UsePost("api/v1/enrollment/validateidentitybyid", undefined, request);
    }

    function ValidateUsername(request: ValidateUsernameRequest): Promise<AxiosResponse<any, any>> {
        return UsePost("api/v1/enrollment/validateusername", undefined, request);
    }

    function MfaOptions(request: MfaOptionsRequest): Promise<AxiosResponse<MfaOptionsResponse, any>> {
        return UsePost("api/v1/enrollment/mfaoptions", undefined, request);
    }

    function MfaRequestCode(request: MfaCodeRequest): Promise<AxiosResponse<any, any>> {
        return UsePost("api/v1/enrollment/mfarequestcode", undefined, request);
    }

    function MfaProcessCode(request: MfaProcessCodeRequest): Promise<AxiosResponse<any, any>> {
        return UsePost("api/v1/enrollment/mfaprocesscode", undefined, request);
    }

    function AcceptTerms(request: AcceptTermsRequest): Promise<AxiosResponse<AcceptTermsResponse, any>> {
        return UsePost("api/v1/enrollment/acceptterms", undefined, request);
    }

    return {
        ValidateIdentity,
        ValidateIdentityById,
        ValidateUsername,
        MfaOptions,
        MfaRequestCode,
        MfaProcessCode,
        AcceptTerms
    };
};
