import { AxiosResponse } from "axios";
import { ReCaptchaResponse } from ".";
import { UseGet } from "../../../hooks";

export const useReCaptchaContext = () => {
    const client = useReCaptchaClient();

    return { ...client };
};

const useReCaptchaClient = () => {
    async function Get(responseToken: string): Promise<AxiosResponse<ReCaptchaResponse, any>> {
        return await UseGet<ReCaptchaResponse>(`api/v1/recaptcha/verify?responseToken=${responseToken}`);
    }

    return { Get };
};
