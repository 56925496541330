import { ReactElement } from "react";
import { Size } from "../../../../../types";
import "./documentsSummarySplash.scss";

export type DocumentsSummarySplashProps = {
    size: Size;
    accountIndex: number;
};

export function DocumentsSummarySplash(props: DocumentsSummarySplashProps): ReactElement {
    return <h2 className="documentsSummarySplash">Documents</h2>;
}

export default DocumentsSummarySplash;
