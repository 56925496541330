import { ReactElement } from "react";
import { CCPAAuthenticatedFormFields, CCPAAuthenticatedView } from "./ccpaAuthenticatedView";
import { FormikHelpers } from "formik";
import { CCPAFormModelProps, useCCPAFormViewModel } from "../ccpaFormViewModel";

export function CCPAAuthenticatedModel(props: CCPAFormModelProps): ReactElement {
    const { state, api } = useCCPAFormViewModel({ ...props, isPublic: false });

    function onCancel() {
        state.setShowCancel(true);
    }

    function onSubmit(values: CCPAAuthenticatedFormFields, actions: FormikHelpers<CCPAAuthenticatedFormFields>) {
        api.ccpaPrivate(values, actions);
    }

    return <CCPAAuthenticatedView {...props} {...state} onSubmit={onSubmit} onCancel={onCancel} />;
}
