import { ReactElement, useEffect } from "react";
import { DocUploadRequest, DocumentReasons, UserAccount, ValidationErrors } from "../../../../api/backend";
import { Size } from "../../../../types";
import { DocumentReasonOption } from "./documentReasonOption/documentReasonOption";
import DocumentUploadFormView from "./documentUploadFormView";
import { useDocumentUploadFormViewModel } from "./documentUploadFormViewModel";

export type DocumentUploadFormModelProps = {
    account: UserAccount;
    index: number;
    size: Size;
    unavailableMessage: string;
};

export function DocumentUploadFormModel(props: DocumentUploadFormModelProps): ReactElement {
    const { state, api } = useDocumentUploadFormViewModel(props);

    useEffect(() => {
        api.GetDocumentReasons(props.account.accountHandle).then((res) => {
            if (res.status === 200) {
                state.setRows(createRows(res.data));
            } else {
                //if we get a valid error status, return that, otherwise use what was assigned
                if (res.statusText) {
                    props.unavailableMessage = res.statusText;
                }
            }
        });
    }, [state.loadReasons, state.accountIndex]);

    useEffect(() => {
        if (state.files.length > 0 && state.documentReasonId !== undefined) {
            state.setSubmitDisabled(false);
        } else {
            state.setSubmitDisabled(true);
        }
    }, [state.files, state.documentReasonId]);

    function createRows(data: DocumentReasons[]): ReactElement[] {
        let newRows = [] as ReactElement[];
        for (let i = 0; i < data.length; i++) {
            newRows.push(
                <DocumentReasonOption
                    key={data[i]?.Id}
                    id={data[i]?.Id.toString()}
                    header={data[i]?.Title}
                    selection={""}
                    setSelection={state.setDocumentReasonId}
                />
            );
        }
        return newRows;
    }

    const onUploadClick = () => {
        if (state.inputFile && state.inputFile.current && state.files.length < state.maxFiles)
            state.inputFile.current.click();
    };

    function onFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].size >= state.appSettings?.docUpload?.docUploadMaxFileSize) {
                state.setBodyText(
                    `Unable to add document ${event.target.files[0].name}: File size is greater than what is acceptable.`
                );
                state.setShowUploadError(true);
            } else if (
                ![
                    "jpg",
                    "jpeg",
                    "pdf",
                    "png",
                    "tiff",
                    "image/jpg",
                    "image/jpeg",
                    "application/pdf",
                    "image/png",
                    "image/tiff"
                ].includes(event.target.files[0].type.toLocaleLowerCase())
            ) {
                state.setBodyText(
                    `Unable to add document ${event.target.files[0].name}: File format is not permitted.`
                );
                state.setShowUploadError(true);
            } else if (event.target.files[0].size === 0) {
                state.setBodyText(`Error for document ${event.target.files[0].name}: File is empty.`);
                state.setShowUploadError(true);
            } else if (state.files.length + event.target.files.length <= state.maxFiles) {
                var newFiles = Array.from(event.target.files);
                state.setFiles([...state.files, ...newFiles]);
            }
        }
    }

    function removeFile(id: number) {
        var fileArr = [...state.files];
        fileArr.splice(id, 1);
        state.setFiles([...fileArr]);
    }

    function UploadDocuments() {
        if (
            state.documentReasonId &&
            state.documentReasonId !== undefined &&
            state.files.length > 0 &&
            state.files.length <= state.maxFiles
        ) {
            const DocumentUpload: DocUploadRequest = {
                accountHandle: props.account.accountHandle,
                documentReasonId: state.documentReasonId,
                files: state.files
            };
            api.PostFiles(DocumentUpload)
                .then((data) => {
                    state.setLoadReasons((x) => x + 1);
                    state.setShowUploadConfirmation(true);
                })
                .catch((err: any) => {
                    let errors = err.response?.data as ValidationErrors;
                    let errorField = Object.keys(errors.errors)[0];
                    let errorMessage = errors.errors[errorField][0];

                    if (errorMessage === "Daily upload limit reached or exceeded") {
                        state.setBodyText(`Unable to upload documents: Daily upload limit reached or exceeded.`);
                        state.setShowUploadError(true);
                    } else {
                        state.setBodyText("Unable to upload documents: Try again.");
                        state.setShowUploadError(true);
                    }
                    console.log(err);
                });
        }
    }

    return (
        <DocumentUploadFormView
            {...props}
            {...state}
            onUploadClick={onUploadClick}
            onFileChange={onFileChange}
            onSubmit={UploadDocuments}
            removeFile={removeFile}
        />
    );
}
