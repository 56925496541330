import { Form, Formik } from "formik";
import { ReactElement } from "react";
import { BrandedCard, ButtonPro, Modal } from "../../../generic";
import "./manageAlertsTerms.scss";
import { ManageAlertsTermsState } from "./manageAlertsTermsViewModel";

type ManageAlertsTermsViewProps = ManageAlertsTermsState & {
    onDeclineTerms: Function;
    onSubmit: Function;
    onAccept: Function;
    scrolledTerms: boolean;
    setScrolledTerms: React.Dispatch<React.SetStateAction<boolean>>;
    onCloseViewTerms: Function;
    viewOnlyTermsAndConditions: boolean;
};

export type ManageAlertsTermsFields = {
    accept: boolean;
};

export function ManageAlertsTermsView(props: ManageAlertsTermsViewProps): ReactElement {
    function handleOnScroll(e: any) {
        const { scrollTop, scrollHeight, offsetHeight } = e.target;
        const hasScrollReachedBottom = offsetHeight + scrollTop > scrollHeight - 40;

        if (hasScrollReachedBottom) {
            props.setScrolledTerms(true);
        }
    }

    return (
        <>
            <Modal show={true}>
                <BrandedCard id="manageAlertsTerms" header={"Alerts Terms and Conditions"} size={props.size}>
                    <div className="manageAlertsTerms-body">
                        <Formik
                            initialValues={{ accept: false }}
                            onSubmit={(values, actions) => {
                                props.onSubmit(values, actions);
                            }}
                        >
                            {({ values, isSubmitting }) => (
                                <Form className={`manageAlertsTerms-form${props.size}`} autoComplete="off">
                                    <div className="manageAlertsTerms-border">
                                        <div className="manageAlertsTerms-content">
                                            <span
                                                id="manageAlertsTerms-parent-id"
                                                onScroll={handleOnScroll}
                                                className="manageAlertsTerms-terms"
                                                dangerouslySetInnerHTML={{
                                                    __html: props.cms.AlertsTermsAndConditionsDisclosure.BodyText
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="manageAlertsTerms-instruction"
                                        hidden={props.viewOnlyTermsAndConditions}
                                    >
                                        Scroll to the end to accept.
                                    </div>
                                    <div className="manageAlertsTerms-buttons">
                                        <span
                                            hidden={props.viewOnlyTermsAndConditions}
                                            className="manageAlertsTerms-enroll-container"
                                        >
                                            <ButtonPro
                                                id={`manageAlertsTerms-cancel${props.size}`}
                                                type="button"
                                                variant="neutral"
                                                onClick={() => props.onDeclineTerms()}
                                            >
                                                Decline
                                            </ButtonPro>
                                            <ButtonPro
                                                id={`manageAlertsTerms-submit${props.size}`}
                                                type="submit"
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    props.onAccept();
                                                }}
                                                isSubmitting={isSubmitting}
                                                disabled={!props.scrolledTerms}
                                            >
                                                Accept
                                            </ButtonPro>
                                        </span>
                                        <span
                                            hidden={!props.viewOnlyTermsAndConditions}
                                            className="manageAlertsTerms-close-container"
                                        >
                                            <span className="manageAlertsTerms-close">
                                                <ButtonPro
                                                    id={`manageAlertsTerms-submit${props.size}`}
                                                    type="button"
                                                    onClick={() => props.onCloseViewTerms()}
                                                >
                                                    Close
                                                </ButtonPro>
                                            </span>
                                        </span>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </BrandedCard>
            </Modal>
        </>
    );
}
