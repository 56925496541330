import { ReactElement, useEffect } from "react";
import { ExpandableRowContext } from "../../../contexts";
import ActivityView from "./activityView";
import { useActivityViewModel } from "./activityViewModel";

export function ActivityModel(): ReactElement {
    const { context, state, api } = useActivityViewModel();

    useEffect(() => {
        state.setLoading(true);
        state.setSeeMore(false);
        api.All(state.account.accountHandle)
            .then((res) => {
                state.setTransactions(res.data);
                state.setActivityErr(false);
            })
            .catch(() => {
                state.setActivityErr(true);
            })
            .finally(() => {
                state.setLoading(false);
            });
    }, [state.accountIndex]);

    return (
        <ExpandableRowContext.Provider value={{ ...context }}>
            <ActivityView {...state} />
        </ExpandableRowContext.Provider>
    );
}
