import { ReactElement } from "react";
import { getColor } from "./icon";
import { ColorBlockProps, getBrandedColorCode } from "./iconProps";

export function ColorBlocks_br_c(props: ColorBlockProps): ReactElement {
    const lg = getColor("color", getBrandedColorCode(props.colors?.lg), "#8B257C");
    const md = getColor("color", getBrandedColorCode(props.colors?.md), "#F37232");
    const sm = getColor("color", getBrandedColorCode(props.colors?.sm), "#60AADB");

    return (
        <svg
            id={props.id}
            className={props.className}
            width="255"
            height="170"
            viewBox="0 0 255 170"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="br_c-svg"
        >
            <rect x="85" width="170" height="170" fill={lg} data-testid="br_c-lgRec" />
            <rect y="85" width="85" height="85" fill={md} data-testid="br_c-mdRec" />
            <rect x="42.5" y="42.5" width="42.5" height="42.5" fill={sm} data-testid="br_c-smRec" />
        </svg>
    );
}

<svg xmlns="http://www.w3.org/2000/svg" width="255" height="170" viewBox="0 0 255 170" fill="none"></svg>;
