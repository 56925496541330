import { ReactElement, useEffect } from "react";
import { DashboardCard } from ".";
import { CardApplication } from "../cardApplication/cardApplication";
import DashboardView from "./dashboardView";
import { storageKeys, useDashboardViewModel } from "./dashboardViewModel";

export function DashboardModel(): ReactElement {
    const { hooks, state } = useDashboardViewModel();

    useEffect(() => {
        state.setShowNav(false);
    }, []);

    useEffect(() => {
        hooks.insertSwrveEvent("Web_Dashboard_Screen");
        hooks.removeValue(storageKeys);
    }, []);

    useEffect(() => {
        if (state.accounts?.length === 1) {
            hooks.navigate("/account/summary/0/home", { relative: "path" });
        } else if (state.accounts?.length ?? 0 > 1) {
            let eles = [] as ReactElement[];

            eles = eles.concat(
                state.accounts!.map((a, i) => {
                    return a.isApplicationStatus ? (
                        <CardApplication key={a.accountHandle} index={i} account={a} cms={state.appCms} isDashboard />
                    ) : (
                        <DashboardCard key={a.accountHandle} index={i} account={a} />
                    );
                })
            );
            state.setRows(eles);
        } else {
            //No Accounts?
            console.log("No accounts", state.accounts);
        }
    }, [state.accounts, state.appCms]);

    return <DashboardView {...state} />;
}
