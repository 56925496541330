import { AxiosResponse } from "axios";
import { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { CookieSetOptions } from "universal-cookie";
import {
    UserAccount,
    useAccountContext,
    useDigitalActionsContext,
    useKnowledgeManagementContext,
    useProfileContext
} from "../../../../api/backend";
import { ClearIdentityOptions, useIdentityConnectContext } from "../../../../api/identityServer";
import { AppDownloadBannerContext, useOneTrustContext, useSwrveContext } from "../../../../contexts";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { AppNavProps } from "../../appNavProps";

type AppHeaderPrivateViewModel = {
    hooks: AppHeaderPrivateHooks;
    state: AppHeaderPrivateState;
    api: AppHeaderPrivateApi;
};

type AppHeaderPrivateHooks = {
    navigate: NavigateFunction;
    clearIdentity: (option: ClearIdentityOptions) => void;
    clearConsumerGuid: () => void;
    clearJwt: () => void;
    removeCookie: (name: string, options?: CookieSetOptions | undefined) => void;
    swrveShutdown: () => void;
    onSearch: () => void;
    actionAllowed: (option: string, accountHandle?: string | undefined) => boolean;
};

export type AppHeaderPrivateState = {
    size: Size;
    accounts: UserAccount[] | undefined;
    szKey: false | "md";
    setSzKey: React.Dispatch<React.SetStateAction<false | "md">>;
    acProps: AppNavProps;
    setAcProps: React.Dispatch<React.SetStateAction<AppNavProps>>;
    iKey: string | undefined;
    setIKey: React.Dispatch<React.SetStateAction<string | undefined>>;
    showOffCanvas: boolean;
    setShowOffCanvas: React.Dispatch<React.SetStateAction<boolean>>;
    showBanner: boolean;
    setAccountIndex: React.Dispatch<React.SetStateAction<number>>;
    accountIndex: number;
    updateProfileAction: boolean;
    setUpdateProfileAction: React.Dispatch<React.SetStateAction<boolean>>;
    updateDeliveryAction: boolean;
    setUpdateDeliveryAction: React.Dispatch<React.SetStateAction<boolean>>;
    searchText: string;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    location: Location<any>;
};

type AppHeaderPrivateApi = {
    logout: () => Promise<AxiosResponse<any, any>>;
};

const cookieKeys = ["OptanonConsent", "OptanonAlertBoxClosed"];

export function useAppHeaderPrivateViewModel(): AppHeaderPrivateViewModel {
    const { clear: clearIdentity } = useIdentityConnectContext();
    const { clearConsumerGuid, clearJwt } = useOneTrustContext();
    const { swrveShutdown } = useSwrveContext();
    const { showBanner } = useContext(AppDownloadBannerContext);
    const navigate = useNavigate();
    const { size } = useViewport(true);
    const [szKey, setSzKey] = useState("md" as "md" | false);
    const [iKey, setIKey] = useState("0" as string | undefined);
    const [acProps, setAcProps] = useState({ open: true, accord: "mm-", keys: ["0", "0", "0"] });
    const [showOffCanvas, setShowOffCanvas] = useState(false);
    const { accountIndex, accounts, setAccountIndex } = useAccountContext();
    const { actionAllowed } = useDigitalActionsContext();
    const [updateProfileAction, setUpdateProfileAction] = useState(false);
    const [updateDeliveryAction, setUpdateDeliveryAction] = useState(false);
    const verint = useKnowledgeManagementContext();
    const location = useLocation();
    const [, , removeCookie] = useCookies(cookieKeys);
    const { PutLogout: logout } = useProfileContext();

    return {
        hooks: {
            navigate,
            clearIdentity,
            clearConsumerGuid,
            clearJwt,
            removeCookie,
            swrveShutdown,
            onSearch: verint.onSearch,
            actionAllowed
        },
        state: {
            size,
            accounts,
            szKey,
            setSzKey,
            acProps,
            setAcProps,
            iKey,
            setIKey,
            showOffCanvas,
            setShowOffCanvas,
            showBanner,
            setAccountIndex,
            accountIndex,
            setUpdateProfileAction,
            updateProfileAction,
            updateDeliveryAction,
            setUpdateDeliveryAction,
            location,
            ...verint
        },
        api: {
            logout
        }
    };
}
