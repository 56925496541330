import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function LinkOut(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M10.0669 15.6912L25.0002 1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.0001 9.39573V1H16.4668" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M13.1333 6.33325H1.93333C1.6858 6.33325 1.4484 6.43158 1.27337 6.60662C1.09833 6.78165 1 7.01905 1 7.26659V24.0666C1 24.3141 1.09833 24.5515 1.27337 24.7266C1.4484 24.9016 1.6858 24.9999 1.93333 24.9999H18.7333C18.9809 24.9999 19.2183 24.9016 19.3933 24.7266C19.5683 24.5515 19.6667 24.3141 19.6667 24.0666V12.8666"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
