import { v4 } from "uuid";

export function New(): string {
    return v4();
}

export function Valid(uuid: string | undefined): boolean {
    let valid = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/g.test(
        uuid ?? ""
    );

    return valid;
}
