import { useState } from "react";
import { NavigateFunction, useLoaderData, useNavigate } from "react-router-dom";
import { DisclosuresCms } from "../../../../api/backend";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { ManageAlertsTermsData } from "../manageAlertsLoader";

type ManageAlertsTermsViewModel = {
    hooks: ManageAlertsTermsHooks;
    state: ManageAlertsTermsState;
};

type ManageAlertsTermsHooks = {
    navigate: NavigateFunction;
};

export type ManageAlertsTermsState = {
    size: Size;
    cms: DisclosuresCms;
    showCancel: boolean;
    setshowCancel: React.Dispatch<React.SetStateAction<boolean>>;
    showError: boolean;
    setShowError: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useManageAlertsTermsViewModel(): ManageAlertsTermsViewModel {
    const navigate = useNavigate();
    const { cms } = useLoaderData() as ManageAlertsTermsData;
    const { size } = useViewport();
    const [showCancel, setshowCancel] = useState(false);
    const [showError, setShowError] = useState(false);

    return {
        hooks: {
            navigate
        },
        state: {
            size,
            cms,
            showCancel,
            setshowCancel,
            showError,
            setShowError
        }
    };
}
