import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Settings(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.75 17.2499C0.75076 16.134 1.02804 15.0356 1.55706 14.0531C2.08607 13.0705 2.85033 12.2344 3.78149 11.6195C4.71265 11.0045 5.7817 10.6299 6.89305 10.5291C8.00439 10.4283 9.12339 10.6045 10.15 11.0419"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.375 4.875C3.375 5.96902 3.8096 7.01823 4.58318 7.79182C5.35677 8.5654 6.40598 9 7.5 9C8.59402 9 9.64323 8.5654 10.4168 7.79182C11.1904 7.01823 11.625 5.96902 11.625 4.875C11.625 3.78098 11.1904 2.73177 10.4168 1.95818C9.64323 1.1846 8.59402 0.75 7.5 0.75C6.40598 0.75 5.35677 1.1846 4.58318 1.95818C3.8096 2.73177 3.375 3.78098 3.375 4.875V4.875Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.75 16.5C15.75 16.8978 15.908 17.2794 16.1893 17.5607C16.4706 17.842 16.8522 18 17.25 18C17.6478 18 18.0294 17.842 18.3107 17.5607C18.592 17.2794 18.75 16.8978 18.75 16.5C18.75 16.1022 18.592 15.7206 18.3107 15.4393C18.0294 15.158 17.6478 15 17.25 15C16.8522 15 16.4706 15.158 16.1893 15.4393C15.908 15.7206 15.75 16.1022 15.75 16.5V16.5Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.5238 10.7002L18.9658 12.1532C19.0387 12.3943 19.2005 12.5988 19.4185 12.7252C19.6364 12.8516 19.8942 12.8906 20.1398 12.8342L21.6118 12.4932C21.8908 12.4303 22.1826 12.4584 22.4444 12.5734C22.7063 12.6883 22.9245 12.8841 23.067 13.1321C23.2096 13.38 23.269 13.6671 23.2365 13.9513C23.2041 14.2354 23.0815 14.5017 22.8868 14.7112L21.8558 15.8222C21.6837 16.0072 21.5881 16.2505 21.5881 16.5032C21.5881 16.7558 21.6837 16.9991 21.8558 17.1842L22.8868 18.2952C23.0815 18.5046 23.2041 18.7709 23.2365 19.0551C23.269 19.3392 23.2096 19.6263 23.067 19.8742C22.9245 20.1222 22.7063 20.318 22.4444 20.433C22.1826 20.5479 21.8908 20.576 21.6118 20.5132L20.1398 20.1722C19.8942 20.1158 19.6364 20.1547 19.4185 20.2811C19.2005 20.4075 19.0387 20.612 18.9658 20.8532L18.5238 22.3002C18.4416 22.5742 18.2733 22.8145 18.0438 22.9853C17.8143 23.1561 17.5359 23.2483 17.2498 23.2483C16.9637 23.2483 16.6852 23.1561 16.4557 22.9853C16.2262 22.8145 16.0579 22.5742 15.9758 22.3002L15.5338 20.8472C15.4608 20.606 15.299 20.4015 15.0811 20.2751C14.8632 20.1487 14.6053 20.1098 14.3598 20.1662L12.8878 20.5072C12.6088 20.57 12.317 20.5419 12.0551 20.427C11.7932 20.312 11.5751 20.1162 11.4325 19.8682C11.29 19.6203 11.2306 19.3332 11.263 19.0491C11.2955 18.7649 11.418 18.4986 11.6128 18.2892L12.6438 17.1782C12.8158 16.9931 12.9115 16.7498 12.9115 16.4972C12.9115 16.2445 12.8158 16.0012 12.6438 15.8162L11.6128 14.7052C11.418 14.4957 11.2955 14.2294 11.263 13.9453C11.2306 13.6611 11.29 13.374 11.4325 13.1261C11.5751 12.8781 11.7932 12.6823 12.0551 12.5674C12.317 12.4524 12.6088 12.4243 12.8878 12.4872L14.3598 12.8282C14.6053 12.8846 14.8632 12.8456 15.0811 12.7192C15.299 12.5928 15.4608 12.3883 15.5338 12.1472L15.9758 10.6942C16.0586 10.4203 16.2274 10.1805 16.4573 10.0102C16.6872 9.83994 16.9659 9.74835 17.252 9.74903C17.5381 9.7497 17.8163 9.8426 18.0454 10.0139C18.2745 10.1853 18.4423 10.4259 18.5238 10.7002Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
