import { AxiosResponse } from "axios";
import { useContext, useEffect } from "react";
import { appendOptionalParam, ElectronicDeliveryPreferences, EnrollRequest, UnenrollRequest } from ".";
import { useAccountContext, useCmsContext, useDigitalActionsContext, UserAccount, useSettingsContext } from "..";
import { UseGet, UsePut, useSessionStorage } from "../../../hooks";
import { IsNullOrEmpty } from "../../../utility";
import { useIdentityConnectContext } from "../../identityServer";
import { DeliveryPreferencesContext } from "./deliveryPreferencesContext";

export const useDeliveryPreferencesContext = () => {
    const context = useContext(DeliveryPreferencesContext);
    const client = useDeliveryPreferencesClient();
    const [storage, storeValue, removeValue] = useSessionStorage(["deliveryPreferences", "deliveryLandingPrompted"]);
    const { access_token } = useIdentityConnectContext();
    const { CommunicationSettings } = useCmsContext();
    const { accounts } = useAccountContext();
    const { actionAllowed } = useDigitalActionsContext();
    const { appSettings } = useSettingsContext();
    const isEMessengerDown: boolean = appSettings?.featureFlags?.isEMessengerDown;

    useEffect(() => {
        CommunicationSettings().then((res) => {
            context.setPreferencesCms(res.data);
        });
    }, []);

    useEffect(() => {
        if (!IsNullOrEmpty(storage["deliveryPreferences"])) {
            context.setPreferences(JSON.parse(storage.deliveryPreferences));
        } else if (!IsNullOrEmpty(access_token) && !isEMessengerDown) {
            client.Get();
        }
    }, [access_token]);

    function landing() {
        if (!storage.deliveryLandingPrompted) {
            let allNotSplit = accounts?.every((x) => x.isSplitElectronicDelivery === false);
            let ePrompt = accounts?.find((x) => x.displayEStatementPrompt === true);
            let anySplit = accounts.find((x) => isSplit(x)) !== undefined;

            if (isEMessengerDown === false) {
                if (allNotSplit && ePrompt !== undefined) {
                    context.setShowPreferencesLanding(true);

                    client.LandingPage(ePrompt.accountHandle);
                } else if (anySplit) {
                    context.setShowSplitDeliveryLanding(true);
                }
            }
        }
    }

    function isSplit(account: UserAccount): boolean {
        return (
            account.isSplitElectronicDelivery === true &&
            actionAllowed("eLetterEnroll", account.accountHandle) &&
            actionAllowed("eStatementEnroll", account.accountHandle)
        );
    }

    const clear = () => {
        context.setPreferences(undefined);
        removeValue(["deliveryPreferences", "deliveryLandingPrompted"]);
    };

    return { ...context, ...client, landing, clear, isSplit };
};

const useDeliveryPreferencesClient = () => {
    const context = useContext(DeliveryPreferencesContext);
    const { access_token } = useIdentityConnectContext();
    const [, storeValue] = useSessionStorage(["deliveryPreferences"]);

    async function Get(token?: string): Promise<AxiosResponse<ElectronicDeliveryPreferences[], any>> {
        context.setLoading(true);

        return await UseGet<ElectronicDeliveryPreferences[]>("api/v1/deliverypreferences", token ?? access_token)
            .then((x) => {
                context.setPreferences(x.data);
                storeValue("deliveryPreferences", JSON.stringify(x.data));
                return x;
            })
            .finally(() => {
                context.setLoading(false);
            });
    }

    async function Enroll(request: EnrollRequest): Promise<AxiosResponse<any, any>> {
        let queryParams = `?accountHandle=${request.accountHandle}`;
        queryParams = appendOptionalParam(queryParams, "enrollStatements", request.enrollStatements);
        queryParams = appendOptionalParam(queryParams, "enrollDocuments", request.enrollDocuments);
        queryParams = appendOptionalParam(queryParams, "allAccounts", request.allAccounts);

        return await UsePut(`api/v1/deliverypreferences/enroll${queryParams}`, access_token);
    }

    async function Unenroll(request: UnenrollRequest): Promise<AxiosResponse<any, any>> {
        let queryParams = `?accountHandle=${request.accountHandle}`;
        queryParams = appendOptionalParam(queryParams, "unenrollStatements", request.unenrollStatements);
        queryParams = appendOptionalParam(queryParams, "unenrollDocuments", request.unenrollDocuments);
        queryParams = appendOptionalParam(queryParams, "allAccounts", request.allAccounts);

        return await UsePut(`api/v1/deliverypreferences/unenroll${queryParams}`, access_token);
    }

    async function LandingPage(accountHandle: string): Promise<AxiosResponse<any, any>> {
        return await UsePut(`api/v1/deliverypreferences/landingpage?accountHandle=${accountHandle}`, access_token);
    }

    return {
        Get,
        Enroll,
        Unenroll,
        LandingPage
    };
};
