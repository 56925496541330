import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function SupportFill(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg id={id} className={svgClass} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472ZM12.1552 6.75585C11.7437 6.7274 11.3323 6.81261 10.966 7.00218C10.5996 7.19174 10.2924 7.47836 10.078 7.83071C9.86354 8.18306 9.75008 8.58758 9.75 9.00006C9.74992 9.41427 9.41407 9.75 8.99986 9.74992C8.58564 9.74984 8.24992 9.41399 8.25 8.99977C8.25013 8.31231 8.43924 7.63811 8.79665 7.05086C9.15407 6.46361 9.66603 5.98591 10.2766 5.66997C10.8872 5.35403 11.5728 5.212 12.2587 5.25942C12.9445 5.30684 13.6041 5.54187 14.1654 5.93883C14.7266 6.3358 15.168 6.87941 15.4412 7.51026C15.7144 8.14111 15.8089 8.83492 15.7144 9.51586C15.6199 10.1968 15.3401 10.8387 14.9055 11.3713C14.4708 11.904 13.8981 12.3069 13.25 12.536C13.1037 12.5877 12.9771 12.6836 12.8875 12.8103C12.798 12.937 12.7499 13.0884 12.75 13.2435V14.2499C12.75 14.6641 12.4142 14.9999 12 14.9999C11.5858 14.9999 11.25 14.6641 11.25 14.2499V13.2443C11.25 13.2442 11.25 13.2444 11.25 13.2443C11.2498 12.779 11.394 12.3246 11.6625 11.9446C11.9312 11.5644 12.3111 11.277 12.75 11.1218C13.1389 10.9843 13.4825 10.7426 13.7433 10.423C14.0041 10.1034 14.172 9.71828 14.2286 9.30971C14.2853 8.90115 14.2286 8.48486 14.0647 8.10635C13.9008 7.72784 13.636 7.40167 13.2992 7.1635C12.9624 6.92532 12.5667 6.7843 12.1552 6.75585ZM11.375 16.6896C11.56 16.566 11.7775 16.5 12 16.5C12.2984 16.5 12.5845 16.6185 12.7955 16.8295C13.0065 17.0405 13.125 17.3266 13.125 17.625C13.125 17.8475 13.059 18.065 12.9354 18.25C12.8118 18.435 12.6361 18.5792 12.4305 18.6644C12.2249 18.7495 11.9987 18.7718 11.7805 18.7284C11.5623 18.685 11.3618 18.5778 11.2045 18.4205C11.0472 18.2632 10.94 18.0627 10.8966 17.8445C10.8532 17.6262 10.8755 17.4 10.9606 17.1945C11.0458 16.9889 11.19 16.8132 11.375 16.6896Z"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
