import { ReactElement } from "react";
import { AppSettings } from "../../../../api/backend";
import { MFAOptions } from "../../../../api/identityServer";
import { IsNullOrEmpty } from "../../../../utility";
import { RadioPro } from "../../../generic";
import { DeliveryMethod, MfaSelection, MfaValues } from "../loginDeliveryMfaViewModel";
import "./mfaRadioOption.scss";

type MfaRadioOptionProps = {
    id: string;
    type: "text" | "call" | "email";
    mfaOptions: MFAOptions | undefined;
    setSelection: React.Dispatch<React.SetStateAction<MfaSelection>>;
    selection: MfaSelection;
    appSettings: AppSettings;
};

export function MfaRadioOption(props: MfaRadioOptionProps): ReactElement {
    if (props.mfaOptions !== undefined) {
        const optionValues = getOptions(props.mfaOptions, props.type);
        const isOptionEnabled = getIsEnabled(props.type, props.appSettings);
        const disabledMessage = getDisabledMessage(props.type);

        if (optionValues.length === 1) {
            return (
                <label
                    id={props.id}
                    className={
                        props.selection.type === optionValues[0].deliveryMethod
                            ? "mfaRadioOption mfaRadioOption-selected"
                            : "mfaRadioOption"
                    }
                >
                    <div className="mfaRadioOption-radio">
                        <RadioPro
                            name="mfa"
                            id={`mfa-${props.type}`}
                            className="MfaRadioOption-input"
                            value={optionValues[0].deliveryMethod}
                            disabled={!isOptionEnabled}
                            onChange={(e: React.SyntheticEvent) => onChange(e, props.setSelection, optionValues)}
                        />
                        <div className="mfaRadioOption-selector">
                            <label className="mfaRadioOption-header" htmlFor={props.type + "dd"}>
                                {getOptionLabel(props.type)}
                            </label>

                            {isOptionEnabled ? (
                                <div
                                    className="mfaRadioOption-select"
                                    id={props.type + "dd"}
                                    onClick={(e: React.SyntheticEvent) => onChange(e, props.setSelection, optionValues)}
                                >
                                    {getMask(props.type, optionValues[0].value)}
                                </div>
                            ) : (
                                <p>{disabledMessage}</p>
                            )}
                        </div>
                    </div>
                </label>
            );
        }

        if (optionValues.length > 0) {
            return (
                <label
                    id={props.id}
                    className={
                        props.selection.type === props.type
                            ? "mfaRadioOption mfaRadioOption-selected"
                            : "mfaRadioOption"
                    }
                >
                    <div className="mfaRadioOption-radio">
                        <RadioPro
                            name="mfa"
                            id={`mfa-${props.type}`}
                            className="MfaRadioOption-input"
                            value={props.type}
                            disabled={!isOptionEnabled}
                            onChange={(e: React.SyntheticEvent) => onChange(e, props.setSelection, optionValues)}
                        />
                        <div className="mfaRadioOption-selector">
                            <label className="mfaRadioOption-header" htmlFor={props.type + "dd"}>
                                {getOptionLabel(props.type)}
                            </label>

                            {isOptionEnabled ? (
                                <select
                                    className="mfaRadioOption-select"
                                    id={props.type + "dd"}
                                    onChange={(e: React.SyntheticEvent) =>
                                        onChange(e, props.setSelection, optionValues)
                                    }
                                >
                                    {optionValues.map((ov) => (
                                        <option key={ov.deliveryMethod} value={ov.deliveryMethod}>
                                            {getMask(props.type, ov.value)}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <p>{disabledMessage}</p>
                            )}
                        </div>
                    </div>
                </label>
            );
        }
    }

    return <></>;
}

const getOptions = (mfaOptions: MFAOptions, type: "text" | "call" | "email"): MfaValues[] => {
    const optionValues: MfaValues[] = [];
    var call = type === "call";
    if (["text", "call"].includes(type)) {
        if (!IsNullOrEmpty(mfaOptions.cellPhoneNumber))
            optionValues.push({
                value: mfaOptions.cellPhoneNumber!,
                deliveryMethod: call ? "CallCellPhone" : "TextCellPhone"
            });
        if (!IsNullOrEmpty(mfaOptions.otherPhoneNumber))
            optionValues.push({
                value: mfaOptions.otherPhoneNumber!,
                deliveryMethod: call ? "CallOtherPhone" : "TextOtherPhone"
            });
        if (!IsNullOrEmpty(mfaOptions.homePhoneNumber))
            optionValues.push({
                value: mfaOptions.homePhoneNumber!,
                deliveryMethod: call ? "CallHomePhone" : "TextHomePhone"
            });
        if (!IsNullOrEmpty(mfaOptions.workPhoneNumber))
            optionValues.push({
                value: mfaOptions.workPhoneNumber!,
                deliveryMethod: call ? "CallWorkPhone" : "TextWorkPhone"
            });
    } else {
        if (!IsNullOrEmpty(mfaOptions.emailAddress))
            optionValues.push({
                value: mfaOptions.emailAddress!,
                deliveryMethod: "Email"
            });
    }

    return optionValues;
};

const getIsEnabled = (type: "text" | "call" | "email", appSettings: AppSettings): boolean => {
    switch (type) {
        case "text":
            return appSettings?.mfaSettings?.EnableMfaForText ?? true;
        case "call":
            return appSettings?.mfaSettings?.EnableMfaForPhone ?? true;
        case "email":
            return appSettings?.mfaSettings?.EnableMfaForEmail ?? true;
    }
};

const getDisabledMessage = (type: "text" | "call" | "email"): string => {
    switch (type) {
        case "text":
            return "Text not available";
        case "call":
            return "Phone not available";
        case "email":
            return "Email not available";
    }
};

const getOptionLabel = (type: "text" | "call" | "email"): string => {
    switch (type) {
        case "text":
            return "Text Message";
        case "call":
            return "Phone call";
        case "email":
            return "Email";
    }
};

const getMask = (type: "text" | "call" | "email", unmasked: string): string => {
    if (type === "email") {
        var char = unmasked.substring(0, 1);
        var atIndex = unmasked.indexOf("@");
        var endIndex = atIndex < 8 ? atIndex : atIndex - 1;
        var xLength = 6; // Always display 6 dots
        return `${char}${Array(xLength).fill("•").join("")}${unmasked.substring(endIndex)}`;
    } else {
        return `(•••) •••-${unmasked.substring(unmasked.length - 4)}`;
    }
};

const onChange = (
    e: any,
    setSelection: React.Dispatch<React.SetStateAction<MfaSelection>>,
    optionValue: MfaValues[]
): void => {
    let text = "";
    let value = "" as DeliveryMethod;
    if (optionValue.length === 1) {
        var radio = e.target.parentElement.parentElement.getElementsByTagName("input")[0];
        radio.checked = true;
        value = radio.value;
        text = radio.value;
    } else {
        if (e.target instanceof HTMLInputElement) {
            text = e.target.value;
            value = e.target.parentElement.parentElement.getElementsByTagName("select")[0].value;
        }
        if (e.target instanceof HTMLSelectElement) {
            value = e.target.value;
            var radio = e.target.parentElement.parentElement.getElementsByTagName("input")[0];
            radio.checked = true;
            text = radio.value;
        }
    }
    setSelection({ type: text, value: value });
};
