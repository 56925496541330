import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function HelpArticle(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.25 17.25C11.25 18.8413 11.8821 20.3674 13.0074 21.4926C14.1326 22.6179 15.6587 23.25 17.25 23.25C18.8413 23.25 20.3674 22.6179 21.4926 21.4926C22.6179 20.3674 23.25 18.8413 23.25 17.25C23.25 15.6587 22.6179 14.1326 21.4926 13.0074C20.3674 11.8821 18.8413 11.25 17.25 11.25C15.6587 11.25 14.1326 11.8821 13.0074 13.0074C11.8821 14.1326 11.25 15.6587 11.25 17.25Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.375 16.125C15.375 15.7542 15.485 15.3916 15.691 15.0833C15.897 14.775 16.1899 14.5346 16.5325 14.3927C16.8751 14.2508 17.2521 14.2137 17.6158 14.286C17.9795 14.3584 18.3136 14.537 18.5758 14.7992C18.838 15.0614 19.0166 15.3955 19.089 15.7592C19.1613 16.1229 19.1242 16.4999 18.9823 16.8425C18.8404 17.1851 18.6 17.478 18.2917 17.684C17.9834 17.89 17.6208 18 17.25 18"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.25 20.25C17.3242 20.25 17.3967 20.272 17.4583 20.3132C17.52 20.3544 17.5681 20.413 17.5965 20.4815C17.6248 20.55 17.6323 20.6254 17.6178 20.6982C17.6033 20.7709 17.5676 20.8377 17.5152 20.8902C17.4627 20.9426 17.3959 20.9783 17.3232 20.9928C17.2504 21.0073 17.175 20.9998 17.1065 20.9715C17.038 20.9431 16.9794 20.895 16.9382 20.8333C16.897 20.7717 16.875 20.6992 16.875 20.625C16.875 20.5255 16.9145 20.4302 16.9848 20.3598C17.0552 20.2895 17.1505 20.25 17.25 20.25Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M3.75 6.75H14.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.75 11.25H9.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.75 15.75H7.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M7.5 20.25H2.25C1.85218 20.25 1.47064 20.092 1.18934 19.8107C0.908035 19.5294 0.75 19.1478 0.75 18.75V2.25C0.75 1.85218 0.908035 1.47064 1.18934 1.18934C1.47064 0.908035 1.85218 0.75 2.25 0.75H12.879C13.2765 0.750085 13.6578 0.907982 13.939 1.189L16.811 4.061C17.092 4.3422 17.2499 4.72345 17.25 5.121V7.5"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
