import { ReactElement } from "react";
import { Icon, getColor } from "./icon";
import { IconProps } from "./iconProps";

export function Error(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const fill = getColor("crit");

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="11" cy="15.0601" r="0.75" fill={fill} />
            <path
                d="M11 14.75C10.9382 14.75 10.8778 14.7683 10.8264 14.8027C10.775 14.837 10.7349 14.8858 10.7113 14.9429C10.6876 15 10.6814 15.0628 10.6935 15.1235C10.7056 15.1841 10.7353 15.2398 10.779 15.2835C10.8227 15.3272 10.8784 15.3569 10.939 15.369C10.9997 15.3811 11.0625 15.3749 11.1196 15.3512C11.1767 15.3276 11.2255 15.2875 11.2598 15.2361C11.2942 15.1847 11.3125 15.1243 11.3125 15.0625C11.3125 14.9796 11.2796 14.9001 11.221 14.8415C11.1624 14.7829 11.0829 14.75 11 14.75Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M11 12.25V5.375" strokeWidth="1.5" strokeLinecap="round" />
            <path
                d="M1.625 11C1.625 13.4864 2.61272 15.871 4.37087 17.6291C6.12903 19.3873 8.5136 20.375 11 20.375C13.4864 20.375 15.871 19.3873 17.6291 17.6291C19.3873 15.871 20.375 13.4864 20.375 11C20.375 8.5136 19.3873 6.12903 17.6291 4.37087C15.871 2.61272 13.4864 1.625 11 1.625C8.5136 1.625 6.12903 2.61272 4.37087 4.37087C2.61272 6.12903 1.625 8.5136 1.625 11V11Z"
                strokeWidth="1.5"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
