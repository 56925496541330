import { ReactElement } from "react";
import { DisclosuresViewPrintPreview } from "./disclosuresViewPrintPreview";
import { usePCPDisclosuresViewModelTab } from "./pcpDisclosuresViewModelTab";

export function PCPDisclosuresModelTab(): ReactElement {
    const { state } = usePCPDisclosuresViewModelTab();

    function onPrint() {
        document.getElementById("disclosure")!.style.height = "100%";

        window.print();

        document.getElementById("disclosure")!.style.height = "100vh";
    }

    return <DisclosuresViewPrintPreview {...state} onPrint={onPrint} />;
}
