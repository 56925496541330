import { useContext, useEffect } from "react";
import { CardNavContext } from "../contexts";

export const useCardNav = () => {
    const { setShowNav } = useContext(CardNavContext);

    useEffect(() => {
        setShowNav(true);

        return () => {
            setShowNav(false);
        };
    }, []);
};
