import dayjs from "dayjs";
import { ReactElement } from "react";
import { ButtonPro } from "../../../generic";
import { CardApplicationProps } from "../../cardApplication/cardApplication";
import "../../cardSummary/cardSummaryCommon.scss";
import "./cardAppStatus.scss";
import { CardAppStatusState } from "./cardAppStatusViewModel";

type CardAppStatusViewProps = CardApplicationProps &
    CardAppStatusState & {
        onUpload: Function;
    };

export function CardAppStatusView(props: CardAppStatusViewProps): ReactElement {
    return (
        <div
            className={`cardAppStatus${props.size} ${props.account.applicationFee <= 0 ? "nonPfSd" : ""} ${props.account.applicationStatus}`}
        >
            <div className="cardAppStatus-content cardSummaryCommon cardTilePadding">
                <div className="cardAppStatus-received">
                    <h5>Application Received:&nbsp;</h5>
                    <p>{dayjs(props.account.appEnterDate).format("MM/DD/YYYY")}</p>
                </div>

                <p>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: props.statusText
                        }}
                    />
                </p>

                {props.showButton ? (
                    <ButtonPro variant="tertiary" content={"Upload Verification"} onClick={() => props.onUpload()} />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}
