import "./typographyTest.scss";

export function TypographyTest(): React.ReactElement {
    return (
        <div id="typographyTest">
            <div className="typographyTest-sizes">
                <div className="typographyTest-row">
                    <label className="typographyTest-map">h3 = h1:</label>
                    <h1>PREMIER Bankcard</h1>
                </div>
                <div className="typographyTest-row">
                    <label className="typographyTest-map">h4 = h2:</label>
                    <h2>PREMIER Bankcard</h2>
                </div>
                <div className="typographyTest-row">
                    <label className="typographyTest-map">h5 = h3:</label>
                    <h3>PREMIER Bankcard</h3>
                </div>
                <div className="typographyTest-row">
                    <label className="typographyTest-map">subtitle 1 = h4:</label>
                    <h4>PREMIER Bankcard</h4>
                </div>
                <div className="typographyTest-row">
                    <label className="typographyTest-map">subtitle 2 = h5:</label>
                    <h5>PREMIER Bankcard</h5>
                </div>
                <div className="typographyTest-row">
                    <label className="typographyTest-map">caption = h6:</label>
                    <h6>PREMIER Bankcard</h6>
                </div>
                <div className="typographyTest-row">
                    <label className="typographyTest-map">caption 500 = h6:</label>
                    <h6 className="fw-semibold">PREMIER Bankcard</h6>
                </div>
                <div className="typographyTest-row">
                    <label className="typographyTest-map">caption 700 = h6:</label>
                    <h6 className="fw-bold">PREMIER Bankcard</h6>
                </div>
                <div className="typographyTest-row">
                    <label className="typographyTest-map">error = h6 error:</label>
                    <h6 className="validation-error">PREMIER Bankcard</h6>
                </div>
                <div className="typographyTest-row">
                    <label className="typographyTest-map">p = p:</label>
                    <p>PREMIER Bankcard</p>
                </div>
                <div className="typographyTest-row">
                    <label className="typographyTest-map">label = label:</label>
                    <label>PREMIER Bankcard</label>
                </div>
            </div>
            <div className="typographyTest-colors">
                <div className="typographyTest-row">
                    <label className="typographyTest-map">text-primary</label>
                    <h3 id="typographyTest-textPrimary">PREMIER Bankcard</h3>
                </div>
                <div className="typographyTest-row">
                    <label className="typographyTest-map">secondary-drk</label>
                    <h3 id="typographyTest-secondary-drk">PREMIER Bankcard</h3>
                </div>
                <div className="typographyTest-row">
                    <label className="typographyTest-map">grayScale-drk</label>
                    <h3 id="typographyTest-grayScale-drk">PREMIER Bankcard</h3>
                </div>
                <div className="typographyTest-row">
                    <label className="typographyTest-map">error</label>
                    <h3 id="typographyTest-error">PREMIER Bankcard</h3>
                </div>
            </div>
        </div>
    );
}
