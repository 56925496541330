import { ReactElement } from "react";
import "./error503.scss";
import { Error503State } from "./error503ViewModel";

export function Error503View(props: Error503State): ReactElement {
    return (
        <div className="error503">
            <div className={`error503-page${props.size}`}>
                <h1 className="error503-header">PREMIER Online is Unavailable</h1>
                <p className="error503-body">
                    We are performing routine maintenance for 2-3 hours. Please check back later.
                </p>
            </div>
        </div>
    );
}
