import { ReactElement, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { IsNullOrEmpty } from "../../utility";
import { useAuthenticatedViewModel } from "./authenticatedViewModel";

interface AuthenticatedProps {
    children: ReactElement;
}

export function RequiredAuth(props: AuthenticatedProps) {
    const location = useLocation();
    const { state } = useAuthenticatedViewModel();

    useEffect(() => {
        if (state.loggedIn && state.deepLink === undefined) {
            state.setDeepLink(location.pathname);
        }
    }, []);

    return state.loggedIn ? props.children : <Navigate to="/auth/login" replace state={{ path: location.pathname }} />;
}

export function Authorized(props: AuthenticatedProps) {
    const { state } = useAuthenticatedViewModel();

    return !state.loggedIn ? (
        props.children
    ) : (
        <Navigate
            to={IsNullOrEmpty(state.deepLink) || state.deepLink === "/" ? "/account/dashboard" : state.deepLink}
            replace
        />
    );
}
