import { SecurityType } from "../../../contexts/mfaContext";

export type ForgotLoginData = {
    securityType: SecurityType;
};

export async function ForgotUsernameLoader(): Promise<ForgotLoginData> {
    let data = { securityType: "ForgotUsername" } as ForgotLoginData;

    return data;
}

export async function ForgotPasswordLoader(): Promise<ForgotLoginData> {
    let data = { securityType: "ForgotPassword" } as ForgotLoginData;

    return data;
}

export async function EnrollmentLoader(): Promise<ForgotLoginData> {
    let data = { securityType: "Enrollment" } as ForgotLoginData;

    return data;
}
