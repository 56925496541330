import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { UserAccount, useAccountContext, useDigitalActionsContext } from "../../../api/backend";
import { useCardNav, useIndexedRoute, useViewport } from "../../../hooks";
import { Size } from "../../../types";

type ActivitySummaryViewModel = {
    state: ActivitySummaryState;
};

export type ActivitySummaryState = {
    size: Size;
    account: UserAccount;
    accountIndex: number;
    allowPaperless: boolean;
};

const useActivitySummaryState = (): ActivitySummaryState => {
    const { id } = useParams();
    const { size } = useViewport(false, true);
    const { accounts, accountIndex } = useAccountContext();
    const { actionAllowed } = useDigitalActionsContext();

    const allowPaperless = useMemo(() => {
        if (accounts && accountIndex < accounts.length && Number(id) === accountIndex) {
            let accountHandle = accounts[accountIndex].accountHandle;
            return actionAllowed("eLetterEnroll", accountHandle) || actionAllowed("eStatementEnroll", accountHandle);
        }
        return false;
    }, [accountIndex]);

    const account = accounts[accountIndex];

    return {
        size,
        account,
        accountIndex,
        allowPaperless
    };
};

const useActivitySummaryHooks = () => {
    useCardNav();
    useIndexedRoute("/account/summary/%s/activity");
};

export function useActivitySummaryViewModel(): ActivitySummaryViewModel {
    const state = useActivitySummaryState();
    useActivitySummaryHooks();

    return {
        state
    };
}
