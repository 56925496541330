import { useLayoutEffect, useMemo, useRef, useState } from "react";

type StorageKey = "sessionStorage" | "localStorage";

export function useSessionStorage<T extends string, U = { [K in T]?: any }>(
    dependencies?: T[]
): [U, (name: T, value: any) => void, (names: T[]) => void] {
    const storage = sessionStorage;
    const key = "sessionStorage";
    const set = useRef(setItemFn(storage, key));
    const remove = useRef(removeItemFn(storage, key));

    return useStorage(storage, key, set.current, remove.current, dependencies);
}

export function useLocalStorage<T extends string, U = { [K in T]?: any }>(
    dependencies?: T[]
): [U, (name: T, value: any) => void, (names: T[]) => void] {
    const storage = localStorage;
    const key = "localStorage";
    const set = useRef(setItemFn(storage, key));
    const remove = useRef(removeItemFn(storage, key));

    return useStorage(storage, key, set.current, remove.current, dependencies);
}

function useStorage<T extends string, U = { [K in T]?: any }>(
    browser: Storage,
    key: StorageKey,
    storeValueFn: (name: T, value: any) => void,
    removeValueFn: <T extends string>(names: T[]) => void,
    dependencies?: T[]
): [U, (name: T, value: any) => void, (names: T[]) => void] {
    const [storage, setItems] = useState(getAll(browser));

    useLayoutEffect(() => {
        function onChange() {
            const newItems = getAll(browser);

            if (shouldUpdate(dependencies || null, newItems, storage)) {
                setItems(newItems);
            }
        }

        if (isInBrowser()) {
            window.addEventListener(key, onChange);

            return () => {
                window.removeEventListener(key, onChange);
            };
        }
    }, [storage]);

    const storeValue = useMemo(() => storeValueFn, [storage]);
    const removeValue = useMemo(() => removeValueFn, [storage]);

    return [storage, storeValue, removeValue];
}

const getAll = (browser: Storage): any => {
    const result: { [name: string]: any } = {};

    const keys = Object.keys(browser);
    let i = keys.length;

    while (i--) {
        result[keys[i]] = browser.getItem(keys[i]);
    }

    return result;
};

function isInBrowser() {
    return (
        typeof window !== "undefined" &&
        typeof window.document !== "undefined" &&
        typeof window.document.createElement !== "undefined"
    );
}

function shouldUpdate<U = { [K: string]: any }>(dependencies: Array<keyof U> | null, newItems: U, oldItems: U) {
    if (!dependencies) {
        return true;
    }

    for (let dependency of dependencies) {
        if (newItems[dependency] !== oldItems[dependency]) {
            return true;
        }
    }

    return false;
}

const setItemFn = <T extends string>(browser: Storage, key: StorageKey): ((name: T, value: any) => void) => {
    return (name: T, value: any) => {
        window.dispatchEvent(new Event(key));
        browser.setItem(name, value);
    };
};

const removeItemFn = <T extends string>(browser: Storage, key: StorageKey): ((names: T[]) => void) => {
    return (names: T[]) => {
        window.dispatchEvent(new Event(key));

        names.forEach((n) => {
            browser.removeItem(n);
        });
    };
};
