import { ReactElement, useEffect } from "react";
import ReactDOM from "react-dom";
import { ModalProps } from "../../generic";
import LoadingView from "./loadingView";
import { useLoadingViewModel } from "./loadingViewModel";

export function LoadingModal(props: ModalProps): ReactElement {
    if (props.show) {
        return ReactDOM.createPortal(<LoadingModel />, document.getElementById("App") ?? document.createElement("App"));
    } else {
        return <></>;
    }
}

function LoadingModel(): ReactElement {
    const { state } = useLoadingViewModel();

    useEffect(() => {
        let timer = setTimeout(() => {
            state.setShowDelayMsg(true);
        }, 10_000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return <LoadingView {...state} />;
}
