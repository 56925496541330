import { ReactElement } from "react";
import { getColor } from "./icon";
import { ColorBlockProps, getBrandedColorCode } from "./iconProps";

export function ColorBlocks_tl_l(props: ColorBlockProps): ReactElement {
    const lg = getColor("color", getBrandedColorCode(props.colors?.lg), "#60AADB");
    const md = getColor("color", getBrandedColorCode(props.colors?.md), "#8B257C");
    const sm = getColor("color", getBrandedColorCode(props.colors?.sm), "#42967D");

    return (
        <svg
            id={props.id}
            className={props.className}
            width="352"
            height="352"
            viewBox="0 0 352 352"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="tl_l-svg"
        >
            <rect width="66%" height="66%" fill={lg} data-testid="tl_l-lgRec" />
            <rect x="66%" y="66%" width="34%" height="34%" fill={md} data-testid="tl_l-mdRec" />
            <rect x="66%" y="50%" width="16%" height="16%" fill={sm} data-testid="tl_l-smRec" />
        </svg>
    );
}
