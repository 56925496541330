import { Form, FormikProps } from "formik";
import { ReactElement } from "react";
import { Loading } from "../../..";
import { Alert, ButtonPro } from "../../../generic";
import "./paymentAccountsDelete.scss";
import { PaymentAccountsDeleteProps } from "./paymentAccountsDeleteModel";
import { PaymentAccountsDeleteState } from "./paymentAccountsDeleteViewModel";

type PaymentAccountsDeleteViewProps = PaymentAccountsDeleteProps &
    PaymentAccountsDeleteState &
    FormikProps<{}> & {
        onClose: Function;
    };

export function PaymentAccountsDeleteView(props: PaymentAccountsDeleteViewProps): ReactElement {
    return (
        <Form>
            <div className="paymentaccountdelete">
                <div className="paymentaccountdelete-header">
                    <h4 className="paymentaccountdelete-header-main">Delete Payment Account</h4>
                    <div className="paymentaccountdelete-header-subtext">
                        <h6>Are you sure you want to delete this payment account?</h6>
                    </div>
                    <div className="paymentaccountdelete-header-cms">
                        {/* This needs to become CMS Soon */}
                        <Alert level="info" text={props.alertText} />
                    </div>
                </div>
                <div className="paymentaccountdelete-divider" />
                <div className="paymentaccountdelete-body">
                    <div className="paymentaccountdelete-maintext">
                        <h5 className="paymentaccountdelete-body-title">Payment Account:</h5>
                        <p className="paymentaccountdelete-body-accountdetails">
                            {props.deletedAccount ? (
                                <>
                                    {props.deletedAccount.bankName} {props.deletedAccount.maskedAccountNumber}
                                </>
                            ) : (
                                <></>
                            )}
                        </p>
                    </div>
                    <div className="paymentaccountdelete-body-buttongroup">
                        <ButtonPro
                            variant="tertiary"
                            className="paymentaccountdelete-body-close"
                            onClick={() => props.onClose()}
                        >
                            Close
                        </ButtonPro>
                        <ButtonPro
                            type="submit"
                            variant="primary-neg"
                            className="paymentaccountdelete-body-delete-button"
                        >
                            Delete
                        </ButtonPro>
                    </div>
                </div>
            </div>

            <Loading show={props.isSubmitting} />
        </Form>
    );
}
