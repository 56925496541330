import { ReactElement, useMemo } from "react";
import { DropdownItem } from "react-bootstrap";
import { BankAccountGetResponse, usePaymentAccountContext } from "../../../../../api/backend";
import { useViewport } from "../../../../../hooks";
import { Size } from "../../../../../types";
import { TruncateMid } from "../../../../../utility";
import { Check } from "../../../../generic/icons";
import { SelectPaymentAccountProps } from "./selectPaymentAccount";

export type SelectPaymentAccountState = {
    size: Size;
    selected: string;
    rows: ReactElement[];
};

const useSelectPaymentAccountState = (props: SelectPaymentAccountProps) => {
    const { size } = useViewport(false, true);
    const { paymentAccounts } = usePaymentAccountContext();
    const acct = paymentAccounts.find((p) => p.bankAccountId === props.paymentAccountId);
    const selected =
        props.paymentAccountId === 0 || acct === undefined ? "Select payment account" : `${getBankDisplay(acct)}`;

    const rows = useMemo(() => {
        return paymentAccounts.map((v, i) => (
            <DropdownItem
                key={v.bankAccountId}
                eventKey={i}
                className="selectPaymentAccount-item"
                onClick={() => props.setPaymentAccountId(v.bankAccountId)}
            >
                <p>{getBankDisplay(v)}</p>
                {props.paymentAccountId === v.bankAccountId ? <Check /> : <></>}
            </DropdownItem>
        ));
    }, [paymentAccounts, size]);

    function getBankDisplay(paymentAccount: BankAccountGetResponse): string {
        let display = paymentAccount.bankName + " " + paymentAccount.maskedAccountNumber.replaceAll("*", "•");

        if (size === "-mobile") {
            display = TruncateMid(display, (display.length * 3) / 4);
        }

        return display;
    }

    return {
        size,
        selected,
        rows
    };
};

export const useSelectPaymentAccountViewModel = (props: SelectPaymentAccountProps) => {
    const state = useSelectPaymentAccountState(props);

    return {
        state
    };
};
