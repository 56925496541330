import { ReactElement } from "react";
import { DocumentUploadForm } from "../..";
import { BackArrow } from "../../generic";
import "./documentUpload.scss";
import { DocumentUploadState } from "./documentUploadViewModel";
import DocumentUploadHeader from "./header/documentUploadHeader";
import DocumentUploadUnavailable from "./unavailable/documentUploadUnavailable";

function DocumentUploadView(props: DocumentUploadState): ReactElement {
    return (
        <>
            <div id={`documentUpload${props.size}`}>
                <BackArrow available to={`/account/summary/${props.accountIndex}/home`}>
                    <DocumentUploadHeader {...props} />
                </BackArrow>

                {props.showDocUploadFeature ? (
                    <DocumentUploadForm
                        account={props.accounts![props.accountIndex]}
                        index={props.accountIndex}
                        size={props.size}
                        unavailableMessage={props.unavailableMessage}
                    />
                ) : (
                    <DocumentUploadUnavailable unavailableMessage={props.unavailableMessage} size={props.size} />
                )}
            </div>
        </>
    );
}
export default DocumentUploadView;
