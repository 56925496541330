import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Blocks(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M14 2H32V20H14V2Z" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2 26H20V44H2V26Z" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26 26H44V44H26V26Z" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M23 10.25C23.1989 10.25 23.3897 10.329 23.5303 10.4697C23.671 10.6103 23.75 10.8011 23.75 11"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.25 11C22.25 10.8011 22.329 10.6103 22.4697 10.4697C22.6103 10.329 22.8011 10.25 23 10.25"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23 11.75C22.8011 11.75 22.6103 11.671 22.4697 11.5303C22.329 11.3897 22.25 11.1989 22.25 11"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.75 11C23.75 11.1989 23.671 11.3897 23.5303 11.5303C23.3897 11.671 23.1989 11.75 23 11.75"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11 34.25C11.1989 34.25 11.3897 34.329 11.5303 34.4697C11.671 34.6103 11.75 34.8011 11.75 35"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.25 35C10.25 34.8011 10.329 34.6103 10.4697 34.4697C10.6103 34.329 10.8011 34.25 11 34.25"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11 35.75C10.8011 35.75 10.6103 35.671 10.4697 35.5303C10.329 35.3897 10.25 35.1989 10.25 35"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.75 35C11.75 35.1989 11.671 35.3897 11.5303 35.5303C11.3897 35.671 11.1989 35.75 11 35.75"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M35 34.25C35.1989 34.25 35.3897 34.329 35.5303 34.4697C35.671 34.6103 35.75 34.8011 35.75 35"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.25 35C34.25 34.8011 34.329 34.6103 34.4697 34.4697C34.6103 34.329 34.8011 34.25 35 34.25"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M35 35.75C34.8011 35.75 34.6103 35.671 34.4697 35.5303C34.329 35.3897 34.25 35.1989 34.25 35"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M35.75 35C35.75 35.1989 35.671 35.3897 35.5303 35.5303C35.3897 35.671 35.1989 35.75 35 35.75"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
