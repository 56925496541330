import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";

type CardAppPreviewViewModel = {
    state: CardAppPreviewState;
};

export type CardAppPreviewState = { size: Size };

export function useCardAppPreviewViewModel(): CardAppPreviewViewModel {
    const { size } = useViewport(true);

    return {
        state: { size }
    };
}
