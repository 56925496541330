import { AxiosResponse } from "axios";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
    AppSettings,
    ContextualControlsStatusResponse,
    useAccountContext,
    useCmsContext,
    useContextualMessagingContext,
    useSettingsContext
} from "../../../../api/backend";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { CardSummaryTileProps } from "./cardSummaryTileModel";

type CardSummaryTileViewModel = {
    hooks: CardSummaryTileHooks;
    state: CardSummaryTileState;
    api: CardSummaryTileApi;
};

type CardSummaryTileHooks = {
    lockCardModal: () => Promise<void>;
    navigate: NavigateFunction;
};

export type CardSummaryTileState = {
    size: Size;
    alert: string;
    setAlert: React.Dispatch<React.SetStateAction<string>>;
    showAlert: boolean;
    setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
    alertBody: string;
    showMoreAnnualFeeAlert: boolean;
    setShowMoreAnnualFeeAlert: React.Dispatch<React.SetStateAction<boolean>>;
    lockStatus: boolean;
    setLockStatus: React.Dispatch<React.SetStateAction<boolean>>;
    showLockCardDisabledModal: boolean;
    setShowLockCardDisabledModal: React.Dispatch<React.SetStateAction<boolean>>;
    setShowLockCard: React.Dispatch<React.SetStateAction<boolean>>;
    showLockCard: boolean;
    appSettings: AppSettings;
    setShowUnlockCard: React.Dispatch<React.SetStateAction<boolean>>;
    showUnlockCard: boolean;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    lockLoading: boolean;
    setLockLoading: React.Dispatch<React.SetStateAction<boolean>>;
    accountIndex: number;
    setAccountIndex: React.Dispatch<React.SetStateAction<number>>;
};

export type CardSummaryTileApi = {
    accountSummaryCms: () => Promise<void>;
    getLockStatus: (index: number) => Promise<AxiosResponse<ContextualControlsStatusResponse, any>>;
};

export function useCardSummaryTileViewModel(props: CardSummaryTileProps): CardSummaryTileViewModel {
    const navigate = useNavigate();
    const { size } = useViewport(true, true);
    const { ControlsStatus } = useContextualMessagingContext();
    const { AccountSummary } = useCmsContext();
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState("");
    const [showMoreAnnualFeeAlert, setShowMoreAnnualFeeAlert] = useState(false);
    const [lockStatus, setLockStatus] = useState(false);
    const [lockLoading, setLockLoading] = useState(false);
    const [alertBody, setAlertBody] = useState("");
    const [showLockCardDisabledModal, setShowLockCardDisabledModal] = useState(false);
    const [showLockCard, setShowLockCard] = useState(false);
    const { accounts, accountIndex, setAccountIndex } = useAccountContext();
    const { appSettings } = useSettingsContext();
    const [showUnlockCard, setShowUnlockCard] = useState(false);
    const [loading, setLoading] = useState(false);

    async function accountSummaryCms(): Promise<void> {
        await AccountSummary().then((res) => {
            setAlertBody(
                res.data.AnnualFeeWarningMessageText.replaceAll(
                    "[[ANNUALFEE]]",
                    props?.account?.annualCharge?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD"
                    })
                )
            );
        });
    }

    async function getLockStatus(index: number): Promise<AxiosResponse<ContextualControlsStatusResponse, any>> {
        return await ControlsStatus(accounts![index].accountHandle);
    }

    async function lockCardModal() {
        setLoading(true);
        if (appSettings?.featureFlags?.lockCardEnabled) {
            await getLockStatus(props.index)
                .then((res) => {
                    setLockStatus(res.data.isLocked);
                    if (res.data.isLocked) {
                        setShowUnlockCard(true);
                        setShowLockCard(false);
                    } else {
                        setShowUnlockCard(false);
                        setShowLockCard(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setShowLockCardDisabledModal(true);
                })
                .finally(() => setLoading(false));
        } else {
            setShowLockCardDisabledModal(true);
            setLoading(false);
        }
    }

    return {
        hooks: { lockCardModal, navigate },
        state: {
            size,
            alert,
            setAlert,
            showAlert,
            setShowAlert,
            alertBody,
            showMoreAnnualFeeAlert,
            setShowMoreAnnualFeeAlert,
            lockStatus,
            setLockStatus,
            showLockCard,
            setShowLockCard,
            showLockCardDisabledModal,
            setShowLockCardDisabledModal,
            appSettings,
            showUnlockCard,
            setShowUnlockCard,
            loading,
            setLoading,
            lockLoading,
            setLockLoading,
            accountIndex,
            setAccountIndex
        },
        api: { accountSummaryCms, getLockStatus }
    };
}
