import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import {
    AdditionalCardGetResponse,
    AdditionalCardPostRequest,
    AdditionalCardPostResponse,
    UserAccount,
    useAccountContext,
    useAdditionalCardContext,
    useDigitalActionsContext
} from "../../../../api/backend";
import { CardNavContext } from "../../../../contexts";
import { useActiveElement, useViewport } from "../../../../hooks";
import { FormFocus, Size } from "../../../../types";
import { RequestAuthUserFormProps } from "./requestAuthUserFormModel";
import { RequestAuthUserFormFields } from "./requestAuthUserFormView";

type RequestAuthUserFormViewModel = {
    state: RequestAuthUserFormState;
    api: RequestAuthUserFormApi;
    hooks: RequestAuthUserFormHooks;
};

export type RequestAuthUserFormState = {
    size: Size;
    activeElement: Element | null;
    canSubmit: boolean;
    setCanSubmit: React.Dispatch<React.SetStateAction<boolean>>;
    focused: FormFocus<RequestAuthUserFormFields>;
    setFocused: React.Dispatch<React.SetStateAction<FormFocus<RequestAuthUserFormFields>>>;
    bodyText: string;
    footerText: string;
    last4: string;
    account: UserAccount;
    index: number;
    accountIndex: number;
};

type RequestAuthUserFormApi = {
    additionalCardPost: (request: AdditionalCardPostRequest) => Promise<AxiosResponse<AdditionalCardPostResponse>>;
};
type RequestAuthUserFormHooks = {
    navigate: NavigateFunction;
    actionAllowed: (option: string, accountHandle?: string | undefined) => boolean;
};

export function useRequestAuthUserFormViewModel(props: RequestAuthUserFormProps): RequestAuthUserFormViewModel {
    const { id } = useParams();
    const { accounts, accountIndex } = useAccountContext();
    const { size } = useViewport(false, true);
    const { GetCms, Post: postAC } = useAdditionalCardContext();
    const activeElement = useActiveElement();
    const [canSubmit, setCanSubmit] = useState(false);
    const [focused, setFocused] = useState({} as FormFocus<RequestAuthUserFormFields>);
    const [bodyText, setBodyText] = useState("");
    const [footerText, setFooterText] = useState("");
    const account = accounts![Number(id)];
    const last4 = account?.maskedAccountNumber?.substring(account?.maskedAccountNumber?.length - 4);
    const { setShowNav } = useContext(CardNavContext);
    const { actionAllowed } = useDigitalActionsContext();
    const navigate = useNavigate();

    async function additionalCardPost(
        request: AdditionalCardPostRequest
    ): Promise<AxiosResponse<AdditionalCardPostResponse>> {
        return await postAC(account.accountHandle, request);
    }

    async function additionalCardGet(): Promise<AxiosResponse<AdditionalCardGetResponse, any>> {
        return await GetCms(account.accountHandle);
    }

    useEffect(() => {
        setShowNav(true);
        additionalCardGet().then((res) => {
            const split = SplitCms(res.data);
            setBodyText(split.bodyText);
            setFooterText(split.footerText);
        });
    }, []);

    return {
        state: {
            size,
            activeElement,
            canSubmit,
            setCanSubmit,
            focused,
            setFocused,
            bodyText,
            footerText,
            last4,
            account,
            index: Number(id),
            accountIndex
        },
        api: {
            additionalCardPost
        },
        hooks: {
            navigate,
            actionAllowed
        }
    };
}

//TODO consolidate CMS? (DEV vs. UAT)
export const SplitCms = (cms: AdditionalCardGetResponse): { bodyText: string; footerText: string } => {
    let split = cms.cmsMessage.split("<p>").map((s) => "<p>" + s);

    if (split.length === 1) {
        split = cms.cmsMessage.split("<div>&nbsp;</div>");
        return { bodyText: split[0], footerText: split[2] };
    } else {
        return { bodyText: split[1], footerText: split[3] };
    }
};
