import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function PBCLogoSm(props: IconProps): ReactElement {
    let { id, svgClass, ...iProps } = props;

    const svg = (
        <svg id={id} className={svgClass} width="57" height="35" viewBox="0 0 57 35" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.31799 23.3773C1.64352 20.6864 1.05678 17.8148 1.8957 14.7202C2.62197 12.0411 4.20734 9.89921 6.22477 8.06136C9.04413 5.49209 12.3544 3.77586 15.932 2.55457C22.0515 0.46334 28.3341 -0.0772047 34.7395 0.655908C38.4482 1.0799 42.0325 1.98869 45.4352 3.54951C48.5067 4.9583 51.279 6.78771 53.3654 9.50901C55.5644 12.3739 56.4403 15.5682 55.4787 19.1273C54.6212 22.3013 52.6156 24.6983 50.1005 26.6831C45.5361 30.2811 40.2302 32.0987 34.5697 32.9686L33.1692 33.1713C31.6511 33.8149 29.0083 34.2642 29.0083 34.2642C34.1073 34.2642 38.9778 32.869 38.9778 32.869C43.3354 31.7304 47.4257 30.0108 50.9848 27.1814C53.3519 25.2997 55.2887 23.0716 56.2823 20.1476C56.2823 20.1476 57.0842 18.2979 56.7833 15.2726C56.4773 12.9989 55.4854 11.0377 54.0429 9.2911C51.975 6.7894 49.3457 5.03938 46.4439 3.67789C41.7971 1.49882 36.8662 0.490367 31.7738 0.238676C28.667 0.0849587 25.5669 0.218405 22.4819 0.684625C18.2318 1.32483 14.1465 2.49038 10.3134 4.46506C7.29905 6.01743 4.59737 7.9769 2.52951 10.7202C-0.493277 14.732 -0.822791 19.3875 1.62671 23.4838C2.49924 24.9449 3.83411 26.3132 5.12863 27.4061C5.12863 27.4061 4.05939 25.9956 3.81562 24.0615L3.31799 23.3773Z" />
            <path d="M10.4751 32.94C11.7175 32.4805 12.1462 31.4332 12.5161 30.3065C14.7689 23.4281 17.0334 16.5513 19.3047 9.67967C19.5367 8.97865 19.8057 8.28608 20.1201 7.61884C20.574 6.656 21.4096 6.19823 22.4267 6.05633C22.8638 5.99552 23.311 5.97356 23.7532 5.97356C29.5751 5.96849 35.3954 5.97018 41.2174 5.97187C41.4796 5.97187 41.7436 5.98877 42.0025 6.01917C43.0347 6.14248 43.487 6.84181 43.1625 7.84182C42.29 10.5327 41.4107 13.2219 40.5314 15.9111C40.0927 17.2557 39.6522 18.6003 39.2084 19.9449C38.7931 21.2051 37.7659 21.9635 36.431 21.972C33.8823 21.9889 31.3336 21.9923 28.785 21.999C27.667 22.0024 26.5473 22.0142 25.431 21.9906C25.0863 21.9838 24.9569 22.1037 24.8543 22.4247C24.0036 25.124 23.1126 27.8099 22.2787 30.5126C21.8433 31.9214 20.8178 32.5582 19.493 32.8521C19.145 32.9298 18.7751 32.9332 18.4154 32.9349C15.7692 32.9433 13.123 32.94 10.4751 32.94ZM25.088 21.6747C26.1018 21.7591 27.0063 21.5852 27.8435 21.0446C28.8522 20.3926 29.5499 19.499 29.9299 18.374C30.9688 15.3064 31.9876 12.2321 32.9997 9.15602C33.1577 8.67459 33.267 8.16952 33.3359 7.66783C33.4536 6.81985 33.0249 6.27424 32.223 6.26411C31.2698 6.25228 30.4241 6.55127 29.9164 7.41952C29.6037 7.955 29.3263 8.52594 29.1313 9.11379C28.3243 11.531 27.5526 13.9618 26.7726 16.3875C26.2111 18.1392 25.6563 19.8926 25.088 21.6747Z" />
            <path d="M46.5681 5.93298C49.416 7.67793 51.7781 9.88064 52.9919 13.0665C54.3957 16.7523 53.7367 20.1949 51.5377 23.379C49.6984 26.0429 47.1867 27.9281 44.364 29.4281C41.469 30.9669 38.4126 31.8859 35.2066 32.5024C35.1444 32.5142 34.8535 32.5548 34.8535 32.5548C35.5647 32.2862 36.1195 32.0311 36.6911 31.7727C40.3998 30.1021 43.7537 27.9112 46.5159 24.8841C48.5653 22.6392 50.1524 20.124 50.7542 17.0885C51.62 12.7236 50.2162 9.14753 46.9379 6.24211C46.8169 6.13907 46.6925 6.03771 46.5681 5.93298Z" />
            <path d="M4.39239 17.0447C4.57901 14.6781 5.42296 12.396 6.89401 10.5379C7.48242 9.79462 8.1465 9.10205 8.87109 8.45001C12.0822 5.55472 15.8985 3.81485 20.0191 2.70504C24.1145 1.60199 28.2872 1.2574 32.5154 1.56652C32.7608 1.5851 33.0063 1.59524 33.1694 1.60537C31.742 1.68983 30.2424 1.70335 28.763 1.88071C21.0849 2.80133 14.2206 5.55979 8.62564 11.0733C8.62564 11.0733 6.19968 13.1511 4.33691 17.7541L4.39239 17.0447Z" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
