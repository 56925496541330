import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function ArrowRight(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="10"
            height="18"
            viewBox="0 0 10 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.999999 1L8.6224 8.62311C8.67194 8.67258 8.71124 8.73133 8.73805 8.796C8.76487 8.86067 8.77867 8.92999 8.77867 9C8.77867 9.07001 8.76487 9.13933 8.73805 9.204C8.71124 9.26867 8.67194 9.32742 8.6224 9.37689L1 17"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
