import { ReactElement } from "react";
import { Size } from "../../../../../types";
import { ButtonPro } from "../../../../generic";
import "./paymentButton.scss";

type PaymentButtonViewProps = {
    size: Size;
    makeNewPayment: Function;
};

export function PaymentButtonView(props: PaymentButtonViewProps): ReactElement {
    return (
        <div className={`paymentButton${props.size}`}>
            <ButtonPro
                className={`paymentButton-button${props.size}`}
                variant="primary"
                onClick={() => props.makeNewPayment()}
            >
                Make a Payment
            </ButtonPro>
        </div>
    );
}
