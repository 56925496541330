import { AxiosResponse } from "axios";
import { useState } from "react";
import { useAccountContext, usePaymentContext, UserAccount } from "../../../../api/backend";
import { useExpandableRowContext } from "../../../../contexts";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";

export type ReviewCancelPaymentViewModel = {
    state: ReviewCancelPaymentState;
    api: ReviewCancelPaymentApi;
};

export type ReviewCancelPaymentState = {
    size: Size;
    account: UserAccount;
    error: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    setCurIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
};

type ReviewCancelPaymentApi = {
    paymentDelete: (paymentId: number) => Promise<AxiosResponse<any, any>>;
};

const useReviewCancelPaymentState = (): ReviewCancelPaymentState => {
    const { accounts, accountIndex } = useAccountContext();
    const { size } = useViewport(false, true);
    const [error, setError] = useState("");
    const { setCurIndex } = useExpandableRowContext();
    const account = accounts[accountIndex];

    return {
        size,
        account: account!,
        error,
        setError,
        setCurIndex
    };
};

const useReviewCancelPaymentApi = (): ReviewCancelPaymentApi => {
    const { accounts, accountIndex } = useAccountContext();
    const { Delete } = usePaymentContext();

    async function paymentDelete(paymentId: number): Promise<AxiosResponse<any, any>> {
        return await Delete(accounts![accountIndex].accountHandle, paymentId);
    }

    return {
        paymentDelete
    };
};

export const useReviewCancelPaymentViewModel = (): ReviewCancelPaymentViewModel => {
    const state = useReviewCancelPaymentState();
    const api = useReviewCancelPaymentApi();

    return {
        state,
        api
    };
};
