import { ReactElement } from "react";
import { Children } from "../../types";
import { IdentityConnectContext, useIdentityConnectProps } from "./connectClient/identityConnectContext";

export function Identity(props: Children): ReactElement {
    const identityConnectProps = useIdentityConnectProps();

    return (
        <>
            <IdentityConnectContext.Provider value={identityConnectProps}>
                {props.children}
            </IdentityConnectContext.Provider>
        </>
    );
}
