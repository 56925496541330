import { ReactElement } from "react";
import { useCardAppStatusViewModel } from "./cardAppStatusViewModel";
import { CardAppStatusView } from "./cardAppStatusView";
import { CardApplicationProps } from "../../cardApplication/cardApplication";

export function CardAppStatus(props: CardApplicationProps): ReactElement {
    const { hooks, state } = useCardAppStatusViewModel(props);

    function onUpload() {
        hooks.navigate(`/account/summary/${props.index}/document-upload`, { relative: "path" });
    }

    return <CardAppStatusView {...props} {...state} onUpload={onUpload} />;
}
