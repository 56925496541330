import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";
import "./notification.scss";
export type NavigationProps = IconProps & { notificationNumber?: string };
export function Notification(props: NavigationProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    let svg = (
        <div className="icon-notification-circle" data-testid="notification-icon">
            {props.notificationNumber ? <p className="icon-notification-number">{props.notificationNumber}</p> : <></>}
        </div>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
