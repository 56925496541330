import { ReactElement, useEffect } from "react";
import { LogoutView } from "./logoutView";
import { storageKeys, useLogoutViewModel } from "./logoutViewModel";

export type LogoutProps = {
    timeout: boolean;
};

export function LogoutModel(props: LogoutProps): ReactElement {
    const { hooks, state } = useLogoutViewModel();

    useEffect(() => {
        hooks.clearIdentity("access");
        hooks.clearLink();
        hooks.removeValue(storageKeys);

        if (!props.timeout) {
            var timer = setTimeout(() => {
                window.location.href = state.redirectLogoutUrl;
            }, 2000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, []);

    return <LogoutView {...props} {...state} />;
}
