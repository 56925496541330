import { ReactElement, useEffect, useState } from "react";
import { useBackgroundTick } from "../../../../hooks";
import "./asyncElement.scss";

type AsyncElementProps = {
    loading: boolean;
    className?: string;
    children: any;
};

export function AsyncElement(props: AsyncElementProps): ReactElement {
    return <>{props.loading ? <AsyncElementFallback {...props} /> : props.children}</>;
}

type AsyncElementFallbackProps = {
    className?: string;
};

function AsyncElementFallback(props: AsyncElementFallbackProps): ReactElement {
    return (
        <>
            <div className={props.className ?? "asyncElementFallback"}>
                <h5> </h5>
            </div>
        </>
    );
}
