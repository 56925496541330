import { useContext } from "react";
import { AppSettings, useSettingsContext } from "../../../api/backend";
import {
    AppDownloadBannerContext,
    UnAuthBlocksContext,
    ViewRestrictionContext,
    useMfaContext
} from "../../../contexts";
import { SecurityType } from "../../../contexts/mfaContext";
import { useIsLoggedIn, useViewport } from "../../../hooks";
import { Size } from "../../../types";

type PublicViewModel = {
    state: PublicViewState;
};

export type PublicViewState = {
    showBanner: boolean;
    loggedIn: boolean;
    securityType: SecurityType | undefined;
    restricted: boolean;
    appSettings: AppSettings;
    showBlocks: boolean;
    size: Size;
};

export function usePublicViewModel(): PublicViewModel {
    const { appSettings } = useSettingsContext();
    const { securityType } = useMfaContext();
    const { showBanner } = useContext(AppDownloadBannerContext);
    const { loggedIn } = useIsLoggedIn();
    const { restricted } = useContext(ViewRestrictionContext);
    const { showBlocks } = useContext(UnAuthBlocksContext);
    const { size } = useViewport(true);

    return {
        state: {
            showBanner,
            loggedIn,
            securityType,
            restricted,
            appSettings,
            showBlocks,
            size
        }
    };
}
