import { useState } from "react";

export type AddPaymentAccountState = {
    showReview: boolean;
    setShowReview: React.Dispatch<React.SetStateAction<boolean>>;
    showSuccess: boolean;
    setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    routingNumber: string;
    setRoutingNumber: React.Dispatch<React.SetStateAction<string>>;
    checkingNumber: string;
    setCheckingNumber: React.Dispatch<React.SetStateAction<string>>;
    confirmNumber: string;
    setConfirmNumber: React.Dispatch<React.SetStateAction<string>>;
    bankName: string;
    setBankName: React.Dispatch<React.SetStateAction<string>>;
};

const useAddPaymentAccountState = (): AddPaymentAccountState => {
    const [showReview, setShowReview] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [routingNumber, setRoutingNumber] = useState("");
    const [checkingNumber, setCheckingNumber] = useState("");
    const [confirmNumber, setConfirmNumber] = useState("");
    const [bankName, setBankName] = useState("");

    return {
        showReview,
        setShowReview,
        showSuccess,
        setShowSuccess,
        routingNumber,
        setRoutingNumber,
        checkingNumber,
        setCheckingNumber,
        confirmNumber,
        setConfirmNumber,
        bankName,
        setBankName
    };
};

export function useAddPaymentAccountViewModel() {
    const state = useAddPaymentAccountState();

    return {
        state
    };
}
