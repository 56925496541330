import { createContext, useState } from "react";

export type UnAuthBlocksContextProps = {
    showBlocks: boolean;
    setShowBlocks: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UnAuthBlocksContext = createContext<UnAuthBlocksContextProps>({
    showBlocks: false,
    setShowBlocks: () => {}
});

export function useUnAuthBlocksProps(): UnAuthBlocksContextProps {
    const [showBlocks, setShowBlocks] = useState(false);

    return { showBlocks, setShowBlocks };
}
