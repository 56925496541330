import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Trash(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1.09375 5.48438H23.0938" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M14.3438 1.48438H9.84375C9.44593 1.48438 9.06439 1.64241 8.78309 1.92371C8.50179 2.20502 8.34375 2.58655 8.34375 2.98438V5.48438H15.8438V2.98438C15.8437 2.58655 15.6857 2.20502 15.4044 1.92371C15.1231 1.64241 14.7416 1.48438 14.3438 1.48438Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M9.84375 18.2344V10.7344" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.3438 18.2344V10.7344" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M18.9538 22.1044C18.9259 22.4802 18.7568 22.8315 18.4803 23.0875C18.2038 23.3436 17.8406 23.4854 17.4638 23.4844H6.72375C6.34692 23.4854 5.98369 23.3436 5.70722 23.0875C5.43075 22.8315 5.26157 22.4802 5.23375 22.1044L3.84375 5.48438H20.3438L18.9538 22.1044Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
