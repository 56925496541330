import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function User(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.75 6C5.75 7.39239 6.30312 8.72774 7.28769 9.71231C8.27226 10.6969 9.60761 11.25 11 11.25C12.3924 11.25 13.7277 10.6969 14.7123 9.71231C15.6969 8.72774 16.25 7.39239 16.25 6C16.25 4.60761 15.6969 3.27226 14.7123 2.28769C13.7277 1.30312 12.3924 0.75 11 0.75C9.60761 0.75 8.27226 1.30312 7.28769 2.28769C6.30312 3.27226 5.75 4.60761 5.75 6V6Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.25 23.25C1.25 20.6641 2.27723 18.1842 4.10571 16.3557C5.93419 14.5272 8.41414 13.5 11 13.5C13.5859 13.5 16.0658 14.5272 17.8943 16.3557C19.7228 18.1842 20.75 20.6641 20.75 23.25"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
