import { Form, Formik } from "formik";
import { ReactElement } from "react";
import { CancelEnrollSetup, EnrollTermsSplash, InvalidEnrollSession } from "../..";
import { ButtonPro } from "../../generic";
import { LoginCard } from "../common/loginCardModel";
import "./enrollTerms.scss";
import { EnrollTermsState } from "./enrollTermsViewModel";

type EnrollTermsViewProps = EnrollTermsState & {
    onCancel: Function;
    onSubmit: Function;
};

export type EnrollTermsFields = {
    accept: boolean;
};

export function EnrollTermsView(props: EnrollTermsViewProps): ReactElement {
    return (
        <>
            {!props.showSplash ? (
                <LoginCard id="enrollTerms" size={props.size} header="Agree to Terms of Use" subtitle={props.subtitle}>
                    <Formik
                        initialValues={{ accept: false }}
                        onSubmit={(values, actions) => {
                            props.onSubmit(values, actions);
                        }}
                    >
                        {({ values, isSubmitting }) => (
                            <Form className={`loginCard-form${props.size}`} autoComplete="off">
                                <div className="enrollTerms-border">
                                    <div className="enrollTerms-content">
                                        <span
                                            className="enrollTerms-terms"
                                            dangerouslySetInnerHTML={{
                                                __html: props.cms.Terms
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="loginCard-buttons">
                                    <ButtonPro
                                        id={`enrollTerms-cancel${props.size}`}
                                        type="button"
                                        variant="neutral"
                                        onClick={() => props.onCancel()}
                                    >
                                        Cancel
                                    </ButtonPro>
                                    <ButtonPro
                                        id={`enrollTerms-submit${props.size}`}
                                        type="submit"
                                        onClick={() => (values.accept = true)}
                                        isSubmitting={isSubmitting}
                                    >
                                        Agree & Continue
                                    </ButtonPro>
                                </div>

                                <CancelEnrollSetup show={props.showCancel} setShow={props.setshowCancel} />
                                <InvalidEnrollSession show={props.showError} setShow={props.setShowError} />
                            </Form>
                        )}
                    </Formik>
                </LoginCard>
            ) : (
                <EnrollTermsSplash />
            )}
        </>
    );
}
