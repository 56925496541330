import { NavigateFunction, useNavigate } from "react-router-dom";
import { useSessionStorage, useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { Decrypt } from "../../../utility";

type ShowUsernameViewModel = {
    hooks: ShowUsernameHooks;
    state: ShowUsernameState;
};

export type ShowUsernameHooks = {
    navigate: NavigateFunction;
    storage: { [x: string]: any };
    removeValue: (names: string[]) => void;
};

export type ShowUsernameState = {
    size: Size;
    username: string;
};

const storageKeys = ["username"];

export function useShowUsernameViewModel(): ShowUsernameViewModel {
    const navigate = useNavigate();
    const { size } = useViewport(false, true);
    const [storage, , removeValue] = useSessionStorage(storageKeys);
    const username = Decrypt<string>(storage["username"]);

    return {
        hooks: {
            navigate,
            storage,
            removeValue
        },
        state: {
            size,
            username
        }
    };
}
