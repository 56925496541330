import { useMemo } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useAccountContext, useDeliveryPreferencesContext, useSettingsContext } from "../../../api/backend";

export type EnrollInPaperlessAlertState = {
    loading: boolean;
    showPaperlessAlert: boolean;
};

type EnrollInPaperlessAlertHooks = {
    navigate: NavigateFunction;
};

const useEnrollInPaperlessAlertState = (): EnrollInPaperlessAlertState => {
    const { appSettings } = useSettingsContext();
    const { accounts, accountIndex } = useAccountContext();
    const { loading, preferences } = useDeliveryPreferencesContext();

    const showPaperlessAlert = useMemo(() => {
        let isEMessengerDown = appSettings?.featureFlags?.isEMessengerDown ?? true;
        let accountHandle = accounts[accountIndex].accountHandle;
        let preference = preferences?.find((x) => x.accountHandle === accountHandle)?.eStatementsEnrollment;
        let changesAllowed = preference?.changesAllowed ?? false;
        let isNotEnrolled = preference?.enrollmentStatus === "NotEnrolled";

        return !isEMessengerDown && changesAllowed && isNotEnrolled;
    }, [appSettings, accounts, accountIndex, preferences]);

    return {
        loading,
        showPaperlessAlert
    };
};

const useEnrollInPaperlessAlertHooks = (): EnrollInPaperlessAlertHooks => {
    const navigate = useNavigate();

    return {
        navigate
    };
};

export const useEnrollInPaperlessAlertViewModel = () => {
    const state = useEnrollInPaperlessAlertState();
    const hooks = useEnrollInPaperlessAlertHooks();

    return {
        state,
        hooks
    };
};
