import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function MailRead(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="30"
            viewBox="0 0 24 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 10H20C21.1 10 22 10.9 22 12V24C22 25.1 21.1 26 20 26H4C2.9 26 2 25.1 2 24V12C2 10.9 2.9 10 4 10Z"
                fill="white"
                stroke="#42967D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22 12L12 19L2 12"
                stroke="#42967D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.0174 11.7861L12.6247 18.5002C12.2595 18.7924 11.7405 18.7924 11.3753 18.5002L3.03254 11.826C2.51456 11.4116 2.53622 10.617 3.076 10.2314L11.8887 3.93663C12.2508 3.67797 12.7403 3.68973 13.0896 3.96548L21.0124 10.2203C21.5174 10.619 21.5199 11.3841 21.0174 11.7861Z"
                fill="white"
                stroke="#42967D"
                strokeWidth="1.5"
            />
            <path d="M7 16L4.5 19.5" stroke="#42967D" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M17 16L19.5 19.5" stroke="#42967D" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
