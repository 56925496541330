import { FormikProps } from "formik";
import { ReactElement } from "react";
import { ReviewPaymentAccountProps } from "./reviewPaymentAccountModel";
import ReviewPaymentAccountView from "./reviewPaymentAccountView";
import { ReviewPaymentAccountViewModel } from "./reviewPaymentAccountViewModel";

type ReviewPaymentAccountFormProps = {
    props: ReviewPaymentAccountProps;
    vm: ReviewPaymentAccountViewModel;
    formProps: FormikProps<{}>;
};

export function ReviewPaymentAccountForm(form: ReviewPaymentAccountFormProps): ReactElement {
    const { props, formProps } = { ...form };
    const { state } = { ...form.vm };

    function onCancel() {
        props.setRoutingNumber("");
        props.setCheckingNumber("");
        props.setConfirmNumber("");
        props.setBankName("");
        props.setShow(false);

        if (props.callback !== undefined) {
            props.callback();
        }
    }

    function onEdit() {
        props.setShow(false);
        props.setShowEnter(true);
    }

    return <ReviewPaymentAccountView {...props} {...state} {...formProps} onCancel={onCancel} onEdit={onEdit} />;
}
