import { Form, Formik } from "formik";
import { ReactElement } from "react";
import { Loading, Lockout, LockoutWait } from "../..";
import { FormProps } from "../../../types";
import { Alert, ButtonPro, CheckboxPro, GenericModal, PasswordInput, UsernameInput } from "../../generic";
import { LoginCard } from "../common/loginCardModel";
import "./login.scss";
import { LoginState } from "./loginViewModel";

type LoginViewProps = LoginState & {
    onForgotPassword: Function;
    onForgotUsername: Function;
    onEnroll: Function;
    onSubmit: Function;
};

export type LoginFields = {
    username: string;
    password: string;
    checkbox: boolean;
};

export type LoginErrors = {
    username?: string;
    password?: string;
    invalidLogin?: string;
};

export function LoginView(props: LoginViewProps): ReactElement {
    return (
        <LoginCard id="login" size={props.size} header="Welcome!" subtitle="Enter your credentials">
            <Formik
                initialValues={props.initialValues}
                onSubmit={(values, actions) => {
                    props.onSubmit(values, actions);
                }}
            >
                {(formProps: FormProps<LoginFields>) => (
                    <Form id="login" className={`loginCard-form${props.size}`} autoComplete="off">
                        <UsernameInput label="Username" error={props.loginErrors.username} />
                        <PasswordInput
                            id="password"
                            label="Password"
                            error={props.loginErrors.password}
                            stateValue={{
                                isPrivate: true,
                                canPeek: true
                            }}
                        />
                        <CheckboxPro id="checkbox" name="checkbox" label={"Remember me"} />
                        {formProps.values.checkbox ? (
                            <Alert
                                level={"info"}
                                children={
                                    <div id="login-rememberme-hint">
                                        <div>
                                            By selecting "Remember me", your username will be saved. This is not
                                            recommended if you are on a public or shared device.
                                        </div>
                                    </div>
                                }
                            />
                        ) : null}
                        <ButtonPro id="login-submit" type="submit" isSubmitting={formProps.isSubmitting}>
                            Log In
                        </ButtonPro>
                        <div id={`login-forgot-row${props.size}`}>
                            <ButtonPro
                                className="login-forgotButton"
                                type="button"
                                variant="tertiary"
                                onClick={() => props.onForgotPassword()}
                            >
                                Forgot Your Password?
                            </ButtonPro>
                            {props.size !== "-mobile" ? <div className="login-forgot-divider" /> : null}
                            <ButtonPro
                                className="login-forgotButton"
                                type="button"
                                variant="tertiary"
                                onClick={() => props.onForgotUsername()}
                            >
                                Forgot Your Username?
                            </ButtonPro>
                        </div>
                        <div id="login-setup">
                            <div id="login-newContainer">
                                <div className="login-newDivider" />
                                <h6>New to PREMIER?</h6>
                                <div className="login-newDivider" />
                            </div>
                            <ButtonPro variant="secondary" type="button" onClick={() => props.onEnroll()}>
                                Set Up Online Access
                            </ButtonPro>
                        </div>
                        <Lockout
                            show={props.showModalLockout}
                            setShow={props.setshowModalLockout}
                            setShowInfo={props.setshowModalStayOnPage}
                            cms={props.modalCms}
                        />
                        <LockoutWait
                            show={props.showModalStayOnPage}
                            setShow={props.setshowModalStayOnPage}
                            cms={props.modalCms}
                        />
                        <GenericModal show={props.showErrorModal} {...props.errorConstants} />
                        <Loading show={formProps.isSubmitting} />
                    </Form>
                )}
            </Formik>
        </LoginCard>
    );
}
