export const EmailRegex =
    /(?:[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*)@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:)\])/im;

export function IsEmail(val: string): boolean {
    return EmailRegex.test(val);
}

export function IsSSN(val: string): boolean {
    return (
        /^(?!(000|666|9))(?:\d{3}|[AB]\d{2})?(?!00)\d{2}?(?!0000)\d{4}$/g.test(val) ||
        /^(?!(000|666|9))(?:\d{3}|[AB]\d{2})-?(?!00)\d{2}-?(?!0000)\d{4}$/g.test(val)
    );
}

export function IsLength(val: string, min: number, max: number): boolean {
    return new RegExp(`^.{${min},${max}}$`, "g").test(val);
}

export function HasSpecial(val: string): boolean {
    return /(?=.*[^\da-zA-Z])/g.test(val);
}

export function HasSpace(val: string): boolean {
    return /\s/g.test(val);
}

export function HasUppercase(val: string): boolean {
    return /[A-Z]/g.test(val);
}

export function HasLowercase(val: string): boolean {
    return /[a-z]/g.test(val);
}

export function HasNumbers(val: string): boolean {
    return /[0-9]/g.test(val);
}

export function IsAlphaNumeric(val: string): boolean {
    return /^[A-Za-z0-9]*$/g.test(val);
}

export function IsNumeric(val: string): boolean {
    return /^(0|[1-9]\d*)(\.\d+)?$/g.test(val);
}
