import { FormikHelpers } from "formik";
import { ReactElement, useEffect } from "react";
import { Modal, ModalProps } from "../../../generic";
import { onChangeFn, onOtpFailFn, onStateChangedFn, submitEnrollFn, submitLoginFn } from "../mfaEnterCodeFn";
import { MfaFields } from "../mfaEnterCodeView";
import { useMfaEnterCodeViewModel } from "../mfaEnterCodeViewModel";
import { MfaEnterCodeCSView } from "./mfaEnterCodeCSView";

export type MfaEnterCodeCSProps = {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setShowLast: React.Dispatch<React.SetStateAction<boolean>>;
    setShowTrust: React.Dispatch<React.SetStateAction<boolean>>;
    setShowError: React.Dispatch<React.SetStateAction<boolean>>;
    setShowPinExpired: React.Dispatch<React.SetStateAction<boolean>>;
};

export function MfaEnterCodeCSModal(props: ModalProps & MfaEnterCodeCSProps): ReactElement {
    return (
        <Modal show={props.show}>
            <MfaEnterCodeCSModel {...props} />
        </Modal>
    );
}

function MfaEnterCodeCSModel(props: MfaEnterCodeCSProps): ReactElement {
    const { hooks, state, api } = useMfaEnterCodeViewModel();

    useEffect(() => {
        onStateChangedFn(state);
    }, [state.canContinue, state.codeState]);

    function onChange(value: string) {
        onChangeFn(state, value);
    }

    function onOtpFail() {
        onOtpFailFn(state);
    }

    function onRequestNew() {
        props.setShowModal(false);
        props.setShowLast(true);
    }

    function onCancel() {
        if (state.mfaOptions?.noContactInfo) {
            state.setShowCancel(true);
        } else {
            props.setShowModal(false);
        }
    }

    function onSubmit(values: MfaFields, actions: FormikHelpers<MfaFields>) {
        if (state.securityType !== "Enrollment" && state.securityType !== "OMT") {
            submitLoginFn(hooks, state, api, actions, {
                setShow: props.setShowModal,
                setShowTrust: props.setShowTrust,
                setShowError: props.setShowError,
                setShowPinExpired: props.setShowPinExpired
            });
        } else {
            submitEnrollFn(hooks, state, api, actions);
        }
    }

    return (
        <MfaEnterCodeCSView
            {...state}
            onChange={onChange}
            onOtpFail={onOtpFail}
            onRequestNew={onRequestNew}
            onCancel={onCancel}
            onSubmit={onSubmit}
        />
    );
}
