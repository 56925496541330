import { AxiosResponse } from "axios";
import { CCPAPrivateRequest, CCPAPublicRequest } from ".";
import { UsePost } from "../../../hooks";
import { useIdentityConnectContext } from "../../identityServer";

export const useCcpaContext = () => {
    const client = useCcpaClient();

    return { ...client };
};

const useCcpaClient = () => {
    const { access_token } = useIdentityConnectContext();

    async function Public(request: CCPAPublicRequest): Promise<AxiosResponse<any, any>> {
        return await UsePost(`api/v1/ccpa/public`, undefined, request);
    }

    async function Private(request: CCPAPrivateRequest): Promise<AxiosResponse<any, any>> {
        return await UsePost(`api/v1/ccpa/private`, access_token, request);
    }

    return {
        Public,
        Private
    };
};
