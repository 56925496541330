import { AxiosResponse } from "axios";
import { useState } from "react";
import {
    CommunicationSettingsCmsModel,
    EnrollRequest,
    OnlineActions,
    UserAccount,
    useAccountContext,
    useDeliveryPreferencesContext,
    useDigitalActionsContext,
    useSettingsContext
} from "../../../../api/backend";

type DeliveryPreferencesTermsViewModel = {
    hooks: DeliveryPreferencesTermsHooks;
    state: DeliveryPreferencesTermsState;
    api: DeliveryPreferencesTermsApi;
};

type DeliveryPreferencesTermsHooks = {
    actionAllowed: (option: string, accountHandle?: string) => boolean;
};

export type DeliveryPreferencesTermsState = {
    accounts: UserAccount[] | undefined;
    cms: CommunicationSettingsCmsModel;
    canSave: boolean;
    setCanSave: React.Dispatch<React.SetStateAction<boolean>>;
    digitalActions: OnlineActions[] | undefined;
    EStatementDisclosure: string;
};

type DeliveryPreferencesTermsApi = {
    Enroll: (request: EnrollRequest) => Promise<AxiosResponse<any, any>>;
};

export function useDeliveryPreferencesTermsViewModel(): DeliveryPreferencesTermsViewModel {
    const { accounts } = useAccountContext();
    const { Enroll, preferencesCms: cms } = useDeliveryPreferencesContext();
    const { digitalActions, actionAllowed } = useDigitalActionsContext();
    const { appSettings } = useSettingsContext();
    const [canSave, setCanSave] = useState(false);
    const EStatementDisclosure: string = appSettings?.pdfs?.esign_consent_disclosure;

    return {
        hooks: { actionAllowed },
        state: { accounts, cms, canSave, setCanSave, EStatementDisclosure, digitalActions },
        api: { Enroll }
    };
}
