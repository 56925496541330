import { AxiosResponse } from "axios";
import { useState } from "react";
import { PCPEnrollmentRequest, UserAccount, useAccountContext, usePCPContext } from "../../../../../api/backend";

export type PCPEnrollmentModalViewModel = {
    state: PCPEnrollmentModalState;
    api: PCPEnrollmentModalAPI;
};

export type PCPEnrollmentModalState = {
    setConfirmEnrollmentCheck: React.Dispatch<React.SetStateAction<boolean>>;
    confirmEnrollmentCheck: boolean;
    setCanContinue: React.Dispatch<React.SetStateAction<boolean>>;
    accounts: UserAccount[] | undefined;
    accountIndex: number;
    canContinue: boolean;
    initialError: string;
    setInitialError: React.Dispatch<React.SetStateAction<string>>;
    errorCount: number;
    setErrorCount: React.Dispatch<React.SetStateAction<number>>;
    initialsSubmitted: string;
    setInitialsSubmitted: React.Dispatch<React.SetStateAction<string>>;
};

export type PCPEnrollmentModalAPI = {
    getPCPEnrollmentStatus: (application: PCPEnrollmentRequest) => Promise<AxiosResponse<any, any>>;
};

export function usePCPEnrollmentModalViewModel(): PCPEnrollmentModalViewModel {
    const [confirmEnrollmentCheck, setConfirmEnrollmentCheck] = useState(false);
    const { Post } = usePCPContext();
    const { accounts, accountIndex } = useAccountContext();
    const [initialError, setInitialError] = useState("");
    const [initialsSubmitted, setInitialsSubmitted] = useState("");
    const [errorCount, setErrorCount] = useState(0);
    const [canContinue, setCanContinue] = useState(false);
    function getPCPEnrollmentStatus(application: PCPEnrollmentRequest): Promise<AxiosResponse<any, any>> {
        return Post(application);
    }
    return {
        state: {
            confirmEnrollmentCheck,
            setConfirmEnrollmentCheck,
            canContinue,
            setCanContinue,
            accounts,
            accountIndex,
            initialError,
            setInitialError,
            errorCount,
            setErrorCount,
            initialsSubmitted,
            setInitialsSubmitted
        },
        api: { getPCPEnrollmentStatus }
    };
}
