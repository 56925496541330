export type IconProps = {
    id?: string;
    svgClass?: string;

    className?: string;
    caption?: string;
    onClick?: () => any;
    clickRestricted?: boolean;

    title?: string;
};

export type ColorBlockProps = {
    id?: string;
    className?: string;
    colors?: BlockColors;
};

type BlockColors = {
    lg?: BrandedColor;
    md?: BrandedColor;
    sm?: BrandedColor;
};

type BrandedColor = "Orange" | "Gold" | "Purple" | "Blue" | "Green";

export function getBrandedColorCode(color?: BrandedColor): string | undefined {
    if (color) {
        switch (color) {
            case "Orange":
                return "#f37232";
            case "Gold":
                return "#e4cb34";
            case "Purple":
                return "#8b257c";
            case "Blue":
                return "#4f97c6";
            case "Green":
                return "#42967d";
        }
    }

    return undefined;
}
