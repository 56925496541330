import dayjs from "dayjs";
import { ReactElement, useEffect } from "react";
import { DocumentsRow } from "./documentsRow/documentsRow";
import DocumentsView from "./documentsView";
import { useDocumentsViewModel } from "./documentsViewModel";

export function DocumentsModel(): ReactElement {
    const { state, api } = useDocumentsViewModel();

    useEffect(() => {
        api.documentsGet().then((res) => {
            state.setHistory(res.data.sort((a, b) => dayjs(b.documentDate).diff(a.documentDate)));
        });
    }, [state.rowViewed, state.accountIndex]);

    useEffect(() => {
        state.setDisplayedHistory(state.seeMore ? state.history : state.history.slice(0, 3));
    }, [state.history, state.seeMore]);

    useEffect(() => {
        state.setRows(createRows());
    }, [state.displayedHistory]);

    function createRows(): ReactElement[] {
        let newRows = [] as ReactElement[];

        for (let i = 0; i < state.displayedHistory.length; i++) {
            newRows.push(
                <DocumentsRow
                    key={`state.displayedHistory[i].documentId.toString()${i}`}
                    index={i}
                    document={state.displayedHistory[i]}
                    rowViewed={state.rowViewed}
                    setRowViewed={state.setRowViewed}
                />
            );
        }

        return newRows;
    }

    return <DocumentsView {...state} />;
}
