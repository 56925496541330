import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Search(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            width="24"
            height="24"
            className={svgClass}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.47208 13.3571C2.41223 15.5693 4.19265 17.3174 6.42168 18.2168C8.6507 19.1163 11.1457 19.0934 13.3579 18.1533C15.5701 17.2131 17.3182 15.4327 18.2176 13.2037C19.1171 10.9746 19.0942 8.4796 18.1541 6.26743C17.2139 4.05526 15.4335 2.30717 13.2045 1.40772C10.9754 0.508266 8.4804 0.531128 6.26824 1.47128C4.05607 2.41143 2.30798 4.19185 1.40852 6.42087C0.50907 8.6499 0.531933 11.1449 1.47208 13.3571V13.3571Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M16.2212 16.2197L23.2502 23.2497" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
