import { ReactElement } from "react";
import "./radioPro.scss";

type RadioProProps = any & {
    id: string;
    name: string;
    className?: string;
    value: string;
    disabled?: boolean;
    checked?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

export function RadioPro(props: RadioProProps): ReactElement {
    return (
        <div className="radio-wrapper">
            <input type="radio" {...props} />
        </div>
    );
}
