import { ReactElement } from "react";
import { ArrowRight } from "../../../generic/icons";
import { IndexedApplication } from "../../../generic/visuals/indexedApplication/indexedApplication";
import { CardAppStatus } from "../../cardTiles";
import "../cardApplication.scss";
import { CardApplicationViewProps } from "../cardApplicationView";

export function CardApplicationAltView(props: CardApplicationViewProps): ReactElement {
    return (
        <div
            className="cardApplicationAlt"
            data-testid="viewAppDiv"
            onClick={() => {
                props.viewApplication();
            }}
        >
            <div className="cardApplicationAlt-header">
                <IndexedApplication icon account={props.account} />
                <ArrowRight className={`cardApplicationAlt-icon`} />
            </div>
            <div className={`cardApplication-tiles${props.size}`}>
                <CardAppStatus {...props} />
            </div>
        </div>
    );
}
