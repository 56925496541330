import { ReactElement } from "react";
import { IndexedCreditCard } from "../../..";
import { ButtonPro } from "../../../generic";
import "./dashboardCardHeader.scss";
import { DashboardCardHeaderProps } from "./dashboardCardHeaderModel";
import { DashboardCardHeaderState } from "./dashboardCardHeaderViewModel";

export type DashboardCardHeaderViewProps = DashboardCardHeaderProps &
    DashboardCardHeaderState & {
        viewAccount: Function;
    };

export function DashboardCardHeaderView(props: DashboardCardHeaderViewProps): ReactElement {
    return (
        <div id="dashboardCardHeader">
            <IndexedCreditCard size="h4" account={props.account} icon />
            <div id="dashboardCardHeader-content">
                <ButtonPro
                    id="dashboardCardHeader-actionsButton"
                    variant="secondary"
                    onClick={() => props.viewAccount()}
                >
                    View Account
                </ButtonPro>
            </div>
        </div>
    );
}
