import { ReactElement } from "react";
import { getColor } from "./icon";
import { ColorBlockProps, getBrandedColorCode } from "./iconProps";

export function ColorBlocks_br_l(props: ColorBlockProps): ReactElement {
    const lg = getColor("color", getBrandedColorCode(props.colors?.lg), "#60AADB");
    const md = getColor("color", getBrandedColorCode(props.colors?.md), "#8B257C");
    const sm = getColor("color", getBrandedColorCode(props.colors?.sm), "#F37232");

    return (
        <svg
            id={props.id}
            className={props.className}
            width="196"
            height="233"
            viewBox="0 0 196 233"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="br_l-svg"
        >
            <rect
                x="49.0986"
                y="233.445"
                width="143.988"
                height="146.927"
                transform="rotate(-90 49.0986 233.445)"
                fill={lg}
                data-testid="br_l-lgRec"
            />
            <rect
                x="104.927"
                y="89.2539"
                width="89.2558"
                height="91.0774"
                transform="rotate(-90 104.927 89.2539)"
                fill={md}
                data-testid="br_l-mdRec"
            />
            <rect
                y="233.223"
                width="48.2216"
                height="49.2057"
                transform="rotate(-90 0 233.223)"
                fill={sm}
                data-testid="br_l-smRec"
            />
        </svg>
    );
}
