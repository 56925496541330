import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function ArrowUp(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.00293 9L8.62604 1.3776C8.67551 1.32806 8.73426 1.28876 8.79893 1.26195C8.8636 1.23513 8.93292 1.22133 9.00293 1.22133C9.07294 1.22133 9.14226 1.23513 9.20693 1.26195C9.2716 1.28876 9.33035 1.32806 9.37982 1.3776L17.0029 9"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
