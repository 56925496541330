import { ReactElement } from "react";
import { CardSummaryTile } from ".";
import { BackArrow } from "../../generic";
import { MultAcctOffer } from "../modals/multAcctOffer/multAcctOfferModel";
import { SplashMessage } from "../splashMessage/splashMessage";
import "./cardSummary.scss";
import { CardSummaryState } from "./cardSummaryViewModel";

export type CardSummaryViewProps = CardSummaryState;

export function CardSummaryView(props: CardSummaryViewProps): ReactElement {
    return (
        <>
            <div id={`cardSummary${props.size}`}>
                {props.size === "" ? (
                    <>
                        <BackArrow available={props.accounts!.length > 1} to="/account/dashboard">
                            <SplashMessage cms={props.appCms} />
                        </BackArrow>
                    </>
                ) : (
                    <></>
                )}
                <CardSummaryTile
                    index={props.urlId !== undefined ? Number(props.urlId) : props.accountIndex}
                    account={props.accounts![props.accountIndex]}
                    showUnLockCardSuccess={props.showUnLockCardSuccess}
                    appCms={props.appCms}
                />
            </div>
            <MultAcctOffer
                show={props.showOfferPopup && !props.showPreferencesLanding}
                setShow={props.setShowOfferPopup}
                bodyText={props.bodyText}
                redirectUrl={props.redirectUrl}
                accountHandle={props.offerAccountHandle!}
            />
        </>
    );
}
