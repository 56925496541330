import { ReactElement } from "react";
import { ProminentAlertView } from "./prominentAlertView";
import { useProminentAlertViewModel } from "./prominentAlertViewModel";

export function ProminentAlert(): ReactElement {
    const { hooks, state } = useProminentAlertViewModel();

    function dismiss() {
        state.setDismissed(true);
        hooks.storeValue("prominentAlert", true);
    }

    return <ProminentAlertView {...state} dismiss={dismiss} />;
}
