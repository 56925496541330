import { useContext } from "react";
import { AppSettings, useSettingsContext } from "../../../api/backend";
import { AppDownloadBannerContext, CardNavContext, ViewRestrictionContext } from "../../../contexts";
import { useIsLoggedIn } from "../../../hooks";

type PrivateViewModel = {
    state: PrivateViewState;
};

export type PrivateViewState = {
    showBanner: boolean;
    loggedIn: boolean;
    restricted: boolean;
    showNav: boolean;
    appSettings: AppSettings;
};

export function usePrivateViewModel(): PrivateViewModel {
    const { appSettings } = useSettingsContext();
    const { showBanner } = useContext(AppDownloadBannerContext);
    const { restricted } = useContext(ViewRestrictionContext);
    const { showNav } = useContext(CardNavContext);
    const { loggedIn } = useIsLoggedIn();

    return {
        state: {
            showBanner,
            loggedIn,
            restricted,
            showNav,
            appSettings
        }
    };
}
