import { ReactElement, useEffect } from "react";
import PCPView from "./pcpView";
import { usePCPViewModel } from "./pcpViewModel";

export function PCPModel(): ReactElement {
    const { state, api, hooks } = usePCPViewModel();

    useEffect(() => {
        if (!hooks.actionAllowed("premierCreditProtection", state.accounts![state.accountIndex].accountHandle)) {
            hooks.navigate(`/account/dashboard`, { relative: "path" });
        }
    });

    return <PCPView {...state} {...api} />;
}
