import { ReactElement } from "react";
import { Pagination } from "react-bootstrap";
import { Loading } from "../..";
import { SearchResults } from "../../../api/backend";
import { ButtonPro } from "../../generic";
import { ArrowLeft, ArrowRight, ColorBlocks6, Search, Warning } from "../../generic/icons";
import "./help.scss";
import { HelpState } from "./helpViewModel";

type HelpViewProps = HelpState & {
    onSearch: Function;
    onSelected: Function;
    onPageChange: Function;
    onBackToResults: Function;
};

export function HelpView(props: HelpViewProps): ReactElement {
    const knowledgeManagementFlag =
        props.appSettings != undefined && props.appSettings.featureFlags != undefined
            ? props.appSettings.featureFlags.knowledgeManagement
            : false;

    return (
        <div id="help">
            {!knowledgeManagementFlag ? (
                <HelpViewUnavailable {...props} />
            ) : props.articles === undefined ? (
                <HelpViewSearch {...props} />
            ) : props.articles.searchResults.length > 0 ? (
                props.selectedArticle === undefined ? (
                    <HelpViewResults {...props} />
                ) : (
                    <HelpArticleFull {...props} />
                )
            ) : (
                <HelpViewNoResults {...props} />
            )}

            <Loading show={props.loading} />
        </div>
    );
}

function HelpViewUnavailable(props: HelpViewProps): ReactElement {
    return (
        <div className="help-results">
            <div className={`help-results-content${props.size}`}>
                <div className={`help-results-none${props.size}`}>
                    <div className="help-none-unavailable">
                        <Warning id="search-non" />
                        <h2>Search temporarily unavailable</h2>
                        <p>PREMIER Help is currently unavailable. We apologize for any inconvenience.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function HelpViewSearch(props: HelpViewProps): ReactElement {
    return (
        <div className="help-landing">
            <div className={`help-content${props.size}`}>
                <div className="help-landing-header">
                    <h2 className={`h2${props.size}`}>What Can We Help You With?</h2>
                    {props.size !== "-mobile" ? <ColorBlocks6 className="help-blocks" /> : <></>}
                </div>
                <HelpSearch {...props} />
            </div>
        </div>
    );
}

function HelpViewResults(props: HelpViewProps): ReactElement {
    return (
        <>
            <div className={`help-header${props.size}`}>
                <HelpSearch {...props} />
            </div>
            <div className="help-results">
                <div className={`help-results-content${props.size}`}>
                    <div className="help-results-header">
                        <h2>Search Results</h2>
                        <h6>
                            {props.articles?.searchResults.length} results for "{props.curSearch}"
                        </h6>
                    </div>
                    <div className="help-results-map">
                        {props.curArticles?.map((x) => <HelpArticle key={x.id} {...x} {...props} />)}
                    </div>
                    <HelpPages {...props} />
                </div>
            </div>
        </>
    );
}

function HelpViewNoResults(props: HelpViewProps): ReactElement {
    return (
        <>
            <div className={`help-header${props.size}`}>
                <HelpSearch {...props} />
            </div>
            <div className="help-results">
                <div className={`help-results-content${props.size}`}>
                    <div className={`help-results-none${props.size}`}>
                        <Search id="search-non" />
                        <div className="help-none-body">
                            <h2>
                                We couldn't find any results for "{props.curSearch.substring(0, 20)}
                                {props.curSearch.length > 20 ? "..." : ""}"
                            </h2>
                            <h5>If you would like to continue searching, here are a few tips:</h5>
                            <ul>
                                <li>Check your spelling</li>
                                <li>Be less specific for a broader search result</li>
                                <li>Example search terms "payment", "Lock card", "Pin"</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function HelpSearch(props: HelpViewProps): ReactElement {
    return (
        <div
            className="help-search"
            onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === "Enter") {
                    props.onSearch();
                }
            }}
        >
            <label htmlFor="search-input" />
            <input
                id="search-input"
                type="search"
                placeholder="Search..."
                value={props.searchText}
                onChange={(x) => {
                    props.setSearchText(x.target.value);
                }}
            />
            <Search className="search" onClick={() => props.onSearch()} />
        </div>
    );
}

function HelpArticle(props: SearchResults & { onSelected: Function }): ReactElement {
    return (
        <div className="result-item" onClick={() => props.onSelected(props.id)}>
            <div className="result-item-content">
                <h3>{props.name}</h3>
                <p>{props.description}</p>
            </div>
            <ArrowRight />
        </div>
    );
}

function HelpArticleFull(props: HelpViewProps): ReactElement {
    return (
        <div className="help-results">
            <div className={`help-results-content${props.size}`}>
                <ButtonPro variant="tertiary" className="help-backButton" onClick={() => props.onBackToResults()}>
                    <ArrowLeft className="help-arrow" />
                    <>Back to search results</>
                </ButtonPro>
                <div className={`help-results-none${props.size}`}>
                    <div className="help-article-full">
                        <h1>{props.selectedArticle?.name}</h1>
                        <span dangerouslySetInnerHTML={{ __html: props.selectedArticle?.description ?? "" }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function HelpPages(props: HelpViewProps): ReactElement {
    let items = [];
    for (let i = 1; i <= props.pages; i++) {
        items.push(
            <Pagination.Item key={i} active={i === props.curPage} onClick={() => props.onPageChange(i)}>
                {i}
            </Pagination.Item>
        );
    }

    return (
        <>
            {props.pages > 1 ? (
                <div className="help-pages">
                    <p>
                        Page {props.curPage} of {props.pages}
                    </p>
                    <Pagination>
                        <Pagination.Prev
                            disabled={props.curPage === 1}
                            onClick={() => props.onPageChange(props.curPage - 1)}
                        />
                        {items}
                        <Pagination.Next
                            disabled={props.curPage === props.pages}
                            onClick={() => props.onPageChange(props.curPage + 1)}
                        />
                    </Pagination>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}
