import { ReactElement } from "react";
import { LoginCms } from "../../../../api/backend";
import { GenericModal, GenericModalProps, ModalProps } from "../../../generic";

type LockoutWaitProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    cms: LoginCms;
};

export function LockoutWait(props: ModalProps & LockoutWaitProps): ReactElement {
    const state = {
        headerText: "",
        bodyText: props.cms?.LoginAttemptFailuresMessage,
        primaryButton: {
            text: "Close",
            action: () => {
                props.setShow(false);
            }
        }
    } as GenericModalProps;

    return <GenericModal show={props.show} {...state} />;
}
