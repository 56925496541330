import { Formik, FormikProps } from "formik";
import { ReactElement } from "react";
import { Modal, ModalProps } from "../../../generic";
import { EnterPaymentAccountForm } from "./enterPaymentAccountForm";
import { EnterPaymentAccountFormFields, useEnterPaymentAccountViewModel } from "./enterPaymentAccountViewModel";
import { validationSchema } from "./enterPaymentAccountYup";

export type EnterPaymentAccountProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowReview: React.Dispatch<React.SetStateAction<boolean>>;
    routingNumber: string;
    setRoutingNumber: React.Dispatch<React.SetStateAction<string>>;
    checkingNumber: string;
    setCheckingNumber: React.Dispatch<React.SetStateAction<string>>;
    confirmNumber: string;
    setConfirmNumber: React.Dispatch<React.SetStateAction<string>>;
    setBankName: React.Dispatch<React.SetStateAction<string>>;
    callback?: Function;
};

export function EnterPaymentAccountModal(props: ModalProps & EnterPaymentAccountProps): ReactElement {
    return (
        <Modal show={props.show}>
            <EnterPaymentAccountModel {...props} />
        </Modal>
    );
}

export function EnterPaymentAccountModel(props: EnterPaymentAccountProps): ReactElement {
    const vm = useEnterPaymentAccountViewModel();

    const initialValues = {
        routingNumber: props.routingNumber,
        checkingNumber: props.checkingNumber,
        confirmNumber: props.checkingNumber
    } as EnterPaymentAccountFormFields;

    function onSubmit() {
        props.setShow(false);
        props.setShowReview(true);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            initialTouched={{ routingNumber: false, checkingNumber: false, confirmNumber: false }}
            onSubmit={(values, actions) => {
                onSubmit();
            }}
        >
            {(formProps: FormikProps<EnterPaymentAccountFormFields>) => (
                <EnterPaymentAccountForm props={props} vm={vm} formProps={formProps} />
            )}
        </Formik>
    );
}
