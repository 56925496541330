import { ReactElement } from "react";
import { Alert } from "../../generic";
import "./paymentHub.scss";
import { PaymentHubState } from "./paymentHubViewModel";
import { CurrentBalance } from "./paymentTiles/currentBalance/currentBalance";
import { MinAmountDue } from "./paymentTiles/minAmountDue/minAmountDue";
import { PaymentButton } from "./paymentTiles/paymentButton/paymentButton";
import { PaymentDueDate } from "./paymentTiles/paymentDueDate/paymentDueDate";

export type PaymentHubViewProps = PaymentHubState;

function PaymentHubView(props: PaymentHubViewProps): ReactElement {
    return (
        <>
            <div className={`paymentHubContainer${props.size}`} data-testid="paymentHubContainer">
                {props.showAlert ? (
                    <div className="paymentHub-alert-container">
                        <Alert level={"error"} header={"Past Due"} text={""} />
                    </div>
                ) : (
                    <></>
                )}
                {props.showBalance ? (
                    <div className={`PaymentHub${props.size}`} id={`PaymentHub${props.size}`}>
                        <CurrentBalance />
                    </div>
                ) : (
                    <div className={`PaymentHub${props.size}`}>
                        <MinAmountDue />
                        <PaymentDueDate />
                        <PaymentButton />
                    </div>
                )}
            </div>
        </>
    );
}

export default PaymentHubView;
