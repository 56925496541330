import { IDictionary, ISwrveEmbeddedMessage, SwrveSDK } from "@swrve/web-sdk";
import { ReactElement, createContext, useContext, useState } from "react";
import { SwrveApiKey, SwrveAppId } from "../res/react-env-variables";
import { Children } from "../types";
import { NotImplemented } from "../utility";

type SwrveContextProps = {
    swrveInitialized: boolean;
    setSwrveInitialized: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SwrveContext = createContext<SwrveContextProps>({
    swrveInitialized: false,
    setSwrveInitialized: NotImplemented
});

const useSwrveContextProps = (): SwrveContextProps => {
    const [swrveInitialized, setSwrveInitialized] = useState(false);

    return { swrveInitialized, setSwrveInitialized };
};

export function Swrve(props: Children): ReactElement {
    const contextProps = useSwrveContextProps();

    return (
        <>
            <SwrveContext.Provider value={contextProps}>{props.children}</SwrveContext.Provider>
        </>
    );
}

export const useSwrveContext = () => {
    const swrveContext = useContext(SwrveContext);

    const initializeSwrve = (applicantHandle: string) => {
        if (swrveContext.swrveInitialized) {
            SwrveSDK.shutdown();
        }
        /** create your embedded callback */
        var embeddedbCallbackImp = (
            message: ISwrveEmbeddedMessage,
            personalizationProperties: IDictionary<string> | undefined
        ) => {
            //console.log("embeddedbCallbackImp called");
            //console.log(`embedded campaign with data: ${message.data} and personalization properties: ${JSON.stringify(personalizationProperties)}`);
            /** if you want to track an impression event */
            //SwrveSDK.embeddedMessageWasShownToUser(message);
            /** The message object returns a list of strings representing the button options. */
            /** In this example, we are taking out the first button from the list and sending a click event */
            // var buttonName = message.buttons[0];
            // SwrveSDK.embeddedMessageButtonWasPressed(message, buttonName);
        };

        //   /** add it to an embedded config object */
        var embeddedConfig = { embeddedCallback: embeddedbCallbackImp };

        /** add it to your config on init */
        SwrveSDK.initWithConfig({
            appId: SwrveAppId,
            apiKey: SwrveApiKey,
            externalUserId: applicantHandle,
            embeddedMessageConfig: embeddedConfig
        });

        swrveContext.setSwrveInitialized(true);
    };

    const swrveShutdown = () => {
        if (swrveContext.swrveInitialized) SwrveSDK.shutdown();

        swrveContext.setSwrveInitialized(false);
    };

    const insertSwrveEvent = (event: string) => {
        if (swrveContext.swrveInitialized) SwrveSDK.event(event);
    };

    return {
        initializeSwrve: initializeSwrve,
        insertSwrveEvent: insertSwrveEvent,
        swrveShutdown: swrveShutdown
    };
};
