import { useAccountContext } from "../../../../api/backend";
import { useExpandableRowContext } from "../../../../contexts";
import { PaymentScheduledRowProps } from "./paymentScheduledRow";

export type PaymentScheduledRowState = {
    last4: string;
    expanded: boolean;
    className: string;
};

type PaymentScheduledRowHooks = {
    newRowExpanded: (index: number) => void;
};

const usePaymentScheduledRowState = (props: PaymentScheduledRowProps): PaymentScheduledRowState => {
    const { accounts, accountIndex } = useAccountContext();
    const { curIndex } = useExpandableRowContext();

    const account = accounts[accountIndex];
    const expanded = props.index === curIndex;
    const className = props.payment.paymentStatus.toLowerCase();
    const last4 = account!.maskedAccountNumber.slice(-4);

    return { last4, expanded, className };
};

const usePaymentScheduledRowHooks = (): PaymentScheduledRowHooks => {
    const { newRowExpanded } = useExpandableRowContext();

    return {
        newRowExpanded
    };
};

export const usePaymentScheduledRowViewModel = (props: PaymentScheduledRowProps) => {
    const state = usePaymentScheduledRowState(props);
    const hooks = usePaymentScheduledRowHooks();

    return {
        state,
        hooks
    };
};
