import { useContext, useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { OnlineActions, UserAccount, useAccountContext, useDigitalActionsContext } from "../../../api/backend";
import { ViewRestrictionContext } from "../../../contexts";

export type PCPViewModel = {
    state: PCPState;
    api: PCPAPI;
    hooks: PCPHooks;
};

export type PCPState = {
    viewMore: boolean;
    setViewMore: React.Dispatch<React.SetStateAction<boolean>>;
    accounts: UserAccount[] | undefined;
    accountIndex: number;
};
export type PCPAPI = {
    navigateToPCP: () => void;
};

type PCPHooks = {
    navigate: NavigateFunction;
    actionAllowed: (option: string, accountHandle?: string | undefined) => boolean;
};

export function usePCPViewModel(): PCPViewModel {
    const [viewMore, setViewMore] = useState(false);
    const navigate = useNavigate();
    const { setRestricted } = useContext(ViewRestrictionContext);
    const { accounts, accountIndex } = useAccountContext();
    const { actionAllowed } = useDigitalActionsContext();

    function navigateToPCP() {
        navigate("/account/premier-credit-protection-enrollment-status");
    }

    useEffect(() => {
        setRestricted(false);

        return () => {
            setRestricted(true);
        };
    }, []);

    return {
        state: { viewMore, setViewMore, accountIndex, accounts },
        api: { navigateToPCP },
        hooks: {
            navigate,
            actionAllowed
        }
    };
}
