import dayjs from "dayjs";
import { ReactElement, useMemo, useState } from "react";
import { PaymentResponse, usePaymentContext } from "../../../api/backend";
import { PaymentHistoryRow } from "./paymentHistoryRow/paymentHistoryRow";

type PaymentHistoryContext = {
    curIndex: number | undefined;
    setCurIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export type PaymentHistoryState = {
    loading: boolean;
    seeMore: boolean;
    setSeeMore: React.Dispatch<React.SetStateAction<boolean>>;
    filter: number;
    setFilter: React.Dispatch<React.SetStateAction<number>>;
    years: number[];
    filteredHistory: PaymentResponse[];
    rows: ReactElement[];
};

const usePaymentHistoryContext = (): PaymentHistoryContext => {
    const [curIndex, setCurIndex] = useState(undefined as undefined | number);

    return {
        curIndex,
        setCurIndex
    };
};

const usePaymentHistoryState = (): PaymentHistoryState => {
    const { history: paymentHistory, loading } = usePaymentContext();
    const [seeMore, setSeeMore] = useState(false);
    const [filter, setFilter] = useState(0 as number);

    const years = useMemo(() => {
        return paymentHistory
            .map((p) => dayjs.tz(p.paymentDate).year())
            .filter((p, i, s) => i === s.indexOf(p))
            .sort((a, b) => a - b);
    }, [paymentHistory]);

    const filteredHistory = useMemo(() => {
        return paymentHistory
            .filter((p) => {
                if (filter > 0) return dayjs.tz(p.paymentDate).year() === filter;
                else return dayjs(p.paymentDate) > dayjs().subtract(6, "month");
            })
            .sort((a, b) => dayjs(b.paymentDate).diff(a.paymentDate));
    }, [paymentHistory, filter]);

    const displayedHistory = useMemo(() => {
        return seeMore ? filteredHistory : filteredHistory.slice(0, 3);
    }, [filteredHistory, seeMore]);

    const rows = useMemo(() => {
        return displayedHistory.map((v, i) => <PaymentHistoryRow key={v.paymentID.toString()} index={i} payment={v} />);
    }, [displayedHistory]);

    return {
        loading,
        seeMore,
        setSeeMore,
        filter,
        setFilter,
        years,
        filteredHistory,
        rows
    };
};

export const usePaymentHistoryViewModel = () => {
    const context = usePaymentHistoryContext();
    const state = usePaymentHistoryState();

    return {
        context,
        state
    };
};
