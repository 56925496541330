import { ReactElement } from "react";
import { getColor } from "./icon";
import { ColorBlockProps, getBrandedColorCode } from "./iconProps";

export function ColorBlocks_tr_l(props: ColorBlockProps): ReactElement {
    const lg = getColor("color", getBrandedColorCode(props.colors?.lg), "#42967D");
    const md = getColor("color", getBrandedColorCode(props.colors?.md), "#E4CB34");
    const sm = getColor("color", getBrandedColorCode(props.colors?.sm), "#60AADB");

    return (
        <svg
            id={props.id}
            className={props.className}
            width="390"
            height="390"
            viewBox="0 0 390 390"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="tr_l-svg"
        >
            <rect x="130" y="0.666748" width="260" height="260" fill={lg} data-testid="tr_l-lgRec" />
            <rect x="65" y="195.167" width="65" height="65" fill={sm} data-testid="tr_l-smRec" />
            <rect y="260" width="130" height="130" fill={md} data-testid="tr_l-mdRec" />
        </svg>
    );
}
