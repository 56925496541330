import { ReactElement } from "react";
import { Regex } from "../../../../utility";
import { ValidationLI } from "./validationLi";
import "./validationLi.scss";

type UsernameValidationProps = {
    touched?: boolean;
    criteria: UsernameCriteria;
};

export type UsernameCriteria = {
    length?: boolean;
    alphaNumeric?: boolean;
    noSpecial?: boolean;
    hasError?: boolean;
};

export function UsernameValidation(props: UsernameValidationProps): ReactElement {
    return (
        <div className="validationRequirements">
            <h6 className="validationRequirements-header">Your username must contain:</h6>
            <div className="validationRequirements-list">
                <ValidationLI touched={props.touched} success={props.criteria.length}>
                    Between 8 and 32 characters
                </ValidationLI>
                <ValidationLI touched={props.touched} success={props.criteria.alphaNumeric}>
                    Letters and numbers only
                </ValidationLI>
                <ValidationLI touched={props.touched} success={props.criteria.noSpecial}>
                    No spaces or special characters
                </ValidationLI>
            </div>
        </div>
    );
}

export function ValidateUsername(username: string, usernameTouched: boolean): UsernameCriteria {
    let length = Regex.IsLength(username, 8, 32);
    let special = Regex.HasSpecial(username);
    let space = Regex.HasSpace(username);
    let alphaNumeric = Regex.IsAlphaNumeric(username) && !special && !space;
    let hasError = (!length || !alphaNumeric || special || space) && usernameTouched;

    return {
        length: length,
        alphaNumeric: alphaNumeric,
        noSpecial: !special && !space,
        hasError: hasError
    } as UsernameCriteria;
}
