import { Form, Formik } from "formik";
import { ReactElement } from "react";
import { Loading } from "../../..";
import { useViewport } from "../../../../hooks";
import { FormProps } from "../../../../types";
import { BrandedCard, ButtonPro, Modal } from "../../../generic";
import "./deliveryPreferencesTerms.scss";
import { DeliveryPreferencesTermsState } from "./deliveryPreferencesTermsViewModel";

type DeliveryPreferencesTermsViewProps = DeliveryPreferencesTermsState & {
    show: boolean;
    setShow: Function;
    initialValues: any;
    setEditPreferences: React.Dispatch<React.SetStateAction<boolean>>;
    downloadPDF: Function;
    onDecline: Function;
    onSubmit: Function;
};

function DeliveryPreferencesTermsView(props: DeliveryPreferencesTermsViewProps): ReactElement {
    const { size } = useViewport(false, true);

    const handleOnScroll = (e: any) => {
        const { scrollTop, scrollHeight, offsetHeight } = e.target;
        const hasScrollReachedBottom = offsetHeight + scrollTop > scrollHeight - 40;

        if (hasScrollReachedBottom) {
            props.setCanSave(true);
        }
    };

    return (
        <Modal show={props.show}>
            <BrandedCard
                id="deliveryPreferencesTerms"
                header="User Consent"
                size={size}
                subtitle="PLEASE READ BEFORE ENROLLING"
                download={true}
                downloadPDF={props.downloadPDF}
                downloadTitle="Download"
            >
                <Formik
                    initialValues={{ accept: false }}
                    onSubmit={(values, actions) => {
                        props.onSubmit(values, actions);
                    }}
                >
                    {(formProps: FormProps<any>) => (
                        <Form className="deliveryPreferencesTerms-form" autoComplete="off">
                            <div className="deliveryPreferencesTerms-border">
                                <div className="deliveryPreferencesTerms-content">
                                    <span
                                        id="deliveryPreferencesTerms-terms"
                                        onScroll={handleOnScroll}
                                        dangerouslySetInnerHTML={{
                                            __html: props.cms.DisclosureText
                                        }}
                                        data-testid="deliveryPreferencesTerms-terms"
                                    />
                                </div>
                            </div>

                            <div className="deliveryPreferencesTerms-bottomText">Scroll to the end to accept.</div>
                            <div id="deliveryPreferencesTerms-buttongroup">
                                <ButtonPro
                                    id={`deliveryPreferencesTerms-cancel${size}`}
                                    variant="neutral"
                                    onClick={() => {
                                        props.onDecline();
                                    }}
                                >
                                    Decline
                                </ButtonPro>
                                <ButtonPro
                                    id={`deliveryPreferencesTerms-submit${size}`}
                                    variant="primary"
                                    type="submit"
                                    disabled={!props.canSave}
                                >
                                    Accept
                                </ButtonPro>
                            </div>
                            <Loading show={formProps.isSubmitting} />
                        </Form>
                    )}
                </Formik>
            </BrandedCard>
        </Modal>
    );
}

export default DeliveryPreferencesTermsView;
