import { IMaskOptions } from "./interfaces/iMaskOptions";
import { ISelection } from "./interfaces/iSelection";
import {
    clearRange,
    formatValue,
    getInsertStringLength,
    insertString,
    getLeftEditablePosition,
    getRightEditablePosition
} from "./utils/string";

export default function processChange(
    maskOptions: IMaskOptions,
    value: string,
    selection: ISelection,
    previousValue: string,
    previousSelection: ISelection
) {
    const { mask, prefix, lastEditablePosition } = maskOptions;
    let newValue = value;
    let enteredString = "" as string | null;
    let formattedEnteredStringLength = 0;
    let removedLength = 0;
    let cursorPosition = Math.min(previousSelection.start, selection.start);

    if (selection.end > previousSelection.start) {
        enteredString = newValue.slice(previousSelection.start, selection.end);
        formattedEnteredStringLength = getInsertStringLength(maskOptions, previousValue, enteredString, cursorPosition);
        if (!formattedEnteredStringLength) {
            removedLength = 0;
        } else {
            removedLength = previousSelection.length;
        }
    } else if (newValue.length < previousValue.length) {
        removedLength = previousValue.length - newValue.length;
    }

    newValue = previousValue;

    if (removedLength) {
        if (removedLength === 1 && !previousSelection.length) {
            const deleteFromRight = previousSelection.start === selection.start;
            cursorPosition = deleteFromRight
                ? getRightEditablePosition(maskOptions, selection.start) ?? 0
                : getLeftEditablePosition(maskOptions, selection.start) ?? 0;
        }
        newValue = clearRange(maskOptions, newValue, cursorPosition, removedLength);
    }

    newValue = insertString(maskOptions, newValue, enteredString ?? "", cursorPosition);

    cursorPosition = cursorPosition + formattedEnteredStringLength;
    if (mask && cursorPosition >= mask.length) {
        cursorPosition = mask.length;
    } else if (prefix && cursorPosition < prefix.length && !formattedEnteredStringLength) {
        cursorPosition = prefix.length;
    } else if (
        prefix &&
        cursorPosition >= prefix.length &&
        cursorPosition < (lastEditablePosition ?? 0) &&
        formattedEnteredStringLength
    ) {
        cursorPosition = getRightEditablePosition(maskOptions, cursorPosition) ?? 0;
    }

    newValue = formatValue(maskOptions, newValue);

    if (!enteredString) {
        enteredString = null;
    }

    return {
        value: newValue,
        enteredString,
        selection: { start: cursorPosition, end: cursorPosition }
    };
}
