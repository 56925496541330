import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("America/Chicago");

export function GetMonthAbbrev(monthNumber: number) {
    const months = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    let abbrev: string = "";
    if (monthNumber >= 0 && monthNumber < 12) {
        abbrev = months[monthNumber];
    }
    return abbrev;
}

export function GetFormattedDate(dateString: string | undefined | Dayjs, includeYear: boolean): string {
    if (!dateString) {
        return "";
    }

    const originalTimezone = dateString.toString().slice(-6);
    const date = dayjs(dateString).utcOffset(originalTimezone);
    let monthName = GetMonthAbbrev(date.get("M"));
    let formattedDate = monthName + " " + date.format("D");
    if (includeYear) {
        formattedDate = formattedDate + date.format(", YYYY");
    }
    return formattedDate;
}

export function FormatSeconds(amount: number): string {
    let m: string;
    let s: string;

    let minutes = Math.floor(amount / 60);
    if (minutes > 0) {
        m = minutes.toString().padStart(2, "0");
    } else {
        m = "00";
    }

    let seconds = Math.floor(amount % 60);
    if (seconds > 0) {
        s = seconds.toString().padStart(2, "0");
    } else {
        s = "00";
    }

    return `${m}:${s}`;
}
