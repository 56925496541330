import { AxiosError } from "axios";
import { FormikHelpers } from "formik";
import { ReactElement, useEffect, useState } from "react";
import { ValidatePassword, ValidateUsername } from "../..";
import { MfaOptionsResponse, ValidationErrors } from "../../../api/backend";
import { MFAOptions } from "../../../api/identityServer";
import { IsNullOrEmpty, Regex, UUID } from "../../../utility";
import { EnrollCredentialsFields, EnrollCredentialsView } from "./enrollCredentialsView";
import { useEnrollCredentialsViewModel } from "./enrollCredentialsViewModel";

export function EnrollCredentialsModel(): ReactElement {
    const { hooks, state, api } = useEnrollCredentialsViewModel();
    const [tempEmail, setTempEmail] = useState("");

    useEffect(() => {
        if (state.searchParams.get("id") === null) {
            let id = UUID.New();
            hooks.navigate({
                pathname: "/auth/enroll",
                search: `?id=${id}`
            });
        } else {
            if (state.securityType !== undefined) {
                state.setSessionId(state.searchParams.get("id")!);
            } else if (state.securityType === undefined) {
                state.setSecurityType("OMT");

                if (state.searchParams.get("id") !== null) {
                    let accountGuid = state.searchParams.get("id");
                    let id = UUID.New();
                    state.setSessionId(id);

                    api.ValidateIdentityById({ accountGuid: accountGuid!, sessionId: id })
                        .then((res) => {
                            console.log(res);
                            // hooks.navigate({
                            //     pathname: "/auth/register",
                            //     search: `?id=${id}`
                            // })
                        })
                        .catch((err) => {
                            state.setShowError(true);
                        });
                } else {
                    console.log("OMT Missing ID");
                }
            }
        }
    }, []);

    useEffect(() => {
        state.setSubtitle(state.securityType === "OMT" ? "Step 1 of 3" : "Step 2 of 4");
    }, [state.securityType]);

    useEffect(() => {
        if (state.emailTouched && !IsNullOrEmpty(tempEmail) && !Regex.IsEmail(tempEmail)) {
            state.setEmailError("Invalid Email");
        }
    }, [state.activeElement]);

    function onUsernameChange(value: string, values: EnrollCredentialsFields): void {
        state.setUsernameError("");

        values.usernameCriteria = ValidateUsername(value, state.usernameTouched);

        if (!state.usernameTouched && value.length > 0) state.setUsernameTouched(true);

        if (/\d{8}/g.test(value))
            state.setUsernameError(
                " For your security, your username must not contain more than 8 consecutive numbers"
            );

        onValuesChanged(values, /\d{8}/g.test(value));
    }

    function onPasswordChanged(password: string, passwordType: string, values: EnrollCredentialsFields): void {
        let newPassword = passwordType === "password" ? password : values.password;
        let confirmPassword = passwordType === "confirmPassword" ? password : values.confirm;

        values.passwordCriteria = ValidatePassword(newPassword, confirmPassword, state.passwordTouched);

        values.passwordCriteria = {
            ...values.passwordCriteria
        };

        if (!state.passwordTouched && newPassword.length > 0) state.setPasswordTouched(true);
        if (!state.confirmTouched && confirmPassword.length > 0) state.setConfirmTouched(true);

        onValuesChanged(values);
    }

    function onEmailChanged(value: string, values: EnrollCredentialsFields): void {
        state.setEmailError("");
        setTempEmail(value);

        values.emailCriteria.valid = Regex.IsEmail(value);

        if (!state.emailTouched && value.length > 0) state.setEmailTouched(true);

        if (value.length > 50) {
            state.setEmailError("Email must be 50 characters or less");
        }

        onValuesChanged(values, value.length > 50);
    }

    function onValuesChanged(values: EnrollCredentialsFields, override: boolean = false) {
        state.setCanContinue(
            values.usernameCriteria.hasError === false &&
                values.passwordCriteria.hasError === false &&
                values.emailCriteria.valid === true &&
                values.username !== state.usernameOld &&
                !override
        );
    }

    function onCancel() {
        state.setShowModal(true);
    }

    function onSubmit(values: EnrollCredentialsFields, actions: FormikHelpers<EnrollCredentialsFields>) {
        if (state.sessionId && UUID.Valid(state.sessionId)) {
            api.ValidateUsername({
                Username: values.username,
                Password: values.password,
                Email: values.email,
                SessionId: state.sessionId
            })
                .then(() => {
                    api.MfaOptions({ sessionId: state.sessionId! }).then((res) => {
                        res.data.noContactInfo = noContactInfo(res.data);
                        state.setMfaOptions({ ...res.data } as MFAOptions);

                        hooks.navigate({
                            pathname: "/auth/request-code",
                            search: `?id=${state.sessionId}`
                        });
                    });
                })
                .catch((err: AxiosError) => {
                    if (err.response?.status === 422) {
                        let errors = err.response?.data as ValidationErrors;
                        let errorField = Object.keys(errors.errors)[0];
                        let errorMessage = errors.errors[errorField][0];

                        if (errorField === "UsernameAlreadyUsed") {
                            state.setUsernameError(errorMessage);
                            state.setUsernameOld(values.username);
                        } else if (errorField === "InvalidEmail") {
                            state.setEmailError(errorMessage);
                        }
                    } else {
                        let errors = err.response?.data as ValidationErrors;
                        let errorField = Object.keys(errors.errors)[0];
                        let errorMessage = errors.errors[errorField][0];

                        if (errorMessage.toString().toUpperCase().includes("EMAIL")) {
                            state.setEmailError(errorMessage);
                        }
                    }
                    state.setCanContinue(false);
                })
                .finally(() => actions.setSubmitting(false));
        } else {
            hooks.navigate("/auth/login");
        }
    }

    return (
        <EnrollCredentialsView
            {...state}
            onUsernameChange={onUsernameChange}
            onPasswordChanged={onPasswordChanged}
            onEmailChanged={onEmailChanged}
            onCancel={onCancel}
            onSubmit={onSubmit}
        />
    );
}

const noContactInfo = (contactInfo: MfaOptionsResponse): boolean => {
    return (
        phoneNullOrEmpty(contactInfo.homePhoneNumber) &&
        phoneNullOrEmpty(contactInfo.workPhoneNumber) &&
        phoneNullOrEmpty(contactInfo.cellPhoneNumber) &&
        phoneNullOrEmpty(contactInfo.otherPhoneNumber) &&
        IsNullOrEmpty(contactInfo.emailAddress)
    );
};

const phoneNullOrEmpty = (number: string): boolean => {
    return IsNullOrEmpty(number) || /^[0]+$/g.test(number) || /^[0]+[1]$/g.test(number);
};
