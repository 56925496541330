import { ReactElement } from "react";
import { Loading } from "../../..";
import { Alert, AsyncElement, ButtonPro, GenericModal } from "../../../generic";
import { Check } from "../../../generic/icons";
import { ManageAlertsTextsState } from "./manageAlertsTextsViewModel";

type ManageAlertsTextsViewProps = ManageAlertsTextsState & {
    onEditSpecificAlerts: Function;
    onCancelEditSpecificAlerts: Function;
    onSaveSpecificAlerts: Function;
    onCategoryClick: Function;
    onSpecificAlertCheckChange: Function;
    isEnrolled: boolean;
    isEditingSpecificAlerts: boolean;
    isEditing: boolean;
    isLoading: boolean;
    isEditingSpecificAlertsChanged: boolean;
};

export function ManageAlertsTextsView(props: ManageAlertsTextsViewProps): ReactElement {
    return (
        <>
            {props.isEnrolled && !props.isEditingSpecificAlerts && (
                <>
                    <div id="manageAlertsForm-alerts">
                        <div className="manageAlertsForm-alerts-header-top">
                            <div className="manageAlerts-section-header-row">
                                <div className="manageAlerts-section-header-row-text">Your Alerts</div>
                                <div className="manageAlerts-section-header-row-button">
                                    <ButtonPro disabled={props.isEditing} onClick={() => props.onEditSpecificAlerts()}>
                                        Edit Alerts
                                    </ButtonPro>
                                </div>
                            </div>
                        </div>

                        <AsyncElement loading={props.isLoading}>
                            <div className="alerts-list">
                                <div className="manageAlerts-section-category-text">Important Updates</div>
                                <div className="alerts-list-row">
                                    <div className="manageAlerts-category-row-text">Declined Transactions</div>
                                    <div>
                                        <Check className="manageAlerts-category-row-icon-check" />
                                    </div>
                                </div>

                                {props.rowsAlerts.length > 0 && props.rowsAlerts}
                            </div>
                        </AsyncElement>

                        <div className="manageAlertsForm-alerts-header-bottom">
                            <div className="manageAlerts-section-header-row">
                                <div className="manageAlerts-section-header-row-text"></div>
                                <div className="manageAlerts-section-header-row-button">
                                    <ButtonPro disabled={props.isEditing} onClick={() => props.onEditSpecificAlerts()}>
                                        Edit Alerts
                                    </ButtonPro>
                                </div>
                            </div>
                        </div>
                    </div>

                    <GenericModal show={props.showModalError} {...props.modalErrorProps} />
                    <GenericModal
                        show={props.showModalUpdateSpecificAlertsSuccess}
                        {...props.modalUpdateSpecificAlertsSuccessProps}
                    />
                </>
            )}

            {props.isEnrolled && props.isEditingSpecificAlerts && (
                <>
                    <div id="manageAlertsForm-alerts">
                        <div className="manageAlertsForm-alerts-header-top">
                            <div className="manageAlerts-section-header-row">
                                <div className="manageAlerts-section-header-row-text">Your Alerts</div>
                                <div className="manageAlerts-section-header-row-buttons">
                                    <div className="manageAlerts-section-header-row-button">
                                        <ButtonPro
                                            onClick={() => props.onCancelEditSpecificAlerts()}
                                            variant="secondary"
                                        >
                                            Cancel
                                        </ButtonPro>
                                    </div>
                                    <div className="manageAlerts-section-header-row-button">
                                        <ButtonPro
                                            disabled={
                                                !props.isEditingSpecificAlertsChanged || props.anyThresholdItemErrors
                                            }
                                            onClick={() => props.onSaveSpecificAlerts()}
                                        >
                                            Save Changes
                                        </ButtonPro>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <AsyncElement loading={props.isLoading}>
                            <div className="alerts-list">
                                <div className="manageAlerts-section-category-text alerts-list-mandatory-padding">
                                    Important Updates
                                </div>
                                <div id="alertpage-alert" className="alerts-list-padding">
                                    <Alert level="info">
                                        You will automatically receive alerts for declined transactions
                                    </Alert>
                                </div>
                                <div className="alerts-list-row">
                                    <div className="manageAlerts-category-row-text">Declined Transactions</div>
                                    <div className="checkboxPro">
                                        <input
                                            type="checkbox"
                                            aria-label="check"
                                            checked={true}
                                            disabled={true}
                                        ></input>
                                    </div>
                                </div>

                                {props.rowsAlertsEdit.length > 0 && props.rowsAlertsEdit}
                            </div>
                        </AsyncElement>

                        <div className="manageAlertsForm-alerts-header-bottom">
                            <div className="manageAlerts-section-header-row">
                                <div className="manageAlerts-section-header-row-text"></div>
                                <div className="manageAlerts-section-header-row-buttons">
                                    <div className="manageAlerts-section-header-row-button">
                                        <ButtonPro
                                            onClick={() => props.onCancelEditSpecificAlerts()}
                                            variant="secondary"
                                        >
                                            Cancel
                                        </ButtonPro>
                                    </div>
                                    <div className="manageAlerts-section-header-row-button">
                                        <ButtonPro
                                            disabled={
                                                !props.isEditingSpecificAlertsChanged || props.anyThresholdItemErrors
                                            }
                                            onClick={() => props.onSaveSpecificAlerts()}
                                        >
                                            Save Changes
                                        </ButtonPro>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <GenericModal show={props.showModalError} {...props.modalErrorProps} />
                    <GenericModal
                        show={props.showModalUpdateSpecificAlertsSuccess}
                        {...props.modalUpdateSpecificAlertsSuccessProps}
                    />
                    <GenericModal show={props.showModalCancelWithoutSaving} {...props.modalCancelWithoutSavingProps} />
                </>
            )}
            <Loading show={props.isLoading} />
        </>
    );
}
