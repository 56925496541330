import { ReactElement, useEffect } from "react";
import "./togglePro.scss";
import { useToggleProViewModel } from "./toggleProViewModel";
import { ToggleProView } from "./toggleProView";

export type ToggleProProps = any & {
    id?: string;
    checked?: boolean;
    onToggle?: Function;
    disabled?: boolean;
    variant?: "" | "neg" | "label" | "lock";
};

function defaults(props: ToggleProProps): ToggleProProps {
    return { checked: false, disabled: false, variant: "", ...props };
}

export function TogglePro(props: ToggleProProps): ReactElement {
    props = defaults(props);
    const { state } = useToggleProViewModel(props);

    useEffect(() => {
        state.setIsToggled(props.checked ?? false);
    }, [props.checked]);

    return <ToggleProView {...props} {...state} />;
}
