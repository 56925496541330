import { Field, FormikContextType, useFormikContext } from "formik";
import { ChangeEvent, FocusEvent, FocusEventHandler, ReactElement, useEffect, useState } from "react";
import { useActiveElement } from "../../../../hooks";
import { ButtonPro } from "../buttonPro/buttonPro";
import "./inputPro.scss";

export type InputProProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
    FieldProps<string> & {
        label?: string;
        className?: string;
        stateValue?: StateValue<string>;
        mask?: RegExp;
    };

type FieldProps<T> = {
    value?: T;
    error?: string;
    touched?: boolean;
};

type StateValue<T> = {
    get?: T;
    set?: React.Dispatch<React.SetStateAction<T>>;
    isPrivate?: boolean;
    canPeek?: boolean;
};

function defaults(props: InputProProps): InputProProps {
    return { inputMode: "text", ...props };
}

export function InputPro(props: InputProProps): ReactElement {
    props = defaults(props);
    const { children, touched, stateValue, onChange, ...fieldProps } = props;
    const activeElement = useActiveElement();
    const [stateClass, setStateClass] = useState("inputPro-row");
    const [show, setShow] = useState(false);
    const isPrivate = props.stateValue?.isPrivate === true;
    const canPeek = props.stateValue?.canPeek === true;
    const formik = useFormikContext<any>();

    useEffect(() => {
        setStateClass(getStateClass(props, activeElement));
    }, [activeElement, props.error]);

    useEffect(() => {
        if (isPrivate && show) {
            const timer = setTimeout(() => {
                setShow(false);
            }, 10_000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [show]);

    useEffect(() => {
        if (props.stateValue?.get === "" && formik.values[props.id!] !== "") {
            formik.values[props.id!] = "";
            formik.setFieldValue(props.id!, "");
        }
    }, [props.stateValue?.get]);

    return (
        <div className={"inputPro" + (props.className ? "-" + props.className : "")}>
            <div className="inputPro-children">
                <label htmlFor={props.id}>{props.label}</label>
                {children ? children : <></>}
            </div>
            <div className={stateClass}>
                <Field
                    type={!isPrivate ? "text" : show && canPeek ? "text" : "password"}
                    name={props.id}
                    {...fieldProps}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onFieldChange(e, formik, props.id, props.mask, props.onChange, props.stateValue)
                    }
                    onBlur={(e: FocusEvent<HTMLInputElement>) => {
                        onFieldBlur(e, formik, props.id, props.onBlur);
                    }}
                    className={
                        !isPrivate || !canPeek
                            ? "inputPro-inputValue"
                            : show
                              ? "inputPro-inputValue-show"
                              : "inputPro-inputValue-hide"
                    }
                />
                {canPeek ? (
                    /* istanbul ignore next */
                    <ButtonPro
                        tabIndex={-1}
                        className="inputPro-show"
                        variant="neutral"
                        onClick={() => {
                            setShow(!show);
                        }}
                        unblock
                    >
                        <h6>{show ? "Hide" : "Show"}</h6>
                    </ButtonPro>
                ) : (
                    <></>
                )}
            </div>
            {props.error && (touched === undefined ? true : touched) && (
                <div className="validation-error text-start">
                    <h6>{props.error}</h6>
                </div>
            )}
        </div>
    );
}

export function onFieldChange(
    e: ChangeEvent<HTMLInputElement>,
    formik: FormikContextType<any>,
    id?: string,
    mask?: RegExp,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    stateValue?: StateValue<string>
) {
    if (id !== undefined && mask !== undefined) {
        if (mask.test(e.target.value)) {
            formik.values[id] = e.target.value;
            formik.setFieldValue(id, e.target.value);

            if (stateValue?.set !== undefined) stateValue.set(e.target.value);

            if (onChange) {
                onChange(e);
            }
        } else {
            formik.values[id] = formik.values[id] ?? "";
            formik.setFieldValue(id, formik.values[id] ?? ""); //maintain current value
        }
    } else {
        if (id !== undefined) {
            formik.values[id] = e.target.value;
            formik.setFieldValue(id, e.target.value);
        }

        if (stateValue?.set !== undefined) stateValue.set(e.target.value);

        if (onChange) {
            onChange(e);
        }
    }
}

export function onFieldBlur(
    e: FocusEvent<HTMLInputElement>,
    formik: FormikContextType<any>,
    id?: string,
    onBlur?: FocusEventHandler<HTMLInputElement>
) {
    if (id !== undefined) {
        formik.setFieldTouched(id!, true);
        formik.touched[id!] = true;
    }

    if (onBlur) {
        onBlur(e);
    }
}

export const getStateClass = (props: InputProProps, activeElement: Element | null): string => {
    let hasError = props.error && (props.touched === undefined ? true : props.touched);

    if (!hasError && activeElement?.id === props.id) {
        return "inputPro-row-active";
    } else if (!hasError) {
        return "inputPro-row";
    } else if (hasError) {
        return "inputPro-row-error";
    } else {
        return "inputPro-row";
    }
};
