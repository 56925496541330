import { useLoaderData } from "react-router";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { PCPFormsAndMaterialsData } from "./disclosuresLoader";

type PCPFormsAndMaterialsTabViewModel = {
    state: PCPFormsAndMaterialsTabState;
};

export type PCPFormsAndMaterialsTabState = {
    size: Size;
    headerText: string;
    bodyText: string;
    isPrint: boolean;
    printView: string;
    isPdf: boolean;
    contentType: string;
    fileName: string;
    pdfData: ArrayBuffer;
};

export function usePCPFormsAndMaterialsViewModelTab(): PCPFormsAndMaterialsTabViewModel {
    const { size } = useViewport(false, true);
    const data = useLoaderData() as PCPFormsAndMaterialsData;
    const contentType = data.pageInfo!.ContentType!;
    const fileName = data.pageInfo!.FileName!;
    const pdfData = data?.pageInfo?.ContentData === undefined ? "" : data?.pageInfo?.ContentData;

    return {
        state: {
            size,
            contentType,
            fileName,
            pdfData: pdfData as unknown as ArrayBuffer,
            ...data
        }
    };
}
