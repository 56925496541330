import { UserAccount, useAccountContext } from "../../../../api/backend";
import { useSessionStorage, useViewport } from "../../../../hooks";
import { Size } from "../../../../types";

type DeliveryPreferencesPromptViewModel = {
    hooks: DeliveryPreferencesPromptHooks;
    state: DeliveryPreferencesPromptState;
};

type DeliveryPreferencesPromptHooks = {
    storeValue: (name: string, value: any) => void;
};

export type DeliveryPreferencesPromptState = {
    size: Size;
    accounts: UserAccount[];
};

const sessionKey = ["deliveryLandingPrompted"];

export function useDeliveryPreferencesPromptViewModel(): DeliveryPreferencesPromptViewModel {
    const { size } = useViewport(false, true);
    const { accounts } = useAccountContext();
    const [, storeValue] = useSessionStorage(sessionKey);

    return {
        hooks: {
            storeValue
        },
        state: {
            size,
            accounts: accounts!
        }
    };
}
