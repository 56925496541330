import { useAccountContext } from "../../../../api/backend";
import { useExpandableRowContext } from "../../../../contexts";
import { PaymentHistoryRowProps } from "./paymentHistoryRow";

export type PaymentHistoryRowState = {
    last4: string;
    expanded: boolean;
    className: string;
};

type PaymentHistoryRowHooks = {
    newRowExpanded: (index: number) => void;
};

const usePaymentHistoryRowState = (props: PaymentHistoryRowProps): PaymentHistoryRowState => {
    const { accounts, accountIndex } = useAccountContext();
    const { curIndex } = useExpandableRowContext();

    const account = accounts[accountIndex];
    const expanded = props.index === curIndex;
    const className = props.payment.paymentStatus.toLowerCase();
    const last4 = account!.maskedAccountNumber.slice(-4);

    return { last4, expanded, className };
};

const usePaymentHistoryRowHooks = (): PaymentHistoryRowHooks => {
    const { newRowExpanded } = useExpandableRowContext();

    return {
        newRowExpanded
    };
};

export function usePaymentHistoryRowViewModel(props: PaymentHistoryRowProps) {
    const state = usePaymentHistoryRowState(props);
    const hooks = usePaymentHistoryRowHooks();

    return {
        state,
        hooks
    };
}
