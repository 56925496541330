import { FormikHelpers } from "formik";
import { ReactElement, useEffect } from "react";
import useReCaptcha from "../../../../../hooks/useReCaptcha";
import { ReCaptchaSiteKey } from "../../../../../res/react-env-variables";
import { CCPAFormModelProps, useCCPAFormViewModel } from "../ccpaFormViewModel";
import { CCPAAnonymousFormFields, CCPAAnonymousView } from "./ccpaAnonymousView";

export function CCPAAnonymousModel(props: CCPAFormModelProps): ReactElement {
    const { state, api } = useCCPAFormViewModel({ ...props, isPublic: true });
    const { generateReCaptchaToken } = useReCaptcha();

    const handleLoaded = () => {
        (window as any).grecaptcha.ready(() => [
            //     (window as any).grecaptcha.execute(ReCaptchaSiteKey, { action: "ccpa" }).then((token) => {
            //         state.setReCaptchaToken(token);
            //     })
        ]);
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "Https://www.google.com/recaptcha/api.js?render=" + ReCaptchaSiteKey;
        script.addEventListener("load", handleLoaded);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    function onCancel() {
        state.setShowCancel(true);
    }

    async function onSubmit(
        values: CCPAAnonymousFormFields,
        actions: FormikHelpers<CCPAAnonymousFormFields>,
        e: React.SyntheticEvent
    ) {
        const ccpaToken = generateReCaptchaToken("ccpa");

        ccpaToken.then((val) => {
            api.ccpaReCaptcha(val).then((captchaRes) => {
                console.log("captchaRes.data.success: " + captchaRes.data.success);
                console.log("captchaRes.data.score: " + captchaRes.data.score);

                if (captchaRes.data.success && captchaRes.data.score >= 0.5) {
                    api.ccpaPublic(values)
                        .then(() => {
                            state.setShowSuccess(true);
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(() => {
                            actions.setSubmitting(false);
                        });
                } else {
                    actions.setSubmitting(false);
                    values.captchaValid = false;
                }
            });
        });
    }

    return <CCPAAnonymousView {...props} {...state} onSubmit={onSubmit} onCancel={onCancel} />;
}
