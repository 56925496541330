import { ReactElement } from "react";
import { Icon, getColor } from "./icon";
import { IconProps } from "./iconProps";

export function InfoFill(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const backfill = getColor("background");

    const svg = (
        <svg id={id} className={svgClass} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                strokeWidth="1.5"
            />
            <path d="M10.5 6H11" stroke={backfill} strokeWidth="1.5" strokeLinecap="round" />
            <path d="M9 10H11V15" stroke={backfill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 15H13" stroke={backfill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
