import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Chat(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.75 18.75H11.25L5.25 23.25V18.75H2.25C1.85218 18.75 1.47064 18.592 1.18934 18.3107C0.908035 18.0294 0.75 17.6478 0.75 17.25V2.25C0.75 1.85218 0.908035 1.47064 1.18934 1.18934C1.47064 0.908035 1.85218 0.75 2.25 0.75H21.75C22.1478 0.75 22.5294 0.908035 22.8107 1.18934C23.092 1.47064 23.25 1.85218 23.25 2.25V17.25C23.25 17.6478 23.092 18.0294 22.8107 18.3107C22.5294 18.592 22.1478 18.75 21.75 18.75Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.5 9.375C7.42583 9.375 7.35333 9.39699 7.29166 9.4382C7.22999 9.4794 7.18193 9.53797 7.15355 9.60649C7.12516 9.67502 7.11774 9.75042 7.13221 9.82316C7.14668 9.8959 7.18239 9.96272 7.23484 10.0152C7.28728 10.0676 7.3541 10.1033 7.42684 10.1178C7.49958 10.1323 7.57498 10.1248 7.64351 10.0965C7.71203 10.0681 7.7706 10.02 7.8118 9.95834C7.85301 9.89667 7.875 9.82417 7.875 9.75C7.875 9.65054 7.83549 9.55516 7.76516 9.48483C7.69484 9.41451 7.59946 9.375 7.5 9.375Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 9.375C11.9258 9.375 11.8533 9.39699 11.7917 9.4382C11.73 9.4794 11.6819 9.53797 11.6535 9.60649C11.6252 9.67502 11.6177 9.75042 11.6322 9.82316C11.6467 9.8959 11.6824 9.96272 11.7348 10.0152C11.7873 10.0676 11.8541 10.1033 11.9268 10.1178C11.9996 10.1323 12.075 10.1248 12.1435 10.0965C12.212 10.0681 12.2706 10.02 12.3118 9.95834C12.353 9.89667 12.375 9.82417 12.375 9.75C12.375 9.65054 12.3355 9.55516 12.2652 9.48483C12.1948 9.41451 12.0995 9.375 12 9.375Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.5 9.375C16.4258 9.375 16.3533 9.39699 16.2917 9.4382C16.23 9.4794 16.1819 9.53797 16.1535 9.60649C16.1252 9.67502 16.1177 9.75042 16.1322 9.82316C16.1467 9.8959 16.1824 9.96272 16.2348 10.0152C16.2873 10.0676 16.3541 10.1033 16.4268 10.1178C16.4996 10.1323 16.575 10.1248 16.6435 10.0965C16.712 10.0681 16.7706 10.02 16.8118 9.95834C16.853 9.89667 16.875 9.82417 16.875 9.75C16.875 9.65054 16.8355 9.55516 16.7652 9.48483C16.6948 9.41451 16.5995 9.375 16.5 9.375Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
