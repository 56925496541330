import { ReactElement } from "react";
import { BrandedCard, ButtonPro, IndexedCreditCard } from "../../../generic";
import "./deliveryPreferencesPrompt.scss";
import { DeliveryPreferencesPromptProps } from "./deliveryPreferencesPromptModel";
import { DeliveryPreferencesPromptState } from "./deliveryPreferencesPromptViewModel";

type DeliveryPreferencesPromptViewProps = DeliveryPreferencesPromptProps &
    DeliveryPreferencesPromptState & {
        onCancel: Function;
        onSubmit: Function;
    };

export function DeliveryPreferencesPromptView(props: DeliveryPreferencesPromptViewProps): ReactElement {
    return (
        <BrandedCard id="deliveryPreferencesPrompt" size={props.size} header="Go Paperless!">
            <div className={`brandedCard-form${props.size}`}>
                <div className="deliveryPreferencesPrompt-body">
                    <p>
                        Electronic statements and documents are not only convenient and environmentally friendly, they
                        are secure. Avoid the risks associated with paper statement theft and sign up for electronic
                        statements today!
                    </p>
                    <div className="deliveryPreferencesPrompt-cards">
                        <div className="deliveryPreferencesPrompt-cards-container">
                            {props.accounts
                                .filter((x) => x.displayEStatementPrompt === true)
                                .map((x) => (
                                    <IndexedCreditCard key={x.accountHandle} account={x} size="h4" icon />
                                ))}
                        </div>
                    </div>
                </div>
                <div className="brandedCard-buttons">
                    <ButtonPro variant="secondary" content={"Not Now"} onClick={() => props.onCancel()} />
                    <ButtonPro content={"Yes, Go Paperless"} onClick={() => props.onSubmit()} />
                </div>
            </div>
        </BrandedCard>
    );
}
