import { ReactElement } from "react";
import { ExpandableRowContext } from "../../../contexts";
import PaymentHistoryView from "./paymentHistoryView";
import { usePaymentHistoryViewModel } from "./paymentHistoryViewModel";

export function PaymentHistoryModel(): ReactElement {
    const { context, state } = usePaymentHistoryViewModel();

    return (
        <ExpandableRowContext.Provider value={{ ...context }}>
            <PaymentHistoryView {...state} />
        </ExpandableRowContext.Provider>
    );
}
