import { useState } from "react";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";

export type LoadingState = {
    size: Size;
    showDelayMsg: boolean;
    setShowDelayMsg: React.Dispatch<React.SetStateAction<boolean>>;
};

const useLoadingState = (): LoadingState => {
    const { size } = useViewport(false, true);
    const [showDelayMsg, setShowDelayMsg] = useState(false);

    return {
        size,
        showDelayMsg,
        setShowDelayMsg
    };
};

export const useLoadingViewModel = () => {
    const state = useLoadingState();

    return {
        state
    };
};
