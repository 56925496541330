import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "../../icons";
import "./backArrow.scss";

type BackArrowProps = {
    available: boolean;
    to: string;
    children: ReactElement;
};

export function BackArrow(props: BackArrowProps): ReactElement {
    const navigate = useNavigate();

    return (
        <>
            <div className="backArrow">
                {props.available ? (
                    <>
                        <ArrowLeft className="backArrow-icon" onClick={() => navigate(props.to)} />
                    </>
                ) : (
                    <></>
                )}
                {props.children}
            </div>
        </>
    );
}
