import { LettersCms, useLoaderCms } from "../../../../api/backend";

export type DocumentsCMSData = {
    cms: LettersCms;
};

export async function DocumentsCMSLoader(): Promise<DocumentsCMSData> {
    const { Letters } = useLoaderCms();
    const cms = (await Letters()).data;

    return { cms };
}
