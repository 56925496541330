import { ReactElement } from "react";
import { getColor } from "./icon";
import { ColorBlockProps, getBrandedColorCode } from "./iconProps";

export function ColorBlocks_tr_c(props: ColorBlockProps): ReactElement {
    const lg = getColor("color", getBrandedColorCode(props.colors?.lg), "#60AADB");
    const md = getColor("color", getBrandedColorCode(props.colors?.md), "#F37232");
    const sm = getColor("color", getBrandedColorCode(props.colors?.sm), "#E4CB34");

    return (
        <svg
            id={props.id}
            className={props.className}
            width="89"
            height="74"
            viewBox="0 0 89 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="tr_c-svg"
        >
            <rect x="16.5%" y="79%" width="16%" height="20%" fill={sm} data-testid="tr_c-smRec" />
            <rect y="39%" width="33%" height="40%" fill={lg} data-testid="tr_c-lgRec" />
            <rect x="33%" width="66%" height="79%" fill={md} data-testid="tr_c-mdRec" />
        </svg>
    );
}
