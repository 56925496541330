import { ReactElement, useEffect, useState } from "react";
import { GenericModal, GenericModalProps, ModalProps } from "../../generic";

type AnnualFeeAlertProps = {
    bodyText: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export function AnnualFeeAlertModal(props: ModalProps & AnnualFeeAlertProps): ReactElement {
    let [state, setState] = useState({} as GenericModalProps);

    useEffect(() => {
        setState({
            headerText: "Annual Fee Notice",
            bodyText: props.bodyText,
            primaryButton: {
                text: "Close",
                action: () => props.setShow(false)
            }
        });
    }, []);

    useEffect(() => {
        setState({
            headerText: "Annual Fee Notice",
            bodyText: props.bodyText,
            primaryButton: {
                text: "Close",
                action: () => props.setShow(false)
            }
        });
    }, [props.bodyText]);

    return <GenericModal show={props.show} {...state} />;
}
