import { Form, FormikProps } from "formik";
import { ReactElement } from "react";
import { BrandedCard, ButtonPro } from "../../../generic";
import { InputPro } from "../../../generic/inputs/inputPro/inputPro";
import "./enterPaymentAccount.scss";
import { EnterPaymentAccountProps } from "./enterPaymentAccountModel";
import { EnterPaymentAccountFormFields, EnterPaymentAccountState } from "./enterPaymentAccountViewModel";

export type EnterPaymentAccountViewProps = EnterPaymentAccountProps &
    EnterPaymentAccountState &
    FormikProps<EnterPaymentAccountFormFields> & {
        validateRouting: Function;
        onChangeConfirm: Function;
        canContinue: boolean;
        onCancel: Function;
    };

export function EnterPaymentAccountView(props: EnterPaymentAccountViewProps): ReactElement {
    return (
        <BrandedCard
            size={props.size}
            id={`enterPaymentAccount${props.size}`}
            header="Add a Payment Account"
            subtitle={"Step 1 of 2"}
        >
            <Form className={`brandedCard-form${props.size}`} autoComplete="off">
                <InputPro
                    id="routingNumber"
                    type="text"
                    label="Routing number"
                    placeholder="Enter number"
                    mask={/^[0-9]{0,9}$/im}
                    inputMode="numeric"
                    stateValue={{
                        get: props.routingNumber,
                        set: props.setRoutingNumber
                    }}
                    onBlur={() => props.validateRouting()}
                    error={props.errors.routingNumber || props.routingError}
                    touched={props.touched.routingNumber ?? false}
                />

                <InputPro
                    id="checkingNumber"
                    label="Checking account number"
                    placeholder="Enter number"
                    mask={/^[0-9]{0,17}$/im}
                    stateValue={{
                        get: props.checkingNumber,
                        set: props.setCheckingNumber,
                        isPrivate: true,
                        canPeek: true
                    }}
                    inputMode="numeric"
                    error={props.errors.checkingNumber}
                    touched={props.touched.checkingNumber ?? false}
                />

                <InputPro
                    id="confirmNumber"
                    label="Verify checking account"
                    placeholder="Re-enter number"
                    mask={/^[0-9]{0,17}$/im}
                    stateValue={{
                        get: props.confirmNumber,
                        set: props.setConfirmNumber,
                        isPrivate: true,
                        canPeek: true
                    }}
                    inputMode="numeric"
                    onChange={() => props.onChangeConfirm()}
                    error={props.errors.confirmNumber}
                    touched={props.touched.confirmNumber ?? false}
                />

                <h6>All fields are required</h6>

                <div className={`brandedCard-buttons`}>
                    <ButtonPro type="button" variant="neutral" onClick={() => props.onCancel()}>
                        Cancel
                    </ButtonPro>
                    <ButtonPro type="submit" disabled={!props.canContinue}>
                        Next
                    </ButtonPro>
                </div>
            </Form>
        </BrandedCard>
    );
}

export default EnterPaymentAccountView;
