import { AxiosResponse } from "axios";
import { useContext, useEffect } from "react";
import { OnlineActionAccess, OnlineActions } from ".";
import { UseGet, useSessionStorage } from "../../../hooks";
import { EqualsIgnoreCase, IsNullOrEmpty } from "../../../utility";
import { useIdentityConnectContext } from "../../identityServer";
import { useAccountContext } from "../accountClient/useAccountClient";
import { DigitalActionsContext } from "./digitalActionsContext";

export const useDigitalActionsContext = () => {
    const context = useContext(DigitalActionsContext);
    const { accounts } = useAccountContext();
    const client = useDigitalActionsClient();
    const [storage, , removeValue] = useSessionStorage(["accountMenus"]);

    useEffect(() => {
        if (!IsNullOrEmpty(storage["accountMenus"])) {
            context.setDigitalActions(JSON.parse(storage.accountMenus));
        }
    }, []);

    function actionAllowed(option: string, accountHandle?: string) {
        if (accounts !== undefined) {
            let accountActions: OnlineActionAccess[] | undefined;

            let actions = context.digitalActions ?? (JSON.parse(storage.accountMenus) as OnlineActions[]) ?? [];

            if (accountHandle === undefined) {
                accountActions = actions.find(
                    (x) => x.access.find((y) => EqualsIgnoreCase(option, y.onlineActionName))?.isAllowed === true
                )?.access;
            } else {
                accountActions = actions.find((x) => EqualsIgnoreCase(accountHandle, x.accountHandle))?.access;
            }

            if (accountActions !== undefined) {
                let action = accountActions.find((x) => EqualsIgnoreCase(option, x.onlineActionName));

                return action?.isAllowed === true;
            }
        }
        return false;
    }

    const clear = () => {
        context.setDigitalActions(undefined);
        removeValue(["accountMenus"]);
    };

    return { ...context, ...client, clear, getOrder, getTitle, isService, actionAllowed };
};

const useDigitalActionsClient = () => {
    const context = useContext(DigitalActionsContext);
    const { access_token } = useIdentityConnectContext();
    const [, storeValue] = useSessionStorage(["accountMenus"]);

    async function Get(accountHandle: string, token?: string): Promise<AxiosResponse<OnlineActions[], any>> {
        context.setLoading(true);

        return UseGet<OnlineActions[]>(
            `api/v1/digitalActions?accountHandle=${accountHandle}`,
            token ?? access_token
        ).then(async (x) => {
            await context.setDigitalActions(x.data);
            await storeValue("accountMenus", JSON.stringify(x.data));
            context.setLoading(false);
            return x;
        });
    }

    return { Get };
};

const getOrder = (option: string): number => {
    switch (option?.trim().toLowerCase()) {
        case "creditlimitincrease":
            return 1;
        case "premiercreditprotection":
            return 2;
        case "requestauthorizeduser":
            return 3;
        case "uploaddocuments":
            return 4;
        case "eletterview":
            return 5;
        case "lockunlockcard":
            return 6;
        case "alerts":
            return 7;
        default:
            return 10;
    }
};

const getTitle = (option: string): string => {
    switch (option?.trim().toLowerCase()) {
        case "creditlimitincrease":
            return "Request Credit Limit Increase";
        case "premiercreditprotection":
            return "Go to PREMIER Credit Protection";
        case "requestauthorizeduser":
            return "Request Authorized User";
        case "uploaddocuments":
            return "Upload Documents";
        case "eletterview":
            return "View Documents";
        case "lockunlockcard":
            return "Lock Card";
        case "alerts":
            return "Manage Alerts";
        default:
            return option;
    }
};

const isService = (option: string): boolean => {
    return [
        "creditlimitincrease",
        "premiercreditprotection",
        "requestauthorizeduser",
        "uploaddocuments",
        "eletterview",
        "lockunlockcard",
        "alerts"
    ].includes(option?.trim().toLowerCase());
};
