import { ReactElement, useEffect } from "react";
import PaymentsSummaryView from "./paymentsSummaryView";
import { usePaymentsSummaryViewModel } from "./paymentsSummaryViewModel";

export function PaymentsSummaryModel(): ReactElement {
    const { state, api, hooks } = usePaymentsSummaryViewModel();

    useEffect(() => {
        if (state.account && !hooks.actionAllowed("payments", state.account.accountHandle)) {
            hooks.navigate(`/account/dashboard`, { relative: "path" });
        }

        if (state.accounts && state.accountIndex < state.accounts.length && state.urlId === state.accountIndex) {
            api.paymentsGet(state.accounts[state.accountIndex].accountHandle);
        }
    }, [state.accountIndex]);

    return <PaymentsSummaryView {...state} />;
}
