import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function PrivacyDoc(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="22"
            height="24"
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.75 21.75C20.75 22.1478 20.592 22.5294 20.3107 22.8107C20.0294 23.092 19.6478 23.25 19.25 23.25H2.75C2.35218 23.25 1.97064 23.092 1.68934 22.8107C1.40804 22.5294 1.25 22.1478 1.25 21.75V2.25C1.25 1.85218 1.40804 1.47064 1.68934 1.18934C1.97064 0.908035 2.35218 0.75 2.75 0.75H13.379C13.7765 0.750085 14.1578 0.907982 14.439 1.189L20.311 7.061C20.592 7.3422 20.7499 7.72345 20.75 8.121V21.75Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.75 8.25H14.75C14.3522 8.25 13.9706 8.09196 13.6893 7.81066C13.408 7.52936 13.25 7.14782 13.25 6.75V0.75"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.669 9.60791L6.537 12.4499C6.4725 12.5358 6.39031 12.6068 6.29598 12.6582C6.20165 12.7096 6.09739 12.7401 5.99025 12.7477C5.88311 12.7552 5.77559 12.7397 5.67496 12.7022C5.57434 12.6646 5.48296 12.6059 5.407 12.5299L4.25 11.3739"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.669 16.3579L6.537 19.1999C6.4725 19.2858 6.39031 19.3568 6.29598 19.4082C6.20165 19.4596 6.09739 19.4901 5.99025 19.4977C5.88311 19.5052 5.77559 19.4897 5.67496 19.4522C5.57434 19.4146 5.48296 19.3559 5.407 19.2799L4.25 18.1229"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M11.75 12.75H16.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.75 18.75H16.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
