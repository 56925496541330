import { useViewport } from "../../../hooks";
import { Size } from "../../../types";

type NewPasswordSplashViewModel = {
    state: NewPasswordSplashState;
};

export type NewPasswordSplashState = {
    size: Size;
};

export function useNewPasswordSplashViewModel(): NewPasswordSplashViewModel {
    const { size } = useViewport(true, true);

    return {
        state: {
            size
        }
    };
}
