import { ReactElement, useMemo, useState } from "react";
import { PaymentResponse, usePaymentContext } from "../../../api/backend";
import { PaymentScheduledRow } from "./paymentScheduledRow/paymentScheduledRow";

type PaymentScheduledContext = {
    curIndex: number | undefined;
    setCurIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export type PaymentsScheduledState = {
    loading: boolean;
    seeMore: boolean;
    setSeeMore: React.Dispatch<React.SetStateAction<boolean>>;
    scheduledPayments: PaymentResponse[];
    rows: ReactElement[];
    showCancel: boolean;
    setShowCancel: React.Dispatch<React.SetStateAction<boolean>>;
    showSuccess: boolean;
    setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    cancelPayment: PaymentResponse | undefined;
    setCancelPayment: React.Dispatch<React.SetStateAction<PaymentResponse | undefined>>;
};

const usePaymentScheduledContext = (): PaymentScheduledContext => {
    const [curIndex, setCurIndex] = useState(undefined as undefined | number);

    return {
        curIndex,
        setCurIndex
    };
};

const usePaymentsScheduledState = (): PaymentsScheduledState => {
    const { scheduled: scheduledPayments, loading } = usePaymentContext();
    const [seeMore, setSeeMore] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [cancelPayment, setCancelPayment] = useState(undefined as undefined | PaymentResponse);

    const displayedScheduled = useMemo(() => {
        return seeMore ? scheduledPayments : scheduledPayments.slice(0, 3);
    }, [scheduledPayments, seeMore]);

    const rows = useMemo(() => {
        return displayedScheduled.map((v, i) => (
            <PaymentScheduledRow key={v.paymentID.toString()} index={i} payment={v} cancelPayment={onCancelPayment} />
        ));
    }, [displayedScheduled]);

    function onCancelPayment(payment: PaymentResponse) {
        if (cancelPayment?.paymentID === payment.paymentID) {
            setShowCancel(true);
        } else {
            setCancelPayment(payment);
            setShowCancel(true);
        }
    }

    return {
        loading,
        seeMore,
        setSeeMore,
        scheduledPayments,
        rows,
        showCancel,
        setShowCancel,
        showSuccess,
        setShowSuccess,
        cancelPayment,
        setCancelPayment
    };
};

export const usePaymentScheduledViewModel = () => {
    const context = usePaymentScheduledContext();
    const state = usePaymentsScheduledState();

    return {
        context,
        state
    };
};
