import { ReactElement } from "react";
import { RequestAuthUserForm } from ".";
import { GenericModal } from "../../generic";
import { RequestAuthUserState } from "./requestAuthUserViewModel";

type RequestAuthUserViewProps = RequestAuthUserState;

function RequestAuthUserView(props: RequestAuthUserViewProps): ReactElement {
    return (
        <>
            <RequestAuthUserForm setShowSubmitted={props.setShowSubmitted} setShowError={props.setShowError} />

            <GenericModal show={props.showSubmitted} {...props.submittedProps} />

            <GenericModal show={props.showError} {...props.errorProps} />
        </>
    );
}

export default RequestAuthUserView;
