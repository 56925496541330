import { ReactElement } from "react";
import { IndexedCreditCard } from "../../..";
import { UserAccount } from "../../../../api/backend";
import { Size } from "../../../../types";
import "./cardSummaryHeader.scss";

export type CardSummaryHeaderViewProps = {
    size: Size;
    account: UserAccount;
};

export function CardSummaryHeaderView(props: CardSummaryHeaderViewProps): ReactElement {
    return (
        <>
            <div id={`cardSummaryHeader${props.size}`} className="cardTilePadding">
                <IndexedCreditCard account={props.account} size="h4" icon />
            </div>
        </>
    );
}
