import { ReactElement } from "react";
import { CardApplicationProps } from "../../cardApplication/cardApplication";
import { CardProgramFeeView } from "./cardProgramFeeView";
import { useCardProgramFeeViewModel } from "./cardProgramFeeViewModel";

export function CardProgramFee(props: CardApplicationProps): ReactElement {
    const { state } = useCardProgramFeeViewModel();

    function onPay() {
        window.open(props.account.applicationPaymentUrl!);
    }

    return <CardProgramFeeView {...props} {...state} onPay={onPay} />;
}
