import { ReactElement } from "react";
import { ApiQueueContext, useApiQueueContextProps } from "../../contexts";
import { Children } from "../../types";
import { AccountContext, useAccountContextProps } from "./accountClient/accountContext";
import {
    DeliveryPreferencesContext,
    useDeliveryPreferencesProps
} from "./deliveryPreferencesClient/deliveryPreferencesContext";
import { DigitalActionsContext, useDigitalActionsContextProps } from "./digitalActionsClient/digitalActionsContext";
import { DocUploadContext, useDocUploadContextProps } from "./docUploadClient/docUploadContext";
import {
    KnowledgeManagementContext,
    useKnowledgeManagementContextProps
} from "./knowledgeManagementClient/knowledgeManagementContext";
import { PaymentAccountContext, usePaymentAccountContextProps } from "./paymentAccountClient/paymentAccountContext";
import { PaymentContext, usePaymentContextProps } from "./paymentClient/paymentContext";
import { SettingsContext, useSettingsContextProps } from "./settingsClient/settingsContext";

export function Backend(props: Children): ReactElement {
    const apiQueueProps = useApiQueueContextProps();
    const settingsProps = useSettingsContextProps();
    const accountProps = useAccountContextProps();
    const digitalActionsProps = useDigitalActionsContextProps();
    const paymentProps = usePaymentContextProps();
    const paymentAccountProps = usePaymentAccountContextProps();
    const deliveryPreferencesProps = useDeliveryPreferencesProps();
    const docUploadProps = useDocUploadContextProps();
    const verintProps = useKnowledgeManagementContextProps();

    return (
        <>
            <ApiQueueContext.Provider value={apiQueueProps}>
                <SettingsContext.Provider value={settingsProps}>
                    <AccountContext.Provider value={accountProps}>
                        <DigitalActionsContext.Provider value={digitalActionsProps}>
                            <PaymentContext.Provider value={paymentProps}>
                                <PaymentAccountContext.Provider value={paymentAccountProps}>
                                    <DeliveryPreferencesContext.Provider value={deliveryPreferencesProps}>
                                        <DocUploadContext.Provider value={docUploadProps}>
                                            <KnowledgeManagementContext.Provider value={verintProps}>
                                                {props.children}
                                            </KnowledgeManagementContext.Provider>
                                        </DocUploadContext.Provider>
                                    </DeliveryPreferencesContext.Provider>
                                </PaymentAccountContext.Provider>
                            </PaymentContext.Provider>
                        </DigitalActionsContext.Provider>
                    </AccountContext.Provider>
                </SettingsContext.Provider>
            </ApiQueueContext.Provider>
        </>
    );
}
