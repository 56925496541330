import { AxiosError } from "axios";
import dayjs, { Dayjs } from "dayjs";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { ReactElement } from "react";
import { ValidationErrors } from "../../../../api/backend";
import { Modal, ModalProps } from "../../../generic";
import { AmountSelection } from "../schedulePayment/paymentAmountOption/paymentAmountOption";
import { ReviewPaymentForm } from "./reviewPaymentForm";
import { useReviewPaymentViewModel } from "./reviewPaymentViewModel";

export type ReviewPaymentProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowSchedule: React.Dispatch<React.SetStateAction<boolean>>;
    setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    paymentAccountId: number;
    setPaymentAccountId: React.Dispatch<React.SetStateAction<number>>;
    setAmountSelection: React.Dispatch<React.SetStateAction<AmountSelection>>;
    amount: string;
    setAmount: React.Dispatch<React.SetStateAction<string>>;
    date: Dayjs | undefined;
    setDate: React.Dispatch<React.SetStateAction<Dayjs | undefined>>;
    setSuccessId: React.Dispatch<React.SetStateAction<number>>;
};

export function ReviewPaymentModal(props: ModalProps & ReviewPaymentProps): ReactElement {
    return (
        <Modal show={props.show}>
            <ReviewPaymentModel {...props} />
        </Modal>
    );
}

export function ReviewPaymentModel(props: ReviewPaymentProps): ReactElement {
    const vm = useReviewPaymentViewModel(props);

    function onSubmit(actions: FormikHelpers<{}>) {
        const now = dayjs.tz();

        if (now.isSame(props.date, "date") && now.hour() >= 17) {
            vm.state.setError("Payments made after 5:00 p.m. CT must be dated for the following day.");
            vm.state.setCanContinue(false);
            actions.setSubmitting(false);
            return;
        }

        vm.api
            .paymentPost(vm.state.account, vm.state.paymentAccount!, props.amount, props.date!)
            .then((res) => {
                props.setSuccessId(res.data.paymentID);
                props.setAmountSelection("");
                props.setAmount("");
                props.setDate(undefined);
                props.setShowSuccess(true);
                props.setShow(false);
            })
            .catch((err: AxiosError) => {
                if (err.response?.status === 400) {
                    let errors = err.response?.data as ValidationErrors;
                    let errorField = Object.keys(errors.errors)[0];
                    vm.state.setError(errors.errors[errorField][0]);
                    vm.state.setCanContinue(false);
                } else if (err.response?.status === 401) {
                    vm.hooks.clearIdentity("access");
                    props.setShow(false);
                    vm.hooks.navigate("/auth/login");
                }
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    return (
        <Formik
            initialValues={{}}
            onSubmit={(_, actions) => {
                onSubmit(actions);
            }}
        >
            {(formProps: FormikProps<{}>) => <ReviewPaymentForm props={props} vm={vm} formProps={formProps} />}
        </Formik>
    );
}
