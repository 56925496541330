import { ReactElement } from "react";
import { AddPaymentAccountSuccess, EnterPaymentAccount, ReviewPaymentAccount } from ".";
import { AddPaymentAccountProps } from "./addPaymentAccountModel";
import { AddPaymentAccountState } from "./addPaymentAccountViewModel";

type AddPaymentAccountViewProps = AddPaymentAccountProps & AddPaymentAccountState;

export function AddPaymentAccountView(props: AddPaymentAccountViewProps): ReactElement {
    return (
        <>
            {props.showEnter || props.showReview || props.showSuccess ? (
                <>
                    <EnterPaymentAccount {...props} show={props.showEnter} setShow={props.setShowEnter} />

                    <ReviewPaymentAccount {...props} show={props.showReview} setShow={props.setShowReview} />

                    <AddPaymentAccountSuccess {...props} show={props.showSuccess} setShow={props.setShowSuccess} />
                </>
            ) : (
                <></>
            )}
        </>
    );
}

export default AddPaymentAccountView;
