import { AxiosResponse } from "axios";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
    BankAccountGetResponse,
    PaymentCms,
    PaymentPostRequest,
    PaymentResponse,
    UserAccount,
    useAccountContext,
    usePaymentAccountContext,
    usePaymentContext
} from "../../../../api/backend";
import { ClearIdentityOptions, useIdentityConnectContext } from "../../../../api/identityServer";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { FormatMoney } from "../../../../utility";
import { ReviewPaymentProps } from "./reviewPaymentModel";
const tz = "America/Chicago";
dayjs.tz.setDefault(tz);

export type ReviewPaymentViewModel = {
    hooks: ReviewPaymentHooks;
    state: ReviewPaymentState;
    api: ReviewPaymentApi;
};

type ReviewPaymentHooks = {
    navigate: NavigateFunction;
    clearIdentity: (option: ClearIdentityOptions) => void;
};

export type ReviewPaymentState = {
    size: Size;
    paymentCms: PaymentCms;
    account: UserAccount;
    paymentAccount: BankAccountGetResponse | undefined;
    acknowledgement: string;
    error: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    canContinue: boolean;
    setCanContinue: React.Dispatch<React.SetStateAction<boolean>>;
};

type ReviewPaymentApi = {
    paymentPost: (
        account: UserAccount,
        paymentAccount: BankAccountGetResponse,
        amount: string,
        date: Dayjs
    ) => Promise<AxiosResponse<PaymentResponse, any>>;
};

const useReviewPaymentState = (props: ReviewPaymentProps): ReviewPaymentState => {
    const { size } = useViewport(false, true);
    const { accounts, accountIndex } = useAccountContext();
    const { paymentAccounts } = usePaymentAccountContext();
    const { paymentCms } = usePaymentContext();
    const [error, setError] = useState("");
    const [canContinue, setCanContinue] = useState(true);

    const account = accounts[accountIndex];
    const paymentAccount = paymentAccounts.find((a) => a.bankAccountId === props.paymentAccountId);
    const now = dayjs.tz().format("M/DD/YYYY h:mm:ss A");
    const pd = props.date!.format("M/DD/YYYY");

    const acknowledgement = paymentCms.ConfirmPaymentBodyText.replaceAll("[[TODAYSDATETIME]]", now)
        .replaceAll("[[PAYMENTAMOUNT]]", FormatMoney(props.amount, false).formatted)
        .replaceAll("[[ROUTINGNUMBER]]", paymentAccount?.abaNumber ?? "")
        .replaceAll("[[CHECKINGACCOUNTNUMBER]]", paymentAccount?.maskedAccountNumber.slice(-4) ?? "")
        .replaceAll("[[LAST4]]", account.maskedAccountNumber.slice(-4))
        .replaceAll("[[PAYMENTDATE]]", pd);

    return {
        size,
        paymentCms,
        account,
        paymentAccount,
        acknowledgement,
        error,
        setError,
        canContinue,
        setCanContinue
    };
};

const useReviewPaymentHooks = (): ReviewPaymentHooks => {
    const navigate = useNavigate();
    const { clear: clearIdentity } = useIdentityConnectContext();

    return {
        navigate,
        clearIdentity
    };
};

const useReviewPaymentApi = (): ReviewPaymentApi => {
    const { Post } = usePaymentContext();

    async function paymentPost(
        account: UserAccount,
        paymentAccount: BankAccountGetResponse,
        amount: string,
        date: Dayjs
    ): Promise<AxiosResponse<PaymentResponse, any>> {
        let newPaymentDate = date.format("YYYY-MM-DD");
        let paymentDate = dayjs.tz(newPaymentDate).toDate();
        let request = {
            bankAccountId: paymentAccount.bankAccountId,
            paymentDate: paymentDate,
            paymentAmount: Number(amount)
        } as PaymentPostRequest;

        return await Post(account.accountHandle, request);
    }

    return {
        paymentPost
    };
};

export const useReviewPaymentViewModel = (props: ReviewPaymentProps): ReviewPaymentViewModel => {
    const state = useReviewPaymentState(props);
    const hooks = useReviewPaymentHooks();
    const api = useReviewPaymentApi();

    return {
        hooks,
        state,
        api
    };
};
