import { AxiosResponse } from "axios";
import { useContext, useEffect } from "react";
import { ConnectTokenResponse } from ".";
import { useIdentityPost, useLocalStorage, useSessionStorage } from "../../../hooks";
import { IsNullOrEmpty } from "../../../utility";
import { IdentityConnectContext } from "./identityConnectContext";

export type ClearIdentityOptions = "access" | "refresh" | "all";

export const useIdentityConnectContext = () => {
    const context = useContext(IdentityConnectContext);
    const client = useIdentityConnectClient();
    const [local, , removeLocal] = useLocalStorage(["refresh_token"]);
    const [session, , removeSession] = useSessionStorage(["access_token"]);

    useEffect(() => {
        if (!IsNullOrEmpty(session["access_token"])) {
            context.setAccessToken(session.access_token);
        }

        if (!IsNullOrEmpty(local["refresh_token"])) {
            context.setRefreshToken(local.refresh_token);
        }
    }, []);

    const clear = (option: ClearIdentityOptions) => {
        if (["access", "all"].includes(option)) {
            context.setAccessToken("");
            removeSession(["access_token"]);
        }

        if (["refresh", "all"].includes(option)) {
            context.setRefreshToken("");
            removeLocal(["refresh_token"]);
        }
    };

    return { ...context, ...client, clear, access_token: context.access_token ?? session.access_token };
};

const useIdentityConnectClient = () => {
    const context = useContext(IdentityConnectContext);
    const [, storeLocal] = useLocalStorage(["refresh_token"]);
    const [, storeSession] = useSessionStorage(["access_token"]);

    async function VerifyCode(
        onlineUserId: number,
        accountId: number,
        mfaCode: string
    ): Promise<AxiosResponse<ConnectTokenResponse, any>> {
        return await useIdentityPost<ConnectTokenResponse>(
            "/connect/token",
            `grant_type=mulecode&online_user_id=${onlineUserId}&account_id=${accountId}&code=${mfaCode}&platform="Web"`
        ).then((x) => {
            context.setAccessToken(x.data.access_token!);
            context.setRefreshToken(x.data.refresh_token!);
            storeSession("access_token", x.data.access_token);
            storeLocal("refresh_token", x.data.refresh_token);
            return x;
        });
    }

    async function RefreshToken(
        refreshToken: string,
        trustedLogin: boolean
    ): Promise<AxiosResponse<ConnectTokenResponse, any>> {
        return await useIdentityPost<ConnectTokenResponse>(
            "/connect/token",
            `grant_type=refresh_token&sessionEventType=${trustedLogin ? "LoginSuccess_TrustedDevice" : "LoginSuccess_SessionExtension"}&refresh_token=${refreshToken}`
        ).then((x) => {
            context.setAccessToken(x.data.access_token!);
            context.setRefreshToken(refreshToken);
            storeSession("access_token", x.data.access_token);
            storeLocal("refresh_token", refreshToken);
            return x;
        });
    }

    async function AuthorizeOtac(otac: string): Promise<AxiosResponse<ConnectTokenResponse, any>> {
        return await useIdentityPost<ConnectTokenResponse>("/connect/token", `grant_type=otac&otac=${otac}`).then(
            (x) => {
                context.setAccessToken(x.data.access_token!);
                context.setRefreshToken(x.data.refresh_token!);
                storeSession("access_token", x.data.access_token);
                storeLocal("refresh_token", x.data.refresh_token);
                return x;
            }
        );
    }

    return { VerifyCode, RefreshToken, AuthorizeOtac };
};
