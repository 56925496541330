import { ReactElement } from "react";
import { GenericModal, ModalProps } from "../../generic";

type DocUploadPrimeProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export function DocUploadPrime(props: ModalProps & DocUploadPrimeProps): ReactElement {
    const state = {
        headerText: "Service Not Available",
        bodyText: "Upload Documents is not available for your card type",
        primaryButton: {
            text: "Close",
            action: () => {
                props.setShow(false);
            }
        }
    };

    return <GenericModal show={props.show} {...state} />;
}
