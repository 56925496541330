import { ReactElement, useEffect } from "react";
import { SetTitle } from "../../../utility";
import { StatementDoc } from "../../generic/icons";
import { ButtonPro } from "../../generic/inputs/buttonPro/buttonPro";
import "./creditCardAgreement.scss";
import { CreditCardAgreementState } from "./creditCardAgreementViewModel";

type CreditCardAgreementView = CreditCardAgreementState;

export function CreditCardAgreementView(props: CreditCardAgreementView): ReactElement {
    useEffect(() => {
        SetTitle("Credit Card Agreements");

        return () => SetTitle("");
    }, []);

    return (
        <>
            {" "}
            <div className="creditCardAgreement-outer">
                <div className="creditCardAgreement-titleBox">
                    <h2 className="creditCardAgreement-title">{props.cms ? props.cms.PageTitle : ""}</h2>
                </div>
                <div className="creditCardAgreement">
                    <div className="creditCardAgreement-doc">
                        <span
                            className="creditCardAgreement-body"
                            dangerouslySetInnerHTML={{
                                __html: props.cms ? props.cms.BodyText : ""
                            }}
                        />
                        <h5 className="creditCardAgreement-minorTitle">Agreement PDFs</h5>
                        <ul className="creditCardAgreement-list">
                            {props.links?.map((data, index) => (
                                <li className="creditCardAgreement-listItem" key={index}>
                                    <a
                                        className="creditCardAgreement-listhref"
                                        href={data?.Url}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <ButtonPro
                                            className="creditCardAgreement-button"
                                            type="button"
                                            variant="tertiary"
                                            btnSize="sm"
                                        >
                                            <StatementDoc
                                                className="creditCardAgreement-statementIcon"
                                                svgClass="icon-size"
                                            />
                                            {data?.Name}
                                        </ButtonPro>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
