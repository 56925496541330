import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Disability(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="65"
            height="64"
            viewBox="0 0 65 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="32.0367" cy="32.0001" r="30.8001" stroke="#F37232" strokeWidth="2.4" />
            <path
                d="M34.7792 25.5456C37.6827 25.5456 40.0364 23.1918 40.0364 20.2884C40.0364 17.385 37.6827 15.0312 34.7792 15.0312C31.8758 15.0312 29.5221 17.385 29.5221 20.2884C29.5221 23.1918 31.8758 25.5456 34.7792 25.5456Z"
                stroke="#F37232"
                strokeWidth="2.62858"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.7792 25.5469V36.0612H42.6649C43.362 36.0612 44.0306 36.3381 44.5236 36.8311C45.0165 37.324 45.2935 37.9926 45.2935 38.6898V49.2041"
                stroke="#F37232"
                strokeWidth="2.62858"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.2078 30.8047C26.5471 30.8064 24.9178 31.2575 23.4928 32.1103C22.0678 32.9631 20.9001 34.1857 20.1138 35.6485C19.3275 37.1112 18.9517 38.7595 19.0264 40.4185C19.1011 42.0776 19.6235 43.6854 20.5381 45.0716C21.4527 46.4577 22.7255 47.5705 24.2214 48.2917C25.7173 49.013 27.3805 49.3159 29.0347 49.1683C30.6888 49.0207 32.2722 48.4282 33.6167 47.4535C34.9613 46.4788 36.017 45.1583 36.6718 43.6321"
                stroke="#F37232"
                strokeWidth="2.62858"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
