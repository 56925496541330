import { ReactElement } from "react";
import { useSettingsContext } from "../../../../api/backend";
import { CardActionsView } from "./cardActionsView";
import { useCardActionsViewModel } from "./cardActionsViewModel";

export type CardActionsProps = {
    lockStatus: boolean;
    lockLoading: boolean;
    index: number;
    showLockCardDisabledModal: boolean;
    setShowLockCardDisabledModal: React.Dispatch<React.SetStateAction<boolean>>;
    manageLockCard: Function;
};

export function CardActions(props: CardActionsProps): ReactElement {
    const { state, hooks } = useCardActionsViewModel(props);
    const { appSettings } = useSettingsContext();
    const isContextualManagementDown: boolean = !appSettings?.featureFlags?.contextualManagement;
    const isLockCardEnabled: boolean = appSettings?.featureFlags?.lockCardEnabled;

    const enrollPaperless = () => {
        hooks.navigate("/profile/delivery-options");
    };

    const ManagePaperlessSettings = () => {
        state.setAccountIndex(props.index);
        hooks.navigate("/profile/delivery-options");
    };

    const ManageAlerts = () => {
        state.setAccountIndex(props.index);
        isContextualManagementDown
            ? state.setShowManageAlertsUnavailable(true)
            : hooks.navigate(`/account/summary/${props.index}/manage-alerts`);
    };

    const addAuthUser = () => {};

    return (
        <CardActionsView
            {...props}
            {...state}
            enrollPaperless={enrollPaperless}
            addAuthUser={addAuthUser}
            managePaperlessSettings={ManagePaperlessSettings}
            manageAlerts={ManageAlerts}
        />
    );
}
