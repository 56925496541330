import {
    CreditCardAgreement,
    CurrentCreditCardOffersCms,
    useCreditCardAgreementsClient,
    useLoaderCms
} from "../../../api/backend";

export type CreditCardAgreementData = {
    cms: CurrentCreditCardOffersCms;
    links: CreditCardAgreement[];
};

export async function CreditCardAgreementLoader(): Promise<CreditCardAgreementData> {
    const { Get } = useCreditCardAgreementsClient();
    const { CurrentCreditCardOffers } = useLoaderCms();

    const links = (await Get()).data;
    const cms = (await CurrentCreditCardOffers()).data;

    return { cms, links };
}
