import { ReactElement } from "react";
import { AppController } from "./AppController";
import { Backend } from "./api/backend/backend";
import { Identity } from "./api/identityServer/identity";
import {
    AppDownloadBanner,
    CardNavContext,
    DeepLinkContext,
    MfaContextProvider,
    OneTrust,
    Swrve,
    ViewRestrictionContext,
    useCardNavContextProps,
    useDeepLinkContextProps,
    useViewRestrictionProps
} from "./contexts";
import { BrowserSupport } from "./contexts/browserSupportContext";
import { SessionTimer } from "./contexts/sessionTimeoutContext";
import { useTooltipCms } from "./hooks";

export function AppContext(): ReactElement {
    useTooltipCms();

    const deepLinkProps = useDeepLinkContextProps();
    const viewRestrictionProps = useViewRestrictionProps();
    const cardNavProps = useCardNavContextProps();

    return (
        <OneTrust>
            <Swrve>
                <AppDownloadBanner>
                    <MfaContextProvider>
                        <Identity>
                            <Backend>
                                <DeepLinkContext.Provider value={deepLinkProps}>
                                    <BrowserSupport>
                                        <SessionTimer>
                                            <ViewRestrictionContext.Provider value={viewRestrictionProps}>
                                                <CardNavContext.Provider value={cardNavProps}>
                                                    <AppController />
                                                </CardNavContext.Provider>
                                            </ViewRestrictionContext.Provider>
                                        </SessionTimer>
                                    </BrowserSupport>
                                </DeepLinkContext.Provider>
                            </Backend>
                        </Identity>
                    </MfaContextProvider>
                </AppDownloadBanner>
            </Swrve>
        </OneTrust>
    );
}
