import { ReactElement, useEffect } from "react";
import LockCardView from "./lockCardView";
import { useLockCardViewModel } from "./lockCardViewModel";

export function LockCardModel(): ReactElement {
    const { hooks, state } = useLockCardViewModel();

    useEffect(() => {
        if (!hooks.actionAllowed("lockUnlockCard", state.account.accountHandle)) {
            hooks.navigate(`/account/dashboard`, { relative: "path" });
        }
    }, [state.accountIndex]);

    function goToAccountHome() {
        hooks.navigate("/account/summary/" + state.index + "/home", { relative: "path" });
    }

    return (
        <LockCardView
            {...state}
            lockCard={hooks.lockCard}
            unlockCard={hooks.unlockCard}
            goToAccountHome={goToAccountHome}
        />
    );
}
