import { ReactElement } from "react";
import { StatementDetails } from "../../../../api/backend";
import { isMobileIOs } from "../../../../utility/device";
import { StatementsRowView } from "./statementsRowView";
import { useStatementsRowViewModel } from "./statementsRowViewModel";

export type StatementsRowProps = {
    index: number;
    statement: StatementDetails;
};

export function StatementsRow(props: StatementsRowProps): ReactElement {
    const { state, api } = useStatementsRowViewModel(props);

    function downloadPDF() {
        if (!state.isEMessengerDown) {
            state.setLoading(true);
            api.getStatement(state.account.accountHandle, props.statement.statementId)
                .then((res) => {
                    var byteCharacters = atob(res.data.pdfStatement);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var file = new Blob([byteArray], { type: "application/pdf;base64" });
                    var fileURL = URL.createObjectURL(file);
                    const downloadLink = document.createElement("a");
                    let statementDate = new Date(props.statement.statementDate);
                    var month = statementDate.getMonth() + 1;
                    var day = statementDate.getDate();
                    var year = statementDate.getFullYear();
                    const fileName = `${year}-${month}-${day} Statement.pdf`;
                    downloadLink.href = fileURL;
                    downloadLink.download = fileName;
                    downloadLink.click();
                })
                .finally(() => {
                    state.setLoading(false);
                });
        }
    }

    function openPDF() {
        if (!state.isEMessengerDown) {
            if (isMobileIOs()) {
                downloadPDF();
            } else {
                state.setLoading(true);
                api.getStatement(state.account.accountHandle, props.statement.statementId)
                    .then((res) => {
                        var byteCharacters = atob(res.data.pdfStatement);
                        var byteNumbers = new Array(byteCharacters.length);
                        for (var i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        var byteArray = new Uint8Array(byteNumbers);
                        var file = new Blob([byteArray], { type: "application/pdf;base64" });
                        var fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    })
                    .finally(() => {
                        state.setLoading(false);
                    });
            }
        }
    }

    return <StatementsRowView {...props} {...state} downloadPDF={downloadPDF} openPDF={openPDF} />;
}
