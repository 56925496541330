import { ReactElement } from "react";
import { useSettingsContext } from "../../../../api/backend";
import { ButtonPro } from "../../../generic";
import { ArrowDown, ArrowUp } from "../../../generic/icons";
import "./documents.scss";
import { DocumentsState } from "./documentsViewModel";

export function DocumentsView(props: DocumentsState): ReactElement {
    const { appSettings } = useSettingsContext();
    const showDocuments: boolean = !appSettings?.featureFlags?.isEMessengerDown;
    const noRowsMessage: string = showDocuments
        ? "No documents available"
        : "Viewing documents is currently unavailable due to site maintenance, which may take up to 7 hours. Please check back later.";

    return (
        <div id="documents">
            <div className="documents-header">
                <h5>Documents</h5>
            </div>
            <div className="documents-list">
                {props.rows.length > 0 && showDocuments ? (
                    props.rows
                ) : (
                    <div className="documents-noList">
                        <h5>{noRowsMessage}</h5>
                    </div>
                )}
            </div>
            <div className="documents-footer">
                {props.history.length > 3 ? (
                    <ButtonPro
                        variant="neutral"
                        className="documents-footer-more"
                        unblock
                        onClick={() => props.setSeeMore(!props.seeMore)}
                    >
                        <h6>View {!props.seeMore ? "More" : "Less"}</h6>
                        {!props.seeMore ? <ArrowDown /> : <ArrowUp />}
                    </ButtonPro>
                ) : (
                    <p></p>
                )}
                <p>
                    {props.history.length} result{props.history.length !== 1 ? "s" : ""}
                </p>
            </div>
        </div>
    );
}

export default DocumentsView;
