export const ShowPastDueAlert = (
    pastDue: boolean | undefined,
    accountStatus: string | undefined,
    seeStatement: boolean | undefined
): boolean => {
    if (seeStatement || accountStatus === "R" || accountStatus === "Z") {
        return false;
    }

    if (pastDue) {
        return true;
    } else {
        return false;
    }
};
