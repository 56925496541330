import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Passcode(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.75 12.75H2.25C1.85218 12.75 1.47064 12.592 1.18934 12.3107C0.908035 12.0294 0.75 11.6478 0.75 11.25V2.25C0.75 1.85218 0.908035 1.47064 1.18934 1.18934C1.47064 0.908035 1.85218 0.75 2.25 0.75H20.25C20.6478 0.75 21.0294 0.908035 21.3107 1.18934C21.592 1.47064 21.75 1.85218 21.75 2.25V8.25"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.25 6.375C5.32417 6.375 5.39667 6.39699 5.45834 6.4382C5.52001 6.47941 5.56807 6.53797 5.59646 6.6065C5.62484 6.67502 5.63227 6.75042 5.6178 6.82316C5.60333 6.8959 5.56761 6.96272 5.51517 7.01517C5.46272 7.06761 5.3959 7.10333 5.32316 7.1178C5.25042 7.13227 5.17502 7.12484 5.1065 7.09646C5.03797 7.06807 4.97941 7.02001 4.9382 6.95834C4.89699 6.89667 4.875 6.82417 4.875 6.75C4.87487 6.70072 4.88448 6.6519 4.90328 6.60634C4.92208 6.56078 4.94969 6.51939 4.98454 6.48454C5.01939 6.44969 5.06078 6.42208 5.10634 6.40328C5.1519 6.38448 5.20072 6.37487 5.25 6.375"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.25 6.375C11.3242 6.375 11.3967 6.39699 11.4583 6.4382C11.52 6.47941 11.5681 6.53797 11.5965 6.6065C11.6248 6.67502 11.6323 6.75042 11.6178 6.82316C11.6033 6.8959 11.5676 6.96272 11.5152 7.01517C11.4627 7.06761 11.3959 7.10333 11.3232 7.1178C11.2504 7.13227 11.175 7.12484 11.1065 7.09646C11.038 7.06807 10.9794 7.02001 10.9382 6.95834C10.897 6.89667 10.875 6.82417 10.875 6.75C10.8749 6.70072 10.8845 6.6519 10.9033 6.60634C10.9221 6.56078 10.9497 6.51939 10.9845 6.48454C11.0194 6.44969 11.0608 6.42208 11.1063 6.40328C11.1519 6.38448 11.2007 6.37487 11.25 6.375"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.25 6.375C17.3242 6.375 17.3967 6.39699 17.4583 6.4382C17.52 6.47941 17.5681 6.53797 17.5965 6.6065C17.6248 6.67502 17.6323 6.75042 17.6178 6.82316C17.6033 6.8959 17.5676 6.96272 17.5152 7.01517C17.4627 7.06761 17.3959 7.10333 17.3232 7.1178C17.2504 7.13227 17.175 7.12484 17.1065 7.09646C17.038 7.06807 16.9794 7.02001 16.9382 6.95834C16.897 6.89667 16.875 6.82417 16.875 6.75C16.8749 6.70072 16.8845 6.6519 16.9033 6.60634C16.9221 6.56078 16.9497 6.51939 16.9845 6.48454C17.0194 6.44969 17.0608 6.42208 17.1063 6.40328C17.1519 6.38448 17.2007 6.37487 17.25 6.375"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.25 15.75H23.25V23.25H14.25V15.75Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.75 11.25C17.9544 11.25 17.1913 11.5661 16.6287 12.1287C16.0661 12.6913 15.75 13.4544 15.75 14.25V15.75H21.75V14.25C21.75 13.4544 21.4339 12.6913 20.8713 12.1287C20.3087 11.5661 19.5456 11.25 18.75 11.25Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.75 19.1541C18.8242 19.1541 18.8967 19.176 18.9583 19.2173C19.02 19.2585 19.0681 19.317 19.0965 19.3855C19.1248 19.4541 19.1323 19.5295 19.1178 19.6022C19.1033 19.675 19.0676 19.7418 19.0152 19.7942C18.9627 19.8467 18.8959 19.8824 18.8232 19.8968C18.7504 19.9113 18.675 19.9039 18.6065 19.8755C18.538 19.8471 18.4794 19.7991 18.4382 19.7374C18.397 19.6757 18.375 19.6032 18.375 19.5291C18.375 19.4296 18.4145 19.3342 18.4848 19.2639C18.5552 19.1936 18.6505 19.1541 18.75 19.1541"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
