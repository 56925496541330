import { ReactElement, useEffect, useState } from "react";
import { useSettingsContext } from "../../../../api/backend";
import { AsyncElement, ButtonPro } from "../../../generic";
import { Lock, PaperlessNotEnrolled, Unlock } from "../../../generic/icons";
import { ManageAlerts } from "../../../generic/icons/manageAlerts";
import "../../cardSummary/cardSummaryCommon.scss";
import { ManageAlertsUnavailable } from "../../modals/manageAlertsUnavailable";
import "./cardActions.scss";
import { CardActionsProps } from "./cardActionsModel";
import { CardActionsState } from "./cardActionsViewModel";

export type CardActionsViewProps = CardActionsProps &
    CardActionsState & {
        enrollPaperless: Function;
        addAuthUser: Function;
        managePaperlessSettings: Function;
        manageAlerts: Function;
    };

export function CardActionsView(props: CardActionsViewProps): ReactElement {
    const { appSettings, loading: settingsLoading } = useSettingsContext();
    const isEMessengerDown: boolean = appSettings?.featureFlags?.isEMessengerDown;
    const [showPaperlessAlert, setShowPaperlessAlert] = useState(false);

    useEffect(() => {
        try {
            var sp =
                !isEMessengerDown &&
                props.preferences !== undefined &&
                (props.preferences.find((x) => x.accountHandle === props.accounts![props.index].accountHandle)
                    ?.eStatementsEnrollment?.changesAllowed ??
                    false) &&
                props.preferences.find((x) => x.accountHandle === props.accounts![props.index].accountHandle)
                    ?.eStatementsEnrollment?.enrollmentStatus === "NotEnrolled";
            setShowPaperlessAlert(sp);
        } catch (error) {
            console.log(error);
        }
    }, [props.preferences, appSettings]);

    return (
        <div className={`cardSummaryCommon-actions${props.size} cardTilePadding`}>
            <div className="cardSummaryCommon-card">
                <div className="cardSummaryCommon-header-row">
                    <h2 className="cardSummaryCommon-header">Quick Actions</h2>
                </div>
                <div className="cardSummaryCommon-content-col1">
                    <div id={`cardSummaryTile-one-column${props.size}`}>
                        <div id={`cardSummaryTile-two-column${props.size}`}>
                            {props.allowPaperless ? (
                                <div className="cardSummaryCommon-content-row">
                                    {isEMessengerDown ? (
                                        <PaperlessQuickAction
                                            showEnroll={false}
                                            onClick={props.managePaperlessSettings}
                                        />
                                    ) : (
                                        <AsyncElement loading={settingsLoading || props.loading}>
                                            {showPaperlessAlert ? (
                                                <PaperlessQuickAction
                                                    showEnroll={true}
                                                    onClick={props.enrollPaperless}
                                                />
                                            ) : (
                                                <PaperlessQuickAction
                                                    showEnroll={false}
                                                    onClick={props.managePaperlessSettings}
                                                />
                                            )}
                                        </AsyncElement>
                                    )}
                                </div>
                            ) : (
                                <></>
                            )}
                            {props.allowLockCard ? (
                                <div className="cardSummaryCommon-content-row">
                                    <AsyncElement loading={props.lockLoading}>
                                        {props.lockStatus ? (
                                            <ButtonPro
                                                className="cardSummaryCommon-button"
                                                variant="tertiary"
                                                onClick={() => props.manageLockCard()}
                                            >
                                                <div className="cardSummaryCommon-button-icon-div">
                                                    <Unlock className="cardSummaryCommon-icon" />
                                                </div>
                                                <div className="cardSummaryCommon-text">Unlock My Card</div>
                                            </ButtonPro>
                                        ) : (
                                            <ButtonPro
                                                className="cardSummaryCommon-button"
                                                variant="tertiary"
                                                onClick={() => props.manageLockCard()}
                                            >
                                                <div className="cardSummaryCommon-button-icon-div">
                                                    <Lock className="cardSummaryCommon-icon" />
                                                </div>
                                                <div className="cardSummaryCommon-text">Lock My Card</div>
                                            </ButtonPro>
                                        )}
                                    </AsyncElement>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className={`cardSummaryTile-second-column${props.size}`}>
                            {props.allowAlerts ? (
                                <div className="cardSummaryCommon-content-row">
                                    <ButtonPro
                                        className="cardSummaryCommon-button"
                                        variant="tertiary"
                                        onClick={() => props.manageAlerts()}
                                    >
                                        <div className="cardSummaryCommon-button-icon-div">
                                            <ManageAlerts className="cardSummaryCommon-icon" />
                                        </div>
                                        <div className="cardSummaryCommon-text">Manage My Alerts</div>
                                    </ButtonPro>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ManageAlertsUnavailable
                show={props.showManageAlertsUnavailable}
                setShow={props.setShowManageAlertsUnavailable}
            />
        </div>
    );
}

export function PaperlessQuickAction(props: { showEnroll: boolean; onClick: Function }) {
    const labelName = props.showEnroll ? "Enroll in Paperless" : "View Paperless Settings";
    return (
        <ButtonPro className="cardSummaryCommon-button" variant="tertiary" onClick={() => props.onClick()}>
            <div className="cardSummaryCommon-button-icon-div">
                <PaperlessNotEnrolled className="cardSummaryCommon-icon" />
            </div>
            <div className="cardSummaryCommon-text">{labelName}</div>
        </ButtonPro>
    );
}
