import dayjs from "dayjs";
import { ReactElement, useEffect } from "react";
import StatementsView from "./statementsView";
import { useStatementsViewModel } from "./statementsViewModel";

export function StatementsModel(): ReactElement {
    const { state, api } = useStatementsViewModel();

    useEffect(() => {
        if (!state.isEMessengerDown) {
            state.setLoading(true);
            api.Get(state.account.accountHandle)
                .then((res) => {
                    state.setStatements(res.data.sort((a, b) => dayjs(b.statementDate).diff(a.statementDate)));
                })
                .finally(() => {
                    state.setLoading(false);
                });
        }
    }, [state.accountIndex]);

    return <StatementsView {...state} />;
}
