import { ReactElement } from "react";
import { SeeStatementMessageAlert } from "../../../../common/seeStatementMessageAlert/seeStatementMessageAlert";
import { ToolTip } from "../../../../generic";
import "./minAmountDue.scss";
import { MinAmountDueState } from "./minAmountDueViewModel";

export function MinAmountDueView(props: MinAmountDueState): ReactElement {
    return (
        <div className={`paymentAmount${props.size}`} id="paymentAmount-MinAmountDue">
            <div className="paymentAmount-header">
                <h5>Total Minimum Amount Due</h5>
                <ToolTip>{props.cms.BodyText}</ToolTip>
            </div>
            {props.account?.seeStatementMessage ? (
                <div className="paymentHub-info-container">
                    <SeeStatementMessageAlert />
                </div>
            ) : (
                <>
                    <div className="paymentAmount-amount">
                        <h2 className={`paymentAmount-dollar-${props.account?.accent}`}>${props.dollars}</h2>
                        <h3 className={`paymentAmount-cents-${props.account?.accent}`}>{props.cents}</h3>
                    </div>
                </>
            )}
        </div>
    );
}
