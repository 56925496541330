import { AxiosResponse } from "axios";
import { useState } from "react";
import { BankAccountNameResponse, useBankAccountNameContext } from "../../../../api/backend";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";

export type EnterPaymentAccountViewModel = {
    state: EnterPaymentAccountState;
    api: EnterPaymentAccountApi;
};

export type EnterPaymentAccountFormFields = {
    routingNumber: string;
    checkingNumber: string;
    confirmNumber: string;
};

export type EnterPaymentAccountState = {
    size: Size;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    routingError: string;
    setRoutingError: React.Dispatch<React.SetStateAction<string>>;
    routingSent: string;
    setRoutingSent: React.Dispatch<React.SetStateAction<string>>;
};

type EnterPaymentAccountApi = {
    getBankName: (abaNumber: string) => Promise<AxiosResponse<BankAccountNameResponse, any>>;
};

const useEnterPaymentAccountState = (): EnterPaymentAccountState => {
    const { size } = useViewport(false, true);
    const [loading, setLoading] = useState(false);
    const [routingError, setRoutingError] = useState("");
    const [routingSent, setRoutingSent] = useState("");

    return {
        size,
        loading,
        setLoading,
        routingError,
        setRoutingError,
        routingSent,
        setRoutingSent
    };
};

const useEnterPaymentAccountApi = (): EnterPaymentAccountApi => {
    const { Get: getBankName } = useBankAccountNameContext();

    return {
        getBankName
    };
};

export const useEnterPaymentAccountViewModel = (): EnterPaymentAccountViewModel => {
    const state = useEnterPaymentAccountState();
    const api = useEnterPaymentAccountApi();

    return {
        state,
        api
    };
};
