import { useContext, useState } from "react";
import { AppDownloadBannerContext } from "../../../contexts";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { isIOS } from "../../../utility/device";

export type AppDownloadBannerState = {
    size: Size;
    showIntermediate: boolean;
    setShowIntermediate: React.Dispatch<React.SetStateAction<boolean>>;
    intermediateDest: string;
    setShowBanner: React.Dispatch<React.SetStateAction<boolean>>;
};

const useAppDownloadBannerState = (): AppDownloadBannerState => {
    const { size } = useViewport(false, true);
    const { setShowBanner } = useContext(AppDownloadBannerContext);
    const [showIntermediate, setShowIntermediate] = useState(false);
    const intermediateDest =
        isIOS() === true
            ? "https://apps.apple.com/us/app/premier-credit-card/id1476775662"
            : "https://play.google.com/store/apps/details?id=com.firstpremier.mypremiercreditcard.app";

    return {
        size,
        setShowBanner,
        showIntermediate,
        setShowIntermediate,
        intermediateDest
    };
};

export const useAppDownloadBannerViewModel = () => {
    const state = useAppDownloadBannerState();

    return {
        state
    };
};
