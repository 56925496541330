import { ReactElement } from "react";
import { Dropdown } from "react-bootstrap";
import { ButtonPro } from "../../../generic";
import { ArrowDown } from "../../../generic/icons";
import "./activityDateFilter.scss";

type ActivityDateFilterViewProps = {
    filter: number;
    items: ReactElement[];
};

export function ActivityDateFilterView(props: ActivityDateFilterViewProps): ReactElement {
    return (
        <Dropdown align="end" className="activityDateFilter">
            <Dropdown.Toggle as="div" className="activityDateFilter-toggle">
                <ButtonPro variant="neutral" className="activityDateFilter-button">
                    <p>{props.filter === 0 ? "Last 6 months" : props.filter}</p>
                    <ArrowDown />
                </ButtonPro>
            </Dropdown.Toggle>
            <Dropdown.Menu className="activityDateFilter-menu">{props.items}</Dropdown.Menu>
        </Dropdown>
    );
}
