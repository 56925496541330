import { ReactElement } from "react";
import { CancelEnrollSetup, CancelPasswordReset } from "../../..";
import { HelpLine } from "../../../../utility";
import { Info } from "../../../generic/icons";
import { ButtonPro } from "../../../generic/inputs/buttonPro/buttonPro";
import { BrandedCard } from "../../../generic/visuals/brandedCard/brandedCard";
import { LoginCard } from "../../common/loginCardModel";
import "./mfaAssistance.scss";
import { MfaAssistanceState } from "./mfaAssistanceViewModel";

type MfaAssistanceViewProps = MfaAssistanceState & {
    onCancel: Function;
    onNext: Function;
    noPhoneEmail: boolean | undefined;
};

function MfaAssistanceView(props: MfaAssistanceViewProps): ReactElement {
    const body = (
        <div className={`brandedCard-form${props.size}`}>
            <div id="mfaAssistance-contentPrompt">
                <p>{props.bodyText}</p>
            </div>

            <div id="mfaAssistance-contentInfo">
                <div id="mfaAssistance-infoNumber">
                    <h5>Call: {HelpLine(props.mfaOptions?.isCorpCard ?? false)}</h5>
                    <p>
                        A Customer Service Associate will provide you with a secure access code on the call. Please
                        enter this code on the next screen.
                    </p>
                </div>
                <div id="mfaAssistance-times">
                    <div id="mfaAssistance-days">
                        <h6>Monday to Friday:</h6>
                        <h6>Saturday:</h6>
                        <h6>Sunday:</h6>
                    </div>
                    {(props.mfaOptions?.isCorpCard ?? false) ? GetBusinessCommunicationsHours() : GetCustServiceHours()}
                </div>
                <div id="mfaAssistance-contentTip">
                    <Info id="mfaAssistance-tipIcon" />
                    <h6>Time displayed is in Central Time (CT)</h6>
                </div>
            </div>

            <div className="brandedCard-buttons">
                <ButtonPro id="mfaAssistance-cancel" variant="neutral" onClick={() => props.onCancel()}>
                    Cancel
                </ButtonPro>
                <ButtonPro id={`mfaAssistance-submit${props.size}`} onClick={() => props.onNext()}>
                    {props.size === "-mobile" ? "Enter Code" : "Go to Enter Code"}
                </ButtonPro>
            </div>

            {props.securityType === "Enrollment" ? (
                <CancelEnrollSetup show={props.showCancelModal} setShow={props.setShowCancelModal} />
            ) : props.securityType === "ForgotPassword" ? (
                <CancelPasswordReset show={props.showCancelModal} setShow={props.setShowCancelModal} />
            ) : (
                <></>
            )}
        </div>
    );
    return (
        <>
            {!props.noPhoneEmail ? (
                <BrandedCard id="mfaAssistance" size={props.size} header={props.headerText} subtitle={props.subtitle}>
                    {body}
                </BrandedCard>
            ) : (
                <LoginCard id={"mfaAssistance"} header={props.headerText} subtitle={props.subtitle} size={props.size}>
                    <div id="NoPhoneEmail">{body}</div>
                </LoginCard>
            )}
        </>
    );
}

function GetCustServiceHours(): ReactElement {
    return (
        <div id="mfaAssistance-hours">
            <h6>7:00 a.m. - 9:00 p.m.</h6>
            <h6>8:00 a.m. - 4:30 p.m.</h6>
            <h6>Closed</h6>
        </div>
    );
}

function GetBusinessCommunicationsHours(): ReactElement {
    return (
        <div id="mfaAssistance-hours">
            <h6>8:00 a.m. - 5:00 p.m.</h6>
            <h6>Closed</h6>
            <h6>Closed</h6>
        </div>
    );
}

export default MfaAssistanceView;
