import { useContext, useEffect, useState } from "react";
import { useLoaderData } from "react-router";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { PublicCCPAPageConfiguration } from "../../../../api/backend";
import { CardNavContext, useDeepLinkContext } from "../../../../contexts";
import { useIsLoggedIn, useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { CCPAHomeData } from "./ccpaHomeLoader";

type CCPAHomeViewModel = {
    hooks: CCPAHomeHooks;
    state: CCPAHomeState;
};

type CCPAHomeHooks = {
    navigate: NavigateFunction;
};

export type CCPAHomeState = {
    cms: PublicCCPAPageConfiguration;
    setDeepLink: (value: string) => void
    size: Size;
    loggedIn: boolean;
    isAnonymous: boolean;
    setIsAnonymous: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useCCPAHomeViewModel(): CCPAHomeViewModel {
    const navigate = useNavigate();
    const { setShowNav } = useContext(CardNavContext);
    const data = useLoaderData() as CCPAHomeData;
    const { size } = useViewport(true, true);
    const { loggedIn } = useIsLoggedIn();
    const [isAnonymous, setIsAnonymous] = useState(false);
    const { setDeepLink } = useDeepLinkContext();

    useEffect(() => {
        setShowNav(false);
    }, []);

    return {
        hooks: { navigate },
        state: {
            ...data,
            setDeepLink,
            size,
            loggedIn,
            isAnonymous,
            setIsAnonymous
        }
    };
}
