import { ReactElement } from "react";
import { DashboardCardProps } from "./dashboardCardModel";
import { DashboardCardState } from "./dashboardCardViewModel";
import { DashboardCardMainView } from "./desktop/dashboardCardMainView";
import { DashboardCardAltView } from "./responsive/dashboardCardAltView";

export type DashboardCardViewProps = DashboardCardProps &
    DashboardCardState & {
        onCardSelected: (index: number) => void;
    };

export function DashboardCardView(props: DashboardCardViewProps): ReactElement {
    return (
        <>
            {props.size === "" ? (
                <>
                    <DashboardCardMainView {...props} />
                </>
            ) : (
                <>
                    <DashboardCardAltView {...props} />
                </>
            )}
        </>
    );
}
