import { ReactElement, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { ButtonPro } from "../../../generic";
import { ArrowDown, Check } from "../../../generic/icons";
import "./paymentHistoryDateFilter.scss";

type PaymentHistoryDateFilterProps = {
    years: number[];
    filter: number;
    setFilter: React.Dispatch<React.SetStateAction<number>>;
};

export function PaymentHistoryDateFilter(props: PaymentHistoryDateFilterProps): ReactElement {
    const sixMonth = useMemo(
        () => (
            <Dropdown.Item
                data-testid={"paymentHistory-setFilter"}
                key="0"
                eventKey={0}
                className="paymentHistoryFilter-item"
                onClick={() => props.setFilter(0)}
            >
                <p>Last 6 months</p>
                {props.filter === 0 ? <Check /> : <></>}
            </Dropdown.Item>
        ),
        [props.filter]
    );

    const items = useMemo(() => {
        return ([sixMonth] as ReactElement[]).concat(
            props.years.map((v, i) => (
                <Dropdown.Item
                    key={v}
                    eventKey={i}
                    className="paymentHistoryFilter-item"
                    onClick={() => {
                        props.setFilter(v);
                    }}
                >
                    <p>{v}</p>
                    {props.filter === v ? <Check /> : <></>}
                </Dropdown.Item>
            ))
        );
    }, [props.filter, props.years]);

    return (
        <Dropdown align="end" className="paymentHistoryFilter">
            <Dropdown.Toggle as="div" className="paymentHistoryFilter-toggle">
                <ButtonPro variant="neutral" className="paymentHistoryFilter-button">
                    <p>{props.filter === 0 ? "Last 6 months" : props.filter}</p>
                    <ArrowDown />
                </ButtonPro>
            </Dropdown.Toggle>
            <Dropdown.Menu className="paymentHistoryFilter-menu">{items}</Dropdown.Menu>
        </Dropdown>
    );
}
