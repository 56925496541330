import { ReactElement, useEffect } from "react";
import { useErrorPageViewModel } from "./errorPageViewModel";
import ErrorPageView from "./errorPageView";

export type ErrorPageProps = {
    status: Number | undefined;
};

export function ErrorPageModel(props: ErrorPageProps): ReactElement {
    const { hooks, state } = useErrorPageViewModel(props);

    useEffect(() => {
        hooks.storeValue("access_token", "");
    }, []);

    function onContinue() {
        hooks.navigate("/");
    }

    return <ErrorPageView {...props} {...state} onContinue={onContinue} />;
}
