import { createContext, useContext } from "react";
import { NotImplemented } from "../utility";

export type ExpandableRowContextProps = {
    curIndex: undefined | number;
    setCurIndex: React.Dispatch<React.SetStateAction<undefined | number>>;
};

export const ExpandableRowContext = createContext<ExpandableRowContextProps>({
    curIndex: undefined,
    setCurIndex: NotImplemented
});

export const useExpandableRowContext = () => {
    const context = useContext(ExpandableRowContext);

    const newRowExpanded = (index: number) => {
        context.setCurIndex(index === context.curIndex ? undefined : index);
    };

    return {
        curIndex: context.curIndex,
        setCurIndex: context.setCurIndex,
        newRowExpanded
    };
};
