import { AxiosResponse } from "axios";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
    AppSettings,
    ContextualControlsStatusResponse,
    useCmsContext,
    useContextualMessagingContext,
    useSettingsContext
} from "../../../../api/backend";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { DashboardCardProps } from "./dashboardCardModel";

type DashboardCardViewModel = {
    hooks: DashbaordCardHooks;
    state: DashboardCardState;
    api: DashboardCardApi;
};

type DashbaordCardHooks = {
    navigate: NavigateFunction;
};

export type DashboardCardState = {
    size: Size;
    alert: string;
    setAlert: React.Dispatch<React.SetStateAction<string>>;
    showAlert: boolean;
    setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
    alertBody: string;
    showMoreAnnualFeeAlert: boolean;
    setShowMoreAnnualFeeAlert: React.Dispatch<React.SetStateAction<boolean>>;
    lockStatus: boolean;
    setLockStatus: React.Dispatch<React.SetStateAction<boolean>>;
    appSettings: AppSettings;
    alertCount: number;
    setAlertCount: React.Dispatch<React.SetStateAction<number>>;
    notification: boolean;
    setNotification: React.Dispatch<React.SetStateAction<boolean>>;
};

type DashboardCardApi = {
    accountSummaryCms: () => Promise<void>;
    getLockStatus: () => Promise<AxiosResponse<ContextualControlsStatusResponse, any>>;
};

export function useDashboardCardViewModel(props: DashboardCardProps): DashboardCardViewModel {
    const navigate = useNavigate();
    const { size } = useViewport(true, true);
    const { ControlsStatus } = useContextualMessagingContext();
    const { AccountSummary } = useCmsContext();
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState("");
    const [showMoreAnnualFeeAlert, setShowMoreAnnualFeeAlert] = useState(false);
    const [lockStatus, setLockStatus] = useState(false);
    const [alertBody, setAlertBody] = useState("");
    const { appSettings } = useSettingsContext();
    const [alertCount, setAlertCount] = useState(0);
    const [notification, setNotification] = useState(false);

    async function accountSummaryCms(): Promise<void> {
        await AccountSummary().then((res) => {
            setAlertBody(
                res.data.AnnualFeeWarningMessageText.replaceAll(
                    "[[ANNUALFEE]]",
                    props?.account?.annualCharge?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD"
                    })
                )
            );
        });
    }
    async function getLockStatus(): Promise<AxiosResponse<ContextualControlsStatusResponse, any>> {
        return await ControlsStatus(props.account.accountHandle);
    }

    return {
        hooks: {
            navigate
        },
        state: {
            size,
            alert,
            setAlert,
            showAlert,
            setShowAlert,
            alertBody,
            showMoreAnnualFeeAlert,
            setShowMoreAnnualFeeAlert,
            lockStatus,
            setLockStatus,
            appSettings,
            alertCount,
            setAlertCount,
            notification,
            setNotification
        },
        api: { accountSummaryCms, getLockStatus }
    };
}
