import { ReactElement, useEffect } from "react";
import { SetTitle } from "../../../utility";
import { ButtonPro } from "../../generic";
import "./disclosures.scss";
import { DisclosuresState } from "./disclosuresViewModel";

type DisclosuresViewProps = DisclosuresState & {
    navigatePrintPreview: Function;
};

export function DisclosuresView(props: DisclosuresViewProps): ReactElement {
    useEffect(() => {
        SetTitle(props.headerText);
        return () => SetTitle("");
    }, []);

    return (
        <div className={`disclosures${props.size} container background-primary-lit`}>
            <div className="disclosures-page-container">
                <div className="disclosures-header-section">
                    <div className="disclosures-header">{props.headerText}</div>
                </div>
                <div className="disclosures-body-container">
                    <div className="disclosures-body-header-section row d-flex">
                        <div>
                            <ButtonPro
                                className="disclosures-print-link"
                                content={"View Printable Page"}
                                variant="tertiary"
                                btnSize="sm"
                                onClick={() => props.navigatePrintPreview()}
                            />
                        </div>
                    </div>
                    <div className="disclosures-body-section">
                        <span className="disclosures-body-text" dangerouslySetInnerHTML={{ __html: props.bodyText }} />
                    </div>
                </div>
            </div>
        </div>
    );
}
