import { ReactElement, useEffect } from "react";
import { Error403View } from "./error403View";
import { useError403ViewModel } from "./error403ViewModel";

export function Error403Model(): ReactElement {
    const { hooks, state } = useError403ViewModel();

    useEffect(() => {
        if (state.location.pathname.toLowerCase() !== "/error403") {
            hooks.navigate("/Error403");
        }
    }, []);

    return <Error403View {...state} />;
}
