import { ReactElement } from "react";
import { PaymentsSummarySplashView } from "./paymentsSummarySplashView";
import { usePaymentsSummarySplashViewModel } from "./paymentsSummarySplashViewModel";

function PaymentsSummarySplash(): ReactElement {
    const { state } = usePaymentsSummarySplashViewModel();

    return <PaymentsSummarySplashView {...state} />;
}

export default PaymentsSummarySplash;
