import { createContext, useState } from "react";
import { NotImplemented } from "../utility";

export type ViewRestrictionContextProps = {
    restricted: boolean;
    setRestricted: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ViewRestrictionContext = createContext<ViewRestrictionContextProps>({
    restricted: true,
    setRestricted: NotImplemented
});

export function useViewRestrictionProps(): ViewRestrictionContextProps {
    const [restricted, setRestricted] = useState(true);

    return { restricted, setRestricted };
}
