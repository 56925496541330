import { useState } from "react";
import { ToggleProProps } from "./togglePro";

type ToggleProViewModel = {
    state: ToggleProState;
};

export type ToggleProState = {
    isToggled: boolean;
    setIsToggled: React.Dispatch<React.SetStateAction<boolean>>;
    labels: string[];
};

export function useToggleProViewModel(props: ToggleProProps): ToggleProViewModel {
    const defaultChecked = (props.checked ? props.checked : false) as boolean;
    const [isToggled, setIsToggled] = useState(defaultChecked);
    const labels = getLabels(props.variant);

    return {
        state: {
            isToggled,
            setIsToggled,
            labels
        }
    };
}

const getLabels = (variant?: "" | "neg" | "label" | "lock"): string[] => {
    switch (variant) {
        case "label":
            return ["On", "Off"];
        case "lock":
            return ["Unlock", "Lock"];
        default:
            return ["", ""];
    }
};
