import dayjs from "dayjs";
import { useState } from "react";
import { CalendarPro } from "../../generic/visuals/calendarPro/calendarPro";
import "./calendarTest.scss";

export function CalendarTest(): React.ReactElement {
    const [date, setDate] = useState(dayjs.tz());

    return (
        <div className="calendarTest">
            <CalendarPro
                className="calendarTest-obj"
                props={{
                    selectedDate: date,
                    onChange: setDate,
                    selectorDateFormat: "MMMM DD YYYY",
                    dueDate: dayjs.tz("2023 09 30"),
                    endDate: dayjs.tz().add(28, "day"),
                    onClose: () => {}
                }}
            />
        </div>
    );
}
