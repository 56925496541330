import { AxiosResponse } from "axios";
import { useState } from "react";
import {
    Statement,
    useAccountContext,
    UserAccount,
    useSettingsContext,
    useStatementsContext
} from "../../../../api/backend";
import { useExpandableRowContext } from "../../../../contexts";
import { StatementsRowProps } from "./statementsRow";

export type StatementsRowState = {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    expanded: boolean;
    setCurIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
    isEMessengerDown: boolean;
    account: UserAccount;
};

type StatementsRowApi = {
    getStatement: (accountHandle: string, statementId: string) => Promise<AxiosResponse<Statement, any>>;
};

const useStatementsRowState = (props: StatementsRowProps): StatementsRowState => {
    const { appSettings } = useSettingsContext();
    const { accounts, accountIndex } = useAccountContext();
    const [loading, setLoading] = useState(false);
    const { curIndex, setCurIndex } = useExpandableRowContext();
    const expanded = props.index === curIndex;
    const isEMessengerDown = appSettings?.featureFlags?.isEMessengerDown ?? true;
    const account = accounts[accountIndex];

    return {
        loading,
        setLoading,
        expanded,
        setCurIndex,
        isEMessengerDown,
        account
    };
};

const useStatementsRowApi = (): StatementsRowApi => {
    const { GetById: getStatement } = useStatementsContext();

    return {
        getStatement
    };
};

export const useStatementsRowViewModel = (props: StatementsRowProps) => {
    const state = useStatementsRowState(props);
    const api = useStatementsRowApi();

    return {
        state,
        api
    };
};
