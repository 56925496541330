import { ReactElement } from "react";
import { BrowserCompatibilityState } from "./browserCompatibilityViewModel";
import { ButtonPro } from "../../generic";
import "./browserCompatibility.scss";

type BrowserCompatibilityViewProps = BrowserCompatibilityState & {
    onContinue: Function;
};

function BrowserCompatibilityView(props: BrowserCompatibilityViewProps): ReactElement {
    return (
        <div className={`browserCompatibility${props.size}`}>
            <h2 className={`browserCompatibility-header${props.size}`}>{props.headerText}</h2>
            <h3>
                <span dangerouslySetInnerHTML={{ __html: props.bodyText }} />
            </h3>
            <ButtonPro className={`browserCompatibility-button${props.size}`} onClick={() => props.onContinue()}>
                Continue Anyway
            </ButtonPro>
        </div>
    );
}

export default BrowserCompatibilityView;
