import { createContext, useState } from "react";
import { OnlineActions } from ".";
import { NotImplemented } from "../../../utility";

export type DigitalActionsContextProps = {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    digitalActions: OnlineActions[] | undefined;
    setDigitalActions: React.Dispatch<React.SetStateAction<OnlineActions[] | undefined>>;
};

export const DigitalActionsContext = createContext<DigitalActionsContextProps>({
    loading: false,
    setLoading: NotImplemented,
    digitalActions: undefined,
    setDigitalActions: NotImplemented
});

export const useDigitalActionsContextProps = (): DigitalActionsContextProps => {
    const [loading, setLoading] = useState(false);
    const [digitalActions, setDigitalActions] = useState<OnlineActions[] | undefined>(undefined);

    return { loading, setLoading, digitalActions, setDigitalActions };
};
