import { ReactElement } from "react";
import BrowserCompatibilityView from "./browserCompatibilityView";
import { useBrowserCompatibilityViewModel } from "./browserCompatibilityViewModel";

export function BrowserCompatibilityModel(): ReactElement {
    const { hooks, state } = useBrowserCompatibilityViewModel();

    if (state.acknowledgement === true || (state.supported && state.current)) onContinue();

    function onContinue() {
        state.setAcknowledgement(true);
        hooks.storeValue("browserAcknowledgement", true);

        if (state.access_token) {
            hooks.navigate("/account/dashboard");
        } else {
            hooks.navigate("/auth/login");
        }
    }

    return <BrowserCompatibilityView {...state} onContinue={onContinue} />;
}
