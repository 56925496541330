import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function HeartBeat(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="65"
            height="64"
            viewBox="0 0 65 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="32.89" cy="31.9998" r="30.7998" stroke="#60AADB" strokeWidth="2.39999" />
            <path
                d="M16.1287 25.0456C17.4692 18.5794 25.4338 13.7165 33.004 22.3119C43.8074 9.93141 55.3468 25.2822 47.5136 33.1942L33.004 46.5735L24.5138 38.6878"
                stroke="#60AADB"
                strokeWidth="2.62856"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.9182 32.1165H22.4896L26.4324 26.8594L31.6896 36.0593L35.6324 30.8022H39.5752"
                stroke="#60AADB"
                strokeWidth="2.62856"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
