import { ChangePasswordCms, useLoaderCms } from "../../../api/backend";

export type ChangePasswordData = {
    cms: ChangePasswordCms;
    changePasswordConfirmationText: string;
};

export async function ChangePasswordLoader(): Promise<ChangePasswordData> {
    const { ChangePassword } = useLoaderCms();
    const cms = (await ChangePassword()).data;
    const changePasswordConfirmationText = cms.ChangePasswordConfirmationText.replace("<p>", "").replace("</p>", "");
    return { cms, changePasswordConfirmationText };
}
