import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "util";
import { useAccountContext } from "../api/backend";

export const useIndexedRoute = (route: string) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { accounts, setAccountIndex } = useAccountContext();

    const urlId = Number(id);

    useEffect(() => {
        if (accounts.length > 1) {
            if (isFinite(urlId)) {
                if (urlId < accounts.length) {
                    setAccountIndex(urlId);
                } else {
                    setAccountIndex(accounts.length - 1);
                    navigate(format(route, accounts.length - 1));
                }
            } else {
                setAccountIndex(0);
                navigate(format(route, 0));
            }
        }
    }, []);
};
