import { Component } from "react";
import * as icon from "../../generic/icons";
import "./iconTest.scss";

export class IconTest extends Component {
    render() {
        return (
            <div id="iconTest">
                <div className="iconTest-row">
                    <icon.Home className="iconTest-stroke-color" />
                    <icon.HomeFill className="iconTest-fill-color" />
                    <icon.Pay className="iconTest-stroke-color" />
                    <icon.PayFill className="iconTest-fill-color" />
                    <icon.Settings className="iconTest-stroke-color" />
                    <icon.SettingsFill className="iconTest-fill-color" />
                    <icon.Support className="iconTest-fill-color" />
                    <icon.SupportFill className="iconTest-fill-color" />
                    <icon.Error className="iconTest-stroke-color" />
                    <icon.Info className="iconTest-stroke-color" />
                    <icon.InfoFill className="iconTest-sf-color" />
                </div>
                <div className="iconTest-row">
                    <icon.ArrowRight className="iconTest-stroke-color" />
                    <icon.ArrowLeft className="iconTest-stroke-color" />
                    <icon.ArrowUp className="iconTest-stroke-color" />
                    <icon.ArrowDown className="iconTest-stroke-color" />
                    <icon.CreditCard className="iconTest-stroke-color" />
                    <icon.HelpArticle className="iconTest-stroke-color" />
                    <icon.Star className="iconTest-stroke-color" />
                    <icon.Hide className="iconTest-fill-color" />
                    <icon.Show className="iconTest-stroke-color" />
                    <icon.Exit className="iconTest-stroke-color" />
                    <icon.Delete className="iconTest-stroke-color" />
                    <icon.Share className="iconTest-stroke-color" />
                    <icon.Check className="iconTest-stroke-color" />
                    <icon.Dollar className="iconTest-stroke-color" />
                </div>
                <div className="iconTest-row">
                    <icon.CalendarDollar className="iconTest-stroke-color" />
                    <icon.Copy className="iconTest-fill-color" />
                    <icon.Processing className="iconTest-stroke-color" />
                    <icon.StatementDoc className="iconTest-stroke-color" />
                    <icon.UploadDocs className="iconTest-stroke-color" />
                    <icon.Chat className="iconTest-stroke-color" />
                    <icon.BiometricsPrint className="iconTest-stroke-color" />
                    <icon.Passcode className="iconTest-stroke-color" />
                    <icon.PrivacyDoc className="iconTest-stroke-color" />
                    <icon.Statements className="iconTest-fill-color" />
                    <icon.AddUser className="iconTest-stroke-color" />
                    <icon.User className="iconTest-stroke-color" />
                    <icon.Mail className="iconTest-stroke-color" />
                    <icon.MailNotification className="iconTest-stroke-color" />
                </div>
                <div className="iconTest-row">
                    <icon.PaperlessNotEnrolled className="iconTest-stroke-color" />
                    <icon.PaperlessEnrolled className="iconTest-stroke-color" />
                    <icon.Pencil className="iconTest-stroke-color" />
                    <icon.Increase className="iconTest-stroke-color" />
                    <icon.PaymentHistory className="iconTest-stroke-color" />
                    <icon.ManagePaymentAccount className="iconTest-stroke-color" />
                    <icon.Calendar className="iconTest-stroke-color" />
                    <icon.Add className="iconTest-stroke-color" svgClass="svgTest" />
                    <icon.Download className="iconTest-stroke-color" title="Download" />
                    <icon.Cancel className="iconTest-stroke-color" />
                    <icon.Trash className="iconTest-stroke-color" />
                    <icon.CreditHeart className="iconTest-stroke-color" />
                    <icon.Advice className="iconTest-stroke-color" />
                    <icon.Travel className="iconTest-stroke-color" />
                </div>
                <div className="iconTest-row">
                    <icon.Search className="iconTest-stroke-color" />
                    <icon.More className="iconTest-fill-color" />
                    <icon.BulletPoint className="iconTest-fill-color-sm" />
                    <icon.Lock className="iconTest-stroke-color" />
                    <icon.Unlock className="iconTest-stroke-color" />
                    <icon.LinkOut className="iconTest-stroke-color" />
                    <icon.Login className="iconTest-stroke-color" />
                    <icon.Logout className="iconTest-stroke-color" />
                    <icon.Warning className="iconTest-stroke-color" />
                </div>
                <div className="iconTest-row">
                    <icon.Facebook className="iconTest-fill-color" />
                    <icon.Instagram className="iconTest-fill-color" />
                    <icon.Youtube className="iconTest-fill-color" />
                    <icon.PBCLogoSm className="iconTest-fill-color-sm" />
                    <icon.PBCLogoLg className="iconTest-fill-color-lg" />
                </div>
                <div className="iconTest-row">
                    <icon.CheckSquare className="iconTest-stroke-color" />
                    <icon.Key className="iconTest-stroke-color" />
                    <icon.Blocks className="iconTest-stroke-color" />
                    <icon.SmartPhoneMoney className="iconTest-stroke-color" />
                    <icon.CreditIncrease className="iconTest-stroke-color" />
                    <icon.CardLimit className="iconTest-stroke-color" />
                    <icon.CardOptions className="iconTest-stroke-color" />
                    <icon.CustomerSupport className="iconTest-stroke-color" />
                    <icon.Percent className="iconTest-stroke-color" />
                    <icon.Notifications className="iconTest-stroke-color" />
                    <icon.SmartPhoneCard className="iconTest-stroke-color" />
                    <icon.MultipleUsers className="iconTest-stroke-color" />
                </div>
                <div className="iconTest-row">
                    <icon.ColorBlocks1 className="iconTest-colorBlocks" />
                    <icon.ColorBlocks2 className="iconTest-colorBlocks" />
                    <icon.ColorBlocks3 className="iconTest-colorBlocks" />
                    <icon.ColorBlocks4
                        className="iconTest-colorBlocks"
                        colors={{ lg: "Purple", md: "Blue", sm: "Orange" }}
                    />
                    <icon.ColorBlocks5 className="iconTest-colorBlocks" />
                    <icon.ColorBlocks6 className="iconTest-colorBlocks" />
                </div>
                <div className="iconTest-row">
                    <div className="iconTest-caption">
                        <icon.HomeFill />
                        <icon.HomeFill caption="Home" />
                        <icon.HomeFill
                            caption="Home"
                            onClick={() => {
                                console.log("Clicked");
                            }}
                        />
                        <icon.HomeFill
                            className="iconTest-fill-btn"
                            caption="Home"
                            onClick={() => {
                                console.log("Clicked");
                            }}
                        />
                        <icon.HomeFill
                            className="iconTest-fill-color-btn"
                            caption="Home"
                            onClick={() => {
                                console.log("Clicked");
                            }}
                        />
                        <icon.HomeFill
                            className="iconTest-caption-test"
                            caption="Home"
                            onClick={() => {
                                console.log("Clicked");
                            }}
                        />
                        <icon.HomeFill className="iconTest-caption-test" caption="Home" />
                    </div>
                </div>
            </div>
        );
    }
}
