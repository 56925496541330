import dayjs, { Dayjs } from "dayjs";
import { createContext, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useIdentityConnectContext } from "../api/identityServer";
import { SessionTimeoutModal } from "../components/peripherals/sessionTimeout/sessionTimeoutModel";
import { useBackgroundTick, useSessionStorage } from "../hooks";
import { SessionTimeout } from "../res/react-env-variables";
import { Children } from "../types";
import { Decrypt, Encrypt, IsNullOrEmpty, NotImplemented } from "../utility";

const prompt_time = 2;
const refresh_token_time = 8;
let update = false;
let timeout = true;

export type SessionTime = {
    startTime: Dayjs;
    endTime: Dayjs;
};

export type SessionTimerContextProps = {
    showSessionTimer: boolean;
    setShowSessionTimer: React.Dispatch<React.SetStateAction<boolean>>;
    sessionTimes: SessionTime;
    setSessionTimes: React.Dispatch<React.SetStateAction<SessionTime>>;
    running: boolean;
};

export const SessionTimerContext = createContext<SessionTimerContextProps>({
    showSessionTimer: false,
    setShowSessionTimer: NotImplemented,
    sessionTimes: { startTime: dayjs(), endTime: dayjs().add(refresh_token_time, "minutes") },
    setSessionTimes: NotImplemented,
    running: false
});

const useSessionTimerContextProps = (): SessionTimerContextProps => {
    const [showSessionTimer, setShowSessionTimer] = useState<boolean>(false);
    const [sessionTimes, setSessionTimes] = useState<SessionTime>({
        startTime: dayjs(),
        endTime: dayjs().add(refresh_token_time, "minutes")
    });

    return {
        showSessionTimer,
        setShowSessionTimer,
        sessionTimes,
        setSessionTimes,
        running: false
    };
};

export function SessionTimer(props: Children) {
    const { access_token, refresh_token, RefreshToken } = useIdentityConnectContext();
    const contextProps = useSessionTimerContextProps();
    const [storage, storeValue, removeValue] = useSessionStorage(["sessionTime"]);
    const { tick, run, stop, restart, expired } = useBackgroundTick();

    const onActive = () => {
        reset();
        timeout = true;
    };

    const { reset, getLastActiveTime } = useIdleTimer({
        onActive,
        timeout: 1000,
        throttle: 500
    });

    useEffect(() => {
        var lastActiveTime = dayjs(getLastActiveTime());
        if (lastActiveTime) {
            if (expired(lastActiveTime.add(SessionTimeout - prompt_time, "minute")) && timeout) {
                timeout = false;
                contextProps.setShowSessionTimer(true);
            }
        }
    });

    useEffect(() => {
        if (IsNullOrEmpty(access_token)) {
            removeValue(["sessionTime"]);
        }
    }, [access_token]);

    useEffect(() => {
        if (run) {
            setTimes();
        }
    }, [run]);

    useEffect(() => {
        if (!contextProps.showSessionTimer && !IsNullOrEmpty(access_token)) {
            restart();
        } else {
            stop();

            if (IsNullOrEmpty(access_token)) {
                removeValue(["sessionTime"]);
            }
        }
    }, [contextProps.showSessionTimer, access_token]);

    useEffect(() => {
        if (expired(contextProps.sessionTimes.endTime)) {
            RefreshToken(refresh_token, false);
            update = true;
            setTimes();
        }
    }, [tick]);

    function setTimes() {
        if (!IsNullOrEmpty(storage["sessionTime"]) && !update) {
            let times = Decrypt<SessionTime>(storage["sessionTime"]);
            contextProps.setSessionTimes({ startTime: dayjs(times.startTime), endTime: dayjs(times.endTime) });
        } else {
            let now = dayjs();
            let times = { startTime: now, endTime: now.add(refresh_token_time, "minutes") };
            contextProps.setSessionTimes(times);
            storeValue("sessionTime", Encrypt(times));
            update = false;
        }
    }

    function updateSession() {
        contextProps.setShowSessionTimer(false);
        timeout = true;
    }

    return (
        <>
            <SessionTimerContext.Provider value={{ ...contextProps, running: run }}>
                {props.children}
                <SessionTimeoutModal show={contextProps.showSessionTimer === true} updateSession={updateSession} />
            </SessionTimerContext.Provider>
        </>
    );
}
