import { useEffect, useState } from "react";
import { Loading } from "../..";
import "./loadingTest.scss";

export function LoadingTest(): React.ReactElement {
    const [showModal, setShowModal] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setShowModal(false);
        }, 15_000);
    });

    return (
        <div className="loadingTest">
            <Loading show={showModal} />
        </div>
    );
}
