import { AxiosResponse } from "axios";
import { useState } from "react";
import { NavigateFunction, useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import {
    AcceptTermsRequest,
    AcceptTermsResponse,
    ElectronicDeliveryPreferences,
    EnrollmentCmsModel,
    OnlineActions,
    UserAccount,
    UserConsentResponse,
    useAccountContext,
    useDeliveryPreferencesContext,
    useDigitalActionsContext,
    useEnrollmentContext,
    useUserConsentContext
} from "../../../api/backend";
import { ConnectTokenResponse, useIdentityConnectContext } from "../../../api/identityServer";
import { useMfaContext, useOneTrustContext, useSwrveContext } from "../../../contexts";
import { SecurityType } from "../../../contexts/mfaContext";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { EnrollTermsData } from "./enrollTermsLoader";

type EnrollTermsViewModel = {
    hooks: EnrollTermsHooks;
    state: EnrollTermsState;
    api: EnrollTermsApi;
};

type EnrollTermsHooks = {
    navigate: NavigateFunction;
    initializeSwrve: (applicantHandle: string) => void;
    syncOneTrustConsentProfile: (consumerGuid: string, jwt: string) => void;
    updateConsumerGuid: (consumerGuid?: string | undefined) => void;
    updateJwt: (jwt?: string | undefined) => void;
};

export type EnrollTermsState = {
    size: Size;
    securityType: SecurityType | undefined;
    setSecurityType: (value: SecurityType) => void;
    searchParams: URLSearchParams;
    cms: EnrollmentCmsModel;
    showCancel: boolean;
    setshowCancel: React.Dispatch<React.SetStateAction<boolean>>;
    showError: boolean;
    setShowError: React.Dispatch<React.SetStateAction<boolean>>;
    showSplash: boolean;
    setShowSplash: React.Dispatch<React.SetStateAction<boolean>>;
    subtitle: string;
};

type EnrollTermsApi = {
    AcceptTerms: (request: AcceptTermsRequest) => Promise<AxiosResponse<AcceptTermsResponse, any>>;
    AuthorizeOtac: (otac: string) => Promise<AxiosResponse<ConnectTokenResponse, any>>;
    userAccounts: (token: string) => Promise<AxiosResponse<UserAccount[], any>>;
    digitalActions: (token: string, accounts: UserAccount[]) => Promise<AxiosResponse<OnlineActions[], any>>;
    getPreferences: (token: string) => Promise<AxiosResponse<ElectronicDeliveryPreferences[], any>>;
    userConsentGet: (onlineUserId: number, applicantHandle: string) => Promise<AxiosResponse<UserConsentResponse, any>>;
};

export function useEnrollTermsViewModel(): EnrollTermsViewModel {
    const navigate = useNavigate();
    const { securityType, setSecurityType } = useMfaContext();
    const { AuthorizeOtac } = useIdentityConnectContext();
    const { UserAccounts } = useAccountContext();
    const { Get: getActions } = useDigitalActionsContext();
    const { Get: getPreferences } = useDeliveryPreferencesContext();
    const { Get: getConsent } = useUserConsentContext();
    const { AcceptTerms } = useEnrollmentContext();
    const [searchParams] = useSearchParams();
    const { cms } = useLoaderData() as EnrollTermsData;
    const { size } = useViewport(false, true);
    const [showCancel, setshowCancel] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showSplash, setShowSplash] = useState(false);
    const subtitle = securityType === "Enrollment" ? "Step 4 of 4" : "Step 3 of 3";
    const { initializeSwrve } = useSwrveContext();
    const { syncOneTrustConsentProfile, updateConsumerGuid, updateJwt } = useOneTrustContext();

    async function userAccounts(token: string): Promise<AxiosResponse<UserAccount[], any>> {
        return await UserAccounts(token);
    }

    async function digitalActions(
        token: string,
        accounts: UserAccount[]
    ): Promise<AxiosResponse<OnlineActions[], any>> {
        return await getActions(accounts[0].accountHandle, token);
    }

    async function userConsentGet(
        onlineUserId: number,
        applicantHandle: string
    ): Promise<AxiosResponse<UserConsentResponse, any>> {
        return await getConsent(onlineUserId, applicantHandle);
    }

    return {
        hooks: {
            navigate,
            initializeSwrve,
            syncOneTrustConsentProfile,
            updateConsumerGuid,
            updateJwt
        },
        state: {
            size,
            securityType,
            setSecurityType,
            searchParams,
            cms,
            showCancel,
            setshowCancel,
            showError,
            setShowError,
            showSplash,
            setShowSplash,
            subtitle
        },
        api: { AcceptTerms, AuthorizeOtac, userAccounts, digitalActions, getPreferences, userConsentGet }
    };
}
