import { AxiosResponse } from "axios";
import { ChangePasswordRequest, VerifyPasswordRequest } from ".";
import { UsePost, UsePut } from "../../../hooks";
import { useIdentityConnectContext } from "../../identityServer";

export const usePasswordContext = () => {
    const client = usePasswordClient();

    return { ...client };
};

const usePasswordClient = () => {
    const { access_token } = useIdentityConnectContext();

    async function Verify(request: VerifyPasswordRequest): Promise<AxiosResponse<any, any>> {
        return await UsePost("api/v1/password/verify", access_token, request);
    }

    async function Change(request: ChangePasswordRequest): Promise<AxiosResponse<any, any>> {
        return await UsePut("api/v1/password", access_token, request);
    }

    return {
        Verify,
        Change
    };
};
