import { ReactElement } from "react";
import { IsNullOrEmpty } from "../../../../utility";
import { useCardNavViewModel } from "../cardNavViewModel";
import { CardNavAltView } from "./cardNavAltView";

export function CardNavAlt(): ReactElement {
    const { hooks, state } = useCardNavViewModel();

    function onCardSelected(i: number) {
        state.setAccountIndex(i);

        let newLocation = state.location.pathname
            .split("/")
            .map((s) => {
                if (!IsNullOrEmpty(s) && !isNaN(Number(s))) {
                    return i;
                }
                return s;
            })
            .join("/");

        hooks.navigate(newLocation, { relative: "path" });
    }

    return <CardNavAltView {...state} onCardSelected={onCardSelected} />;
}
