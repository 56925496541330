import { ReactElement, useEffect, useState } from "react";
import { useSessionStorage } from "../../../../hooks";
import { IsNullOrEmpty } from "../../../../utility";
import { Modal, ModalProps } from "../../../generic";
import { MultAcctOfferView } from "./multAcctOfferView";

export type MultAcctOfferProps = {
    bodyText: string;
    redirectUrl: string;
    accountHandle: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export function MultAcctOffer(props: ModalProps & MultAcctOfferProps): ReactElement {
    const [storage] = useSessionStorage(["ShowMultAcctOfferPopup"]);
    const [show, setShow] = useState<boolean>(
        storage.ShowMultAcctOfferPopup === undefined &&
            !IsNullOrEmpty(props.accountHandle) &&
            !IsNullOrEmpty(props.bodyText) &&
            props.show === true
    );

    useEffect(() => {
        setShow(
            storage.ShowMultAcctOfferPopup === undefined &&
                !IsNullOrEmpty(props.accountHandle) &&
                !IsNullOrEmpty(props.bodyText) &&
                props.show === true
        );
    }, [props.accountHandle, props.bodyText, props.show, storage.ShowMultAcctOfferPopup]);

    return (
        <Modal show={show}>
            <MultAcctOfferModel {...props} />
        </Modal>
    );
}

export function MultAcctOfferModel(props: MultAcctOfferProps): ReactElement {
    const [storage, storeValue] = useSessionStorage(["ShowMultAcctOfferPopup"]);

    function learnMore() {
        setExitValues();
        window.open(props.redirectUrl, "_blank");
    }

    function noThanks() {
        setExitValues();
    }

    function setExitValues() {
        storeValue("ShowMultAcctOfferPopup", false);
        props.setShow(false);
    }

    return <MultAcctOfferView {...props} learnMore={learnMore} noThanks={noThanks} />;
}
