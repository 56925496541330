import { Form, Formik } from "formik";
import { ReactElement } from "react";
import { Loading } from "../../..";
import { ButtonPro } from "../../../generic";
import "./trustThisDevice.scss";

type trustThisDeviceViewProps = {
    onSubmit: Function;
};

function trustThisDeviceView(props: trustThisDeviceViewProps): ReactElement {
    return (
        <Formik
            initialValues={{ trust: false }}
            onSubmit={(values, actions) => {
                props.onSubmit(values, actions);
            }}
        >
            {({ values, errors, isSubmitting }) => (
                <Form className="trustThisDevice">
                    <div className="trustThisDevice-header">Trust This Device?</div>
                    <div className="trustThisDevice-body">
                        Select "Trust Device" to skip verification upon subsequent logins from this device.
                        <br />
                        <br />
                        Select "Not Now" to verify your identity each time you log in.
                    </div>
                    <div className="trustThisDevice-buttongroup">
                        <ButtonPro type="submit" variant="neutral" isSubmitting={isSubmitting}>
                            Not Now
                        </ButtonPro>
                        <ButtonPro type="submit" onClick={() => (values.trust = true)} isSubmitting={isSubmitting}>
                            Trust Device
                        </ButtonPro>
                    </div>
                    <Loading show={isSubmitting} />
                </Form>
            )}
        </Formik>
    );
}

export default trustThisDeviceView;
