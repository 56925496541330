import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function CardLimit(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28.5 10.5H4.5C3.70435 10.5 2.94129 10.8161 2.37868 11.3787C1.81607 11.9413 1.5 12.7044 1.5 13.5V37.5C1.5 38.2956 1.81607 39.0587 2.37868 39.6213C2.94129 40.1839 3.70435 40.5 4.5 40.5H28.5"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M1.5 19.5H27" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 28.5H18" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M46.5 11.626H37.95C36.6372 11.626 35.3781 12.1475 34.4498 13.0758C33.5215 14.0041 33 15.2632 33 16.576C33 24 46.5 24 46.5 31.426C46.4995 32.7386 45.9778 33.9974 45.0496 34.9256C44.1214 35.8538 42.8627 36.3754 41.55 36.376H33"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M39.75 11.626V7.12598" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M39.75 36.376V40.876" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
