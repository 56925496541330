import { ReactElement } from "react";
import { AsyncElement } from "../../generic";
import { ArrowRight } from "../../generic/icons";
import { ButtonPro } from "../../generic/inputs/buttonPro/buttonPro";
import "./activity.scss";
import { ActivityState } from "./activityViewModel";

type ActivityRecentViewProps = ActivityState & {
    rows: ReactElement[];
    onViewAll: Function;
};

function ActivityRecentView(props: ActivityRecentViewProps): ReactElement {
    return (
        <div id="activity">
            <div className="activity-header">
                <h5>Recent Activity</h5>
            </div>
            <AsyncElement loading={props.loading}>
                <div className="activity-list" data-testid="activity-list">
                    {props.rows.length > 0 ? (
                        props.rows
                    ) : (
                        <div className="activity-noList">
                            <h5>No activity available</h5>
                        </div>
                    )}
                </div>
            </AsyncElement>

            <div className="activity-footer">
                <ButtonPro variant="neutral" className="activity-footer-more" onClick={props.onViewAll}>
                    <h6>View All Activity</h6>
                    <ArrowRight />
                </ButtonPro>
            </div>
        </div>
    );
}

export default ActivityRecentView;
