import { ReactElement } from "react";
import { CardApplicationProps } from "../../cardApplication/cardApplication";
import { CardSecurityDepositView } from "./cardSecurityDepositView";
import { useCardSecurityDepositViewModel } from "./cardSecurityDepositViewModel";

export function CardSecurityDeposit(props: CardApplicationProps): ReactElement {
    const { state } = useCardSecurityDepositViewModel();

    function onPay() {
        window.open(props.account.applicationPaymentUrl!);
    }

    return <CardSecurityDepositView {...props} {...state} onPay={onPay} />;
}
