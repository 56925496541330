import { ReactElement } from "react";
import { Dropdown } from "react-bootstrap";
import CsvDownload from "react-csv-downloader";
import { Columns } from "react-csv-downloader/dist/esm/lib/csv";
import { TransactionDetails } from "../../../../api/backend";
import { Download } from "../../../generic/icons";
import "./activityDownload.scss";

type ActivityDownloadViewProps = {
    filteredHistory: TransactionDetails[];
    columns: Columns;
    exportCSV: Function;
    exportOFX: Function;
};

export function ActivityDownloadView(props: ActivityDownloadViewProps): ReactElement {
    return (
        <Dropdown align="end" className="activityDownload-dropdown">
            <Dropdown.Toggle className="activityDownload-dropdown-toggle">
                <Download />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item className="activityDownload-dropdown-item" disabled={props.filteredHistory.length <= 0}>
                    <CsvDownload
                        filename="MyPremierCreditcardDownload"
                        extension=".csv"
                        noHeader
                        columns={props.columns}
                        datas={() => props.exportCSV()}
                    >
                        Download as CSV
                    </CsvDownload>
                </Dropdown.Item>
                <Dropdown.Item
                    className="activityDownload-dropdown-item"
                    onClick={() => props.exportOFX()}
                    disabled={props.filteredHistory.length <= 0}
                >
                    Download as OFX
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}
