import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function BiometricsPrint(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="20"
            height="25"
            viewBox="0 0 20 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1 13.0005V24.0005" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M14.976 2.50071C13.62 1.60094 12.0453 1.08569 10.4197 1.00979C8.79409 0.9339 7.17831 1.30021 5.74434 2.06972C4.31037 2.83924 3.11189 3.98316 2.27645 5.37975C1.44101 6.77633 0.999873 8.37331 1 10.0007"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.9999 24.0005V10.0005C19.0029 8.22016 18.4751 6.47935 17.4839 5.00049"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16 16.5005V10.0005C16 8.40919 15.3679 6.88307 14.2426 5.75785C13.1174 4.63263 11.5913 4.00049 10 4.00049C8.4087 4.00049 6.88258 4.63263 5.75736 5.75785C4.63214 6.88307 4 8.40919 4 10.0005V24.0005"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M16 24.0005V19.5005" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 18.0005V24.0005" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M13 24.0005V10.0005C13 9.20484 12.6839 8.44178 12.1213 7.87917C11.5587 7.31656 10.7956 7.00049 10 7.00049C9.20435 7.00049 8.44129 7.31656 7.87868 7.87917C7.31607 8.44178 7 9.20484 7 10.0005V15.0005"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M10 10.5005V18.5005" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 21.5005V24.0005" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
