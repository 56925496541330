import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Pay(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="26"
            height="24"
            viewBox="0 0 26 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.7501 8.3999C4.82921 8.3999 4.90655 8.42154 4.97233 8.46207C5.03811 8.50261 5.08937 8.56022 5.11965 8.62762C5.14992 8.69503 5.15785 8.7692 5.14241 8.84076C5.12698 8.91232 5.08888 8.97805 5.03294 9.02964C4.977 9.08123 4.90573 9.11636 4.82813 9.13059C4.75054 9.14483 4.67011 9.13752 4.59702 9.1096C4.52393 9.08168 4.46146 9.0344 4.41751 8.97373C4.37356 8.91307 4.3501 8.84175 4.3501 8.76879C4.3501 8.67096 4.39224 8.57713 4.46726 8.50795C4.54227 8.43877 4.64401 8.3999 4.7501 8.3999Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.7501 15.7778C20.8292 15.7778 20.9065 15.7995 20.9723 15.84C21.0381 15.8805 21.0894 15.9381 21.1196 16.0056C21.1499 16.073 21.1578 16.1471 21.1424 16.2187C21.127 16.2902 21.0889 16.356 21.0329 16.4076C20.977 16.4592 20.9057 16.4943 20.8281 16.5085C20.7505 16.5228 20.6701 16.5155 20.597 16.4875C20.5239 16.4596 20.4615 16.4123 20.4175 16.3517C20.3736 16.291 20.3501 16.2197 20.3501 16.1467C20.3501 16.0489 20.3922 15.9551 20.4673 15.8859C20.5423 15.8167 20.644 15.7778 20.7501 15.7778Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M0.75 5.08008H24.75V19.8356H0.75V5.08008Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.5498 12.4579C9.5498 13.2406 9.88695 13.9913 10.4871 14.5447C11.0872 15.0981 11.9011 15.4091 12.7498 15.4091C13.5985 15.4091 14.4124 15.0981 15.0125 14.5447C15.6127 13.9913 15.9498 13.2406 15.9498 12.4579C15.9498 11.6753 15.6127 10.9246 15.0125 10.3712C14.4124 9.81776 13.5985 9.50684 12.7498 9.50684C11.9011 9.50684 11.0872 9.81776 10.4871 10.3712C9.88695 10.9246 9.5498 11.6753 9.5498 12.4579Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
