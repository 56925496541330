import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { ArrowLeft, ArrowRight } from "../../../icons";
import { changeDateMonth } from "../calendarUtils";
import "./datePickerSelector.scss";

export interface IDatePickerSelectorProps {
    shownDate: Dayjs;
    selectorDateFormat?: string;
    setShownDate: React.Dispatch<React.SetStateAction<Dayjs>>;
}

export function DatePickerSelector(props: IDatePickerSelectorProps): React.ReactElement {
    let current = IsCurrentMonth(props.shownDate);

    const handleIconClick = (isNextMonth: boolean) => {
        return () => {
            props.setShownDate(changeDateMonth(props.shownDate, isNextMonth));
        };
    };

    return (
        <div className="datePickerSelector">
            <div className="datePickerSelector-icon" onClick={!current ? handleIconClick(false) : () => {}}>
                <ArrowLeft className={`datePickerSelector-arrow${current ? "-disabled" : ""}`} />
            </div>

            <div className="datePickerSelector-date">
                <h6>{props.shownDate.format("MMMM YYYY")}</h6>
            </div>

            <div className="datePickerSelector-icon" onClick={current ? handleIconClick(true) : () => {}}>
                <ArrowRight className={`datePickerSelector-arrow${!current ? "-disabled" : ""}`} />
            </div>
        </div>
    );
}

function IsCurrentMonth(date: Dayjs): boolean {
    const now = dayjs.tz();

    if (now.isSame(date, "month")) {
        return true;
    }

    return false;
}
