import { ClearIdentityOptions, useIdentityConnectContext } from "../../../api/identityServer";
import { useDeepLinkContext } from "../../../contexts";
import { useSessionStorage, useViewport } from "../../../hooks";
import { LogoutRedirectUrl, MarketingSiteUrl } from "../../../res/react-env-variables";
import { Size } from "../../../types";
import { IsNullOrEmpty } from "../../../utility";

type LogoutViewModel = {
    hooks: LogoutHooks;
    state: LogoutState;
};

type LogoutHooks = {
    removeValue: (names: string[]) => void;
    clearIdentity: (option: ClearIdentityOptions) => void;
    clearLink: () => void
};

export type LogoutState = {
    size: Size;
    redirectLogoutUrl: string;
};

export const storageKeys = ["showTrust", "mfaRoute", "ShowMultAcctOfferPopup"];

export function useLogoutViewModel(): LogoutViewModel {
    const { size } = useViewport(true, true);
    const { clear: clearIdentity } = useIdentityConnectContext();
    const { clear: clearLink } = useDeepLinkContext();
    const [, , removeValue] = useSessionStorage(storageKeys);
    const redirectLogoutUrl: string = getRedirectURL();

    return {
        hooks: {
            removeValue,
            clearIdentity,
            clearLink
        },
        state: {
            size,
            redirectLogoutUrl
        }
    };
}

function getRedirectURL() {
    let redirectURL: string = LogoutRedirectUrl;

    if (IsNullOrEmpty(redirectURL)) {
        redirectURL = MarketingSiteUrl;
    }

    if (IsNullOrEmpty(redirectURL)) {
        redirectURL = "https://www.mypremiercreditcard.com";
    }

    return redirectURL;
}
