import { shouldPolyfill as shouldCannonical } from "@formatjs/intl-getcanonicallocales/should-polyfill";
import { shouldPolyfill as shouldLocale } from "@formatjs/intl-locale/should-polyfill";
import { shouldPolyfill as shouldFormat } from "@formatjs/intl-numberformat/should-polyfill";
import { shouldPolyfill as shouldPlural } from "@formatjs/intl-pluralrules/should-polyfill";
const replaceAll = require("string.prototype.replaceall");

export async function Polyfill() {
    replaceAll.shim();

    if (shouldCannonical()) {
        await import("@formatjs/intl-getcanonicallocales/polyfill");
    }
    if (shouldLocale()) {
        await import("@formatjs/intl-locale/polyfill");
    }
    if (shouldPlural()) {
        await import("@formatjs/intl-pluralrules/polyfill");
        await import(`@formatjs/intl-pluralrules/locale-data/en`);
    }
    if (shouldFormat()) {
        await import("@formatjs/intl-numberformat/polyfill");
        await import(`@formatjs/intl-numberformat/locale-data/en`);
    }
}
