import { ReactElement } from "react";
import { MfaAssistanceEnterCodeView } from "./mfaAssistanceEnterCodeView";
import { useMfaAssistanceEnterCodeViewModel } from "./mfaAssistanceEnterCodeViewModel";

export type MfaAssistanceEnterCodeProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setShowLast: React.Dispatch<React.SetStateAction<boolean>>;
    setShowTrust: React.Dispatch<React.SetStateAction<boolean>>;
    setShowError: React.Dispatch<React.SetStateAction<boolean>>;
    setShowPinExpired: React.Dispatch<React.SetStateAction<boolean>>;
};

export function MfaAssistanceEnterCodeModel(props: MfaAssistanceEnterCodeProps): ReactElement {
    const { state } = useMfaAssistanceEnterCodeViewModel();

    return <MfaAssistanceEnterCodeView {...props} {...state} />;
}
