import { ReactElement } from "react";
import { PaymentResponse } from "../../../../api/backend";
import { PaymentScheduledRowView } from "./paymentScheduledRowView";
import { usePaymentScheduledRowViewModel } from "./paymentScheduledRowViewModel";

export type PaymentScheduledRowProps = {
    index: number;
    payment: PaymentResponse;
    cancelPayment: Function;
};

export function PaymentScheduledRow(props: PaymentScheduledRowProps): ReactElement {
    const { hooks, state } = usePaymentScheduledRowViewModel(props);

    return <PaymentScheduledRowView {...props} {...state} {...hooks} />;
}
