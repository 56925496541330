import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Processing(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="23"
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M4.42477 13.6694V18.4692H-0.375" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.8247 8.8696V4.06982H23.6245" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M19.1246 4.06738C20.7797 5.79165 21.7905 8.03327 21.9869 10.4152C22.1834 12.7972 21.5536 15.1741 20.2035 17.1463C18.8533 19.1184 16.8652 20.5655 14.5735 21.244C12.2818 21.9225 9.82633 21.7911 7.62012 20.8719"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.12305 18.4723C2.47159 16.7472 1.46399 14.5063 1.26945 12.1261C1.07491 9.74584 1.70527 7.37114 3.05469 5.40074C4.40412 3.43033 6.39041 1.98423 8.68005 1.30526C10.9697 0.626286 13.4232 0.755793 15.6286 1.67203"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
