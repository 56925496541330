import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function CreditHeart(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="22"
            viewBox="0 0 24 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.8432 7.21298L16.9432 4.18298C16.6874 3.92859 16.5183 3.60011 16.4598 3.24415C16.4012 2.8882 16.4563 2.52286 16.6172 2.19998V2.19998C16.7389 1.95643 16.9168 1.74531 17.1362 1.58403C17.3556 1.42275 17.6102 1.31593 17.879 1.27237C18.1478 1.22881 18.4231 1.24976 18.6821 1.3335C18.9412 1.41723 19.1767 1.56135 19.3692 1.75398L19.8432 2.22798L20.3172 1.75398C20.5096 1.56135 20.7451 1.41723 21.0042 1.3335C21.2633 1.24976 21.5386 1.22881 21.8074 1.27237C22.0761 1.31593 22.3307 1.42275 22.5501 1.58403C22.7695 1.74531 22.9474 1.95643 23.0692 2.19998V2.19998C23.2302 2.52253 23.2858 2.88755 23.228 3.24341C23.1702 3.59927 23.002 3.92796 22.7472 4.18298L19.8432 7.21298Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M0.75 20C4.5 20 3 20.75 12.75 20.75C15.666 20.75 16.412 19.115 16.435 17"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M0.75 12.393H3.132C3.27497 12.393 3.41617 12.3613 3.54537 12.3001C3.67457 12.2389 3.78853 12.1497 3.879 12.039L7.929 8.05396L11.758 8.81995C12.304 8.92895 12.663 9.36495 12.425 10.32C12.3787 10.5062 12.2777 10.6743 12.1352 10.8027C11.9927 10.9312 11.815 11.0142 11.625 11.041L9.75 11.31C9.63716 11.3212 9.52776 11.3552 9.42837 11.4098C9.32898 11.4644 9.24165 11.5385 9.17161 11.6277C9.10156 11.7169 9.05026 11.8193 9.02077 11.9288C8.99128 12.0383 8.98422 12.1527 9 12.265V14.822C8.93725 15.1134 8.7831 15.3772 8.56 15.575L6.482 17.214"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.25 5.75H10.5C10.1022 5.75 9.72064 5.90804 9.43934 6.18934C9.15804 6.47064 9 6.85218 9 7.25V8.268"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 17H21.75C22.1478 17 22.5294 16.842 22.8107 16.5607C23.092 16.2794 23.25 15.8978 23.25 15.5V7.25"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M12.4922 9.5H23.2502" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.25 12.5H18.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
