import { FormikProvider, useFormik } from "formik";
import { ButtonPro, CheckboxPro, RadioPro, TogglePro, ToolTip } from "../../generic";
import "./buttonTest.scss";

export function ButtonTest(): React.ReactElement {
    const formik = useFormik({ initialValues: {}, onSubmit: (values) => {} });

    return (
        <div id="buttonTest">
            <div className="buttonTest-beta">
                <div className="buttonTest-split">
                    <div className="buttonTest-variant">
                        <h3>Primary CTA</h3>
                        <div className="buttonTest-grid">
                            <div className="buttonTest-row">
                                <h6 className="fw-semiBold">Default</h6>
                                <ButtonPro content={"Label"} onClick={() => {}} />
                                <ButtonPro content={"Label"} variant="primary-neg" />
                            </div>
                            <div className="buttonTest-row">
                                <h6 className="fw-semiBold">Inactive</h6>
                                <ButtonPro content={"Label"} disabled />
                                <ButtonPro content={"Label"} disabled variant="primary-neg" />
                            </div>
                            <div className="buttonTest-row">
                                <h6 className="fw-semiBold">Hover</h6>
                                <ButtonPro content={"Label"} className="buttonTest-primary-hover" />
                                <ButtonPro
                                    content={"Label"}
                                    variant="primary-neg"
                                    className="buttonTest-primary-neg-hover"
                                />
                            </div>
                            <div className="buttonTest-row">
                                <h6 className="fw-semiBold">Pressed</h6>
                                <ButtonPro content={"Label"} className="buttonTest-primary-pressed" />
                                <ButtonPro
                                    content={"Label"}
                                    variant="primary-neg"
                                    className="buttonTest-primary-neg-pressed"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="buttonTest-variant">
                        <h3>Checkbox (Form)</h3>
                        <FormikProvider value={formik}>
                            <form id="buttonTest-checkForm">
                                <div className="buttonTest-grid">
                                    <div className="buttonTest-row">
                                        <h6 className="fw-semiBold">Unchecked</h6>
                                        <CheckboxPro checked={false} />
                                    </div>
                                    <div className="buttonTest-row">
                                        <h6 className="fw-semiBold">Checked</h6>
                                        <CheckboxPro checked />
                                    </div>
                                    <div className="buttonTest-row">
                                        <h6 className="fw-semiBold">Inactive</h6>
                                        <CheckboxPro checked disabled />
                                    </div>
                                </div>
                                <div className="buttonTest-grid">
                                    <div className="buttonTest-row">
                                        <h6 className="fw-semiBold">Unchecked</h6>
                                        <CheckboxPro label="Label" checked={false} />
                                    </div>
                                    <div className="buttonTest-row">
                                        <h6 className="fw-semiBold">Checked</h6>
                                        <CheckboxPro checked label="Label" />
                                    </div>
                                    <div className="buttonTest-row">
                                        <h6 className="fw-semiBold">Inactive</h6>
                                        <CheckboxPro checked disabled label="Label" />
                                    </div>
                                </div>
                            </form>
                        </FormikProvider>
                    </div>
                </div>
                <div className="buttonTest-split">
                    <div className="buttonTest-variant">
                        <h3>Secondary CTA</h3>
                        <div className="buttonTest-grid">
                            <div className="buttonTest-row">
                                <h6 className="fw-semiBold">Default</h6>
                                <ButtonPro content={"Label"} variant="secondary" />
                                <ButtonPro content={"Label"} variant="secondary-neg" />
                            </div>
                            <div className="buttonTest-row">
                                <h6 className="fw-semiBold">Inactive</h6>
                                <ButtonPro content={"Label"} disabled variant="secondary" />
                                <ButtonPro content={"Label"} disabled variant="secondary-neg" />
                            </div>
                            <div className="buttonTest-row">
                                <h6 className="fw-semiBold">Hover</h6>
                                <ButtonPro
                                    content={"Label"}
                                    variant="secondary"
                                    className="buttonTest-secondary-hover"
                                />
                                <ButtonPro
                                    content={"Label"}
                                    variant="secondary-neg"
                                    className="buttonTest-secondary-neg-hover"
                                />
                            </div>
                            <div className="buttonTest-row">
                                <h6 className="fw-semiBold">Pressed</h6>
                                <ButtonPro
                                    content={"Label"}
                                    variant="secondary"
                                    className="buttonTest-secondary-pressed"
                                />
                                <ButtonPro
                                    content={"Label"}
                                    variant="secondary-neg"
                                    className="buttonTest-secondary-neg-pressed"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="buttonTest-variant">
                        <h3>Radio Button</h3>
                        <div className="buttonTest-grid">
                            <RadioPro id="radioTest1" name="radioTest" value="radioTest1" />
                            <RadioPro id="radioTest2" name="radioTest" value="radioTest2" />
                            <RadioPro id="radioTest3" name="radioTest2" value="radioTest3" disabled />
                        </div>
                    </div>
                </div>
                <div className="buttonTest-variant">
                    <h3>Tertiary CTA</h3>
                    <div className="buttonTest-grid">
                        <div className="buttonTest-row">
                            <h6 className="fw-semiBold">Default</h6>
                            <ButtonPro content={"Label"} variant="tertiary" />
                            <ButtonPro content={"Label"} variant="tertiary-neg" />
                            <ButtonPro content={"Label"} variant="neutral" />
                        </div>
                        <div className="buttonTest-row">
                            <h6 className="fw-semiBold">Inactive</h6>
                            <ButtonPro content={"Label"} disabled variant="tertiary" />
                            <ButtonPro content={"Label"} disabled variant="tertiary-neg" />
                            <ButtonPro content={"Label"} disabled variant="neutral" />
                        </div>
                        <div className="buttonTest-row">
                            <h6 className="fw-semiBold">Hover</h6>
                            <ButtonPro content={"Label"} variant="tertiary" className="buttonTest-tertiary-hover" />
                            <ButtonPro
                                content={"Label"}
                                variant="tertiary-neg"
                                className="buttonTest-tertiary-neg-hover"
                            />
                            <ButtonPro content={"Label"} variant="neutral" className="buttonTest-neutral-hover" />
                        </div>
                        <div className="buttonTest-row">
                            <h6 className="fw-semiBold">Pressed</h6>
                            <ButtonPro content={"Label"} variant="tertiary" className="buttonTest-tertiary-pressed" />
                            <ButtonPro
                                content={"Label"}
                                variant="tertiary-neg"
                                className="buttonTest-tertiary-neg-pressed"
                            />
                            <ButtonPro content={"Label"} variant="neutral" className="buttonTest-neutral-pressed" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="buttonTest-gamma ">
                <div className="buttonTest-toggle">
                    <h3>Toggle</h3>
                    <TogglePro checked />
                    <TogglePro />
                    <TogglePro disabled />
                    <TogglePro variant="neg" checked />
                    <TogglePro variant="neg" checked disabled />
                    <TogglePro variant="label" checked />
                    <TogglePro variant="label" />
                    <TogglePro variant="label" checked disabled />
                    <TogglePro variant="label" disabled />
                    <TogglePro variant="lock" />
                    <TogglePro variant="lock" checked />
                    <TogglePro variant="lock" disabled />
                    <TogglePro variant="lock" checked disabled />
                </div>
                <div className="buttonTest-toggle">
                    <h3>Tooltips</h3>
                    <ToolTip>This is a Tooltip!</ToolTip>
                </div>
            </div>
        </div>
    );
}
