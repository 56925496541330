import { ReactElement } from "react";
import { ColorBlocks1 } from "../../generic/icons";
import "./loginCard.scss";
import { LoginCardProps } from "./loginCardModel";
import { LoginCardState } from "./loginCardViewModel";

type LoginCardViewProps = LoginCardProps & LoginCardState;

export function LoginCardView(props: LoginCardViewProps): ReactElement {
    return (
        <div className={`loginScreen${props.size}`}>
            <div id={props.id} className="loginCard">
                <div className={`loginCard-header${props.size}`}>
                    <div className="loginCard-headerContent">
                        <h4>{props.header}</h4>
                        <ColorBlocks1 className="loginCard-headerBlocks" />
                    </div>
                    {props.subtitle ? <h6>{props.subtitle}</h6> : <></>}
                </div>

                {props.children}
            </div>
        </div>
    );
}
