import { ReactElement } from "react";
import { Icon, getColor } from "./icon";
import { IconProps } from "./iconProps";

export function MailNotification(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const backfill = getColor("background");
    const forefill = getColor("crit");

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="27"
            height="26"
            viewBox="0 0 27 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 6H20C21.1 6 22 6.9 22 8V20C22 21.1 21.1 22 20 22H4C2.9 22 2 21.1 2 20V8C2 6.9 2.9 6 4 6Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M22 8L12 15L2 8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="21" cy="6" r="5.5" fill={forefill} stroke={backfill} />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
