import { ReactElement, useEffect } from "react";
import { isMobileIOs } from "../../../../utility/device";
import { PdfViewerView } from "./pdfViewerView";

export type PdfViewerModelProps = {
    size: string;
    fileName: string;
    contentType: string;
    pdfArrayBuffer: ArrayBuffer;
};

export function PdfViewerModel(props: PdfViewerModelProps): ReactElement {
    useEffect(() => {
        if (isMobileIOs()) {
            const binaryString = atob(props.pdfArrayBuffer.toString());
            const uint8Array = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                uint8Array[i] = binaryString.charCodeAt(i);
            }

            var blob = new Blob([uint8Array], {
                type: props.contentType
            });
            var fileURL = URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = fileURL;
            link.setAttribute("download", props.fileName);
            document.body.appendChild(link);
            link.click();
        }
    }, []);

    return <PdfViewerView {...props} />;
}
