import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiBaseUrl, IdentityClient, IdentitySecret, IdentityUrl } from "../res/react-env-variables";

export function UseGet<T>(
    url: string,
    token?: string,
    config?: AxiosRequestConfig<any>
): Promise<AxiosResponse<T, any>> {
    return GetInstance(token).get(url, config);
}

export function UsePut<T, TB>(
    url: string,
    token?: string,
    body?: TB,
    config?: AxiosRequestConfig<any>
): Promise<AxiosResponse<T, any>> {
    return GetInstance(token).put(url, body, config);
}

export function UsePost<T, TB>(
    url: string,
    token?: string,
    body?: TB,
    config?: AxiosRequestConfig<any>
): Promise<AxiosResponse<T, any>> {
    return GetInstance(token).post(url, body, config);
}

export function useIdentityPost<T>(url: string, body: string): Promise<AxiosResponse<T, any>> {
    body = `client_id=${IdentityClient}&client_secret=${IdentitySecret}${body.length > 0 ? `&${body}` : ""}`;
    return GetIdentityInstance().post(url, body);
}

export function UseDelete<T>(
    url: string,
    token?: string,
    config?: AxiosRequestConfig<any>
): Promise<AxiosResponse<T, any>> {
    return GetInstance(token).delete(url, config);
}

export function GetInstance(token?: string): AxiosInstance {
    return token !== undefined ? authorized(token) : anonymous();
}

function GetIdentityInstance(): AxiosInstance {
    let identityInstance = axios.create({
        baseURL: IdentityUrl,
        responseType: "json",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    });

    SetupInterceptors(identityInstance);

    return identityInstance;
}

function authorized(token: string): AxiosInstance {
    let authorizedInstance = axios.create({
        baseURL: ApiBaseUrl,
        responseType: "json",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${token}`,
            Platform: "Web"
        }
    });

    SetupInterceptors(authorizedInstance);

    return authorizedInstance;
}

function anonymous(): AxiosInstance {
    let anonymousInstance = axios.create({
        baseURL: ApiBaseUrl,
        responseType: "json"
    });

    SetupInterceptors(anonymousInstance);

    return anonymousInstance;
}

export const SetupInterceptors = (axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.response.use(
        (response: AxiosResponse) => {
            return response;
        },
        (error: AxiosError) => {
            return Promise.reject(error);
        }
    );
};
