import { ReactElement } from "react";
import "./paymentsSummarySplash.scss";
import { PaymentsSummarySplashState } from "./paymentsSummarySplashViewModel";

export function PaymentsSummarySplashView(props: PaymentsSummarySplashState): ReactElement {
    return (
        <div id={`paymentsSummarySplash${props.size}`}>
            <h2 className="paymentsSummarySplash-content">
                Your&nbsp;
                <span className={`paymentsSummarySplash-indexed-${props.account?.accent}`}>Payments</span>
            </h2>
        </div>
    );
}
