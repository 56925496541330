import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { ButtonPro } from "../..";
import { GetFormattedDate } from "../../../../utility";
import "./calendarPro.scss";
import { DatePickerCalendar } from "./datePickerCalendar/datePickerCalendar";
import { DatePickerSelector } from "./datePickerSelector/datePickerSelector";

export interface ICalendarProProps {
    selectedDate: Dayjs | undefined;
    selectorDateFormat: string;
    endDate?: Dayjs;
    dueDate?: Dayjs;
    invalidDates?: Dayjs[];
    onChange: (newDate: Dayjs) => void;
    onClose: Function;
}

export function CalendarPro({
    id,
    className,
    style,
    props
}: {
    id?: string;
    className?: string;
    style?: any;
    props: ICalendarProProps;
}): React.ReactElement {
    const [shownDate, setShownDate] = useState(props.selectedDate?.clone() ?? dayjs.tz());
    const now = dayjs.tz();

    return (
        <div id={id} className={`calendarPro${className !== undefined ? ` ${className}` : ""}`} style={style}>
            <div className="calendarPro-header">
                <DatePickerSelector
                    shownDate={shownDate}
                    selectorDateFormat={props.selectorDateFormat}
                    setShownDate={setShownDate}
                />
                <h6>Today:&nbsp;{GetFormattedDate(now.toString(), false)}</h6>
            </div>

            <DatePickerCalendar {...props} shownDate={shownDate} />
            <div className="calendarPro-legend">
                <div className="calendarPro-legend-due">
                    {props.dueDate !== undefined ? (
                        <>
                            <h6>Payment due: {GetFormattedDate(props.dueDate, true)}</h6>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="calendarPro-legend-keys">
                    {props.dueDate === undefined ? (
                        <></>
                    ) : (
                        <div className="calendarPro-legendItem">
                            <div
                                className={`calendarPro-legendCircle${
                                    props.selectedDate?.isSame(props.dueDate, "day") ? "Set" : "Due"
                                }`}
                            />
                            <h6>Payment due date</h6>
                        </div>
                    )}
                    <div className="calendarPro-legendItem">
                        <div className="calendarPro-legendCircleSet" />
                        <h6>Set date</h6>
                    </div>
                    {(props.invalidDates?.length ?? 0) > 0 ? <></> : <></>}
                    {/* Todo Uncomment For 69955 */}
                    {/* <div className="calendarPro-legendItem">
                        <div className="calendarPro-legendCircleScheduled" />
                        <h6>Payment Scheduled</h6>
                    </div> */}
                </div>
            </div>

            <ButtonPro variant="primary" content={"Set Date"} onClick={() => props.onClose()} />
        </div>
    );
}
