import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function BriefCase(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="65"
            height="64"
            viewBox="0 0 65 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="32.11" cy="32.0001" r="30.8001" stroke="#8B257C" strokeWidth="2.4" />
            <path
                d="M46.682 25.5391H17.7676C16.3159 25.5391 15.139 26.7159 15.139 28.1676V46.5677C15.139 48.0194 16.3159 49.1963 17.7676 49.1963H46.682C48.1337 49.1963 49.3105 48.0194 49.3105 46.5677V28.1676C49.3105 26.7159 48.1337 25.5391 46.682 25.5391Z"
                stroke="#8B257C"
                strokeWidth="2.62858"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.139 36.0547H49.3105"
                stroke="#8B257C"
                strokeWidth="2.62858"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.2249 33.4297V38.6868"
                stroke="#8B257C"
                strokeWidth="2.62858"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M40.1106 25.542C40.1106 23.4506 39.2798 21.4448 37.8009 19.9659C36.322 18.4871 34.3163 17.6562 32.2248 17.6562C30.1334 17.6562 28.1276 18.4871 26.6488 19.9659C25.1699 21.4448 24.3391 23.4506 24.3391 25.542"
                stroke="#8B257C"
                strokeWidth="2.62858"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
