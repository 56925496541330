import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Facebook(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg id={id} className={svgClass} width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
            <path d="M28 14.0853C28 6.30537 21.7328 0 14 0C6.26719 0 0 6.30537 0 14.0853C0 21.1169 5.11875 26.9436 11.8125 28V18.1568H8.25781V14.0853H11.8125V10.9821C11.8125 7.45255 13.9016 5.50206 17.1008 5.50206C18.632 5.50206 20.2344 5.77717 20.2344 5.77717V9.24347H18.468C16.7289 9.24347 16.1875 10.3301 16.1875 11.4443V14.0853H20.0703L19.4496 18.1568H16.1875V28C22.8813 26.9436 28 21.1169 28 14.0853Z" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
