import { ReactElement } from "react";
import { ElectronicDeliveryPreferences } from "../../../../api/backend";
import { ModalProps } from "../../../generic";
import DeliveryPreferencesConfirmationView from "./deliveryPreferencesConfirmationView";

type DeliveryPreferencesConfirmationProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    tempDeliveryPreferences: ElectronicDeliveryPreferences[] | undefined;
    setTempDeliveryPreferences: React.Dispatch<React.SetStateAction<ElectronicDeliveryPreferences[] | undefined>>;
    resetForm: Function;
    confirmRows: ReactElement[];
};

export function DeliveryPreferencesConfirmationModel(
    props: ModalProps & DeliveryPreferencesConfirmationProps
): ReactElement {
    function onClose(): void {
        props.setShow(false);
        props.resetForm();
    }

    return <DeliveryPreferencesConfirmationView {...props} onClose={onClose} />;
}
