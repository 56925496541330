import { ReactElement } from "react";
import { IntermediateWarningModal } from "../..";
import { ButtonPro } from "../../generic";
import { Exit } from "../../generic/icons";
import "./appDownloadBanner.scss";
import { AppDownloadBannerState } from "./appDownloadBannerViewModel";

type AppDownloadBannerViewProps = AppDownloadBannerState & {
    close: Function;
    getIt: Function;
};

export function AppDownloadBannerView(props: AppDownloadBannerViewProps): ReactElement {
    return (
        <>
            <div className={`appDownloadBanner${props.size}`}>
                <div className="appDownloadBanner-body">
                    <Exit className="appDownloadBanner-exit" onClick={() => props.close()} />
                    <img
                        className="appDownloadBanner-img"
                        src={require("../../../res/images/appLogo.png")}
                        alt="app"
                        title="app"
                    />
                    <h6>Do more with the PREMIER Credit Card App</h6>
                </div>
                <div className="appDownloadBanner-btns">
                    <ButtonPro className="appDownloadBanner-btn" variant="secondary" onClick={() => props.getIt()}>
                        Get It
                    </ButtonPro>
                </div>
            </div>

            <IntermediateWarningModal
                show={props.showIntermediate}
                setShow={props.setShowIntermediate}
                dest={props.intermediateDest}
            />
        </>
    );
}
