import { Form, Formik } from "formik";
import { ReactElement, useContext, useEffect } from "react";
import { InputField, Loading, StateSelectorField } from "../..";
import { Profile } from "../../../api/backend";
import { CardNavContext, useSwrveContext } from "../../../contexts";
import { FormProps } from "../../../types";
import { FormatPhone, SetTitle } from "../../../utility";
import { Alert, AsyncElement, BackArrow, ButtonPro, CheckboxPro, GenericModal, IndexedCreditCard } from "../../generic";
import { InputPro } from "../../generic/inputs/inputPro/inputPro";
import "./updateContactInformation.scss";
import {
    AddressFormFields,
    EmailFormFields,
    PhoneNumberFormFields,
    UpdateContactInformationState
} from "./updateContactInformationViewModel";

type UpdateContactInformationViewProps = UpdateContactInformationState & {
    cards: ReactElement[];
};

export function UpdateContactInformationView(props: UpdateContactInformationViewProps): ReactElement {
    SetTitle("Contact Information");
    const { insertSwrveEvent } = useSwrveContext();
    const { setShowNav } = useContext(CardNavContext);

    useEffect(() => {
        SetTitle("Contact Information");
        setShowNav(false);
        insertSwrveEvent("Web_ManageContactInformation_Screen");
        SetTitle("Contact Information");

        return () => {
            SetTitle("");
        };
    }, []);

    return (
        <div className={`updateContactInformation${props.size}`}>
            <div className="updateContactInformation-page-container">
                <Loading show={props.loading} />
                <div className="updateContactInformation-page-subcontainer">
                    <BackArrow available to="/account/dashboard">
                        <div>
                            <h2 className="updateContactInformation-header">Contact Information</h2>
                        </div>
                    </BackArrow>
                    <div>
                        <AsyncElement loading={props.loadingAsync}>
                            <div className="updateContactInformation-cards">{props.cards}</div>
                        </AsyncElement>
                    </div>
                </div>
            </div>
            <GenericModal show={props.showSuccessModal} {...props.successModal} />
            <GenericModal show={props.blocker.state === "blocked"} {...props.discardChangesModal} />
        </div>
    );
}

type UpdateContactInformationCardProps = UpdateContactInformationState & {
    submitAddress: Function;
    checkAddress: Function;
    checkPhone: Function;
    checkEmail: Function;
    submitEmail: Function;
    submitPhone: Function;
    index: number;
    last4: string;
    profile: Profile;
};

export function CardContainer(props: UpdateContactInformationCardProps): ReactElement {
    return (
        <div className="updateContactInformation-cardContainer">
            <div className={`updateContactInformation-body-container${props.size}`}>
                <div className="updateContactInformation-body-headerSubTitle">
                    <IndexedCreditCard account={props.accounts![props.index]} size={"h4"} />
                </div>
                <div className="updateContactInformation-profileContainer">
                    <AddressContainer {...props} />
                    <PhoneContainer {...props} />
                    <EmailContainer {...props} />
                </div>
            </div>
        </div>
    );
}

function AddressContainer(props: UpdateContactInformationCardProps): ReactElement {
    const addressError = {
        headerText: "Invalid Address",
        bodyText: props.addressApiError,
        primaryButton: {
            text: "Close",
            action: () => {
                document.getElementById("address1")?.focus();
                props.setShowAddressApiError(false);
            }
        }
    };

    const initalValues = {
        address1: props.profile?.address1,
        address2: props.profile?.address2,
        city: props.profile?.city,
        state: props.profile?.state,
        zipCode: props.profile?.zipCode,
        multipleCard: true
    } as AddressFormFields;

    return (
        <>
            <div className={`updateContactInformation-body-section-address${props.size}`}>
                <div className="updateContactInformation-body-header-section">Mailing Address</div>
                {props.editAddress && props.index === props.activeIndex ? (
                    <div>
                        <Formik
                            initialValues={initalValues}
                            onSubmit={(values, actions) => {
                                props.submitAddress(values, actions);
                            }}
                        >
                            {(formProps: FormProps<AddressFormFields>) => (
                                <Form autoComplete="off">
                                    <div className="updateContactInformation-input-group">
                                        <InputPro
                                            id="address1"
                                            error={props.address1Error}
                                            className="updateContactInformation-inputPro-addr1"
                                            label="Address Line 1*"
                                            maxLength={26}
                                            onChange={props.checkAddress(
                                                formProps.values["address1"],
                                                "address1",
                                                formProps,
                                                false
                                            )}
                                            onBlur={() => {
                                                props.checkAddress(
                                                    formProps.values["address1"],
                                                    "address1",
                                                    formProps,
                                                    true
                                                );
                                            }}
                                        />
                                        <InputPro
                                            id="address2"
                                            className="updateContactInformation-inputPro"
                                            label="Address Line 2"
                                            maxLength={26}
                                            onChange={props.checkAddress(
                                                formProps.values["address2"],
                                                "address2",
                                                formProps,
                                                false
                                            )}
                                            onBlur={() => {
                                                props.checkAddress(
                                                    formProps.values["address2"],
                                                    "address2",
                                                    formProps,
                                                    true
                                                );
                                            }}
                                        />
                                        <InputPro
                                            id="city"
                                            error={props.cityError}
                                            className="updateContactInformation-inputPro"
                                            label="City*"
                                            maxLength={18}
                                            onChange={props.checkAddress(
                                                formProps.values["city"],
                                                "city",
                                                formProps,
                                                false
                                            )}
                                            onBlur={() => {
                                                props.checkAddress(formProps.values["city"], "city", formProps, true);
                                            }}
                                        />
                                        <StateSelectorField
                                            id="state"
                                            blankOption={false}
                                            label="State*"
                                            onClick={() => {
                                                props.setSelectedState(formProps.values["state"]);
                                                props.checkAddress(
                                                    formProps.values["state"],
                                                    "state",
                                                    formProps,
                                                    false
                                                );
                                            }}
                                            onBlur={() => {
                                                props.setSelectedState(formProps.values["state"]);
                                                props.checkAddress(
                                                    formProps.values["state"],
                                                    "state",
                                                    formProps,
                                                    false
                                                );
                                            }}
                                        />
                                        {props.isStateFC ? (
                                            <InputPro
                                                id="zipCode"
                                                label="Zip Code*"
                                                className="updateContactInformation-inputPro"
                                                maxLength={9}
                                                mask={new RegExp("^[0-9]*$")}
                                                value="0000000000"
                                                type="text"
                                                onChange={props.checkAddress(
                                                    formProps.values["zipCode"],
                                                    "zipCode",
                                                    formProps,
                                                    false
                                                )}
                                                onBlur={() => {
                                                    props.checkAddress(
                                                        formProps.values["zipCode"],
                                                        "zipCode",
                                                        formProps,
                                                        true
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <InputPro
                                                id="zipCode"
                                                label="Zip Code*"
                                                error={props.zipCodeError}
                                                className="updateContactInformation-inputPro"
                                                maxLength={9}
                                                mask={new RegExp("^[0-9]*$")}
                                                type="text"
                                                onChange={props.checkAddress(
                                                    formProps.values["zipCode"],
                                                    "zipCode",
                                                    formProps,
                                                    false
                                                )}
                                                onBlur={() => {
                                                    props.checkAddress(
                                                        formProps.values["zipCode"],
                                                        "zipCode",
                                                        formProps,
                                                        true
                                                    );
                                                }}
                                            />
                                        )}
                                        {props.canEditMultiple ? (
                                            <CheckboxPro
                                                id="multipleCard"
                                                name="multipleCard"
                                                label="Apply change to all accounts"
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>

                                    <div className="address-buttons">
                                        <ButtonPro
                                            id="cancel"
                                            type="button"
                                            variant="secondary"
                                            onClick={() => {
                                                props.setEditAddress(false);
                                                props.setCanContinue(false);
                                                props.setUnsavedChanges(false);
                                                props.setSelectedState(props.profile!.state);
                                            }}
                                        >
                                            Cancel
                                        </ButtonPro>
                                        <ButtonPro
                                            id="save"
                                            type="submit"
                                            isSubmitting={formProps.isSubmitting}
                                            disabled={!props.canContinue}
                                        >
                                            Save Changes
                                        </ButtonPro>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <GenericModal show={props.showAddressApiError} {...addressError} />
                    </div>
                ) : (
                    <div>
                        {props.profile === undefined || props.profile?.canEditAddress ? (
                            <></>
                        ) : (
                            <div className="updateContatctInformation-alert">
                                <Alert level="info" text="Your address cannot be changed at this time." />
                            </div>
                        )}
                        <div className="updateContactInformation-body-section-text">
                            {props.profile?.address1} {props.profile?.address2} {props.profile?.city}{" "}
                            {props.profile?.state}, {props.profile?.zipCode}
                        </div>
                        <ButtonPro
                            disabled={
                                !props.profile?.canEditAddress ||
                                props.editEmail ||
                                props.editPhone ||
                                (props.editAddress && props.index !== props.activeIndex)
                            }
                            className="edit-button"
                            content={"Edit Address"}
                            variant="tertiary"
                            onClick={() => {
                                props.setSelectedState(props.profile!.state);
                                props.setActiveIndex(props.index);
                                props.setEditAddress(true);
                                props.setAddress1Error("");
                                props.setCityError("");
                                props.setZipCodeError("");
                                props.setCanContinue(false);
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

function PhoneContainer(props: UpdateContactInformationCardProps): ReactElement {
    const phoneError = {
        headerText: "Invalid Number",
        bodyText: props.phoneApiError,
        primaryButton: {
            text: "Close",
            action: () => {
                document.getElementById("cellPhone1")?.focus();
                props.setShowPhoneApiError(false);
            }
        }
    };

    const formatedhomePhone = FormatPhone(props.profile?.homePhone);
    const formatedworkPhone = FormatPhone(props.profile?.workPhone);
    const formatedcellPhone1 = FormatPhone(props.profile?.cellPhone1);
    const formatedcellPhone2 = FormatPhone(props.profile?.cellPhone2);
    const initalValues = {
        homePhone: props.profile?.homePhone,
        workPhone: props.profile?.workPhone,
        cellPhone1: props.profile?.cellPhone1,
        cellPhone2: props.profile?.cellPhone2,
        multipleCard: true
    } as PhoneNumberFormFields;

    return (
        <>
            <div className={`updateContactInformation-body-section-phone${props.size}`}>
                <div className="updateContactInformation-body-header-section">Phone</div>
                {props.editPhone && props.index === props.activeIndex ? (
                    <div>
                        {props.profileCMS?.CellPhoneDisclaimer !== undefined ? (
                            <div className="dislclaimer-alert">
                                <Alert>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: props.profileCMS ? props.profileCMS.CellPhoneDisclaimer : ""
                                        }}
                                    />
                                </Alert>
                            </div>
                        ) : (
                            <></>
                        )}
                        <Formik
                            initialValues={initalValues}
                            onSubmit={(values, actions) => {
                                props.submitPhone(values, actions);
                            }}
                        >
                            {(formProps: FormProps<PhoneNumberFormFields>) => (
                                <Form autoComplete="off">
                                    <div className="updateContactInformation-input-group">
                                        <InputField
                                            id="cellPhone1"
                                            label="Cell Phone 1"
                                            error={props.cellPhone1Error}
                                            mask="(999) 999-9999"
                                            inputMode="numeric"
                                            onChange={props.checkPhone(
                                                formProps.values["cellPhone1"],
                                                "cellPhone1",
                                                formProps,
                                                false
                                            )}
                                            onBlur={() => {
                                                props.checkPhone(
                                                    formProps.values["cellPhone1"],
                                                    "cellPhone1",
                                                    formProps,
                                                    true
                                                );
                                            }}
                                            ignoreTouched={true}
                                            alwaysHighlightLine={true}
                                            highLightLineClass={
                                                props.cellPhone1Error !== "" ? "inputField-row-error" : ""
                                            }
                                            autoComplete="mobile tel-national"
                                        />
                                        <InputField
                                            id="cellPhone2"
                                            label="Cell Phone 2"
                                            error={props.cellPhone2Error}
                                            mask="(999) 999-9999"
                                            inputMode="numeric"
                                            onChange={props.checkPhone(
                                                formProps.values["cellPhone2"],
                                                "cellPhone2",
                                                formProps,
                                                false
                                            )}
                                            onBlur={() => {
                                                props.checkPhone(
                                                    formProps.values["cellPhone2"],
                                                    "cellPhone2",
                                                    formProps,
                                                    true
                                                );
                                            }}
                                            ignoreTouched={true}
                                            alwaysHighlightLine={true}
                                            highLightLineClass={
                                                props.cellPhone2Error !== "" ? "inputField-row-error" : ""
                                            }
                                            autoComplete="mobile2 tel-national"
                                        />
                                        <InputField
                                            id="homePhone"
                                            label="Home Phone"
                                            error={props.homePhoneError}
                                            mask="(999) 999-9999"
                                            inputMode="numeric"
                                            onChange={props.checkPhone(
                                                formProps.values["homePhone"],
                                                "homePhone",
                                                formProps,
                                                false
                                            )}
                                            onBlur={() => {
                                                props.checkPhone(
                                                    formProps.values["homePhone"],
                                                    "homePhone",
                                                    formProps,
                                                    true
                                                );
                                            }}
                                            ignoreTouched={true}
                                            alwaysHighlightLine={true}
                                            highLightLineClass={
                                                props.homePhoneError !== "" ? "inputField-row-error" : ""
                                            }
                                            autoComplete="home tel-national"
                                        />
                                        <InputField
                                            id="workPhone"
                                            label="Work Phone"
                                            error={props.workPhoneError}
                                            mask="(999) 999-9999"
                                            inputMode="numeric"
                                            onChange={props.checkPhone(
                                                formProps.values["workPhone"],
                                                "workPhone",
                                                formProps,
                                                false
                                            )}
                                            onBlur={() => {
                                                props.checkPhone(
                                                    formProps.values["workPhone"],
                                                    "workPhone",
                                                    formProps,
                                                    true
                                                );
                                            }}
                                            ignoreTouched={true}
                                            alwaysHighlightLine={true}
                                            highLightLineClass={
                                                props.workPhoneError !== "" ? "inputField-row-error" : ""
                                            }
                                            autoComplete="work tel-national"
                                        />
                                        {props.canEditMultiple ? (
                                            <CheckboxPro
                                                id="multipleCard"
                                                name="multipleCard"
                                                label="Apply change to all accounts"
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>

                                    <div className="address-buttons">
                                        <ButtonPro
                                            id="cancel"
                                            type="button"
                                            variant="secondary"
                                            onClick={() => {
                                                props.setEditPhone(false);
                                                props.setCanContinue(false);
                                                props.setUnsavedChanges(false);
                                            }}
                                        >
                                            Cancel
                                        </ButtonPro>
                                        <ButtonPro
                                            id="save"
                                            type="submit"
                                            isSubmitting={formProps.isSubmitting}
                                            disabled={!props.canContinue}
                                        >
                                            Save Changes
                                        </ButtonPro>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <GenericModal show={props.showPhoneApiError} {...phoneError} />
                    </div>
                ) : (
                    <div>
                        <>
                            {!props.profile?.canEditPhoneNumber ? (
                                <div className="updateContatctInformation-alert">
                                    <Alert level="info">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: props.profileCMS ? props.profileCMS.CeaseDesistMessage : ""
                                            }}
                                        />
                                    </Alert>
                                </div>
                            ) : (
                                <div className="updateContactInformation-body-section-text">
                                    {props.profile?.cellPhone1 !== "" ? (
                                        <>
                                            <div>Cell Phone 1: {formatedcellPhone1.formatted}</div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {props.profile?.cellPhone2 !== "" ? (
                                        <>
                                            <div>Cell Phone 2: {formatedcellPhone2.formatted}</div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {props.profile?.homePhone !== "" ? (
                                        <>
                                            <div>Home Phone: {formatedhomePhone.formatted}</div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {props.profile?.workPhone !== "" ? (
                                        <>
                                            <div>Work Phone: {formatedworkPhone.formatted}</div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            )}
                        </>
                        <ButtonPro
                            disabled={
                                !props.profile?.canEditPhoneNumber ||
                                props.editAddress ||
                                props.editEmail ||
                                (props.editPhone && props.index !== props.activeIndex)
                            }
                            className="edit-button"
                            content={"Edit Phone"}
                            variant="tertiary"
                            onClick={() => {
                                props.setActiveIndex(props.index);
                                props.setEditPhone(true);
                                props.setCellPhone1Error("");
                                props.setCellPhone2Error("");
                                props.setHomePhoneError("");
                                props.setWorkPhoneError("");
                                props.setCanContinue(false);
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

function EmailContainer(props: UpdateContactInformationCardProps): ReactElement {
    const emailError = {
        headerText: "Invalid Email",
        bodyText: props.emailApiError,
        primaryButton: {
            text: "Close",
            action: () => {
                document.getElementById("emailAddress")?.focus();
                props.setShowEmailApiError(false);
            }
        }
    };

    const initalValues = {
        emailAddress: props.profile?.emailAddress ?? "",
        multipleCard: true
    } as EmailFormFields;

    return (
        <>
            <div className={`updateContactInformation-body-section-email${props.size}`}>
                <div className="updateContactInformation-body-header-section">Email</div>
                {props.editEmail && props.index === props.activeIndex ? (
                    <div>
                        {props.profileCMS?.EmailDisclaimer !== undefined ? (
                            <div className="dislclaimer-alert">
                                <Alert text={props.profileCMS.EmailDisclaimer}>
                                    {" "}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: props.profileCMS ? props.profileCMS.EmailDisclaimer : ""
                                        }}
                                    />
                                </Alert>
                            </div>
                        ) : (
                            <></>
                        )}
                        <Formik
                            initialValues={initalValues}
                            onSubmit={(values, actions) => {
                                props.submitEmail(values, actions);
                            }}
                        >
                            {(formProps: FormProps<EmailFormFields>) => (
                                <Form autoComplete="off">
                                    <div className="updateContactInformation-input-group">
                                        <InputPro
                                            id="emailAddress"
                                            className="updateContactInformation-inputPro"
                                            label="Email*"
                                            maxLength={50}
                                            error={props.emailErrors}
                                            onChange={props.checkEmail(formProps.values["emailAddress"])}
                                        />
                                        {props.canEditMultiple ? (
                                            <CheckboxPro
                                                id="multipleCard"
                                                name="multipleCard"
                                                label="Apply change to all accounts"
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <div className="address-buttons">
                                        <ButtonPro
                                            id="cancel"
                                            type="button"
                                            variant="secondary"
                                            onClick={() => {
                                                props.setEditEmail(false);
                                                props.setCanContinue(false);
                                                props.setUnsavedChanges(false);
                                            }}
                                        >
                                            Cancel
                                        </ButtonPro>
                                        <ButtonPro
                                            id="save"
                                            type="submit"
                                            isSubmitting={formProps.isSubmitting}
                                            disabled={!props.canContinue}
                                        >
                                            Save Changes
                                        </ButtonPro>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <GenericModal show={props.showEmailApiError} {...emailError} />
                    </div>
                ) : (
                    <div>
                        <div className="updateContactInformation-body-section-text">
                            {props.profile?.emailAddress ?? "Not available"}
                        </div>
                        <ButtonPro
                            disabled={
                                props.editAddress ||
                                props.editPhone ||
                                (props.editEmail && props.index !== props.activeIndex)
                            }
                            className="edit-button"
                            content={"Edit Email"}
                            variant="tertiary"
                            onClick={() => {
                                props.setActiveIndex(props.index);
                                props.setEditEmail(true);
                                props.setEmailErrors("");
                                props.setCanContinue(false);
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    );
}
