import { FormikProps } from "formik";
import { ReactElement } from "react";
import { ReviewCancelPaymentProps } from "./reviewCancelPayment";
import ReviewCancelPaymentView from "./reviewCancelPaymentView";
import { ReviewCancelPaymentViewModel } from "./reviewCancelPaymentViewModel";

type ReviewCancelPaymentFormProps = {
    props: ReviewCancelPaymentProps;
    vm: ReviewCancelPaymentViewModel;
    formProps: FormikProps<{}>;
};

export function ReviewCancelPaymentForm(form: ReviewCancelPaymentFormProps): ReactElement {
    const { props, formProps } = { ...form };
    const { state } = { ...form.vm };

    function onCancel() {
        props.setShow(false);
    }

    return <ReviewCancelPaymentView {...props} {...state} {...formProps} onCancel={onCancel} />;
}
