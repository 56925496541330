import dayjs from "dayjs";
import { ReactElement, useEffect, useRef, useState } from "react";
import { Dropdown, OverlayTrigger } from "react-bootstrap";
import { IndexedCreditCard } from "../../..";
import { GetFormattedDate } from "../../../../utility";
import { Alert, ButtonPro, ToolTip } from "../../../generic";
import { ArrowDown, Calendar } from "../../../generic/icons";
import { PaymentAmountOption, PaymentAmountOptionOther } from "./paymentAmountOption/paymentAmountOption";
import { PaymentCalendarOverlay } from "./paymentCalendarOverlay/paymentCalendarOverlay";
import "./schedulePayment.scss";
import { SchedulePaymentProps } from "./schedulePaymentModel";
import { SchedulePaymentState } from "./schedulePaymentViewModel";
import { SelectPaymentAccount } from "./selectPaymentAccount/selectPaymentAccount";

type SchedulePaymentViewProps = SchedulePaymentProps &
    SchedulePaymentState & {
        onSelectionChanged: Function;
        onPaymentAccountClick: Function;
        onAddPaymentAccount: Function;
        onCancel: Function;
        onSubmit: Function;
    };

function SchedulePaymentView(props: SchedulePaymentViewProps): ReactElement {
    return (
        <div id="schedulePayment">
            <div className="schedulePayment-container">
                <div className="schedulePayment-header">
                    <h5>Make a Payment to&nbsp;</h5>
                    <IndexedCreditCard account={props.account} size="h5" icon={false} />
                </div>

                <div className="schedulePayment-accountContainer">
                    <div className={`schedulePayment-account${props.accountError !== "" ? "-error" : ""}`}>
                        <h5>Choose payment account</h5>
                        <SelectPaymentAccount {...props} />
                    </div>
                    <div className="validation-error">
                        <h6>{props.accountError}</h6>
                    </div>
                </div>

                <div className="schedulePayment-amountContainer">
                    <div className={`schedulePayment-amount${props.amountError !== "" ? "-error" : ""}`}>
                        <h5>Select amount</h5>
                        <AmountOptions {...props} />
                    </div>
                    <div className="validation-error">
                        <h6>{props.amountError}</h6>
                    </div>
                </div>

                <div className="schedulePayment-dateContainer">
                    <div
                        className={`schedulePayment-date${props.dateError !== "" || props.after5 !== "" ? "-error" : ""}`}
                    >
                        <h5>Payment date</h5>
                        <DateOptions {...props} />
                    </div>
                    <div className="validation-error">
                        <h6>{props.dateError || props.after5}</h6>
                    </div>
                </div>

                {props.account.isChargedOffStatus ? (
                    <div className="schedulePayment-alerts">
                        <Alert level="info">
                            <span>{props.avoidLatePaymentText}</span>
                        </Alert>
                    </div>
                ) : (
                    <div className="schedulePayment-alerts">
                        {props.account.seeStatementMessage ? (
                            <Alert level="warning">
                                Payment summary is unavailable. See most recent statement for your payment and due date
                                details.
                            </Alert>
                        ) : (
                            <></>
                        )}

                        {!props.account.seeStatementMessage &&
                        props.account.minimumAmountDue > 0 &&
                        dayjs.tz(props.date).isAfter(props.account.paymentDueBy, "day") ? (
                            <Alert level="warning">
                                <span>Selected Date is Past the Payment Due Date</span>
                            </Alert>
                        ) : (
                            <></>
                        )}

                        <Alert level="info">
                            <span dangerouslySetInnerHTML={{ __html: props.createPaymentFooterText }} />
                        </Alert>
                        <Alert level="info">
                            <span dangerouslySetInnerHTML={{ __html: props.createPaymentBodyText }} />
                        </Alert>
                    </div>
                )}
                <div className={`schedulePayment-buttons${props.size}`}>
                    <ButtonPro type="button" variant="neutral" onClick={() => props.onCancel()}>
                        Cancel
                    </ButtonPro>
                    <ButtonPro
                        type="button"
                        variant="primary"
                        disabled={!props.canContinue}
                        onClick={() => props.onSubmit()}
                    >
                        Continue to Review
                    </ButtonPro>
                </div>
            </div>
            <div className={props.showCalendar ? "schedulePayment-spacer" : ""} />
        </div>
    );
}

function AmountOptions(props: SchedulePaymentViewProps): ReactElement {
    return (
        <>
            {props.account.isChargedOffStatus ? (
                <></>
            ) : (
                <>
                    <PaymentAmountOption
                        id="min"
                        header="Total Minimum Amount Due"
                        amount={props.account.minimumAmountDue}
                        selection={props.amountSelection}
                        onSelectionChanged={props.onSelectionChanged}
                        disabled={props.account.minimumAmountDue <= 0}
                    >
                        <ToolTip>{props.tooltip.BodyText}</ToolTip>
                    </PaymentAmountOption>
                    <PaymentAmountOption
                        id="statement"
                        header="Statement Balance"
                        amount={props.account.lastStatementBalance}
                        selection={props.amountSelection}
                        onSelectionChanged={props.onSelectionChanged}
                        disabled={props.account.lastStatementBalance <= 0}
                    />
                </>
            )}
            <PaymentAmountOption
                id="current"
                header="Current Balance"
                amount={props.account.currentBalance}
                selection={props.amountSelection}
                onSelectionChanged={props.onSelectionChanged}
            />
            <PaymentAmountOptionOther {...props} />
        </>
    );
}

function DateOptions(props: SchedulePaymentViewProps): ReactElement {
    const [show, setShow] = useState(false);
    let past5 = dayjs.tz().hour() >= 17;
    let earliest = past5 ? dayjs().add(1, "d") : dayjs();
    let due = getDueDate(props);

    return (
        <div className="schedulePayment-date-wrap">
            <Dropdown
                align={"start"}
                className="schedulePayment-date-dropdown"
                show={show}
                onToggle={(nextShow, meta) => {
                    setShow(nextShow);
                }}
            >
                <Dropdown.Toggle className="schedulePayment-date-toggle">
                    {props.date === undefined ? (
                        <p>Select Payment Date</p>
                    ) : (
                        <>
                            <p className="scheduled">Scheduled: {GetFormattedDate(props.date, true)}</p>
                        </>
                    )}
                    <ArrowDown />
                </Dropdown.Toggle>
                <Dropdown.Menu className="schedulePayment-date-menu">
                    <Dropdown.Item
                        className="schedulePayment-date-item"
                        onClick={() => {
                            props.setDate(earliest);
                        }}
                    >
                        <p>{GetFormattedDate(earliest, false)}</p>
                        &nbsp;
                        <h6>Earliest payment date</h6>
                    </Dropdown.Item>
                    {due !== undefined ? (
                        <>
                            <Dropdown.Item
                                id={due > earliest ? "schedulePayment-date-item" : "schedulePayment-date-item-disabled"}
                                className="schedulePayment-date-item"
                                onClick={() => {
                                    props.setDate(due);
                                }}
                                disabled={due <= earliest}
                            >
                                <p>{GetFormattedDate(due, false)}</p>
                                &nbsp;
                                <h6>Due date</h6>
                            </Dropdown.Item>
                        </>
                    ) : (
                        <></>
                    )}

                    <Dropdown.Item
                        className="schedulePayment-date-item"
                        onClick={() => {
                            props.setShowCalendar(true);
                        }}
                    >
                        <Calendar />
                        &nbsp;
                        <p>Choose a different date</p>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <DateOption {...props} />
        </div>
    );
}

function DateOption(props: SchedulePaymentViewProps): ReactElement {
    const ref = useRef(null as any);

    useEffect(() => {
        if (props.showCalendar) ref.current?.scrollIntoView();
    }, [props.showCalendar]);

    const calendarOverlay = (
        <span className="calendar">
            <PaymentCalendarOverlay
                date={props.date}
                setDate={props.setDate}
                dueDate={getDueDate(props)}
                onClose={() => props.setShowCalendar(false)}
                //Todo Uncomment For 69955
                //invalidDates={props.scheduledPayments.map((s) => dayjs.tz(s.paymentDate))}
            />
        </span>
    );

    return (
        <OverlayTrigger
            trigger="click"
            placement="bottom-start"
            overlay={calendarOverlay}
            show={props.showCalendar}
            rootClose={true}
            onToggle={() => props.setShowCalendar(false)}
        >
            <div
                ref={ref}
                id="schedulePayment-dateSelector"
                className="schedulePayment-dateSelector"
                onClick={() => {
                    props.setShowCalendar(!props.showCalendar);
                }}
            ></div>
        </OverlayTrigger>
    );
}

const getDueDate = (props: SchedulePaymentViewProps): dayjs.Dayjs | undefined => {
    return !props.account.isChargedOffStatus && !props.account.seeStatementMessage && props.account.paymentDueBy
        ? dayjs.tz(props.account.paymentDueBy)
        : undefined;
};

export default SchedulePaymentView;
