import { ReactElement } from "react";
import "./validationLi.scss";

export function ValidationLI(props: { touched?: boolean; success?: boolean; children: any }): ReactElement {
    return (
        <div className={`validationRequirements-listItem${!props.touched ? "" : props.success ? "-success" : "-fail"}`}>
            <h6 className="validationRequirements-bullet">{!props.touched ? "•" : props.success ? "✓" : "X"}</h6>
            <h6>{props.children}</h6>
        </div>
    );
}
