import { ReactElement } from "react";
import { Regex } from "../../../../utility";
import { ValidationLI } from "./validationLi";
import "./validationLi.scss";

type PasswordValidationProps = {
    touched?: boolean;
    confirmTouched?: boolean;
    criteria: PasswordCriteria;
};

export type PasswordCriteria = {
    length?: boolean;
    uppercase?: boolean;
    lowercase?: boolean;
    numericSpecial?: boolean;
    confirmed?: boolean;
    hasError?: boolean;
};

export function PasswordValidation(props: PasswordValidationProps): ReactElement {
    return (
        <div className="validationRequirements">
            <h6 className="validationRequirements-header">Your password must contain:</h6>
            <div className="validationRequirements-list">
                <ValidationLI touched={props.touched} success={props.criteria.length}>
                    Between 8 and 64 characters
                </ValidationLI>
                <ValidationLI touched={props.touched} success={props.criteria.uppercase}>
                    One UPPERCASE
                </ValidationLI>
                <ValidationLI touched={props.touched} success={props.criteria.lowercase}>
                    One lowercase
                </ValidationLI>
                <ValidationLI touched={props.touched} success={props.criteria.numericSpecial}>
                    A number (0-9) and/or a special character (! @ # ?)
                </ValidationLI>
                <ValidationLI touched={props.touched && props.confirmTouched} success={props.criteria.confirmed}>
                    Passwords must match
                </ValidationLI>
            </div>
        </div>
    );
}

export function ValidatePassword(password: string, confirmPass: string, passwordTouched: boolean): PasswordCriteria {
    let length = Regex.IsLength(password, 8, 64);
    let special = Regex.HasSpecial(password);
    let space = Regex.HasSpace(password);
    let uppercase = Regex.HasUppercase(password);
    let lowercase = Regex.HasLowercase(password);
    let numbers = Regex.HasNumbers(password);
    let numSpec = (numbers || special) && !space;
    let confirmed = password === confirmPass && password.length > 0 && confirmPass.length > 0;

    return {
        length: length,
        uppercase: uppercase,
        lowercase: lowercase,
        numericSpecial: numSpec,
        confirmed: confirmed,
        hasError: (!length || !uppercase || !lowercase || !numSpec || !confirmed) && passwordTouched
    } as PasswordCriteria;
}
