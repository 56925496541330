import { ReactElement, useEffect } from "react";
import { HelpLine, SetTitle } from "../../../utility";
import { Alert, AsyncElement, BackArrow, ButtonPro, GenericModal } from "../../generic";
import { IndexedCreditCard } from "../../generic/visuals/indexedCreditCard/indexedCreditCard";
import "./lockCard.scss";
import { LockCardState } from "./lockCardViewModel";

type LockCardViewViewProps = LockCardState & {
    lockCard: Function;
    unlockCard: Function;
    goToAccountHome: Function;
};
function LockCardView(props: LockCardViewViewProps): ReactElement {
    useEffect(() => {
        SetTitle("Lock Card");
        return () => SetTitle("");
    }, []);

    return (
        <div id={`lockcard${props.size}`}>
            <BackArrow available to={`/account/summary/${props.index}/home`}>
                <h2 className="lockcardHeader">Lock Card</h2>
            </BackArrow>

            <div id={`lockcardForm${props.size}`}>
                <>
                    {props.lockCardEnabled ? (
                        <>
                            <div className="lockcardForm-headerSubTitle">
                                <IndexedCreditCard account={props.account} size={"h4"} />
                            </div>
                            <AsyncElement loading={props.loading}>
                                <LockCardBody {...props} />

                                <div id="lockcard-buttongroup">
                                    <ButtonPro
                                        id="lockcard-submit"
                                        variant={props.lockStatus ? "primary" : "primary-neg"}
                                        onClick={() => {
                                            props.lockStatus ? props.unlockCard() : props.lockCard();
                                        }}
                                    >
                                        {props.lockStatus ? "Unlock My Card" : "Lock My Card"}
                                    </ButtonPro>
                                </div>
                            </AsyncElement>
                        </>
                    ) : (
                        <LockCardUnavailable {...props} />
                    )}
                </>
            </div>
            <>
                {props.lockStatus ? (
                    <GenericModal show={props.unlockCardSuccessModal} {...props.UnlockCardSuccessModalProps} />
                ) : (
                    <GenericModal show={props.lockCardSuccessModal} {...props.LockCardSuccessModalProps} />
                )}
            </>
        </div>
    );
}

function LockCardBody(props: LockCardViewViewProps): ReactElement {
    return (
        <>
            <div id="lockcard-body">
                <div>
                    <h5 id="lockcard-subheader">What happens when I lock my card?</h5>
                    <br />
                    <ul id="lockcard-list">
                        <li>
                            <p>New in-store purchases, online, phone, and cash advances will be declined.</p>
                        </li>
                        <li>
                            <p>
                                Recurring transactions, payments, dispute adjustments, credits, and fees will continue.
                            </p>
                        </li>
                    </ul>
                    <p>{`Select ${props.lockStatus ? "Unlock My Card" : "Lock My Card"} below to ${props.lockStatus ? "unlock" : "lock"} the card ending in ***${props.last4}.`}</p>
                </div>
            </div>
            <div id="lockcard-alert">
                <Alert level="info">
                    {"If you've lost your card and need a replacement, call our Customer Service team at " +
                        HelpLine(props.account.isCorpCard) +
                        "."}
                </Alert>
            </div>
        </>
    );
}

function LockCardUnavailable(props: LockCardViewViewProps): ReactElement {
    return (
        <div id="lockCardUnavailable">
            <div>
                <div id="lockCardUnavailable-body">
                    <div>
                        <h4 id="lockcard-subheader">Lock Card is Temporarily Unavailable</h4>
                        <div className="lockCard-unavailable-text">
                            Lock Card is temporarily unavailable. Please check back in 2-3 hours.
                        </div>
                    </div>
                </div>
                <div id="lockCardUnavailable-alert">
                    <Alert level="info">
                        {"If you've lost your card or need a replacement, call our Customer Service team at " +
                            HelpLine(props.account.isCorpCard) +
                            "."}
                    </Alert>
                </div>
                <div className="lockcard-buttongroup">
                    <ButtonPro
                        className="lockCard-unavailable-button"
                        variant="primary"
                        onClick={() => props.goToAccountHome()}
                    >
                        Back to Home
                    </ButtonPro>
                </div>
            </div>
        </div>
    );
}

export default LockCardView;
