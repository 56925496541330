import { ReactElement } from "react";
import { Error404View } from "./error404View";
import { useError404ViewModel } from "./error404ViewModel";

export function Error404Model(): ReactElement {
    const { hooks, state } = useError404ViewModel();

    function returnHome() {
        if (state.location.pathname?.toLowerCase() === state.deepLink.toLowerCase()) {
            hooks.clearLink();
        }

        if (!!state.access_token) {
            hooks.navigate("/account/dashboard");
        } else {
            hooks.navigate("/auth/login");
        }
    }

    return <Error404View {...state} returnHome={returnHome} />;
}
