import { ReactElement, useEffect } from "react";
import { DeliveryPreferencesLanding, MakePayment, SplitDeliveryLanding } from "../..";
import { useDeliveryPreferencesContext, usePaymentContext } from "../../../api/backend";
import PrivateView from "./privateView";
import { usePrivateViewModel } from "./privateViewModel";

export type PrivateViewProps = {
    children?: ReactElement;
};

export function PrivateViewContext(props: PrivateViewProps): ReactElement {
    const { showMakePayment, setShowMakePayment } = usePaymentContext();
    const {
        showPreferencesLanding,
        setShowPreferencesLanding,
        showSplitDeliveryLanding,
        setShowSplitDeliveryLanding,
        landing
    } = useDeliveryPreferencesContext();

    useEffect(() => {
        landing();
    }, []);

    return (
        <>
            <PrivateViewModel {...props} />
            <MakePayment showSchedule={showMakePayment} setShowSchedule={setShowMakePayment} />
            <DeliveryPreferencesLanding
                showLanding={showPreferencesLanding}
                setShowLanding={setShowPreferencesLanding}
            />
            <SplitDeliveryLanding
                showSplitLanding={showSplitDeliveryLanding}
                setShowSplitLanding={setShowSplitDeliveryLanding}
            />
        </>
    );
}

function PrivateViewModel(props: PrivateViewProps): ReactElement {
    const { state } = usePrivateViewModel();

    return <PrivateView {...props} {...state} />;
}
