import { AxiosResponse } from "axios";
import { useContext } from "react";
import { ForgotPasswordAttemptsResponse, ForgotUsernamePasswordMFAResponse, MFAReturn } from ".";
import { useIdentityPost, useSessionStorage } from "../../../hooks";
import { DigitalActionsContext } from "../../backend/digitalActionsClient/digitalActionsContext";

export const useIdentityPremierContext = () => {
    const client = useIdentityPremierClient();

    return { ...client };
};

const useIdentityPremierClient = () => {
    const context = useContext(DigitalActionsContext);
    const [, storeValue] = useSessionStorage(["accountMenus"]);

    async function Login(username: string, password: string): Promise<AxiosResponse<MFAReturn, any>> {
        return await useIdentityPost<MFAReturn>(
            "/PremierAccount/Login",
            `grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`
        ).then((x) => {
            if ((x.data.messagingInfo?.customDataClasSaveResult?.errors?.length ?? 0) === 0) {
                context.setDigitalActions(x.data.accountLevelMenu);
                storeValue("accountMenus", JSON.stringify(x.data.accountLevelMenu));
                context.setLoading(false);
            }
            return x;
        });
    }

    async function SendCode(
        contactType: string,
        onlineUserId: number,
        accountId: number,
        MFADeliveryAddress: string
    ): Promise<AxiosResponse<any, any>> {
        return await useIdentityPost(
            "/PremierAccount/sendcode",
            `contactType=${contactType}&onlineUserId=${onlineUserId}&accountId=${accountId}&MFADeliveryAddress=${MFADeliveryAddress}&platform=Web`
        );
    }

    async function ForgotUsernamePassword(
        ssn: string,
        accountIdentifier: string,
        eventType: string
    ): Promise<AxiosResponse<ForgotUsernamePasswordMFAResponse>> {
        return await useIdentityPost(
            "/PremierAccount/ForgotUsernamePassword",
            `grant_type=mulecode&SocialSecurityNumber=${ssn}&AccountIdentifier=${accountIdentifier}&eventType=${eventType}`
        );
    }

    async function ForgotPasswordAttempts(
        ssn: string,
        last4: string,
        accountId: string
    ): Promise<AxiosResponse<ForgotPasswordAttemptsResponse, any>> {
        return await useIdentityPost(
            "/PremierAccount/ForgotPasswordAttempts",
            `ssn=${ssn}&creditCardNumber=${last4}&accountId=${accountId}`
        );
    }

    async function SaveForgotUsernameDetails(
        ssn: string,
        last4: string,
        accountId: string
    ): Promise<AxiosResponse<any, any>> {
        return await useIdentityPost(
            "/PremierAccount/ForgotDetails",
            `ssn=${ssn}&creditCardNumber=${last4}&accountId=${accountId}&eventType=ForgotPassword`
        );
    }

    async function SaveForgotPasswordDetails(
        ssn: string,
        last4: string,
        accountId: string
    ): Promise<AxiosResponse<any, any>> {
        return await useIdentityPost(
            "/PremierAccount/ForgotDetails",
            `ssn=${ssn}&creditCardNumber=${last4}&accountId=${accountId}&eventType=ForgotUsername`
        );
    }

    return {
        Login,
        SendCode,
        ForgotUsernamePassword,
        ForgotPasswordAttempts,
        SaveForgotUsernameDetails,
        SaveForgotPasswordDetails
    };
};
