import { ReactElement, createContext, useEffect, useState } from "react";
import { IntermediateWarningCms, useCmsContext } from "../api/backend";
import { useSessionStorage } from "../hooks";
import { Children } from "../types";
import { NotImplemented } from "../utility";
import { isAndroid, isIOS, isMobile, isTablet } from "../utility/device";

export type AppDownloadBannerContextProps = {
    showBanner: boolean;
    setShowBanner: React.Dispatch<React.SetStateAction<boolean>>;
    warning: IntermediateWarningCms | undefined;
    setWarning: React.Dispatch<React.SetStateAction<IntermediateWarningCms | undefined>>;
};

export const AppDownloadBannerContext = createContext<AppDownloadBannerContextProps>({
    showBanner: false,
    setShowBanner: NotImplemented,
    warning: undefined,
    setWarning: NotImplemented
});

const useAppDownloadBannerContextProps = (): AppDownloadBannerContextProps => {
    const [storage] = useSessionStorage(["ShowAppBanner"]);
    const [showBanner, setShowBanner] = useState((storage["ShowAppBanner"] as boolean) ?? false);
    const [warning, setWarning] = useState<IntermediateWarningCms>();

    return {
        showBanner,
        setShowBanner,
        warning,
        setWarning
    };
};

export function AppDownloadBanner(props: Children): ReactElement {
    const contextProps = useAppDownloadBannerContextProps();
    const [session, storeSession] = useSessionStorage(["ShowAppBanner"]);
    const { IntermediateWarning } = useCmsContext();

    useEffect(() => {
        IntermediateWarning().then((res) => {
            contextProps.setWarning(res.data);
        });

        if ([undefined, "true"].includes(session["ShowAppBanner"])) {
            if ((isIOS() || isAndroid()) && (isMobile() || isTablet())) {
                contextProps.setShowBanner(true);
                storeSession("ShowAppBanner", true);
            } else {
                contextProps.setShowBanner(false);
                storeSession("ShowAppBanner", false);
            }
        } else {
            contextProps.setShowBanner(false);
        }
    }, []);

    useEffect(() => {
        storeSession("ShowAppBanner", contextProps.showBanner);
    }, [contextProps.showBanner]);

    return <AppDownloadBannerContext.Provider value={contextProps}>{props.children}</AppDownloadBannerContext.Provider>;
}
