import { ReactElement } from "react";
import { Size } from "../../../../types";
import { ColorBlocks1, Download } from "../../icons";
import "./brandedCard.scss";

type BrandedCardProps = {
    id: string;
    header?: string | ReactElement;
    subtitle?: string | ReactElement;
    children: ReactElement;
    size: Size;
    download?: boolean;
    downloadPDF?: Function;
    downloadTitle?: string;
    displayArt?: boolean;
};

export function BrandedCard(props: BrandedCardProps): ReactElement {
    return (
        <div id={props.id} data-testid={props.id} className="brandedCard">
            <div className={`brandedCard-header${props.size}`}>
                <div className="brandedCard-headerContent">
                    {props.header ? <h4>{props.header}</h4> : props.header}
                    {!props.displayArt && props.displayArt !== undefined ? (
                        <></>
                    ) : (
                        <ColorBlocks1 className="brandedCard-headerBlocks" />
                    )}
                </div>
                <div className="brandedCard-headerSub">
                    {props.subtitle ? (
                        typeof props.subtitle === "string" ? (
                            <div className="brandedCard-headerSubTitle">
                                <h6>{props.subtitle}</h6>
                            </div>
                        ) : (
                            props.subtitle
                        )
                    ) : (
                        <></>
                    )}

                    {props.download && props.downloadPDF !== undefined ? (
                        <div className="brandedCard-headerSub-download">
                            <Download
                                className="brandedCard-download"
                                onClick={() => {
                                    props.downloadPDF?.();
                                }}
                                title={props.downloadTitle}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>

            {props.children}
        </div>
    );
}
