import { FormikProps } from "formik";
import { ReactElement } from "react";
import { ReviewPaymentProps } from "./reviewPaymentModel";
import ReviewPaymentView from "./reviewPaymentView";
import { ReviewPaymentViewModel } from "./reviewPaymentViewModel";

type ReviewPaymentFormProps = {
    props: ReviewPaymentProps;
    vm: ReviewPaymentViewModel;
    formProps: FormikProps<{}>;
};

export function ReviewPaymentForm(form: ReviewPaymentFormProps): ReactElement {
    const { props, formProps } = { ...form };
    const { state } = { ...form.vm };

    function onCancel() {
        props.setAmountSelection("");
        props.setAmount("");
        props.setDate(undefined);
        props.setPaymentAccountId(0);
        props.setShow(false);
    }

    function onEdit() {
        props.setShow(false);
        props.setShowSchedule(true);
    }

    return <ReviewPaymentView {...props} {...state} {...formProps} onCancel={onCancel} onEdit={onEdit} />;
}
