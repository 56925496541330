import { useEffect, useState } from "react";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { UserAccount } from "../../../api/backend";
import { useSessionStorage, useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { IsNullOrEmpty } from "../../../utility";

type Error500ViewModel = {
    hooks: Error500Hooks;
    state: Error500State;
};

type Error500Hooks = {
    navigate: NavigateFunction;
};

export type Error500State = {
    size: Size;
    location: Location;
    loggedIn: boolean;
};

export function useError500ViewModel(): Error500ViewModel {
    const { size } = useViewport(true, true);
    const location = useLocation();
    const navigate = useNavigate();
    const [storage] = useSessionStorage(["access_token", "mfaRoute", "accounts"]);
    const [loggedIn, setLoggedIn] = useState(getLoggedIn());

    useEffect(() => {
        setLoggedIn(getLoggedIn());
    }, [storage.access_token, storage.mfaRoute, storage.accounts]);

    function getLoggedIn(): boolean {
        let valid =
            !IsNullOrEmpty(storage.access_token) &&
            storage.mfaRoute === "Login" &&
            ((JSON.parse(storage.accounts ?? "[]") as UserAccount[]).length ?? 0) > 0;
        return valid;
    }

    return {
        hooks: { navigate },
        state: {
            size,
            location,
            loggedIn
        }
    };
}
