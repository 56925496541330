import { ReactElement } from "react";
import NeedAssistanceView from "./needAssistanceView";
import { useNeedAssistanceViewModel } from "./needAssistanceViewModel";

export type NeedAssistanceProps = {
    showAssistance: boolean;
    setShowAssistance: React.Dispatch<React.SetStateAction<boolean>>;
    isCorpCard: boolean;
};

export function NeedAssistanceModel(props: NeedAssistanceProps): ReactElement {
    const { state } = useNeedAssistanceViewModel();

    return <NeedAssistanceView {...props} {...state} />;
}
