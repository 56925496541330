import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function CreditIncrease(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="49"
            height="49"
            viewBox="0 0 49 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M46.98 42.47L44 38V27.5C44 23.5 36.4 19.22 33.5 17"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M37.3601 30.086L30.0641 24.24C29.5753 23.7512 28.9124 23.4766 28.2211 23.4766C27.5299 23.4766 26.8669 23.7512 26.3781 24.24C25.8893 24.7288 25.6147 25.3917 25.6147 26.083C25.6147 26.7742 25.8893 27.4372 26.3781 27.926L33.4441 35.9V40.9C33.4441 43.262 36.8201 46.99 36.8201 46.99"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M27.5 44H4.748C4.019 43.9995 3.32004 43.7095 2.80475 43.1938C2.28946 42.6782 2 41.979 2 41.25V4.748C2 4.01919 2.28952 3.32022 2.80487 2.80487C3.32022 2.28952 4.01919 2 4.748 2H30.748C31.1091 1.99974 31.4668 2.07061 31.8005 2.20857C32.1343 2.34652 32.4376 2.54887 32.6931 2.80404C32.9487 3.05922 33.1515 3.36223 33.2899 3.69577C33.4283 4.02932 33.4997 4.38687 33.5 4.748V26.994"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M20 8H26V14" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26 8L8 24.5" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.98 32V38" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.98 27.5V38" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
