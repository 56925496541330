import { FormikHelpers } from "formik";
import { ReactElement, useEffect } from "react";
import { RequestCLIView } from "./requestCLIView";
import { useRequestCLIViewModel } from "./requestCLIViewModel";

export function RequestCLI(): ReactElement {
    const { state, api, hooks } = useRequestCLIViewModel();

    useEffect(() => {
        if (!hooks.actionAllowed("creditLimitIncrease", state.accounts![state.accountIndex].accountHandle)) {
            hooks.navigate(`/account/dashboard`, { relative: "path" });
        }
    }, [state.accountIndex]);

    function onSubmit(actions: FormikHelpers<{}>) {
        api.cliGet()
            .then((res) => {
                let isValidResult = false;
                let modalMessage = res?.data?.ResultMessage;
                let modalHeader = "Thanks for Submitting Your Request";

                switch (res.data.ResultCode) {
                    case "CLI_GRANT":
                        let userAccountTimeout = process.env.REACT_APP_ENV !== "Prod" ? 120000 : 5000;
                        state.setTimeoutId(
                            setTimeout(() => {
                                api.userAccounts();
                            }, userAccountTimeout)
                        );

                        isValidResult = true;
                        modalHeader = "Congratulations!";
                        break;
                    case "CLI_GRANTLAST48":
                        isValidResult = true;
                        break;
                    case "CLI_DENIED":
                        isValidResult = true;
                        break;
                    case "CLI_DENYLAST48":
                        isValidResult = true;
                        break;
                    case "CLI_ELMNID0000":
                        isValidResult = true;
                        break;
                    case "CLI_PRIME":
                        isValidResult = true;
                        break;
                    case "CLI_LAST48":
                        isValidResult = true;
                        break;
                    case "CLI_EMPCORP":
                        isValidResult = true;
                        modalHeader = "Unable to Submit Online Request";
                        break;
                    case "CLI_MULTRAN":
                        isValidResult = true;
                        modalHeader = "Unable to Submit Online Request";
                        break;
                    case "CLI_RSTATEMENT":
                        isValidResult = true;
                        modalHeader = "Unable to Submit Online Request";
                        break;
                }
                state.setMessageHeader(modalHeader);
                state.setMessageBody(modalMessage);

                if (isValidResult) {
                    state.setShowSubmitted(true);
                } else {
                    state.setShowError(true);
                }
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    return <RequestCLIView {...state} onSubmit={onSubmit} />;
}
