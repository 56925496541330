import { Form, Formik } from "formik";
import { ReactElement } from "react";
import {
    CancelEnrollSetup,
    CancelPasswordReset,
    CancelSecureAccessCode,
    InvalidEnrollSession,
    NeedAssistance
} from "../..";
import { FormProps } from "../../../types";
import { Alert, ButtonPro } from "../../generic";
import { Info } from "../../generic/icons";
import { LoginCard } from "../common/loginCardModel";
import { MfaAssistanceModel } from "../needAssistance/mfaAssistance/mfaAssistanceModel";
import "./loginDeliverMfa.scss";
import { LoginDeliverMfaState } from "./loginDeliveryMfaViewModel";
import { MfaRadioOption } from "./mfaRadioOption/mfaRadioOption";

type LoginDeliverMfaViewProps = LoginDeliverMfaState & {
    cancelModal: Function;
    onSubmit: Function;
    onShowAssistance: Function;
};

export function LoginDeliverMfaView(props: LoginDeliverMfaViewProps): ReactElement {
    const subtitle =
        props.securityType === "Enrollment" ? "Step 3 of 4" : props.securityType !== "Login" ? "Step 2 of 3" : "";

    return (
        <>
            {props.mfaOptions?.noContactInfo ? (
                <MfaAssistanceModel isNoPhoneEmail={props.mfaOptions.noContactInfo} />
            ) : (
                <LoginCard id="loginDeliverMfa" size={props.size} header="Quick Security Check" subtitle={subtitle}>
                    <Formik
                        initialValues={{}}
                        onSubmit={(values, actions) => {
                            props.onSubmit();
                        }}
                    >
                        {(formProps: FormProps<any>) => (
                            <Form className={`loginCard-form${props.size}`}>
                                <div id="loginDeliverMfa-options">
                                    <MfaRadioOption
                                        id="textOption"
                                        type="text"
                                        mfaOptions={props.mfaOptions}
                                        setSelection={props.setSelection}
                                        selection={props.selection}
                                        appSettings={props.appSettings}
                                    />
                                    <MfaRadioOption
                                        id="callOption"
                                        type="call"
                                        mfaOptions={props.mfaOptions}
                                        setSelection={props.setSelection}
                                        selection={props.selection}
                                        appSettings={props.appSettings}
                                    />
                                    <MfaRadioOption
                                        id="emailOption"
                                        type="email"
                                        mfaOptions={props.mfaOptions}
                                        setSelection={props.setSelection}
                                        selection={props.selection}
                                        appSettings={props.appSettings}
                                    />
                                </div>
                                <Alert level="info">
                                    Note: Each time you select 'Request code' you are opting to receive your secure
                                    access code via text message, email, or voice call to the selected phone number or
                                    email address listed on your account.
                                </Alert>
                                <div className="loginCard-buttons">
                                    <ButtonPro
                                        type="button"
                                        className="loginDeliverMfa-cancel"
                                        variant="neutral"
                                        onClick={() => props.cancelModal()}
                                    >
                                        Cancel
                                    </ButtonPro>
                                    <ButtonPro
                                        type="submit"
                                        className="loginDeliverMfa-sumbit"
                                        disabled={!props.canContinue || formProps.isSubmitting}
                                        isSubmitting={formProps.isSubmitting}
                                    >
                                        Request Code
                                    </ButtonPro>
                                </div>
                                <div id="loginDeliverMfa-assistance">
                                    <ButtonPro
                                        type="button"
                                        className="loginDeliverMfa-assistanceButton"
                                        variant="tertiary"
                                        onClick={() => props.onShowAssistance()}
                                    >
                                        <Info id="loginDeliverMfa-assistanceIcon" />
                                        <h1 id="loginDeliverMfa-assistanceText">Need Assistance?</h1>
                                    </ButtonPro>
                                </div>

                                <NeedAssistance
                                    showAssistance={props.showAssistance}
                                    setShowAssistance={props.setShowAssistance}
                                    isCorpCard={props.mfaOptions?.isCorpCard ?? false}
                                />

                                {props.showCancel ? (
                                    props.securityType === "Login" ? (
                                        <CancelSecureAccessCode show={props.showCancel} setShow={props.setShowCancel} />
                                    ) : props.securityType === "Enrollment" ? (
                                        <CancelEnrollSetup show={props.showCancel} setShow={props.setShowCancel} />
                                    ) : (
                                        <CancelPasswordReset show={props.showCancel} setShow={props.setShowCancel} />
                                    )
                                ) : (
                                    <></>
                                )}
                            </Form>
                        )}
                    </Formik>
                </LoginCard>
            )}

            <InvalidEnrollSession show={props.showError} setShow={props.setShowError} />
        </>
    );
}
