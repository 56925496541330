import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Pencil(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.19 1.81011C21.8494 1.47091 21.4449 1.20257 20.9999 1.02059C20.555 0.838607 20.0784 0.746585 19.5977 0.749839C19.117 0.753094 18.6417 0.85156 18.1993 1.03955C17.7568 1.22754 17.356 1.50133 17.02 1.84511L2.52 16.3451L0.75 23.2501L7.655 21.4791L22.155 6.97911C22.4989 6.64324 22.7727 6.24254 22.9608 5.80018C23.1488 5.35782 23.2473 4.88257 23.2506 4.40191C23.2539 3.92124 23.1618 3.4447 22.9798 2.99983C22.7977 2.55497 22.5293 2.1506 22.19 1.81011V1.81011Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M16.606 2.26001L21.74 7.39401" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.521 16.344L7.66 21.474" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
