import { createContext, useState } from "react";
import { PaymentResponse } from ".";
import { PaymentCms } from "..";
import { NotImplemented } from "../../../utility";

export type PaymentContextProps = {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    history: PaymentResponse[];
    setHistory: React.Dispatch<React.SetStateAction<PaymentResponse[]>>;
    scheduled: PaymentResponse[];
    setScheduled: React.Dispatch<React.SetStateAction<PaymentResponse[]>>;
    showMakePayment: boolean;
    setShowMakePayment: React.Dispatch<React.SetStateAction<boolean>>;
    paymentCms: PaymentCms;
    setPaymentCms: React.Dispatch<React.SetStateAction<PaymentCms>>;
};

export const PaymentContext = createContext<PaymentContextProps>({
    loading: false,
    setLoading: NotImplemented,
    history: [],
    setHistory: NotImplemented,
    scheduled: [],
    setScheduled: NotImplemented,
    showMakePayment: false,
    setShowMakePayment: NotImplemented,
    paymentCms: {} as PaymentCms,
    setPaymentCms: NotImplemented
});

export const usePaymentContextProps = (): PaymentContextProps => {
    const [loading, setLoading] = useState(false);
    const [scheduled, setScheduled] = useState([] as PaymentResponse[]);
    const [history, setHistory] = useState([] as PaymentResponse[]);
    const [showMakePayment, setShowMakePayment] = useState(false);
    const [paymentCms, setPaymentCms] = useState({} as PaymentCms);

    return {
        loading,
        setLoading,
        scheduled,
        setScheduled,
        history,
        setHistory,
        showMakePayment,
        setShowMakePayment,
        paymentCms,
        setPaymentCms
    };
};
