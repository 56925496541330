import { Formik, FormikHelpers, FormikProps } from "formik";
import { ReactElement } from "react";
import { PaymentResponse } from "../../../../api/backend";
import { Modal, ModalProps } from "../../../generic";
import { ReviewCancelPaymentForm } from "./reviewCancelPaymentForm";
import { useReviewCancelPaymentViewModel } from "./reviewCancelPaymentViewModel";

export type ReviewCancelPaymentProps = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    cancelPayment: PaymentResponse | undefined;
    setCancelPayment: React.Dispatch<React.SetStateAction<PaymentResponse | undefined>>;
};

export function ReviewCancelPaymentModal(props: ModalProps & ReviewCancelPaymentProps): ReactElement {
    return (
        <Modal show={props.show && props.cancelPayment !== undefined}>
            <ReviewCancelPaymentModel {...props} />
        </Modal>
    );
}

export function ReviewCancelPaymentModel(props: ReviewCancelPaymentProps): ReactElement {
    const vm = useReviewCancelPaymentViewModel();

    function onSubmit(actions: FormikHelpers<{}>) {
        vm.api
            .paymentDelete(props.cancelPayment!.paymentID)
            .then(() => {
                props.setShow(false);
                vm.state.setCurIndex(undefined);
                props.setCancelPayment(undefined);
                props.setShowSuccess(true);
            })
            .catch((err) => {
                vm.state.setError("Unable to cancel your payment");
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    return (
        <Formik
            initialValues={{}}
            onSubmit={(_, actions) => {
                onSubmit(actions);
            }}
        >
            {(formProps: FormikProps<{}>) => <ReviewCancelPaymentForm props={props} vm={vm} formProps={formProps} />}
        </Formik>
    );
}
