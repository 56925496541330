import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function SmartPhoneMoney(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="49"
            height="49"
            viewBox="0 0 49 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25.5401 17.1801C25.9678 17.7515 26.5282 18.2104 27.1728 18.517C27.8174 18.8236 28.5269 18.9688 29.2401 18.9401C31.5201 18.9401 33.3801 17.5601 33.3801 15.8401C33.3801 14.1201 31.5201 12.7601 29.2401 12.7601C26.9601 12.7601 25.1201 11.3601 25.1201 9.66005C25.1201 7.96005 26.9801 6.50005 29.2401 6.50005C29.9564 6.46972 30.6694 6.61408 31.3174 6.9207C31.9655 7.22732 32.5293 7.68698 32.9601 8.26005"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M29.2402 18.9399V21.9999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M29.2402 3.5V6.56" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M26 30.5V44C26 44.7956 25.6839 45.5587 25.1213 46.1213C24.5587 46.6839 23.7956 47 23 47H5C4.20435 47 3.44129 46.6839 2.87868 46.1213C2.31607 45.5587 2 44.7956 2 44V11C2 10.2044 2.31607 9.44129 2.87868 8.87868C3.44129 8.31607 4.20435 8 5 8"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M2 38.5H26" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M21.5 24H14C13.2044 24 12.4413 23.6839 11.8787 23.1213C11.3161 22.5587 11 21.7956 11 21V5C11 4.20435 11.3161 3.44129 11.8787 2.87868C12.4413 2.31607 13.2044 2 14 2H21.5"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M36.5 2H44C44.7956 2 45.5587 2.31607 46.1213 2.87868C46.6839 3.44129 47 4.20435 47 5V21C47 21.7956 46.6839 22.5587 46.1213 23.1213C45.5587 23.6839 44.7956 24 44 24H36.5"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
