import { ReactElement } from "react";
import { Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GetCardOptions } from "../cardNav";
import { CardNavState } from "../cardNavViewModel";
import { NavMenu } from "../navMenu/navMenu";
import "./cardNavAlt.scss";

type CardNavAltViewProps = CardNavState & {
    onCardSelected: Function;
};

export function CardNavAltView(props: CardNavAltViewProps): ReactElement {
    const navigate = useNavigate();

    return (
        <div className="cardNavAlt">
            <Navbar className={`cardNavAlt-content${props.size}`}>
                {GetCardOptions(props.accounts!, props.accountIndex, props.onCardSelected, navigate, true)}
                <NavMenu isResponsive />
            </Navbar>
        </div>
    );
}
