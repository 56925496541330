import { useEffect, useState } from "react";
import { CmsToolTip, ToolTipCode, ToolTipsCms, useCmsContext } from "../api/backend";

export const useTooltipCms = () => {
    const { ToolTips, EmptyTip } = useCmsContext();
    const [tooltips, setTooltips] = useState<ToolTipsCms>();

    useEffect(() => {
        ToolTips().then((res) => {
            setTooltips(res.data);
        });
    }, []);

    const getTooltip = (code: ToolTipCode): CmsToolTip => {
        return tooltips?.ToolTips.find((t) => t.ToolTipCode === code) ?? EmptyTip("totalMinDueToolTip");
    };

    return { tooltips, getTooltip };
};
