import { AxiosResponse } from "axios";
import { useState } from "react";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import { useEnrollmentContext } from "../../../../api/backend";
import { MFAOptions, useIdentityPremierContext } from "../../../../api/identityServer";
import { useMfaContext } from "../../../../contexts";
import { SecurityType } from "../../../../contexts/mfaContext";
import { useSessionStorage, useViewport } from "../../../../hooks";
import { Size } from "../../../../types";

type MfaAssistanceViewModel = {
    state: MfaAssistanceState;
    hooks: MfaAssistanceHooks;
    api: LoginDeliverMfaApi;
};

type LoginDeliverMfaApi = {
    enrollSendCode: (sessionId: string) => Promise<AxiosResponse<any, any>>;
    SendCode: (
        contactType: string,
        onlineUserId: number,
        accountId: number,
        MFADeliveryAddress: string
    ) => Promise<AxiosResponse<any, any>>;
};

export type MfaAssistanceState = {
    size: Size;
    mfaOptions: MFAOptions | undefined;
    securityType: SecurityType | undefined;
    showCancelModal: boolean;
    setShowCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
    headerText: string;
    bodyText: string;
    subtitle: string;
    searchParams: URLSearchParams;
};
export type MfaAssistanceHooks = {
    navigate: NavigateFunction;
    removeValue: (names: string[]) => void;
};

const storageKeys = ["mfaCustServReload"];

export function useMfaAssistanceViewModel(): MfaAssistanceViewModel {
    const { size } = useViewport(false, true);
    const { mfaOptions, securityType } = useMfaContext();
    const navigate = useNavigate();
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [searchParams] = useSearchParams();
    const { SendCode } = useIdentityPremierContext();
    const { MfaRequestCode } = useEnrollmentContext();
    const [, , removeValue] = useSessionStorage(storageKeys);

    const headerText = !mfaOptions?.noContactInfo
        ? "Having Problems Receiving Your Secure Access Code?"
        : "We Don't Have a Phone Number or Email Address on File";
    const bodyText =
        !mfaOptions?.noContactInfo && securityType !== "OMT" && securityType !== "Enrollment"
            ? "To receive the code necessary to" +
              (securityType !== "Login" ? " reset your password" : " log in to your account") +
              ", please contact our Customer Service team."
            : "To continue, please contact our Customer Service team to receive your secure access code.";
    const subtitle =
        securityType === "OMT" || securityType === "Enrollment"
            ? "Step 3 of 4"
            : securityType !== "Login"
              ? "Step 2 of 3"
              : "";

    async function enrollSendCode(sessionId: string): Promise<AxiosResponse<any, any>> {
        return await MfaRequestCode({ sessionId: sessionId, deliveryMethod: "CustomerService" });
    }

    return {
        hooks: {
            navigate,
            removeValue
        },
        state: {
            size,
            mfaOptions,
            securityType,
            showCancelModal,
            setShowCancelModal,
            headerText,
            bodyText,
            subtitle,
            searchParams
        },
        api: {
            enrollSendCode,
            SendCode
        }
    };
}
