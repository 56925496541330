import { ReactElement } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import { Info, InfoFill } from "../../icons";
import "./tooltip.scss";
import { ToolTipProps } from "./tooltipModel";
import { ToolTipState } from "./tooltipViewModel";

type ToolTipViewProps = ToolTipProps & ToolTipState;

export function ToolTipView(props: ToolTipViewProps): ReactElement {
    return (
        <div id={props.id} className="tooltipView">
            <div id={`tt-root-${props.uuid}`} ref={props.target}>
                {!props.show ? (
                    <Info className="tooltipView-icon-outline" />
                ) : (
                    <InfoFill className="tooltipView-icon-fill" />
                )}
            </div>
            <Overlay show={props.show} target={props.target.current} flip={true}>
                <Tooltip id="tooltip-pro" ref={props.tip} bsPrefix={`bs-tooltip`}>
                    <div
                        className={`tooltipView-wrapper`}
                        ref={(node) => {
                            if (node) {
                                let parent = node.parentElement;
                                let root = getParentById(node, "tooltip-pro");

                                root?.classList.forEach((x) => {
                                    if (x.includes("bs-tooltip-")) {
                                        if (parent) {
                                            parent.className =
                                                "bs-tooltip-inner-" + x.substring(x.lastIndexOf("-") + 1, x.length);
                                        }
                                    }
                                });
                            }
                        }}
                    >
                        <div className="tooltipView-tab" />
                        <h6>{props.children}</h6>
                    </div>
                </Tooltip>
            </Overlay>
        </div>
    );
}

function getParentById(element: HTMLElement | null, id: String): HTMLElement | null {
    if (!element || element.id === id) return element;
    else return getParentById(element.parentElement, id);
}
