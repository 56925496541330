import { ReactElement } from "react";
import { UserAccount } from "../../../../api/backend";
import { CardPaymentView } from "./cardPaymentView";
import { useCardPaymentViewModel } from "./cardPaymentViewModel";

export type CardPaymentProps = {
    index: number;
    account: UserAccount;
};

export function CardPaymentModel(props: CardPaymentProps): ReactElement {
    const { hooks, state } = useCardPaymentViewModel(props);

    return <CardPaymentView {...props} {...state} makeNewPayment={hooks.makeNewPayment} />;
}
