import { ReactElement } from "react";
import { BankAccountGetResponse } from "../../../../api/backend";
import { GenericModal, GenericModalProps, ModalProps } from "../../../generic";
import "./paymentAccountsDeleteSuccess.scss";

export type PaymentAccountsDeleteSuccessProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    deletedAccount: BankAccountGetResponse | undefined;
    setDeletedAccount: React.Dispatch<React.SetStateAction<BankAccountGetResponse | undefined>>;
};

export function PaymentAccountsDeleteSuccess(props: ModalProps & PaymentAccountsDeleteSuccessProps): ReactElement {
    const state = {
        headerText: "Payment Account Deleted",
        primaryButton: {
            text: "Return to Payments",
            action: () => {
                props.setDeletedAccount(undefined);
                props.setShow(false);
            }
        }
    } as GenericModalProps;

    return (
        <GenericModal show={props.show} {...state}>
            <div className="deleteconfirmation-text">
                <p className="deleteconfirmation-bankname">{props.deletedAccount?.bankName}</p>
                <h6 className="deleteconfirmation-accountnumber">{props.deletedAccount?.maskedAccountNumber}</h6>
            </div>
        </GenericModal>
    );
}
