import { ReactElement } from "react";
import { useDisclosuresViewModelTab } from "./disclosuresViewModelTab";
import { DisclosuresViewPdf } from "./disclosuresViewPdf";
import { DisclosuresViewPrintPreview } from "./disclosuresViewPrintPreview";

export function DisclosuresModelTab(): ReactElement {
    const { state } = useDisclosuresViewModelTab();

    function onPrint() {
        document.getElementById("disclosure")!.style.height = "100%";

        window.print();

        document.getElementById("disclosure")!.style.height = "100vh";
    }

    if (state.isPdf) {
        return <DisclosuresViewPdf {...state} />;
    }

    return <DisclosuresViewPrintPreview {...state} onPrint={onPrint} />;
}
