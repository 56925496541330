import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Advice(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.32024 8.61998L5.85024 7.09998C5.64718 6.89587 5.3765 6.77303 5.08918 6.75462C4.80185 6.7362 4.5177 6.82346 4.29024 6.99998C4.12816 7.12489 3.99951 7.28796 3.91575 7.47465C3.832 7.66135 3.79575 7.86587 3.81024 8.06998L4.65024 12.14"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.1602 10.35L17.8402 5.34997C17.7625 5.13892 17.628 4.95344 17.4515 4.81402C17.275 4.67461 17.0635 4.58669 16.8402 4.55997C16.5366 4.5281 16.2323 4.61412 15.9904 4.80025C15.7484 4.98637 15.5872 5.25836 15.5402 5.55997L15.1602 7.41997"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.1852 13.5268C11.4033 10.9125 10.09 8.66887 8.25172 8.51548C6.41347 8.36209 4.74644 10.357 4.52829 12.9713C4.31014 15.5856 5.62349 17.8292 7.46173 17.9826C9.29998 18.136 10.967 16.141 11.1852 13.5268Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.86336 13.333C8.92904 12.546 8.53165 11.8704 7.97577 11.824C7.4199 11.7776 6.91603 12.378 6.85036 13.165C6.78468 13.9521 7.18207 14.6277 7.73795 14.6741C8.29382 14.7205 8.79769 14.12 8.86336 13.333Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.4717 16.3514C20.1594 15.432 20.4674 12.7405 19.1596 10.3398C17.8518 7.9391 15.4234 6.7383 13.7357 7.65773C12.0479 8.57716 11.7399 11.2687 13.0477 13.6694C14.3555 16.07 16.7839 17.2708 18.4717 16.3514Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.7541 13.1988C17.2197 12.9452 17.3058 12.2049 16.9465 11.5453C16.5872 10.8857 15.9185 10.5566 15.4529 10.8103C14.9873 11.0639 14.9011 11.8042 15.2605 12.4638C15.6198 13.1234 16.2885 13.4525 16.7541 13.1988Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.48 15.9999L12.68 19.3899C12.7492 19.4934 12.8493 19.5723 12.966 19.6154C13.0828 19.6585 13.2102 19.6636 13.33 19.6299C13.4295 19.6027 13.52 19.5494 13.592 19.4756C13.6641 19.4018 13.7152 19.3101 13.74 19.2099L14.64 15.6699"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
