import { ReactElement } from "react";
import { FormatMoney, GetFormattedDate } from "../../../../utility";
import { ArrowDown, ArrowUp } from "../../../generic/icons";
import { ActivityRowProps } from "./activityRow";
import "./activityRow.scss";
import { ActivityRowState } from "./activityRowViewModel";

export type ActivityRowViewProps = ActivityRowProps &
    ActivityRowState & {
        newRowExpanded: Function;
    };

export function ActivityRowView(props: ActivityRowViewProps): ReactElement {
    return (
        <div
            className={`activityRow`}
            onClick={() => {
                props.newRowExpanded(props.index);
            }}
            data-testid="expandRow"
        >
            <div className="activityRow-collapsed">
                {props.size === "-mobile" ? (
                    <div className="activityRow-left">
                        <div className="activityRow-desc">
                            <p className={`activityRow-merchdesc-${props.debitOrCredit}`}>
                                {props.activity?.MerchantDescription?.toLowerCase()}
                            </p>
                            <h6 className="activityRow-date">
                                {GetFormattedDate(props.activity.DatePosted?.toString(), true)}
                            </h6>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="activityRow-left">
                            <div className="activityRow-desc">
                                <p className={`activityRow-merchdesc-${props.debitOrCredit}`}>
                                    {props.activity.MerchantDescription?.toLowerCase()}
                                </p>
                            </div>
                        </div>

                        <div className="activityRow-middle">
                            <div className="activityRow-desc">
                                <h6 className="activityRow-date">
                                    {GetFormattedDate(props.activity.DatePosted?.toString(), true)}
                                </h6>
                            </div>
                        </div>
                    </>
                )}

                <div className="activityRow-right">
                    <div className="activityRow-amount">
                        <p className={`activityRow-amount-${props.debitOrCredit}`}>
                            {FormatMoney(props.activity.TransactionAmount).formatted}
                        </p>
                    </div>
                    {props.expanded ? (
                        <ArrowUp className="activityRow-icon" />
                    ) : (
                        <ArrowDown className="activityRow-icon" />
                    )}
                </div>
            </div>
            {props.expanded === true ? (
                <div className="activityRow-expanded">
                    <RowInfo header="Reference Number" value={props.activity.ReferenceNumber} />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}

function RowInfo({ header, value }: { header: string; value: string }): ReactElement {
    return (
        <div className="activityRow-info">
            <h6 className="activityRow-infoHeader">{header}</h6>
            <p className="activityRow-infoBody">{value}</p>
        </div>
    );
}
