import { useRef, useState } from "react";
import { UUID } from "../../../../utility";
import { useHover } from "../../../../hooks";

type ToolTipViewModel = {
    state: ToolTipState;
};

export type ToolTipState = {
    hoveredOver: Element | null | undefined;
    uuid: string;
    target: React.MutableRefObject<any>;
    tip: React.MutableRefObject<any>;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useToolTipViewModel(): ToolTipViewModel {
    const target = useRef(null as any);
    const tip = useRef(null as any);
    const [show, setShow] = useState(false);
    const uuid = UUID.New();
    const hoveredOver = useHover();

    return {
        state: {
            hoveredOver,
            uuid,
            target,
            tip,
            show,
            setShow
        }
    };
}
