import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { FormatMoney } from "../../../../utility";
import { CardBalanceProps } from "./cardBalance";

type CardBalanceViewModel = {
    state: CardBalanceState;
};

export type CardBalanceState = {
    size: Size;
    dollars: string;
    cents: string;
    available: string;
    limit: string;
};

export function useCardBalanceViewModel(props: CardBalanceProps): CardBalanceViewModel {
    const { size } = useViewport(false, true);
    let { dollars, cents } = FormatMoney(props.account.currentBalance);
    let available = FormatMoney(props.account.availableCredit, true, false);
    let limit = FormatMoney(props.account.creditLimit, true, false);

    return {
        state: { size, dollars, cents, available: available.formatted, limit: limit.formatted }
    };
}
