import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function HandHeart(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="65"
            height="64"
            viewBox="0 0 65 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="32.9633" cy="31.9998" r="30.7998" stroke="#42967D" strokeWidth="2.39999" />
            <path
                d="M15.9917 36.0547H21.2488C22.9917 36.0547 24.6631 36.747 25.8955 37.9794C27.1279 39.2118 27.8202 40.8832 27.8202 42.6261"
                stroke="#42967D"
                strokeWidth="2.62856"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.9345 42.625H33.0773C34.4716 42.625 35.8088 43.1789 36.7947 44.1648C37.7806 45.1507 38.3344 46.4878 38.3344 47.8821C38.3344 48.2307 38.196 48.565 37.9495 48.8115C37.703 49.0579 37.3687 49.1964 37.0202 49.1964H15.9917"
                stroke="#42967D"
                strokeWidth="2.62856"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M37.6773 36.0548L27.1631 26.3292C21.4328 20.5726 29.7916 9.42752 37.6773 18.4435C45.563 9.45381 54.0007 20.5989 48.1916 26.3292L37.6773 36.0548Z"
                stroke="#42967D"
                strokeWidth="2.62856"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
