import { ReactElement } from "react";
import { InvalidMFA3Times, MfaAssistance, MfaAssistanceEnterCode, TrustThisDevice } from "../..";
import { PinExpired } from "../modals/pinExpired/pinExpired";
import { NeedAssistanceProps } from "./needAssistanceModel";
import { NeedAssistanceState } from "./needAssistanceViewModel";

type NeedAssistanceViewProps = NeedAssistanceProps & NeedAssistanceState;

function NeedAssistanceView(props: NeedAssistanceViewProps): ReactElement {
    return (
        <>
            <MfaAssistance
                show={props.showAssistance}
                setShowModal={props.setShowAssistance}
                setShowNext={props.setShowEnter}
            />

            <MfaAssistanceEnterCode
                showModal={props.showEnter}
                setShowModal={props.setShowEnter}
                setShowLast={props.setShowAssistance}
                setShowTrust={props.setShowTrust}
                setShowError={props.setShowError}
                setShowPinExpired={props.setShowPinExpired}
            />

            <InvalidMFA3Times show={props.showError} setShow={props.setShowError} isCorpCard={props.isCorpCard} />
            <TrustThisDevice show={props.showTrust} setShow={props.setShowTrust} />
            <PinExpired show={props.showPinExpired} setShow={props.setShowPinExpired} />
        </>
    );
}

export default NeedAssistanceView;
