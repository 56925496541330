import { ReactElement, useEffect } from "react";
import { IsNullOrEmpty } from "../../../utility";
import { CardSummaryView } from "./cardSummaryView";
import { useCardSummaryViewModel } from "./cardSummaryViewModel";

export function CardSummaryModel(): ReactElement {
    const { hooks, state, api } = useCardSummaryViewModel();

    //NO User Story Yet
    // useEffect(() => {
    //     if (!hooks.actionAllowed("accountSummary", state.accounts![state.accountIndex].accountHandle)) {
    //         hooks.navigate(`/account/dashboard`, { relative: "path" });
    //     }
    // });

    useEffect(() => {
        state.setAccountIndex(Number(state.urlId));
        state.setShowNav(true);

        return () => {
            state.setShowNav(false);
        };
    }, []);

    useEffect(() => {
        //at least one account with showMultipleAccountsMessage set to true
        if (!IsNullOrEmpty(state.offerAccountHandle)) {
            //session var to track only show this once, at launch it's undefined, otherwise, it's not the first time (so more than once).
            if ([undefined, "true"].includes(hooks.storage["ShowMultAcctOfferPopup"])) {
                api.maPopup()
                    .then((res) => {
                        if (res.status === 200) {
                            state.setShowOfferPopup(true);
                            state.setBodyText(res.data.BodyText);
                            state.setRedirectUrl(res.data.Url);
                            state.setPopupId(res.data.MultipleAccountPopupId);
                            updateDisplayed(res.data.MultipleAccountPopupId);
                        }
                    })
                    .catch((error) => {
                        //console.log(error);
                        state.setShowOfferPopup(false);
                    });
            }
        }
    }, []);

    function updateDisplayed(popupId: number) {
        updateDisplayedAPI(popupId);
        if (state.apiFailedToPost === 1) {
            //failed once, retry once
            updateDisplayedAPI(popupId);
        }
    }

    function updateDisplayedAPI(popupId: number) {
        api.maPopupDisplayed(popupId)
            .then((res) => {
                if (res.status === 200 || res.status === 204) {
                    return true;
                } else {
                    state.setApiFailedToPost(state.apiFailedToPost + 1);
                    return false;
                }
            })
            .catch(() => {
                state.setApiFailedToPost(state.apiFailedToPost + 1);
                return false;
            });
    }

    return <CardSummaryView {...state} />;
}
