import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function PaymentHistory(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M5.79977 14V18.7998H1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M8.86884 20.5C9.8538 20.8245 10.9064 21 12 21C17.5228 21 22 16.5228 22 11C22 5.47715 17.5228 1 12 1C6.47715 1 2 5.47715 2 11C2 13.9557 3.28231 16.6119 5.32096 18.4426"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M14 8H11.4667C11.0777 8 10.7046 8.12643 10.4296 8.35147C10.1545 8.57652 10 8.88174 10 9.2C10 11 14 11 14 12.8C14 13.1183 13.8455 13.4235 13.5704 13.6485C13.2954 13.8736 12.9223 14 12.5333 14H10"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M12 8V7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 14V15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
