import { ReactElement } from "react";
import { PublicCCPAPageConfiguration } from "../../../../api/backend";
import { Size } from "../../../../types";
import { CCPAOptionsView } from "./ccpaOptionsView";

export type CCPAOptionsModelProps = {
    cms: PublicCCPAPageConfiguration;
    size: Size;
    isCorpCard: boolean;
};

export function CCPAOptionsModel(props: CCPAOptionsModelProps): ReactElement {
    return <CCPAOptionsView {...props} />;
}
