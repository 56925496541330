import { ReactElement } from "react";
import { Loading } from "../../../..";
import { GetFormattedDate } from "../../../../../utility";
import { ButtonPro } from "../../../../generic";
import { MailNotification, MailRead } from "../../../../generic/icons";
import { DocumentsRowProps } from "./documentsRow";
import "./documentsRow.scss";
import { DocumentsRowHooks, DocumentsRowState } from "./documentsRowViewModel";

export type DocumentsRowViewProps = DocumentsRowProps &
    DocumentsRowState &
    DocumentsRowHooks & {
        newRowExpanded: Function;
        openPDF: Function;
        downloadPDF: Function;
    };

export function DocumentsRowView(props: DocumentsRowViewProps): ReactElement {
    return (
        <div className="documentsRow">
            <div className="documentsRow-collapsed">
                <div className="documentsRow-left">
                    {props.document.status === "NEW" ? (
                        <MailNotification className="document-icon" />
                    ) : (
                        <MailRead className="document-icon" />
                    )}
                    <p className="documentsRow-date">
                        {GetFormattedDate(props.document.documentDate?.toString(), true)}
                    </p>
                </div>
                <div className="documentsRow-right">
                    <ButtonPro className="documentsRow-view-button" variant="tertiary" onClick={() => props.openPDF()}>
                        <div className="documentsRow-viewmenu">View</div>
                    </ButtonPro>
                </div>
            </div>
            <Loading show={props.showLoading} />
        </div>
    );
}
