import { ReactElement, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { Check } from "../../../generic/icons";
import { ActivityDateFilterView } from "./activityDateFilterView";

type ActivityDateFilterProps = {
    years: number[];
    filter: number;
    setFilter: React.Dispatch<React.SetStateAction<number>>;
};

export function ActivityDateFilter(props: ActivityDateFilterProps): ReactElement {
    const items = useMemo(() => {
        const sixMonth = (
            <Dropdown.Item key="0" eventKey={0} className="activityDateFilter-item" onClick={() => props.setFilter(0)}>
                <p>Last 6 months</p>
                {props.filter === 0 ? <Check /> : <></>}
            </Dropdown.Item>
        );

        let options = [sixMonth] as ReactElement[];
        return options.concat(
            props.years.map((v, i) => (
                <Dropdown.Item
                    key={v}
                    eventKey={i}
                    className="activityDateFilter-item"
                    onClick={() => {
                        props.setFilter(v);
                    }}
                >
                    <p>{v}</p>
                    {props.filter === v ? <Check /> : <></>}
                </Dropdown.Item>
            ))
        );
    }, [props]);

    return <ActivityDateFilterView {...props} items={items} />;
}
