import { ReactElement } from "react";

export function LoadingIndicator(): ReactElement {
    return (
        <svg
            className="spinner"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="53.7285"
                y="29.131"
                width="7.45736"
                height="28.881"
                rx="3.72868"
                transform="rotate(180 53.7285 29.131)"
                stroke="white"
                strokeWidth="0.5"
                className="seg11"
            />
            <rect
                x="63.6636"
                y="33.7912"
                width="7.45736"
                height="28.881"
                rx="3.72868"
                transform="rotate(-150 63.6636 33.7912)"
                stroke="white"
                strokeWidth="0.5"
                className="seg10"
            />
            <rect
                x="69.9372"
                y="42.7946"
                width="7.45736"
                height="28.881"
                rx="3.72868"
                transform="rotate(-120 69.9372 42.7946)"
                stroke="white"
                strokeWidth="0.5"
                className="seg9"
            />
            <rect
                x="70.8689"
                y="53.7286"
                width="7.45736"
                height="28.881"
                rx="3.72868"
                transform="rotate(-90 70.8689 53.7286)"
                stroke="white"
                strokeWidth="0.5"
                className="seg8"
            />
            <rect
                x="66.2087"
                y="63.6636"
                width="7.45736"
                height="28.881"
                rx="3.72868"
                transform="rotate(-60 66.2087 63.6636)"
                stroke="white"
                strokeWidth="0.5"
                className="seg7"
            />
            <rect
                x="57.2053"
                y="69.9373"
                width="7.45736"
                height="28.881"
                rx="3.72868"
                transform="rotate(-30 57.2053 69.9373)"
                stroke="white"
                strokeWidth="0.5"
                className="seg6"
            />
            <rect
                x="46.2712"
                y="70.869"
                width="7.45736"
                height="28.881"
                rx="3.72868"
                fill="#ABCFC2"
                stroke="white"
                strokeWidth="0.5"
                className="seg5"
            />
            <rect
                x="36.3364"
                y="66.2087"
                width="7.45736"
                height="28.881"
                rx="3.72868"
                transform="rotate(30 36.3364 66.2087)"
                stroke="white"
                strokeWidth="0.5"
                className="seg4"
            />
            <rect
                x="30.0625"
                y="57.2053"
                width="7.45735"
                height="28.881"
                rx="3.72868"
                transform="rotate(60 30.0625 57.2053)"
                stroke="white"
                strokeWidth="0.5"
                className="seg3"
            />
            <rect
                x="29.1311"
                y="46.2714"
                width="7.45736"
                height="28.881"
                rx="3.72868"
                transform="rotate(90 29.1311 46.2714)"
                stroke="white"
                strokeWidth="0.5"
                className="seg2"
            />
            <rect
                x="33.7913"
                y="36.3364"
                width="7.45736"
                height="28.881"
                rx="3.72868"
                transform="rotate(120 33.7913 36.3364)"
                stroke="white"
                strokeWidth="0.5"
                className="seg1"
            />
            <rect
                x="42.7945"
                y="30.0625"
                width="7.45736"
                height="28.881"
                rx="3.72868"
                transform="rotate(150 42.7945 30.0625)"
                stroke="white"
                strokeWidth="0.5"
                className="seg0"
            />
        </svg>
    );
}
