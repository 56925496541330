import { ReactElement } from "react";
import { Container, Dropdown, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AppDownloadBanner, CardNav, SearchHelpTopics } from "../../..";
import { UserAccount } from "../../../../api/backend";
import { GetCardsList } from "../../../accountDetails/cardNavigation/cardNav";
import { DebugInfoHeader } from "../../../devTools/debugInfo/debugInfo";
import { ButtonPro } from "../../../generic";
import { Logout, PBCLogoSm, Search, User } from "../../../generic/icons";
import "./appHeaderPrivate.scss";
import { AppHeaderPrivateState } from "./appHeaderPrivateViewModel";

type AppHeaderPrivateViewProps = AppHeaderPrivateState & {
    handleClose: Function;
    onSelect: Function;
    toggleOffCanvas: Function;
    onEditContactInfo: Function;
    onChooseDelivery: Function;
    onChangePassword: Function;
    onDashboard: Function;
    onLogin: Function;
    onLogout: Function;
    onSearch: Function;
    accounts: UserAccount[] | undefined;
    onCardSelected: Function;
};

function AppHeaderPrivateView(props: AppHeaderPrivateViewProps): ReactElement {
    const navigate = useNavigate();

    return (
        <div id="appHeaderPrivate">
            {props.showBanner && !props.showOffCanvas ? <AppDownloadBanner /> : <></>}
            <Navbar id="appHeaderPrivate-inner" key={props.szKey.toString()} expand={props.szKey}>
                <DebugInfoHeader />
                <Container id="appHeaderPrivate-container" fluid>
                    <div id={`appHeaderPrivate-content${props.size}`}>
                        <PBCLogoSm id="appHeaderPrivate-logo" onClick={() => props.onDashboard()} />

                        <Navbar.Toggle
                            className={`appHeaderPrivate-toggle${props.showOffCanvas ? "" : " collapsed collapsed-mm"}`}
                            onClick={() => {
                                props.toggleOffCanvas();
                            }}
                            aria-expanded={props.showOffCanvas}
                            data-testid="navToggle"
                        >
                            <span className="icon-bar top-bar"></span>
                            <span className="icon-bar middle-bar"></span>
                            <span className="icon-bar bottom-bar"></span>
                        </Navbar.Toggle>
                        <Navbar.Offcanvas
                            restoreFocus={false}
                            show={props.showOffCanvas}
                            onHide={props.handleClose}
                            id={`offcanvasNavbar-expand-${props.szKey}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${props.szKey}`}
                            placement="start"
                        >
                            <Offcanvas.Body>
                                {props.szKey ? (
                                    <>
                                        <Nav className="appHeaderPrivate-nav">
                                            <div className="appHeaderPrivate-full">
                                                <div className="appHeaderPrivate-options">
                                                    <Dropdown align="end" className="profile-dropdown">
                                                        <Dropdown.Toggle className="profile-dropdown-toggle">
                                                            <User
                                                                className="appHeaderPrivate-icon-sf"
                                                                caption="Profile"
                                                            />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {props.updateProfileAction ? (
                                                                <Dropdown.Item
                                                                    className="profile-dropdown-item"
                                                                    onClick={() => props.onEditContactInfo()}
                                                                >
                                                                    Edit Contact Information
                                                                </Dropdown.Item>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            {props.updateDeliveryAction ? (
                                                                <Dropdown.Item
                                                                    className="profile-dropdown-item"
                                                                    onClick={() => props.onChooseDelivery()}
                                                                >
                                                                    Choose Delivery Preferences
                                                                </Dropdown.Item>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <Dropdown.Item
                                                                className="profile-dropdown-item"
                                                                onClick={() => props.onChangePassword()}
                                                            >
                                                                Change Password
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <Search
                                                        className="appHeaderPrivate-icon-sf"
                                                        caption="Search"
                                                        onClick={() => props.onSearch()}
                                                    />
                                                    <Logout
                                                        className="appHeaderPublic-icon-sf"
                                                        caption="Log Out"
                                                        onClick={() => {
                                                            props.onLogout();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Nav>
                                    </>
                                ) : (
                                    <>
                                        <div className="offcanvas-body-mobile-menu">
                                            <div className="offcanvas-body-split">
                                                <Nav className="appHeaderPrivate-nav-mm">
                                                    <div>
                                                        <div className="mm-search-topics-main">
                                                            <SearchHelpTopics {...props} />
                                                        </div>
                                                        <div className="offcanvas-section">
                                                            <h6 className="offcanvas-section-header">Accounts</h6>
                                                            <div className="offcanvas-section-body">
                                                                {GetCardsList(
                                                                    props.accounts!,
                                                                    -1,
                                                                    props.onCardSelected,
                                                                    navigate,
                                                                    props.onDashboard
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="offcanvas-section">
                                                            <h6 className="offcanvas-section-header">Profile</h6>
                                                            <div className="offcanvas-section-body">
                                                                {props.updateProfileAction ? (
                                                                    <ButtonPro
                                                                        variant="neutral"
                                                                        onClick={() => props.onEditContactInfo()}
                                                                    >
                                                                        Edit Contact Information
                                                                    </ButtonPro>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {props.updateDeliveryAction ? (
                                                                    <ButtonPro
                                                                        variant="neutral"
                                                                        onClick={() => props.onChooseDelivery()}
                                                                    >
                                                                        Choose Delivery Preferences
                                                                    </ButtonPro>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                <ButtonPro
                                                                    variant="neutral"
                                                                    onClick={() => props.onChangePassword()}
                                                                >
                                                                    Change Password
                                                                </ButtonPro>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Nav>
                                            </div>
                                            <div
                                                className="offcanvas-logout"
                                                onClick={() => {
                                                    props.onLogout();
                                                }}
                                            >
                                                <Logout id="logout" className="appHeaderPublic-icon-sf" />
                                                <h6>Log Out</h6>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </div>
                </Container>
            </Navbar>
            <CardNav />
        </div>
    );
}
export default AppHeaderPrivateView;
