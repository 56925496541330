import { AxiosResponse } from "axios";
import { Statement, StatementDetails } from ".";
import { useApiQueueContext } from "../../../contexts";
import { useIdentityConnectContext } from "../../identityServer";

export const useStatementsContext = () => {
    const client = useStatementsClient();

    return { ...client };
};

const useStatementsClient = () => {
    const apiQueue = useApiQueueContext();
    const { access_token } = useIdentityConnectContext();

    async function Get(accountHandle: string): Promise<AxiosResponse<StatementDetails[], any>> {
        return await apiQueue.Get(`api/v1/statements?accountHandle=${accountHandle}`, access_token);
    }

    async function GetById(accountHandle: string, statementId: string): Promise<AxiosResponse<Statement, any>> {
        return await apiQueue.Get(`api/v1/statements/${statementId}?accountHandle=${accountHandle}`, access_token);
    }

    return { Get, GetById };
};
