import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Key(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M52.5002 5L47.5002 10M47.5002 10L55.0002 17.5L46.2502 26.25L38.7502 18.75M47.5002 10L38.7502 18.75M28.4752 29.025C29.7661 30.2987 30.7922 31.8151 31.4946 33.487C32.197 35.1589 32.5617 36.9532 32.5678 38.7666C32.5738 40.58 32.2212 42.3767 31.53 44.0533C30.8388 45.7298 29.8229 47.2531 28.5406 48.5354C27.2583 49.8176 25.735 50.8336 24.0585 51.5248C22.3819 52.2159 20.5852 52.5686 18.7718 52.5626C16.9584 52.5565 15.1641 52.1918 13.4922 51.4894C11.8203 50.787 10.3039 49.7608 9.0302 48.47C6.52552 45.8767 5.1396 42.4034 5.17093 38.7982C5.20226 35.193 6.64833 31.7444 9.19769 29.195C11.7471 26.6456 15.1957 25.1996 18.8009 25.1682C22.4061 25.1369 25.8794 26.5228 28.4727 29.0275L28.4752 29.025ZM28.4752 29.025L38.7502 18.75"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
