import { useViewport } from "../../../hooks";
import { Size } from "../../../types";

type ModalViewModel = {
    state: ModalState;
};

export type ModalState = {
    size: Size;
};

export function useModalViewModel(): ModalViewModel {
    const { size } = useViewport(false, true);

    return {
        state: { size }
    };
}
