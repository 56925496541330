import { ReactElement } from "react";
import CurrencyInput from "react-currency-input-field";
import { FormatMoney } from "../../../../../utility";
import { RadioPro } from "../../../../generic";
import "./paymentAmountOption.scss";

type PaymentAmountOptionProps = {
    id: string;
    header: string;
    amount: number;
    selection: AmountSelection;
    onSelectionChanged: Function;
    children?: ReactElement;
    disabled?: boolean;
};

export type AmountSelection = "" | "min" | "statement" | "current" | "other";

export function PaymentAmountOption(props: PaymentAmountOptionProps): ReactElement {
    const selectorClassName = props.disabled ? "paymentAmountOption-selector-disabled" : "paymentAmountOption-selector";

    let disabled = props.id === "min";

    return (
        <div className="paymentAmountOption-radio">
            <RadioPro
                name="paymentAmount"
                id={`paymentAmount-${props.id}`}
                className="paymentAmountOption-input"
                value={props.amount.toString()}
                checked={!props.disabled && props.selection === props.id}
                onChange={(e: React.SyntheticEvent) => {
                    props.onSelectionChanged(props.id);
                }}
                disabled={props.disabled}
            />
            <div
                data-testid={"onChange-selection"}
                className={selectorClassName}
                onClick={(e) => {
                    if (!props.disabled) {
                        onChange(props.id);
                        props.onSelectionChanged(props.id);
                    }
                }}
            >
                <div className="paymentAmountOption-header">
                    <h6>{props.header}</h6>
                    {props.children ? props.children : <></>}
                </div>
                <p>{FormatMoney(props.amount).formatted}</p>
            </div>
        </div>
    );
}

type PaymentAmountOptionOtherProps = {
    amount: string;
    setAmount: React.Dispatch<React.SetStateAction<string>>;
    amountSelection: AmountSelection;
    onSelectionChanged: Function;
};

export function PaymentAmountOptionOther(props: PaymentAmountOptionOtherProps): ReactElement {
    const validateOther = (value: string | undefined): void => {
        const rawValue = value === undefined && !Number.isNaN(value) ? "" : value;
        let ov = rawValue || "";
        props.setAmount(ov);
    };

    return (
        <div className="paymentAmountOption-radio">
            <RadioPro
                name="paymentAmount"
                id={`paymentAmount-other`}
                className="paymentAmountOption-input"
                value={"0.00"}
                checked={props.amountSelection === "other"}
                onChange={(e: React.SyntheticEvent) => {
                    props.onSelectionChanged("other");
                }}
            />
            <div
                data-testid={"onChange-selection"}
                className="paymentAmountOption-selector"
                onClick={(e) => {
                    onChange("other");
                    props.onSelectionChanged("other");
                }}
            >
                <div className="paymentAmountOption-header">
                    <h6>Other Amount</h6>
                </div>
                <div className="paymentAmountOption-value" data-testid={"onValuChange-VlaidateOther"}>
                    <p>$</p>
                    <CurrencyInput
                        id="paymentAmount-otherInput"
                        name="otherAmount"
                        allowNegativeValue={false}
                        decimalScale={2}
                        placeholder="0.00"
                        maxLength={props.amount.includes(".") ? 8 : 5}
                        onValueChange={validateOther}
                        value={props.amountSelection === "other" ? props.amount : ""}
                    />
                </div>
            </div>
        </div>
    );
}

const onChange = (id: string): void => {
    var radio = document.getElementById(`paymentAmount-${id}`) as HTMLInputElement;
    radio.checked = true;
};
