import { ReactElement } from "react";
import { CardAppPreviewView } from "./cardAppPreviewView";
import { useCardAppPreviewViewModel } from "./cardAppPreviewViewModel";
import { CardApplicationProps } from "../../cardApplication/cardApplication";

export function CardAppPreview(props: CardApplicationProps): ReactElement {
    const { state } = useCardAppPreviewViewModel();

    return <CardAppPreviewView {...props} {...state} />;
}
