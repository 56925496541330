import { ReactElement, useEffect } from "react";
import { useCreditCardAgreementViewModel } from "./creditCardAgreementViewModel";
import { CreditCardAgreementView } from "./creditCardAgreementView";

export function CreditCardAgreementModel(): ReactElement {
    const { state } = useCreditCardAgreementViewModel();

    useEffect(() => {
        if (state.loggedIn) state.setShowNav(false);
    }, []);

    return <CreditCardAgreementView {...state} />;
}
