import { Formik, FormikHelpers, FormikProps } from "formik";
import { ReactElement } from "react";
import { ValidationErrors } from "../../../../api/backend";
import { Modal, ModalProps } from "../../../generic";
import { ReviewPaymentAccountForm } from "./reviewPaymentAccountForm";
import { useReviewPaymentAccountViewModel } from "./reviewPaymentAccountViewModel";

export type ReviewPaymentAccountProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowEnter: React.Dispatch<React.SetStateAction<boolean>>;
    setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    routingNumber: string;
    setRoutingNumber: React.Dispatch<React.SetStateAction<string>>;
    checkingNumber: string;
    setCheckingNumber: React.Dispatch<React.SetStateAction<string>>;
    setConfirmNumber: React.Dispatch<React.SetStateAction<string>>;
    bankName: string;
    setBankName: React.Dispatch<React.SetStateAction<string>>;
    callback?: Function;
};

export function ReviewPaymentAccountModal(props: ModalProps & ReviewPaymentAccountProps): ReactElement {
    return (
        <Modal show={props.show}>
            <ReviewPaymentAccountModel {...props} />
        </Modal>
    );
}

export function ReviewPaymentAccountModel(props: ReviewPaymentAccountProps): ReactElement {
    const vm = useReviewPaymentAccountViewModel(props);

    function onSubmit(actions: FormikHelpers<{}>) {
        vm.api
            .Post(props.routingNumber, props.checkingNumber)
            .then((res) => {
                props.setShow(false);
                props.setShowSuccess(true);
            })
            .catch((err) => {
                if (err.response?.status === 400) {
                    let errors = err.response?.data as ValidationErrors;
                    let errorField = Object.keys(errors.errors)[0];
                    let eText: string = errors.errors[errorField][0];
                    if (eText.includes("already enrolled")) {
                        vm.state.setWarning(eText);
                    } else {
                        vm.state.setError(eText);
                    }
                    vm.state.setCanContinue(false);
                }
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    return (
        <Formik
            initialValues={{}}
            onSubmit={(_, actions) => {
                onSubmit(actions);
            }}
        >
            {(formProps: FormikProps<{}>) => <ReviewPaymentAccountForm props={props} vm={vm} formProps={formProps} />}
        </Formik>
    );
}
