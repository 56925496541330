import { ReactElement } from "react";
import { ActivitySummarySplashView } from "./activitySummarySplashView";
import { useActivitySummarySplashViewModel } from "./activitySummarySplashViewModel";

function ActivitySummarySplash(): ReactElement {
    const { state } = useActivitySummarySplashViewModel();

    return <ActivitySummarySplashView {...state} />;
}

export default ActivitySummarySplash;
