import { useEffect, useState } from "react";
import { CmsToolTip, useDigitalActionsContext, usePaymentContext } from "../../../../api/backend";
import { useTooltipCms, useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { FormatMoney } from "../../../../utility";
import { CardPaymentProps } from "./cardPaymentModel";

type CardPaymentViewModel = {
    hooks: CardPaymentHooks;
    state: CardPaymentState;
};

type CardPaymentHooks = {
    makeNewPayment: (index: number) => void;
};

export type CardPaymentState = {
    size: Size;
    dollars: string;
    cents: string;
    tooltip: CmsToolTip;
    paymentActions: boolean | undefined;
};

export function useCardPaymentViewModel(props: CardPaymentProps): CardPaymentViewModel {
    const { size } = useViewport(false, true);
    const { makeNewPayment } = usePaymentContext();
    const { getTooltip } = useTooltipCms();
    const tooltip = getTooltip("totalMinDueToolTip");
    let { dollars, cents } = FormatMoney(props.account.minimumAmountDue, true);
    const { actionAllowed } = useDigitalActionsContext();
    const [paymentActions, setPaymentActions] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        setPaymentActions(actionAllowed("payments", props.account.accountHandle));
    }, [props.account]);

    return {
        hooks: { makeNewPayment },
        state: { size, dollars, cents, tooltip, paymentActions }
    };
}
