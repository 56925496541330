import { ReactElement } from "react";
import { Alert, BrandedCard, ButtonPro } from "..";
import { useViewport } from "../../../hooks";
import "./genericModal.scss";
import { GenericModalProps } from "./genericModalModel";
import { ModalState } from "./modalViewModel";

export function GenericModalView(props: ModalState & GenericModalProps): ReactElement {
    const { size } = useViewport(false, true);

    return (
        <>
            {props.isBranded ? (
                <BrandedCard id="brandedModal" size={size} header={props.headerText} subtitle={props.subtitle}>
                    <div id="genericModal" className="genericModal-branded">
                        {props.children !== undefined ? (
                            props.children
                        ) : (
                            <p>
                                <span dangerouslySetInnerHTML={{ __html: props.bodyText! }} />
                            </p>
                        )}
                        {props.alertText !== undefined ? (
                            <Alert level={"info"} header={props.alertText} text={""} />
                        ) : (
                            <></>
                        )}

                        <div className={`genericModal-buttons${size}`}>
                            <ButtonPro
                                type="button"
                                className={props.primaryButton.className ?? ""}
                                content={props.primaryButton.text}
                                variant={props.primaryButton.variant}
                                onClick={() => props.primaryButton.action()}
                            />
                            {props.secondaryButton !== undefined ? (
                                <ButtonPro
                                    type="button"
                                    className={props.secondaryButton?.className ?? ""}
                                    content={props.secondaryButton?.text}
                                    variant={props.secondaryButton?.variant}
                                    onClick={() => props.secondaryButton?.action()}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </BrandedCard>
            ) : (
                <div id="genericModal" className="genericModal">
                    <h4>{props.headerText}</h4>
                    {props.children !== undefined ? (
                        props.children
                    ) : (
                        <p>
                            <span dangerouslySetInnerHTML={{ __html: props.bodyText! }} />
                        </p>
                    )}
                    {props.alertText !== undefined ? (
                        <Alert level={"info"} header={props.alertText} text={""} />
                    ) : (
                        <></>
                    )}
                    <div className={`genericModal-buttons${size}`}>
                        <ButtonPro
                            type="button"
                            className={props.primaryButton.className ?? ""}
                            content={props.primaryButton.text}
                            variant={props.primaryButton.variant}
                            onClick={() => props.primaryButton.action()}
                        />
                        {props.secondaryButton !== undefined ? (
                            <ButtonPro
                                type="button"
                                className={props.secondaryButton?.className ?? ""}
                                content={props.secondaryButton?.text}
                                variant={props.secondaryButton?.variant}
                                onClick={() => props.secondaryButton?.action()}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
