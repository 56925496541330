import { ReactElement } from "react";
import { FormatMoney } from "../../../../utility";
import { GetFormattedDate } from "../../../../utility/date";
import { ButtonPro } from "../../../generic";
import { ArrowDown, ArrowUp } from "../../../generic/icons";
import { PaymentScheduledRowProps } from "./paymentScheduledRow";
import "./paymentScheduledRow.scss";
import { PaymentScheduledRowState } from "./paymentScheduledRowViewModel";

type PaymentScheduledRowViewProps = PaymentScheduledRowProps &
    PaymentScheduledRowState & {
        newRowExpanded: Function;
    };

export function PaymentScheduledRowView(props: PaymentScheduledRowViewProps): ReactElement {
    return (
        <div className="paymentScheduledRow-content">
            <div
                data-testid={"paymentScheduledRow-expand-button"}
                className={`paymentScheduledRow-${props.className}`}
                onClick={() => {
                    props.newRowExpanded(props.index);
                }}
            >
                <div className="paymentScheduledRow-collapsed">
                    <p className={`paymentScheduledRow-date-${props.className}`}>
                        {GetFormattedDate(props.payment.paymentDate?.toString(), true)}
                    </p>
                    <div className="paymentScheduledRow-right">
                        <div className="paymentScheduledRow-amount">
                            <p className={`paymentScheduledRow-amount-${props.className}`}>
                                {FormatMoney(props.payment.paymentAmount).formatted}
                            </p>
                            <h6 className={`paymentScheduledRow-status-${props.className}`}>
                                {props.payment.paymentStatus}
                            </h6>
                        </div>
                        {props.expanded ? (
                            <ArrowUp className="paymentScheduledRow-icon" />
                        ) : (
                            <ArrowDown className="paymentScheduledRow-icon" />
                        )}
                    </div>
                </div>
                {props.expanded === true ? (
                    <div className="paymentScheduledRow-expanded">
                        <div>
                            <RowInfo
                                header="Confirmation Number"
                                value={props.payment.referenceNumber}
                                cn={props.className}
                            />
                            <RowInfo
                                header="Scheduled Date"
                                value={GetFormattedDate(props.payment.dateEntered?.toString(), true)}
                                cn={props.className}
                            />
                            <RowInfo
                                header="Credit Card Paid"
                                value={`PREMIER ••••${props.last4}`}
                                cn={props.className}
                            />
                            <RowInfo
                                header="Paid From Account"
                                value={`${props.payment.bankName} ${props.payment.paidFrom.replaceAll("*", "•")}`}
                                cn={props.className}
                            />
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            {props.expanded === true && props.payment.isDeletable ? (
                <div className="row-detail-cancel">
                    <ButtonPro
                        className="row-detail-cancel-button"
                        variant="tertiary-neg"
                        onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                            props.cancelPayment(props.payment);
                        }}
                    >
                        Cancel Payment
                    </ButtonPro>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}

function RowInfo({ header, value, cn }: { header: string; value: string; cn: string }): ReactElement {
    return (
        <div className="paymentScheduledRow-info">
            <h6 className={`paymentScheduledRow-infoHeader-${cn}`}>{header}</h6>
            <p className={`paymentScheduledRow-infoBody-${cn}`}>{value}</p>
        </div>
    );
}
