import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Printer(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.2 4.8H20.4V1.8C20.4 0.8076 19.5924 0 18.6 0H5.4C4.4076 0 3.6 0.8076 3.6 1.8V4.8H1.8C0.8076 4.8 0 5.6076 0 6.6V17.4C0 18.3924 0.8076 19.2 1.8 19.2H3.6V22.2V24H20.4V22.2V19.2H22.2C23.1924 19.2 24 18.3924 24 17.4V6.6C24 5.6076 23.1924 4.8 22.2 4.8ZM4.8 1.8C4.8 1.4688 5.0688 1.2 5.4 1.2H18.6C18.9312 1.2 19.2 1.4688 19.2 1.8V4.8H4.8V1.8ZM19.2 22.8H4.8C4.8 22.8 4.8 22.4343 4.8 22.2C4.8 19.1539 4.8 13.5 4.8 13.5H12H19.2V22.2V22.8ZM22.8 17.4C22.8 17.7312 22.5312 18 22.2 18H20.4V14.4V13.8V12H12H3.6V13.8V14.4V18H1.8C1.4688 18 1.2 17.7312 1.2 17.4V6.6C1.2 6.2688 1.4688 6 1.8 6H22.2C22.5312 6 22.8 6.2688 22.8 6.6V17.4Z"
                fill="#42967D"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
