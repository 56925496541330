import { ReactElement } from "react";
import { BrandedCard, ButtonPro, IndexedCreditCard } from "../../../generic";
import "./splitDeliveryPrompt.scss";
import { SplitDeliveryPromptProps } from "./splitDeliveryPromptModel";
import { SplitDeliveryPromptState } from "./splitDeliveryPromptViewModel";

type SplitDeliveryPromptViewProps = SplitDeliveryPromptProps &
    SplitDeliveryPromptState & {
        onPaper: Function;
        onPaperless: Function;
    };

export function SplitDeliveryPromptView(props: SplitDeliveryPromptViewProps): ReactElement {
    return (
        <BrandedCard id="splitDeliveryPrompt" size={props.size} header="Choose Your Communication Preferences">
            <div className={`brandedCard-form${props.size}`}>
                <div className="splitDeliveryPrompt-body">
                    <p>
                        You're receiving both electronic and paper communications. Select your preferred method of
                        delivery.
                    </p>
                    <div className="splitDeliveryPrompt-cards">
                        {props.accounts
                            .filter((x) => x.isSplitElectronicDelivery === true)
                            .map((x) => (
                                <IndexedCreditCard key={x.accountHandle} account={x} size="h4" icon />
                            ))}
                    </div>
                </div>
                <div className="brandedCard-buttons">
                    <ButtonPro content={"Go Paperless!"} onClick={() => props.onPaperless()} />
                    <ButtonPro variant="secondary" content={"I Want Paper"} onClick={() => props.onPaper()} />
                </div>
            </div>
        </BrandedCard>
    );
}
