import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function CheckSquare(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="49"
            height="50"
            viewBox="0 0 49 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14 18.502L20.588 27.724C20.8592 28.1037 21.2138 28.4161 21.6247 28.6373C22.0355 28.8585 22.4915 28.9825 22.9578 28.9999C23.424 29.0173 23.8881 28.9275 24.3142 28.7376C24.7404 28.5476 25.1173 28.2624 25.416 27.904L47 2"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M44 21.502V41.002C44 42.5933 43.3679 44.1194 42.2426 45.2446C41.1174 46.3698 39.5913 47.002 38 47.002H8C6.4087 47.002 4.88258 46.3698 3.75736 45.2446C2.63214 44.1194 2 42.5933 2 41.002V11.002C2 9.41065 2.63214 7.88453 3.75736 6.75931C4.88258 5.63409 6.4087 5.00195 8 5.00195H33.5"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
