import { ReactElement } from "react";
import { BankAccountGetResponse } from "../../../../api/backend";
import { ButtonPro } from "../../../generic";
import "./paymentAccountsRow.scss";

export type PaymentAccountsRowProps = {
    index: number;
    paymentAccount: BankAccountGetResponse;
    onDelete: Function;
};

export function PaymentAccountsRow(props: PaymentAccountsRowProps): ReactElement {
    return (
        <div className="paymentaccount-body" key={props.index}>
            <p className="paymentaccount-body-accountname">
                {props.paymentAccount.bankName} {props.paymentAccount.maskedAccountNumber}
            </p>
            <ButtonPro
                btnSize="sm"
                variant="tertiary-neg"
                className="paymentaccount-body-button"
                onClick={() => {
                    props.onDelete(props.paymentAccount);
                }}
            >
                Delete
            </ButtonPro>
        </div>
    );
}
