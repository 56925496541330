import { AxiosResponse } from "axios";
import { PCPEnrollmentRequest, PremierCreditProtectionResponse } from ".";
import { useApiQueueContext } from "../../../contexts";
import { UsePost } from "../../../hooks";
import { useIdentityConnectContext } from "../../identityServer";

export const usePCPContext = () => {
    const client = usePCPClient();

    return { ...client };
};

const usePCPClient = () => {
    const apiQueue = useApiQueueContext();
    const { access_token } = useIdentityConnectContext();

    async function Get(applicantGuid: string): Promise<AxiosResponse<PremierCreditProtectionResponse, any>> {
        return await apiQueue.Get(`api/v1/PremierCreditProtection?applicantGuid=${applicantGuid}`, access_token);
    }

    async function Post(application: PCPEnrollmentRequest): Promise<AxiosResponse<any, any>> {
        return await UsePost("api/v1/PremierCreditProtection", access_token, application);
    }

    return {
        Get,
        Post
    };
};
