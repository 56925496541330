import { EnrollmentCmsModel, useLoaderCms } from "../../../api/backend";

export type EnrollTermsData = {
    cms: EnrollmentCmsModel;
};

export async function EnrollTermsLoader(): Promise<EnrollTermsData> {
    const { Enrollment } = useLoaderCms();
    let cms = (await Enrollment()).data;

    return { cms };
}
