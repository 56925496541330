import { ReactElement } from "react";
import { CardAppPreview, CardAppStatus, CardProgramFee, CardSecurityDeposit } from "../../cardTiles";
import "../cardApplication.scss";
import { CardApplicationViewProps } from "../cardApplicationView";
import { CardApplicationHeader } from "../header/cardApplicationHeader";

export function CardApplicationMainView(props: CardApplicationViewProps): ReactElement {
    return (
        <div className="cardApplication">
            <CardApplicationHeader {...props} />
            <div className={`cardApplication-tiles${props.size}`}>
                <CardAppStatus {...props} />
                {!["D", "W"].includes(props.account.status) ? (
                    <>
                        {props.account.balanceType === "Program Fee" && props.account.applicationFee > 0 ? (
                            <CardProgramFee {...props} />
                        ) : props.account.balanceType === "Security Deposit" && props.account.applicationFee > 0 ? (
                            <CardSecurityDeposit {...props} />
                        ) : (
                            <></>
                        )}
                        <CardAppPreview {...props} />
                    </>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}
