import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAccountContext } from "../../../api/backend";
import { GenericModalProps } from "../../generic";

type RequestAuthUserViewModel = {
    state: RequestAuthUserState;
};

export type RequestAuthUserState = {
    showSubmitted: boolean;
    setShowSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
    submittedProps: GenericModalProps;
    showError: boolean;
    setShowError: React.Dispatch<React.SetStateAction<boolean>>;
    errorProps: GenericModalProps;
};

export function useRequestAuthUserViewModel(): RequestAuthUserViewModel {
    const navigate = useNavigate();
    const { id } = useParams();
    const [showSubmitted, setShowSubmitted] = useState(false);
    const [showError, setShowError] = useState(false);
    const { setAccountIndex } = useAccountContext();

    useEffect(() => {
        setAccountIndex(Number(id));
    }, []);

    const submittedProps = {
        headerText: "Your Request Has Been Submitted",
        bodyText:
            "You have successfully submitted your Authorized User request. You will receive a response via electronic delivery (if enrolled) or by mail within 7-10 days.",
        primaryButton: {
            text: "Back to Home",
            action: () => {
                setShowSubmitted(false);
                navigate("/account/summary/" + id + "/home", { relative: "path" });
            }
        }
    } as GenericModalProps;

    const errorProps = {
        headerText: "Add Authorized User Unavailable",
        bodyText: "We're sorry, something went wrong. Please try again.",
        primaryButton: {
            text: "Close",
            action: () => setShowError(false)
        }
    } as GenericModalProps;

    return {
        state: {
            showSubmitted,
            setShowSubmitted,
            submittedProps,
            showError,
            setShowError,
            errorProps
        }
    };
}
