import { Outlet } from "react-router-dom";
import { AppHeaderPublic, Footer, ProminentAlert } from "../..";
import { ColorBlocks2 } from "../../generic/icons";
import "./publicView.scss";
import "./publicViewClean.scss";
import { PublicViewProps } from "./publicViewContext";
import { PublicViewState } from "./publicViewModel";

export function PublicView(props: PublicViewProps & PublicViewState) {
    return (
        <div id="publicView">
            <AppHeaderPublic />
            <div id="publicView-outter" className={`publicView-outter${props.showBanner ? "-banner" : ""}`}>
                <div id="publicView-content" className={props.restricted ? "restricted" : ""}>
                    <div id="content-wrapper">
                        <div className="content">
                            {props.appSettings?.featureFlags?.displayBannerUnauthenticated ? <ProminentAlert /> : <></>}
                            {props.children !== undefined ? props.children : <Outlet />}
                        </div>
                        <div className="background">
                            {props.showBlocks && props.size === "" ? (
                                <ColorBlocks2 className="publicView-blocks" />
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export function PublicViewClean(props: PublicViewProps) {
    return (
        <div id="publicViewClean">
            <div id="publicViewClean-content">{props.children !== undefined ? props.children : <Outlet />}</div>
        </div>
    );
}
