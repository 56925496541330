import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function SmartPhoneCard(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="48"
            height="49"
            viewBox="0 0 48 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M30.5 38.888V44C30.5 44.7956 30.1839 45.5587 29.6213 46.1213C29.0587 46.6839 28.2956 47 27.5 47H4.5C3.70435 47 2.94129 46.6839 2.37868 46.1213C1.81607 45.5587 1.5 44.7956 1.5 44V5C1.5 4.20435 1.81607 3.44129 2.37868 2.87868C2.94129 2.31607 3.70435 2 4.5 2H27.5C28.2956 2 29.0587 2.31607 29.6213 2.87868C30.1839 3.44129 30.5 4.20435 30.5 5V7"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M2.08594 38.8879H30.4999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M43.25 13.5H23.75C21.9551 13.5 20.5 14.9551 20.5 16.75V29.75C20.5 31.5449 21.9551 33 23.75 33H43.25C45.0449 33 46.5 31.5449 46.5 29.75V16.75C46.5 14.9551 45.0449 13.5 43.25 13.5Z"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M20.5 20H46.5" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
