import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function ArrowLeft(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="10"
            height="18"
            viewBox="0 0 10 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 17L1.3776 9.37689C1.32806 9.32742 1.28876 9.26867 1.26194 9.204C1.23513 9.13933 1.22133 9.07001 1.22133 9C1.22133 8.92999 1.23513 8.86067 1.26194 8.796C1.28876 8.73133 1.32806 8.67258 1.3776 8.62311L9 1"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
