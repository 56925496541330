import { AxiosResponse } from "axios";
import {
    AccountSummaryCms,
    ApplicationStatusCms,
    BrowserMessageCmsModel,
    BrowserVersionCmsModel,
    ChangePasswordCms,
    CmsToolTip,
    CommunicationSettingsCmsModel,
    ConsumerAlertCms,
    CreditLineIncreaseCms,
    CurrentCreditCardOffersCms,
    DisclosuresCms,
    EnrollmentCmsModel,
    FormsAndMaterialsCms,
    IntermediateWarningCms,
    LettersCms,
    LoginCms,
    PaymentCms,
    PremierCreditProtectionCMS,
    PublicCCPAPageViewModel,
    ToolTipCode,
    ToolTipsCms,
    UpdateProfileCms
} from ".";
import { useApiQueueContext } from "../../../contexts";
import { UseGet, useSessionStorage } from "../../../hooks";
import { Decrypt, Encrypt } from "../../../utility";

const storageKeys = [""];

export const useCmsContext = () => {
    const apiQueue = useApiQueueContext();
    const [storage, storeValue] = useSessionStorage(storageKeys);

    const get = <T>(key: string) => {
        return Decrypt(storage[key]) as T;
    };

    const set = (key: string, value: any) => {
        storeValue(key, Encrypt(value));
    };

    const fetch = async <T>(key: string) => {
        if (!storage[key]) {
            return await fetchGet<T>(key);
        } else {
            let value = get<T>(key);

            if (JSON.stringify(value) === "{}") {
                return await fetchGet<T>(key);
            }

            return await Promise.resolve({ data: value } as AxiosResponse<T, any>);
        }
    };

    const fetchGet = async <T>(key: string) => {
        return await apiQueue.Get<T>(`/api/v1/cms/${key}`).then((x) => {
            set(key, x.data);
            return x;
        });
    };

    async function AccountSummary(): Promise<AxiosResponse<AccountSummaryCms, any>> {
        return await fetch("AccountSummaryMobilePage");
    }

    async function ApplicationStatus(): Promise<AxiosResponse<ApplicationStatusCms, any>> {
        return await fetch("ApplicationStatusPage");
    }

    async function CommunicationSettings(): Promise<AxiosResponse<CommunicationSettingsCmsModel, any>> {
        return await fetch("CommunicationSettingsPage");
    }

    async function ConsumerAlert(): Promise<AxiosResponse<ConsumerAlertCms, any>> {
        return await fetch("ConsumerAlertPage");
    }

    async function IntermediateWarning(): Promise<AxiosResponse<IntermediateWarningCms, any>> {
        return await fetch("GlobalCollateralIntermediateWarningConfigurationPage");
    }

    async function Login(): Promise<AxiosResponse<LoginCms, any>> {
        return await fetch("LoginPage");
    }

    async function Payment(): Promise<AxiosResponse<PaymentCms, any>> {
        return await fetch("PaymentMobilePage");
    }

    async function PremierCreditProtection(): Promise<AxiosResponse<PremierCreditProtectionCMS, any>> {
        return await fetch("PremierCreditProtectionPage");
    }

    async function UpdateProfile(): Promise<AxiosResponse<UpdateProfileCms, any>> {
        return await fetch("UpdateProfilePage");
    }

    async function ToolTips(): Promise<AxiosResponse<ToolTipsCms, any>> {
        return await fetch("GlobalCollateralToolTipPage");
    }

    function EmptyTip(code: ToolTipCode): CmsToolTip {
        return {
            Id: 0,
            BodyText: "",
            Active: false,
            Name: "",
            ToolTipCode: code,
            ToolTipType: ""
        };
    }

    return {
        AccountSummary,
        ApplicationStatus,
        CommunicationSettings,
        ConsumerAlert,
        IntermediateWarning,
        Login,
        Payment,
        PremierCreditProtection,
        UpdateProfile,
        ToolTips,
        EmptyTip
    };
};

export const useLoaderCms = () => {
    const get = <T>(key: string) => {
        return Decrypt(sessionStorage[key]) as T;
    };

    const set = (key: string, value: any) => {
        sessionStorage.setItem(key, Encrypt(value));
    };

    const fetch = async <T>(key: string) => {
        return await UseGet<T>(`/api/v1/cms/${key}`);
    };

    const fetchAndStore = async <T>(key: string) => {
        if (!sessionStorage[key]) {
            return await fetchGet<T>(key);
        } else {
            let value = get<T>(key);

            if (JSON.stringify(value) === "{}") {
                return await fetchGet<T>(key);
            }

            return await Promise.resolve({ data: value } as AxiosResponse<T, any>);
        }
    };

    const fetchGet = async <T>(key: string) => {
        return await UseGet<T>(`/api/v1/cms/${key}`).then((x) => {
            set(key, x.data);
            return x;
        });
    };

    async function BrowserMessage(): Promise<AxiosResponse<BrowserMessageCmsModel, any>> {
        return await fetchAndStore("BrowserMessagePage");
    }

    async function BrowserVersion(): Promise<AxiosResponse<BrowserVersionCmsModel, any>> {
        return await fetchAndStore("GlobalBrowserVersionConfigurationPage");
    }

    async function CCPAPublicPage(): Promise<AxiosResponse<PublicCCPAPageViewModel, any>> {
        return await fetchAndStore("PublicCCPAPage");
    }

    async function ChangePassword(): Promise<AxiosResponse<ChangePasswordCms, any>> {
        return await fetchAndStore("ChangePasswordPage");
    }

    async function CreditLineIncrease(): Promise<AxiosResponse<CreditLineIncreaseCms, any>> {
        return await fetchAndStore("CreditLineIncreasePage");
    }

    async function CurrentCreditCardOffers(): Promise<AxiosResponse<CurrentCreditCardOffersCms, any>> {
        return await fetchAndStore("CurrentCreditCardOffersPage");
    }

    async function Disclosures(): Promise<AxiosResponse<DisclosuresCms, any>> {
        return await fetchAndStore("DisclosurePage");
    }

    async function Enrollment(): Promise<AxiosResponse<EnrollmentCmsModel, any>> {
        return await fetchAndStore("EnrollmentPage");
    }

    async function FormsAndMaterials(): Promise<AxiosResponse<FormsAndMaterialsCms, any>> {
        return await fetch("GlobalCollateralFormsAndMaterialsConfigurationPage");
    }

    async function Letters(): Promise<AxiosResponse<LettersCms, any>> {
        return await fetchAndStore("LettersPage");
    }

    async function PremierCreditProtection(): Promise<AxiosResponse<PremierCreditProtectionCMS, any>> {
        return await fetchAndStore("PremierCreditProtectionPage");
    }

    return {
        BrowserMessage,
        BrowserVersion,
        CCPAPublicPage,
        ChangePassword,
        CreditLineIncrease,
        CurrentCreditCardOffers,
        Disclosures,
        Enrollment,
        FormsAndMaterials,
        Letters,
        PremierCreditProtection
    };
};

//UNUSED CMS CALLS

// async function AboutUs(): Promise<AxiosResponse<AboutUsCms, any>> {
//     return await UseGet("/api/v1/cms/AboutUsPage");
// }

// async function AccountActivity(): Promise<AxiosResponse<AccountActivityCms, any>> {
//     return await UseGet(`/api/v1/cms/AccountActivityPage`);
// }

// async function AccountTransactionHistory(): Promise<AxiosResponse<AccountTransactionHistoryCms, any>> {
//     return await UseGet(`/api/v1/cms/AccountTransactionHistoryPage`);
// }

// async function AdditionalCard(): Promise<AxiosResponse<AdditionalCardCms, any>> {
//     return await UseGet(`/api/v1/cms/AdditionalCardMobilePage`);
// }

// async function CCPALandingPage(): Promise<AxiosResponse<CCPALandingPageModel, any>> {
//     return await UseGet(`/api/v1/cms/CCPALandingPage`);
// }

// async function CCPAPrivatePage(): Promise<AxiosResponse<PrivateCCPAPageModel, any>> {
//     return await UseGet(`/api/v1/cms/PrivateCCPAPage`);
// }

// async function ContactUs(): Promise<AxiosResponse<ContactUsCms, any>> {
//     return await UseGet(`/api/v1/cms/ContactUsPage`);
// }

// async function DisputeTransaction(): Promise<AxiosResponse<DisputeTransactionCms, any>> {
//     return await UseGet(`/api/v1/cms/DisputeTransactionMobilePage`);
// }

// async function Faq(): Promise<AxiosResponse<FaqCmsModel, any>> {
//     return await UseGet(`/api/v1/cms/FaqPage`);
// }

// async function GlobalImageData(): Promise<AxiosResponse<GlobalImageDataCms, any>> {
//     return await UseGet(`/api/v1/cms/GlobalImageDataConfigurationPage`);
// }

// async function Help(): Promise<AxiosResponse<HelpCmsModel, any>> {
//     return await UseGet(`/api/v1/cms/HelpPage`);
// }

// async function Inquiry(): Promise<AxiosResponse<InquiryCms, any>> {
//     return await UseGet(`/api/v1/cms/InquiryMobilePage`);
// }

// async function LinkYourAccounts(): Promise<AxiosResponse<LinkYourAccountsCms, any>> {
//     return await UseGet(`/api/v1/cms/LinkYourAccountsPage`);
// }

// async function MultipleAccountPopup(): Promise<AxiosResponse<MultipleAccountPopupCms, any>> {
//     return await UseGet(`/api/v1/cms/GlobalCollateralMultipleAccountPopupConfigurationPage`);
// }

// async function RequestPin(): Promise<AxiosResponse<RequestPinCms, any>> {
//     return await UseGet(`/api/v1/cms/RequestPinMobilePage`);
// }

// async function Statements(): Promise<AxiosResponse<StatementsCms, any>> {
//     return await UseGet(`/api/v1/cms/StatementMobilePage`);
// }

// async function SystemOptions(): Promise<AxiosResponse<SystemOptionsCms, any>> {
//     return await UseGet(`/api/v1/cms/GlobalCollateralSystemOptionsConfigurationPage`);
// }
