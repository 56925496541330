import { ReactElement, useEffect } from "react";
import { PCPEnrollmentRequest, ValidationErrors } from "../../../../../api/backend";
import { FormProps } from "../../../../../types";
import { HelpLine } from "../../../../../utility";
import { Modal, ModalProps } from "../../../../generic";
import { EnrollmentStatusArray } from "../pcpEnrollmentStatusViewModel";
import PCPEnrollmentModalView from "./pcpEnrollmentModalView";
import { usePCPEnrollmentModalViewModel } from "./pcpEnrollmentModalViewModel";

export type PcpEnrollmentModalProps = ModalProps & {
    enrollmentArray: EnrollmentStatusArray[];
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    overSixtyTwo: boolean | undefined;
};

export function PCPEnrollmentModal(props: PcpEnrollmentModalProps): ReactElement {
    return (
        <Modal show={props.show}>
            <PCPEnrollmentModel {...props} />
        </Modal>
    );
}

function PCPEnrollmentModel(props: PcpEnrollmentModalProps): ReactElement {
    const { state, api } = usePCPEnrollmentModalViewModel();

    function onCancel() {
        props.setShow(false);
    }
    useEffect(() => {
        if (state.errorCount >= 3) {
            state.setInitialError(
                `We are unable to process your request. Please verify your initials match, or call Customer Service at ${HelpLine(state.accounts![state.accountIndex].isCorpCard)}.`
            );
        }
    }, [state.errorCount]);

    function onBlur(
        value: FormProps<{
            initials: string;
        }>
    ) {
        if (value.values.initials.length < 2) {
            state.setInitialError("Initials must be 2-3 characters");
        }
    }

    function onChange(
        value: FormProps<{
            initials: string;
        }>
    ) {
        if (value.values.initials !== state.initialsSubmitted && value.values.initials.length >= 2) {
            state.setInitialError("");
        }
        state.setCanContinue(value.values.initials.length >= 2);
    }

    function onSubmit(
        value: FormProps<{
            initials: string;
        }>
    ) {
        state.setInitialsSubmitted(value.values.initials);
        let application: PCPEnrollmentRequest = {
            accountsToEnroll: [],
            applicantGuid: state.accounts![0].applicantHandle,
            initials: value.values.initials.toLocaleUpperCase(),
            over62Enroll: props.overSixtyTwo!
        };
        props.enrollmentArray.forEach((enrollmentAccount) => {
            if (enrollmentAccount.checked && !enrollmentAccount.enrolled)
                application.accountsToEnroll.push(enrollmentAccount.accountHandle);
        });
        api.getPCPEnrollmentStatus(application)
            .then(() => {
                props.setShow(false);
                props.setShowSuccess(true);
            })
            .catch((err) => {
                let errors = err.response?.data as ValidationErrors;
                let errorField = Object.keys(errors.errors)[0];
                state.setInitialError(errors.errors[errorField][0]);
                state.setErrorCount(state.errorCount + 1);
            });
    }

    function onTermsClick() {
        window.open(window.location.origin + "/home/PREMIER-credit-protection-disclosure", "_blank");
    }

    return (
        <PCPEnrollmentModalView
            {...props}
            {...state}
            enrollmentArray={props.enrollmentArray}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onChange={onChange}
            onBlur={onBlur}
            onTermsClick={onTermsClick}
        />
    );
}
