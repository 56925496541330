import { ReactElement } from "react";
import { useViewport } from "../../../hooks";
import MarketingPhoto1 from "../../../res/images/marketingphoto1.png";
import { ButtonPro } from "../../generic";
import {
    ArrowDown,
    ArrowUp,
    BriefCase,
    ColorBlocks3,
    ColorBlocks4,
    Disability,
    HandHeart,
    HeartBeat
} from "../../generic/icons";
import "./pcp.scss";
import { PCPAPI, PCPState } from "./pcpViewModel";

type PCPViewProps = PCPState & PCPAPI;

function PCPView(props: PCPViewProps): ReactElement {
    const { size } = useViewport(true, true);

    return (
        <>
            <div className={`pcp-main${size}`}>
                <div className="pcp-section-top-outer">
                    <div className="pcp-section-top">
                        <div className="pcp-text-container">
                            <div className="pcp-section-header">
                                <div className="pcp-page-title">PREMIER Credit Protection</div>
                                <div className="pcp-page-subtitle">
                                    Protect your credit card account against the unexpected. We offer PREMIER Credit
                                    Protection.
                                </div>
                                <p>
                                    Don't let an unexpected event, like a job loss or medical challenge, interrupt your
                                    journey to a brighter financial future. Enroll in PREMIER Credit Protection, our
                                    optional payment protection plan for your PREMIER Bankcard Credit Card account.
                                </p>
                                <div className="pcp-enroll-button-top">
                                    <ButtonPro
                                        className="pcp-button"
                                        variant="primary"
                                        onClick={() => props.navigateToPCP()}
                                    >
                                        <div className="pcp-button-text">Enroll Now</div>
                                    </ButtonPro>
                                </div>
                            </div>
                        </div>
                        <div className="pcp-hero-container">
                            <div className="pcp-hero-section">
                                <div className="pcp-hero-img">
                                    <picture className="pcp-hero-pic">
                                        <img className="pcp-hero-photo" src={MarketingPhoto1} alt="Happy Family" />
                                        <ColorBlocks3 className="pcp-hero-blocks" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pcp-middle-outer">
                    <div className="pcp-section-middle">
                        <div className="pcp-middle-subtitle">
                            <h3>
                                PREMIER Credit Protection may provide you with benefits in case you experience a
                                protected event such as:
                            </h3>
                        </div>

                        <div className="pcp-squares">
                            <div className="pcp-events">
                                <div className="pcp-event-box">
                                    <div className="pcp-square-icon">
                                        <BriefCase />
                                    </div>
                                    <div className="pcp-square-text">Involuntary Unemployment</div>
                                </div>
                                <div className="pcp-event-box">
                                    <div className="pcp-square-icon">
                                        <Disability />
                                    </div>
                                    <div className="pcp-square-text">Disability</div>
                                </div>
                                <div className="pcp-event-box">
                                    <div className="pcp-square-icon">
                                        <HandHeart />
                                    </div>
                                    <div className="pcp-square-text">Unpaid Family Leave</div>
                                </div>
                                <div className="pcp-event-box">
                                    <div className="pcp-square-icon">
                                        <HeartBeat />
                                    </div>
                                    <div className="pcp-square-text">Hospitalization</div>
                                </div>
                            </div>
                        </div>

                        {props.viewMore ? (
                            <div className="pcp-expandable-events">
                                <div className="pcp-qualifying-events">
                                    <div className="pcp-qualify-event-title">What are Protected Events?</div>
                                    <div className="pcp-qualify-event-text">
                                        <ul>
                                            <li className="pcp-qualify-event-li">
                                                <span className="pcp-qualify-event-text-bold">
                                                    Involuntary Unemployment:
                                                </span>{" "}
                                                You become and remain totally and continuously unemployed during the
                                                term of this contract due to permanent involuntary termination of
                                                employment: involuntary layoff or suspension of employment: authorized,
                                                unionized strike or labor dispute by a chartered or previously organized
                                                trade or labor union; lockout or discharge of employed or temporary
                                                closing in response to organized employee activity.
                                            </li>
                                            <li className="pcp-qualify-event-li">
                                                <span className="pcp-qualify-event-text-bold">Disability:</span> You
                                                become and remain unable to perform the major duties of your occupation
                                                as a direct result of unintentional bodily injury, sickness, or disease
                                                suffered during the term of this contract, and you are not working for
                                                wages or profit during the activation period of the Disability, and you
                                                are under the continuous care of a licensed physician (other than
                                                yourself).
                                            </li>
                                            <li className="pcp-qualify-event-li">
                                                <span className="pcp-qualify-event-text-bold">
                                                    Unpaid Family Leave:
                                                </span>{" "}
                                                You have a loss of income due to an unpaid leave of absence from
                                                employment beginning after the Enrollment date and continues during the
                                                term of this contract, and the leave of absence is approved by your
                                                employer, and the leave is due to one of the following: accident or
                                                illness involving yourself, a child, spouse or parent; you are recalled
                                                to active military duty: the birth or adoption of a child.
                                            </li>
                                            <li className="pcp-qualify-event-li">
                                                <span className="pcp-qualify-event-text-bold">Hospitalization:</span>{" "}
                                                You have a hospital night stay of 1 or more nights.
                                            </li>
                                            <li className="pcp-qualify-event-li">
                                                <span className="pcp-qualify-event-text-bold">Loss of Life:</span> Loss
                                                of your life during the term of the contract
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="pcp-qualify-event-footnote">
                                        *To activate protection for any protected event, each of the conditions and
                                        requirements listed in the contract for that event must be fully satisfied and
                                        the event may not be subject to any of the applicable exclusions from
                                        protection.
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="pcp-viewmore">
                            <div>
                                {props.viewMore ? (
                                    <ButtonPro
                                        btnSize="sm"
                                        variant="primary"
                                        className="pcp-viewmore-button"
                                        onClick={() => props.setViewMore(!props.viewMore)}
                                    >
                                        <p className="pcp-viewmore-text">View Less</p>
                                        <ArrowUp className="pcp-viewmore-icon" svgClass="icon-size" />
                                    </ButtonPro>
                                ) : (
                                    <ButtonPro
                                        btnSize="sm"
                                        variant="primary"
                                        className="pcp-viewmore-button"
                                        onClick={() => props.setViewMore(!props.viewMore)}
                                    >
                                        <p className="pcp-viewmore-text">View More</p>
                                        <ArrowDown className="pcp-viewmore-icon" svgClass="icon-size" />
                                    </ButtonPro>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pcp-section-footer-outer">
                    <div className="pcp-section-footer">
                        <div className="pcp-footer">
                            <div className="pcp-footer-blocks">
                                <ColorBlocks4 className="pcp-footer-blocks-img" />
                            </div>
                            <div className="pcp-footer-text">
                                <div>
                                    <p className="pcp-footer-p">
                                        At just{" "}
                                        <span className="pcp-footer-color">
                                            $0.89 per $100 of your ending monthly credit card balance
                                        </span>{" "}
                                        each month, the program is automatically billed to your PREMIER Bankcard Credit
                                        Card account.
                                    </p>
                                    <p className="pcp-footer-p-gap">
                                        We're confident you'll be glad you chose PREMIER Credit Protection. However, if
                                        you're not completely satisfied, you have 30 days to cancel and get a full
                                        refund. You can cancel at any time but will not receive a refund after the
                                        initial 30 day period.
                                    </p>
                                    <p className="pcp-footer-p-gap">
                                        To be eligible for benefits, a Protected Event must occur 30 days or more after the enrollment date. Other eligibility requirements also apply.
                                    </p>
                                </div>
                                <div className="pcp-enroll-button-bottom">
                                    <ButtonPro
                                        className="pcp-button"
                                        variant="primary"
                                        onClick={() => props.navigateToPCP()}
                                    >
                                        <div className="pcp-button-text">Enroll Now</div>
                                    </ButtonPro>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </>
    );
}

export default PCPView;
