import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function MultipleUsers(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg id={id} className={svgClass} viewBox="0 0 48 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.75 9.75C6.75 11.938 7.61919 14.0365 9.16637 15.5836C10.7135 17.1308 12.812 18 15 18C17.188 18 19.2865 17.1308 20.8336 15.5836C22.3808 14.0365 23.25 11.938 23.25 9.75C23.25 7.56196 22.3808 5.46354 20.8336 3.91637C19.2865 2.36919 17.188 1.5 15 1.5C12.812 1.5 10.7135 2.36919 9.16637 3.91637C7.61919 5.46354 6.75 7.56196 6.75 9.75V9.75Z"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.5 34.5C1.5 30.9196 2.92232 27.4858 5.45406 24.9541C7.9858 22.4223 11.4196 21 15 21C18.5804 21 22.0142 22.4223 24.5459 24.9541C27.0777 27.4858 28.5 30.9196 28.5 34.5"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.5022 2.83197C29.748 2.02255 31.1891 1.56451 32.6736 1.50623C34.158 1.44795 35.6307 1.79158 36.936 2.50085C38.2414 3.21012 39.331 4.2587 40.0898 5.53588C40.8486 6.81306 41.2485 8.27144 41.2472 9.75704C41.2459 11.2426 40.8435 12.7003 40.0825 13.9762C39.3215 15.252 38.23 16.2987 36.9234 17.0057C35.6169 17.7127 34.1436 18.0538 32.6593 17.993C31.1749 17.9321 29.7346 17.4716 28.4902 16.66"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M30 21.334C31.9771 20.8861 34.0296 20.8887 36.0056 21.3417C37.9816 21.7946 39.8303 22.6863 41.4149 23.9507C42.9994 25.2151 44.2792 26.8198 45.1595 28.6459C46.0398 30.472 46.4979 32.4728 46.5 34.5"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
