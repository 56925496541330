import { AxiosResponse } from "axios";
import { usePaymentAccountContext } from "../../../../api/backend";

export type PaymentAccountsDeleteViewModel = {
    state: PaymentAccountsDeleteState;
    api: PaymentAccountsDeleteApi;
};

export type PaymentAccountsDeleteState = {
    alertText: string;
};

type PaymentAccountsDeleteApi = {
    Delete: (bankAccountId: number) => Promise<AxiosResponse<any, any>>;
};

const usePaymentAccountsDeleteState = (): PaymentAccountsDeleteState => {
    const alertText =
        "Deleting this payment account does not cancel any scheduled online payments. To review scheduled payments, see the Payments page.";

    return {
        alertText
    };
};

const usePaymentAccountsDeleteApi = (): PaymentAccountsDeleteApi => {
    const { Delete } = usePaymentAccountContext();

    return {
        Delete
    };
};

export const usePaymentAccountsDeleteViewModel = (): PaymentAccountsDeleteViewModel => {
    const state = usePaymentAccountsDeleteState();
    const api = usePaymentAccountsDeleteApi();

    return {
        state,
        api
    };
};
