import { ReactElement, useEffect } from "react";
import { DocumentsSummaryView } from "./documentsSummaryView";
import { useDocumentsSummaryViewModel } from "./documentsSummaryViewModel";

export function DocumentsSummary(): ReactElement {
    const { hooks, state } = useDocumentsSummaryViewModel();

    useEffect(() => {
        if (!hooks.actionAllowed("eLetterView", state.accounts![state.accountIndex].accountHandle)) {
            hooks.navigate(`/account/dashboard`, { relative: "path" });
        }
    }, [state.accountIndex]);

    useEffect(() => {
        state.setShowNav(true);

        return () => {
            state.setShowNav(false);
        };
    }, []);

    return <DocumentsSummaryView {...state} />;
}
