import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import {
    BankAccountGetResponse,
    CmsToolTip,
    PaymentResponse,
    UserAccount,
    useAccountContext,
    usePaymentAccountContext,
    usePaymentContext
} from "../../../../api/backend";
import { useSwrveContext } from "../../../../contexts";
import { useTooltipCms, useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { FormatMoney, IsNullOrEmpty } from "../../../../utility";
import { SchedulePaymentProps } from "./schedulePaymentModel";

export type SchedulePaymentViewModel = {
    state: SchedulePaymentState;
    hooks: SchedulePaymentHooks;
    api: SchedulePaymentApi;
};

type SchedulePaymentHooks = {
    insertSwrveEvent: (event: string) => void;
};

export type SchedulePaymentState = {
    size: Size;
    account: UserAccount;
    last4: string;
    avoidLatePaymentText: string;
    createPaymentBodyText: string;
    createPaymentFooterText: string;
    tooltip: CmsToolTip;
    paymentAccounts: BankAccountGetResponse[];
    scheduledPayments: PaymentResponse[];
    showCalendar: boolean;
    setShowCalendar: React.Dispatch<React.SetStateAction<boolean>>;
    canContinue: boolean;
    accountError: string;
    amountError: string;
    dateError: string;
    after5: string;
    setAfter5: React.Dispatch<React.SetStateAction<string>>;
    invalidDates: PaymentResponse[];
    paymentAccountTouched: boolean;
    setPaymentAccountTouched: React.Dispatch<React.SetStateAction<boolean>>;
    paymentAmountTouched: boolean;
    setPaymentAmountTouched: React.Dispatch<React.SetStateAction<boolean>>;
};

type SchedulePaymentApi = {
    paymentAccountsGet: () => Promise<AxiosResponse<BankAccountGetResponse[], any>>;
};

const useSchedulePaymentState = (props: SchedulePaymentProps): SchedulePaymentState => {
    const { size } = useViewport(false, true);
    const { accounts, accountIndex } = useAccountContext();
    const { paymentAccounts } = usePaymentAccountContext();
    const { paymentCms, scheduled: scheduledPayments } = usePaymentContext();
    const { getTooltip } = useTooltipCms();
    const [showCalendar, setShowCalendar] = useState(false);
    const [paymentAccountTouched, setPaymentAccountTouched] = useState(false);
    const [paymentAmountTouched, setPaymentAmountTouched] = useState(false);
    const [after5, setAfter5] = useState("");
    let canContinue = false;
    let accountError = "";
    let amountError = "";
    let dateError = "";

    const invalidDates = useMemo(
        () => scheduledPayments.filter((d) => d.paymentStatus === "Scheduled"),
        [scheduledPayments]
    );

    const tooltip = getTooltip("totalMinDueToolTip");
    const account = accounts[accountIndex];
    const last4 = account.maskedAccountNumber.slice(-4);

    const avoidLatePaymentText =
        "Payment requests received by 5:00 pm CT will be credited as of today. You may not see the payment on your account for up to two days.";
    const createPaymentBodyText = paymentCms.CreatePaymentBodyText;
    const createPaymentFooterText = paymentCms.CreatePaymentFooter + " " + avoidLatePaymentText;

    let curBal = account?.currentBalance;
    const now = dayjs.tz();
    let pa = props.paymentAccountId !== 0;
    let a = props.amount !== "" && !!props.amountSelection;
    let cb = props.amount !== "" && Number(props.amount) <= curBal;
    let az = props.amount !== "" && Number(props.amount) <= 0.0;
    let d = false;

    if (paymentAccountTouched) {
        if (!pa) {
            accountError = "Payment account required";
        }
    }

    if (paymentAmountTouched) {
        if (!a) {
            amountError = "Payment amount required";
        } else if (az) {
            amountError = "Must be an amount over zero";
        } else if (!cb) {
            amountError = `Amount must be ${FormatMoney(curBal, true).formatted} or less`;
        }
    }

    if (now.isSame(props.date, "date") && now.hour() >= 17) {
        dateError = "Payments made after 5:00 PM CT must be dated for the following day";
        d = false; //no after 5pm
    } else if (
        props.date &&
        ((!now.isSame(props.date, "date") && (props.date?.diff(now, "date") ?? 0) > 0) ||
            now.isSame(props.date, "date"))
    ) {
        dateError = "";
        d = true;
    } else if (!props.date) {
        d = false;
    } else {
        d = true;
    }

    canContinue = pa && a && !az && cb && d && IsNullOrEmpty(after5);

    return {
        size,
        account,
        last4,
        avoidLatePaymentText,
        createPaymentBodyText,
        createPaymentFooterText,
        tooltip,
        paymentAccounts,
        scheduledPayments,
        showCalendar,
        setShowCalendar,
        canContinue,
        accountError,
        amountError,
        dateError,
        after5,
        setAfter5,
        invalidDates,
        paymentAccountTouched,
        setPaymentAccountTouched,
        paymentAmountTouched,
        setPaymentAmountTouched
    };
};

const useSchedulePaymentHooks = (): SchedulePaymentHooks => {
    const { insertSwrveEvent } = useSwrveContext();

    return {
        insertSwrveEvent
    };
};

const useSchedulePaymentApi = (): SchedulePaymentApi => {
    const { Get: paymentAccountsGet } = usePaymentAccountContext();

    return {
        paymentAccountsGet
    };
};

export const useSchedulePaymentViewModel = (props: SchedulePaymentProps): SchedulePaymentViewModel => {
    const state = useSchedulePaymentState(props);
    const hooks = useSchedulePaymentHooks();
    const api = useSchedulePaymentApi();

    return {
        state,
        hooks,
        api
    };
};
