import { Form, Formik } from "formik";
import { ReactElement } from "react";
import { CancelEnrollSetup, InvalidMFA3Times, Loading, TrustThisDevice } from "../..";
import { Alert, ButtonPro } from "../../generic";
import { LoginCard } from "../common/loginCardModel";
import { InvalidSession } from "../modals/invalidSession/invalidSession";
import { PinExpired } from "../modals/pinExpired/pinExpired";
import "./mfaEnterCode.scss";
import { MfaEnterCodeState } from "./mfaEnterCodeViewModel";
import { OtpInput } from "./otpInput/otpInput";

export type MfaEnterCodeViewProps = MfaEnterCodeState & {
    onChange: (value: string) => void;
    onOtpFail: Function;
    onRequestNew: Function;
    onCancel: Function;
    onSubmit: Function;
};

export type MfaFields = {
    [digit1: string]: string;
    digit2: string;
    digit3: string;
    digit4: string;
};

export type MfaErrors = {
    digit1?: string;
    digit2?: string;
    digit3?: string;
    digit4?: string;
};

export function MfaEnterCodeView(props: MfaEnterCodeViewProps): ReactElement {
    return (
        <>
            <LoginCard
                id="mfaEnterCode"
                size={props.size}
                header="Enter Your Secure Access Code"
                subtitle={props.subtitle}
            >
                <Formik
                    initialValues={props.initialValues}
                    onSubmit={(values, actions) => {
                        props.onSubmit(values, actions);
                    }}
                >
                    {({ values, errors, isSubmitting }) => (
                        <Form className={`loginCard-form${props.size}`} autoComplete="one-time-code">
                            <div id="mfaEnterCode-form-frame-1">
                                <div id="mfaEnterCode-form-passcode">
                                    <OtpInput
                                        value={props.otp}
                                        valueLength={4}
                                        onChange={props.onChange}
                                        codeState={props.codeState}
                                        onClick={() => props.onOtpFail()}
                                    />
                                    {!errors.digit1 && <h6>Enter code</h6>}
                                    {errors.digit1 && <h6 className="h6-error">{errors.digit1}</h6>}
                                </div>
                                <Alert level="info">
                                    Your code should arrive instantaneously. However, it may take up to 5 minutes to
                                    arrive. Requesting a new code will start the clock again.
                                </Alert>
                            </div>
                            <div id="mfaEnterCode-form-frame-2">
                                <h6>Didn't get it?</h6>
                                <ButtonPro
                                    type="button"
                                    className="mfaEnterCode-newCode"
                                    variant="tertiary"
                                    onClick={() => props.onRequestNew()}
                                >
                                    Request a new Code
                                </ButtonPro>
                            </div>
                            <div id="mfaEnterCode-form-frame-3">
                                <ButtonPro
                                    id="mfaEnterCode-cancel"
                                    type="button"
                                    variant="neutral"
                                    onClick={() => props.onCancel()}
                                >
                                    Cancel
                                </ButtonPro>
                                <ButtonPro
                                    id="mfaEnterCode-submit"
                                    type="submit"
                                    disabled={!props.canContinue}
                                    isSubmitting={isSubmitting}
                                >
                                    Submit
                                </ButtonPro>
                            </div>
                            <Loading show={isSubmitting} />
                        </Form>
                    )}
                </Formik>
            </LoginCard>
            <CancelEnrollSetup show={props.showCancel} setShow={props.setShowCancel} />
            <TrustThisDevice show={props.showTrust} setShow={props.setShowTrust} />
            <InvalidMFA3Times
                show={props.showError}
                setShow={props.setShowError}
                isCorpCard={props.mfaOptions?.isCorpCard ?? false}
            />
            <PinExpired show={props.showPinExpired} setShow={props.setShowPinExpired} />
            <InvalidSession show={props.showInvalidSession} setShow={props.setShowInvalidSession} />
        </>
    );
}
