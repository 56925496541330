import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function More(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg id={id} className={svgClass} width="4" height="19" viewBox="0 0 4 19" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 16.0029C0 16.5334 0.210714 17.0421 0.585786 17.4171C0.960859 17.7922 1.46957 18.0029 2 18.0029C2.53043 18.0029 3.03914 17.7922 3.41421 17.4171C3.78929 17.0421 4 16.5334 4 16.0029C4 15.4725 3.78929 14.9638 3.41421 14.5887C3.03914 14.2136 2.53043 14.0029 2 14.0029C1.46957 14.0029 0.960859 14.2136 0.585786 14.5887C0.210714 14.9638 0 15.4725 0 16.0029Z" />
            <path d="M0 9C0 9.53043 0.210714 10.0391 0.585786 10.4142C0.960859 10.7893 1.46957 11 2 11C2.53043 11 3.03914 10.7893 3.41421 10.4142C3.78929 10.0391 4 9.53043 4 9C4 8.46957 3.78929 7.96086 3.41421 7.58579C3.03914 7.21071 2.53043 7 2 7C1.46957 7 0.960859 7.21071 0.585786 7.58579C0.210714 7.96086 0 8.46957 0 9Z" />
            <path d="M0 2C0 2.53043 0.210714 3.03914 0.585786 3.41421C0.960859 3.78929 1.46957 4 2 4C2.53043 4 3.03914 3.78929 3.41421 3.41421C3.78929 3.03914 4 2.53043 4 2C4 1.46957 3.78929 0.960859 3.41421 0.585786C3.03914 0.210714 2.53043 0 2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2Z" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
