import { ReactElement } from "react";
import { CardSummaryHeader } from "..";
import { ActivityRecent, Loading } from "../../..";
import { Alert } from "../../../generic/visuals/alert/alert";
import { CardApplication } from "../../cardApplication/cardApplication";
import { CardActions, CardBalance, CardPayment } from "../../cardTiles";
import { AnnualFeeAlertModal } from "../../modals/annualFeeAlert";
import { LockCardDisabled } from "../../modals/lockCardDisabled";
import "./cardSummaryTile.scss";
import { CardSummaryTileProps } from "./cardSummaryTileModel";
import { CardSummaryTileState } from "./cardSummaryTileViewModel";

export type CardSummaryTileViewProps = CardSummaryTileProps &
    CardSummaryTileState & { header?: ReactElement; manageLockCard?: Function };

export function CardSummaryTileView(props: CardSummaryTileViewProps): ReactElement {
    return (
        <>
            {props.account.isApplicationStatus ? (
                <CardApplication index={props.index} account={props.account} cms={props.appCms} />
            ) : (
                <OpenCard {...props} />
            )}

            <TileModals {...props} />
        </>
    );
}

function OpenCard(props: CardSummaryTileViewProps): ReactElement {
    return (
        <div className={`cardSummaryTileContainer${props.size}`}>
            <div className={`cardSummaryTileHeader${props.size}`}>
                {props.header ? props.header : <CardSummaryHeader {...props} />}
            </div>
            <PastDueAlert {...props} />
            <CardLockedAlert {...props} />
            <UnLockCardSuccess {...props} />
            <AnnualFeeAlert {...props} />

            {props.account.isChargedOffStatus ? (
                <div className={`cardSummaryTileCO${props.size}`}>
                    <div className={`cardSummaryTilePaymentCO${props.size}`}>
                        <CardPayment {...props} />
                    </div>
                </div>
            ) : (
                <div className={`cardSummaryTile${props.size}`}>
                    <div className={`cardSummaryTileBalance${props.size}`}>
                        <CardBalance {...props} />
                    </div>
                    <div className={`cardSummaryTilePayment${props.size}`}>
                        <CardPayment {...props} />
                    </div>
                    <div className={`cardSummaryTileActions${props.size}`}>
                        <CardActions
                            lockStatus={props.lockStatus}
                            lockLoading={props.lockLoading}
                            index={props.index}
                            showLockCardDisabledModal={props.showLockCardDisabledModal}
                            setShowLockCardDisabledModal={props.setShowLockCardDisabledModal}
                            manageLockCard={props.manageLockCard!}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export function TileModals(props: CardSummaryTileViewProps): ReactElement {
    return (
        <>
            <LockCardDisabled
                show={props.showLockCardDisabledModal}
                setShow={props.setShowLockCardDisabledModal}
                account={props.account}
            />

            {props.header === undefined ? <ActivityRecent /> : <></>}
            <Loading show={props.loading} />
        </>
    );
}

function UnLockCardSuccess(props: CardSummaryTileViewProps): ReactElement {
    return (
        <>
            {props.showUnLockCardSuccess ? (
                <div className="cardSummaryTile-alert-container">
                    <Alert level={"success"} text={"Your card has been unlocked"} />
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

export function PastDueAlert(props: CardSummaryTileViewProps): ReactElement {
    return (
        <>
            {props.showAlert ? (
                <div className="cardSummaryTile-alert-container">
                    <Alert level={"error"} header={props.alert} text={""} />
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

export function AnnualFeeAlert(props: CardSummaryTileViewProps): ReactElement {
    return (
        <>
            {props.account.showAnnualFeeMessage ? (
                <>
                    <div className={`cardSummaryTile-alert-container`}>
                        <Alert level={"warning"} header={""} more={() => props.setShowMoreAnnualFeeAlert(true)}>
                            <h6 className={`cardSummaryTile-alert-annualfee${props.size}`}>{props.alertBody}</h6>
                        </Alert>
                    </div>
                    <AnnualFeeAlertModal
                        show={props.showMoreAnnualFeeAlert}
                        setShow={props.setShowMoreAnnualFeeAlert}
                        bodyText={props.alertBody}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    );
}

export function CardLockedAlert(props: CardSummaryTileViewProps): ReactElement {
    return (
        <>
            {props.lockStatus ? (
                <div className="cardSummaryTile-alert-container">
                    <Alert
                        level={"warning"}
                        text={"Your card is currently locked"}
                        more={() => {
                            props.manageLockCard!();
                        }}
                    />
                </div>
            ) : (
                <></>
            )}
        </>
    );
}
