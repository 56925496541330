import { useExpandableRowContext } from "../../../../contexts";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { ActivityRowProps } from "./activityRow";

type ActivityRowHooks = {
    newRowExpanded: (index: number) => void;
};

export type ActivityRowState = {
    size: Size;
    expanded: boolean;
    setCurIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
    debitOrCredit: string;
};

const useActivityRowState = (props: ActivityRowProps): ActivityRowState => {
    const { size } = useViewport(false, true);
    const { curIndex, setCurIndex } = useExpandableRowContext();
    const expanded = props.index === curIndex;
    const debitOrCredit: string = props.activity?.TransactionAmount < 0 ? "credit" : "debit";

    return {
        size,
        expanded,
        debitOrCredit,
        setCurIndex
    };
};

const useActivityRowHooks = (): ActivityRowHooks => {
    const { newRowExpanded } = useExpandableRowContext();

    return {
        newRowExpanded
    };
};

export const useActivityRowViewModel = (props: ActivityRowProps) => {
    const state = useActivityRowState(props);
    const hooks = useActivityRowHooks();

    return {
        state,
        hooks
    };
};
