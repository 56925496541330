import "./cardAppPreview.scss";
import "../../cardSummary/cardSummaryCommon.scss";
import { ReactElement } from "react";
import { CardAppPreviewState } from "./cardAppPreviewViewModel";
import { CardApplicationProps } from "../../cardApplication/cardApplication";
import { FormatMoney } from "../../../../utility";

type CardAppPreviewViewProps = CardApplicationProps & CardAppPreviewState & {};

export function CardAppPreviewView(props: CardAppPreviewViewProps): ReactElement {
    return (
        <div className={`cardAppPreview${props.size} ${props.account.applicationFee <= 0 ? "nonPfSd" : ""}`}>
            <div className="cardAppPreview-content cardSummaryCommon cardTilePadding">
                <h5>Upon Account Opening</h5>
                <div className="cardAppPreview-amount">
                    <p>Credit Limit</p>
                    <h5>{FormatMoney(props.account.creditLimit).formatted}</h5>
                </div>
                <div className="cardAppPreview-amount">
                    <p>First Year Annual Fee</p>
                    <h5>{FormatMoney(props.account.annualCharge).formatted}</h5>
                </div>
            </div>
        </div>
    );
}
