import * as Yup from "yup";
import { EmailRegex } from "../../../../../utility/regex";

export const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .trim()
        .min(2, length("First Name", 2, 50))
        .max(50, "")
        .required(required("First Name")),
    lastName: Yup.string()
        .trim()
        .min(2, length("Last Name", 2, 50))
        .max(50, "")
        .required(required("Last Name")),
    address1: Yup.string()
        .trim()
        .min(2, length("Address Line 1", 2, 26))
        .max(26, "")
        .required(required("Address Line 1")),
    address2: Yup.string().trim().max(26, ""),
    city: Yup.string()
        .trim()
        .min(2, length("City", 2, 18))
        .max(18, "")
        .required(required("City")),
    state: Yup.string().required(required("State")),
    zip: Yup.string()
        .trim()
        .matches(/^[0-9]{5}(?:[0-9]{4})?$/, "Zip Code must be either 5 or 9 digits")
        .required(required("Zip Code")),
    phoneNumber: Yup.string().trim().length(10, "Phone Number must be ten digits").required(required("Phone Number")),
    emailAddress: Yup.string()
        .trim()
        .max(50, "")
        .matches(EmailRegex, "Email is invalid")
        .required(required("Email Address")),
    includeAgentInfo: Yup.boolean(),
    agentFirstName: Yup.string().when("includeAgentInfo", {
        is: (include: boolean) => !!include,
        then: () =>
            Yup.string()
                .trim()
                .min(2, length("First Name", 2, 50))
                .max(50, "")
                .required(required("First Name")),
        otherwise: () => Yup.string().notRequired()
    }),
    agentLastName: Yup.string().when("includeAgentInfo", {
        is: (include: boolean) => !!include,
        then: () =>
            Yup.string()
                .trim()
                .min(2, length("Last Name", 2, 50))
                .max(50, "")
                .required(required("Last Name")),
        otherwise: () => Yup.string().notRequired()
    }),
    agentAddress1: Yup.string().when("includeAgentInfo", {
        is: (include: boolean) => !!include,
        then: () =>
            Yup.string()
                .trim()
                .min(2, length("Address Line 1", 2, 26))
                .max(26, "")
                .required(required("Address Line 1")),
        otherwise: () => Yup.string().notRequired()
    }),
    agentAddress2: Yup.string().when("includeAgentInfo", {
        is: (include: boolean) => !!include,
        then: () => Yup.string().trim().max(26, ""),
        otherwise: () => Yup.string().notRequired()
    }),
    agentCity: Yup.string().when("includeAgentInfo", {
        is: (include: boolean) => !!include,
        then: () =>
            Yup.string()
                .trim()
                .min(2, length("City", 2, 18))
                .max(18, "")
                .required(required("City")),
        otherwise: () => Yup.string().notRequired()
    }),
    agentState: Yup.string().when("includeAgentInfo", {
        is: (include: boolean) => !!include,
        then: () => Yup.string().required(required("State")),
        otherwise: () => Yup.string().notRequired()
    }),
    agentZip: Yup.string().when("includeAgentInfo", {
        is: (include: boolean) => !!include,
        then: () =>
            Yup.string()
                .trim()
                .matches(/^[0-9]{5}(?:[0-9]{4})?$/, "Zip Code must be either 5 or 9 digits")
                .required(required("Zip Code")),
        otherwise: () => Yup.string().notRequired()
    })
});

function required(field: string): string {
    return `${field} is required`;
}

function length(field: string, min: number, max: number): string {
    return `${field} must be ${min}-${max} characters `;
}
