import { AxiosResponse } from "axios";
import { useState } from "react";
import { NavigateFunction, useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import { UserConsentResponse, useEnrollmentContext, useUserConsentContext } from "../../../api/backend";
import {
    ForgotPasswordAttemptsResponse,
    ForgotUsernamePasswordMFAResponse,
    MFAOptions,
    useIdentityPremierContext
} from "../../../api/identityServer";
import { useMfaContext, useOneTrustContext } from "../../../contexts";
import { SecurityType } from "../../../contexts/mfaContext";
import { useSessionStorage, useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { ForgotLoginData } from "./forgotLoginLoader";

type ForgotLoginViewModel = {
    hooks: ForgotLoginHooks;
    state: ForgotLoginState;
    api: ForgotLoginApi;
};

type ForgotLoginHooks = {
    navigate: NavigateFunction;
    storeValue: (name: string, value: any) => void;
    syncOneTrustConsentProfile: (consumerGuid: string, jwt: string) => void;
    updateConsumerGuid: (consumerGuid?: string | undefined) => void;
    updateJwt: (jwt?: string | undefined) => void;
};

export type ForgotLoginState = {
    size: Size;
    securityType: SecurityType | undefined;
    setSecurityType: (value: SecurityType) => void;
    setMfaOptions: (value: MFAOptions) => void;
    searchParams: URLSearchParams;
    ssn: string;
    setSSN: React.Dispatch<React.SetStateAction<string>>;
    refNumber: string;
    setRefNumber: React.Dispatch<React.SetStateAction<string>>;
    errorHeader: string;
    setErrorHeader: React.Dispatch<React.SetStateAction<string>>;
    errorMessage: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    showErrorModal: boolean;
    setShowErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
    canContinue: boolean;
    setCanContinue: React.Dispatch<React.SetStateAction<boolean>>;
    errorAction: () => void;
    setErrorAction: React.Dispatch<React.SetStateAction<() => void>>;
    subtitle: string;
    continueText: string;
    errorButtonText: string;
    setErrorButtonText: React.Dispatch<React.SetStateAction<string>>;
};

type ForgotLoginApi = {
    forgotUsernamePassword: (eventType: string) => Promise<AxiosResponse<ForgotUsernamePasswordMFAResponse>>;
    saveForgotUsernameDetails: () => Promise<AxiosResponse<any, any>>;
    saveForgotPasswordDetails: () => Promise<AxiosResponse<any, any>>;
    forgotPasswordAttempts: () => Promise<AxiosResponse<ForgotPasswordAttemptsResponse, any>>;
    userConsentGet: (onlineUserId: number, applicantHandle: string) => Promise<AxiosResponse<UserConsentResponse, any>>;
    validateIdentity: (sessionId: string) => Promise<AxiosResponse<any, any>>;
};

const storageKeys = ["username"];

export function useForgotLoginViewModel(): ForgotLoginViewModel {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const data = useLoaderData() as ForgotLoginData;
    const [_, storeValue] = useSessionStorage(storageKeys);
    const { securityType, setSecurityType, setMfaOptions } = useMfaContext();
    const { size } = useViewport(false, true);
    const [ssn, setSSN] = useState("");
    const [refNumber, setRefNumber] = useState("");
    const [errorHeader, setErrorHeader] = useState("Information Doesn't Match");
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [canContinue, setCanContinue] = useState(false);
    const [errorAction, setErrorAction] = useState(() => () => setShowErrorModal(false));
    const [errorButtonText, setErrorButtonText] = useState(data.securityType === "Enrollment" ? "Close" : "Continue");
    const constants = getConstants(data.securityType);
    const { ForgotUsernamePassword, SaveForgotUsernameDetails, SaveForgotPasswordDetails, ForgotPasswordAttempts } =
        useIdentityPremierContext();
    const { Get: getConsent } = useUserConsentContext();
    const { ValidateIdentity } = useEnrollmentContext();
    const { syncOneTrustConsentProfile, updateConsumerGuid, updateJwt } = useOneTrustContext();

    async function forgotUsernamePassword(
        eventType: string
    ): Promise<AxiosResponse<ForgotUsernamePasswordMFAResponse>> {
        return await ForgotUsernamePassword(ssn.replaceAll("-", ""), refNumber, eventType);
    }

    async function saveForgotUsernameDetails(): Promise<AxiosResponse<any, any>> {
        let { last4, accountId } = getLast4orId(refNumber);
        return await SaveForgotUsernameDetails(ssn.replaceAll("-", ""), last4, accountId);
    }

    async function saveForgotPasswordDetails(): Promise<AxiosResponse<any, any>> {
        let { last4, accountId } = getLast4orId(refNumber);
        return await SaveForgotPasswordDetails(ssn.replaceAll("-", ""), last4, accountId);
    }

    async function forgotPasswordAttempts(): Promise<AxiosResponse<ForgotPasswordAttemptsResponse, any>> {
        let { last4, accountId } = getLast4orId(refNumber);
        return await ForgotPasswordAttempts(ssn.replaceAll("-", ""), last4, accountId);
    }

    async function userConsentGet(
        onlineUserId: number,
        applicantHandle: string
    ): Promise<AxiosResponse<UserConsentResponse, any>> {
        return await getConsent(onlineUserId, applicantHandle);
    }

    async function validateIdentity(sessionId: string): Promise<AxiosResponse<any, any>> {
        let request = { Ssn: ssn.replaceAll("-", ""), VerificationNumber: refNumber, SessionId: sessionId };
        return await ValidateIdentity(request);
    }

    return {
        hooks: {
            navigate,
            storeValue,
            syncOneTrustConsentProfile,
            updateConsumerGuid,
            updateJwt
        },
        state: {
            size,
            securityType,
            setSecurityType,
            setMfaOptions,
            searchParams,
            ssn,
            setSSN,
            refNumber,
            setRefNumber,
            errorHeader,
            setErrorHeader,
            errorMessage,
            setErrorMessage,
            showErrorModal,
            setShowErrorModal,
            canContinue,
            setCanContinue,
            errorAction,
            setErrorAction,
            errorButtonText,
            setErrorButtonText,
            ...constants
        },
        api: {
            forgotUsernamePassword,
            saveForgotUsernameDetails,
            saveForgotPasswordDetails,
            forgotPasswordAttempts,
            userConsentGet,
            validateIdentity
        }
    };
}

function getConstants(type: SecurityType | undefined): {
    subtitle: string;
    continueText: string;
} {
    let subtitle = "";
    let continueText = "Get Username";

    if (type === "ForgotPassword") {
        subtitle = "Step 1 of 3";
        continueText = "Next";
    } else if (type === "Enrollment") {
        subtitle = "Step 1 of 4";
        continueText = "Next";
    }

    return { subtitle, continueText };
}

function getLast4orId(refNumber: string) {
    let last4 = refNumber.length === 16 ? refNumber.substring(refNumber.length - 4) : "";
    let accountId = refNumber.length < 16 ? refNumber : "";

    return { last4, accountId };
}
