import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function ManageAlerts(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 0.857147C13.9505 0.857147 15.8211 1.63197 17.2003 3.01117C18.5795 4.39036 19.3543 6.26096 19.3543 8.21143C19.3543 16.3886 22.3372 18 23.1429 18H0.857178C1.68003 18 4.64575 16.3714 4.64575 8.21143C4.64575 6.26096 5.42057 4.39036 6.79977 3.01117C8.17897 1.63197 10.0496 0.857147 12 0.857147Z"
                stroke="#5D5D5D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M9.42865 21.1371C9.57683 21.7063 9.90968 22.2103 10.375 22.57C10.8404 22.9297 11.4119 23.1248 12.0001 23.1248C12.5882 23.1248 13.1598 22.9297 13.6251 22.57C14.0905 22.2103 14.4233 21.7063 14.5715 21.1371"
                stroke="#5D5D5D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
