import { Size } from "../../../../types";
import { AlertProps } from "./alert";
import { useViewport } from "../../../../hooks";

type AlertViewModel = {
    state: AlertState;
};

export type AlertState = {
    size: Size;
    className: string;
};

export function useAlertViewModel(props: AlertProps): AlertViewModel {
    const { size } = useViewport(false, true);
    const className = `alert-${props.level}`;

    return {
        state: { size, className }
    };
}
