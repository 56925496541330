import { useContext, useState } from "react";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { useKnowledgeManagementContext } from "../../../../api/backend";
import { AppDownloadBannerContext, useOneTrustContext } from "../../../../contexts";
import { useSessionStorage, useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { EqualsIgnoreCase } from "../../../../utility";

type AppHeaderPublicViewModel = {
    hooks: AppHeaderPublicHooks;
    state: AppHeaderPublicState;
};

type AppHeaderPublicHooks = {
    navigate: NavigateFunction;
    storeValue: (name: string, value: any) => void;
    onSearch: () => void;
    clearConsumerGuid: () => void;
    clearJwt: () => void;
};

export type AppHeaderPublicState = {
    size: Size;
    szKey: false | "md";
    setSzKey: React.Dispatch<React.SetStateAction<false | "md">>;
    showOffCanvas: boolean;
    setShowOffCanvas: React.Dispatch<React.SetStateAction<boolean>>;
    showBanner: boolean;
    searchText: string;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    location: Location<any>;
    showLogin: boolean;
};

const storageKeys = ["access_token"];

export function useAppHeaderPublicViewModel(): AppHeaderPublicViewModel {
    const navigate = useNavigate();
    const { size } = useViewport(true);
    const [szKey, setSzKey] = useState("md" as "md" | false);
    const [, storeValue] = useSessionStorage(storageKeys);
    const { showBanner } = useContext(AppDownloadBannerContext);
    const [showOffCanvas, setShowOffCanvas] = useState(false);
    const verint = useKnowledgeManagementContext();
    const location = useLocation();
    const { clearConsumerGuid, clearJwt } = useOneTrustContext();
    const showLogin = !EqualsIgnoreCase(location.pathname, "/auth/login");

    return {
        hooks: {
            navigate,
            storeValue,
            onSearch: verint.onSearch,
            clearConsumerGuid,
            clearJwt
        },
        state: {
            showOffCanvas,
            setShowOffCanvas,
            size,
            szKey,
            setSzKey,
            showBanner,
            location,
            showLogin,
            ...verint
        }
    };
}
