import { ReactElement, useEffect } from "react";
import { HelpView } from "./helpView";
import { useHelpViewModel } from "./helpViewModel";

export function Help(): ReactElement {
    const { state, api } = useHelpViewModel();

    useEffect(() => {
        if (state.selectedArticle) {
            window.scrollTo(0, 0);
            window.document.getElementById("publicView-outter")?.scrollTo(0, 0);
            window.document.getElementById("privateView-outter")?.scrollTo(0, 0);
        }
    }, [state.selectedArticle]);

    useEffect(() => {
        api.onSearch();
    }, []);

    function onSearch() {
        api.onSearch();
    }

    function onSelected(id: string) {
        let selected = state.articles?.searchResults.find((x) => x.id === id);

        if (selected) {
            state.setLoading(true);

            api.Content(selected?.url!)
                .then((x) => {
                    state.setSelectedArticle(x.data);
                })
                .finally(() => {
                    state.setLoading(false);
                });
        }
    }

    function onBackToResults() {
        state.setSelectedArticle(undefined);
    }

    function onPageChange(newPage: number) {
        state.setCurPage(newPage);
        state.setCurArticles(state.articles?.searchResults.slice(10 * (newPage - 1), 10 * (newPage - 1) + 10));
    }

    return (
        <HelpView
            {...state}
            onSearch={onSearch}
            onSelected={onSelected}
            onPageChange={onPageChange}
            onBackToResults={onBackToResults}
        />
    );
}
