import { ReactElement } from "react";
import { ButtonPro } from "../../../generic/inputs/buttonPro/buttonPro";
import { BrandedCard } from "../../../generic";
import { MultAcctOfferProps } from "./multAcctOfferModel";
import "./multAcctOffer.scss";

export type MultAcctOfferViewProps = MultAcctOfferProps & {
    learnMore: Function;
    noThanks: Function;
};

export function MultAcctOfferView(props: MultAcctOfferViewProps): ReactElement {
    return (
        <BrandedCard id="multAcctOffer" size={""} header="Congratulations!" subtitle={""}>
            <div className="multAcctOffer-form">
                <div id="multAcctOffer-contentPrompt">
                    <p>
                        <span className="genericModal-bodyText" dangerouslySetInnerHTML={{ __html: props.bodyText }} />
                    </p>
                </div>

                <div className="brandedCard-buttons">
                    <ButtonPro id="multAcctOffer-close" variant="primary" onClick={() => props.learnMore()}>
                        Learn More
                    </ButtonPro>
                </div>
                <div className="brandedCard-buttons">
                    <ButtonPro id="multAcctOffer-close" variant="secondary" onClick={() => props.noThanks()}>
                        No, Thank You
                    </ButtonPro>
                </div>
            </div>
        </BrandedCard>
    );
}
