import { AxiosResponse } from "axios";
import { ReactElement, useState } from "react";
import { NavigateFunction, useLoaderData, useNavigate } from "react-router-dom";
import {
    DocumentDetails,
    LettersCms,
    UserAccount,
    useAccountContext,
    useDocumentsContext
} from "../../../../api/backend";
import { DocumentsCMSData } from "../summary/documentsCMSLoader";

export type DocumentsViewModel = {
    context: DocumentsContext;
    state: DocumentsState;
    api: DocumentsApi;
    hooks: DocumentsHooks;
};

type DocumentsContext = {
    curIndex: number | undefined;
    setCurIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export type DocumentsState = {
    cms: LettersCms;
    history: DocumentDetails[];
    setHistory: React.Dispatch<React.SetStateAction<DocumentDetails[]>>;
    displayedHistory: DocumentDetails[];
    setDisplayedHistory: React.Dispatch<React.SetStateAction<DocumentDetails[]>>;
    rows: ReactElement[];
    setRows: React.Dispatch<React.SetStateAction<ReactElement[]>>;
    seeMore: boolean;
    setSeeMore: React.Dispatch<React.SetStateAction<boolean>>;
    rowViewed: number;
    setRowViewed: React.Dispatch<React.SetStateAction<number>>;
    accounts: UserAccount[] | undefined;
    accountIndex: number;
};

export type DocumentsApi = {
    documentsGet: () => Promise<AxiosResponse<DocumentDetails[], any>>;
};

type DocumentsHooks = {
    navigate: NavigateFunction;
};

export function useDocumentsViewModel(): DocumentsViewModel {
    const { Get } = useDocumentsContext();
    const [history, setHistory] = useState([] as DocumentDetails[]);
    const [displayedHistory, setDisplayedHistory] = useState([] as DocumentDetails[]);
    const [rows, setRows] = useState([] as ReactElement[]);
    const [seeMore, setSeeMore] = useState(false);
    const [curIndex, setCurIndex] = useState(undefined as undefined | number);
    const data = useLoaderData() as DocumentsCMSData;
    const [rowViewed, setRowViewed] = useState(0);
    const { accounts, accountIndex } = useAccountContext();
    const navigate = useNavigate();

    function documentsGet(): Promise<AxiosResponse<DocumentDetails[], any>> {
        return Get(accounts[accountIndex].accountHandle);
    }

    return {
        context: {
            curIndex,
            setCurIndex
        },
        state: {
            ...data,
            history,
            setHistory,
            displayedHistory,
            setDisplayedHistory,
            rows,
            setRows,
            seeMore,
            setSeeMore,
            rowViewed,
            setRowViewed,
            accountIndex,
            accounts
        },
        api: {
            documentsGet
        },
        hooks: {
            navigate
        }
    };
}
