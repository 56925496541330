import { Form, Formik } from "formik";
import { ReactElement } from "react";
import { InputField, Loading } from "../..";
import { ButtonPro, GenericModal, ToolTip } from "../../generic";
import { InputPro } from "../../generic/inputs/inputPro/inputPro";
import { LoginCard } from "../common/loginCardModel";
import "./forgotLogin.scss";
import { ForgotLoginState } from "./forgotLoginViewModel";

type ForgotLoginViewProps = ForgotLoginState & {
    onSubmit: Function;
    onCancel: Function;
};

export type ForgotPasswordFields = {
    ssn: string;
    refNumber: string;
};

export type ForgotPasswordErrors = {
    ssn?: string;
    refNumber?: string;
};

export function ForgotLoginView(props: ForgotLoginViewProps): ReactElement {
    return (
        <LoginCard id="forgotPassword" size={props.size} header="Enter Your Information" subtitle={props.subtitle}>
            <Formik
                initialValues={{ ssn: "", refNumber: "" }}
                onSubmit={(values, actions) => {
                    props.onSubmit(actions);
                }}
            >
                {({
                    values,
                    errors,
                    isSubmitting
                }: {
                    values: ForgotPasswordFields;
                    errors: ForgotPasswordErrors;
                    isSubmitting: boolean;
                }) => (
                    <Form className={`loginCard-form${props.size}`} autoComplete="off">
                        <InputField
                            id="ssn"
                            type="text"
                            label="Social Security Number"
                            placeholder="xxx-xx-xxxx"
                            mask={"*99-99-9999"}
                            stateValue={{ get: props.ssn, set: props.setSSN, isPrivate: true, canPeek: true }}
                            inputMode="text"
                        />

                        <InputPro
                            id="refNumber"
                            label="Credit Card or Reference Number"
                            placeholder="Enter credit card or reference number"
                            mask={/^[0-9]{0,16}$/im}
                            stateValue={{
                                get: props.refNumber,
                                set: props.setRefNumber,
                                isPrivate: true,
                                canPeek: true
                            }}
                            inputMode="numeric"
                        >
                            <ToolTip>
                                Use the 16-digit number on the front of your card or the Reference Number you received
                                when signing up for your account
                            </ToolTip>
                        </InputPro>

                        <h6 className="forgotPassword-footnote">All fields are required</h6>

                        <div className="loginCard-buttons">
                            <ButtonPro
                                id={`forgotPassword-cancel${props.size}`}
                                type="button"
                                variant="neutral"
                                onClick={() => props.onCancel()}
                            >
                                Cancel
                            </ButtonPro>
                            <ButtonPro
                                id={`forgotPassword-submit${props.size}`}
                                type="submit"
                                disabled={!props.canContinue}
                                isSubmitting={isSubmitting}
                            >
                                {props.size === "-mobile" ? "Next" : props.continueText}
                            </ButtonPro>
                        </div>

                        <GenericModal
                            show={props.showErrorModal}
                            headerText={props.errorHeader}
                            bodyText={props.errorMessage}
                            primaryButton={{
                                text: props.errorButtonText,
                                action: () => props.errorAction()
                            }}
                        />

                        <Loading show={isSubmitting} />
                    </Form>
                )}
            </Formik>
        </LoginCard>
    );
}
