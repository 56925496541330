import dayjs, { Dayjs } from "dayjs";
import { FormatMoney } from "./money";

export type DownloadDetails = {
    TransactionDate: string;
    ReferenceNumber: string;
    TransactionAmount: number;
    MerchantDescription: string;
};

export function OFXExporter(data: DownloadDetails[], accountId: string, balance: number, date: Dayjs): string {
    return GetHeader(accountId) + GetBody(data) + GetFooter(data, balance, date);
}

function GetHeader(accountId: string): string {
    let header =
        "OFXHEADER:100\n" +
        "DATA:OFXSGML\n" +
        "VERSION:102\n" +
        "SECURITY:NONE\n" +
        "ENCODING:USASCII\n" +
        "CHARSET:1252\n" +
        "COMPRESSION:NONE\n" +
        "OLDFILEUID:NONE\n" +
        "NEWFILEUID:NONE\n" +
        "<OFX>\n" +
        "<SIGNONMSGSRSV1>\n" +
        "<SONRS>\n" +
        "<STATUS>\n" +
        "<CODE>0</CODE>\n" +
        "<SEVERITY>INFO</SEVERITY>\n" +
        "</STATUS>\n" +
        "<DTSERVER>[[CURRENTDATE]]050000[-5:EST]</DTSERVER>\n" +
        "<LANGUAGE>ENG</LANGUAGE>\n" +
        "</SONRS>\n" +
        "</SIGNONMSGSRSV1>\n" +
        "<CREDITCARDMSGSRSV1>\n" +
        "<CCSTMTTRNRS>\n" +
        "<TRNUID>1</TRNUID>\n" +
        "<STATUS>\n" +
        "<CODE>0</CODE>\n" +
        "<SEVERITY>INFO</SEVERITY>\n" +
        "</STATUS>\n" +
        "<CCSTMTRS>\n" +
        "<CURDEF>USD</CURDEF>\n" +
        "<CCACCTFROM>\n" +
        "<ACCTID>[[ACCOUNTNUMBER]]</ACCTID>\n" +
        "</CCACCTFROM>\n" +
        "<BANKTRANLIST>\n";

    header = header.replaceAll("[[CURRENTDATE]]", dayjs().format("YYYYMMDD"));
    header = header.replaceAll("[[ACCOUNTNUMBER]]", accountId);

    return header;
}

function GetBody(data: DownloadDetails[]): string {
    let body = "";

    let lines = data.map((d, i) => {
        let line = GetTransactionTemplate();

        if (d.TransactionAmount > 0) {
            line = line.replaceAll("[[TRANSACTIONTYPE]]", "DEBIT");
        } else {
            line = line.replaceAll("[[TRANSACTIONTYPE]]", "CREDIT");
        }

        line = line.replaceAll("[[DATEPOSTED]]", dayjs(d.TransactionDate).format("MMDDYY"));
        line = line.replaceAll("[[TRANSACTIONAMOUNT]]", FormatMoney(d.TransactionAmount, false, true).formatted);
        //Legacy - This Line was always 0 - might have been a bug?
        line = line.replaceAll("[[ROWKOUNT]]", i.toString());

        line = line.replaceAll("[[DESCRIPTION]]", d.MerchantDescription);

        return line;
    });

    lines.forEach((x) => {
        body = body.concat(x);
    });

    return body;
}

function GetFooter(data: DownloadDetails[], balance: number, date: Dayjs): string {
    let b = 0;
    data.forEach((d) => (b = b + d.TransactionAmount));

    let footer =
        "</BANKTRANLIST>\n" +
        "<LEDGERBAL>\n" +
        "<BALAMT>[[BALANCEAMOUNT]]</BALAMT>\n" +
        "<DTASOF>[[ASOFDATE]][-5:EST]</DTASOF>\n" +
        "</LEDGERBAL>\n" +
        "</CCSTMTRS>\n" +
        "</CCSTMTTRNRS>\n" +
        "</CREDITCARDMSGSRSV1>\n" +
        "</OFX>\n";

    //Balance is not the Same as Legacy - What do we need to do here
    footer = footer.replaceAll("[[BALANCEAMOUNT]]", FormatMoney(b, true, true).formatted);
    footer = footer.replaceAll("[[ASOFDATE]]", date.format("M/D/YYYY"));

    return footer;
}

function GetTransactionTemplate(): string {
    return (
        "<STMTTRN>\n" +
        "<TRNTYPE>[[TRANSACTIONTYPE]]</TRNTYPE>\n" +
        "<DTPOSTED>[[DATEPOSTED]][-5:EST]</DTPOSTED>\n" +
        "<TRNAMT>[[TRANSACTIONAMOUNT]]</TRNAMT>\n" +
        "<FITID>[[DATEPOSTED]][[ROWKOUNT]]</FITID>\n" +
        "<NAME>[[DESCRIPTION]]</NAME>\n" +
        "</STMTTRN>\n"
    );
}
