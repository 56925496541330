import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function CreditCard(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="36"
            height="27"
            viewBox="0 0 36 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.125 4.41895C1.125 2.7621 2.46815 1.41895 4.125 1.41895H31.875C33.5319 1.41895 34.875 2.76209 34.875 4.41895V22.261C34.875 23.9178 33.5319 25.261 31.875 25.261H4.125C2.46815 25.261 1.125 23.9178 1.125 22.261V4.41895Z"
                strokeWidth="2.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M1.125 7.92139H34.875" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.875 14.4238H20.25" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.875 18.7583H15.75" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
