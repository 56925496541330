import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
    AppSettings,
    ArticleContent,
    Articles,
    SearchResults,
    useKnowledgeManagementContext,
    useSettingsContext
} from "../../../api/backend";
import { CardNavContext, ViewRestrictionContext } from "../../../contexts";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { AxiosResponse } from "axios";

type HelpViewModel = {
    state: HelpState;
    api: HelpApi;
};

export type HelpState = {
    size: Size;
    appSettings: AppSettings;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    searchText: string;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    curSearch: string;
    setCurSearch: React.Dispatch<React.SetStateAction<string>>;
    articles: Articles | undefined;
    setArticles: React.Dispatch<React.SetStateAction<Articles | undefined>>;
    curArticles: SearchResults[] | undefined;
    setCurArticles: React.Dispatch<React.SetStateAction<SearchResults[] | undefined>>;
    pages: number;
    setPages: React.Dispatch<React.SetStateAction<number>>;
    curPage: number;
    setCurPage: React.Dispatch<React.SetStateAction<number>>;
    selectedArticle: ArticleContent | undefined;
    setSelectedArticle: React.Dispatch<React.SetStateAction<ArticleContent | undefined>>;
};

type HelpApi = {
    onSearch: () => void;
    Content(queryParams: string): Promise<AxiosResponse<ArticleContent, any>>;
};

export function useHelpViewModel(): HelpViewModel {
    const { size } = useViewport(false, true);
    const { appSettings } = useSettingsContext();
    const { setRestricted } = useContext(ViewRestrictionContext);
    const { setShowNav } = useContext(CardNavContext);
    const verint = useKnowledgeManagementContext();
    const location = useLocation();
    const [selectedArticle, setSelectedArticle] = useState<ArticleContent>();

    useEffect(() => {
        setShowNav(false);
        setRestricted(false);

        return () => {
            setRestricted(true);
        };
    }, []);

    useEffect(() => {
        return () => {
            verint.clear();
        };
    }, [location]);

    useEffect(() => {
        if (!verint.articles) {
            setSelectedArticle(undefined);
        }
    }, [verint.articles]);

    return {
        state: {
            size,
            appSettings,
            selectedArticle,
            setSelectedArticle,
            ...verint
        },
        api: {
            ...verint
        }
    };
}
