import { ReactElement } from "react";
import { ApplicationStatusCms, UserAccount } from "../../../api/backend";
import { CardApplicationView } from "./cardApplicationView";
import { useCardApplicationViewModel } from "./cardApplicationViewModel";

export type CardApplicationProps = {
    index: number;
    account: UserAccount;
    cms: ApplicationStatusCms | undefined;
    isDashboard?: boolean;
};

export function CardApplication(props: CardApplicationProps): ReactElement {
    const { hooks, state } = useCardApplicationViewModel();

    function viewApplication() {
        state.setAccountIndex(props.index);
        hooks.navigate(`/account/summary/${props.index}/home`);
    }

    return <CardApplicationView {...props} {...state} viewApplication={viewApplication} />;
}
