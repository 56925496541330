import { createContext, useState } from "react";
import { BankAccountGetResponse } from ".";
import { NotImplemented } from "../../../utility";

export type PaymentAccountContextProps = {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    paymentAccounts: BankAccountGetResponse[];
    setPaymentAccounts: React.Dispatch<React.SetStateAction<BankAccountGetResponse[]>>;
};

export const PaymentAccountContext = createContext<PaymentAccountContextProps>({
    loading: false,
    setLoading: NotImplemented,
    paymentAccounts: [],
    setPaymentAccounts: NotImplemented
});

export const usePaymentAccountContextProps = (): PaymentAccountContextProps => {
    const [loading, setLoading] = useState(false);
    const [paymentAccounts, setPaymentAccounts] = useState<BankAccountGetResponse[]>([]);

    return {
        loading,
        setLoading,
        paymentAccounts,
        setPaymentAccounts
    };
};
