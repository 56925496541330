import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
    UserAccount,
    useAccountContext,
    useDeliveryPreferencesContext,
    useDigitalActionsContext
} from "../../../../api/backend";
import { ElectronicDeliveryPreferences } from "../../../../api/backend/deliveryPreferencesClient";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { CardActionsProps } from "./cardActionsModel";

type CardActionsViewModel = {
    state: CardActionsState;
    hooks: ActivityHooks;
};

export type CardActionsState = {
    size: Size;
    accounts: UserAccount[] | undefined;
    showManageAlertsUnavailable: boolean;
    setShowManageAlertsUnavailable: React.Dispatch<React.SetStateAction<boolean>>;
    accountIndex: number;
    setAccountIndex: React.Dispatch<React.SetStateAction<number>>;
    loading: boolean;
    preferences: ElectronicDeliveryPreferences[] | undefined;
    allowLockCard: boolean;
    allowPaperless: boolean;
    allowAlerts: boolean;
};

type ActivityHooks = {
    navigate: NavigateFunction;
};

export function useCardActionsViewModel(props: CardActionsProps): CardActionsViewModel {
    const { size } = useViewport(true, true);
    const { accounts, accountIndex, setAccountIndex } = useAccountContext();
    const [showManageAlertsUnavailable, setShowManageAlertsUnavailable] = useState(false);
    const navigate = useNavigate();
    const { actionAllowed } = useDigitalActionsContext();
    const { loading, preferences } = useDeliveryPreferencesContext();
    const [allowPaperless, setAllowPaperless] = useState(true);
    const [allowLockCard, setAllowLockCard] = useState(true);
    const [allowAlerts, setAllowAlerts] = useState(true);

    useEffect(() => {
        let accountHandle = accounts![props.index].accountHandle;

        setAllowPaperless(
            actionAllowed("eLetterEnroll", accountHandle) || actionAllowed("eStatementEnroll", accountHandle)
        );
        setAllowLockCard(actionAllowed("lockUnlockCard", accountHandle));
        setAllowAlerts(actionAllowed("alerts", accountHandle));
    }, [props.index]);

    return {
        state: {
            size,
            accounts,
            showManageAlertsUnavailable,
            setShowManageAlertsUnavailable,
            accountIndex,
            setAccountIndex,
            loading,
            preferences,
            allowLockCard,
            allowPaperless,
            allowAlerts
        },
        hooks: { navigate }
    };
}
