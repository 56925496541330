import { ReactElement } from "react";
import { PdfViewer } from "../../generic";
import "./disclosures.scss";
import { DisclosuresTabState } from "./disclosuresViewModelTab";

type DisclosuresViewPdfProps = DisclosuresTabState;

export function DisclosuresViewPdf(props: DisclosuresViewPdfProps): ReactElement {
    return (
        <div id="disclosure" className={`disclosures${props.size} container background-grayscale-white`}>
            <div className="disclosures-page-container">
                <div className="disclosures-body-container">
                    <div className="disclosures-body-section">
                        <div className="disclosures-body-header-section row d-flex">
                            <PdfViewer
                                size={props.size}
                                fileName={props.fileName}
                                contentType={props.contentType}
                                pdfArrayBuffer={props.pdfData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
