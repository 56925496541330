import { Form, Formik } from "formik";
import { ReactElement } from "react";
import { Loading, StateSelectorField } from "../../../..";
import { FormProps } from "../../../../../types";
import { ButtonPro, CheckboxPro, GenericModal } from "../../../../generic";
import { CCPAOptionsModel } from "../../../ccpa/ccpaOptions/ccpaOptionsModel";
import { CCPAOptionsFormFields } from "../../../ccpa/ccpaOptions/ccpaOptionsView";
import { CCPAFormModelProps, CCPAFormState } from "../ccpaFormViewModel";
import { CCPAInput } from "../ccpaInput/ccpaInput";
import "./ccpaAnonymous.scss";
import { validationSchema } from "./ccpaAnonymousYup";

type CCPAAnonymousViewProps = CCPAFormModelProps &
    CCPAFormState & {
        onCancel: Function;
        onSubmit: Function;
    };

export type CCPAAnonymousFormFields = CCPAOptionsFormFields & {
    firstName: string;
    lastName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    phoneNumber: string;
    emailAddress: string;
    includeAgentInfo: boolean;
    agentFirstName: string;
    agentLastName: string;
    agentAddress1: string;
    agentAddress2: string;
    agentCity: string;
    agentState: string;
    agentZip: string;
    captchaValid: boolean;
};

export function CCPAAnonymousView(props: CCPAAnonymousViewProps): ReactElement {
    const initalValues = {
        categoriesSources: false,
        specificData: false,
        requestData: false,
        deleteData: false,
        deleteConfirmation: false,
        captchaValid: true
    } as CCPAAnonymousFormFields;

    return (
        <div id="ccpaAnonymous">
            <Formik
                initialValues={initalValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    props.onSubmit(values, actions);
                }}
            >
                {(formProps: FormProps<CCPAAnonymousFormFields>) => (
                    <Form className="ccpaAnonymous-form">
                        <h5>Consumer Information</h5>

                        <div className="ccpaAnonymous-fields">
                            <CCPAInput id="firstName" label="First Name*" max={50} />
                            <CCPAInput id="lastName" label="Last Name*" max={50} />
                            <CCPAInput id="address1" label="Address Line 1*" max={26} />
                            <CCPAInput id="address2" label="Address Line 2" max={26} />
                            <CCPAInput id="city" label="City*" max={18} />
                            <StateSelectorField id="state" blankOption={true} label="State*" />
                            <CCPAInput id="zip" label="Zip Code*" max={9} mask={/^[0-9]*$/im} />
                            <CCPAInput id="phoneNumber" label="Phone Number*" max={10} />
                            <CCPAInput id="emailAddress" label="Email Address*" max={50} />
                        </div>
                        <CheckboxPro id="includeAgentInfo" name="includeAgentInfo">
                            I am authorized to submit this request on behalf of another individual who is a California
                            resident.
                        </CheckboxPro>

                        {formProps.values.includeAgentInfo === true ? (
                            <>
                                <h5>Authorized Agent Information</h5>
                                <div className="ccpaAnonymous-fields">
                                    <CCPAInput id="agentFirstName" label="First Name*" max={50} />
                                    <CCPAInput id="agentLastName" label="Last Name*" max={50} />
                                    <CCPAInput id="agentAddress1" label="Address Line 1*" max={26} />
                                    <CCPAInput id="agentAddress2" label="Address Line 2" max={26} />
                                    <CCPAInput id="agentCity" label="City*" max={18} />
                                    <StateSelectorField id="agentState" blankOption={true} label="State*" />
                                    <CCPAInput id="agentZip" label="Zip Code*" max={9} mask={/^[0-9]*$/im} />
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        <CCPAOptionsModel {...props} isCorpCard={false} />

                        {formProps.values.captchaValid === false ? (
                            <>
                                <div className="validation-error">
                                    <h6>Invalid Captcha! The form cannot be submitted.</h6>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        <div className="ccpa-buttons">
                            <ButtonPro type="button" variant="secondary" onClick={() => props.onCancel()}>
                                Cancel
                            </ButtonPro>
                            <ButtonPro type="submit" disabled={submitDisabled(formProps)}>
                                Submit Request
                            </ButtonPro>
                        </div>

                        <Loading show={formProps.isSubmitting} />
                    </Form>
                )}
            </Formik>

            <GenericModal show={props.showCancel} {...props.cancelProps} />
            <GenericModal show={props.showSuccess} {...props.successProps} />
        </div>
    );
}

function submitDisabled(formProps: FormProps<CCPAAnonymousFormFields>): boolean {
    return (
        (formProps.values.deleteData === true && formProps.values.deleteConfirmation !== true) ||
        (formProps.values.categoriesSources !== true &&
            formProps.values.specificData !== true &&
            formProps.values.requestData !== true &&
            formProps.values.deleteData !== true) ||
        !formProps.isValid ||
        !formProps.values.captchaValid
    );
}
