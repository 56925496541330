import { AxiosResponse } from "axios";
import { Profile, ProfileUpdateAddress, ProfileUpdateEmail, ProfileUpdatePhone } from ".";
import { useApiQueueContext } from "../../../contexts";
import { UsePut } from "../../../hooks";
import { useIdentityConnectContext } from "../../identityServer";

export const useProfileContext = () => {
    const client = useProfileClient();

    return { ...client };
};

const useProfileClient = () => {
    const apiQueue = useApiQueueContext();
    const { access_token } = useIdentityConnectContext();

    async function Get(accountHandle: string): Promise<AxiosResponse<Profile, any>> {
        return await apiQueue.Get(`api/v2/profile?accountHandle=${accountHandle}`, access_token);
    }

    async function PutAddress(accountHandle: string, request: ProfileUpdateAddress): Promise<AxiosResponse<any, any>> {
        return await UsePut(`api/v1/profile/address?accountHandle=${accountHandle}`, access_token, request);
    }

    async function PutPhone(accountHandle: string, request: ProfileUpdatePhone): Promise<AxiosResponse<any, any>> {
        return await UsePut(`api/v1/profile/phone?accountHandle=${accountHandle}`, access_token, request);
    }

    async function PutEmail(accountHandle: string, request: ProfileUpdateEmail): Promise<AxiosResponse<any, any>> {
        return await UsePut(`api/v1/profile/email?accountHandle=${accountHandle}`, access_token, request);
    }

    async function PutLogout(): Promise<AxiosResponse<any, any>> {
        return await UsePut("api/v1/profile/logout?platform=Web", access_token);
    }

    return { Get, PutAddress, PutPhone, PutEmail, PutLogout };
};
