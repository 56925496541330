import { ReactElement, useEffect } from "react";
import AppHeaderPublicView from "./appHeaderPublicView";
import { useAppHeaderPublicViewModel } from "./appHeaderPublicViewModel";

export function AppHeaderPublicModel(): ReactElement {
    const { hooks, state } = useAppHeaderPublicViewModel();

    useEffect(() => {
        if (state.size === "") {
            state.setSzKey("md");

            if (state.showOffCanvas) {
                state.setShowOffCanvas(false);
            }
        } else state.setSzKey(false);
    }, [state.size]);

    function handleClose() {
        state.setShowOffCanvas(false);
    }

    function toggleOffCanvas() {
        state.setShowOffCanvas(!state.showOffCanvas);
    }

    function onSearch() {
        handleClose();

        if (state.location.pathname !== "/help") hooks.navigate("/help");

        hooks.onSearch();
    }

    function onLogin() {
        handleClose();
        hooks.clearConsumerGuid();
        hooks.clearJwt();
        hooks.storeValue("access_token", "");
        hooks.navigate("/auth/login");
    }

    return (
        <AppHeaderPublicView
            {...state}
            onLogin={onLogin}
            handleClose={handleClose}
            toggleOffCanvas={toggleOffCanvas}
            onSearch={onSearch}
        />
    );
}
