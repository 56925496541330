import { useContext, useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useIdentityConnectContext } from "../../../api/identityServer";
import { CardNavContext, ViewRestrictionContext, useDeepLinkContext } from "../../../contexts";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { SetTitle } from "../../../utility";

type Error404ViewModel = {
    hooks: Error404Hooks;
    state: Error404State;
};

type Error404Hooks = {
    navigate: NavigateFunction;
    clearLink: () => void;
};

export type Error404State = {
    size: Size;
    access_token: string;
    deepLink: string;
    location: any;
};

export function useError404ViewModel(): Error404ViewModel {
    const { size } = useViewport(true, true);
    const location = useLocation();
    const navigate = useNavigate();
    const { access_token } = useIdentityConnectContext();
    const { setShowNav } = useContext(CardNavContext);
    const { deepLink, clear: clearLink } = useDeepLinkContext();
    const { setRestricted } = useContext(ViewRestrictionContext);

    useEffect(() => {
        if (location.pathname?.toLowerCase() === deepLink.toLowerCase()) {
            clearLink();
        }

        SetTitle("Page Not Found");
        setRestricted(false);

        if (!!access_token) {
            setShowNav(false);
        }

        return () => {
            SetTitle("");
            setRestricted(true);
        };
    }, []);

    return {
        hooks: {
            navigate,
            clearLink
        },
        state: {
            size,
            deepLink,
            access_token: "",
            location
        }
    };
}
