import { ReactElement } from "react";
import { Icon, getColor } from "./icon";
import { IconProps } from "./iconProps";

export function Youtube(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const backfill = getColor("background");

    const svg = (
        <svg id={id} className={svgClass} width="42" height="28" viewBox="0 0 42 28" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.8848 4.4546C39.4216 2.74151 38.0852 1.40006 36.3721 0.941875C34.071 0.0535115 11.783 -0.381761 5.31756 0.96733C3.60447 1.4306 2.26301 2.76697 1.80483 4.48006C0.766285 9.03642 0.687376 18.8873 1.83029 23.5455C2.29356 25.2586 3.62992 26.6001 5.34301 27.0582C9.89938 28.107 31.5612 28.2546 36.3976 27.0582C38.1106 26.595 39.4521 25.2586 39.9103 23.5455C41.0176 18.5819 41.0965 9.34188 39.8848 4.4546Z" />
            <path d="M27.4121 14.0003L17.0266 8.04395V19.9567L27.4121 14.0003Z" fill={backfill} />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
