import { ReactElement } from "react";
import "./sessionTimeout.scss";
import { ButtonPro } from "../../generic/inputs/buttonPro/buttonPro";
import { SessionTimeoutState } from "./sessionTimeoutViewModel";
import { FormatSeconds } from "../../../utility";

type SessionTimeoutViewProps = SessionTimeoutState & {
    extendSession: Function;
    logOut: Function;
};

export function SessionTimeoutView(props: SessionTimeoutViewProps): ReactElement {
    return (
        <div className="sessiontimeout-modal">
            <div className="sessiontimeout-header">Session Timeout</div>
            <div className="sessiontimeout-body">
                <p>For your security, your session will expire in:</p>
                <div className="sessiontimeout-timer">{FormatSeconds(props.ticker)}</div>
                <p>
                    If you want to extend your session, please select “Extend My Session” below. If you select “Log Out”
                    or do not respond, your session will automatically expire.
                </p>
            </div>
            <div className={`sessiontimeout-button${props.size}`}>
                <ButtonPro variant="neutral" onClick={() => props.logOut()}>
                    Log Out
                </ButtonPro>
                <ButtonPro onClick={() => props.extendSession()}>Extend My Session</ButtonPro>
            </div>
        </div>
    );
}
