import { useEffect, useState } from "react";
import { useAccountContext } from "../api/backend";
import { useIdentityConnectContext } from "../api/identityServer";
import { useMfaContext } from "../contexts";
import { IsNullOrEmpty } from "../utility";

export const useIsLoggedIn = () => {
    const { access_token } = useIdentityConnectContext();
    const { securityType } = useMfaContext();
    const { accounts } = useAccountContext();
    const [loggedIn, setLoggedIn] = useState(getLoggedIn());

    useEffect(() => {
        setLoggedIn(getLoggedIn());
    }, [access_token, securityType, accounts]);

    function getLoggedIn(): boolean {
        let valid = !IsNullOrEmpty(access_token) && securityType === "Login" && (accounts?.length ?? 0) > 0;
        return valid;
    }

    return { loggedIn };
};
