import { ReactElement } from "react";
import "./activitySummarySplash.scss";
import { ActivitySummarySplashState } from "./activitySummarySplashViewModel";

export function ActivitySummarySplashView(props: ActivitySummarySplashState): ReactElement {
    return (
        <div id={`activitySummarySplash${props.size}`}>
            <h2 className="activitySummarySplash-content">
                Your&nbsp;
                <span className={`activitySummarySplash-indexed-${props.account!.accent}`}>Activity & Statements</span>
            </h2>
        </div>
    );
}
