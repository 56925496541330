import { ReactElement } from "react";
import { AsyncElement, ButtonPro } from "../../generic";
import { ArrowDown, ArrowUp } from "../../generic/icons";
import "./paymentHistory.scss";
import { PaymentHistoryDateFilter } from "./paymentHistoryDateFilter/paymentHistoryDateFilter";
import { PaymentHistoryState } from "./paymentHistoryViewModel";

function PaymentHistoryView(props: PaymentHistoryState): ReactElement {
    return (
        <div id="paymentHistory">
            <div className="paymentHistory-header">
                <h5>Payment History</h5>
                <PaymentHistoryDateFilter {...props} />
            </div>
            <AsyncElement loading={props.loading}>
                <div className="paymentHistory-list" data-testid="paymentHistory-list">
                    {props.rows.length > 0 ? (
                        props.rows
                    ) : (
                        <div className="paymentHistory-noList">
                            <h5>No payments to display</h5>
                        </div>
                    )}
                </div>
            </AsyncElement>
            <div className="paymentHistory-footer" data-testid={"paymentHistoryRow-View-More-Than-Three"}>
                {props.filteredHistory.length > 3 ? (
                    <ButtonPro
                        variant="neutral"
                        className="paymentHistory-footer-more"
                        unblock
                        onClick={() => props.setSeeMore(!props.seeMore)}
                    >
                        <h6>View {!props.seeMore ? "More" : "Less"}</h6>
                        {!props.seeMore ? <ArrowDown /> : <ArrowUp />}
                    </ButtonPro>
                ) : (
                    <p></p>
                )}
                <p>
                    {props.filteredHistory.length} result{props.filteredHistory.length !== 1 ? "s" : ""}
                </p>
            </div>
        </div>
    );
}

export default PaymentHistoryView;
