import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Unlock(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.75 9.75V6C0.75 4.60761 1.30312 3.27226 2.28769 2.28769C3.27226 1.30312 4.60761 0.75 6 0.75C7.39239 0.75 8.72774 1.30312 9.71231 2.28769C10.6969 3.27226 11.25 4.60761 11.25 6V9.75"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.75 9.75H8.25C7.42157 9.75 6.75 10.4216 6.75 11.25V21.75C6.75 22.5784 7.42157 23.25 8.25 23.25H21.75C22.5784 23.25 23.25 22.5784 23.25 21.75V11.25C23.25 10.4216 22.5784 9.75 21.75 9.75Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 15.75C14.9258 15.75 14.8533 15.772 14.7917 15.8132C14.73 15.8544 14.6819 15.913 14.6535 15.9815C14.6252 16.05 14.6177 16.1254 14.6322 16.1982C14.6467 16.2709 14.6824 16.3377 14.7348 16.3902C14.7873 16.4426 14.8541 16.4783 14.9268 16.4928C14.9996 16.5073 15.075 16.4998 15.1435 16.4715C15.212 16.4431 15.2706 16.395 15.3118 16.3333C15.353 16.2717 15.375 16.1992 15.375 16.125C15.3751 16.0757 15.3655 16.0269 15.3467 15.9813C15.3279 15.9358 15.3003 15.8944 15.2655 15.8595C15.2306 15.8247 15.1892 15.7971 15.1437 15.7783C15.0981 15.7595 15.0493 15.7499 15 15.75V15.75Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
