import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Percent(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M13.5 34.5L34.5 13.5" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M16 19.5C17.933 19.5 19.5 17.933 19.5 16C19.5 14.067 17.933 12.5 16 12.5C14.067 12.5 12.5 14.067 12.5 16C12.5 17.933 14.067 19.5 16 19.5Z"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32 35.5C33.933 35.5 35.5 33.933 35.5 32C35.5 30.067 33.933 28.5 32 28.5C30.067 28.5 28.5 30.067 28.5 32C28.5 33.933 30.067 35.5 32 35.5Z"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.5 7.5V4.5C1.5 3.70435 1.81607 2.94129 2.37868 2.37868C2.94129 1.81607 3.70435 1.5 4.5 1.5H7.5"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.5 46.5H4.5C3.70435 46.5 2.94129 46.1839 2.37868 45.6213C1.81607 45.0587 1.5 44.2956 1.5 43.5V40.5"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M1.5 15V21" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.5 27V33" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M46.5 15V21" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 1.5H21" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M46.5 40.5V43.5C46.5 44.2956 46.1839 45.0587 45.6213 45.6213C45.0587 46.1839 44.2956 46.5 43.5 46.5H40.5"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M46.5 33V27" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M33 46.5H27" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M27 1.5H33" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M40.5 1.5H43.5C44.2956 1.5 45.0587 1.81607 45.6213 2.37868C46.1839 2.94129 46.5 3.70435 46.5 4.5V7.5"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M21 46.5H15" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
