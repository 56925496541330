import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { UserAccount } from "../../../../api/backend";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { CardApplicationProps } from "../../cardApplication/cardApplication";

type CardAppStatusViewModel = {
    hooks: CardAppStatusHooks;
    state: CardAppStatusState;
};

export type CardAppStatusHooks = {
    navigate: NavigateFunction;
};

export type CardAppStatusState = {
    size: Size;
    statusText: string;
    showButton: boolean;
};

export function useCardAppStatusViewModel(props: CardApplicationProps): CardAppStatusViewModel {
    const navigate = useNavigate();
    const { size } = useViewport(true);
    const [statusText, setStatusText] = useState("");
    const [showButton, setShowButton] = useState(true);

    useEffect(() => {
        setShowButton(props.account.applicationStatus === "Pending");
    }, []);

    useEffect(() => {
        setState();
    }, []);

    useEffect(() => {
        setState();
    }, [props.cms]);

    const setState = () => {
        if (props.cms) {
            setStatusText(getAppStatusText(props).body);
        }
    };

    return {
        hooks: {
            navigate
        },
        state: {
            size,
            statusText,
            showButton
        }
    };
}

function ReplaceVariables(str: string, account: UserAccount): string {
    return str
        .replaceAll("[[SCHEDULEDWITHDRAWDATE]]", dayjs(account.scheduledWithdrawDate).format("MM/DD/YYYY"))
        .replaceAll("[[BALANCETYPE]]", account.balanceType!);
}

type AppStatusContent = {
    header: string;
    body: string;
};

export function getAppStatusText(props: CardApplicationProps): AppStatusContent {
    let header = "";
    let body = "";

    if (props.cms) {
        if (props.account.applicationStatus === "Withdrawn") {
            body = props.cms.WithdrawnHeaderText;
        } else if (props.account.applicationStatus === "Denied") {
            body = props.cms.DeniedHeaderText;
        } else if (props.account.applicationStatus === "InactiveState") {
            body = props.cms.InactiveStateText;
        } else if (props.account.applicationStatus === "Approved") {
            body = props.cms.InstActvText;
        } else if (dayjs(props.account.scheduledWithdrawDate).year() >= dayjs().year()) {
            if (props.account.applicationBalance > 0) {
                if (props.account.balanceType === "Program Fee") {
                    if (props.account.applicationStatus === "Pending") {
                        header = props.cms.PendingHeaderText;
                        body = props.cms.PfFooterText;
                    } else if (props.account.applicationStatus === "Passed") {
                        header = props.cms.ApprovedHeaderText;
                        body = props.cms.PfFooterText;
                    } else if (props.account.applicationStatus === "Offline") {
                        header = props.cms.OpenHeaderText;
                        body = props.cms.PfFooterText;
                    }
                }

                if (props.account.balanceType === "Security Deposit") {
                    if (props.account.applicationStatus === "Pending") {
                        header = props.cms.PendingHeaderText;
                        body = props.cms.SdFooterText;
                    } else if (props.account.applicationStatus === "Passed") {
                        header = props.cms.ApprovedHeaderText;
                        body = props.cms.SdFooterText;
                    } else if (props.account.applicationStatus === "Offline") {
                        header = props.cms.OpenHeaderText;
                        body = props.cms.SdFooterText;
                    }
                }
            } else {
                // Balance === 0.00
                if (props.account.applicationStatus === "Pending") {
                    header = props.cms.PendingHeaderText;
                    body = props.cms.NonPfSdFooterText;
                } else if (props.account.applicationStatus === "Passed") {
                    header = props.cms.ApprovedHeaderText;
                    body = props.cms.NonPfSdFooterText;
                } else if (props.account.applicationStatus === "Offline") {
                    body = props.cms.OpenZeroBalanceHeaderText;
                }
            }
        } else {
            //Invalid Withdraw Date
        }
    } else {
        //NO Cms
    }

    header = ReplaceVariables(header, props.account);
    body = ReplaceVariables(body, props.account);

    return { header, body };
}
