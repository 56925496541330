import { ReactElement } from "react";
import { ToolTip } from "../../../generic";
import "../../cardSummary/cardSummaryCommon.scss";
import { CardBalanceProps } from "./cardBalance";
import "./cardBalance.scss";
import { CardBalanceState } from "./cardBalanceViewModel";

export type CardBalanceViewProps = CardBalanceProps & CardBalanceState;

export function CardBalanceView(props: CardBalanceViewProps): ReactElement {
    const dollarCN = `cardBalance-dollar-${props.account.accent}`;
    const centsCN = `cardBalance-cents-${props.account.accent}`;

    return (
        <>
            <div className={`cardSummaryCommon${props.size} cardTilePadding`}>
                <div className="cardSummaryCommon-card">
                    <div className="cardSummaryCommon-header-row">
                        <h2 className="cardSummaryCommon-header">Current Balance</h2>
                        <ToolTip>
                            Access to your available credit may be impacted by the status of another PREMIER Bankcard
                            credit card.
                        </ToolTip>
                    </div>
                    <div className="cardSummaryCommon-content-col">
                        <div className="cardSummaryCommon-content-row">
                            <h4 className={dollarCN}>${props.dollars}</h4>
                            <h5 className={centsCN}>{props.cents}</h5>
                        </div>
                        <label className="cardSummaryCommon-available" data-testid="BalanceYouHave">
                            You have&nbsp;
                            <span className="cardSummaryCommon-available-bold" data-testid="BalanceYouHaveBold">
                                {props.available} of {props.limit}
                            </span>
                            &nbsp;available
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
}
