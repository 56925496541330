import { ReactElement } from "react";
import { IsNullOrEmpty } from "../../../../utility";
import { useCardNavViewModel } from "../cardNavViewModel";
import { CardNavMainView } from "./cardNavMainView";

export function CardNavMain(): ReactElement {
    const { hooks, state } = useCardNavViewModel();

    function onNavSelect(e: string | null) {
        state.setNavKey(e?.toString() ?? "#home");
    }

    function onDropdownClick(i: number) {
        state.setAccountIndex(i);

        let newLocation = state.location.pathname
            .split("/")
            .map((s) => {
                if (!IsNullOrEmpty(s) && !isNaN(Number(s))) {
                    return i;
                }
                return s;
            })
            .join("/");

        hooks.navigate(newLocation, { relative: "path" });
    }

    return <CardNavMainView {...state} onNavSelect={onNavSelect} onDropdownClick={onDropdownClick} />;
}
