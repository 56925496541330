import { AxiosResponse } from "axios";
import { ReactElement, useMemo, useState } from "react";
import {
    StatementDetails,
    useAccountContext,
    UserAccount,
    useSettingsContext,
    useStatementsContext
} from "../../../api/backend";
import { StatementsRow } from "./statementsRow/statementsRow";

export type StatementsState = {
    account: UserAccount;
    accountIndex: number;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setStatements: React.Dispatch<React.SetStateAction<StatementDetails[]>>;
    isEMessengerDown: boolean;
    rows: ReactElement[];
    noRowsMessage: string;
};

export type StatementsApi = {
    Get: (accountHandle: string) => Promise<AxiosResponse<StatementDetails[], any>>;
};

const useStatementsState = (): StatementsState => {
    const { appSettings } = useSettingsContext();
    const [loading, setLoading] = useState(false);
    const [statements, setStatements] = useState([] as StatementDetails[]);
    const { accounts, accountIndex } = useAccountContext();
    const isEMessengerDown = appSettings?.featureFlags?.isEMessengerDown ?? true;
    const account = accounts[accountIndex];

    const noRowsMessage = !isEMessengerDown
        ? "No statements available"
        : "Viewing statements is currently unavailable due to site maintenance, which may take up to 7 hours. Please check back later.";

    const rows = useMemo(() => {
        return statements.map((v, i) => (
            <StatementsRow key={`history[i].statementId.toString()${i}`} index={i} statement={v} />
        ));
    }, [statements]);

    return {
        account,
        accountIndex,
        loading,
        setLoading,
        setStatements,
        isEMessengerDown,
        rows,
        noRowsMessage
    };
};

const useStatementsApi = (): StatementsApi => {
    const { Get } = useStatementsContext();

    return {
        Get
    };
};

export function useStatementsViewModel() {
    const state = useStatementsState();
    const api = useStatementsApi();

    return {
        state,
        api
    };
}
