import { ReactElement } from "react";
import { EnrollTermsSplashState } from "./enrollTermsSplashViewModel";
import "./enrollTermsSplash.scss";

type EnrollTermsSplashViewProps = EnrollTermsSplashState;

export function EnrollTermsSplashView(props: EnrollTermsSplashViewProps): ReactElement {
    return (
        <div id="enrollTermsSplash">
            <div id={`enrollTermsSplash-page${props.size}`}>
                <div id="enrollTermsSplash-content">
                    <div id={`enrollTermsSplash-header${props.size}`}>
                        <div id={`enrollTermsSplash-headerText${props.size}`}>Setting Up Your Account</div>
                    </div>
                    <div id="enrollTermsSplash-body">
                        <h3>In some cases, this can take up to 60 seconds.</h3>
                        <h3 id="enrollTermsSplash-bodyHighlight">Please do not refresh or close this page.</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}
