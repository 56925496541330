import { ReactElement } from "react";
import { ShowUsernameView } from "./showUsernameView";
import { useShowUsernameViewModel } from "./showUsernameViewModel";

export function ShowUsernameModel(): ReactElement {
    const { hooks, state } = useShowUsernameViewModel();

    const returnToLogin = () => {
        hooks.navigate("/auth/login");
    };

    const forgotYourPassword = () => {
        clearVals();
        hooks.navigate("/auth/request-code");
    };

    function clearVals() {
        hooks.removeValue(["username"]);
    }

    return <ShowUsernameView {...state} returnToLogin={returnToLogin} forgotYourPassword={forgotYourPassword} />;
}
