import { AxiosResponse } from "axios";
import { ReactElement, useEffect, useState } from "react";
import {
    ElectronicDeliveryPreferences,
    EnrollRequest,
    useAccountContext,
    useDeliveryPreferencesContext,
    UserAccount
} from "../../../../api/backend";
import { GenericModalProps } from "../../../generic";

type SplitDeliveryLandingViewModel = {
    hooks: SplitDeliveryLandingHooks;
    state: SplitDeliveryLandingState;
    api: SplitDeliveryLandingApi;
};

export type SplitDeliveryLandingHooks = {
    isSplit: (account: UserAccount) => boolean;
};

export type SplitDeliveryLandingState = {
    accounts: UserAccount[];
    showTerms: boolean;
    setShowTerms: React.Dispatch<React.SetStateAction<boolean>>;
    showConfirmation: boolean;
    setShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
    ignore: any;
    setIgnore: React.Dispatch<React.SetStateAction<any>>;
    preferences: ElectronicDeliveryPreferences[];
    confirmRows: ReactElement[];
    setConfirmRows: React.Dispatch<React.SetStateAction<ReactElement[]>>;
    initialValues: any;
    selectedValues: any;
    confirmationModal: GenericModalProps;
    setChoice: React.Dispatch<React.SetStateAction<boolean>>;
};

type SplitDeliveryLandingApi = {
    getPreferences: () => Promise<AxiosResponse<ElectronicDeliveryPreferences[], any>>;
    Enroll: (request: EnrollRequest) => Promise<AxiosResponse<any, any>>;
};

export function useSplitDeliveryLandingViewModel(): SplitDeliveryLandingViewModel {
    const [ignore, setIgnore] = useState<any>();
    const [showTerms, setShowTerms] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const { accounts } = useAccountContext();
    const { Get: getPreferences, Enroll, preferences, isSplit } = useDeliveryPreferencesContext();
    const [confirmRows, setConfirmRows] = useState<ReactElement[]>([]);
    const [choice, setChoice] = useState(false);
    let dpValues = getValues(accounts!, isSplit);

    const confirmationModal = {
        headerText: "",
        bodyText: `Your Preferences Have Been Updated to Receive Communications ${choice ? "Electronically" : "by Mail"}`,
        primaryButton: {
            text: "Close",
            action: () => {
                setShowConfirmation(false);
            }
        }
    } as GenericModalProps;

    useEffect(() => {
        confirmationModal.bodyText = `Your Preferences Have Been Updated to Receive Communications ${choice ? "Electronically" : "by Mail"}`;
    }, [choice]);

    return {
        hooks: {
            isSplit
        },
        state: {
            accounts: accounts!,
            showTerms,
            setShowTerms,
            showConfirmation,
            setShowConfirmation,
            ignore,
            setIgnore,
            preferences: preferences!,
            confirmRows,
            setConfirmRows,
            ...dpValues,
            confirmationModal,
            setChoice
        },
        api: {
            getPreferences,
            Enroll
        }
    };
}

type dpValues = {
    initialValues: any;
    selectedValues: any;
};

const getValues = (accounts: UserAccount[], isSplit: (account: UserAccount) => boolean): dpValues => {
    let ivs = "{";
    for (var i = 0; i < accounts.length; i++) {
        ivs += ` "checkBox-${i}" : ${isSplit(accounts[i]) ? "false" : "true"}${i < accounts.length - 1 ? "," : ""}`;
    }
    ivs += "}";

    let svs = "{";
    for (var j = 0; j < accounts.length; j++) {
        svs += ` "checkBox-${j}" : true${j < accounts.length - 1 ? "," : ""}`;
    }
    svs += "}";

    return {
        initialValues: JSON.parse(ivs),
        selectedValues: JSON.parse(svs)
    };
};
