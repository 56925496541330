import { ReactElement } from "react";
import { DeliveryPreferencesConfirmationModel } from "../deliveryPreferencesConfirmation/deliveryPreferencesConfirmationModel";
import { DeliveryPreferencesPrompt } from "../deliveryPreferencesPrompt/deliveryPreferencesPromptModel";
import { DeliveryPreferencesTermsModel } from "../deliveryPreferencesTerms/deliveryPreferencesTermsModel";
import { DeliveryPreferencesLandingProps } from "./deliveryPreferencesLandingModel";
import { DeliveryPreferencesLandingState } from "./deliveryPreferencesLandingViewModel";

type DeliveryPreferencesLandingViewProps = DeliveryPreferencesLandingProps &
    DeliveryPreferencesLandingState & {
        SetDelPrefTimer: Function;
    };

export function DeliveryPreferencesLandingView(props: DeliveryPreferencesLandingViewProps): ReactElement {
    return (
        <>
            {/* <> Prompt </> */}
            <DeliveryPreferencesPrompt show={props.showLanding} setShow={props.setShowLanding} {...props} />

            {/* <> Terms </> */}
            <DeliveryPreferencesTermsModel
                show={props.showTerms}
                setShow={props.setShowTerms}
                setShowDelPrefConfirmModal={props.setShowConfirmation}
                setEditPreferences={props.setIgnore}
                initialValues={props.initialValues}
                selectedValues={props.selectedValues}
                setSelectedValues={props.setIgnore}
                resetForm={() => {}}
                setDelPrefTimer={() => props.SetDelPrefTimer()}
                setTempDeliveryPreferences={props.setIgnore}
            />

            {/* <> Confirmation </> */}
            <DeliveryPreferencesConfirmationModel
                show={props.showConfirmation}
                setShow={props.setShowConfirmation}
                tempDeliveryPreferences={props.ignore}
                setTempDeliveryPreferences={props.setIgnore}
                resetForm={() => {}}
                confirmRows={props.confirmRows}
            />
        </>
    );
}
