import { ReactElement, useEffect } from "react";
import { Error503View } from "./error503View";
import { useError503ViewModel } from "./error503ViewModel";

export function Error503Model(): ReactElement {
    const { hooks, state } = useError503ViewModel();

    useEffect(() => {
        if (state.location.pathname.toLowerCase() !== "/error503") {
            hooks.navigate("/Error503");
        }
    }, []);

    return <Error503View {...state} />;
}
