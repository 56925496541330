import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function PayFill(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg id={id} className={svgClass} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3871 10.5818C10.8053 10.1962 11.3845 9.97013 12 9.97013C12.6155 9.97013 13.1947 10.1962 13.6129 10.5818C14.029 10.9656 14.25 11.4724 14.25 11.9868C14.25 12.5012 14.029 13.008 13.6129 13.3918C13.1947 13.7774 12.6155 14.0035 12 14.0035C11.3845 14.0035 10.8053 13.7774 10.3871 13.3918C9.97101 13.008 9.75 12.5012 9.75 11.9868C9.75 11.4724 9.97101 10.9656 10.3871 10.5818Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.75 4.32023C0.335786 4.32023 0 4.65602 0 5.07023V18.9036C0 19.3178 0.335786 19.6536 0.75 19.6536H23.25C23.6642 19.6536 24 19.3178 24 18.9036V5.07023C24 4.65602 23.6642 4.32023 23.25 4.32023H0.75ZM4.5 7.43278C4.22035 7.43278 3.94115 7.53467 3.72638 7.73274C3.50957 7.93268 3.375 8.21748 3.375 8.52861C3.375 8.75953 3.44958 8.9796 3.58086 9.16079C3.71149 9.34109 3.89057 9.473 4.08886 9.54874C4.28688 9.62439 4.50174 9.64342 4.70848 9.60549C4.91533 9.56755 5.11297 9.47265 5.27362 9.32449C5.43491 9.17575 5.55253 8.97863 5.60093 8.75421C5.64948 8.52915 5.62388 8.29663 5.53061 8.08898C5.43778 7.88229 5.28517 7.71555 5.10179 7.60256C4.91889 7.48985 4.70962 7.43278 4.5 7.43278ZM18.7264 14.6492C18.9412 14.4512 19.2203 14.3493 19.5 14.3493C19.7096 14.3493 19.9189 14.4064 20.1018 14.5191C20.2852 14.6321 20.4378 14.7988 20.5306 15.0055C20.6239 15.2131 20.6495 15.4457 20.6009 15.6707C20.5525 15.8951 20.4349 16.0923 20.2736 16.241C20.113 16.3892 19.9153 16.4841 19.7085 16.522C19.5017 16.5599 19.2869 16.5409 19.0889 16.4652C18.8906 16.3895 18.7115 16.2576 18.5809 16.0773C18.4496 15.8961 18.375 15.676 18.375 15.4451C18.375 15.134 18.5096 14.8492 18.7264 14.6492ZM12 8.47013C11.0242 8.47013 10.0773 8.82707 9.37022 9.47913C8.66113 10.1331 8.25 11.0336 8.25 11.9868C8.25 12.94 8.66113 13.8405 9.37022 14.4945C10.0773 15.1465 11.0242 15.5035 12 15.5035C12.9758 15.5035 13.9227 15.1465 14.6298 14.4945C15.3389 13.8405 15.75 12.94 15.75 11.9868C15.75 11.0336 15.3389 10.1331 14.6298 9.47913C13.9227 8.82707 12.9758 8.47013 12 8.47013Z"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
