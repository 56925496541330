import { Outlet } from "react-router-dom";
import { AppHeaderPrivate, AppHeaderPublic, Footer, ProminentAlert } from "../..";
import "./privateView.scss";
import { PrivateViewProps } from "./privateViewContext";
import { PrivateViewState } from "./privateViewModel";

export function PrivateView(props: PrivateViewProps & PrivateViewState) {
    return (
        <div id="privateView">
            {props.loggedIn ? <AppHeaderPrivate /> : <AppHeaderPublic />}
            <div id="privateView-outter" className={`privateView-outter${props.showBanner ? "-banner" : ""}`}>
                <div
                    id="privateView-content"
                    className={`${props.restricted ? "restricted" : ""} ${props.showNav ? "navShown" : ""}`}
                >
                    {props.appSettings?.featureFlags?.displayBannerAuthenticated ||
                    props.appSettings?.featureFlags?.displayBannerUnauthenticated ? (
                        <ProminentAlert />
                    ) : (
                        <></>
                    )}
                    {props.children !== undefined ? props.children : <Outlet />}
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default PrivateView;
