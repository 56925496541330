import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Delete(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="14"
            viewBox="0 0 24 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.5 4.74707L12 9.24707M12 4.74707L16.5 9.24707"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.12124 1H21.0002C21.3981 1 21.7796 1.15804 22.0609 1.43934C22.3422 1.72065 22.5002 2.10218 22.5002 2.5V11.5C22.5002 11.8978 22.3422 12.2794 22.0609 12.5607C21.7796 12.842 21.3981 13 21.0002 13H8.12124C7.7237 12.9999 7.34244 12.842 7.06124 12.561L1.72024 7.527C1.65058 7.45744 1.59531 7.37482 1.5576 7.28388C1.5199 7.19294 1.50049 7.09545 1.50049 6.997C1.50049 6.89855 1.5199 6.80107 1.5576 6.71013C1.59531 6.61918 1.65058 6.53657 1.72024 6.467L7.06124 1.436C7.34293 1.15608 7.72413 0.999291 8.12124 1Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
