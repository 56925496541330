import { ReactElement } from "react";
import { FormatMoney, GetFormattedDate } from "../../../../utility";
import { ArrowDown, ArrowUp } from "../../../generic/icons";
import { PaymentHistoryRowProps } from "./paymentHistoryRow";
import "./paymentHistoryRow.scss";
import { PaymentHistoryRowState } from "./paymentHistoryRowViewModel";

type PaymentHistoryRowViewProps = PaymentHistoryRowProps &
    PaymentHistoryRowState & {
        newRowExpanded: Function;
    };

export function PaymentHistoryRowView(props: PaymentHistoryRowViewProps): ReactElement {
    return (
        <div className={`paymentHistoryRow-content paymentHistoryRow-content-${props.className}`}>
            <div
                data-testid={"paymentHistoryRow-expand-button"}
                className={`paymentHistoryRow-${props.className}`}
                onClick={() => {
                    props.newRowExpanded(props.index);
                }}
            >
                <div className="paymentHistoryRow-collapsed">
                    <p className={`paymentHistoryRow-date-${props.className}`}>
                        {GetFormattedDate(props.payment.paymentDate?.toString(), true)}
                    </p>
                    <div className="paymentHistoryRow-right">
                        <div className="paymentHistoryRow-amount">
                            <p className={`paymentHistoryRow-amount-${props.className}`}>
                                {FormatMoney(props.payment.paymentAmount).formatted}
                            </p>
                            <h6 className={`paymentHistoryRow-status-${props.className}`}>
                                {props.payment.paymentStatus}
                            </h6>
                        </div>
                        {props.expanded ? (
                            <ArrowUp className="paymentHistoryRow-icon" />
                        ) : (
                            <ArrowDown className="paymentHistoryRow-icon" />
                        )}
                    </div>
                </div>
                {props.expanded === true ? (
                    <div className="paymentHistoryRow-expanded">
                        <RowInfo
                            header="Confirmation Number"
                            value={props.payment.referenceNumber}
                            cn={props.className}
                        />
                        <RowInfo
                            header="Scheduled Date"
                            value={GetFormattedDate(props.payment.dateEntered?.toString(), true)}
                            cn={props.className}
                        />
                        <RowInfo header="Credit Card Paid" value={`PREMIER ••••${props.last4}`} cn={props.className} />
                        <RowInfo
                            header="Paid From Account"
                            value={`${props.payment.bankName} ${props.payment.paidFrom.replaceAll("*", "•")}`}
                            cn={props.className}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}

function RowInfo({ header, value, cn }: { header: string; value: string; cn: string }): ReactElement {
    return (
        <div className="paymentHistoryRow-info">
            <h6 className={`paymentHistoryRow-infoHeader-${cn}`}>{header}</h6>
            <p className={`paymentHistoryRow-infoBody-${cn}`}>{value}</p>
        </div>
    );
}
