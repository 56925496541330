import { ReactElement } from "react";
import { CCPAHomeView } from "./ccpaHomeView";
import { useCCPAHomeViewModel } from "./ccpaHomeViewModel";

export function CCPAHomeModel(): ReactElement {
    const { hooks, state } = useCCPAHomeViewModel();

    function onLogin() {
        state.setDeepLink("/home/ccpa")
        hooks.navigate("/auth/login");
    }

    function onNoAccount() {
        state.setIsAnonymous(true);
    }

    return <CCPAHomeView {...state} onLogin={onLogin} onNoAccount={onNoAccount} />;
}
