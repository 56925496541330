import { AxiosError } from "axios";
import { ReactElement } from "react";
import { createBrowserRouter, useRouteError } from "react-router-dom";
import { UserAccount } from "../api/backend";
import { AppContext } from "../AppContext";
import {
    ActivitySummary,
    BrowserCompatibility,
    BrowserCompatibilityLoader,
    CCPAHome,
    CCPAHomeLoader,
    CardSummary,
    ChangePassword,
    ChangePasswordLoader,
    Dashboard,
    DocumentUpload,
    DocumentsCMSLoader,
    DocumentsSummary,
    EnrollCredentials,
    EnrollTerms,
    Error403,
    Error404,
    Error500,
    Error503,
    ErrorPage,
    ForgotLogin,
    LockCard,
    Login,
    LoginDeliverMfa,
    ManageAlerts,
    MfaEnterCode,
    NewPassword,
    PCP,
    PCPEnrollment,
    PaymentsSummary,
    PrivateView,
    PublicView,
    RequestAuthUser,
    RequestCLI,
    RequestCLILoader,
    ShowUsername,
    UpdateContactInformation,
    UpdateDeliveryPreferences
} from "../components";
import { Authorized, RequiredAuth } from "../components/authenticated/authenticatedModel";
import { DevTools } from "../components/devTools/devTools";
import { EnrollTermsLoader } from "../components/login/enrollTerms/enrollTermsLoader";
import {
    EnrollmentLoader,
    ForgotPasswordLoader,
    ForgotUsernameLoader
} from "../components/login/forgotLogin/forgotLoginLoader";
import { ManageAlertsLoader } from "../components/profile/manageAlerts/manageAlertsLoader";
import {
    AccessibilityDisclosureLoader,
    CreditCardAgreement,
    CreditCardAgreementLoader,
    Disclosures,
    DisclosuresTab,
    Help,
    Logout,
    MastercardGuideToBenefitsLoader,
    PCPDisclosureLoader,
    PCPDisclosuresTab,
    PCPFormsAndMaterialsLoader,
    PCPFormsAndMaterialsTab,
    PrivacyDisclosureLoader,
    PrivacyNoticeDisclosureLoader,
    TermsAndConditionsDisclosureLoader
} from "../components/public";
import { useSessionStorage } from "../hooks";
import { IsNullOrEmpty } from "../utility";

export function Router() {
    return createBrowserRouter([
        {
            path: "/",
            element: <AppContext />,
            loader: BrowserCompatibilityLoader,
            errorElement: <RootBoundry />,
            children: [
                {
                    element: <PublicView />,
                    children: [
                        { path: "*", element: <Error404 /> },
                        {
                            path: "error403",
                            element: <Error403 />
                        },
                        {
                            path: "error500",
                            element: <Error500 />
                        },
                        {
                            path: "error503",
                            element: <Error503 />
                        },
                        {
                            path: "devtools",
                            element: <DevTools />
                        },
                        {
                            path: "browser-check",
                            element: <BrowserCompatibility />,
                            loader: BrowserCompatibilityLoader
                        },
                        {
                            path: "credit-card-agreements",
                            element: <CreditCardAgreement />,
                            loader: CreditCardAgreementLoader
                        },
                        {
                            path: "Home/Offers",
                            element: <CreditCardAgreement />,
                            loader: CreditCardAgreementLoader
                        },
                        {
                            path: "home/accessibility",
                            element: <Disclosures />,
                            loader: () => AccessibilityDisclosureLoader(false)
                        },
                        {
                            path: "home/privacy",
                            element: <Disclosures />,
                            loader: () => PrivacyDisclosureLoader(false)
                        },
                        {
                            path: "home/terms-of-use",
                            element: <Disclosures />,
                            loader: () => TermsAndConditionsDisclosureLoader(false)
                        },
                        {
                            path: "home/ccpa",
                            element: <CCPAHome />,
                            loader: () => CCPAHomeLoader()
                        },
                        {
                            path: "logout",
                            element: <Logout timeout={false} />
                        },
                        {
                            path: "timeout",
                            element: <Logout timeout={true} />
                        },
                        {
                            path: "help",
                            element: <Help />
                        }
                    ]
                },
                {
                    path: "account",
                    element: (
                        <RequiredAuth>
                            <PrivateView />
                        </RequiredAuth>
                    ),
                    children: [
                        {
                            path: "dashboard",
                            element: <Dashboard />
                        },
                        {
                            path: "summary/:id",
                            children: [
                                {
                                    path: "home",
                                    element: <CardSummary />
                                },
                                {
                                    path: "payments",
                                    element: <PaymentsSummary />
                                },
                                {
                                    path: "activity",
                                    element: <ActivitySummary />
                                },
                                {
                                    path: "document-upload",
                                    element: <DocumentUpload />
                                },
                                {
                                    path: "authorized-user",
                                    element: <RequestAuthUser />
                                },
                                {
                                    path: "lock-card",
                                    element: <LockCard />
                                },
                                {
                                    path: "documents",
                                    element: <DocumentsSummary />,
                                    loader: () => DocumentsCMSLoader()
                                },
                                {
                                    path: "credit-line-increase",
                                    element: <RequestCLI />,
                                    loader: () => RequestCLILoader()
                                },
                                {
                                    path: "manage-alerts",
                                    element: <ManageAlerts />,
                                    loader: () => ManageAlertsLoader()
                                }
                            ]
                        },
                        {
                            path: "premier-credit-protection",
                            element: <PCP />
                        },
                        {
                            path: "premier-credit-protection-enrollment-status",
                            element: <PCPEnrollment />
                        }
                    ]
                },
                {
                    path: "auth",
                    element: (
                        <Authorized>
                            <PublicView />
                        </Authorized>
                    ),
                    children: [
                        {
                            path: "login",
                            element: <Login />
                        },
                        {
                            path: "request-code",
                            element: <LoginDeliverMfa />
                        },
                        {
                            path: "enter-code",
                            element: <MfaEnterCode />
                        },
                        {
                            path: "forgot-password",
                            element: <ForgotLogin />,
                            loader: () => ForgotPasswordLoader()
                        },
                        {
                            path: "forgot-username",
                            element: <ForgotLogin />,
                            loader: () => ForgotUsernameLoader()
                        },
                        {
                            path: "enroll",
                            element: <ForgotLogin />,
                            loader: () => EnrollmentLoader()
                        },
                        {
                            path: "register",
                            element: <EnrollCredentials />
                        },
                        {
                            path: "accept-terms",
                            element: <EnrollTerms />,
                            loader: () => EnrollTermsLoader()
                        },
                        {
                            path: "new-password",
                            element: <NewPassword />
                        },
                        {
                            path: "show-username",
                            element: <ShowUsername />
                        }
                    ]
                },
                {
                    path: "profile",
                    element: (
                        <RequiredAuth>
                            <PrivateView />
                        </RequiredAuth>
                    ),
                    children: [
                        {
                            path: "update-profile",
                            element: <UpdateContactInformation />
                        },
                        {
                            path: "delivery-options",
                            element: <UpdateDeliveryPreferences />
                        },
                        {
                            path: "change-password",
                            element: <ChangePassword />,
                            loader: () => ChangePasswordLoader()
                        }
                    ]
                }
            ]
        },
        {
            element: <PublicView clean />,
            children: [
                {
                    path: "home/print-view-privacy",
                    element: <DisclosuresTab />,
                    loader: () => PrivacyDisclosureLoader(true)
                },
                {
                    path: "home/print-view-terms-of-use",
                    element: <DisclosuresTab />,
                    loader: () => TermsAndConditionsDisclosureLoader(true)
                },
                {
                    path: "home/print-view-accessibility",
                    element: <DisclosuresTab />,
                    loader: () => AccessibilityDisclosureLoader(true)
                },
                {
                    path: "home/privacy-notice",
                    element: <DisclosuresTab />,
                    loader: () => PrivacyNoticeDisclosureLoader(false)
                },
                {
                    path: "home/PREMIER-credit-protection-disclosure",
                    element: <PCPDisclosuresTab />,
                    loader: () => PCPDisclosureLoader(true)
                },
                {
                    path: "home/PREMIER-credit-protection-benefit-activation-form",
                    element: <PCPFormsAndMaterialsTab />,
                    loader: () => PCPFormsAndMaterialsLoader(false)
                },
                {
                    path: "home/mastercard-guide-to-benefits",
                    element: <PCPFormsAndMaterialsTab />,
                    loader: () => MastercardGuideToBenefitsLoader(false)
                },
                {
                    path: "premier-credit-protection-enrollment-status",
                    element: <PCPEnrollment />
                }
            ]
        }
    ]);
}

function RootBoundry(): ReactElement {
    const error: any = useRouteError();
    const [storage] = useSessionStorage(["access_token", "mfaRoute", "accounts"]);
    let loggedIn =
        !IsNullOrEmpty(storage.access_token) &&
        storage.mfaRoute === "Login" &&
        ((JSON.parse(storage.accounts ?? "[]") as UserAccount[]).length ?? 0) > 0;

    if (error instanceof AxiosError) {
        let status = error.response?.status;
        let code = error.code?.toUpperCase();
        let page = GetError(loggedIn, status);

        if (status) {
            return page;
        } else if (code?.includes("ERR_NETWORK")) {
            return <Error503 />;
        } else if (code?.includes("ERR_")) {
            return <Error500 />;
        } else {
            return page;
        }
    }

    return GetError(loggedIn);
}

function GetError(loggedIn: boolean, status?: number): ReactElement {
    let errorPage = <ErrorPage status={status} />;

    switch (status) {
        case 403:
            return <Error403 />;
        case 500:
            return <Error500 />;
        case 503:
            return <Error503 />;
        default: {
            if (loggedIn) return <PrivateView>{errorPage}</PrivateView>;
            return <PublicView>{errorPage}</PublicView>;
        }
    }
}
