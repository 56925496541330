import { AxiosError } from "axios";
import { FormikHelpers } from "formik";
import { ValidationErrors } from "../../../api/backend";
import { GrantError } from "../../../api/identityServer";
import { UUID } from "../../../utility";
import { MfaFields } from "./mfaEnterCodeView";
import { MfaEnterCodeApi, MfaEnterCodeHooks, MfaEnterCodeState } from "./mfaEnterCodeViewModel";

const changeFocus = (index: number, forward: boolean) => {
    const newIndex = forward ? index + 1 : index - 1;
    let ele = document.querySelector(`input[id=mfaInput${newIndex}]`) as HTMLInputElement;
    ele?.focus();
};

export function onStateChangedFn(state: MfaEnterCodeState) {
    if (state.codeState !== "-fail") {
        if (state.canContinue) {
            state.setCodeState("-pass");
            (document.querySelector(`button[id=mfaEnterCode-submit]`) as HTMLButtonElement)?.focus();
        } else {
            state.setCodeState("");
        }
    }
}

function resetField(state: MfaEnterCodeState) {
    clearTimeout(state.timeoutId);
    state.setCodeState("");
    state.setOtp("");

    let gModal = document.getElementById("genericModal");
    if (!gModal) {
        changeFocus(-1, true);
    }
}

export function resetFieldTimerFn(state: MfaEnterCodeState, actions: FormikHelpers<MfaFields>) {
    state.setTimeoutId(
        setTimeout(() => {
            actions.setErrors({});
            resetField(state);
        }, 2000)
    );
}

export function onChangeFn(state: MfaEnterCodeState, value: string) {
    state.setOtp(value);
    state.setCanContinue(value.trim().length === 4);
}

export function onOtpFailFn(state: MfaEnterCodeState) {
    if (state.codeState === "-fail") {
        resetField(state);
    }
}

export function onOtpChangeFn(state: MfaEnterCodeState, value: string) {
    state.setOtp(value);
    state.setCanContinue(value.trim().length === 4);
}

export function submitLoginFn(
    hooks: MfaEnterCodeHooks,
    state: MfaEnterCodeState,
    api: MfaEnterCodeApi,
    actions: FormikHelpers<MfaFields>,
    cs?: {
        setShow: React.Dispatch<React.SetStateAction<boolean>>;
        setShowTrust: React.Dispatch<React.SetStateAction<boolean>>;
        setShowError: React.Dispatch<React.SetStateAction<boolean>>;
        setShowPinExpired: React.Dispatch<React.SetStateAction<boolean>>;
    }
) {
    api.verifyCode()
        .then(async (res) => {
            if (cs) {
                cs.setShow(false);
                cs.setShowPinExpired(false);
            }

            if (state.securityType === "Login") {
                hooks.storeValue("showTrust", true);
                if (cs) {
                    cs.setShowTrust(true);
                } else {
                    state.setShowTrust(true);
                }
            } else {
                hooks.removeValue(["showTrust", "username", "password"]);
                hooks.navigate("/auth/new-password");
            }
        })
        .catch((err: AxiosError) => {
            state.setCodeState("-fail");
            let reqErr = err.response?.data as GrantError;
            switch (reqErr?.errorCode) {
                case "INCORRECT_PIN":
                    actions.setErrors({
                        digit1: "Invalid Code"
                    });
                    break;
                case "PIN_ATTEMPTS":
                    //TODO LOCKOUT -> Forgot Password
                    state.setShowError(true);
                    break;
                case "INVALID_MFA_TOO_MANY_ATTEMPTS":
                    if (cs) {
                        cs.setShow(false);
                        cs.setShowError(true);
                    } else {
                        state.setShowError(true);
                    }
                    break;
                case "PIN_EXPIRED":
                    if (cs) {
                        cs.setShow(false);
                        cs.setShowPinExpired(true);
                    } else {
                        state.setShowPinExpired(true);
                    }
                    break;
                default:
                    actions.setErrors({ digit1: "Something went wrong!" });
                    state.setShowError(true);
            }
            state.setCanContinue(false);
            resetFieldTimerFn(state, actions);
        })
        .finally(() => {
            actions.setSubmitting(false);
        });
}

export function submitEnrollFn(
    hooks: MfaEnterCodeHooks,
    state: MfaEnterCodeState,
    api: MfaEnterCodeApi,
    actions: FormikHelpers<MfaFields>
) {
    let sessionId = state.searchParams.get("id");

    if (sessionId && UUID.Valid(sessionId)) {
        api.enrollVerifyCode({ sessionId: sessionId, mfaCode: state.otp })
            .then((res) => {
                hooks.navigate({
                    pathname: "/auth/accept-terms",
                    search: `?id=${sessionId}`
                });
            })
            .catch((err: AxiosError) => {
                state.setCodeState("-fail");
                switch (err.response?.status) {
                    case 422: {
                        let errors = err.response?.data as ValidationErrors;
                        let errorField = Object.keys(errors.errors)[0];
                        if (errorField === "InvalidMfaEntered") {
                            actions.setErrors({ digit1: "Invalid Code" });
                        } else if (errorField === "InvalidMfaTooManyAttempts") {
                            state.setShowError(true);
                        } else if (errorField === "InvalidSession") {
                            state.setShowInvalidSession(true);
                        } else {
                            actions.setErrors({ digit1: "Something went wrong!" });
                        }
                    }
                }
                state.setCanContinue(false);
                resetFieldTimerFn(state, actions);
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    } else {
        hooks.navigate("/auth/login");
    }
}
