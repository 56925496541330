import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Star(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.7426 1.95566L17.1627 8.73215L23.7453 9.38435C23.902 9.39737 24.0517 9.45503 24.1767 9.5505C24.3016 9.64597 24.3966 9.77524 24.4504 9.92303C24.5042 10.0708 24.5145 10.2309 24.4801 10.3844C24.4457 10.5378 24.3681 10.6782 24.2564 10.7889L18.839 16.1588L20.8475 23.4556C20.8886 23.6104 20.8841 23.7737 20.8346 23.926C20.7851 24.0783 20.6926 24.213 20.5683 24.314C20.444 24.415 20.2932 24.4779 20.1341 24.4951C19.9749 24.5124 19.8141 24.4834 19.671 24.4114L12.9974 21.1065L6.33305 24.4073C6.19 24.4793 6.02921 24.5083 5.87003 24.4911C5.71085 24.4738 5.56005 24.4109 5.43577 24.3099C5.31149 24.2089 5.21902 24.0742 5.16949 23.9219C5.11995 23.7696 5.11547 23.6063 5.15656 23.4515L7.16508 16.1547L1.74361 10.7848C1.63193 10.6741 1.55429 10.5337 1.51991 10.3803C1.48553 10.2268 1.49583 10.0667 1.54961 9.91894C1.60338 9.77116 1.69837 9.64188 1.82333 9.54641C1.94828 9.45094 2.09798 9.39328 2.25469 9.38026L8.83731 8.72806L12.2523 1.95566C12.3225 1.81848 12.4293 1.70334 12.5607 1.62294C12.6922 1.54254 12.8433 1.5 12.9974 1.5C13.1516 1.5 13.3027 1.54254 13.4341 1.62294C13.5656 1.70334 13.6724 1.81848 13.7426 1.95566V1.95566Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
