import { FormikHelpers } from "formik";
import { ReactElement, useEffect } from "react";
import { useSettingsContext } from "../../../api/backend";
import { UUID } from "../../../utility";
import { EnrollTermsFields, EnrollTermsView } from "./enrollTermsView";
import { useEnrollTermsViewModel } from "./enrollTermsViewModel";

export function EnrollTermsModel(): ReactElement {
    const { hooks, state, api } = useEnrollTermsViewModel();
    const { appSettings } = useSettingsContext();
    const isEMessengerDown: boolean = appSettings?.featureFlags?.isEMessengerDown;

    useEffect(() => {
        if (state.searchParams.get("id") === null || state.securityType === undefined) {
            let id = UUID.New();
            hooks.navigate({
                pathname: "/auth/enroll",
                search: `?id=${id}`
            });
        }
    }, []);

    useEffect(() => {
        if (state.showSplash) {
            window.document.getElementById("publicView-outter")?.scrollTo(0, 0);
            let sessionId = state.searchParams.get("id");
            api.AcceptTerms({ SessionId: sessionId!, AcceptedTerms: true })
                .then(async (res) => {
                    api.AuthorizeOtac(res.data.otac)
                        .then(async (tokenResponse) => {
                            api.userAccounts(tokenResponse.data.access_token!)
                                .then(async (accounts) => {
                                    await api.digitalActions(tokenResponse.data.access_token!, accounts.data);

                                    if (accounts.data.length > 0) {
                                        await api
                                            .userConsentGet(
                                                accounts.data[0].onlineUserId,
                                                accounts.data[0].applicantHandle
                                            )
                                            .then(async (consentRes) => {
                                                hooks.updateConsumerGuid(consentRes.data.consumerGuid);
                                                hooks.updateJwt(consentRes.data.jwt);
                                                hooks.syncOneTrustConsentProfile(
                                                    consentRes.data.consumerGuid,
                                                    consentRes.data.jwt
                                                );
                                            });

                                        hooks.initializeSwrve(accounts.data[0].applicantHandle);
                                    }

                                    if (!isEMessengerDown) {
                                        await api.getPreferences(tokenResponse.data.access_token!);
                                    }
                                })
                                .finally(() => {
                                    state.setSecurityType("Login");
                                    hooks.navigate("/account/dashboard");
                                });
                        })
                        .catch((err) => {
                            state.setShowSplash(false);
                            state.setShowError(true);
                        });
                })
                .catch((err) => {
                    state.setShowSplash(false);
                    state.setShowError(true);
                });
        }
    }, [state.showSplash]);

    function onCancel() {
        state.setshowCancel(true);
    }

    function onSubmit(values: EnrollTermsFields, actions: FormikHelpers<EnrollTermsFields>) {
        actions.setSubmitting(false);
        let sessionId = state.searchParams.get("id");

        if (sessionId && UUID.Valid(sessionId)) {
            state.setShowSplash(true);
        } else {
            state.setShowError(true);
        }
    }

    return <EnrollTermsView {...state} onCancel={onCancel} onSubmit={onSubmit} />;
}
