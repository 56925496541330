export const EqualsIgnoreCase = (str1: string | undefined, str2: string | undefined): boolean => {
    return str1?.trim().toUpperCase() === str2?.trim().toUpperCase();
};

export const IncludesIgnoreCase = (str1: string | undefined, str2: string | undefined): boolean => {
    let s1 = str1?.trim().toUpperCase();
    let s2 = str2?.trim().toUpperCase();

    if (s2) {
        return s1?.includes(s2) ?? false;
    }

    return false;
};

export const IsNullOrEmpty = (value: string | undefined | null) => {
    return (
        value === null ||
        value === undefined ||
        value?.trim() === "" ||
        EqualsIgnoreCase(value, "null") ||
        EqualsIgnoreCase(value, "undefined")
    );
};

export const TruncateMid = function (fullStr: string, strLen: number, separator?: string) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || "...";

    var sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2);

    return fullStr.substring(0, frontChars) + separator + fullStr.substring(fullStr.length - backChars);
};
