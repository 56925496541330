import "./cardSecurityDeposit.scss";
import { ReactElement } from "react";
import { CardSecurityDepositState } from "./cardSecurityDepositViewModel";
import { CardApplicationProps } from "../../cardApplication/cardApplication";
import { FormatMoney } from "../../../../utility";
import { ButtonPro } from "../../../generic";

type CardSecurityDepositViewProps = CardApplicationProps &
    CardSecurityDepositState & {
        onPay: Function;
    };

export function CardSecurityDepositView(props: CardSecurityDepositViewProps): ReactElement {
    return (
        <div className={`cardSecurityDeposit${props.size}`}>
            <div className="cardSecurityDeposit-content cardSummaryCommon cardTilePadding">
                <div className="cardSecurityDeposit-amounts">
                    <h5>Security Deposit</h5>
                    <div className="cardSecurityDeposit-amount">
                        <p>Security Deposit Amount</p>
                        <h5>{FormatMoney(props.account.applicationFee).formatted}</h5>
                    </div>
                    <div className="cardSecurityDeposit-amount">
                        <p>Remaining Amount</p>
                        <h5>{FormatMoney(props.account.applicationBalance).formatted}</h5>
                    </div>
                </div>
                {props.account.applicationBalance > 0 ? (
                    <ButtonPro content={"Fund Security Deposit"} onClick={() => props.onPay()} />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}
