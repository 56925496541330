import { ReactElement, useEffect } from "react";
import DocumentUploadView from "./documentUploadView";
import { useDocumentUploadViewModel } from "./documentUploadViewModel";

export function DocumentUploadModel(): ReactElement {
    const { state, hooks } = useDocumentUploadViewModel();

    useEffect(() => {
        state.setAccountIndex(Number(state.id));

        if (!state.accounts![Number(state.id)].isApplicationStatus) state.setShowNav(true);

        return () => {
            state.setShowNav(false);
        };
    }, []);

    useEffect(() => {
        if (!hooks.actionAllowed("uploadDocuments", state.accounts![state.accountIndex].accountHandle)) {
            hooks.navigate(`/account/dashboard`, { relative: "path" });
        }
    }, [state.accountIndex]);

    useEffect(() => {
        state.insertSwrveEvent("Web_UploadDocuments_Screen");
    }, []);

    if (state.accounts![state.accountIndex].isApplicationStatus) {
        state.unavailableMessage = state.appSettings?.docUpload?.uploadDocumentsUnavailableMessageForApplications;
    } else {
        state.unavailableMessage = state.appSettings?.docUpload?.uploadDocumentsUnavailableMessage;
    }

    return <DocumentUploadView {...state} />;
}
