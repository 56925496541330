import { NavigateFunction, useNavigate } from "react-router-dom";
import { useSessionStorage, useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { ErrorPageProps } from "./errorPageModel";

type ErrorPageViewModel = {
    hooks: ErrorPageHooks;
    state: ErrorPageState;
};

type ErrorPageHooks = {
    navigate: NavigateFunction;
    storeValue: (name: string, value: any) => void;
};

export type ErrorPageState = {
    size: Size;
    primaryBtn: string;
};

const storageKeys = ["access_token"];

export function useErrorPageViewModel(props: ErrorPageProps): ErrorPageViewModel {
    const navigate = useNavigate();
    const { size } = useViewport();
    const [, storeValue] = useSessionStorage(storageKeys);

    const primaryBtn = props.status === 401 ? "Back to Log In" : "Retry";

    return {
        hooks: { navigate, storeValue },
        state: { size, primaryBtn }
    };
}
