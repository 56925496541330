import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useMemo } from "react";
import { getCalendarRows } from "../calendarUtils";
import "./datePickerCalendar.scss";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Chicago");

export interface IDatePickerCalendarProps {
    shownDate: Dayjs;
    selectedDate: Dayjs | undefined;
    endDate?: Dayjs;
    dueDate?: Dayjs;
    invalidDates?: Dayjs[];
    onChange: (newDate: Dayjs) => void;
}

const now = dayjs.tz();

export function DatePickerCalendar(props: IDatePickerCalendarProps): React.ReactElement {
    useEffect(() => {}, [props.invalidDates]);

    const handleSelectDate = (value: Dayjs) => {
        return () => {
            if (dayValid(value, props.endDate) && !dayScheduled(value, props.invalidDates)) props.onChange(value);
        };
    };

    const rows = useMemo(() => getCalendarRows(props.shownDate), [props.shownDate]);

    return (
        <div className="datePickerCalendar">
            <div className={"datePickerCalendar-header"}>
                {rows[0].map(({ value }, i) => (
                    <div key={i} className={"datePickerCalendar-cell"}>
                        <h6>{value.format("ddd")}</h6>
                    </div>
                ))}
            </div>

            {rows.map((cells, rowIndex) => (
                <div key={rowIndex} className={"datePickerCalendar-row"}>
                    {cells.map(({ text, value }, i) => (
                        <div
                            key={`${text} - ${i}`}
                            className={getDayClass(
                                now,
                                value,
                                props.selectedDate,
                                props.endDate,
                                props.dueDate,
                                props.invalidDates
                            )}
                            onClick={handleSelectDate(value)}
                        >
                            <h6>{text}</h6>
                            {(props.dueDate ? dateIsImportant(props.dueDate, value.format(cFormat)) : false) ? (
                                <h6>Due</h6>
                            ) : (
                                <></>
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

const cFormat = "MM DD YYYY";
const getDayClass = (
    now: Dayjs,
    value: Dayjs,
    selectedDate: Dayjs | undefined,
    endDate?: Dayjs,
    dueDate?: Dayjs,
    invalidDates?: Dayjs[]
): string => {
    const v = value.format(cFormat);

    if (now.isSame(value, "day")) {
        if (value.hour() >= 17) return "datePickerCalendar-dayCell-invalid";
    }

    if (dayScheduled(value, invalidDates)) return "datePickerCalendar-dayCell-scheduled";

    if (!dayValid(value, endDate)) return "datePickerCalendar-dayCell-invalid";

    if (dueDate ? dateIsImportant(dueDate, v) : false) {
        if (v !== selectedDate?.format(cFormat)) return "datePickerCalendar-dayCell-important";
        else return "datePickerCalendar-dayCell-selected";
    }
    if (v === selectedDate?.format(cFormat)) return "datePickerCalendar-dayCell-selected";

    return "datePickerCalendar-dayCell";
};

const dayValid = (value: Dayjs, endDate?: Dayjs): boolean => {
    var end = endDate === undefined ? true : value > endDate;
    return !(value.isBefore(now, "d") || end);
};

const dayScheduled = (value: Dayjs, invalidDates?: Dayjs[]): boolean => {
    return invalidDates?.find((d) => value.isSame(d, "date")) !== undefined;
};

function dateIsImportant(e: Dayjs, v: string) {
    return v === e.format(cFormat);
}
