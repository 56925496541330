import { ReactElement, useEffect } from "react";
import { useDisclosuresViewModel } from "./disclosuresViewModel";
import { IncludesIgnoreCase, SetTitle } from "../../../utility";
import { DisclosuresView } from "./disclosuresView";

export function DisclosuresModel(): ReactElement {
    const { state } = useDisclosuresViewModel();

    useEffect(() => {
        if (state.loggedIn) state.setShowNav(false);
    }, []);

    useEffect(() => {
        document.querySelectorAll("a").forEach((a) => {
            if (IncludesIgnoreCase(a.href, "/home/ccpa")) {
                a.target = "_self";
            }
        });

        SetTitle(state.headerText);

        return () => SetTitle("");
    }, []);

    function navPrintPreview() {
        window.open(window.location.origin + state.printView, "_blank");
    }

    return <DisclosuresView {...state} navigatePrintPreview={navPrintPreview} />;
}
