import { ReactElement } from "react";
import { ButtonPro } from "../../generic";
import { ColorBlocks5 } from "../../generic/icons";
import "./error404.scss";
import { Error404State } from "./error404ViewModel";

type Error404ViewProps = Error404State & {
    returnHome: Function;
};

export function Error404View(props: Error404ViewProps): ReactElement {
    const errorMessage = "Sorry, we couldn't find that page.";

    return (
        <div id="error404">
            <div className={`error404-content${props.size}`}>
                <div className="error404-text">
                    <h5>Page Not Found</h5>
                    {props.size === "" ? <h2>{errorMessage}</h2> : <h4>{errorMessage}</h4>}
                </div>
                <ButtonPro type="button" onClick={() => props.returnHome()}>
                    Back to Home
                </ButtonPro>
            </div>
            <ColorBlocks5 className={`error404-blocks${props.size}`} />
        </div>
    );
}
