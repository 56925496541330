import { useLoaderData } from "react-router";
import { useIsLoggedIn, useViewport } from "../../../hooks";
import { DisclosuresData } from "./disclosuresLoader";
import { Size } from "../../../types";
import { CardNavContext } from "../../../contexts";
import { useContext } from "react";

type DisclosuresViewModel = {
    state: DisclosuresState;
};

export type DisclosuresState = {
    size: Size;
    headerText: string;
    bodyText: string;
    isPrint: boolean;
    printView: string;
    isPdf: boolean;
    contentType: string;
    fileName: string;
    loggedIn: boolean;
    setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
    pdfData: ArrayBuffer;
};

export function useDisclosuresViewModel(): DisclosuresViewModel {
    const { size } = useViewport(false, true);
    const { setShowNav } = useContext(CardNavContext);
    const { loggedIn } = useIsLoggedIn();
    const data = useLoaderData() as DisclosuresData;
    const contentType = data.cms.PrivacyNotice.ContentType;
    const fileName = data.cms.PrivacyNotice.FileName;
    const pdfData = data.cms.PrivacyNotice.DisclosureData;

    return {
        state: {
            size,
            contentType,
            fileName,
            pdfData,
            loggedIn,
            setShowNav,
            ...data
        }
    };
}
