import { ReactElement } from "react";
import { LogoutProps } from "./logoutModel";
import { LogoutState } from "./logoutViewModel";
import "./logout.scss";

type LogoutViewProps = LogoutProps & LogoutState;

export function LogoutView(props: LogoutViewProps): ReactElement {
    return (
        <div id="logout">
            <div id={`logout-page${props.size}`}>
                <div id="logout-content">
                    <div id={`logout-header${props.size}`}>
                        <div id="logout-headerText">
                            {props.timeout ? "Your Session has Expired" : "You Have Successfully Logged Out"}
                        </div>
                    </div>
                    <div>
                        <div id="logout-subtitle">For your security, make sure you close your browser</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
