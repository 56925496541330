import { createContext, useState } from "react";
import { NotImplemented } from "../utility";

export type CardNavContextProps = {
    showNav: boolean;
    setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CardNavContext = createContext<CardNavContextProps>({
    showNav: false,
    setShowNav: NotImplemented
});

export const useCardNavContextProps = (): CardNavContextProps => {
    const [showNav, setShowNav] = useState(true);

    return {
        showNav,
        setShowNav
    };
};
