import { ReactElement } from "react";
import { GetFormattedDate } from "../../../../../utility";
import { SeeStatementMessageAlert } from "../../../../common/seeStatementMessageAlert/seeStatementMessageAlert";
import "./paymentDueDate.scss";
import { PaymentDueDateState } from "./paymentDueDateViewModel";

export function PaymentDueDateView(props: PaymentDueDateState): ReactElement {
    return (
        <div className={`paymentDueDate${props.size}`}>
            <h5>Payment Due Date</h5>
            {props.account?.seeStatementMessage ? (
                <div className="paymentHub-info-container">
                    <SeeStatementMessageAlert />
                </div>
            ) : (
                <>
                    <h2>{GetFormattedDate(props.account?.paymentDueBy, true)}</h2>
                </>
            )}
        </div>
    );
}
