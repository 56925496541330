import dayjs from "dayjs";
import { Form, FormikProps } from "formik";
import { ReactElement } from "react";
import { IndexedCreditCard, Loading } from "../../..";
import { FormatMoney, GetFormattedDate } from "../../../../utility";
import { Alert } from "../../../generic";
import { ButtonPro } from "../../../generic/inputs/buttonPro/buttonPro";
import "./reviewPayment.scss";
import { ReviewPaymentProps } from "./reviewPaymentModel";
import { ReviewPaymentState } from "./reviewPaymentViewModel";

type ReviewPaymentViewProps = ReviewPaymentProps &
    ReviewPaymentState &
    FormikProps<{}> & {
        onCancel: Function;
        onEdit: Function;
    };

export function ReviewPaymentView(props: ReviewPaymentViewProps): ReactElement {
    return (
        <Form>
            <div id="reviewPayment">
                <div className="reviewPayment-header">
                    <div className="reviewPayment-card">
                        <h4>Payment to&nbsp;</h4>
                        <IndexedCreditCard account={props.account} size="h4" icon={false} />
                    </div>
                    <h6>Let's make sure the details are correct before proceeding</h6>
                </div>
                {props.error !== "" ? <Alert level="error">{props.error}</Alert> : <></>}
                <div className="reviewPayment-info">
                    <div className="reviewPayment-row">
                        <p>From Account:</p>
                        <h5>
                            {props.paymentAccount?.bankName}&nbsp;
                            {props.paymentAccount?.maskedAccountNumber.replaceAll("*", "•")}
                        </h5>
                    </div>
                    <div className="reviewPayment-row">
                        <p>Amount:</p>
                        <h5>{FormatMoney(props.amount).formatted}</h5>
                    </div>
                    <div className="reviewPayment-row">
                        <p>Payment Date:</p>
                        <h5>{GetFormattedDate(props.date, true)}</h5>
                    </div>

                    {props.account.isChargedOffStatus ? (
                        <></>
                    ) : (
                        <div className="reviewPayment-alerts">
                            {!props.account.seeStatementMessage &&
                            props.account.minimumAmountDue > 0 &&
                            dayjs.tz(props.date).isAfter(props.account.paymentDueBy, "day") ? (
                                <Alert level="warning">
                                    <span>Selected Date is Past the Payment Due Date</span>
                                </Alert>
                            ) : (
                                <></>
                            )}
                        </div>
                    )}
                </div>

                {props.error === "" ? (
                    <div className="reviewPayment-acknowledgement">
                        <p>
                            <span dangerouslySetInnerHTML={{ __html: props.acknowledgement }} />
                        </p>
                        <p>
                            <span dangerouslySetInnerHTML={{ __html: props.paymentCms.ConfirmPaymentFooter }} />
                        </p>
                    </div>
                ) : (
                    <></>
                )}

                <div className="reviewPayment-buttons">
                    <ButtonPro variant="neutral" onClick={() => props.onCancel()}>
                        Cancel
                    </ButtonPro>
                    <ButtonPro variant="neutral" onClick={() => props.onEdit()}>
                        Edit
                    </ButtonPro>
                    <ButtonPro type="submit" variant="primary" disabled={!props.canContinue}>
                        Submit Payment
                    </ButtonPro>
                </div>
            </div>

            <Loading show={props.isSubmitting} />
        </Form>
    );
}

export default ReviewPaymentView;
