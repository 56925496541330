import { ReactElement, useEffect } from "react";
import { ToolTipView } from "./tooltipView";
import { useToolTipViewModel } from "./tooltipViewModel";

export type ToolTipProps = {
    id?: string;
    children: ReactElement | string;
};

export function ToolTipModel(props: ToolTipProps): ReactElement {
    const { state } = useToolTipViewModel();

    useEffect(() => {
        if (state.hoveredOver?.id === `tt-root-${state.uuid}`) {
            state.setShow(true);
        } else {
            state.setShow(false);
        }
    }, [state.hoveredOver]);

    return <ToolTipView {...props} {...state} />;
}
