import { useFormikContext } from "formik";
import { ReactElement } from "react";
import { HelpLine } from "../../../../utility";
import { CheckboxPro } from "../../../generic";
import "./ccpaOptions.scss";
import { CCPAOptionsModelProps } from "./ccpaOptionsModel";

type CCPAOptionsViewProps = CCPAOptionsModelProps;

export type CCPAOptionsFormFields = {
    categoriesSources: boolean; //Know what categories
    specificData: boolean; // Access Specific Data
    requestData: boolean; // Correct Inaccurate
    deleteData: boolean;
    deleteConfirmation: boolean;
};

export function CCPAOptionsView(props: CCPAOptionsViewProps): ReactElement {
    const formik = useFormikContext<CCPAOptionsFormFields>();

    return (
        <div id="ccpaOptions">
            <h5>Select one or more of the following requests:</h5>
            <CheckboxPro id="categoriesSources" name="categoriesSources">
                Know what categories of personal information we collected about the Consumer, the categories of sources
                from which the personal information was collected, the business or commercial purpose for collecting,
                the personal information, the categories of personal information we disclosed for a business purpose,
                and for each category identified, the categories of third parties to whom we disclosed that particular
                category of personal information.
            </CheckboxPro>
            <CheckboxPro id="specificData" name="specificData">
                Access specific pieces of personal information that we collected and maintain about the Consumer.
            </CheckboxPro>
            <CheckboxPro id="requestData" name="requestData">
                Correct inaccurate personal information we maintain about the Consumer. (Please access your account
                online or contact Customer Service at {HelpLine(props.isCorpCard)} to update any contact information on
                your account.)
            </CheckboxPro>
            <CheckboxPro id="deleteData" name="deleteData">
                Delete all personal information we have collected about the Consumer.
            </CheckboxPro>
            {formik.values["deleteData"] === true ? (
                <CheckboxPro id="deleteConfirmation" name="deleteConfirmation">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: props.cms.DeleteConfirmationText
                        }}
                    />
                </CheckboxPro>
            ) : (
                <></>
            )}
        </div>
    );
}
