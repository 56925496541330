import { ReactElement } from "react";
import { ButtonPro } from "../../../generic/inputs/buttonPro/buttonPro";
import "./paymentSuccess.scss";
import { PaymentSuccessProps } from "./paymentSuccessModel";
import { PaymentSuccessState } from "./paymentSuccessViewModel";

type PaymentSuccessViewProps = PaymentSuccessProps &
    PaymentSuccessState & {
        onClose: Function;
    };

export function PaymentSuccessView(props: PaymentSuccessViewProps): ReactElement {
    return (
        <div id="paymentSuccess">
            <div className="paymentSuccess-header">
                <h4>Payment Scheduled</h4>
                <h6>Thank you. Your payment to Card (*{props.last4}) has been scheduled.</h6>
            </div>
            <div className="paymentSuccess-body">
                <h5>Confirmation number</h5>
                <p>{props.successId}</p>
            </div>
            <ButtonPro variant="primary" onClick={() => props.onClose()}>
                Done
            </ButtonPro>
        </div>
    );
}

export default PaymentSuccessView;
