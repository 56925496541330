import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Show(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="16"
            viewBox="0 0 24 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.9998 1.25121C7.96883 1.18321 3.79983 4.00021 1.17883 6.88521C0.903711 7.19064 0.751465 7.58714 0.751465 7.99821C0.751465 8.40927 0.903711 8.80578 1.17883 9.11121C3.74283 11.9352 7.89983 14.8172 11.9998 14.7482C16.0998 14.8172 20.2578 11.9352 22.8238 9.11121C23.0989 8.80578 23.2512 8.40927 23.2512 7.99821C23.2512 7.58714 23.0989 7.19064 22.8238 6.88521C20.1998 4.00021 16.0308 1.18321 11.9998 1.25121Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.75 8.00002C15.7498 8.74166 15.5297 9.46659 15.1175 10.0831C14.7054 10.6997 14.1196 11.1802 13.4344 11.4639C12.7491 11.7475 11.9952 11.8217 11.2678 11.6768C10.5404 11.532 9.87234 11.1748 9.348 10.6503C8.82365 10.1258 8.4666 9.45765 8.32198 8.73024C8.17737 8.00284 8.25169 7.2489 8.53555 6.56373C8.81941 5.87857 9.30005 5.29295 9.91672 4.88094C10.5334 4.46893 11.2584 4.24902 12 4.24902C12.4926 4.24889 12.9804 4.34583 13.4355 4.5343C13.8905 4.72277 14.304 4.99908 14.6523 5.34743C15.0006 5.69579 15.2768 6.10935 15.4651 6.5645C15.6535 7.01964 15.7503 7.50745 15.75 8.00002Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
