import { Form, Formik } from "formik";
import { ReactElement } from "react";
import { Loading } from "../../..";
import { Alert, BrandedCard, ButtonPro } from "../../../generic";
import { InvalidSession } from "../../modals/invalidSession/invalidSession";
import { MfaEnterCodeViewProps } from "../mfaEnterCodeView";
import { OtpInput } from "../otpInput/otpInput";
import "./mfaEnterCodeCS.scss";

export function MfaEnterCodeCSView(props: MfaEnterCodeViewProps): ReactElement {
    return (
        <>
            <BrandedCard
                id="mfaEnterCodeCS"
                size={props.size}
                header="Enter Your Secure Access Code"
                subtitle={props.subtitle}
            >
                <Formik
                    initialValues={props.initialValues}
                    onSubmit={(values, actions) => {
                        props.onSubmit(values, actions);
                    }}
                >
                    {({ values, errors, isSubmitting }) => (
                        <Form className={`brandedCard-form${props.size}`} autoComplete="one-time-code">
                            <div id="mfaEnterCodeCS-form-frame-1">
                                <p>
                                    Please enter the secure access code provided by your Customer Service Associate
                                    below:
                                </p>
                            </div>
                            <div id="mfaEnterCodeCS-form-frame-2">
                                <div id="mfaEnterCodeCS-form-passcode">
                                    <OtpInput
                                        value={props.otp}
                                        valueLength={4}
                                        onChange={props.onChange}
                                        codeState={props.codeState}
                                        onClick={() => props.onOtpFail()}
                                    />
                                    {!errors.digit1 && <h6>Enter code</h6>}
                                    {errors.digit1 && <h6 className="h6-error">{errors.digit1}</h6>}
                                </div>
                                <Alert level="info">
                                    Your code has been created and will be valid for approximately 10 minutes.
                                </Alert>
                            </div>
                            <div id="mfaEnterCodeCS-form-frame-3">
                                <ButtonPro
                                    id="mfaEnterCodeCS-cancel"
                                    type="button"
                                    variant="neutral"
                                    onClick={() => props.onCancel()}
                                >
                                    Cancel
                                </ButtonPro>
                                <ButtonPro
                                    id="mfaEnterCodeCS-submit"
                                    type="submit"
                                    disabled={!props.canContinue}
                                    isSubmitting={isSubmitting}
                                >
                                    Submit
                                </ButtonPro>
                            </div>
                            <div id="mfaEnterCodeCS-form-frame-4">
                                <ButtonPro
                                    className="mfaEnterCodeCS-newCode"
                                    variant="tertiary"
                                    onClick={() => props.onRequestNew()}
                                >
                                    Need to Request Another Code?
                                </ButtonPro>
                            </div>
                            <Loading show={isSubmitting} />
                        </Form>
                    )}
                </Formik>
            </BrandedCard>
            <InvalidSession show={props.showInvalidSession} setShow={props.setShowInvalidSession} />
        </>
    );
}
