import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Download(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="24"
            height="18"
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12.001 0.75V12.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M7.50098 8.25L12.001 12.75L16.501 8.25"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.251 12.75V14.25C23.251 15.0456 22.9349 15.8087 22.3723 16.3713C21.8097 16.9339 21.0466 17.25 20.251 17.25H3.75098C2.95533 17.25 2.19227 16.9339 1.62966 16.3713C1.06705 15.8087 0.750977 15.0456 0.750977 14.25V12.75"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
