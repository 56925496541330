import { AxiosResponse } from "axios";
import { useState } from "react";
import { DocumentContents, useDocumentsContext } from "../../../../../api/backend";
import { useExpandableRowContext } from "../../../../../contexts";

type DocumentsRowViewModel = {
    hooks: DocumentsRowHooks;
    state: DocumentsRowState;
};

export type DocumentsRowHooks = {
    newRowExpanded: (index: number) => void;
    getDocument: (documentId: number) => Promise<AxiosResponse<DocumentContents, any>>;
};

export type DocumentsRowState = {
    expanded: boolean;
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    curIndex: number | undefined;
    setCurIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
    showLoading: boolean;
    setShowLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useDocumentsRowViewModel(): DocumentsRowViewModel {
    const { curIndex, setCurIndex, newRowExpanded } = useExpandableRowContext();
    const [expanded, setExpanded] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const { GetById } = useDocumentsContext();

    const getDocument = (documentId: number) => {
        return GetById(documentId);
    };

    return {
        hooks: { newRowExpanded, getDocument },
        state: { expanded, setExpanded, curIndex, setCurIndex, showLoading, setShowLoading }
    };
}
