import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

export function useBackgroundTick() {
    const [run, setRun] = useState(false);
    const [tick, setTick] = useState(0);

    useEffect(() => {
        if (run) {
            setTick(tick + 1);
        }
    }, [run]);

    useEffect(() => {
        if (run) {
            setTimeout(() => {
                setTick(tick + 1);
            }, 1000);
        }
    }, [tick]);

    function start() {
        setRun(true);
    }

    function stop() {
        setRun(false);
    }

    function restart() {
        setTick(0);
        setRun(true);
    }

    function expired(end: Dayjs): boolean {
        return dayjs() >= end;
    }

    return { tick, run, start, stop, restart, expired };
}
