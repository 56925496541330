import { AxiosResponse } from "axios";
import { UserConsentResponse } from ".";
import { UseGet } from "../../../hooks";

export const useUserConsentContext = () => {
    const client = useUserConsentClient();

    return { ...client };
};

const useUserConsentClient = () => {
    async function Get(
        onlineUserId: number,
        applicantHandle: string
    ): Promise<AxiosResponse<UserConsentResponse, any>> {
        return await UseGet<UserConsentResponse>(
            `api/v1/userconsent?onlineUserId=${onlineUserId}&applicantHandle=${applicantHandle}`
        );
    }

    return { Get };
};
