import dayjs, { Dayjs } from "dayjs";
import { ReactElement } from "react";
import { CalendarPro } from "../../../../generic/visuals/calendarPro/calendarPro";
import "./paymentCalendarOverlay.scss";

type PaymentCalendarOverlayProps = {
    date: Dayjs | undefined;
    setDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs | undefined>>;
    dueDate?: Dayjs;
    onClose: Function;
    invalidDates?: Dayjs[];
};

export function PaymentCalendarOverlay(props: PaymentCalendarOverlayProps): ReactElement {
    const past5 = dayjs.tz().hour() >= 17;
    let endDate = past5 ? 28 : 27;

    let width = (document.getElementById("schedulePayment-dateSelector") as HTMLDivElement).clientWidth;

    return (
        <div className="paymentCalendarOverlay">
            <CalendarPro
                style={{ width: width }}
                props={{
                    selectedDate: props.date,
                    selectorDateFormat: "MMMM DD YYYY",
                    dueDate: props.dueDate,
                    endDate: dayjs.tz().add(endDate, "day"),
                    onChange: props.setDate,
                    onClose: props.onClose,
                    invalidDates: props.invalidDates
                }}
            />
        </div>
    );
}
