import { ReactElement } from "react";
import { GenericModal, ModalProps } from "../../generic";

type ManageAlertsDisabledProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ManageAlertsDisabled(props: ModalProps & ManageAlertsDisabledProps): ReactElement {
    const state = {
        headerText: "Manage Alerts Not Available",
        bodyText: "This action is not available for your current card status.",
        primaryButton: {
            text: "Close",
            action: () => {
                props.setShow(false);
            }
        }
    };

    return <GenericModal show={props.show} {...state} />;
}
