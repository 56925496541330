import { useLoaderData } from "react-router";
import { CreditCardAgreement, CurrentCreditCardOffersCms } from "../../../api/backend";
import { CreditCardAgreementData } from "./creditCardAgreementLoader";
import { useContext } from "react";
import { CardNavContext } from "../../../contexts";
import { useIsLoggedIn } from "../../../hooks";

type CreditCardAgreementViewModel = {
    state: CreditCardAgreementState;
};

export type CreditCardAgreementState = {
    loggedIn: boolean;
    setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
    cms: CurrentCreditCardOffersCms;
    links: CreditCardAgreement[];
};

export function useCreditCardAgreementViewModel(): CreditCardAgreementViewModel {
    const data = useLoaderData() as CreditCardAgreementData;
    const { setShowNav } = useContext(CardNavContext);
    const { loggedIn } = useIsLoggedIn();

    return {
        state: {
            loggedIn,
            setShowNav,
            ...data
        }
    };
}
