import { createContext, useContext, useEffect, useState } from "react";
import { useSessionStorage } from "../hooks";
import { IsNullOrEmpty, NotImplemented } from "../utility";

export type DeepLinkContextProps = {
    deepLink: string;
    setDeepLink: React.Dispatch<React.SetStateAction<string>>;
};

export const DeepLinkContext = createContext<DeepLinkContextProps>({
    deepLink: "",
    setDeepLink: NotImplemented
});

export const useDeepLinkContextProps = (): DeepLinkContextProps => {
    const [deepLink, setDeepLink] = useState("");

    return {
        deepLink,
        setDeepLink
    };
};

export const useDeepLinkContext = () => {
    const context = useContext(DeepLinkContext);
    const [storage, storeValue, removeValue] = useSessionStorage(["PremierDeepLink"]);

    useEffect(() => {
        if (!IsNullOrEmpty(storage["PremierDeepLink"])) {
            context.setDeepLink(storage.PremierDeepLink);
        }
    }, []);

    function setDeepLink(value: string) {
        if (
            !["/logout", "/timeout"].includes(value.toLowerCase()) &&
            !value.toLowerCase().includes("/auth/") &&
            !value.toLowerCase().includes("/error")
        ) {
            context.setDeepLink(value);
            storeValue("PremierDeepLink", value);
        }
    }

    function clear() {
        context.setDeepLink("");
        removeValue(["PremierDeepLink"]);
    }

    return {
        deepLink: context.deepLink,
        setDeepLink,
        clear
    };
};
