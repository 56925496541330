import {
    isAndroid as checkAndroid,
    isIOS as checkIos,
    isMobile as checkMobile,
    isTablet as checkTablet,
    browserName as getBrowserName,
    browserVersion as getBrowserVersion,
    isFirefox,
    isMobileSafari,
    isSafari,
    isWindows,
    osVersion
} from "react-device-detect";

export function browserName(): string {
    return getBrowserName;
}

export function browserVersion(): string {
    return getBrowserVersion;
}

export function isWindowsPhoneBrowser(): boolean {
    return isWindows && checkMobile;
}

export function isAndroid(): boolean {
    return checkAndroid;
}

export function isIOS(): boolean {
    return checkIos;
}

export function checkOSVersion(majorV?: number, minorV?: number): boolean {
    let passVersion = true;

    if (majorV) {
        let curVersion = osVersion.split(".");

        passVersion = majorV <= Number(curVersion[0]);

        if (passVersion && minorV && curVersion.length >= 2) {
            passVersion = minorV <= Number(curVersion[1]);
        }
    }

    return passVersion;
}

export function isMobile(): boolean {
    return checkMobile;
}

export function isTablet(): boolean {
    return checkTablet;
}

export function isMobileIOs(): boolean {
    return checkMobile && checkIos;
}

export function isFirefoxOrSafari(): boolean {
    return isFirefox || isSafari || isMobileSafari;
}
