import { ReactElement } from "react";
import { ButtonPro } from "../../generic";
import { Printer } from "../../generic/icons";
import "./disclosures.scss";
import { DisclosuresTabState } from "./disclosuresViewModelTab";

type DisclosuresViewPrintPreviewProps = DisclosuresTabState & {
    onPrint: Function;
};

export function DisclosuresViewPrintPreview(props: DisclosuresViewPrintPreviewProps): ReactElement {
    return (
        <div id="disclosure" className={`disclosures${props.size} container background-grayscale-white`}>
            <div className="disclosures-page-container">
                <div className="disclosures-header-section no-print">
                    <div>
                        <ButtonPro
                            id="disclosures-button"
                            className="disclosures-print-link"
                            type="button"
                            variant="tertiary"
                            btnSize="sm"
                            onClick={() => props.onPrint()}
                        >
                            <Printer className="disclosures-statementIcon" svgClass="icon-size" />
                        </ButtonPro>
                    </div>
                </div>
                <div className="disclosures-body-container">
                    <div className="disclosures-body-header-section row d-flex">
                        <div className="disclosures-header">{props.headerText}</div>
                    </div>
                    <div className="disclosures-body-section">
                        <span className="disclosures-body-text" dangerouslySetInnerHTML={{ __html: props.bodyText }} />
                    </div>
                </div>
            </div>
        </div>
    );
}
