import { Form, Formik } from "formik";
import { ReactElement } from "react";
import { IndexedCreditCard, InputField, Loading } from "../../..";
import { FormProps } from "../../../../types";
import { BackArrow, ButtonPro, ToolTip } from "../../../generic";
import { InputPro } from "../../../generic/inputs/inputPro/inputPro";
import "./requestAuthUserForm.scss";
import { RequestAuthUserFormProps } from "./requestAuthUserFormModel";
import { RequestAuthUserFormState } from "./requestAuthUserFormViewModel";

type RequestAuthUserFormViewProps = RequestAuthUserFormProps &
    RequestAuthUserFormState & {
        onChange: Function;
        onBlur: Function;
        onSubmit: Function;
    };

export type RequestAuthUserFormFields = {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
};

function RequestAuthUserFormView(props: RequestAuthUserFormViewProps): ReactElement {
    const initalValues = { firstName: "", lastName: "", dateOfBirth: "" } as RequestAuthUserFormFields;

    return (
        <>
            <div id={`requestAuthUser${props.size}`}>
                <BackArrow available to={`/account/summary/${props.index}/home`}>
                    <h2 className="requestAuthUserHeader">Request Authorized User</h2>
                </BackArrow>

                <Formik
                    enableReinitialize
                    initialValues={initalValues}
                    initialTouched={{ firstName: false, lastName: false, dateOfBirth: false }}
                    validate={(values) => {
                        const errors: any = {};

                        if (!values.firstName) {
                            errors.firstName = "First Name cannot be blank";
                        }

                        if (!values.lastName) {
                            errors.lastName = "Last Name cannot be blank";
                        }

                        return errors;
                    }}
                    onSubmit={(values, actions) => {
                        props.onSubmit(values, actions);
                    }}
                >
                    {(formProps: FormProps<RequestAuthUserFormFields>) => (
                        <Form className={`requestAuthUserForm${props.size}`} autoComplete="off">
                            <div className="requestAuthUserForm-headerSubTitle">
                                <IndexedCreditCard account={props.account} size={"h4"} />
                            </div>

                            <span
                                className="requestAuthUserForm-headerText"
                                dangerouslySetInnerHTML={{ __html: props.bodyText }}
                            />
                            <h5 className="requestAuthUserForm-header-title">Authorized User Information</h5>

                            <div className="requestAuthUserForm-inputPair">
                                <InputPro
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    label="First Name"
                                    placeholder="First Name"
                                    mask={/^[a-z .-]{0,15}$/im}
                                    error={formProps.touched.firstName ? formProps.errors.firstName : ""}
                                    onChange={props.onChange(formProps)}
                                    onBlur={() => {
                                        props.onBlur(formProps);
                                    }}
                                >
                                    <ToolTip>
                                        If First Name is over 15 characters, consider abbreviating or using a nickname
                                        instead.
                                    </ToolTip>
                                </InputPro>
                                {props.focused.firstName ? (
                                    <ul className="requestAuthUserForm-inputTips">
                                        <li className="requestAuthUserForm-tip">
                                            First Name can contain letters (a-z, A-Z), spaces, hyphens (-), and periods
                                            (.)
                                        </li>
                                    </ul>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className="requestAuthUserForm-inputPair">
                                <InputPro
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    label="Last Name"
                                    placeholder="Last Name"
                                    mask={/^[a-z .-]{0,20}$/im}
                                    error={formProps.touched.lastName ? formProps.errors.lastName : ""}
                                    onChange={props.onChange(formProps)}
                                    onBlur={() => {
                                        props.onBlur(formProps);
                                    }}
                                >
                                    <ToolTip>If Last Name is over 20 characters, consider abbreviating.</ToolTip>
                                </InputPro>
                                {props.focused.lastName ? (
                                    <ul className="requestAuthUserForm-inputTips">
                                        <li className="requestAuthUserForm-tip">
                                            Last Name can contain letters (a-z, A-Z), spaces, hyphens (-), and periods
                                            (.)
                                        </li>
                                    </ul>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className="requestAuthUserForm-dateOfBirth">
                                <InputField
                                    id="dateOfBirth"
                                    label="Date of Birth"
                                    placeholder="MM/DD/YYYY"
                                    mask={"99/99/9999"}
                                    inputMode="numeric"
                                    error={formProps.errors.dateOfBirth}
                                    onChange={props.onChange(formProps)}
                                    onBlur={() => {
                                        props.onBlur(formProps);
                                    }}
                                />
                            </div>

                            <span
                                className="requestAuthUserForm-footerText"
                                dangerouslySetInnerHTML={{ __html: props.footerText }}
                            />

                            <div className="requestAuthUserForm-buttons">
                                <ButtonPro id="request" type="submit" disabled={!props.canSubmit}>
                                    Request
                                </ButtonPro>
                            </div>

                            <Loading show={formProps.isSubmitting} />
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}

export default RequestAuthUserFormView;
