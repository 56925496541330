import { ReactElement } from "react";
import { ExpandableRowContext } from "../../../contexts";
import { GenericModalProps } from "../../generic";
import PaymentScheduledView from "./paymentScheduledView";
import { usePaymentScheduledViewModel } from "./paymentScheduledViewModel";

export function PaymentScheduledModel(): ReactElement {
    const { context, state } = usePaymentScheduledViewModel();

    const successModal = {
        headerText: "Payment Canceled",
        bodyText: "You have successfully canceled your payment",
        primaryButton: {
            text: "Close",
            action: () => {
                state.setShowSuccess(false);
            }
        }
    } as GenericModalProps;

    return (
        <ExpandableRowContext.Provider value={{ ...context }}>
            <PaymentScheduledView {...state} successModal={successModal} />
        </ExpandableRowContext.Provider>
    );
}
