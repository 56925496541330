import { useRef, useState } from "react";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { ButtonProProps, ButtonVariant } from "./buttonPro";

type ButtonProViewModel = {
    state: ButtonProState;
};

export type ButtonProState = {
    size: Size;
    clickBlock: boolean;
    setClickBlock: React.Dispatch<React.SetStateAction<boolean>>;
    timer: React.MutableRefObject<NodeJS.Timeout | undefined>;
    variant: ButtonVariant;
};

export function useButtonProViewModel(props: ButtonProProps): ButtonProViewModel {
    let { size } = useViewport(false, true);
    const [clickBlock, setClickBlock] = useState(false);
    const timer = useRef(undefined as NodeJS.Timeout | undefined);
    /* istanbul ignore next */
    const variant = props.variant ?? "primary";

    if (props.btnSize)
        switch (props.btnSize) {
            case "sm":
                size = "-mobile";
                break;
            case "md":
                size = "-tablet";
                break;
            case "lg":
            default:
                size = "";
        }

    return {
        state: { size, clickBlock, setClickBlock, timer, variant }
    };
}
