import { AxiosResponse } from "axios";
import { useState } from "react";
import { BankAccountPostResponse, usePaymentAccountContext, usePaymentContext } from "../../../../api/backend";
import { ReviewPaymentAccountProps } from "./reviewPaymentAccountModel";

export type ReviewPaymentAccountViewModel = {
    state: ReviewPaymentAccountState;
    api: ReviewPaymentAccountApi;
};

export type ReviewPaymentAccountState = {
    acknowledgement: string;
    warning: string;
    setWarning: React.Dispatch<React.SetStateAction<string>>;
    error: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    canContinue: boolean;
    setCanContinue: React.Dispatch<React.SetStateAction<boolean>>;
};

type ReviewPaymentAccountApi = {
    Post: (routingNumber: string, checkingNumber: string) => Promise<AxiosResponse<BankAccountPostResponse, any>>;
};

const useReviewPaymentAccountState = (props: ReviewPaymentAccountProps): ReviewPaymentAccountState => {
    const { paymentCms } = usePaymentContext();
    const [error, setError] = useState("");
    const [warning, setWarning] = useState("");
    const [canContinue, setCanContinue] = useState(true);

    const acknowledgement = paymentCms.ConfirmAccountFooter.replaceAll(
        "[[CHECKINGACCOUNTNUMBER]]",
        props.checkingNumber.slice(-4)
    );

    return {
        acknowledgement,
        warning,
        setWarning,
        error,
        setError,
        canContinue,
        setCanContinue
    };
};

const useReviewPaymentAccountApi = (): ReviewPaymentAccountApi => {
    const { Post: Post_Api } = usePaymentAccountContext();

    async function Post(
        routingNumber: string,
        checkingNumber: string
    ): Promise<AxiosResponse<BankAccountPostResponse, any>> {
        return await Post_Api({
            abaNumber: routingNumber,
            accountNumber: checkingNumber
        });
    }

    return {
        Post
    };
};

export function useReviewPaymentAccountViewModel(props: ReviewPaymentAccountProps): ReviewPaymentAccountViewModel {
    const state = useReviewPaymentAccountState(props);
    const api = useReviewPaymentAccountApi();

    return {
        state,
        api
    };
}
