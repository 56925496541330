import { ReactElement } from "react";
import { UserAccount } from "../../../api/backend";
import { HelpLine } from "../../../utility";
import { GenericModal, ModalProps } from "../../generic";

type LockCardDisabledProps = {
    account: UserAccount;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export function LockCardDisabled(props: ModalProps & LockCardDisabledProps): ReactElement {
    const state = {
        headerText: "Lock Card is Temporarily Unavailable",
        bodyText: "Lock Card is temporarily unavailable. Please check back later.",
        alertText: `If you've lost your card or need a replacement, call our Customer Service team at ${HelpLine(props.account.isCorpCard)}`,
        primaryButton: {
            text: "Close",
            action: () => {
                props.setShow(false);
            }
        }
    };

    return <GenericModal show={props.show} {...state} />;
}
