import { Form, Formik } from "formik";
import { ChangeEvent, ReactElement } from "react";
import {
    CancelEnrollSetup,
    InvalidEnrollSession,
    Loading,
    PasswordCriteria,
    PasswordValidation,
    UsernameCriteria,
    UsernameValidation
} from "../..";
import { FormProps } from "../../../types";
import { Alert, ButtonPro, EmailInput, PasswordInput, UsernameInput } from "../../generic";
import { LoginCard } from "../common/loginCardModel";
import "./enrollCredentials.scss";
import { EnrollCredentialsState } from "./enrollCredentialsViewModel";

type EnrollCredentialsViewProps = EnrollCredentialsState & {
    onUsernameChange: Function;
    onPasswordChanged: Function;
    onEmailChanged: Function;
    onCancel: Function;
    onSubmit: Function;
};

export type EnrollCredentialsFields = {
    username: string;
    password: string;
    confirm: string;
    email: string;
    usernameCriteria: UsernameCriteria;
    passwordCriteria: PasswordCriteria;
    emailCriteria: EmailCriteria;
};

export type EmailCriteria = {
    valid?: boolean;
};

export function EnrollCredentialsView(props: EnrollCredentialsViewProps): ReactElement {
    let initialValues = {
        username: "",
        password: "",
        confirm: "",
        email: "",
        usernameCriteria: { length: undefined, alphaNumeric: undefined, noSpecial: undefined, hasError: undefined },
        passwordCriteria: {
            length: undefined,
            uppercase: undefined,
            lowercase: undefined,
            numericSpecial: undefined,
            confirmed: undefined,
            hasError: undefined
        },
        emailCriteria: {
            valid: undefined
        }
    };

    return (
        <LoginCard
            id="enrollCredentials"
            size={props.size}
            header="Set Up Online Credentials"
            subtitle={props.subtitle}
        >
            <Formik
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                    props.onSubmit(values, actions);
                }}
            >
                {(formProps: FormProps<EnrollCredentialsFields>) => (
                    <Form className={`loginCard-form${props.size}`} autoComplete="off">
                        <div className="enrollCredentials-group">
                            <UsernameInput
                                label="Desired Username"
                                placeholder="Create a username"
                                touched={formProps.touched.username}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    props.onUsernameChange(e.currentTarget.value, formProps.values);
                                    formProps.handleChange(e);
                                }}
                                error={props.usernameError}
                            />
                            {(props.activeElement?.id === "username" || formProps.values.usernameCriteria.hasError) &&
                            !props.usernameError ? (
                                <UsernameValidation
                                    touched={props.usernameTouched}
                                    criteria={formProps.values.usernameCriteria}
                                />
                            ) : (
                                <></>
                            )}
                        </div>

                        <PasswordInput
                            id="password"
                            label="Password"
                            placeholder="Enter password"
                            stateValue={{
                                isPrivate: true,
                                canPeek: true
                            }}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                props.onPasswordChanged(e.currentTarget.value, "password", formProps.values);
                                formProps.handleChange(e);
                            }}
                        />
                        <div className="enrollCredentials-group">
                            <PasswordInput
                                id="confirm"
                                label="Confirm Password"
                                placeholder="Re-enter password"
                                stateValue={{
                                    isPrivate: true,
                                    canPeek: true
                                }}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    props.onPasswordChanged(e.currentTarget.value, "confirmPassword", formProps.values);
                                    formProps.handleChange(e);
                                }}
                            />
                            {props.activeElement?.id === "password" ||
                            props.activeElement?.id === "confirm" ||
                            formProps.values.passwordCriteria.hasError ? (
                                <PasswordValidation
                                    touched={props.passwordTouched}
                                    confirmTouched={props.confirmTouched}
                                    criteria={formProps.values.passwordCriteria}
                                />
                            ) : (
                                <></>
                            )}
                        </div>

                        <EmailInput
                            label="Email"
                            placeholder="Enter your email address"
                            touched={formProps.touched.email}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                props.onEmailChanged(e.currentTarget.value, formProps.values);
                                formProps.handleChange(e);
                            }}
                            error={props.emailError}
                        />

                        {props.activeElement?.id === "email" || props.emailTouched ? (
                            <Alert level="info">
                                By providing your email address, you agree to receive electronic correspondence and/or
                                advertisements from First PREMIER® Bank and PREMIER Bankcard®, LLC.
                            </Alert>
                        ) : (
                            <></>
                        )}

                        <h6 className="enrollCredentials-footnote">All fields are required</h6>

                        <div className="loginCard-buttons">
                            <ButtonPro
                                id={`enrollCredentials-cancel${props.size}`}
                                type="button"
                                variant="neutral"
                                onClick={() => props.onCancel()}
                            >
                                Cancel
                            </ButtonPro>
                            <ButtonPro
                                id={`enrollCredentials-submit${props.size}`}
                                type="submit"
                                disabled={!props.canContinue}
                                isSubmitting={formProps.isSubmitting}
                            >
                                Next
                            </ButtonPro>
                        </div>

                        <CancelEnrollSetup show={props.showModal} setShow={props.setShowModal} />
                        <InvalidEnrollSession show={props.showError} setShow={props.setShowError} />

                        <Loading show={formProps.isSubmitting} />
                    </Form>
                )}
            </Formik>
        </LoginCard>
    );
}
