import { FormikProps } from "formik";
import { ReactElement, useEffect } from "react";
import { ValidationErrors } from "../../../../api/backend";
import { IsNullOrEmpty } from "../../../../utility";
import { EnterPaymentAccountProps } from "./enterPaymentAccountModel";
import EnterPaymentAccountView from "./enterPaymentAccountView";
import { EnterPaymentAccountFormFields, EnterPaymentAccountViewModel } from "./enterPaymentAccountViewModel";

type EnterPaymentAccountFormProps = {
    props: EnterPaymentAccountProps;
    vm: EnterPaymentAccountViewModel;
    formProps: FormikProps<EnterPaymentAccountFormFields>;
};

export function EnterPaymentAccountForm(form: EnterPaymentAccountFormProps): ReactElement {
    const { props, formProps } = { ...form };
    const { state, api } = { ...form.vm };

    const canContinue =
        Object.values(formProps.values).every((x) => !IsNullOrEmpty(x)) &&
        Object.values(formProps.errors).every((x) => IsNullOrEmpty(x)) &&
        IsNullOrEmpty(state.routingError);

    useEffect(() => {
        document.getElementById("routingNumber")?.focus();
    }, []);

    useEffect(() => {
        if (props.routingNumber.length === 9) {
            document.getElementById("checkingNumber")?.focus();
        }
    }, [props.routingNumber]);

    const validateRouting = () => {
        if (props.routingNumber.length === 9 && props.routingNumber !== state.routingSent) {
            state.setLoading(true);
            api.getBankName(props.routingNumber)
                .then((res) => {
                    props.setBankName(res.data.bankName);
                    state.setRoutingError("");
                })
                .catch((err) => {
                    if (err.response?.status === 400) {
                        let errors = err.response?.data as ValidationErrors;
                        let errorField = Object.keys(errors.errors)[0];
                        state.setRoutingError(errors.errors[errorField][0]);
                    }
                })
                .finally(() => {
                    state.setRoutingSent(props.routingNumber);
                    state.setLoading(false);
                });
        }
    };

    const onChangeConfirm = () => {
        if (
            !IsNullOrEmpty(formProps.values.checkingNumber) &&
            formProps.values.checkingNumber === formProps.values.confirmNumber
        ) {
            formProps.touched.confirmNumber = true;
        }
    };

    const onCancel = () => {
        props.setRoutingNumber("");
        props.setCheckingNumber("");
        props.setConfirmNumber("");
        props.setBankName("");
        props.setShow(false);

        if (props.callback !== undefined) {
            props.callback();
        }
    };

    return (
        <EnterPaymentAccountView
            {...props}
            {...state}
            {...formProps}
            validateRouting={validateRouting}
            onChangeConfirm={onChangeConfirm}
            canContinue={canContinue}
            onCancel={onCancel}
        />
    );
}
