import { createContext, useState } from "react";
import { AppSettings } from ".";
import { NotImplemented } from "../../../utility";

export type SettingsContextProps = {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    appSettings: AppSettings;
    setAppSettings: React.Dispatch<React.SetStateAction<AppSettings>>;
};

export const SettingsContext = createContext<SettingsContextProps>({
    loading: false,
    setLoading: NotImplemented,
    appSettings: {} as AppSettings,
    setAppSettings: NotImplemented
});

export const useSettingsContextProps = (): SettingsContextProps => {
    const [loading, setLoading] = useState(false);
    const [appSettings, setAppSettings] = useState({} as AppSettings);

    return {
        loading,
        setLoading,
        appSettings,
        setAppSettings
    };
};
