import { ISelection } from "../interfaces/iSelection";

export function setInputSelection(input: any, start: number, end: number): void {
    if ("selectionStart" in input && "selectionEnd" in input) {
        input.selectionStart = start;
        input.selectionEnd = end;
    } else {
        const range = input.createTextRange();
        range.collapse(true);
        range.moveStart("character", start);
        range.moveEnd("character", end - start);
        range.select();
    }
}

export function getInputSelection(input: any): ISelection {
    let start = 0;
    let end = 0;

    if ("selectionStart" in input && "selectionEnd" in input) {
        start = input.selectionStart;
        end = input.selectionEnd;
    } else {
        const range = (document as any).selection.createRange();
        if (range.parentElement() === input) {
            start = -range.moveStart("character", -input.value.length);
            end = -range.moveEnd("character", -input.value.length);
        }
    }

    return {
        start,
        end,
        length: end - start
    };
}
