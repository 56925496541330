import { Field } from "formik";
import { ReactElement } from "react";
import "./checkboxPro.scss";

type CheckboxProProps = any & {
    id: string;
    name: string;
    label?: string;
    checked?: boolean;
    onChecked?: Function;
    disabled?: boolean;
    children?: any;
};

export function CheckboxPro(props: CheckboxProProps): ReactElement {
    const { children, ...fieldProps } = props;

    return (
        <div className="checkboxPro">
            <Field type="checkbox" {...fieldProps} />
            {props.label !== undefined ? (
                <label htmlFor={props.id}>{props.label}</label>
            ) : props.children !== undefined ? (
                <label htmlFor={props.id}>{props.children}</label>
            ) : (
                <></>
            )}
        </div>
    );
}
