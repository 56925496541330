import { ReactElement } from "react";
import { SeeStatementMessageAlert } from "../../..";
import { FormatMoney, GetFormattedDate } from "../../../../utility";
import { ButtonPro, ToolTip } from "../../../generic";
import "../../cardSummary/cardSummaryCommon.scss";
import "./cardPayment.scss";
import { CardPaymentProps } from "./cardPaymentModel";
import { CardPaymentState } from "./cardPaymentViewModel";

export type CardPaymentViewProps = CardPaymentProps &
    CardPaymentState & {
        makeNewPayment: Function;
    };

export function CardPaymentView(props: CardPaymentViewProps): ReactElement {
    return (
        <div className={`cardSummaryCommon${props.size} cardTilePadding`}>
            {props.account.isChargedOffStatus ? (
                <CardPaymentViewChargedOff {...props} />
            ) : (
                <CardPaymentViewNormal {...props} />
            )}
        </div>
    );
}

export function CardPaymentViewNormal(props: CardPaymentViewProps): ReactElement {
    return (
        <div className="cardSummaryCommon-card">
            <div className="cardSummaryCommon-header-row">
                <h2 className="cardSummaryCommon-header">Total Minimum Amount Due</h2>
                <ToolTip>{props.tooltip.BodyText}</ToolTip>
            </div>
            {props.account.seeStatementMessage ? (
                <div className="cardSummary-info-container">
                    <SeeStatementMessageAlert />
                </div>
            ) : (
                <>
                    <div className="cardSummaryCommon-content-col">
                        <div className="cardSummaryCommon-content-row">
                            <div className="cardSummaryCommon-amount-dollar">${props.dollars}</div>
                            <div className="cardSummaryCommon-amount-cents">{props.cents}</div>
                        </div>
                        <label id="cardPayment-dueDate">
                            Payment Due Date: {GetFormattedDate(props.account.paymentDueBy, true)}
                        </label>
                    </div>
                </>
            )}
            {props.paymentActions ? (
                <ButtonPro
                    className="cardPayment-button"
                    btnSize="lg"
                    onClick={() => props.makeNewPayment(props.index)}
                >
                    Make a Payment
                </ButtonPro>
            ) : (
                <></>
            )}
        </div>
    );
}

function CardPaymentViewChargedOff(props: CardPaymentViewProps): ReactElement {
    let { dollars, cents } = FormatMoney(props.account.currentBalance, true);

    return (
        <div className="cardSummaryCommon-card">
            <div className="cardSummaryCommon-header-row">
                <h2 className="cardSummaryCommon-header">Current Balance</h2>
            </div>
            <div className="cardSummaryCommon-content-col">
                <div className="cardSummaryCommon-content-row">
                    <div className="cardSummaryCommon-amount-dollar">${dollars}</div>
                    <div className="cardSummaryCommon-amount-cents">{cents}</div>
                </div>
                {props.account.currentBalance <= 0 ? (
                    <label id="cardPayment-dueDate"></label>
                ) : (
                    <label id="cardPayment-dueDate">The Current Balance is due</label>
                )}
            </div>
            {props.paymentActions ? (
                <ButtonPro
                    className="cardPayment-button"
                    btnSize="lg"
                    onClick={() => props.makeNewPayment(props.index)}
                    disabled={props.account.currentBalance <= 0}
                    data-testid="button"
                >
                    Make a Payment
                </ButtonPro>
            ) : (
                <></>
            )}
        </div>
    );
}
