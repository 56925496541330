import { AxiosResponse } from "axios";
import { BankAccountNameResponse } from ".";
import { useApiQueueContext } from "../../../contexts";
import { useIdentityConnectContext } from "../../identityServer";

export const useBankAccountNameContext = () => {
    const client = useBankAccountNameClient();

    return { ...client };
};

const useBankAccountNameClient = () => {
    const apiQueue = useApiQueueContext();
    const { access_token } = useIdentityConnectContext();

    async function Get(abaNumber: string): Promise<AxiosResponse<BankAccountNameResponse, any>> {
        return await apiQueue.Get(`api/v1/bankaccountname?abaNumber=${abaNumber}`, access_token);
    }

    return {
        Get
    };
};
