import { AxiosResponse } from "axios";
import { useContext } from "react";
import { BankAccountGetResponse, BankAccountPostRequest, BankAccountPostResponse } from ".";
import { UseDelete, UseGet, UsePost } from "../../../hooks";
import { useIdentityConnectContext } from "../../identityServer";
import { PaymentAccountContext } from "./paymentAccountContext";

export const usePaymentAccountContext = () => {
    const context = useContext(PaymentAccountContext);
    const client = usePaymentAccountClient();

    return { ...context, ...client };
};

const usePaymentAccountClient = () => {
    const context = useContext(PaymentAccountContext);
    const { access_token } = useIdentityConnectContext();

    async function Get(): Promise<AxiosResponse<BankAccountGetResponse[], any>> {
        return await UseGet<BankAccountGetResponse[]>("api/v1/paymentaccount", access_token).then((x) => {
            let pAccounts = x.data.sort((a, b) => b.bankAccountId - a.bankAccountId);
            context.setPaymentAccounts(pAccounts);
            return x;
        });
    }

    async function Post(request: BankAccountPostRequest): Promise<AxiosResponse<BankAccountPostResponse, any>> {
        return await UsePost<BankAccountPostResponse, BankAccountPostRequest>(
            "api/v1/paymentaccount",
            access_token,
            request
        ).then((x) => {
            Get();
            return x;
        });
    }

    async function Delete(bankAccountId: number): Promise<AxiosResponse<any, any>> {
        return await UseDelete(`api/v1/paymentaccount?bankAccountId=${bankAccountId}`, access_token).then((x) => {
            Get();
            return x;
        });
    }

    return {
        Get,
        Post,
        Delete
    };
};
