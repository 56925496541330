import { ReactElement, useEffect } from "react";
import { SetTitle } from "../../../../utility";
import { ButtonPro } from "../../../generic";
import { CCPAAnonymousModel } from "../../ccpa/ccpaForm/ccpaAnonymous/ccpaAnonymousModel";
import { CCPAAuthenticatedModel } from "../../ccpa/ccpaForm/ccpaAuthenticated/ccpaAuthenticatedModel";
import "./ccpaHome.scss";
import { CCPAHomeState } from "./ccpaHomeViewModel";

type CCPAHomeViewProps = CCPAHomeState & {
    onLogin: Function;
    onNoAccount: Function;
};

export function CCPAHomeView(props: CCPAHomeViewProps): ReactElement {
    useEffect(() => {
        SetTitle(props.cms.PageTitle);

        return () => SetTitle("");
    }, []);

    return (
        <div className="ccpaHome-outer">
            <div className="ccpaHome-titleBox">
                <h2 className="ccpaHome-title">{props.cms ? props.cms.PageTitle : ""}</h2>
            </div>
            <div className="ccpaHome-bodyText">{props.cms ? props.cms.BodyText : ""}</div>

            {!props.loggedIn && !props.isAnonymous ? (
                <CCPAHome {...props} />
            ) : props.loggedIn ? (
                <CCPAAuthenticatedModel {...props} />
            ) : (
                <CCPAAnonymousModel {...props} />
            )}
        </div>
    );
}

function CCPAHome(props: CCPAHomeViewProps): ReactElement {
    return (
        <div className="ccpaHome">
            <div className="ccpaHome-body">
                To complete the CCPA Consumer Request Form, please log in if you have an online account. If you do not
                have an online account, proceed without account.
            </div>
            <div id={`ccpaHome-Buttons${props.size}`}>
                <ButtonPro
                    className={`ccpaHome-cancel${props.size}`}
                    type="button"
                    variant="primary"
                    onClick={() => props.onLogin()}
                >
                    Log In
                </ButtonPro>
                <ButtonPro
                    className={`ccpaHome-continue${props.size}`}
                    type="submit"
                    variant="secondary"
                    onClick={() => props.onNoAccount()}
                >
                    Proceed Without Account
                </ButtonPro>
            </div>
        </div>
    );
}
