import { ReactElement } from "react";
import { Icon } from "./icon";
import { IconProps } from "./iconProps";

export function Notifications(props: IconProps): ReactElement {
    const { id, svgClass, ...iProps } = props;

    const svg = (
        <svg
            id={id}
            className={svgClass}
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.0349 8.66504C12.4394 10.1063 10.3001 12.2465 8.85986 14.8425"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.9375 1.75C10.2737 2.50735 7.84774 3.93289 5.88975 5.89132C3.93176 7.84975 2.50675 10.2761 1.75 12.94"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M9.25 43H46.75" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M46.75 43C46.75 43 43 39.735 43 28C43 18.625 36.595 10.33 28 10.33C19.405 10.33 13 18.625 13 28C13 39.7975 9.25 43 9.25 43"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31.75 50.5C31.75 51.4946 31.3549 52.4484 30.6517 53.1516C29.9484 53.8549 28.9946 54.25 28 54.25C27.0054 54.25 26.0516 53.8549 25.3483 53.1516C24.6451 52.4484 24.25 51.4946 24.25 50.5"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M28 10.33V5.5" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M40.9648 8.66504C42.2541 9.38486 43.4385 10.2782 44.4848 11.32C45.5284 12.3658 46.422 13.5513 47.1398 14.8425"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M43.0625 1.75C45.7263 2.50735 48.1523 3.93289 50.1103 5.89132C52.0682 7.84975 53.4932 10.2761 54.25 12.94"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return <Icon props={{ svg: svg, ...iProps }} />;
}
