import { ReactElement } from "react";
import { Size } from "../../../../../types";
import "./documentsInfo.scss";
import { Alert } from "../../../../generic";
import { LettersCms } from "../../../../../api/backend";

export type DocumentsInfoProps = {
    size: Size;
    cms: LettersCms;
};

export function DocumentsInfo(props: DocumentsInfoProps): ReactElement {
    return (
        <div id={`documentsInfo${props.size}`}>
            <div className="documentsInfo-header">
                <div className="documentsInfo-content">
                    <span
                        className="requestCLIForm-bodyText"
                        dangerouslySetInnerHTML={{ __html: props.cms ? props.cms.BodyText : "" }}
                    />
                </div>
                <Alert level={"info"} text={"Documents are available for 90 days"} />
            </div>
        </div>
    );
}

export default DocumentsInfo;
