import { ReactElement, useEffect } from "react";
import { DocumentDetails } from "../../../../../api/backend";
import { isMobileIOs } from "../../../../../utility/device";
import { DocumentsRowView } from "./documentsRowView";
import { useDocumentsRowViewModel } from "./documentsRowViewModel";

export type DocumentsRowProps = {
    index: number;
    document: DocumentDetails;
    rowViewed: number;
    setRowViewed: React.Dispatch<React.SetStateAction<number>>;
};

export function DocumentsRow(props: DocumentsRowProps): ReactElement {
    const { hooks, state } = useDocumentsRowViewModel();

    useEffect(() => {
        state.setExpanded(props.index === state.curIndex);
    }, [state.curIndex]);

    function downloadPDF() {
        state.setShowLoading(true);
        hooks
            .getDocument(props.document.documentId)
            .then((res) => {
                var byteCharacters = atob(res.data.pdfDocument.toString());
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: "application/pdf;base64" });
                var fileURL = URL.createObjectURL(file);
                const downloadLink = document.createElement("a");
                let statementDate = new Date(props.document.documentDate);
                var month = statementDate.getMonth() + 1;
                var day = statementDate.getDate();
                var year = statementDate.getFullYear();
                const fileName = `${year}-${month}-${day} Statement.pdf`;
                downloadLink.href = fileURL;
                downloadLink.download = fileName;
                downloadLink.click();
            })
            .finally(() => {
                if (props.document.status === "NEW") {
                    updateStatusToRead();
                }
                state.setShowLoading(false);
            });
    }

    function openPDF() {
        if (isMobileIOs()) {
            downloadPDF();
        } else {
            state.setShowLoading(true);
            hooks
                .getDocument(props.document.documentId)
                .then((res) => {
                    var byteCharacters = atob(res.data.pdfDocument.toString());
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var file = new Blob([byteArray], { type: "application/pdf;base64" });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                })
                .finally(() => {
                    if (props.document.status === "NEW") {
                        updateStatusToRead();
                    }
                    updateStatusToRead();
                    state.setShowLoading(false);
                });
        }
    }

    function updateStatusToRead() {
        props.setRowViewed(props.document.documentId);
    }

    return (
        <DocumentsRowView
            {...props}
            {...state}
            {...hooks}
            downloadPDF={downloadPDF}
            openPDF={openPDF}
            newRowExpanded={hooks.newRowExpanded}
        />
    );
}
