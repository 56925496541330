import { ReactElement } from "react";
import { ButtonPro } from "../../generic";
import { ArrowRight, Exit, Warning } from "../../generic/icons";
import "./prominentAlert.scss";
import { ProminentAlertState } from "./prominentAlertViewModel";

type ProminentAlertViewProps = ProminentAlertState & {
    dismiss: Function;
};

export function ProminentAlertView(props: ProminentAlertViewProps): ReactElement {
    return (
        <>
            {props.dismissed ? (
                <></>
            ) : (
                <div id="prominentAlert" role="alert">
                    <div className="prominentAlert-tab" />
                    <div className="prominentAlert-content">
                        <Warning className="prominentAlert-icon" />
                        <div className="prominentAlert-wrapper">
                            <div className="prominentAlert-header">
                                <h5>{props.alert?.ConsumerAlertTitle}</h5>
                                <Exit className="prominentAlert-exit" onClick={() => props.dismiss()} />
                            </div>

                            <p className={`prominentAlert-body-wrap${!props.more ? "" : "-more"}`}>
                                <span
                                    id="prominentAlert-body"
                                    data-testid="prominentAlert-body"
                                    dangerouslySetInnerHTML={{
                                        __html: props.alert?.ConsumerAlertBodyText ?? ""
                                    }}
                                />
                            </p>
                            {props.truncated ? (
                                <ButtonPro
                                    className="prominentAlert-expand"
                                    variant="neutral"
                                    unblock
                                    onClick={() => props.setMore(!props.more)}
                                >
                                    <h6 className="prominentAlert-more">{!props.more ? "Read More" : "Read Less"}</h6>
                                    <ArrowRight className="prominentAlert-arrow" />
                                </ButtonPro>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
