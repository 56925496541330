import { ReactElement } from "react";
import { Modal, ModalProps } from "../../../generic";
import { SplitDeliveryPromptView } from "./splitDeliveryPromptView";
import { useSplitDeliveryPromptViewModel } from "./splitDeliveryPromptViewModel";

export type SplitDeliveryPromptProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowTerms: React.Dispatch<React.SetStateAction<boolean>>;
    setShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
    setGoPaperless: React.Dispatch<React.SetStateAction<boolean>>;
    unEnrollCallback: Function;
};

export function SplitDeliveryPrompt(props: ModalProps & SplitDeliveryPromptProps): ReactElement {
    return (
        <Modal show={props.show}>
            <SplitDeliveryPromptModel {...props} />
        </Modal>
    );
}

function SplitDeliveryPromptModel(props: SplitDeliveryPromptProps): ReactElement {
    const { hooks, state } = useSplitDeliveryPromptViewModel();

    function onPaper() {
        hooks.storeValue("deliveryLandingPrompted", true);
        props.setGoPaperless(false);

        props.unEnrollCallback();

        props.setShow(false);
        props.setShowConfirmation(true);
    }

    function onPaperless() {
        hooks.storeValue("deliveryLandingPrompted", true);
        props.setGoPaperless(true);
        props.setShow(false);
        props.setShowTerms(true);
    }

    return <SplitDeliveryPromptView {...props} {...state} onPaper={onPaper} onPaperless={onPaperless} />;
}
