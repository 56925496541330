import { ReactElement } from "react";
import { ReviewCancelPayment } from ".";
import { AsyncElement, ButtonPro, GenericModal, GenericModalProps } from "../../generic";
import { ArrowDown, ArrowUp } from "../../generic/icons";
import "./paymentScheduled.scss";
import { PaymentsScheduledState } from "./paymentScheduledViewModel";

type PaymentScheduledViewProps = PaymentsScheduledState & {
    successModal: GenericModalProps;
};

function PaymentScheduledView(props: PaymentScheduledViewProps): ReactElement {
    return (
        <div id="paymentScheduled">
            <div className="paymentScheduled-header">
                <h5>Scheduled Payments</h5>
            </div>
            <AsyncElement loading={props.loading}>
                <div className="paymentScheduled-list" data-testid="paymentScheduled-list">
                    {props.rows.length > 0 ? (
                        props.rows
                    ) : (
                        <div className="paymentScheduled-noList">
                            <h5>No scheduled payments</h5>
                        </div>
                    )}
                </div>
            </AsyncElement>
            <div className="paymentScheduled-footer" data-testid={"viewMore Button"}>
                {props.scheduledPayments.length > 3 ? (
                    <ButtonPro
                        variant="neutral"
                        className="paymentScheduled-footer-more"
                        unblock
                        onClick={() => props.setSeeMore(!props.seeMore)}
                    >
                        <h6>View {!props.seeMore ? "More" : "Less"}</h6>
                        {!props.seeMore ? <ArrowDown /> : <ArrowUp />}
                    </ButtonPro>
                ) : (
                    <p></p>
                )}
                <p>
                    {props.scheduledPayments.length} result{props.scheduledPayments.length !== 1 ? "s" : ""}
                </p>
            </div>
            <ReviewCancelPayment
                show={props.showCancel}
                setShow={props.setShowCancel}
                setShowSuccess={props.setShowSuccess}
                cancelPayment={props.cancelPayment}
                setCancelPayment={props.setCancelPayment}
            />
            <GenericModal show={props.showSuccess} {...props.successModal} />
        </div>
    );
}

export default PaymentScheduledView;
