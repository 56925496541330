import { BrowserMessageCms, BrowserVersionCms, useLoaderCms } from "../../../api/backend";
import { browserName, browserVersion } from "../../../utility/device";

export type BrowserCompatibilityData = {
    cms: BrowserMessageCms;
    current: boolean;
    supported: boolean;
};

export async function BrowserCompatibilityLoader(): Promise<BrowserCompatibilityData> {
    const { BrowserMessage, BrowserVersion } = useLoaderCms();

    const cms = (await BrowserMessage()).data.BrowserCompatibilityWarning;
    const browsers = (await BrowserVersion()).data;
    const current = isBrowserCurrent(browsers.GlobalBrowserVersionConfiguration.BrowserVersions);
    const supported = isBrowserSupported(browsers.GlobalBrowserVersionConfiguration.BrowserVersions);

    const data = { cms, current, supported };

    return data;
}

function isBrowserSupported(browsers: BrowserVersionCms[]): boolean {
    return browsers.find((b) => b.Active && b.BrowserFamily === browserName()) !== undefined;
}

function isBrowserCurrent(browsers: BrowserVersionCms[]): boolean {
    var matchingBrowser = browsers.find((b) => b.Active && b.BrowserFamily === browserName());

    if (matchingBrowser !== undefined) {
        return Number(browserVersion()) >= Number(matchingBrowser.MajorVersionNumber);
    }

    return false;
}
