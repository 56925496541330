import { ReactElement } from "react";
import { Size } from "../../../types";
import { useLoginCardViewModel } from "./loginCardViewModel";
import { LoginCardView } from "./loginCardView";

export type LoginCardProps = {
    id: string;
    header: string;
    subtitle?: string;
    children: ReactElement;
    size: Size;
};

export function LoginCard(props: LoginCardProps): ReactElement {
    const { state } = useLoginCardViewModel();

    return <LoginCardView {...props} {...state} />;
}
