import { FieldArray, Form, Formik } from "formik";
import { ReactElement } from "react";
import { HelpLine } from "../../../../utility";
import { Alert, AsyncElement, BackArrow, ButtonPro, CheckboxPro, GenericModal } from "../../../generic";
import { CreditCard } from "../../../generic/icons";
import { PCPEnrollmentModal } from "./pcpEnrollmentModal/pcpEnrollmentModalModel";
import "./pcpEnrollmentStatus.scss";
import { PCPEnrollmentStatusAPI, PCPEnrollmentStatusState } from "./pcpEnrollmentStatusViewModel";

type PCPEnrollmentViewProps = PCPEnrollmentStatusState &
    PCPEnrollmentStatusAPI & { onSubmit: Function; check: Function; onCancel: Function };

function PCPEnrollmentStatusView(props: PCPEnrollmentViewProps): ReactElement {
    return (
        <div className={`pcp-enrollment-status${props.size}`}>
            <BackArrow available to={`/account/summary/${props.accountIndex}/home`}>
                <h2 className="pcp-enrollment-status-title">PREMIER Credit Protection</h2>
            </BackArrow>
            <div className={`pcp-enrollment-status-page${props.size}`}>
                <div className="pcp-enrollment-status-container-top">
                    <div className="pcp-enrollment-status-container-enroll">
                        <h3 className="pcp-enrollment-status-container-enroll-title">Account Enrollment Status</h3>
                        <AsyncElement loading={props.loading}>
                            <div className="pcp-enrollment-status-container-enroll-body">
                                {props.enrollMode ? (
                                    <Formik enableReinitialize initialValues={{}} onSubmit={(values) => {}}>
                                        {() => (
                                            <Form>
                                                <FieldArray
                                                    name="card-enrollment-status"
                                                    render={() => (
                                                        <div className="pcp-enrollment-status-container-enroll-list">
                                                            {props.premierCreditProtectionEnrollment?.accounts.map(
                                                                (data, index) => (
                                                                    <div
                                                                        className="pcp-enrollment-status-container-enroll-list-item"
                                                                        key={index}
                                                                        onClick={() => {
                                                                            props.check(index);
                                                                        }}
                                                                    >
                                                                        <CheckboxPro
                                                                            id={`enroll-card-${index}`}
                                                                            checked={
                                                                                props.enrollmentArray![index].checked
                                                                            }
                                                                            disabled={data.enrolled || !data.eligible}
                                                                        />
                                                                        <CreditCard
                                                                            className={`pcp-enrollment-credit-card-${props.accounts![index].accent + 1}`}
                                                                        />
                                                                        <h6
                                                                            className={`pcp-enrollment-status-container-enroll-list-item-card-${props.accounts![index].accent + 1}`}
                                                                        >
                                                                            {`Card (*${props.accounts![index].maskedAccountNumber.slice(-4)})`}
                                                                        </h6>
                                                                        <h6
                                                                            className={`pcp-enrollment-status-container-enroll-list-item-erollment-status${data.enrolled ? "-enrolled" : ""}`}
                                                                        >
                                                                            {!data.enrolled
                                                                                ? data.eligible
                                                                                    ? "Not Enrolled"
                                                                                    : "Ineligible"
                                                                                : "Enrolled"}
                                                                        </h6>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                />
                                            </Form>
                                        )}
                                    </Formik>
                                ) : (
                                    <div className="pcp-enrollment-status-container-enroll-list">
                                        {props.premierCreditProtectionEnrollment?.accounts.map((data, index) => (
                                            <div
                                                className="pcp-enrollment-status-container-enroll-list-item"
                                                key={index}
                                            >
                                                <CreditCard
                                                    className={`pcp-enrollment-credit-card-${props.accounts![index].accent + 1}`}
                                                />
                                                <h6
                                                    className={`pcp-enrollment-status-container-enroll-list-item-card-${props.accounts![index].accent + 1}`}
                                                >
                                                    {`Card (*${props.accounts![index].maskedAccountNumber.slice(-4)})`}
                                                </h6>
                                                <h6
                                                    className={`pcp-enrollment-status-container-enroll-list-item-erollment-status${data.enrolled ? "-enrolled" : ""}`}
                                                >
                                                    {!data.enrolled
                                                        ? data.eligible
                                                            ? "Not Enrolled"
                                                            : "Ineligible"
                                                        : "Enrolled"}
                                                </h6>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <div className="pcp-enrollment-status-container-button-group">
                                    {props.enrollMode ? (
                                        <div
                                            className={`pcp-enrollment-status-container-button-group-enroll-mode${props.size}`}
                                        >
                                            <ButtonPro
                                                variant="secondary"
                                                onClick={() => props.onCancel()}
                                                className="pcp-enrollment-button-cancel"
                                            >
                                                Cancel
                                            </ButtonPro>
                                            <ButtonPro
                                                disabled={!props.touched}
                                                onClick={() => props.onSubmit()}
                                                className="pcp-enrollment-button-first"
                                            >
                                                Complete Enrollment
                                            </ButtonPro>
                                        </div>
                                    ) : (
                                        <>
                                            {props.allAccountsEnrollerOrIneligible ? (
                                                <></>
                                            ) : (
                                                <ButtonPro onClick={() => props.setEnrollMode(true)}>Enroll</ButtonPro>
                                            )}
                                        </>
                                    )}
                                </div>
                                <div className="pcp-enrollment-status-quick-link-container">
                                    {props.showUnenrollButton ? (
                                        <span
                                            className="pcp-enrollment-status-quick-link"
                                            onClick={() => {
                                                props.setShowUnenrollModal(true);
                                            }}
                                        >
                                            Want to unenroll from PREMIER Credit Protection? &gt;
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                {props.showIneligibleAlert ? (
                                    <div className="ineligible-alert">
                                        <Alert level="info">
                                            {props.pcpCMS !== undefined ? (
                                                <span
                                                    className="ineligible-alert-innerHTML"
                                                    dangerouslySetInnerHTML={{ __html: props!.pcpCMS!.IneligibleText }}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </Alert>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </AsyncElement>
                    </div>
                    <div className="pcp-enrollment-status-container-claim">
                        <div className="pcp-enrollment-status-container-how-to">
                            <h3 className="pcp-enrollment-status-container-enroll-how-to-title">
                                How Do I File a Claim?
                            </h3>
                            <p className="pcp-enrollment-status-container-enroll-how-to-body">
                                We're here to help! If you've recently experienced a protected life event, please call
                                us at {HelpLine(props.accounts![props.accountIndex].isCorpCard)}. Our Customer Service
                                Associates will assist you with filing your claim.
                            </p>
                            <div className="pcp-enrollment-status-quick-links">
                                <span
                                    className="pcp-enrollment-status-quick-link-benefit-form"
                                    onClick={() => {
                                        props.navigateToActivationForm();
                                    }}
                                >
                                    PREMIER Credit Protection Benefit Activation Form
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pcp-enrollment-status-container-center">
                    <div className="pcp-enrollment-status-container-information">
                        <div className="pcp-enrollment-status-container-information-top">
                            <div className="pcp-enrollment-status-container-information-title">
                                <div className="pcp-enrollment-status-container-information-header">
                                    PREMIER Credit Protection is an optional Payment Protection Plan for your PREMIER
                                    Bankcard credit card.
                                </div>
                            </div>
                            <div className="pcp-enrollment-status-container-information-list">
                                <ul>
                                    <li className="pcp-enrollment-status-container-information-list-item">
                                        To be eligible for benefits, a Protected Event must occur 30 days or more after the enrollment date. Other eligibility requirements also apply.
                                    </li>
                                    <li className="pcp-enrollment-status-container-information-list-item">
                                        This product is only $0.89 per $100 of your outstanding ending monthly balance
                                        each month.
                                    </li>
                                    <li className="pcp-enrollment-status-container-information-list-item">
                                        If you're not completely satisfied, you have 30 days after you receive your
                                        first monthly statement that includes PREMIER Credit Protection fees to cancel
                                        this program and receive a full refund of any PREMIER Credit Protection fees.
                                        You may cancel PREMIER Credit Protection at any time.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="pcp-enrollment-status-quick-links-container-border">
                        <div className="pcp-enrollment-status-quick-links-container">
                            <div className="pcp-enrollment-status-container-how-to">
                                <h3 className="pcp-enrollment-status-container-enroll-how-to-title">Quick Links</h3>
                            </div>

                            <div className="pcp-enrollment-status-quick-links">
                                <ul>
                                    <li>
                                        <span
                                            className="pcp-enrollment-status-quick-link"
                                            onClick={() => {
                                                props.navigateToPCP();
                                            }}
                                        >
                                            Learn More about PREMIER Credit Protection
                                        </span>
                                    </li>
                                    <li>
                                        <span
                                            className="pcp-enrollment-status-quick-link"
                                            onClick={() => {
                                                props.navigateToProtectionContract();
                                            }}
                                        >
                                            PREMIER Credit Protection Contract
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pcp-enrollment-status-container-bottom">
                    <div className="pcp-enrollment-status-container-information">
                        <div className="pcp-enrollment-status-container-information-paragraph">
                            <p>
                                Your monthly minimum payment, as of the date the protected event began, may be covered
                                if you experience a protected event such as Involuntary Unemployment, Disability, Unpaid
                                Family Leave, and Hospitalization. If you are not currently employed, such as if you are
                                retired, you may not be eligible for Disability, Unpaid Family Leave, or Involuntary
                                Unemployment protection. The cost of the program will not change if you become
                                ineligible for some of the protection. PREMIER Credit Protection may also cover your
                                full account balance in the event of your Loss of Life.
                            </p>
                            <br />
                            <p>
                                PREMIER Credit Protection is not required to obtain credit. We will give you additional
                                information before it is too late to cancel without receiving a full refund of fees
                                billed for PREMIER Credit Protection.
                            </p>
                            <br />
                            <p>
                                If you choose to purchase coverage, we will send you the PREMIER Credit Protection
                                contract via mail in a few days. Please carefully read the contract for a full
                                explanation of eligibility requirements, as well as, conditions and exclusions that
                                could prevent you from receiving benefits under PREMIER Credit Protection.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <GenericModal show={props.showOverSixtyTwoModal} {...props.overSixtyTwoModal} isBranded={true} />
            <GenericModal show={props.showUnenrollModal} {...props.UnenrollModal} isBranded={true} />
            {props.loading ? (
                <></>
            ) : (
                <PCPEnrollmentModal
                    show={props.showEnrollmentModal}
                    setShow={props.setShowEnrollmentModal}
                    enrollmentArray={props.enrollmentArray!}
                    setShowSuccess={props.setShowSuccessModal}
                    overSixtyTwo={true}
                />
            )}

            <GenericModal show={props.showSuccessModal} {...props.successModal} />
        </div>
    );
}
export default PCPEnrollmentStatusView;
