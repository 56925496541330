import dayjs from "dayjs";
import { useState } from "react";
import { AmountSelection } from "./schedulePayment/paymentAmountOption/paymentAmountOption";

export type MakePaymentState = {
    showReview: boolean;
    setShowReview: React.Dispatch<React.SetStateAction<boolean>>;
    showSuccess: boolean;
    setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    showAddBank: boolean;
    setShowAddBank: React.Dispatch<React.SetStateAction<boolean>>;
    paymentAccountId: number;
    setPaymentAccountId: React.Dispatch<React.SetStateAction<number>>;
    amountSelection: AmountSelection;
    setAmountSelection: React.Dispatch<React.SetStateAction<AmountSelection>>;
    amount: string;
    setAmount: React.Dispatch<React.SetStateAction<string>>;
    date: dayjs.Dayjs | undefined;
    setDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs | undefined>>;
    successId: number;
    setSuccessId: React.Dispatch<React.SetStateAction<number>>;
};

const useMakePaymentState = (): MakePaymentState => {
    const [showReview, setShowReview] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showAddBank, setShowAddBank] = useState(false);
    const [paymentAccountId, setPaymentAccountId] = useState(0);
    const [amountSelection, setAmountSelection] = useState("" as AmountSelection);
    const [amount, setAmount] = useState("");
    const [date, setDate] = useState<dayjs.Dayjs>();
    const [successId, setSuccessId] = useState(0);

    return {
        showReview,
        setShowReview,
        showSuccess,
        setShowSuccess,
        showAddBank,
        setShowAddBank,
        paymentAccountId,
        setPaymentAccountId,
        amountSelection,
        setAmountSelection,
        amount,
        setAmount,
        date,
        setDate,
        successId,
        setSuccessId
    };
};

export const useMakePaymentViewModel = () => {
    const state = useMakePaymentState();

    return {
        state
    };
};
