import { Form, FormikProps } from "formik";
import { ReactElement } from "react";
import { Loading } from "../../..";
import { IsNullOrEmpty } from "../../../../utility";
import { Alert, ButtonPro } from "../../../generic";
import "./reviewPaymentAccount.scss";
import { ReviewPaymentAccountProps } from "./reviewPaymentAccountModel";
import { ReviewPaymentAccountState } from "./reviewPaymentAccountViewModel";

type ReviewPaymentAccountViewProps = ReviewPaymentAccountProps &
    ReviewPaymentAccountState &
    FormikProps<{}> & {
        onCancel: Function;
        onEdit: Function;
    };

export function ReviewPaymentAccountView(props: ReviewPaymentAccountViewProps): ReactElement {
    return (
        <Form>
            <div id="reviewPaymentAccount">
                <div className="reviewPaymentAccount-header">
                    <h4>Confirm Details</h4>
                    <h6>Step 2 of 2</h6>
                </div>
                {props.error !== "" ? <Alert level="error">{props.error}</Alert> : <></>}
                {props.warning !== "" ? <Alert level="warning">{props.warning}</Alert> : <></>}
                <div className="reviewPaymentAccount-info">
                    <div className="reviewPaymentAccount-row">
                        <p>Routing number:</p>
                        <h5>{props.routingNumber}</h5>
                    </div>
                    <div className="reviewPaymentAccount-row">
                        <p>Checking account number:</p>
                        <h5>
                            {Array(props.checkingNumber.length - 4)
                                .fill("•")
                                .join("") + props.checkingNumber.slice(-4)}
                        </h5>
                    </div>
                    <div className="reviewPaymentAccount-row">
                        <p>Bank name:</p>
                        <h5>{!IsNullOrEmpty(props.bankName) ? props.bankName : "Not Found"}</h5>
                    </div>
                </div>

                <div className="reviewPaymentAccount-acknowledgement">
                    <p>
                        <span dangerouslySetInnerHTML={{ __html: props.acknowledgement }} />
                    </p>
                </div>

                <div className="reviewPaymentAccount-buttons">
                    <ButtonPro variant="neutral" onClick={() => props.onCancel()}>
                        Cancel
                    </ButtonPro>
                    <ButtonPro variant="neutral" onClick={() => props.onEdit()}>
                        Edit
                    </ButtonPro>
                    <ButtonPro type="submit" variant="primary" disabled={!props.canContinue}>
                        Add Account
                    </ButtonPro>
                </div>
            </div>

            <Loading show={props.isSubmitting} />
        </Form>
    );
}

export default ReviewPaymentAccountView;
