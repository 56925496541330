import { ReactElement } from "react";
import { PaymentSuccess, ReviewPayment, SchedulePayment } from ".";
import { AddPaymentAccount } from "../..";
import { MakePaymentProps } from "./makePaymentModel";
import { MakePaymentState } from "./makePaymentViewModel";

type MakePaymentViewProps = MakePaymentProps &
    MakePaymentState & {
        addBankCallback: Function;
    };

function MakePaymentView(props: MakePaymentViewProps): ReactElement {
    return (
        <>
            <SchedulePayment {...props} show={props.showSchedule} setShow={props.setShowSchedule} />

            <ReviewPayment {...props} show={props.showReview} setShow={props.setShowReview} />

            <PaymentSuccess {...props} show={props.showSuccess} setShow={props.setShowSuccess} />

            <AddPaymentAccount
                {...props}
                showEnter={props.showAddBank}
                setShowEnter={props.setShowAddBank}
                callback={props.addBankCallback}
            />
        </>
    );
}

export default MakePaymentView;
