import { ReactElement } from "react";
import { ButtonPro } from "../../generic";
import { LoginCard } from "../../login/common/loginCardModel";
import "./errorPage.scss";
import { ErrorPageProps } from "./errorPageModel";
import { ErrorPageState } from "./errorPageViewModel";

type ErrorPageViewProps = ErrorPageProps &
    ErrorPageState & {
        onContinue: Function;
    };

function ErrorPageView(props: ErrorPageViewProps): ReactElement {
    return (
        <LoginCard id="errorPage" size={props.size} header="Something Went Wrong">
            <div className={`loginCard-form${props.size}`}>
                <h6>We're sorry, something went wrong. please try again.</h6>
                {props.status !== 401 ? <ButtonPro content={"Cancel"} onClick={() => props.onContinue()} /> : <></>}
                <ButtonPro content={props.primaryBtn} variant="secondary" onClick={() => props.onContinue()} />
            </div>
        </LoginCard>
    );
}

export default ErrorPageView;
