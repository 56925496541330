import { Form, FormikProps } from "formik";
import { ReactElement } from "react";
import { Loading } from "../../..";
import { FormatMoney, GetFormattedDate } from "../../../../utility";
import { Alert, ButtonPro } from "../../../generic";
import { ReviewCancelPaymentProps } from "./reviewCancelPayment";
import "./reviewCancelPayment.scss";
import { ReviewCancelPaymentState } from "./reviewCancelPaymentViewModel";

type ReviewCancelPaymentViewProps = ReviewCancelPaymentProps &
    ReviewCancelPaymentState &
    FormikProps<{}> & {
        onCancel: Function;
    };

function ReviewCancelPaymentView(props: ReviewCancelPaymentViewProps): ReactElement {
    return (
        <Form>
            <div id="reviewCancelPayment">
                <div className="reviewCancelPayment-header">
                    <h4>Cancel Payment</h4>
                    <h6>Are you sure you want to cancel this payment?</h6>
                </div>
                {props.error !== "" ? <Alert level="error">{props.error}</Alert> : <></>}
                <div className="reviewCancelPayment-info">
                    <h5>Payment details</h5>

                    <div className="reviewCancelPayment-row">
                        <p>Amount:</p>
                        <h5>{FormatMoney(props.cancelPayment?.paymentAmount, true).formatted}</h5>
                    </div>
                    <div className="reviewCancelPayment-row">
                        <p>Scheduled for:</p>
                        <h5>{GetFormattedDate(props.cancelPayment?.paymentDate?.toString(), true)}</h5>
                    </div>
                    <div className="reviewCancelPayment-row">
                        <p>From account:</p>
                        <h5>
                            {props.cancelPayment?.bankName}&nbsp;
                            {props.cancelPayment?.paidFrom.replaceAll("*", "•")}
                        </h5>
                    </div>
                </div>

                <div className="reviewCancelPayment-buttons" data-testid={"cancelButton"}>
                    <ButtonPro variant="neutral" onClick={() => props.onCancel()}>
                        Close
                    </ButtonPro>
                    <ButtonPro type="submit" variant="primary-neg">
                        Cancel Payment
                    </ButtonPro>
                </div>
            </div>

            <Loading show={props.isSubmitting} />
        </Form>
    );
}

export default ReviewCancelPaymentView;
