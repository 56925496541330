import { Form, Formik } from "formik";
import { ReactElement } from "react";
import { Loading } from "../../../..";
import { FormProps } from "../../../../../types";
import { ButtonPro, GenericModal } from "../../../../generic";
import { CCPAOptionsModel } from "../../../ccpa/ccpaOptions/ccpaOptionsModel";
import { CCPAOptionsFormFields } from "../../../ccpa/ccpaOptions/ccpaOptionsView";
import { CCPAFormModelProps, CCPAFormState } from "../ccpaFormViewModel";
import "./ccpaAuthenticated.scss";

type CCPAAuthenticatedViewProps = CCPAFormModelProps &
    CCPAFormState & {
        onCancel: Function;
        onSubmit: Function;
    };

export type CCPAAuthenticatedFormFields = CCPAOptionsFormFields;

export function CCPAAuthenticatedView(props: CCPAAuthenticatedViewProps): ReactElement {
    const initalValues = {
        categoriesSources: false,
        specificData: false,
        requestData: false,
        deleteData: false,
        deleteConfirmation: false
    } as CCPAAuthenticatedFormFields;

    return (
        <div id="ccpaAuthenticated">
            <Formik
                initialValues={initalValues}
                onSubmit={(values, actions) => {
                    props.onSubmit(values, actions);
                }}
            >
                {(formProps: FormProps<CCPAAuthenticatedFormFields>) => (
                    <Form>
                        <CCPAOptionsModel {...props} isCorpCard={props.accounts![0].isCorpCard} />
                        <div className="ccpa-buttons">
                            <ButtonPro type="button" variant="secondary" onClick={() => props.onCancel()}>
                                Cancel
                            </ButtonPro>
                            <ButtonPro type="submit" disabled={submitDisabled(formProps)}>
                                Submit Request
                            </ButtonPro>
                        </div>

                        <Loading show={formProps.isSubmitting} />
                    </Form>
                )}
            </Formik>

            <GenericModal show={props.showCancel} {...props.cancelProps} />
            <GenericModal show={props.showSuccess} {...props.successProps} />
        </div>
    );
}

function submitDisabled(formProps: FormProps<CCPAOptionsFormFields>): boolean {
    return (
        (formProps.values.deleteData === true && formProps.values.deleteConfirmation !== true) ||
        (formProps.values.categoriesSources !== true &&
            formProps.values.specificData !== true &&
            formProps.values.requestData !== true &&
            formProps.values.deleteData !== true)
    );
}
