function getRequestAnimationFrame() {
    let w = window as any;

    return w.requestAnimationFrame || w.webkitRequestAnimationFrame || w.mozRequestAnimationFrame;
}

function getCancelAnimationFrame() {
    let w = window as any;

    return (
        w.cancelAnimationFrame ||
        w.webkitCancelRequestAnimationFrame ||
        w.webkitCancelAnimationFrame ||
        w.mozCancelAnimationFrame
    );
}

export function defer(fn: FrameRequestCallback): number {
    const hasCancelAnimationFrame = !!getCancelAnimationFrame();
    let deferFn;

    if (hasCancelAnimationFrame) {
        deferFn = getRequestAnimationFrame();
    } else {
        deferFn = () => setTimeout(fn, 1000 / 60);
    }

    return deferFn(fn);
}

export function cancelDefer(deferId: number) {
    const cancelFn = getCancelAnimationFrame() || clearTimeout;

    cancelFn(deferId);
}
