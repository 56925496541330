import { ReactElement } from "react";
import { PaymentResponse } from "../../../../api/backend";
import { PaymentHistoryRowView } from "./paymentHistoryRowView";
import { usePaymentHistoryRowViewModel } from "./paymentHistoryRowViewModel";

export type PaymentHistoryRowProps = {
    index: number;
    payment: PaymentResponse;
};

export function PaymentHistoryRow(props: PaymentHistoryRowProps): ReactElement {
    const { hooks, state } = usePaymentHistoryRowViewModel(props);

    return <PaymentHistoryRowView {...props} {...state} {...hooks} />;
}
