import React, { ReactElement } from "react";
import { CancelPasswordReset, InvalidMFA3Times, MfaEnterCodeCS } from "../../..";
import { MfaAssistanceEnterCodeProps } from "./mfaAssistanceEnterCodeModel";
import { MfaAssistanceEnterCodeState } from "./mfaAssistanceEnterCodeViewModel";

type MfaAssistanceEnterCodeViewProps = MfaAssistanceEnterCodeProps & MfaAssistanceEnterCodeState;

export function MfaAssistanceEnterCodeView(props: MfaAssistanceEnterCodeViewProps): ReactElement {
    return (
        <>
            <MfaEnterCodeCS show={props.showModal} {...props} />
            <CancelPasswordReset show={props.showCancelModal} setShow={props.setShowCancelModal} />
        </>
    );
}
