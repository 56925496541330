import { AxiosResponse } from "axios";
import { useState } from "react";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import { MfaProcessCodeRequest, useEnrollmentContext } from "../../../api/backend";
import { ConnectTokenResponse, MFAOptions, useIdentityConnectContext } from "../../../api/identityServer";
import { useMfaContext } from "../../../contexts";
import { SecurityType } from "../../../contexts/mfaContext";
import { useSessionStorage, useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { MfaFields } from "./mfaEnterCodeView";

type MfaEnterCodeViewModel = {
    hooks: MfaEnterCodeHooks;
    state: MfaEnterCodeState;
    api: MfaEnterCodeApi;
};

export type MfaEnterCodeHooks = {
    navigate: NavigateFunction;
    storage: { [x: string]: any };
    storeValue: (name: string, value: any) => void;
    removeValue: (names: string[]) => void;
};

export type MfaEnterCodeState = {
    size: Size;
    securityType: SecurityType | undefined;
    mfaOptions: MFAOptions | undefined;
    searchParams: URLSearchParams;
    otp: string;
    setOtp: React.Dispatch<React.SetStateAction<string>>;
    codeState: OtpCodeState;
    setCodeState: React.Dispatch<React.SetStateAction<OtpCodeState>>;
    canContinue: boolean;
    setCanContinue: React.Dispatch<React.SetStateAction<boolean>>;
    timeoutId: NodeJS.Timeout | undefined;
    setTimeoutId: React.Dispatch<React.SetStateAction<NodeJS.Timeout | undefined>>;
    showCancel: boolean;
    setShowCancel: React.Dispatch<React.SetStateAction<boolean>>;
    showError: boolean;
    setShowError: React.Dispatch<React.SetStateAction<boolean>>;
    showTrust: boolean;
    setShowTrust: React.Dispatch<React.SetStateAction<boolean>>;
    subtitle: string;
    initialValues: MfaFields;
    showPinExpired: boolean;
    setShowPinExpired: React.Dispatch<React.SetStateAction<boolean>>;
    showInvalidSession: boolean;
    setShowInvalidSession: React.Dispatch<React.SetStateAction<boolean>>;
};

export type MfaEnterCodeApi = {
    verifyCode: () => Promise<AxiosResponse<ConnectTokenResponse, any>>;
    enrollVerifyCode: (request: MfaProcessCodeRequest) => Promise<AxiosResponse<any, any>>;
};

type OtpCodeState = "" | "-pass" | "-fail";

const storageKeys = ["showTrust", "username", "password"];

export function useMfaEnterCodeViewModel(): MfaEnterCodeViewModel {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { size } = useViewport(false, true);
    const { VerifyCode } = useIdentityConnectContext();
    const { MfaProcessCode } = useEnrollmentContext();
    const { mfaOptions, securityType } = useMfaContext();
    const [storage, storeValue, removeValue] = useSessionStorage(storageKeys);
    const [otp, setOtp] = useState("");
    const [codeState, setCodeState] = useState<OtpCodeState>("");
    const [canContinue, setCanContinue] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    const [showCancel, setShowCancel] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showTrust, setShowTrust] = useState(
        storage["showTrust"] === "true" ? /* istanbul ignore next */ true : false
    );
    const subtitle = securityType === "Enrollment" ? "Step 3 of 4" : securityType !== "Login" ? "Step 2 of 3" : "";
    const initialValues = { digit1: "", digit2: "", digit3: "", digit4: "" } as MfaFields;
    const [showPinExpired, setShowPinExpired] = useState(false);
    const [showInvalidSession, setShowInvalidSession] = useState(false);

    async function verifyCode(): Promise<AxiosResponse<ConnectTokenResponse, any>> {
        return VerifyCode(mfaOptions?.onlineUserId ?? 0, mfaOptions?.accountId ?? 0, otp);
    }

    async function enrollVerifyCode(request: MfaProcessCodeRequest): Promise<AxiosResponse<any, any>> {
        return await MfaProcessCode(request);
    }

    return {
        hooks: { navigate, storage, storeValue, removeValue },
        state: {
            size,
            securityType,
            mfaOptions,
            searchParams,
            otp,
            setOtp,
            codeState,
            setCodeState,
            canContinue,
            setCanContinue,
            timeoutId,
            setTimeoutId,
            showCancel,
            setShowCancel,
            showError,
            setShowError,
            showTrust,
            setShowTrust,
            subtitle,
            initialValues,
            showPinExpired,
            setShowPinExpired,
            showInvalidSession,
            setShowInvalidSession
        },
        api: { verifyCode, enrollVerifyCode }
    };
}
