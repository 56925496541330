import { useLoaderData } from "react-router";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { PCPData } from "./disclosuresLoader";

type PCPDisclosuresTabViewModel = {
    state: PCPDisclosuresTabState;
};

export type PCPDisclosuresTabState = {
    size: Size;
    headerText: string;
    bodyText: string;
    isPrint: boolean;
    printView: string;
    isPdf: boolean;
    contentType: string;
    fileName: string;
    pdfData: ArrayBuffer;
};

export function usePCPDisclosuresViewModelTab(): PCPDisclosuresTabViewModel {
    const { size } = useViewport(false, true);
    const data = useLoaderData() as PCPData;
    const contentType = "application/pdf";
    const fileName = data.cms.Name;
    const pdfData = new ArrayBuffer(8);

    return {
        state: {
            size,
            contentType,
            fileName,
            pdfData,
            ...data
        }
    };
}
