import { createContext, ReactElement, useEffect, useState } from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { useIdentityConnectContext } from "../api/identityServer";
import { BrowserCompatibilityData } from "../components/peripherals/browserCompatibility/browserCompatibilityLoader";
import { useSessionStorage } from "../hooks";
import { Children } from "../types";
import { IsNullOrEmpty, NotImplemented } from "../utility";
import { useDeepLinkContext } from "./deepLinkContext";

export type BrowserSupportContextProps = {
    acknowledgement: boolean;
    setAcknowledgement: React.Dispatch<React.SetStateAction<boolean>>;
};

export const BrowserSupportContext = createContext<BrowserSupportContextProps>({
    acknowledgement: false,
    setAcknowledgement: NotImplemented
});

const useBrowserSupportContextProps = (): BrowserSupportContextProps => {
    const [storage] = useSessionStorage(["browserAcknowledgement"]);
    const [acknowledgement, setAcknowledgement] = useState((storage["browserAcknowledgement"] as boolean) ?? false);

    return {
        acknowledgement,
        setAcknowledgement
    };
};

export function BrowserSupport(props: Children): ReactElement {
    const navigate = useNavigate();
    const location = useLocation();
    const contextProps = useBrowserSupportContextProps();
    const { current, supported } = useLoaderData() as BrowserCompatibilityData;
    const { setDeepLink } = useDeepLinkContext();
    const { access_token } = useIdentityConnectContext();

    useEffect(() => {
        if (!contextProps.acknowledgement && (!current || !supported)) {
            navigate("browser-check");
        } else if (location.pathname === "/") {
            if (!IsNullOrEmpty(access_token)) {
                navigate("/account/dashboard");
            } else {
                navigate("/auth/login");
            }
        } else if (!location.pathname.includes("login")) {
            setDeepLink(location.pathname);
        }
    }, [contextProps.acknowledgement]);

    return (
        <>
            <BrowserSupportContext.Provider value={contextProps}>{props.children}</BrowserSupportContext.Provider>
        </>
    );
}
