import { ReactElement } from "react";
import { UserAccount } from "../../../../api/backend";
import { CardBalanceView } from "./cardBalanceView";
import { useCardBalanceViewModel } from "./cardBalanceViewModel";

export type CardBalanceProps = {
    index: number;
    account: UserAccount;
};

export function CardBalance(props: CardBalanceProps): ReactElement {
    const { state } = useCardBalanceViewModel(props);

    return <CardBalanceView {...props} {...state} />;
}
