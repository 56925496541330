import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { CookieSetOptions } from "universal-cookie";
import { useProfileContext } from "../../../api/backend";
import { ClearIdentityOptions, ConnectTokenResponse, useIdentityConnectContext } from "../../../api/identityServer";
import { useOneTrustContext, useSwrveContext } from "../../../contexts";
import { SessionTime, SessionTimerContext } from "../../../contexts/sessionTimeoutContext";
import { useBackgroundTick, useViewport } from "../../../hooks";
import { Size } from "../../../types";

type SessionTimeoutViewModel = {
    hooks: SessionTimeoutHooks;
    state: SessionTimeoutState;
    api: SessionTimeoutApi;
};

export type SessionTimeoutHooks = {
    navigate: NavigateFunction;
    clearIdentity: (option: ClearIdentityOptions) => void;
    swrveShutdown: () => void;
    clearConsumerGuid: () => void;
    clearJwt: () => void;
    removeCookie: (name: string, options?: CookieSetOptions | undefined) => void;
    start: () => void;
    stop: () => void;
    expired: (end: dayjs.Dayjs) => boolean;
};

export type SessionTimeoutState = {
    size: Size;
    access_token: string;
    refresh_token: string;
    setShowSessionTimer: React.Dispatch<React.SetStateAction<boolean>>;
    ticker: number;
    setTicker: React.Dispatch<React.SetStateAction<number>>;
    sessionTimes: SessionTime;
    tick: number;
};

type SessionTimeoutApi = {
    RefreshToken: (refreshToken: string, trustedLogin: boolean) => Promise<AxiosResponse<ConnectTokenResponse, any>>;
    logout: () => Promise<AxiosResponse<any, any>>;
};

const cookieKeys = ["OptanonConsent", "OptanonAlertBoxClosed"];

export function useSessionTimeoutViewModel(): SessionTimeoutViewModel {
    const navigate = useNavigate();
    const { size } = useViewport(true, true);
    const { access_token, refresh_token, clear: clearIdentity, RefreshToken } = useIdentityConnectContext();
    const { sessionTimes, setShowSessionTimer } = useContext(SessionTimerContext);
    const [ticker, setTicker] = useState(120);
    const { clearConsumerGuid, clearJwt } = useOneTrustContext();
    const { swrveShutdown } = useSwrveContext();
    const { tick, start, stop, expired } = useBackgroundTick();
    const [, , removeCookie] = useCookies(cookieKeys);
    const { PutLogout: logout } = useProfileContext();

    return {
        hooks: {
            navigate,
            clearIdentity,
            clearConsumerGuid,
            clearJwt,
            removeCookie,
            swrveShutdown,
            start,
            stop,
            expired
        },
        state: {
            size,
            access_token,
            refresh_token,
            setShowSessionTimer,
            ticker,
            setTicker,
            sessionTimes,
            tick
        },
        api: {
            RefreshToken,
            logout
        }
    };
}
