import dayjs from "dayjs";
import { ReactElement, useContext, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { SessionTimerContext } from "../../../contexts";
import { useBackgroundTick, useViewsize } from "../../../hooks";
import { AppVersion, Env } from "../../../res/react-env-variables";
import { browserName, browserVersion } from "../../../utility/device";
import "./debugInfo.scss";

export function DebugInfoHeader(): ReactElement {
    //String Undefined is correct Here
    if (!["undefined", "Prod"].includes(Env) && AppVersion !== "undefined") {
        return <DebugInfoHeaderView />;
    }

    return <></>;
}

export function DebugInfoHeaderView(): React.ReactElement {
    const [today, setDate] = useState(new Date());
    const [visible, setVisible] = useState(true);
    const { sessionTimes, running } = useContext(SessionTimerContext);
    const { stop, restart } = useBackgroundTick();
    const size = useViewsize();

    useEffect(() => {
        if (running) {
            restart();
        } else {
            stop();
        }
    }, [running]);

    const removeElement = () => {
        setVisible((prev) => !prev);
        stop();
    };

    const { getLastActiveTime } = useIdleTimer({
        timeout: 1000,
        throttle: 500
    });

    const sessionTime = sessionTimes.endTime.diff(dayjs());
    const sessionExpiration = dayjs().diff(dayjs(getLastActiveTime()));
    useEffect(() => {
        const timer = setInterval(() => setDate(new Date()), 15000);
        return () => {
            clearInterval(timer);
        };
    });

    return (
        <>
            {visible ? (
                <div id="debugInfoHeader" onClick={removeElement}>
                    <div>{new Date().toLocaleDateString()}</div>
                    <div>{today.toLocaleTimeString()}</div>
                    <div>{Env}</div>
                    <div>Front End Version = {AppVersion}</div>
                    <div>
                        {browserName()} {browserVersion()}
                    </div>
                    <div>
                        {size.width}x{size.height}
                    </div>
                    <div>Access Token Refresh: {!running ? "Refreshing" : msToTime(sessionTime)}</div>
                    <div>Inactivity Timer: {msToTime(sessionExpiration)}</div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

function msToTime(s: number) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    return (hrs > 0 ? `${hrs}:` : "") + mins + ":" + (secs > 9 ? secs : `0${secs}`);
}

export function DebugInfoFooter(): React.ReactElement {
    const [today, setDate] = useState(new Date());
    const [visible, setVisible] = useState(true);

    const size = useViewsize();
    const removeElement = () => {
        setVisible((prev) => !prev);
    };

    useEffect(() => {
        const timer = setInterval(() => setDate(new Date()), 15000);
        return () => {
            clearInterval(timer);
        };
    });

    return (
        <>
            {process.env.REACT_APP_ENV !== "Prod" && process.env.REACT_APP_VERSION && visible ? (
                <div id="debugInfoFooter" onClick={removeElement}>
                    <div>{new Date().toLocaleDateString()}</div>
                    <div>{today.toLocaleTimeString()}</div>
                    <div>{process.env.REACT_APP_ENV}</div>
                    <div>Front End Version = {process.env.REACT_APP_VERSION}</div>
                    <div>
                        {browserName()} {browserVersion()}
                    </div>
                    <div>
                        {size.width}x{size.height}
                    </div>
                    <div className="break">{window.navigator.userAgent}</div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}
