import { useEffect, useState } from "react";
import { ConsumerAlertCms, useCmsContext } from "../../../api/backend";
import { useSessionStorage } from "../../../hooks";

type ProminentAlertViewModel = {
    hooks: ProminentAlertHooks;
    state: ProminentAlertState;
};

type ProminentAlertHooks = {
    storeValue: (name: string, value: any) => void;
};

export type ProminentAlertState = {
    storage: { [x: string]: any };
    alert: ConsumerAlertCms | undefined;
    dismissed: boolean;
    setDismissed: React.Dispatch<React.SetStateAction<boolean>>;
    truncated: boolean;
    more: boolean;
    setMore: React.Dispatch<React.SetStateAction<boolean>>;
};

const storageKeys = ["prominentAlert"];

export function useProminentAlertViewModel(): ProminentAlertViewModel {
    const { ConsumerAlert } = useCmsContext();
    const [alert, setAlert] = useState<ConsumerAlertCms>();
    const [storage, storeValue] = useSessionStorage(storageKeys);
    const [dismissed, setDismissed] = useState(storage["prominentAlert"] ?? false);
    const [more, setMore] = useState(false);
    let truncated = true;

    let ele = document.getElementById("prominentAlert-body") as HTMLSpanElement;
    if (ele && !more) {
        truncated = ele.parentElement!.scrollHeight - 2 >= ele.parentElement!.clientHeight;
    }

    useEffect(() => {
        ConsumerAlert().then((x) => setAlert(x.data));
    }, []);

    return {
        hooks: {
            storeValue
        },
        state: {
            storage,
            alert,
            dismissed,
            setDismissed,
            truncated,
            more,
            setMore
        }
    };
}
