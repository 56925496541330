import { PublicCCPAPageConfiguration, useLoaderCms } from "../../../../api/backend";

export type CCPAHomeData = {
    cms: PublicCCPAPageConfiguration;
};

export async function CCPAHomeLoader(): Promise<CCPAHomeData> {
    const { CCPAPublicPage } = useLoaderCms();
    const cms = (await CCPAPublicPage()).data.PublicCCPAPageConfiguration;

    return { cms };
}
