import { FormikHelpers } from "formik";
import { ReactElement } from "react";
import { ElectronicDeliveryPreferences, useSettingsContext } from "../../../../api/backend";
import { ModalProps } from "../../../generic";
import DeliveryPreferencesTermsView from "./deliveryPreferencesTermsView";
import { useDeliveryPreferencesTermsViewModel } from "./deliveryPreferencesTermsViewModel";

type DeliveryPreferencesTermsProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    initialValues: any;
    selectedValues: any;
    setSelectedValues: React.Dispatch<React.SetStateAction<any>>;
    setShowDelPrefConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
    setEditPreferences: React.Dispatch<React.SetStateAction<boolean>>;
    setTempDeliveryPreferences: React.Dispatch<React.SetStateAction<ElectronicDeliveryPreferences[] | undefined>>;
    resetForm: Function;
    setDelPrefTimer: Function;
};

export function DeliveryPreferencesTermsModel(props: ModalProps & DeliveryPreferencesTermsProps): ReactElement {
    const { hooks, state, api } = useDeliveryPreferencesTermsViewModel();
    const { appSettings } = useSettingsContext();
    const isEMessengerDown: boolean = appSettings?.featureFlags?.isEMessengerDown;

    function downloadPDF() {
        const pdfUrl = state.EStatementDisclosure;

        fetch(pdfUrl).then((response) => {
            response.blob().then((blob) => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);

                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = "esign_consent_disclosure.pdf";
                alink.click();
            });
        });
    }

    function onDecline(): void {
        props.setShow(false);
        state.setCanSave(false);
        props.resetForm();
        props.setEditPreferences(false);
        props.setTempDeliveryPreferences(undefined);
    }

    function onSubmit(values: any, actions: FormikHelpers<any>): void {
        let refeshDeliveryPreferences = false;

        Object.keys(props.selectedValues).forEach((x: any) => {
            let index = Number(x.split("-")[1]);

            if (
                props.selectedValues["checkBox-" + index] === true &&
                props.selectedValues["checkBox-" + index] !== props.initialValues["checkBox-" + index]
            ) {
                refeshDeliveryPreferences = true;
                let currentAccountHandle = state.accounts![index].accountHandle;

                let eLetterEnroll = hooks.actionAllowed("eLetterEnroll", currentAccountHandle);
                let eStatementEnroll = hooks.actionAllowed("eStatementEnroll", currentAccountHandle);

                if (!isEMessengerDown) {
                    api.Enroll({
                        accountHandle: currentAccountHandle,
                        enrollStatements: eStatementEnroll,
                        enrollDocuments: eLetterEnroll,
                        allAccounts: false
                    }).catch((err) => {
                        console.log(err);
                    });
                }
            } else if (
                props.selectedValues["checkBox-" + index] === false &&
                props.selectedValues["checkBox-" + index] !== props.initialValues["checkBox-" + index]
            ) {
                refeshDeliveryPreferences = true;

                if (!isEMessengerDown) {
                    api.Enroll({
                        accountHandle: state.accounts![index].accountHandle,
                        enrollStatements: false,
                        enrollDocuments: false,
                        allAccounts: false
                    }).catch((err) => {
                        console.log(err);
                    });
                }
            }
        });

        props.setEditPreferences(false);
        props.setShow(false);
        props.setShowDelPrefConfirmModal(true);
        state.setCanSave(false);

        if (refeshDeliveryPreferences) {
            props.setDelPrefTimer();
        }
    }

    return (
        <DeliveryPreferencesTermsView
            {...props}
            {...state}
            downloadPDF={downloadPDF}
            onDecline={onDecline}
            onSubmit={onSubmit}
        />
    );
}
