import { useAccountContext, usePaymentContext } from "../../../../../api/backend";
import { useViewport } from "../../../../../hooks";
import { Size } from "../../../../../types";

type PaymentButtonState = {
    size: Size;
    accountIndex: number;
};

type PaymentButtonHooks = {
    makeNewPayment: (index: number) => void;
};

const usePaymentButtonState = (): PaymentButtonState => {
    const { size } = useViewport(true, true);
    const { accountIndex } = useAccountContext();

    return {
        size,
        accountIndex
    };
};

const usePaymentButtonHooks = (): PaymentButtonHooks => {
    const { makeNewPayment } = usePaymentContext();

    return {
        makeNewPayment
    };
};

export const usePaymentButtonViewModel = () => {
    const state = usePaymentButtonState();
    const hooks = usePaymentButtonHooks();

    return {
        state,
        hooks
    };
};
