import { Form, Formik } from "formik";
import { ChangeEvent, ReactElement } from "react";
import { CancelPasswordReset, Loading, NewPasswordSplash, PasswordCriteria, PasswordValidation } from "../..";
import { FormProps } from "../../../types";
import { Alert, ButtonPro, PasswordInput } from "../../generic";
import { LoginCard } from "../common/loginCardModel";
import "./newPassword.scss";
import { NewPasswordState } from "./newPasswordViewModel";

type NewPasswordViewProps = NewPasswordState & {
    checkPassword: Function;
    onSubmit: Function;
    onCancel: Function;
};

export type NewPasswordFields = {
    verifyPassword: string;
    password: string;
    confirmPassword: string;
    passwordCriteria: PasswordCriteria;
};

export function NewPasswordView(props: NewPasswordViewProps): ReactElement {
    let initialValues = {
        verifyPassword: "",
        password: "",
        confirmPassword: "",
        passwordCriteria: {
            length: undefined,
            uppercase: undefined,
            lowercase: undefined,
            numericSpecial: undefined,
            confirmed: undefined,
            hasError: undefined
        }
    };

    return (
        <>
            {" "}
            {!props.showSplash ? (
                <LoginCard id="newPassword" size={props.size} header={props.header} subtitle={props.subtitle}>
                    <div id="newPasswordContent">
                        <Formik
                            initialValues={initialValues}
                            onSubmit={(values, actions) => {
                                props.onSubmit(values, actions);
                            }}
                        >
                            {(formProps: FormProps<NewPasswordFields>) => (
                                <Form className={`loginCard-form${props.size}`} autoComplete="off">
                                    {props.isTempPassword ? (
                                        <PasswordInput
                                            id="verifyPassword"
                                            label="Temporary Password"
                                            placeholder="Enter temporary password"
                                            error={formProps.errors.verifyPassword}
                                            stateValue={{
                                                isPrivate: true,
                                                canPeek: true
                                            }}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                props.checkPassword(
                                                    e.currentTarget.value,
                                                    "verifyPassword",
                                                    formProps.values
                                                );
                                                formProps.handleChange(e);
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <PasswordInput
                                        id="password"
                                        label={props.passwordText}
                                        placeholder={props.passwordPlaceHolder}
                                        error={formProps.errors.password}
                                        stateValue={{
                                            isPrivate: true,
                                            canPeek: true
                                        }}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            props.checkPassword(e.currentTarget.value, "password", formProps.values);
                                            formProps.handleChange(e);
                                        }}
                                    />
                                    <PasswordInput
                                        id="confirmPassword"
                                        label={props.confirmPasswordText}
                                        placeholder={props.confirmPasswordPlaceHolder}
                                        error={formProps.errors.confirmPassword}
                                        stateValue={{
                                            isPrivate: true,
                                            canPeek: true
                                        }}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            props.checkPassword(
                                                e.currentTarget.value,
                                                "confirmPassword",
                                                formProps.values
                                            );
                                            formProps.handleChange(e);
                                        }}
                                    />
                                    <div id="passwordRequirements">
                                        <PasswordValidation
                                            touched={props.passwordTouched}
                                            confirmTouched={props.confirmTouched}
                                            criteria={formProps.values.passwordCriteria}
                                        />

                                        <h6>All fields are required</h6>
                                    </div>
                                    {props.isTempPassword ? (
                                        <Alert level="info">
                                            Please note your temporary password will expire 10 minutes after it was
                                            created
                                        </Alert>
                                    ) : (
                                        <></>
                                    )}

                                    <div id="newPassword-Buttons">
                                        {!props.isTempPassword ? (
                                            <ButtonPro
                                                type="button"
                                                variant="neutral"
                                                id="newPassword-Buttons-Cancel"
                                                onClick={() => props.onCancel()}
                                            >
                                                Cancel
                                            </ButtonPro>
                                        ) : (
                                            <></>
                                        )}

                                        <ButtonPro
                                            type="submit"
                                            variant="primary"
                                            id={
                                                props.isTempPassword
                                                    ? "tempPassword-Buttons-Create"
                                                    : "newPassword-Buttons-Create"
                                            }
                                            disabled={!props.canContinue}
                                            isSubmitting={formProps.isSubmitting}
                                        >
                                            {props.continueText}
                                        </ButtonPro>
                                    </div>

                                    <CancelPasswordReset show={props.showModal} setShow={props.setShowModal} />

                                    <Loading show={formProps.isSubmitting} />
                                </Form>
                            )}
                        </Formik>
                    </div>
                </LoginCard>
            ) : (
                <NewPasswordSplash />
            )}{" "}
        </>
    );
}
