import { ReactElement, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { OnlineActionAccess } from "../../../../api/backend";
import { EqualsIgnoreCase } from "../../../../utility";
import { Check } from "../../../generic/icons";
import { NavMenuView } from "./navMenuView";
import { useNavMenuViewModel } from "./navMenuViewModel";

export type AccountService = {
    onlineAction: OnlineActionAccess;
    order: number;
    display: string;
    action: Function;
    isAllowed: boolean;
    isSelected: boolean;
};

export type NavMenuProps = {
    isResponsive?: boolean;
};

export function NavMenu(props: NavMenuProps): ReactElement {
    const { hooks, state } = useNavMenuViewModel();

    useEffect(() => {
        state.services.current = getAccountServices(
            state.digitalActions?.find((x) => EqualsIgnoreCase(x.accountHandle, state.account.accountHandle))?.access
        );
    }, [state.appSettings, state.accountIndex, state.digitalActions, state.loading, state.lockStatus, state.location]);

    const getAccountServices = (onlineActions?: OnlineActionAccess[]): AccountService[] => {
        let services: AccountService[] = [];

        onlineActions?.forEach((oa) => {
            services.push({
                onlineAction: oa,
                order: hooks.getOrder(oa.onlineActionName!),
                display: hooks.getTitle(oa.onlineActionName!),
                action: getServiceAction(oa.onlineActionName!, oa.isAllowed ?? false),
                isAllowed: (oa.isAllowed ?? false) && hooks.isService(oa.onlineActionName!),
                isSelected: getIsSelected(oa.onlineActionName!)
            });
        });

        return services.filter((x) => x.isAllowed === true).sort((x, y) => x.order - y.order);
    };

    const getServiceAction = (option: string, isAllowed: boolean): Function => {
        switch (option?.trim().toLowerCase()) {
            case "creditlimitincrease":
                return () => {
                    goToCLI(state.accountIndex);
                };
            case "premiercreditprotection":
                return () => {
                    goToPCP();
                };
            case "requestauthorizeduser":
                return () => {
                    goToAuthorizedUser(state.accountIndex);
                };
            case "uploaddocuments":
                return () => {
                    if (state.account.isPrime) {
                        state.setShowDocUplPrimeModal(true);
                    } else {
                        goToUploadDocuments(state.accountIndex);
                    }
                };
            case "eletterview":
                return () => {
                    state.setAccountIndex(state.accountIndex);
                    goToDocuments(state.accountIndex);
                };
            case "lockunlockcard":
                if (isAllowed)
                    return () => {
                        state.setAccountIndex(state.accountIndex);
                        if (state.appSettings?.featureFlags?.lockCardEnabled) {
                            goToLockCard(state.accountIndex);
                        } else {
                            state.setShowLockCardDisabledModal(true);
                        }
                    };
                else
                    return () => {
                        state.setShowLockCardDisabledFromStatusModal(true);
                    };
            case "alerts":
                if (isAllowed)
                    return () => {
                        state.setAccountIndex(state.accountIndex);
                        if (!state.appSettings?.featureFlags?.contextualManagement) {
                            state.setShowManageAlertsUnavailableModal(true);
                        } else {
                            goToManageAlerts(state.accountIndex);
                        }
                    };
                else
                    return () => {
                        state.setShowManageAlertsDisabledModal(true);
                    };
            default:
                return () => {
                    console.log("Account Services menu item is undefined.");
                };
        }
    };

    const getIsSelected = (option: string): boolean => {
        let isSelected = false;

        let isSummary = state.location.pathname.includes("/account/summary");

        switch (option?.trim().toLowerCase()) {
            case "home":
                isSelected = isSummary && state.location.pathname.includes("/home");
                break;
            case "payments":
                isSelected = isSummary && state.location.pathname.includes("/payments");
                break;
            case "activity":
                isSelected = isSummary && state.location.pathname.includes("/activity");
                break;
            case "creditlimitincrease":
                isSelected = isSummary && state.location.pathname.includes("/credit-line-increase");
                break;
            case "premiercreditprotection":
                isSelected = state.location.pathname.includes("/account/premier-credit-protection");
                break;
            case "requestauthorizeduser":
                isSelected = isSummary && state.location.pathname.includes("/authorized-user");
                break;
            case "uploaddocuments":
                isSelected = isSummary && state.location.pathname.includes("/document-upload");
                break;
            case "eletterview":
                isSelected = isSummary && state.location.pathname.includes("/documents");
                break;
            case "lockunlockcard":
                isSelected = isSummary && state.location.pathname.includes("/lock-card");
                break;
            case "alerts":
                isSelected = isSummary && state.location.pathname.includes("/manage-alerts");
                break;
        }

        return isSelected;
    };

    function goToCLI(i: Number) {
        hooks.navigate(`/account/summary/${i}/credit-line-increase`, { relative: "path" });
    }

    function goToUploadDocuments(i: number) {
        hooks.navigate(`/account/summary/${i}/document-upload`, { relative: "path" });
    }

    function goToAuthorizedUser(i: number) {
        hooks.navigate(`/account/summary/${i}/authorized-user`, { relative: "path" });
    }

    function goToLockCard(i: number) {
        hooks.navigate(`/account/summary/${i}/lock-card`, { relative: "path" });
    }

    function goToPCP() {
        if (state.premierCreditProtectionEnrollment?.accounts?.find((x) => x.enrolled) !== undefined) {
            hooks.navigate(`/account/premier-credit-protection-enrollment-status`, { relative: "path" });
        } else if (state.premierCreditProtectionEnrollment?.accounts?.find((x) => x.eligible) !== undefined) {
            hooks.navigate(`/account/premier-credit-protection`, { relative: "path" });
        } else {
            state.setShowPCPUnavailableModal(true);
        }
    }

    function goToManageAlerts(i: number) {
        hooks.navigate(`/account/summary/${i}/manage-alerts`, { relative: "path" });
    }

    function goToDocuments(i: number) {
        hooks.navigate(`/account/summary/${i}/documents`, { relative: "path" });
    }

    const GetNavMenuOptions = (): { menu: ReactElement; options: number } => {
        let elements = [] as ReactElement[];

        if (props.isResponsive === true) elements = elements.concat(GetResponsiveOptions());

        elements = elements.concat(GetDigitalActionOptions());

        return {
            menu: (
                <Dropdown.Menu renderOnMount flip className={`${state.size === "-mobile" ? "dropdown-menu-end" : ""}`}>
                    {elements}
                </Dropdown.Menu>
            ),
            options: elements.length
        };
    };

    const GetResponsiveOptions = (): ReactElement[] => {
        const elements = [] as ReactElement[];

        let homeDisplay = state.account.isApplicationStatus
            ? `Go to Ref ${state.account.referenceNumber}`
            : `Go to Card (*${state.account.maskedAccountNumber.slice(-4)}) Home`;
        let homeSelected = getIsSelected("home");

        elements.push(
            <Dropdown.Item
                key={"Home"}
                eventKey={0}
                onClick={() => {
                    hooks.navigate(`/account/summary/${state.accountIndex}/home`);
                }}
                aria-selected={homeSelected}
            >
                <OnlineActionMI display={homeDisplay} selected={homeSelected} />
            </Dropdown.Item>
        );

        if (state.paymentActions !== undefined && state.paymentActions) {
            let paymentsDisplay = `Go to Payments`;
            let paymentsSelected = getIsSelected("payments");

            elements.push(
                <Dropdown.Item
                    key={"Payments"}
                    eventKey={1}
                    onClick={() => {
                        hooks.navigate(`/account/summary/${state.accountIndex}/payments`);
                    }}
                    aria-selected={paymentsSelected}
                >
                    <OnlineActionMI display={paymentsDisplay} selected={paymentsSelected} />
                </Dropdown.Item>
            );
        }

        let activityDisplay = `View Activity & Statements`;
        let activitySelected = getIsSelected("activity");

        elements.push(
            <Dropdown.Item
                key={"Activity"}
                eventKey={2}
                onClick={() => {
                    hooks.navigate(`/account/summary/${state.accountIndex}/activity`);
                }}
                aria-selected={activitySelected}
            >
                <OnlineActionMI display={activityDisplay} selected={activitySelected} />
            </Dropdown.Item>
        );

        return elements;
    };

    const GetDigitalActionOptions = (): ReactElement[] => {
        const elements = [] as ReactElement[];

        state.services.current.forEach((x) => {
            elements.push(
                <Dropdown.Item
                    key={x.onlineAction.onlineActionName}
                    eventKey={x.order + 2}
                    onClick={() => {
                        x.action();
                    }}
                    aria-selected={x.isSelected}
                >
                    <OnlineActionMI display={x.display} selected={x.isSelected} />
                </Dropdown.Item>
            );
        });

        return elements;
    };

    function OnlineActionMI(props: { display: string; selected: boolean }): ReactElement {
        let divCn = "cardNav-toggleContent-drop-left";

        return (
            <>
                <div className={`${divCn}${props.selected ? " drop-selected" : ""}`}>
                    {props.selected ? <Check className={`cardNav-icon-${state.account.accent}`} /> : <></>}
                    <h6 className={props.selected ? state.cardNumberCN : "cardNav-cardNumber"}>{props.display}</h6>
                </div>
            </>
        );
    }

    return <NavMenuView {...props} {...state} GetNavMenuOptions={GetNavMenuOptions} />;
}
