import { useContext, useEffect } from "react";
import { UnAuthBlocksContext } from "../../../contexts";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";

type LoginCardViewModel = {
    state: LoginCardState;
};

export type LoginCardState = {
    size: Size;
};

export function useLoginCardViewModel(): LoginCardViewModel {
    const { size } = useViewport(true, true);
    const blocks = useContext(UnAuthBlocksContext);

    useEffect(() => {
        blocks.setShowBlocks(true);

        return () => {
            blocks.setShowBlocks(false);
        };
    }, []);

    return {
        state: {
            size
        }
    };
}
