import { FormikHelpers } from "formik";
import { ReactElement, useEffect } from "react";
import { ElectronicDeliveryPreferences, useSettingsContext } from "../../../api/backend";
import {
    AccountPreferenceConfirmedRow,
    AccountPreferenceRow,
    UpdateDeliveryPreferencesView
} from "./updateDeliveryPreferencesView";
import { useUpdateDeliveryPreferencesViewModel } from "./updateDeliveryPreferencesViewModel";

export type updateDeliveryPreferencesViewProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export function UpdateDeliveryPreferencesModel(): ReactElement {
    const { hooks, state, api } = useUpdateDeliveryPreferencesViewModel();
    const { appSettings } = useSettingsContext();
    const isEMessengerDown: boolean = appSettings?.featureFlags?.isEMessengerDown;

    useEffect(() => {
        let eles = [] as ReactElement[];
        eles = eles.concat(
            state.accounts!.map((a, i) => (
                <AccountPreferenceRow
                    key={a.accountHandle}
                    account={a}
                    index={i}
                    {...state}
                    preferences={
                        state.tempDeliveryPreferences !== undefined && state.showDelPrefTermsModal === false
                            ? state.tempDeliveryPreferences
                            : hooks.preferences
                    }
                    confirmedRow={false}
                />
            ))
        );
        state.setRows(eles);
    }, [
        hooks.preferences,
        state.size,
        state.editPreferences,
        state.canSave,
        state.initialValues,
        state.tempDeliveryPreferences,
        state.showDelPrefTermsModal
    ]);

    function goToDashboard() {
        hooks.navigate("/account/dashboard");
    }

    function validate(values: any): any {
        state.setCanSave(JSON.stringify(values) !== JSON.stringify(state.initialValues));

        return {};
    }

    function onSubmit(values: any, actions: FormikHelpers<any>): void {
        state.setSelectedValues(values);
        let userSelectedToEnroll = false;
        let tempDeliveryPreferences = JSON.parse(JSON.stringify(hooks.preferences));

        Object.keys(values).forEach((x: string) => {
            let index = Number(x.split("-")[1]);
            let indexDeliveryPreference = tempDeliveryPreferences.findIndex((p: any) => {
                return p.accountHandle === state.accounts![index].accountHandle;
            });

            if (
                values["checkBox-" + index] === true &&
                values["checkBox-" + index] !== state.initialValues["checkBox-" + index]
            ) {
                tempDeliveryPreferences![indexDeliveryPreference].eStatementsEnrollment.enrollmentStatus = "Enrolled";
                tempDeliveryPreferences![indexDeliveryPreference].eLettersEnrollment.enrollmentStatus = "Enrolled";
                tempDeliveryPreferences![indexDeliveryPreference].changed = true;

                userSelectedToEnroll = true;
                state.setShowDelPrefTermsModal(true);
            } else if (
                values["checkBox-" + index] === false &&
                values["checkBox-" + index] !== state.initialValues["checkBox-" + index]
            ) {
                tempDeliveryPreferences![indexDeliveryPreference].eStatementsEnrollment.enrollmentStatus =
                    "NotEnrolled";
                tempDeliveryPreferences![indexDeliveryPreference].eLettersEnrollment.enrollmentStatus = "NotEnrolled";
                tempDeliveryPreferences![indexDeliveryPreference].changed = true;
            }
        });

        if (!userSelectedToEnroll) {
            let refeshDeliveryPreferences = false;

            Object.keys(values).forEach((x: any) => {
                let index = Number(x.split("-")[1]);

                if (
                    values["checkBox-" + index] === false &&
                    values["checkBox-" + index] !== state.initialValues["checkBox-" + index]
                ) {
                    refeshDeliveryPreferences = true;

                    if (!isEMessengerDown) {
                        api.Enroll({
                            accountHandle: state.accounts![index].accountHandle,
                            enrollStatements: false,
                            enrollDocuments: false,
                            allAccounts: false
                        }).catch((err) => {
                            console.log(err);
                        });
                    }
                }
            });

            state.setEditPreferences(false);
            state.setShowDelPrefConfirmModal(true);
            actions.resetForm();
            actions.setSubmitting(false);

            if (refeshDeliveryPreferences) {
                setDeliveryPreferencesConfirmRows(tempDeliveryPreferences);
                setDelPrefTimer();
            }
        } else {
            setDeliveryPreferencesConfirmRows(tempDeliveryPreferences);
        }
    }

    function setDelPrefTimer() {
        if (!isEMessengerDown) {
            state.setTimeoutId(
                setTimeout(() => {
                    api.getPreferences().finally(() => state.setTempDeliveryPreferences(undefined));
                }, 5000)
            );
        }
    }

    function setDeliveryPreferencesConfirmRows(values: ElectronicDeliveryPreferences[] | undefined) {
        state.setTempDeliveryPreferences(values);
        let eles = [] as ReactElement[];
        eles = eles.concat(
            state.accounts!.map((a, i) => (
                <AccountPreferenceConfirmedRow
                    key={a.accountHandle}
                    account={a}
                    index={i}
                    preferences={values}
                    editPreferences={state.editPreferences}
                    confirmedRow={true}
                />
            ))
        );
        state.setConfirmRows(eles);
    }

    return (
        <UpdateDeliveryPreferencesView
            {...state}
            goToDashboard={goToDashboard}
            onSubmit={onSubmit}
            validate={validate}
            setDelPrefTimer={setDelPrefTimer}
        />
    );
}
