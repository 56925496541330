import { ReactElement } from "react";
import { PaymentButton } from "../paymentButton/paymentButton";
import "./currentBalance.scss";
import { CurrentBalanceState } from "./currentBalanceViewModel";

export function CurrentBalanceView(props: CurrentBalanceState): ReactElement {
    return (
        <div id="currentBalance">
            <div className={`paymentAmount${props.size}`} id={`paymentAmountId${props.size}`}>
                <div className="paymentAmount-header" id="paymentAmount-header-balance">
                    <h5>Current Balance</h5>
                </div>
                <div className="paymentAmount-amount">
                    <h2 className={`paymentAmount-dollar-${props.account?.accent}`}>${props.dollars}</h2>
                    <h3 className={`paymentAmount-cents-${props.account?.accent}`}>{props.cents}</h3>
                </div>
                <div className="paymentAmount-message">
                    <label id="paymentAmount-text">The Current Balance is due</label>
                </div>
                <PaymentButton />
            </div>
        </div>
    );
}
