import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import {
    ContextualControlsStatusResponse,
    UserAccount,
    useAccountContext,
    useContextualMessagingContext,
    useDigitalActionsContext,
    useSettingsContext
} from "../../../api/backend";
import { CardNavContext } from "../../../contexts";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { GenericModalProps } from "../../generic";

type LockCardViewModel = {
    hooks: LockCardHooks;
    state: LockCardState;
    api: LockCardApi;
};

type LockCardHooks = {
    navigate: NavigateFunction;
    lockCard: () => void;
    unlockCard: () => void;
    actionAllowed: (option: string, accountHandle?: string | undefined) => boolean;
};

export type LockCardState = {
    size: Size;
    index: number;
    account: UserAccount;
    accountIndex: number;
    lockCardSuccessModal: boolean;
    setLockCardSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
    LockCardSuccessModalProps: GenericModalProps;
    unlockCardSuccessModal: boolean;
    setUnlockCardSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
    UnlockCardSuccessModalProps: GenericModalProps;
    lockStatus: boolean;
    setLockStatus: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    last4: string;
    lockCardEnabled: boolean;
};

type LockCardApi = {
    getLockStatus: () => Promise<AxiosResponse<ContextualControlsStatusResponse, any>>;
};

export function useLockCardViewModel(): LockCardViewModel {
    const navigate = useNavigate();
    const { setShowNav } = useContext(CardNavContext);
    const { appSettings } = useSettingsContext();
    const { size } = useViewport(false, true);
    const { id } = useParams();
    const { accounts, accountIndex, setAccountIndex } = useAccountContext();
    const account = accounts![Number(id)];
    const { ControlsStatus, ControlsLock, ControlsUnlock } = useContextualMessagingContext();
    const [lockCardSuccessModal, setLockCardSuccessModal] = useState(false);
    const [unlockCardSuccessModal, setUnlockCardSuccessModal] = useState(false);
    const [lockStatus, setLockStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const index = Number(id);
    const { actionAllowed } = useDigitalActionsContext();
    const last4 = account?.maskedAccountNumber?.substring(account?.maskedAccountNumber?.length - 4);
    const lockCardEnabled = appSettings?.featureFlags?.lockCardEnabled;

    const LockCardSuccessModalProps = {
        headerText: "Success!",
        bodyText: "Your card has been locked.",
        primaryButton: {
            text: "Back to Home",
            action: () => {
                setLockCardSuccessModal(false);
                navigate("/account/summary/" + id + "/home", { relative: "path" });
            }
        }
    };

    const UnlockCardSuccessModalProps = {
        headerText: "Success!",
        bodyText: "Your card has been unlocked.",
        primaryButton: {
            text: "Back to Home",
            action: () => {
                setUnlockCardSuccessModal(false);
                navigate("/account/summary/" + id + "/home", { state: { DisplayUnlockMsg: "true" }, relative: "path" });
            }
        }
    };

    function lockCard() {
        setLoading(true);

        LockCard(index)
            .then(() => {
                setLockCardSuccessModal(true);
            })
            .finally(() => setLoading(false));
    }

    async function LockCard(index: number): Promise<AxiosResponse<any, any>> {
        return ControlsLock({ accountHandle: accounts![index].accountHandle });
    }

    function unlockCard() {
        setLoading(true);

        UnLockCard(index)
            .then(() => {
                setUnlockCardSuccessModal(true);
            })
            .finally(() => setLoading(false));
    }

    async function UnLockCard(index: number): Promise<AxiosResponse<any, any>> {
        return ControlsUnlock({
            accountHandle: accounts![index].accountHandle
        });
    }

    async function getLockStatus(): Promise<AxiosResponse<ContextualControlsStatusResponse, any>> {
        return await ControlsStatus(accounts![index].accountHandle);
    }

    useEffect(() => {
        setAccountIndex(index);
    }, []);

    useEffect(() => {
        setShowNav(true);
        setLoading(true);
        if (appSettings?.featureFlags?.lockCardEnabled) {
            getLockStatus()
                .then((res) => {
                    setLockStatus(res.data.isLocked);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLockStatus(false);
        }
    }, [accountIndex, appSettings]);

    return {
        hooks: { navigate, lockCard, unlockCard, actionAllowed },
        state: {
            size,
            index,
            account,
            accountIndex,
            lockCardSuccessModal,
            setLockCardSuccessModal,
            LockCardSuccessModalProps,
            unlockCardSuccessModal,
            setUnlockCardSuccessModal,
            UnlockCardSuccessModalProps,
            lockStatus,
            setLockStatus,
            loading,
            setLoading,
            last4,
            lockCardEnabled
        },
        api: { getLockStatus }
    };
}
