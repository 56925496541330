import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { GenericModal, GenericModalProps, ModalProps } from "../../../generic";

type CancelSecureAccessCodeProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export function CancelSecureAccessCode(props: ModalProps & CancelSecureAccessCodeProps): ReactElement {
    const navigate = useNavigate();

    const state = {
        headerText: "Cancel Secure Access Code Request?",
        bodyText:
            "Are you sure you would like to cancel your code request? You will not be able to log in without a secure access code.",
        primaryButton: {
            text: "Cancel Code Request",
            variant: "tertiary-neg",
            action: () => {
                navigate("/auth/login");
            }
        },
        secondaryButton: {
            text: "Continue Code Request",
            variant: "neutral",
            action: () => {
                props.setShow(false);
            }
        }
    } as GenericModalProps;

    return <GenericModal show={props.show} {...state} />;
}
