import { ReactElement, useEffect } from "react";
import { ElectronicDeliveryPreferences, useSettingsContext } from "../../../../api/backend";
import { AccountPreferenceConfirmedRow } from "../updateDeliveryPreferencesView";
import { SplitDeliveryLandingView } from "./splitDeliveryLandingView";
import { useSplitDeliveryLandingViewModel } from "./splitDeliveryLandingViewModel";

export type SplitDeliveryLandingProps = {
    showSplitLanding: boolean;
    setShowSplitLanding: React.Dispatch<React.SetStateAction<boolean>>;
};

export function SplitDeliveryLandingModel(props: SplitDeliveryLandingProps): ReactElement {
    const { hooks, state, api } = useSplitDeliveryLandingViewModel();
    const { appSettings } = useSettingsContext();
    const isEMessengerDown: boolean = appSettings?.featureFlags?.isEMessengerDown;

    useEffect(() => {
        let affectedActs = state.accounts.filter((x) => hooks.isSplit(x));
        let tempDeliveryPreferences = JSON.parse(
            JSON.stringify(state.preferences ?? [])
        ) as ElectronicDeliveryPreferences[];

        tempDeliveryPreferences.forEach((x) => {
            if (affectedActs.find((a) => a.accountHandle === x.accountHandle) !== undefined) {
                x.changed = true;
                x.eStatementsEnrollment.enrollmentStatus = "Enrolled";
            }
        });

        let eles = [] as ReactElement[];
        eles = eles.concat(
            state.accounts!.map((a, i) => (
                <AccountPreferenceConfirmedRow
                    key={a.accountHandle}
                    account={a}
                    index={i}
                    preferences={tempDeliveryPreferences}
                    editPreferences={false}
                    confirmedRow={true}
                />
            ))
        );
        state.setConfirmRows(eles);
    }, []);

    function unEnrollCallback() {
        if (!isEMessengerDown) {
            Promise.all(
                state.accounts
                    .filter((a) => a.isSplitElectronicDelivery)
                    .map((a) => {
                        return api.Enroll({
                            accountHandle: a.accountHandle,
                            enrollDocuments: false,
                            enrollStatements: false,
                            allAccounts: false
                        });
                    })
            ).catch((err) => {
                console.log(err);
            });
        }
    }

    function declineTerms() {
        state.setChoice(false);
        unEnrollCallback();
        state.setShowConfirmation(true);
    }

    function acceptTerms() {
        //This will only hit if affectedAccounts.length > 0
        state.setChoice(true);
        state.setShowConfirmation(true);

        if (!isEMessengerDown) {
            setTimeout(() => {
                api.getPreferences();
            }, 5000);
        }
    }

    return (
        <SplitDeliveryLandingView
            {...props}
            {...state}
            unEnrollCallback={unEnrollCallback}
            declineTerms={declineTerms}
            acceptTerms={acceptTerms}
        />
    );
}
