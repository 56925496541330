import { ReactElement } from "react";
import { AppDownloadBannerView } from "./appDownloadBannerView";
import { useAppDownloadBannerViewModel } from "./appDownloadBannerViewModel";

export function AppDownloadBannerModel(): ReactElement {
    const { state } = useAppDownloadBannerViewModel();

    function close() {
        state.setShowBanner(false);
    }

    function getIt() {
        state.setShowIntermediate(true);
    }

    return <AppDownloadBannerView {...state} close={close} getIt={getIt} />;
}
