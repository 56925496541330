import { AxiosResponse } from "axios";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import {
    PaymentResponse,
    useAccountContext,
    useDigitalActionsContext,
    usePaymentContext,
    UserAccount
} from "../../../api/backend";
import { useCardNav, useIndexedRoute, useViewport } from "../../../hooks";
import { Size } from "../../../types";

type PaymentsSummaryViewModel = {
    state: PaymentsSummaryState;
    api: PaymentsSummaryApi;
    hooks: PaymentsSummaryHooks;
};

export type PaymentsSummaryState = {
    size: Size;
    accounts: UserAccount[];
    accountIndex: number;
    account: UserAccount | undefined;
    setAccountIndex: React.Dispatch<React.SetStateAction<number>>;
    urlId: Number;
};

type PaymentsSummaryApi = {
    paymentsGet: (accountHandle: string) => Promise<AxiosResponse<PaymentResponse[], any>>;
};
type PaymentsSummaryHooks = {
    navigate: NavigateFunction;
    actionAllowed: (option: string, accountHandle?: string | undefined) => boolean;
};

const usePaymentsSummaryState = (): PaymentsSummaryState => {
    const { id } = useParams();
    const { size } = useViewport(false, true);
    const { accounts, accountIndex, setAccountIndex } = useAccountContext();
    const account = accounts[accountIndex];

    return {
        size,
        accounts,
        account,
        accountIndex,
        setAccountIndex,
        urlId: Number(id)
    };
};

const usePaymentsSummaryApi = (): PaymentsSummaryApi => {
    const { Get: paymentsGet } = usePaymentContext();

    return {
        paymentsGet
    };
};

const usePaymentsSummaryHooks = (): PaymentsSummaryHooks => {
    useCardNav();
    useIndexedRoute("/account/summary/%s/payments");
    const navigate = useNavigate();
    const { actionAllowed } = useDigitalActionsContext();

    return {
        navigate,
        actionAllowed
    };
};

export function usePaymentsSummaryViewModel(): PaymentsSummaryViewModel {
    const state = usePaymentsSummaryState();
    const api = usePaymentsSummaryApi();
    const hooks = usePaymentsSummaryHooks();

    return {
        state,
        api,
        hooks
    };
}
