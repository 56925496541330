import { ContentLibrary, DisclosuresCms, PremierCreditProtectionCMS, useLoaderCms } from "../../../api/backend";

export type DisclosuresData = {
    cms: DisclosuresCms;
    headerText: string;
    bodyText: string;
    isPrint: boolean;
    printView: string;
    isPdf: boolean;
};

export type PCPData = {
    cms: PremierCreditProtectionCMS;
    headerText: string;
    bodyText: string;
    isPrint: boolean;
    printView: string;
    isPdf: boolean;
};

export type PCPFormsAndMaterialsData = {
    pageInfo: ContentLibrary | undefined;
    headerText: string;
    bodyText: string;
    isPrint: boolean;
    printView: string;
    isPdf: boolean;
};

export async function AccessibilityDisclosureLoader(isPrint: boolean): Promise<DisclosuresData> {
    const { Disclosures } = useLoaderCms();
    const cms = (await Disclosures()).data;
    const headerText = cms.AccessibilityStatement.Name;
    const bodyText = cms.AccessibilityStatement.BodyText;
    const printView = "/home/print-view-accessibility";

    return { cms, headerText, bodyText, isPrint, printView, isPdf: false };
}

export async function PrivacyDisclosureLoader(isPrint: boolean): Promise<DisclosuresData> {
    const { Disclosures } = useLoaderCms();
    const cms = (await Disclosures()).data;
    const headerText = cms.PrivacyDisclosure.Name;
    const bodyText = cms.PrivacyDisclosure.BodyText.replace('<a name="sec10"', '<a id="sec10" name="sec10"');
    const printView = "/home/print-view-privacy";

    return { cms, headerText, bodyText, isPrint, printView, isPdf: false };
}

export async function TermsAndConditionsDisclosureLoader(isPrint: boolean): Promise<DisclosuresData> {
    const { Disclosures } = useLoaderCms();
    const cms = (await Disclosures()).data;
    const headerText = cms.TermsAndConditionsDisclosure.Name;
    const bodyText = cms.TermsAndConditionsDisclosure.BodyText;
    const printView = "/home/print-view-terms-of-use";

    return { cms, headerText, bodyText, isPrint, printView, isPdf: false };
}

export async function PrivacyNoticeDisclosureLoader(isPrint: boolean): Promise<DisclosuresData> {
    const { Disclosures } = useLoaderCms();
    const cms = (await Disclosures()).data;
    const headerText = cms.PrivacyNotice.Name;
    const bodyText = cms.PrivacyNotice.BodyText;
    const printView = "";

    return { cms, headerText, bodyText, isPrint, printView, isPdf: true };
}

export async function MastercardGuideToBenefitsLoader(isPrint: boolean): Promise<PCPFormsAndMaterialsData> {
    const { FormsAndMaterials } = useLoaderCms();
    const cms = (await FormsAndMaterials()).data;
    var pageInfo: ContentLibrary | undefined;
    let headerText = "";
    let bodyText = "";
    let printView = "";

    if (cms !== undefined && cms.ContentLibraryData.length > 0) {
        pageInfo = cms.ContentLibraryData.find((element) => element.Id === 4);

        if (pageInfo !== undefined) {
            headerText = pageInfo.Name;
            bodyText = pageInfo.ContentData;
            printView = "";
        }
    }

    return { pageInfo, headerText, bodyText, isPrint, printView, isPdf: true };
}

export async function PCPDisclosureLoader(isPrint: boolean): Promise<PCPData> {
    const { PremierCreditProtection } = useLoaderCms();
    const cms = (await PremierCreditProtection()).data;
    const headerText = cms.Name;
    const bodyText = cms.DisclosureText;
    const printView = "";

    return { cms, headerText, bodyText, isPrint, printView, isPdf: true };
}

export async function PCPFormsAndMaterialsLoader(isPrint: boolean): Promise<PCPFormsAndMaterialsData> {
    const { FormsAndMaterials } = useLoaderCms();
    const cms = (await FormsAndMaterials()).data;
    var pageInfo: ContentLibrary | undefined;
    let headerText = "";
    let bodyText = "";
    let printView = "";

    if (cms !== undefined && cms.ContentLibraryData.length > 0) {
        pageInfo = cms.ContentLibraryData.find((element) => element.Id === 15);

        if (pageInfo !== undefined) {
            headerText = pageInfo.Name;
            bodyText = pageInfo.ContentData;
            printView = "";
        }
    }

    return { pageInfo, headerText, bodyText, isPrint, printView, isPdf: true };
}
