import { ReactElement } from "react";
import { Dropdown } from "react-bootstrap";
import { NavigateFunction } from "react-router-dom";
import { UserAccount } from "../../../api/backend";
import { ButtonPro, DropdownToggle } from "../../generic";
import { Check, CreditCard, StatementDoc } from "../../generic/icons";
import { useCardNavViewModel } from "./cardNavViewModel";
import { CardNavMain } from "./desktop/cardNavMain";
import { CardNavAlt } from "./responsive/cardNavAlt";

export function CardNav(): ReactElement {
    const { state } = useCardNavViewModel();

    return <>{state.showNav ? state.size === "" ? <CardNavMain /> : <CardNavAlt /> : <></>}</>;
}

export const GetCardOptions = (
    accounts: UserAccount[],
    accountIndex: number,
    onClick: Function,
    navigate: NavigateFunction,
    isResponsive: boolean
): ReactElement => {
    if (accounts.length > 1) {
        return (
            <Dropdown className="cardNav-dropdown">
                <Dropdown.Toggle as={DropdownToggle(accounts[accountIndex].accent, true)} className="cardNav-toggle">
                    {GetCurrentCard(accounts, accountIndex, accountIndex, false)}
                </Dropdown.Toggle>
                {GetDropdownCards(accounts, accountIndex, onClick, navigate)}
            </Dropdown>
        );
    }

    return (
        <div
            className="cardNav-dropdown"
            onClick={() => {
                if (accounts.length === 1 && isResponsive) {
                    navigate(`/account/summary/${0}/home`);
                }
            }}
        >
            {GetCurrentCard(accounts, 0, accountIndex, false)}
        </div>
    );
};

const GetCurrentCard = (accounts: UserAccount[], i: number, curIndex: number, drop: boolean): ReactElement => {
    let im = accounts[i].accent;
    let iconCN = `cardNav-icon-${im}`;
    let cardNumberCN = `cardNav-cardNumber-${im}`;

    return (
        <>
            {accounts[i].isApplicationStatus ? (
                <div className={`cardNav-toggleContent${drop ? "-drop" : ""}`}>
                    {drop && i === curIndex ? (
                        <Check className={iconCN} svgClass="icon-size" />
                    ) : (
                        <div className="cardNav_icon_spacer"> </div>
                    )}

                    <div className={`cardNav-toggleContent-dropContent`}>
                        <StatementDoc className={iconCN} svgClass="icon-size" />
                        <h6 className={cardNumberCN}>Ref {accounts[i].referenceNumber}</h6>
                    </div>
                </div>
            ) : (
                <div className={`cardNav-toggleContent${drop ? "-drop" : ""}`}>
                    {drop && i === curIndex ? (
                        <Check className={iconCN} svgClass="icon-size" />
                    ) : (
                        <div className="cardNav_icon_spacer"> </div>
                    )}
                    <div className={`cardNav-toggleContent-dropContent`}>
                        <CreditCard className={iconCN} svgClass="icon-size" />
                        <h6 className={cardNumberCN}>Card (*{accounts[i].maskedAccountNumber.slice(-4)})</h6>
                    </div>
                </div>
            )}
        </>
    );
};

const GetDropdownCards = (
    accounts: UserAccount[],
    curIndex: number,
    onClick: Function,
    navigate: NavigateFunction
): ReactElement => {
    const elements = [] as ReactElement[];

    for (let i = 0; i < accounts.length; i++) {
        elements.push(
            <Dropdown.Item
                key={accounts[i].accountHandle}
                eventKey={i}
                onClick={() => {
                    onClick(i);
                }}
            >
                {GetCurrentCard(accounts, i, curIndex, true)}
            </Dropdown.Item>
        );
    }

    elements.push(
        <Dropdown.Item
            key={`dashboard`}
            eventKey={accounts.length}
            onClick={() => {
                navigate("/account/dashboard");
            }}
        >
            <h6 className="cardNav-viewAll">View All Accounts</h6>
        </Dropdown.Item>
    );

    return <Dropdown.Menu>{elements}</Dropdown.Menu>;
};

export const GetCardsList = (
    accounts: UserAccount[],
    curIndex: number,
    onClick: Function,
    navigate: NavigateFunction,
    onDashboard: Function
): ReactElement => {
    const elements = [] as ReactElement[];

    for (let i = 0; i < accounts.length; i++) {
        elements.push(
            <ButtonPro
                variant="neutral"
                key={accounts[i].accountHandle}
                onClick={() => {
                    onClick(i);
                }}
            >
                {GetCurrentCard(accounts, i, curIndex, true)}
            </ButtonPro>
        );
    }

    if (accounts.length > 1) {
        elements.push(
            <ButtonPro
                key={"dashboard"}
                variant="neutral"
                onClick={() => {
                    onDashboard();
                }}
            >
                <h6 className="cardNav-viewAll">View All Accounts</h6>
            </ButtonPro>
        );
    }

    return <>{elements}</>;
};
