import { ReactElement } from "react";
import { PaymentAccounts, PaymentHistory, PaymentHub, PaymentScheduled } from "../..";
import { BackArrow } from "../../generic";
import "./paymentsSummary.scss";
import PaymentsSummarySplash from "./paymentsSummarySplash/paymentsSummarySplash";
import { PaymentsSummaryState } from "./paymentsSummaryViewModel";

function PaymentsSummaryView(props: PaymentsSummaryState): ReactElement {
    return (
        <div id={`paymentsSummary${props.size}`}>
            <BackArrow available to={`/account/summary/${props.accountIndex}/home`}>
                <PaymentsSummarySplash />
            </BackArrow>

            <PaymentHub />
            <PaymentScheduled />
            <PaymentHistory />
            <PaymentAccounts />
        </div>
    );
}
export default PaymentsSummaryView;
