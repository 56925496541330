import { ReactElement, useEffect } from "react";
import AppHeaderPrivateView from "./appHeaderPrivateView";
import { useAppHeaderPrivateViewModel } from "./appHeaderPrivateViewModel";

export function AppHeaderPrivateModel(): ReactElement {
    const { hooks, state, api } = useAppHeaderPrivateViewModel();

    useEffect(() => {
        if (state.size === "") state.setSzKey("md");
        else state.setSzKey(false);
    }, [state.size]);

    useEffect(() => {
        state.setUpdateProfileAction(hooks.actionAllowed("updateProfile"));
        state.setUpdateDeliveryAction(hooks.actionAllowed("eLetterEnroll") || hooks.actionAllowed("eStatementEnroll"));
    });

    useEffect(() => {
        if (state.size === "") {
            state.setAcProps({ open: true, accord: "mm-", keys: ["0"] });
            state.setIKey("0");

            if (state.showOffCanvas) {
                state.setShowOffCanvas(false);
            }
        } else {
            state.setAcProps({ open: false, accord: "", keys: ["1"] });
        }
    }, [state.size]);

    function handleClose() {
        state.setShowOffCanvas(false);
    }

    function onSelect(e: string | null) {
        state.setIKey(e?.toString());
    }

    function toggleOffCanvas() {
        state.setShowOffCanvas(!state.showOffCanvas);
    }

    function onCardSelected(i: number) {
        state.setAccountIndex(i);
        hooks.navigate(`/account/summary/${i}/home`, { relative: "path" });
        handleClose();
    }

    function onEditContactInfo() {
        handleClose();
        hooks.navigate("/profile/update-profile");
    }

    function onChooseDelivery() {
        handleClose();
        hooks.navigate("/profile/delivery-options");
    }

    function onChangePassword() {
        handleClose();
        hooks.navigate("/profile/change-password");
    }

    function onDashboard() {
        handleClose();
        if (state.accounts?.length === 1 && state.accounts[0].isApplicationStatus === false) {
            hooks.navigate("/account/summary/0/home", { relative: "path" });
        } else {
            hooks.navigate("/account/dashboard");
        }
    }

    function onSearch() {
        handleClose();

        if (state.location.pathname !== "/help") hooks.navigate("/help");

        hooks.onSearch();
    }

    function onLogout() {
        if (state.accounts !== undefined) {
            api.logout();
        }

        hooks.swrveShutdown();
        hooks.clearConsumerGuid();
        hooks.clearJwt();
        hooks.removeCookie("OptanonConsent", { path: "/" });
        hooks.removeCookie("OptanonAlertBoxClosed", { path: "/" });
        hooks.clearIdentity("all");
        hooks.navigate("/logout");
    }

    return (
        <AppHeaderPrivateView
            {...state}
            handleClose={handleClose}
            onSelect={onSelect}
            toggleOffCanvas={toggleOffCanvas}
            onEditContactInfo={onEditContactInfo}
            onChooseDelivery={onChooseDelivery}
            onChangePassword={onChangePassword}
            onDashboard={onDashboard}
            onLogin={onDashboard}
            onLogout={onLogout}
            onSearch={onSearch}
            onCardSelected={onCardSelected}
        />
    );
}
