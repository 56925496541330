import { ReactElement } from "react";
import MakePaymentView from "./makePaymentView";
import { useMakePaymentViewModel } from "./makePaymentViewModel";

export type MakePaymentProps = {
    showSchedule: boolean;
    setShowSchedule: React.Dispatch<React.SetStateAction<boolean>>;
};

export function MakePaymentModel(props: MakePaymentProps): ReactElement {
    const { state } = useMakePaymentViewModel();

    function addBankCallback() {
        props.setShowSchedule(true);
    }

    return <MakePaymentView {...props} {...state} addBankCallback={addBankCallback} />;
}
