import { ReactElement } from "react";
import "./splashMessage.scss";
import { SplashMessageState } from "./splashMessageViewModel";

type SplashMessageViewProps = SplashMessageState & { firstName: string; message: string; size: string };

export function SplashMessageView(props: SplashMessageViewProps): ReactElement {
    return (
        <div id={`splashMessage${props.size}`}>
            <div className="splashMessage-content">
                {props.size === "" ? (
                    <h2>
                        {props.message}&nbsp;<span className="splashMessage-name">{props.firstName}</span>
                    </h2>
                ) : (
                    <></>
                )}

                {props.header ? <h6>{props.header}</h6> : <></>}
            </div>
        </div>
    );
}
