import { ReactElement } from "react";
import AddPaymentAccountView from "./addPaymentAccountView";
import { useAddPaymentAccountViewModel } from "./addPaymentAccountViewModel";

export type AddPaymentAccountProps = {
    showEnter: boolean;
    setShowEnter: React.Dispatch<React.SetStateAction<boolean>>;
    callback?: Function;
};

export function AddPaymentAccountModel(props: AddPaymentAccountProps): ReactElement {
    const { state } = useAddPaymentAccountViewModel();

    return <AddPaymentAccountView {...props} {...state} />;
}
