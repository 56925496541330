import { ReactElement, useEffect } from "react";
import { UserAccount } from "../../../../api/backend";
import { ShowPastDueAlert } from "../../../../utility/showPastDueAlert";
import { DashboardCardView } from "./dashboardCardView";
import { useDashboardCardViewModel } from "./dashboardCardViewModel";

export type DashboardCardProps = {
    index: number;
    account: UserAccount;
};

export function DashboardCardModel(props: DashboardCardProps): ReactElement {
    const { hooks, state, api } = useDashboardCardViewModel(props);

    useEffect(() => {
        api.accountSummaryCms();

        if (state.appSettings?.featureFlags?.lockCardEnabled) {
            api.getLockStatus().then((res) => {
                state.setLockStatus(res.data.isLocked);
            });
        } else {
            state.setLockStatus(false);
        }
    }, []);

    useEffect(() => {
        if (ShowPastDueAlert(props.account.paymentPastDue, props.account.status, props.account.seeStatementMessage)) {
            state.setShowAlert(true);
            state.setAlert("Past Due");
        }
    }, []);

    useEffect(() => {
        if (state.lockStatus || state.showAlert || props.account.showAnnualFeeMessage) {
            state.setNotification(true);
            state.setAlertCount(
                (state.lockStatus ? 1 : 0) + (state.showAlert ? 1 : 0) + (props.account.showAnnualFeeMessage ? 1 : 0)
            );
        } else {
            state.setNotification(false);
        }
    }, [state.lockStatus, state.showAlert, props.account.showAnnualFeeMessage]);

    function onCardSelected(index: number) {
        hooks.navigate(`/account/summary/${index}/home`);
    }

    return <DashboardCardView {...props} {...state} lockStatus={state.lockStatus} onCardSelected={onCardSelected} />;
}
